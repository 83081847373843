import {
  ApplicationDefinition,
  ApplicationEvaluator,
  ApplicationEntry,
  FormQuestionAnswer,
  ApplicationScore,
  Comment,
  Country,
  Interest,
  SDG,
  NewProject,
  Sorting,
  FilterAnswer,
  ApplicationFilters,
} from 'redux/types/account';
import { ApplicationStatus } from 'redux/types/enums';
import { getAxiosInstance } from './helper';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { mentionParse } from 'util/mention';
import { handleError, toastException } from 'redux/epics/helpers';
import { removeMultipleNewLines } from 'util/utils';
import { sanitizeAssetUrl } from 'util/assets';
import methodology from 'redux/actions/methodology';

const ai = getAxiosInstance();

const cleanFilters = (filters: Partial<FilterAnswer>) => {
  const response: Partial<FilterAnswer> = {};

  if (!filters) return response;

  if (filters?.countryFilter?.values?.length) {
    response.countryFilter = filters.countryFilter;
  }
  if (filters?.tagFilter?.values?.length) {
    response.tagFilter = filters.tagFilter;
  }
  if (filters?.industryFilter?.values?.length) {
    response.industryFilter = filters.industryFilter;
  }
  if (filters?.sdgFilter?.values?.length) {
    response.sdgFilter = filters.sdgFilter;
  }
  return response;
};

function _updateApplicationEntryStatus(
  bearer: string,
  applicationEntryId: number,
  status: ApplicationStatus,
  methodologyId: number | undefined = undefined,
  newProject?: NewProject,
  applicantEmail?: string,
): Promise<null> {
  const url =
    (status === ApplicationStatus.Accepted && `/api/Application/${applicationEntryId}/AcceptApplication`) ||
    (status === ApplicationStatus.Denied && `/api/Application/${applicationEntryId}/RejectApplication`) ||
    `/api/Application/UpdateApplicationStatus/${applicationEntryId}`;
  const method = ((status === ApplicationStatus.Accepted || status === ApplicationStatus.Denied) && 'POST') || 'PUT';
  if (status !== ApplicationStatus.Accepted) methodologyId = undefined;
  return new Promise((resolve, reject) => {
    ai({
      method,
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        status,
        methodologyId,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => handleError(err));
  });
}

export function _deleteApplicationEntries(bearer: string, applicationEntryId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/Application/${applicationEntryId}/DeleteApplication`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export async function deleteApplicationEntries(
  bearer: string,
  applicationEntryIds: number[],
  communityId: number,
  applicationDefinitionId: number,
): Promise<{
  applicationEntryIds: number[];
  communityId: number;
  applicationDefinitionId: number;
}> {
  const successfulIds: number[] = [] as number[];
  for (let entryIndex = 0; entryIndex < applicationEntryIds.length; entryIndex++) {
    try {
      await _deleteApplicationEntries(bearer, applicationEntryIds[entryIndex]);
      successfulIds.push(applicationEntryIds[entryIndex]);
    } catch (err: any) {
      toastException(err);
    }
  }
  if (successfulIds.length === 0) throw new Error('All changes failed');
  return {
    applicationEntryIds: successfulIds,
    communityId: communityId,
    applicationDefinitionId: applicationDefinitionId,
  };
}

export function saveApplicationDefinition(
  bearer: string,
  communityId: number,
  applicationDefinition: ApplicationDefinition,
): Promise<{ communityId: number; applicationDefinition: ApplicationDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: applicationDefinition.id ? 'PUT' : 'POST',
      url: '/api/ApplicationDefinition' + (applicationDefinition.id ? `/${applicationDefinition.id}` : ''),
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        ...applicationDefinition,
        acceptedMethodologyId: applicationDefinition.acceptedMethodologyId || null,
        acceptedCircleId: applicationDefinition.acceptedCircleId || null,
        creatorUserId: undefined,
        formId: applicationDefinition.formId || null,
        applicationDefinitionCriterias: applicationDefinition.applicationDefinitionCriterias.map(
          (criteria: string) => ({ evaluationCriteria: criteria.trim() }),
        ),
        applicationDefinitionEvaluators: applicationDefinition.applicationDefinitionEvaluators.map(
          (evaluator: ApplicationEvaluator) => ({ userId: evaluator.userId }),
        ),
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinition: {
            ...response.data,
            applicationDefinitionEvaluators: response.data.applicationDefinitionEvaluators.map((evaluator: any) => ({
              userId: evaluator.userId,
              user: {
                name: evaluator.user.name,
                photoUrl: evaluator.user.photoUrl,
              },
            })),
            applicationDefinitionCriterias: response.data.applicationDefinitionCriterias.map(
              (criteria: { evaluationCriteria: string }) => criteria.evaluationCriteria,
            ),
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityApplicationDefinitions(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; applicationDefinitions: ApplicationDefinition[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/ApplicationDefinitions`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinitions: response.data.list.map(
            (applicationDefinition: any): ApplicationDefinition => ({
              ...applicationDefinition,
              applicationDefinitionCriterias: applicationDefinition.applicationDefinitionCriterias.map(
                (criteria: any) => criteria?.evaluationCriteria || '',
              ),
              applicationDefinitionEvaluators: applicationDefinition.applicationDefinitionEvaluators.map(
                (evaluator: { userId: number; user: { photoUrl: string; name: string } }): ApplicationEvaluator => ({
                  user: {
                    photoUrl: evaluator.user.photoUrl,
                    name: evaluator.user.name,
                  },
                  userId: evaluator.userId,
                }),
              ),
              form: {
                ...applicationDefinition.form,
                questions: applicationDefinition.questions
                  ?.sort((a: any, b: any) => a.questionOrder - b.questionOrder)
                  .map((question: any) => ({
                    ...question,
                    questionOptions: question.questionOptions?.sort(
                      (a: any, b: any) => a.questionOptionOrder - b.questionOptionOrder,
                    ),
                  })),
              },
            }),
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteApplicationDefinition(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
): Promise<{ communityId: number; applicationDefinitionId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/ApplicationDefinition/${applicationDefinitionId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          communityId,
          applicationDefinitionId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchApplicationDefinitionById(
  bearer: string,
  applicationDefinitionId: number,
): Promise<{ applicationDefinition: ApplicationDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationDefinition/${applicationDefinitionId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          applicationDefinition: {
            ...response.data,
            communityLogo: response.data.communityLogoImage || '',
            communityCover: response.data.communityMainImage || '',
            applicationDefinitionCriterias: response.data.applicationDefinitionCriterias.map(
              (criteria: any) => criteria.evaluationCriteria,
            ),
            form: {
              ...response.data.form,
              questions: (response.data.form?.questions || [])
                ?.sort((a: any, b: any) => a.questionOrder - b.questionOrder)
                .map((question: any) => ({
                  ...question,
                  questionOptions: question.questionOptions?.sort(
                    (a: any, b: any) => a.questionOptionOrder - b.questionOptionOrder,
                  ),
                })),
            },
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchApplicationDefinitionPreviewById(
  applicationDefinitionId: number,
): Promise<{ applicationDefinition: ApplicationDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationDefinition/${applicationDefinitionId}/ApplicantPreview`,
    })
      .then((response: AxiosResponse) => {
        resolve({
          applicationDefinition: {
            ...response.data,
            communityLogo: response.data.communityLogoImage || '',
            communityCover: response.data.communityMainImage || '',
            applicationDefinitionCriterias: response.data.applicationDefinitionCriterias.map(
              (criteria: any) => criteria.evaluationCriteria,
            ),
            form: {
              ...response.data.form,
              questions: (response.data.form?.questions || [])
                ?.sort((a: any, b: any) => a.questionOrder - b.questionOrder)
                .map((question: any) => ({
                  ...question,
                  questionOptions: question.questionOptions?.sort(
                    (a: any, b: any) => a.questionOptionOrder - b.questionOptionOrder,
                  ),
                })),
            },
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchApplicationDefinitionOverviewById(
  bearer: string | undefined,
  applicationDefinitionId: number,
): Promise<{ applicationDefinition: ApplicationDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationDefinition/Overview/${applicationDefinitionId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { applicationDefinitionDetail } = response.data;
        resolve({
          applicationDefinition: {
            isSDGsVisible: response.data.isSDGsVisible,
            isDevelopmentStageVisible: response.data.isDevelopmentStageVisible,
            ...response.data.applicationDefinitionDetail,
            communityLogo: applicationDefinitionDetail.communityLogoImage || '',
            communityCover: applicationDefinitionDetail.communityMainImage || '',
            applicationDefinitionCriterias: applicationDefinitionDetail.applicationDefinitionCriterias.map(
              (criteria: any) => criteria.evaluationCriteria,
            ),
            form: {
              ...applicationDefinitionDetail.form,
              questions: (applicationDefinitionDetail.form?.questions || [])
                ?.sort((a: any, b: any) => a.questionOrder - b.questionOrder)
                .map((question: any) => ({
                  ...question,
                  questionOptions: question.questionOptions?.sort(
                    (a: any, b: any) => a.questionOptionOrder - b.questionOptionOrder,
                  ),
                })),
            },
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function saveApplicationEntry(
  bearer: string,
  applicationEntry: ApplicationEntry,
  submitApplication: boolean,
): Promise<{ applicationEntry: ApplicationEntry }> {
  return new Promise((resolve, reject) => {
    ai({
      url: `/api/Application` + (applicationEntry.id ? `/${applicationEntry.id}` : ''),
      method: applicationEntry.id ? 'PUT' : 'POST',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        applicationDefinitionId: applicationEntry.applicationDefinitionId,
        projectName: applicationEntry.project?.name || '',
        projectLogoImage: applicationEntry.project?.logo || '',
        projectMainImageOrVideo: applicationEntry.project?.cover || '',
        projectDescription: applicationEntry.project?.description || '',
        projectCategoryId: applicationEntry.project?.categoryId || 1,
        projectDevelopmentStage: applicationEntry.project?.developmentStage || '',
        websiteUrl: applicationEntry.project?.websiteUrl || '',
        facebookUrl: applicationEntry.project?.facebookUrl || '',
        linkedInUrl: applicationEntry.project?.linkedInUrl || '',
        projectCountryId: applicationEntry.project?.countryId || 1,
        projectCity: applicationEntry.project?.city || '',
        projectAnswers: applicationEntry.projectAnswers || [],
        applicationProjectSustainableDevelopmentGoals:
          applicationEntry.project?.SDGs.map((sdg: number) => ({ sustainableDevelopmentGoalId: sdg })) || [],
        existingProjectId: applicationEntry.existingProjectId ?? null,
      },
    })
      .then((response: AxiosResponse) => {
        saveApplicationAnswers(bearer, response.data.id, applicationEntry.formAnswers).then(
          (answersResponse: { answers: FormQuestionAnswer[] }) => {
            if (response.data.status !== ApplicationStatus.Apply && response.data.status !== ApplicationStatus.Draft)
              resolve({
                applicationEntry: {
                  ...response.data,
                  formAnswers: answersResponse.answers,
                },
              });
            _updateApplicationEntryStatus(
              bearer,
              response.data.id,
              submitApplication ? ApplicationStatus.Pending : applicationEntry.status || ApplicationStatus.Draft,
            )
              .then(() => {
                resolve({
                  applicationEntry: {
                    ...response.data,
                    formAnswers: answersResponse.answers,
                    status: submitApplication ? ApplicationStatus.Pending : applicationEntry.status,
                  },
                });
              })
              .catch(err => reject(err));
          },
        );
      })
      .catch(err => reject(err));
  });
}

function _saveApplicationAnswers(
  bearer: string,
  applicationId: number,
  answers: FormQuestionAnswer[],
  method: 'PUT' | 'POST',
): Promise<{ answers: FormQuestionAnswer[] }> {
  return new Promise((resolve, reject) => {
    if (answers.length === 0) {
      resolve({
        answers: [],
      });
      return;
    }
    ai({
      method,
      url: `/api/Application/${applicationId}/Answers`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: answers.map((answer: FormQuestionAnswer) => {
        if (method === 'POST') {
          const ans: any = answer;
          delete ans.id;
          return ans;
        }
        return answer;
      }),
    })
      .then((response: AxiosResponse) => {
        resolve({
          answers: response.data.list,
        });
      })
      .catch(err => reject(err));
  });
}

export function saveApplicationAnswers(
  bearer: string,
  applicationId: number,
  answers: FormQuestionAnswer[],
): Promise<{ answers: FormQuestionAnswer[]; applicationId: number }> {
  return new Promise((resolve, reject) => {
    answers = answers.map((answer: FormQuestionAnswer) => ({
      ...answer,
      answer: typeof answer.answer === 'string' ? removeMultipleNewLines(answer.answer) : answer.answer,
    }));
    const newAnswers = answers.filter((answer: FormQuestionAnswer) => answer.id === 0);
    const oldAnswers = answers.filter((answer: FormQuestionAnswer) => answer.id !== 0);
    const resolvedAnswers: FormQuestionAnswer[] = [];

    // Updated answers must be saved first, before adding new answers. Otherwise it can
    // create a race condition where the new answers are overriden by the PUT request
    // and removed from the database
    _saveApplicationAnswers(bearer, applicationId, oldAnswers, 'PUT')
      .then(res => {
        resolvedAnswers.push(...res.answers);
        return _saveApplicationAnswers(bearer, applicationId, newAnswers, 'POST');
      })
      .then(res => {
        resolvedAnswers.push(...res.answers);

        resolve({
          applicationId,
          answers: resolvedAnswers,
        });
      })
      .catch(err => reject(err));
  });
}

const _mapResponseToApplicationEntry = (data: any): ApplicationEntry => {
  return {
    id: data.id,
    applicationDefinitionId: data.applicationDefinitionId,
    applicationDefinitionName: data.applicationDefinitionName,
    formAnswers: data.answers?.list || [],
    projectAnswers: data.projectAnswers || [],
    project: {
      communityId: 0,
      name: data.projectName || '',
      logo: data.projectLogoImage || '',
      cover: data.projectMainImageOrVideo || '',
      description: data.projectDescription || '',
      categoryId: data.projectCategoryId || 1,
      developmentStage: data.projectDevelopmentStage || '',
      methodologyId: 0,
      SDGs:
        data.applicationProjectSustainableDevelopmentGoals?.map(
          (sdg: { sustainableDevelopmentGoalId: string }) => sdg.sustainableDevelopmentGoalId,
        ) || [],
      countryId: data.projectCountryId || 2,
      city: data.projectCity || '',
      websiteUrl: data.websiteUrl || '',
      facebookUrl: data.facebookUrl || '',
      linkedInUrl: data.linkedInUrl || '',
    },
    status: data.status,
    applicantId: data.applicantId,
    applicantEmail: data.applicantEmail || '',
    applicantName: data.applicantName || '',
    applicantPhotoUrl: data.applicantPhotourl || '',
    averageScore: data.averageScore || undefined,
    applyDate: moment(data.applyDate).format('DD/MM/YYYY'),
    communityName: data.communityName || undefined,
    communityLogoImage: data.communityLogoImage || undefined,
    comments: data.comments?.list.map((c: any) => {
      return {
        id: c.id,
        type: c.itemType,
        date: c.lastUpdated,
        commentCount: c.commentCount,
        likeCount: c.likeCount,
        isLiked: c.userLikesComment,
        content: mentionParse(c.content),
        comments: c.comments.map((c1: any) => ({
          id: c1.id,
          type: c.itemType,
          date: c1.lastUpdated,
          commentCount: c1.commentCount,
          likeCount: c1.likeCount,
          isLiked: c1.userLikesComment,
          content: mentionParse(c1.content),
          comments: c1.comments,
          author: {
            id: c1.authorId,
            name: c1.authorName,
            photo: sanitizeAssetUrl(c1.authorPhoto),
            occupation: c1.authorOccupation || '',
          },
        })),
        author: {
          id: c.authorId,
          name: c.authorName,
          photo: sanitizeAssetUrl(c.authorPhoto),
          occupation: c.authorOccupation || '',
        },
      };
    }),
    tags: data.tags || [],
    scores:
      data.scores?.list.map((score: any) => ({
        userId: score.userId,
        userName: score.user.name,
        userPhotoUrl: score.user.photoUrl,
        criteria: score.criteria,
        score: score.score,
      })) || [],
    existingProjectId: data.existingProjectId,
  };
};

function _fetchApplicationEntryById(
  bearer: string,
  applicationEntryId: number,
): Promise<{ applicationEntry: ApplicationEntry }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Application/${applicationEntryId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          applicationEntry: _mapResponseToApplicationEntry(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

function _fetchApplicationEntryAnswers(
  bearer: string,
  applicationEntryId: number,
): Promise<{ answers: FormQuestionAnswer[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Application/${applicationEntryId}/Answers`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          answers: response.data.list,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchEvaluationAnswersByApplicationEntryId(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntryId: number;
  formAnswers: FormQuestionAnswer[];
}> {
  return new Promise((resolve, reject) => {
    _fetchApplicationEntryAnswers(bearer, applicationEntryId)
      .then((response: { answers: FormQuestionAnswer[] }) => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          formAnswers: response.answers,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchApplicationEntryById(
  bearer: string,
  applicationEntryId: number,
): Promise<{ applicationEntry: ApplicationEntry }> {
  return new Promise((resolve, reject) => {
    _fetchApplicationEntryById(bearer, applicationEntryId)
      .then(response => {
        resolve({
          applicationEntry: response.applicationEntry,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCurrentUserApplications(bearer: string): Promise<{ applicationEntries: ApplicationEntry[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Application/GetCurrentUserApplications`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          applicationEntries: response.data.list.map(_mapResponseToApplicationEntry).reverse(),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchApplicationEntriesByDefinitionId(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  filtering: Partial<FilterAnswer>,
  sorting: Sorting,
  skip: number,
  take: number,
  concept: string | null,
  status: ApplicationStatus | null,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntries: ApplicationEntry[];
  count: number;
  applicationDefinitionFilters: ApplicationFilters;
}> {
  interface AdditionalProperties {
    status?: ApplicationStatus | null;
    concept?: string | null;
    skip: number;
    take: number;
  }
  return new Promise((resolve, reject) => {
    const filters: Partial<ApplicationFilters> = cleanFilters(filtering);
    const data: Partial<ApplicationFilters> & AdditionalProperties & Sorting = {
      ...filters,
      skip,
      take,
      ...sorting,
      status,
      concept,
    };
    if (!data.status || data.status === null) delete data.status;
    if (!data.concept || data.concept === null) delete data.concept;
    if (sorting.orderBy) data.orderBy = sorting.orderBy - 1;
    ai({
      method: 'POST',
      url: `/api/ApplicationDefinition/${applicationDefinitionId}/Applications`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then((response: AxiosResponse) => {
        const applicationFilters = response.data.applicationDefinitionFilters;
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntries: response.data.list.map(_mapResponseToApplicationEntry),
          count: response.data.count,
          applicationDefinitionFilters: {
            countries: applicationFilters.applicationCountries
              ? applicationFilters.applicationCountries.sort((a: any, b: any) => a.name.localeCompare(b.name))
              : null,
            industries: applicationFilters.applicationIndustries,
            tags: applicationFilters.applicationTags,
            interests: applicationFilters.applicationUserInterests,
            sdgs: applicationFilters.applicationSDGs,
            filters: response.data.filters || [],
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function saveApplicationEntryTags(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
  tags: string[],
): Promise<{ communityId: number; applicationDefinitionId: number; applicationEntryId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Application/${applicationEntryId}/SaveTags`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        tags: tags,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          tags,
        });
      })
      .catch(err => reject(err));
  });
}

export function _saveApplicationEntryScores(
  bearer: string,
  applicationEntryId: number,
  scores: ApplicationScore[],
): Promise<{
  scores: ApplicationScore[];
}> {
  return new Promise((resolve, reject) => {
    if (scores.length === 0) {
      resolve({
        scores: [],
      });
      return;
    }
    ai({
      method: scores[0].userName.length ? 'PUT' : 'POST',
      url: `/api/Application/${applicationEntryId}/Scores`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: scores.map((score: ApplicationScore) => ({
        criteria: score.criteria,
        score: score.score,
      })),
    })
      .then((response: AxiosResponse) => {
        resolve({
          scores: response.data.list.map((score: any) => ({
            userId: score.userId,
            userName: score.user.name,
            userPhotoUrl: score.user.photoUrl,
            criteria: score.criteria,
            score: score.score,
          })),
        });
      })
      .catch(err => reject(err));
  });
}

export function saveApplicationEntryScores(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
  scores: ApplicationScore[],
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntryId: number;
  scores: ApplicationScore[];
}> {
  return new Promise((resolve, reject) => {
    const scoresToUpdate = scores.filter((score: ApplicationScore) => score.userName.length === 0);
    const scoresToCreate = scores.filter((score: ApplicationScore) => score.userName.length !== 0);
    Promise.all([
      _saveApplicationEntryScores(bearer, applicationEntryId, scoresToCreate),
      _saveApplicationEntryScores(bearer, applicationEntryId, scoresToUpdate),
    ])
      .then((responses: { scores: ApplicationScore[] }[]) => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          scores: [...responses[0].scores, ...responses[1].scores],
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchApplicationEntryScores(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntryId: number;
  scores: ApplicationScore[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Application/${applicationEntryId}/Scores`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          scores: response.data.list.map((score: any) => ({
            userId: score.userId,
            userName: score.user.name,
            userPhotoUrl: score.user.photoUrl,
            criteria: score.criteria,
            score: score.score,
          })),
        });
      })
      .catch(err => reject(err));
  });
}

export async function updateApplicationEntryStatus(
  bearer: string,
  communityId: number | undefined,
  applicationDefinitionId: number | undefined,
  applicationEntryIds: number[],
  status: ApplicationStatus,
  methodologyId: number | undefined = undefined,
  newProjects?: (NewProject | undefined)[],
  applicantEmails?: (string | undefined)[],
): Promise<{
  communityId?: number;
  applicationDefinitionId?: number;
  applicationEntryIds: number[];
  status: ApplicationStatus;
}> {
  const successfulIds: number[] = [] as number[];
  for (let entryIndex = 0; entryIndex < applicationEntryIds.length; entryIndex++) {
    try {
      await _updateApplicationEntryStatus(
        bearer,
        applicationEntryIds[entryIndex],
        status,
        methodologyId,
        (newProjects && newProjects[entryIndex]) || undefined,
        (applicantEmails && applicantEmails[entryIndex]) || undefined,
      );
      successfulIds.push(applicationEntryIds[entryIndex]);
    } catch (err: any) {
      toastException(err);
    }
  }
  if (successfulIds.length === 0) throw new Error('All changes failed');
  return {
    communityId,
    applicationDefinitionId,
    applicationEntryIds: successfulIds,
    status,
  };
}

export function fetchApplicationFilters(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  tags: string[];
  countries: Country[];
  industries: Interest[];
  interests: Interest[];
  sdgs: SDG[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationDefinition/${applicationDefinitionId}/ApplicationsFilters`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinitionId,
          tags: response.data.applicationTags,
          countries: response.data.applicationCountries,
          industries: response.data.applicationIndustries,
          interests: response.data.applicationUserInterests,
          sdgs: response.data.applicationSDGs.map((sdg: SDG) => ({
            ...sdg,
            iconName: `/Images/branding.babele/SDGs/Icons_Square/${sdg.iconName}.png`,
          })),
        });
      })
      .catch(err => reject(err));
  });
}

export function downloadApplicationCsv(bearer: string, applicationDefinitionId: number): Promise<any> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationDefinition/${applicationDefinitionId}/Applications-csv`,
      headers: {
        Authorization: `Bearer ${bearer}`,
        Accept: 'text/csv',
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function downloadProjectOverviewCsv(
  bearer: string,
  CommunityId: number,
  Concept?: string,
  CategoryId?: number,
  CountryId?: number,
  Tags?: string[],
  SustainableDevelopmentGoalsIds?: number[],
): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ProjectTracking/ExportToCsv`,
      headers: {
        Authorization: `Bearer ${bearer}`,
        Accept: 'text/csv',
      },
      params: {
        CommunityId,
        Concept,
        CountryId,
        CategoryId,
        Tags,
        SustainableDevelopmentGoalsIds,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function downloadApplicationEvaluations(bearer: string, applicationDefinitionId: number): Promise<any> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationDefinition/${applicationDefinitionId}/ApplicationEvaluations-csv`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

const _mapResponseToComment = (data: any) => ({
  id: data.id,
  author: {
    id: data.authorId,
    name: data.authorName,
    photo: data.authorPhoto,
    occupation: data.authorOccupation || '',
  },
  date: data.lastUpdated,
  content: mentionParse(data.content),
  parentId: data.parentId,
  likeCount: data.likeCount,
  isLiked: data.userLikesComment,
  comments: (data.comments || []).map(_mapResponseToComment),
  canUserEdit: data.canUserEdit,
  canUserRemove: data.canUserRemove,
  userLikesComment: data.userLikesComment,
  userDislikesComment: data.userDislikesComment,
});

export function saveCommentToApplicationEntry(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
  parentId: number,
  content: string,
  id: number,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntryId: number;
  parentId: number;
  comment: Comment;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Comment/SaveApplicationComment`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        applicationId: applicationEntryId,
        id: id,
        parentId: parentId,
        content: content,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          parentId: response.data.parentId,
          comment: _mapResponseToComment(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchApplicationEntryComments(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntryId: number;
  comments: Comment[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Application/${applicationEntryId}/Comments`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          comments: response.data.list.map(_mapResponseToComment),
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteApplicationEntryComment(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
  commentId: number,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntryId: number;
  commentId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: '/api/Comment/RemoveApplicationComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: commentId,
      },
    })
      .then(() => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          commentId,
        });
      })
      .catch(err => reject(err));
  });
}

export function likeApplicationEntryComment(
  bearer: string,
  communityId: number,
  applicationDefinitionId: number,
  applicationEntryId: number,
  commentId: number,
  isLike: boolean,
): Promise<{
  communityId: number;
  applicationDefinitionId: number;
  applicationEntryId: number;
  commentId: number;
  isLike: boolean;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Comment/${isLike ? 'Like' : 'Unlike'}ApplicationComment`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: commentId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          applicationDefinitionId,
          applicationEntryId,
          commentId,
          isLike,
        });
      })
      .catch(err => reject(err));
  });
}

export function acceptAllFilteredApplications(
  bearer: string,
  applicationDefinitionId: number,
  methodologyId: number | undefined,
  filtering: Partial<FilterAnswer>,
  skip: number,
  take: number,
  concept: string | null,
  status: ApplicationStatus | null,
): Promise<null> {
  interface AdditionalProperties {
    status?: ApplicationStatus | null;
    concept?: string | null;
    skip: number;
    take: number;
  }
  return new Promise((resolve, reject) => {
    const filters: Partial<ApplicationFilters> = cleanFilters(filtering);
    const data: Partial<ApplicationFilters> & AdditionalProperties & Sorting = {
      ...filters,
      skip,
      take,
      status,
      concept,
    };

    ai({
      method: 'POST',
      url: `/api/Application/${applicationDefinitionId}/GroupAcceptApplication?methodologyId=${methodologyId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function rejectAllFilteredApplications(
  bearer: string,
  applicationDefinitionId: number,
  filtering: Partial<FilterAnswer>,
  skip: number,
  take: number,
  concept: string | null,
  status: ApplicationStatus | null,
): Promise<null> {
  interface AdditionalProperties {
    status?: ApplicationStatus | null;
    concept?: string | null;
    skip: number;
    take: number;
  }
  return new Promise((resolve, reject) => {
    const filters: Partial<ApplicationFilters> = cleanFilters(filtering);
    const data: Partial<ApplicationFilters> & AdditionalProperties & Sorting = {
      ...filters,
      skip,
      take,
      status,
      concept,
    };

    ai({
      method: 'POST',
      url: `/api/Application/${applicationDefinitionId}/GroupRejectApplication`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function deleteAllFilteredApplications(
  bearer: string,
  applicationDefinitionId: number,
  filtering: Partial<FilterAnswer>,
  skip: number,
  take: number,
  concept: string | null,
  status: ApplicationStatus | null,
): Promise<null> {
  interface AdditionalProperties {
    status?: ApplicationStatus | null;
    concept?: string | null;
    skip: number;
    take: number;
  }
  return new Promise((resolve, reject) => {
    const filters: Partial<ApplicationFilters> = cleanFilters(filtering);
    const data: Partial<ApplicationFilters> & AdditionalProperties & Sorting = {
      ...filters,
      skip,
      take,
      status,
      concept,
    };

    ai({
      method: 'DELETE',
      url: `/api/Application/${applicationDefinitionId}/GroupDeleteApplication`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export default {
  saveApplicationDefinition,
  fetchCommunityApplicationDefinitions,
  deleteApplicationDefinition,
  fetchApplicationDefinitionById,
  saveApplicationEntry,
  fetchApplicationEntryById,
  fetchCurrentUserApplications,
  fetchApplicationEntriesByDefinitionId,
  fetchEvaluationAnswersByApplicationEntryId,
  saveApplicationEntryTags,
  saveApplicationEntryScores,
  fetchApplicationEntryScores,
  updateApplicationEntryStatus,
  fetchApplicationFilters,
  downloadApplicationCsv,
  saveCommentToApplicationEntry,
  fetchApplicationEntryComments,
  deleteApplicationEntryComment,
  likeApplicationEntryComment,
  deleteApplicationEntries,
  fetchApplicationDefinitionOverviewById,
  fetchApplicationDefinitionPreviewById,
};
