import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { SidebarItem, SidebarList, SidebarContainer } from '../styled-components/sidebar';
import { Separator } from '../styled-components/common';

const mapStateToProps = (state: RootState) => ({
  notifications: state.account.details.notifications,
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  style?: React.CSSProperties;
}

const CommunityTitle: React.FC = props => {
  const { children } = props;
  if (children) {
    if (React.Children.count(children) > 13) {
      return <h3 style={{ lineHeight: '1.5em' }}>{children}</h3>;
    }
    return <h2 style={{ lineHeight: '1.5em' }}>{children}</h2>;
  } else {
    return null;
  }
};

const CommunitySidebar: React.FC<Props> = props => {
  const { style } = props;
  return (
    <SidebarContainer style={{ ...style }}>
      <CommunityTitle>Super admin panel</CommunityTitle>
      <SidebarList>
        <Separator />
        <SidebarItem to={`/super-admin/communities`} hasNotifications={false}>
          Communities
        </SidebarItem>
        <SidebarItem to={`/super-admin/projects`} hasNotifications={false}>
          Projects
        </SidebarItem>
        <SidebarItem to={`/super-admin/users`} hasNotifications={false}>
          Users
        </SidebarItem>
        <SidebarItem to={`/super-admin/methodologies`} hasNotifications={false}>
          Methodologies
        </SidebarItem>
        <SidebarItem to={`/super-admin/statistics`} hasNotifications={false}>
          Statistics
        </SidebarItem>
      </SidebarList>
    </SidebarContainer>
  );
};

export default connect(mapStateToProps, {})(CommunitySidebar);
