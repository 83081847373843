/* eslint-disable react/display-name */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import { resetSession } from '../../redux/actions/session';
import { fetchMyStuffAsync, fetchCurrentUserAsync, saveProfileAsync } from '../../redux/actions/account';
import { fetchUserProfileAsync } from '../../redux/actions/users';
import { fetchCurrentUserApplicationsAsync } from '../../redux/actions/application-manager';
import NavigationHeader from '../../components/common/modal-navigation';
import UserCard from '../../components/common/user-card';
import AccountTab from '../../components/account/account';
import MyStuffTab from '../../components/account/my-stuff';
import ProfileTab from '../../components/account/profile';
import NotificationsTab from '../../components/account/notifications';
import { Languages } from '../../redux/types/enums';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const ProfileContainer = styled.div<{ panesCount: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40em;
  height: 100%;

  .ui.secondary.menu .item {
    width: ${({ panesCount }) => 100 / panesCount}%;
    display: flex;
    justify-content: center;
    font-size: 1.2em;
  }

  .active {
    color: ${({ theme }) => theme.colors.blue.normal} !important;
    border-color: ${({ theme }) => theme.colors.blue.normal} !important;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    padding: 0 1em;
    width: 100%;
  }
`;

const StyledTabBar = styled(Tab)`
  width: 100%;
  height: 80%;
  margin-top: 1em;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    margin-top: 0;
    font-size: 1.6em;

    .ui.secondary.pointing.menu {
      font-size: 0.8em;
    }
  }
`;

const mapDispatchToProps = {
  resetSession,
  fetchMyStuff: fetchMyStuffAsync.request,
  fetchCurrentUser: fetchCurrentUserAsync.request,
  fetchUserProfile: fetchUserProfileAsync.request,
  saveProfile: saveProfileAsync.request,
  fetchApplications: fetchCurrentUserApplicationsAsync.request,
};

const mapStateToProps = (state: RootState) => ({
  account: state.account.details.user,
  accountApplications: state.account.applications,
  communities: state.account.communities.list,
  bearer: state.account.session.session.bearer,
  isFetching: state.loading.fetchMyStuffFlag,
  literals: state.literals,
});

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const Account: React.FC<Props> = ({
  resetSession,
  account,
  communities,
  fetchMyStuff,
  bearer,
  isFetching,
  fetchCurrentUser,
  fetchUserProfile,
  saveProfile,
  literals,
  accountApplications,
  fetchApplications,
}) => {
  const history = useHistory();
  const [firstUserDataFetched, setFirstUserDataFetched] = useState<boolean>(false);
  const { tab } = useParams<{ tab: string }>();
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (activeTab === 0) {
      fetchApplications({
        bearer,
      });
    }
  }, [activeTab]);

  const changeLanguage = (language: Languages) => {
    saveProfile({
      bearer,
      rawUser: account.rawUser,
      userId: account.id,
      country: account.countryId,
      city: account.city,
      role: account.occupation,
      bio: account.profile.biography,
      file: null,
      photo: account.photo,
      firstName: account.firstName,
      lastName: account.lastName,
      interests: account.profile.interests.map((c: { id: number }) => c.id),
      skills: account.profile.skills.map((c: { id: number }) => c.id),
      language: language,
      marketExpertises: account.marketExpertises,
      sustainableDevelopmentGoals: account.sustainableDevelopmentGoals,
      primaryCommunityId: account.primaryCommunityId,
      company: account.company,
    });
  };

  useEffect(() => {
    // if (account.profileWizardDone !== undefined && !firstUserDataFetched) {
    if (!firstUserDataFetched) {
      setFirstUserDataFetched(true);
      fetchMyStuff({ bearer });
      fetchCurrentUser(bearer);
      if (account.id) fetchUserProfile({ bearer, id: account.id });
    }
  }, [account]);

  useEffect(() => {
    const tabIndex = panes.findIndex(pane => pane.key === tab);
    if (tabIndex >= 0) {
      setActiveTab(tabIndex);
    }
  }, [tab]);

  const signOut = () => {
    window.localStorage.clear();
    resetSession();
    history.push('/login');
  };

  let panes = [
    {
      menuItem: literals.menu_my_stuff,
      key: 'mystuff',
      render: () => (
        <MyStuffTab
          bearer={bearer}
          key="mystuff"
          account={account}
          applications={accountApplications || []}
          isFetching={isFetching}
        />
      ),
    },
    {
      menuItem: literals.menu_my_profile,
      key: 'profile',
      render: () => {
        if (!account.profile) {
          return <span>{literals.account_not_found_message}</span>;
        }
        return <ProfileTab key="profile" profile={{ ...account.profile, company: account.company }} />;
      },
    },
    {
      menuItem: literals.user_ctrl_initializer_menu_notifications,
      key: 'notifications',
      render: () => <NotificationsTab key="notifications" />,
    },
    {
      menuItem: literals.user_ctrl_initializer_menu_setting,
      key: 'settings',
      render: () => (
        <AccountTab
          key="settings"
          changeLanguage={changeLanguage}
          account={account}
          bearer={bearer}
          signOut={signOut}
          isFetching={isFetching}
        />
      ),
    },
  ];

  if (account.admin) {
    panes = [
      ...panes,
      {
        menuItem: 'Super admin',
        key: 'super-admin',
        render: () => <Redirect to="/super-admin/communities" />,
      },
    ];
  }

  const handleTabChange = (event: SyntheticEvent, data: any) => {
    if (panes[data.activeIndex]) history.push(`/account/${panes[data.activeIndex].key}`);
    // setActiveTab(data.activeIndex);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{literals.page_title_manage_your_account}</title>
      </Helmet>
      <Container>
        <NavigationHeader />
        <ProfileContainer panesCount={panes.length}>
          <UserCard profile={{ ...account }} showAccountButtons={true} signOut={signOut} />
          <StyledTabBar
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            activeIndex={activeTab}
            onTabChange={handleTabChange}
            className="babele-account-menu"
          />
        </ProfileContainer>
      </Container>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
