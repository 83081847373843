import React, { useState } from 'react';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import { Modal, Input } from 'semantic-ui-react';
import { SecondaryButton } from '../styled-components/common';
import { MyStuffItem, Circle } from 'redux/types/account';
import styled from 'styled-components';
import Image from '../common/lazyImage';
import { fetchProjectCircles, inviteProjectMemberViaId, editProjectCircle } from '../../services/api/project';
import { AxiosError } from 'axios';
import { Flag } from '../../redux/types/enums';
import { toastException } from '../../redux/epics/helpers';
import { StyledButton } from 'primitives/Button/style';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0em;
`;

const StyledImage = styled(Image)`
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
`;

const ProjectsContainer = styled.div`
  max-height: 20em;
  overflow: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  &:hover {
    cursor: auto;
  }
  &&& {
    margin-left: auto;
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  currentUserOwnedProjects: state.account.details.user.ownedProjects,
  bearer: state.account.session.session.bearer,
});

const mapDispatchToProps = {};

type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  closeModal: () => void;
  userName: string;
  userId: number;
  teamMemberProjects: { [id: number]: string };
}

const InviteToProjectModal: React.FC<Props> = props => {
  const { currentUserOwnedProjects, teamMemberProjects, bearer, literals } = props;

  const [search, setSearch] = useState<string>('');
  const [newUserInvitedProjects, setNewUserInvitedProjects] = useState<{ [id: number]: Flag }>({});

  const setInvitedFlag = (projectId: number, flag: Flag) => {
    setNewUserInvitedProjects(invitedProjects => ({ ...invitedProjects, [projectId]: flag }));
  };

  const handleChangeCircle = (projectId: number) => {
    setInvitedFlag(projectId, Flag.Request);
    fetchProjectCircles(bearer, projectId).then(({ circles }: { circles: Circle[] }) => {
      const teamCircle = circles.find((c: Circle) => c.name === 'Team');
      if (teamCircle) {
        editProjectCircle(projectId, bearer, teamCircle.id.toString(), props.userId.toString())
          .then(() => {
            setInvitedFlag(projectId, Flag.Success);
          })
          .catch(() => {
            setInvitedFlag(projectId, Flag.Failure);
          });
      } else {
        setInvitedFlag(projectId, Flag.Failure);
      }
    });
  };

  const handleInvite = (projectId: number) => {
    setInvitedFlag(projectId, Flag.Request);
    fetchProjectCircles(bearer, projectId)
      .then(({ circles }: { circles: Circle[] }) => {
        const teamCircle = circles.find((c: Circle) => c.name === 'Team');
        if (teamCircle) {
          inviteProjectMemberViaId(bearer, projectId, {
            userId: props.userId,
            circleId: teamCircle.id,
            inviteAlsoToProjectCircleId: teamCircle.id,
          })
            .then(() => {
              setInvitedFlag(projectId, Flag.Success);
            })
            .catch((err: AxiosError) => {
              setInvitedFlag(projectId, Flag.Failure);
              toastException(err);
            });
        } else {
          setInvitedFlag(projectId, Flag.Failure);
        }
      })
      .catch((err: AxiosError) => {
        setInvitedFlag(projectId, Flag.Failure);
        toastException(err);
      });
  };

  return (
    <Modal open={true} style={{ maxWidth: '35em' }}>
      <Modal.Header>{(literals.invite_user_to_project || '').replace('{0}', props.userName)}</Modal.Header>
      <Modal.Content>
        <Input
          placeholder={literals.generic_search}
          value={search}
          onChange={(evt: React.SyntheticEvent, data: any) => setSearch(data.value.toLowerCase())}
          style={{ width: '100%' }}
        />

        <ProjectsContainer>
          {currentUserOwnedProjects
            .filter((project: MyStuffItem) => project.name.toLowerCase().includes(search))
            .map((project: MyStuffItem) => (
              <Row key={project.id} style={{ borderBottom: '1px solid #eee' }}>
                <StyledImage src={project.logo || project.logoImage} />
                <span>{project.name}</span>
                {(newUserInvitedProjects[project.id] === Flag.Success && (
                  <StyledSecondaryButton>{literals.user_invited}</StyledSecondaryButton>
                )) ||
                  (newUserInvitedProjects[project.id] === Flag.Request && (
                    <StyledButton loading={true} style={{ marginLeft: 'auto' }}>
                      {literals.user_invite}
                    </StyledButton>
                  )) ||
                  (teamMemberProjects[project.id] === 'Team' && (
                    <StyledSecondaryButton>{literals.user_already_team_member}</StyledSecondaryButton>
                  )) ||
                  (teamMemberProjects[project.id] === 'Follower' && (
                    <StyledButton onClick={() => handleChangeCircle(project.id)} style={{ marginLeft: 'auto' }}>
                      {literals.user_invite}
                    </StyledButton>
                  )) || (
                    <StyledButton onClick={() => handleInvite(project.id)} style={{ marginLeft: 'auto' }}>
                      {literals.user_invite}
                    </StyledButton>
                  )}
              </Row>
            ))}
        </ProjectsContainer>
        <Row>
          <SecondaryButton onClick={() => props.closeModal()}>{literals.global_close}</SecondaryButton>
        </Row>
      </Modal.Content>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteToProjectModal);
