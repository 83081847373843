import { createAsyncAction, createAction } from 'typesafe-actions';
import { ImageType } from '../types/enums';
import { FormQuestion } from 'redux/types/account';

export const uploadFileAsync = createAsyncAction(
  'UPLOAD_FILE_REQUEST',
  'UPLOAD_FILE_SUCCESS',
  'UPLOAD_FILE_FAILURE',
  'UPLOAD_FILE_IDLE',
)<
  { bearer: string; file: File; communityId: number; folder: string; imageType?: ImageType },
  { id: number; link: string },
  string,
  null
>();

export const selectFile = createAction('SELECT_FILE')<{ file: File | null; question: FormQuestion }>();

export const clearSelectedFile = createAction('CLEAR_SELECTED_FILE')();

export default {
  uploadFileAsync,
  selectFile,
  clearSelectedFile,
};
