import React, { forwardRef, memo } from 'react';
import { array, number, object, oneOf, oneOfType, string } from 'prop-types';
import { StackStyled } from './style';

type Props = {
  direction?: string;
  style?: any;
  spacing?: number;
  children?: any;
  className?: string;
};

/**
 * The Stack component manages layout of immediate children along the vertical
 *  or horizontal axis with optional spacing and/or dividers between each child.
 */
const Stack = forwardRef(({ direction, children, spacing, style, className }: Props, ref) => (
  <StackStyled
    ref={ref}
    className={className}
    style={{
      flexFlow: direction,
      gap: spacing,
      ...style,
    }}
  >
    {children}
  </StackStyled>
));

Stack.propTypes = {
  direction: oneOf(['row', 'column', 'column-reverse', 'row-reverse']),
  children: oneOfType([string, array, object]),
  spacing: number,
  className: string,
  style: object,
};

Stack.defaultProps = {
  direction: 'column',
  spacing: 0,
  style: {},
};

Stack.displayName = 'Stack';

export default memo(Stack);
