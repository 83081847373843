import {
  Literals,
  Form,
  FormQuestion,
  FormQuestionAnswer,
  FormQuestionOption,
  QuestionRule,
  FormQuestionConditionalLogic,
  FormQuestionConditionalLogicType,
} from 'redux/types/account';
import { FormQuestionAnswerType } from 'redux/types/enums';

export const filterAnswersByConditional = (
  answers: { [formQuestionId: number]: (FormQuestionAnswer & { questionOptionId?: number })[] },
  questions: FormQuestion[],
  conditionalLogic: QuestionRule[] | undefined,
) => {
  const filteredAnswers = {} as { [formQuestionId: number]: FormQuestionAnswer[] };

  conditionalLogic?.forEach(({ conditionFormQuestionId }) => {
    const currentQuestion = questions.find(({ id }) => conditionFormQuestionId === id);
    if (answers[conditionFormQuestionId]) {
      const updatedAnswer = answers[conditionFormQuestionId].filter(
        entry => entry.formQuestionId === conditionFormQuestionId,
      );

      filteredAnswers[conditionFormQuestionId] = updatedAnswer.map(answer => {
        const questionRelatedToAnswer = currentQuestion?.questionOptions?.find(
          ({ questionOptionOrder }) => questionOptionOrder === answer.optionAnswerOrder,
        );

        return {
          ...answer,
          questionOptionId: questionRelatedToAnswer?.id,
        };
      });
    }
  });

  return filteredAnswers;
};

const validateCondition = (
  answers: { [formQuestionId: number]: (FormQuestionAnswer & { questionOptionId?: number })[] },
  rule: QuestionRule,
): boolean => {
  const answerPerQuestion = answers[rule.conditionFormQuestionId];
  const { conditionLogic, customValue, formQuestionOptionId } = rule;

  const checkAnswer = (predicate: (answer: string | number) => boolean) =>
    answerPerQuestion?.some(({ answer, optionAnswerOrder, questionOptionId }) => {
      if (answer) {
        return predicate(answer);
      } else if (!isNaN(optionAnswerOrder) && questionOptionId) {
        return predicate(`${optionAnswerOrder}`);
      }
      return false;
    });

  const checkOption = (predicate: (questionOptionId: number) => boolean) =>
    answerPerQuestion?.some(({ questionOptionId }) => questionOptionId !== undefined && predicate(questionOptionId));

  switch (conditionLogic) {
    case FormQuestionConditionalLogic.EQUAL:
      return customValue
        ? checkAnswer(answer => answer === customValue)
        : checkOption(optionId => optionId === Number(formQuestionOptionId));

    case FormQuestionConditionalLogic.NOT_EQUAL:
      return customValue
        ? !checkAnswer(answer => answer === customValue)
        : !checkOption(optionId => optionId === Number(formQuestionOptionId));

    case FormQuestionConditionalLogic.GREATER_THAN:
      return !isNaN(Number(customValue))
        ? checkAnswer(answer => {
            if (!isNaN(Number(answer))) {
              return Number(customValue) < Number(answer);
            }
            return true;
          })
        : false;

    case FormQuestionConditionalLogic.LESS_THAN:
      return !isNaN(Number(customValue))
        ? checkAnswer(answer => {
            if (!isNaN(Number(answer))) {
              return Number(customValue) > Number(answer);
            }
            return true;
          })
        : false;

    case FormQuestionConditionalLogic.CONTAINS:
      if (typeof customValue === 'string') {
        return checkAnswer(answer => typeof answer === 'string' && answer.includes(customValue));
      }
      return false;

    case FormQuestionConditionalLogic.DOES_NOT_CONTAIN:
      if (typeof customValue === 'string') {
        return checkAnswer(answer => typeof answer === 'string' && !answer.includes(customValue));
      }
      return false;

    case FormQuestionConditionalLogic.EMPTY:
      return customValue ? checkAnswer(answer => !answer) : checkOption(optionId => !optionId);

    case FormQuestionConditionalLogic.NOT_EMPTY:
      return customValue ? checkAnswer(answer => !!answer) : checkOption(optionId => !!optionId);

    default:
      return true;
  }
};

export const validateConditionalRules = (
  answers: { [formQuestionId: number]: (FormQuestionAnswer & { questionOptionId?: number })[] },
  conditionalLogic: QuestionRule[] | undefined,
  conditionalLogicType: FormQuestionConditionalLogicType | undefined,
): boolean => {
  if (!conditionalLogic?.length) return true;

  if (conditionalLogicType === FormQuestionConditionalLogicType.AnyConditional) {
    return conditionalLogic.some(rule => validateCondition(answers, rule));
  }

  if (conditionalLogicType === FormQuestionConditionalLogicType.AllConditionals) {
    return conditionalLogic.every(rule => validateCondition(answers, rule));
  }

  return true;
};

export const getLogicList = ({ literals, answerType }: { literals: Literals; answerType?: FormQuestionAnswerType }) => {
  const LogicList = [
    { text: literals.global_empty, value: FormQuestionConditionalLogic.EMPTY, key: '7' },
    { text: literals.global_not_empty, value: FormQuestionConditionalLogic.NOT_EMPTY, key: '8' },
    { text: literals.global_equal, value: FormQuestionConditionalLogic.EQUAL, key: '1' },
    { text: literals.global_not_equal, value: FormQuestionConditionalLogic.NOT_EQUAL, key: '2' },
  ];

  switch (answerType) {
    case FormQuestionAnswerType.AccountMoneyAnswer:
    case FormQuestionAnswerType.ScaleAnswer:
      LogicList.push(
        { text: literals.global_greater_than, value: FormQuestionConditionalLogic.GREATER_THAN, key: '3' },
        { text: literals.global_less_than, value: FormQuestionConditionalLogic.LESS_THAN, key: '4' },
      );
      break;

    case FormQuestionAnswerType.LongTextAnswer:
    case FormQuestionAnswerType.ShortTextAnswer:
      LogicList.push(
        { text: literals.global_greater_than, value: FormQuestionConditionalLogic.GREATER_THAN, key: '3' },
        { text: literals.global_less_than, value: FormQuestionConditionalLogic.LESS_THAN, key: '4' },
        { text: literals.global_contains, value: FormQuestionConditionalLogic.CONTAINS, key: '5' },
        { text: literals.global_not_contain, value: FormQuestionConditionalLogic.DOES_NOT_CONTAIN, key: '6' },
      );
      break;
    default:
      break;
  }

  return LogicList;
};

export const prepareFormSaveStep1 = (form: Form) => {
  const initialForm = { ...form };

  // Fix for existing data where the last two question options have the same questionOptionOrder
  initialForm.questions.forEach(question => {
    const questionOptions = question.questionOptions;
    if (questionOptions?.length && questionOptions?.length > 1) {
      const lastOption = questionOptions[questionOptions.length - 1];
      const secondLastOption = questionOptions[questionOptions.length - 2];

      if (lastOption.questionOptionOrder === secondLastOption.questionOptionOrder) {
        lastOption.questionOptionOrder++;
      }
    }
  });

  // Prepare form for the first API step by removing conditional logic and setting new IDs
  return {
    ...initialForm,
    questions: initialForm.questions.map(({ formQuestionConditionalLogicList, id, isNew, ...rest }) => ({
      ...rest,
      formQuestionConditionalLogicList: [],
      id: isNew ? 0 : id,
      questionOptions: rest.questionOptions?.map(option => ({
        ...option,
        id: option.isNew ? 0 : option.id,
      })),
    })),
  };
};

export const prepareFormSaveStep2 = (initialForm: Form, formStep2: Form) => {
  const oldIdToNewIdMap: { [key: number]: number } = {};
  const oldOptionIdToNewOptionIdMap: { [key: number]: number } = {};

  // Map old question IDs to new question IDs based on questionOrder
  initialForm.questions.forEach((initialQuestion: FormQuestion) => {
    const newQuestion = formStep2.questions.find(
      (questionStep2: FormQuestion) => questionStep2.questionOrder === initialQuestion.questionOrder,
    );
    if (newQuestion) {
      oldIdToNewIdMap[initialQuestion.id] = newQuestion.id;

      // Map old question option IDs to new option IDs based on questionOptionOrder
      initialQuestion.questionOptions?.forEach(oldOption => {
        const newOption = newQuestion.questionOptions?.find(
          (option: FormQuestionOption) => option.questionOptionOrder === oldOption.questionOptionOrder,
        );
        if (newOption) {
          oldOptionIdToNewOptionIdMap[oldOption.id] = newOption.id;
        }
      });
    }
  });

  // Step 3: Re-add conditional logic with updated question and option IDs
  return {
    ...formStep2,
    questions: formStep2.questions.map((questionStep2: FormQuestion) => {
      const originalQuestion = initialForm.questions.find(q => q.questionOrder === questionStep2.questionOrder);

      const updatedLogic = originalQuestion?.formQuestionConditionalLogicList?.map((logic: QuestionRule) => ({
        ...logic,
        conditionFormQuestionId: oldIdToNewIdMap[logic.conditionFormQuestionId],
        formQuestionOptionId: logic.formQuestionOptionId
          ? oldOptionIdToNewOptionIdMap[logic.formQuestionOptionId]
          : null,
      }));

      return {
        ...questionStep2,
        formQuestionConditionalLogicList: updatedLogic,
      };
    }),
  };
};
