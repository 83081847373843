import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Modal from 'components/common/modal-component';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { StyledTextarea } from 'components/project/new-project-form';
import SubjectIcon from '@material-ui/icons/Subject';
import PeopleIcon from '@material-ui/icons/People';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import EventIcon from '@material-ui/icons/Event';
import { BlueSecondaryButton, SecondaryButton, StyledInput } from 'components/styled-components/common';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ToggleMembers from './toggle-members';
import Image from 'components/common/lazyImage';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MultipleMeetingSettings, YearMonthUser } from 'services/types/user-calendar';
import { fetchMultipleMeetingSettingsAsync } from 'redux/actions/user-calendar';
import { saveCoachingSessionAsync } from 'redux/actions/request';
import { User, Member, Circle, Project } from 'redux/types/account';
import {
  createWeeklyEventsWithAvailabilityLocalized,
  generateUserColors,
  getUserAvailabilitiesEvents,
  getUserFixedAvailabilitiesEvents,
  getMonthsForCurrentWeek,
  Duration,
} from 'util/calendar';
import { chartColors } from 'components/styled-components/babele-theme';
import CalendarScheduler from 'components/common/calendar-scheduler/calendar-scheduler';
import { CalendarViewType, CustomEvent } from 'components/common/calendar-scheduler/types';
import { NavigateAction, View, momentLocalizer } from 'react-big-calendar';
import debounce from 'debounce';
import { toast } from '../../components/common/toast';
import momentTimezone from 'moment-timezone';
import { getUserEventsByMonth } from 'services/api/calendar';
import { UserEvent } from 'redux/types/calendar';
import { Checkbox } from '@material-ui/core';
import { Flag, MeetingProvider } from 'redux/types/enums';
import { StyledButton } from 'primitives/Button/style';

const CardContainer = styled.div`
  display: flex;
  gap: 1em;
`;

const Card = styled.div`
  border: 5px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 15%;
  overflow: hidden;
  flex: 1;
  padding: 1.2em;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0;
  @media (max-width: 630px) {
    font-size: 1.2em;
    line-height: 1.2em;
    }
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.grey.medium};
  text-align: center;
  margin: 0;
  @media (max-width: 630px) {
    font-size: 1em;
    line-height: 1em;
    margin: .5em 0 0 0;
    }
  }
`;

const Icon = styled.img`
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const UserRow = styled(Row)`
  flex: 1;
  overflow: hidden;
  position: relative;
`;

const StyledImage = styled(Image)`
  &&& {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin-right: 0.5em;
  }
`;

const DateTimePickerRow = styled(Row)`
  // justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledDatePicker = styled(DatePicker)`
  &&& {
    margin-right: 0.4em;
    input {
      width: 75px;
      background-color: ${({ theme }) => theme.colors.blue.veryLight};
      border: 0;
      @media (max-width: 870px) {
        font-size: 0.8em;
        width: 64px;
      }
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  &&& {
    input {
      width: 40px;
      background-color: ${({ theme }) => theme.colors.blue.veryLight};
      border: 0;
      @media (max-width: 870px) {
        font-size: 0.8em;
        width: 31px;
      }
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const UserColor = styled.div`
  height: 0.5em;
  width: 7em;
`;

const CalendarRow = styled.div`
  margin-bottom: 1em;

  & > * {
    width: 100%;
  }
`;

const ParticipantName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  padding-right: 1em;
`;

const ButtonDurationContainer = styled.div`
  display: flex;
  padding: 12px 0 14px 0;
  gap: 12px;
  margin-bottom: 10px;
`;

const DurationButton = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 2.3em;
  height: 36px;
  width: 155px;
  border-radius: 10em;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.dark};
  border: solid 2px ${({ theme }) => theme.colors.blue.dark};
  font-size: 12px;
  cursor: pointer;
  text-align: center;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme.colors.blue.dark};
      color: ${theme.colors.white};
    `}
`;

const Note = styled.p`
  color: #000;
  font-size: 1.2em;
`;

const iconProps = {
  fontSize: '1.5em',
};

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  padding: 8px 0;

  align-items: center;
`;

const LegendItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ::before {
    content: '';
    width: 16px;
    height: 16px;
    background: ${({ active }) => (active ? '#ceedd8' : '#FFFFCC')};
    border-bottom: 1px solid ${({ active }) => (active ? '#91ba9d' : '#FFC000')};
    border-right: 1px solid ${({ active }) => (active ? '#91ba9d' : '#FFC000')};
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  currentUser: state.account.details.user as User,
  multipleMeetingSettings: state.userCalendar.multipleMeetingSettings as MultipleMeetingSettings[],
  bearer: state.account.session.session.bearer,
  community: state.account.selectedCommunity,
  isSavingCoachingSession: state.loading.saveCoachingSessionFlag,
  currentUserId: state.account.details.user.id,
  projects: state.account.projects.list,
});

const mapDispatchToProps = {
  fetchMultipleMeetingSettings: fetchMultipleMeetingSettingsAsync.request,
  saveCoachingSession: saveCoachingSessionAsync.request,
};

type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  fetchMembers: (circleId: number, skip: number) => Promise<{ id: number; name: string; photo: string }[]>;
  fetchTeam: () => Promise<{ id: number; name: string; photo: string; circle: { id: number } }[]>;
  searchMembers: (concept: string) => Promise<{ list: Member[] }>;
  fetchCircles: () => void;
  circles: Circle[];
  teamCircles: Circle[];
  projectId: number;
  teamMemberScheduling?: boolean;
  mentorScheduling?: boolean;
  defaultDecision?: string;
  defaultSummary?: string;
  defaultDescription?: string;
}

export const MAXIMUM_PARTICIPANTS = 2;

const AddCoachingModal: React.FC<Props> = props => {
  const {
    literals,
    currentUser,
    bearer,
    fetchMultipleMeetingSettings,
    multipleMeetingSettings,
    projectId,
    saveCoachingSession,
    searchMembers,
    teamMemberScheduling,
    community,
    isSavingCoachingSession,
    currentUserId,
    projects,
  } = props;
  const [decision, setDecision] = useState<string>(props.defaultDecision || '');
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [members, setMembers] = useState<{ id: number; name: string; photo: string }[]>([]);
  const [searchResults, setSearchResults] = useState<Member[]>([]);
  const [usersAvailability, setUsersAvailability] = useState<MultipleMeetingSettings[]>([]);
  const [showCalendarScheduler, setShowCalendarScheduler] = useState<boolean>(false);
  const [fetchedUserMonths, setFetchedUserMonths] = useState<YearMonthUser[]>([]);
  const [scheduledUserEvents, setScheduledUserEvents] = useState<CustomEvent[]>([]);
  const history = useHistory();
  const [coachingSession, setCoachingSession] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    startTime: moment().format('HH:mm'),
    endDate: moment().format('YYYY-MM-DD'),
    endTime: moment().add(Duration.DURATION_45, 'minutes').format('HH:mm'),
    duration: Duration.DURATION_45,
    summary: props.defaultSummary || '',
    createConference: false,
    description: props.defaultDescription || '',
    location: '',
  });

  const communityHasMeetingProvider: boolean = community?.hasMeetingProvider;
  const communityMeetingProviderType: MeetingProvider = community?.meetingProviderType;
  // If the community has a meeting app sync-ed, then use that. Otherwise, use the Babele Zoom
  const communityMeetingProviderToUse: MeetingProvider = communityHasMeetingProvider
    ? communityMeetingProviderType
    : MeetingProvider.Zoom;

  const userHasMeetingProvider: boolean = Boolean(currentUser?.hasMeetingProvider);
  const userMeetingProviderType = currentUser?.meetingProviderType;
  const meetingProviderToUse = userHasMeetingProvider ? userMeetingProviderType : communityMeetingProviderToUse;

  const generatedColors = useMemo(
    () => ({ ...generateUserColors(members, chartColors), [currentUser?.id]: '#FFD433' }),
    [members, currentUser?.id],
  );

  const project = useMemo(() => projects.find((project: Project) => project.id === projectId), [projectId]);

  const mentorCircle = useMemo(() => (project?.circles || []).find((c: Circle) => c.name === 'Mentors'), [
    project?.circles,
  ]);

  const teamCircle = useMemo(() => (project?.circles || []).find((c: Circle) => c.name === 'Team'), [project?.circles]);
  const currentUserMember = useMemo(() => (project?.members || []).find((m: Member) => m.id === currentUserId), [
    project?.members,
  ]);

  const isProjectMentorCircle = mentorCircle?.id === currentUserMember?.circle?.id;

  const isProjectTeamCircle = teamCircle?.id === currentUserMember?.circle?.id;

  const showAddCoachingSessionPanel =
    !isProjectTeamCircle &&
    !community.canEditCommunityInfo &&
    (isProjectMentorCircle || (community && community.permissions?.permissionCanCoachTeamMembers));

  useEffect(() => {
    setSearchResults([]);
  }, [members, setSearchResults]);

  useEffect(() => {
    if (showAddCoachingSessionPanel) setDecision('coach');
  }, [showAddCoachingSessionPanel]);

  const startDatetime = `${coachingSession.startDate}T${coachingSession.startTime}`;
  const endDatetime = `${coachingSession.endDate}T${coachingSession.endTime}`;

  const handleClose = () => {
    // the modal is shown in history.location.state.addNewCoaching
    history.push(history.location.pathname + history.location.search);
  };

  const currentUserAvailability = useMemo(
    () =>
      usersAvailability.find(availability => {
        return availability.userId === currentUser.id;
      }),
    [usersAvailability, currentUser?.id],
  );

  const currentUserTimeZone = useMemo(() => {
    const userSetTimeZone = currentUserAvailability?.userMeetingPreference?.timeZone;

    if (userSetTimeZone && Boolean(momentTimezone.tz.zone(userSetTimeZone as string))) {
      return userSetTimeZone;
    }

    return momentTimezone.tz.guess();
  }, [currentUserAvailability]);
  const usersWeeklyAvailabilites = useMemo(() => getUserAvailabilitiesEvents(usersAvailability), [
    usersAvailability,
    currentUserTimeZone,
  ]);
  const fixedTimeSlotsEvents = useMemo(() => getUserFixedAvailabilitiesEvents(usersAvailability), [
    usersAvailability,
    currentUserTimeZone,
  ]);

  const defaultStartOfDay = momentTimezone.tz(currentUserTimeZone);
  const defaultEndOfDay = momentTimezone.tz(currentUserTimeZone);
  defaultStartOfDay.set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
  defaultEndOfDay.set({ hour: 22, minute: 0, second: 0, millisecond: 0 });

  // Updating the localizer to have Monday as the first day of the week Explanation https://github.com/jquense/react-big-calendar/issues/1661
  moment.updateLocale('en', {
    week: {
      dow: 1,
      doy: 4,
    },
  });
  moment.tz.setDefault(currentUserTimeZone);
  const localizer = momentLocalizer(moment);

  const allEvents = useMemo(() => {
    if (usersWeeklyAvailabilites && fixedTimeSlotsEvents && scheduledUserEvents && currentUserTimeZone) {
      const weeklyEvents = createWeeklyEventsWithAvailabilityLocalized(
        currentDate,
        coachingSession.duration,
        usersWeeklyAvailabilites,
        fixedTimeSlotsEvents,
        scheduledUserEvents,
        [currentUser?.id, ...members.map((member: { id: number }) => member.id)],
        currentUserTimeZone,
        literals.calendar_meeting_slot_available,
        currentUser,
      );
      return [...weeklyEvents];
    }
    return [];
  }, [
    currentDate,
    usersWeeklyAvailabilites,
    coachingSession.duration,
    fixedTimeSlotsEvents,
    scheduledUserEvents,
    members,
    currentUserTimeZone,
    currentUser?.id,
  ]);

  useEffect(() => {
    // A week can span over 2 months, so get the months we need to fetch events for
    const monthsToShow = getMonthsForCurrentWeek(currentDate);
    const userIds = members ? [currentUser?.id, ...members.map((user: any) => user.id)] : [];
    // Creating a list with all the user ids and months that need to be shown
    const userMonthsToShow: YearMonthUser[] = userIds.flatMap((id: number) => {
      return monthsToShow.map(month => ({
        year: month.year,
        month: month.month,
        userId: id,
      }));
    });

    // Don't fetch data that we fetched already
    const userMonthsToFetch = userMonthsToShow.filter(({ year, month, userId }) => {
      return !fetchedUserMonths.find(userMonth => {
        return userMonth.month === month && userMonth.year === year && userMonth.userId === userId;
      });
    });

    const eventsFetchTasks = userMonthsToFetch.map(({ userId, year, month }) =>
      getUserEventsByMonth(bearer, userId, month, year),
    );

    // Fetched all the user events needed
    Promise.allSettled(eventsFetchTasks).then(results => {
      results
        .filter(result => result.status === 'fulfilled')
        .forEach(result => {
          // @ts-ignore
          const { userEvents }: { userEvents: UserEvent[] } = result.value;
          const customEvents = (userEvents || []).map(({ id, start, end }) => {
            return {
              eventId: id,
              start: new Date(start),
              end: new Date(end),
            };
          });

          setScheduledUserEvents(scheduled => [...scheduled, ...customEvents]);
        });
    });
    setFetchedUserMonths(fetchedUserMonths => [...fetchedUserMonths, ...userMonthsToFetch]);
  }, [currentDate, currentUser, members]);

  useEffect(() => {
    const allMembers = [currentUser, ...members];
    if (allMembers && allMembers.length > 0 && multipleMeetingSettings) {
      const selectedUserIds = allMembers?.map((user: { id: number }) => user?.id);
      const selectedUsersData = multipleMeetingSettings
        .filter((user: MultipleMeetingSettings) => selectedUserIds?.includes(user?.userId))
        .map((user: MultipleMeetingSettings) => {
          const isCurrentUser = user?.userId === currentUser?.id;

          return {
            ...user,
            color: isCurrentUser ? '#FFC000' : '00FF00',
            // : members.find((selectedUser: { id: number }) => selectedUser?.id === user?.userId)?.color,
          };
        });

      setUsersAvailability(selectedUsersData);
      if (members.length > 0) {
        setShowCalendarScheduler(true);
      } else {
        setShowCalendarScheduler(false);
      }
    }
  }, [members, currentUser, multipleMeetingSettings]);

  const onCalendarNavigate = (newDate: Date, view: View, action: NavigateAction) => {
    setCurrentDate(newDate);
  };

  const handleSelectEvent = (event: CustomEvent | null) => {
    const startDate = moment(event?.start);
    const endDate = moment(event?.end);
    setCoachingSession({
      ...coachingSession,
      startDate: startDate.format('YYYY-MM-DD'),
      startTime: startDate.format('HH:mm'),
      endDate: endDate.format('YYYY-MM-DD'),
      endTime: endDate.format('HH:mm'),
    });
    setShowCalendarScheduler(false);
  };

  const handleSubmitCoachingSession = () => {
    if (!currentUser?.id) return;

    if (coachingSession.summary.length === 0) {
      toast(literals.coaching_session_no_title, { type: 'warning' });
      return;
    }

    if (coachingSession.description.length === 0) {
      toast(literals.coaching_session_no_description, { type: 'warning' });
      return;
    }
    if (members.length === 0) {
      toast(literals.coaching_session_no_participants, { type: 'warning' });
      return;
    }

    const data = {
      summary: coachingSession.summary,
      description: coachingSession.description,
      projectId,
      start: moment(`${coachingSession.startDate}T${coachingSession.startTime}`).toISOString(),
      end: moment(`${coachingSession.endDate}T${coachingSession.endTime}`).toISOString(),
      location: coachingSession.createConference ? '' : coachingSession.location,
      createConference: coachingSession.createConference,
      attendeeUserIds: [
        ...members.map((invitee: { id: number }) => invitee.id),
        // currentUser?.id
      ],
    };
    saveCoachingSession({ bearer, coachingSession: data });
  };

  const handleSearchMembers = useCallback(
    debounce((concept: string) => {
      if (concept.trim().length === 0) {
        setSearchResults([]);
        return;
      }
      searchMembers(concept).then(({ list }) => {
        setSearchResults(list);
      });
    }, 500),
    [searchMembers, setSearchResults],
  );

  useEffect(() => {
    fetchMultipleMeetingSettings({ bearer: bearer, usersId: [currentUser?.id, ...members.map(({ id }) => id)] });
  }, [members]);

  const toggleCircles = useMemo(() => {
    if (teamMemberScheduling) {
      return props.circles.filter((c: Circle) => c.permissionCanBeInvitedAsCoach);
    }
    return props.circles;
  }, [props.circles, teamMemberScheduling]);

  const handleDurationButtonClick = (duration: number) => {
    setCoachingSession({
      ...coachingSession,
      duration,
      endTime: moment().add(duration, 'minutes').format('HH:mm'),
    });
  };

  if (decision === '') {
    return (
      <Modal title="" noTitle style={{ maxWidth: '50em' }} open={true}>
        <CloseIcon
          style={{ position: 'absolute', fontSize: '3em', top: '1.45em', right: '1em', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <h1 style={{ marginTop: 0 }}>{literals.coaching_add_session_modal}</h1>
        <CardContainer>
          <Card
            onClick={() => {
              history.push(`/project/${projectId}/request/new`);
            }}
          >
            <Icon src="/static/coaching-session/request.png" />
            <Title>{literals.coaching_option_request_title}</Title>
            <Description>{literals.coaching_option_request_description}</Description>
          </Card>
          <Card onClick={() => setDecision('coach')}>
            <Icon src="/static/coaching-session/coach.png" />
            <Title>{literals.coaching_option_coach_title}</Title>
            <Description>{literals.coaching_option_coach_description}</Description>
          </Card>
        </CardContainer>
      </Modal>
    );
  } else {
    return (
      <Modal title="" noTitle open={true}>
        <CloseIcon
          style={{ position: 'absolute', fontSize: '3em', top: '1.45em', right: '1em', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <h1 style={{ marginTop: 0 }}>{literals.coaching_schedule_session}</h1>
        <FormContainer>
          <Row>
            <EditIcon style={iconProps} />
            <StyledInput
              value={coachingSession.summary}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setCoachingSession({ ...coachingSession, summary: evt.target.value })
              }
              placeholder={literals.coaching_add_title}
            />
          </Row>
          <Row>
            <SubjectIcon style={iconProps} />
            <StyledTextarea
              value={coachingSession.description}
              onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) =>
                setCoachingSession({ ...coachingSession, description: evt.target.value })
              }
              placeholder={literals.coaching_add_description}
            />
          </Row>
          <Row style={{ position: 'relative' }}>
            <PeopleIcon style={iconProps} />
            <StyledInput
              onChange={(evt: any) => handleSearchMembers(evt.target.value)}
              onFocus={(evt: any) => handleSearchMembers(evt.target.value)}
              onBlur={evt => {
                if ((evt.relatedTarget as HTMLElement)?.id?.startsWith('coaching-search-result-')) return;
                setTimeout(() => setSearchResults([]), 100);
              }}
              placeholder={literals.coaching_add_guests}
            />
            <ToggleMembers
              fetchCircles={props.fetchCircles}
              circles={toggleCircles}
              teamCircles={props.teamCircles}
              fetchMembers={props.fetchMembers}
              fetchTeam={props.fetchTeam}
              parentMembers={members}
              getInviteesCallback={setMembers}
              searchResults={searchResults}
              currentUserId={currentUser?.id || 0}
              community={props.community}
            >
              <BlueSecondaryButton style={{ display: 'flex', alignItems: 'center' }}>
                {teamMemberScheduling ? literals.coaching_show_coaches : literals.coaching_show_mentees}
                <ArrowDropDownIcon />
              </BlueSecondaryButton>
            </ToggleMembers>
          </Row>
          <Row>
            <UserRow key={currentUser?.id}>
              <StyledImage src={currentUser?.photo} />
              <Column>
                <ParticipantName>{currentUser?.name}</ParticipantName>
                <UserColor style={{ background: generatedColors[currentUser?.id] }} />
              </Column>
            </UserRow>
            {members.map((member: { id: number; name: string; photo: string }) => (
              <UserRow key={member.id}>
                <StyledImage src={member.photo} />
                <Column style={{ position: 'relative' }}>
                  <ParticipantName>{member.name}</ParticipantName>
                  <UserColor style={{ background: generatedColors[member.id] }} />
                  <CloseIcon
                    style={{ position: 'absolute', top: 0, right: 0, fontSize: '1em', cursor: 'pointer' }}
                    onClick={() => setMembers(members.filter(m => m.id !== member.id))}
                  />
                </Column>
              </UserRow>
            ))}
          </Row>
          <Row>
            <EventIcon style={iconProps} />
            <DateTimePickerRow>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                  <StyledDatePicker
                    autoOk={true}
                    margin="normal"
                    id="startDate"
                    label={literals.event_edit_start_date_label && ''}
                    format="DD/MM/YYYY"
                    value={startDatetime}
                    variant={'inline'}
                    onChange={(date: MaterialUiPickersDate): void => {
                      const endDateTime = moment(date?.toString()).add(coachingSession.duration, 'minutes');
                      setCoachingSession({
                        ...coachingSession,
                        startDate: moment(date?.toString()).format('YYYY-MM-DD'),
                        endDate: endDateTime.format('YYYY-MM-DD'),
                        endTime: endDateTime.format('HH:mm'),
                      });
                    }}
                  />
                  <StyledTimePicker
                    autoOk={true}
                    margin="normal"
                    id="startTime"
                    format="HH:mm"
                    label={literals.event_edit_start_time_label && ''}
                    value={startDatetime}
                    variant={'inline'}
                    onChange={(date: MaterialUiPickersDate): void => {
                      const endDateTime = moment(date?.toString()).add(coachingSession.duration, 'minutes');
                      setCoachingSession({
                        ...coachingSession,
                        startTime: moment(date?.toString()).format('HH:mm'),
                        endDate: endDateTime.format('YYYY-MM-DD'),
                        endTime: endDateTime.format('HH:mm'),
                      });
                    }}
                  />
                </div>
                <div style={{ alignSelf: 'center', margin: '0em 0.4em' }}>to</div>
                <div>
                  <StyledDatePicker
                    autoOk={true}
                    margin="normal"
                    id="endDate"
                    label={literals.event_edit_end_date_label && ''}
                    format="DD/MM/YYYY"
                    value={endDatetime}
                    variant={'inline'}
                    onChange={(date: MaterialUiPickersDate): void => {
                      setCoachingSession({
                        ...coachingSession,
                        endDate: moment(date?.toString()).format('YYYY-MM-DD'),
                      });
                    }}
                    minDate={startDatetime}
                  />
                  <StyledTimePicker
                    autoOk={true}
                    margin="normal"
                    id="endTime"
                    format="HH:mm"
                    label={literals.event_edit_end_time_label && ''}
                    value={endDatetime}
                    variant={'inline'}
                    onChange={(date: MaterialUiPickersDate): void => {
                      setCoachingSession({
                        ...coachingSession,
                        endTime: moment(date?.toString()).format('HH:mm'),
                      });
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </DateTimePickerRow>
            {!showCalendarScheduler && (
              <BlueSecondaryButton
                onClick={() => setShowCalendarScheduler(true)}
                style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
              >
                {literals.coaching_edit_date_time}
              </BlueSecondaryButton>
            )}
          </Row>
          <CalendarRow
            style={{
              position: 'relative',
              height: showCalendarScheduler ? 'auto' : '0',
              overflow: showCalendarScheduler ? 'visible' : 'hidden',
            }}
          >
            <Row>
              <Note>{literals.calendar_meeting_select_time_duration}</Note>
            </Row>
            <ButtonDurationContainer>
              <DurationButton
                onClick={() => handleDurationButtonClick(Duration.DURATION_30)}
                isActive={coachingSession.duration === Duration.DURATION_30}
              >
                30 {literals.calendar_meeting_duration_minutes.toLowerCase()}
              </DurationButton>
              <DurationButton
                onClick={() => handleDurationButtonClick(Duration.DURATION_45)}
                isActive={coachingSession.duration === Duration.DURATION_45}
              >
                45 {literals.calendar_meeting_duration_minutes.toLowerCase()}
              </DurationButton>
              <DurationButton
                onClick={() => handleDurationButtonClick(Duration.DURATION_60)}
                isActive={coachingSession.duration === Duration.DURATION_60}
              >
                60 {literals.calendar_meeting_duration_minutes.toLowerCase()}
              </DurationButton>
            </ButtonDurationContainer>
            <CalendarScheduler
              events={allEvents}
              isToolbarVisible={true}
              defaultView={CalendarViewType.WEEK}
              timeSlot={4}
              timeIncrements={15}
              minTime={defaultStartOfDay.toDate()}
              maxTime={defaultEndOfDay.toDate()}
              views={[CalendarViewType.WEEK]}
              localizer={localizer}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectEvent}
              userColorMap={generatedColors}
              isSelectable={usersAvailability?.length > 1}
              currentUser={currentUser}
              onNavigate={onCalendarNavigate}
            />
            <Legend>
              <LegendItem active={true}>{literals.event_inside_office_hours}</LegendItem>
              <LegendItem active={false}>{literals.event_outside_office_hours}</LegendItem>
            </Legend>
          </CalendarRow>
          <Row>
            <VoiceChatIcon style={iconProps} />
            <Row style={{ marginBottom: 0 }}>
              <Checkbox
                onClick={() => {
                  setCoachingSession({ ...coachingSession, createConference: !coachingSession.createConference });
                }}
                checked={coachingSession.createConference}
                color={'primary'}
              />
              <p style={{ marginTop: 0 }}>{literals.event_generate_meet_text}</p>
            </Row>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <StyledButton
              style={{ width: '9em' }}
              disabled={isSavingCoachingSession === Flag.Request}
              loading={isSavingCoachingSession === Flag.Request}
              onClick={() => isSavingCoachingSession !== Flag.Request && handleSubmitCoachingSession()}
            >
              {literals.community_edit_save_button}
            </StyledButton>
            <SecondaryButton style={{ width: '9em' }} onClick={handleClose}>
              {literals.community_edit_cancel_button}
            </SecondaryButton>
          </Row>
        </FormContainer>
      </Modal>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCoachingModal);
