import { Section, Assignment, Task, CreateTaskPayload, Methodology } from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';
import { Planner } from '../types/enums';

export const fetchMethodologyContentAsync = createAsyncAction(
  'FETCH_METHODOLOGY_CONTENT_REQUEST',
  'FETCH_METHODOLOGY_CONTENT_SUCCESS',
  'FETCH_METHODOLOGY_CONTENT_FAILURE',
  'FETCH_METHODOLOGY_CONTENT_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number },
  { communityId: number; methodologyId: number; sections: Section[] },
  string,
  null
>();

export const reorderMethodologySectionsAsync = createAsyncAction(
  'REORDER_METHODOLOGY_SECTIONS_REQUEST',
  'REORDER_METHODOLOGY_SECTIONS_SUCCESS',
  'REORDER_METHODOLOGY_SECTIONS_FAILURE',
  'REORDER_METHODOLOGY_SECTIONS_IDLE',
)<
  { bearer: string; newSectionsOrder: { id: number; order: number }[]; methodologyId: number; communityId: number },
  { newSectionsOrder: { id: number; order: number }[]; methodologyId: number; communityId: number },
  string,
  null
>();

export const reorderMethodologyAssignmentsAsync = createAsyncAction(
  'REORDER_METHODOLOGY_ASSIGNMENTS_REQUEST',
  'REORDER_METHODOLOGY_ASSIGNMENTS_SUCCESS',
  'REORDER_METHODOLOGY_ASSIGNMENTS_FAILURE',
  'REORDER_METHODOLOGY_ASSIGNMENTS_IDLE',
)<
  {
    bearer: string;
    newAssignmentsOrder: { id: number; order: number }[];
    communityId: number;
    methodologyId: number;
  },
  {
    newAssignmentsOrder: { id: number; order: number }[];
    communityId: number;
    methodologyId: number;
  },
  string,
  null
>();

export const saveMethodologySectionAsync = createAsyncAction(
  'SAVE_METHODOLOGY_SECTION_REQUEST',
  'SAVE_METHODOLOGY_SECTION_SUCCESS',
  'SAVE_METHODOLOGY_SECTION_FAILURE',
  'SAVE_METHODOLOGY_SECTION_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    newSection: { id: number; isLean: boolean; methodologyId: number; name: string };
    currentSectionsOrder: { id: number; order: number }[];
    sectionIndex: number;
    isUpdate: boolean;
  },
  { communityId: number; section: Section; sectionIndex: number },
  string,
  null
>();

export const saveMethodologyAssignmentAsync = createAsyncAction(
  'SAVE_METHODOLOGY_ASSIGNMENT_REQUEST',
  'SAVE_METHODOLOGY_ASSIGNMENT_SUCCESS',
  'SAVE_METHODOLOGY_ASSIGNMENT_FAILURE',
  'SAVE_METHODOLOGY_ASSIGNMENT_IDLE',
)<
  {
    bearer: string;
    newAssignment: {
      id: number;
      name: string;
      description?: string;
      details?: string;
      template?: string;
      wikiLinks?: string;
      wikiProblem?: string;
      feedbackText?: string;
      inTutorial?: boolean;
      isActive: true;
      methodologyId: number;
      methodologySectionId: number;
      isReadingAssignment: boolean;
    };
    communityId: number;
    currentAssignmentsOrder: { id: number; order: number }[];
    assignmentIndex: number;
  },
  { communityId: number; assignment: Assignment; assignmentIndex: number },
  string,
  null
>();

export const updateMethodologyAssignmentAsync = createAsyncAction(
  'UPDATE_METHODOLOGY_ASSIGNMENT_REQUEST',
  'UPDATE_METHODOLOGY_ASSIGNMENT_SUCCESS',
  'UPDATE_METHODOLOGY_ASSIGNMENT_FAILURE',
  'UPDATE_METHODOLOGY_ASSIGNMENT_IDLE',
)<
  {
    bearer: string;
    newAssignment: Assignment;
    communityId: number;
  },
  { communityId: number; assignment: Assignment },
  string,
  null
>();

export const deleteMethodologySectionAsync = createAsyncAction(
  'DELETE_METHODOLOGY_SECTION_REQUEST',
  'DELETE_METHODOLOGY_SECTION_SUCCESS',
  'DELETE_METHODOLOGY_SECTION_FAILURE',
  'DELETE_METHODOLOGY_SECTION_IDLE',
)<{ bearer: string; section: Section; communityId: number }, { section: Section; communityId: number }, string, null>();

export const deleteMethodologyAssignmentAsync = createAsyncAction(
  'DELETE_METHODOLOGY_ASSIGNMENT_REQUEST',
  'DELETE_METHODOLOGY_ASSIGNMENT_SUCCESS',
  'DELETE_METHODOLOGY_ASSIGNMENT_FAILURE',
  'DELETE_METHODOLOGY_ASSIGNMENT_IDLE',
)<
  { bearer: string; assignment: Assignment; communityId: number },
  { assignment: Assignment; communityId: number },
  string,
  null
>();

export const deleteTaskAsync = createAsyncAction(
  'DELETE_TASK_REQUEST',
  'DELETE_TASK_SUCCESS',
  'DELETE_TASK_FAILURE',
  'DELETE_TASK_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number; taskId: number },
  { communityId: number; methodologyId: number; taskId: number },
  string,
  null
>();

export const deleteTasksAsync = createAsyncAction(
  'DELETE_TASKS_REQUEST',
  'DELETE_TASKS_SUCCESS',
  'DELETE_TASKS_FAILURE',
  'DELETE_TASKS_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number; taskIds: number[] },
  { communityId: number; methodologyId: number; taskIds: number[] },
  string,
  null
>();

export const fetchMethodologyPlanningAsync = createAsyncAction(
  'FETCH_METHODOLOGY_PLANNING_REQUEST',
  'FETCH_METHODOLOGY_PLANNING_SUCCESS',
  'FETCH_METHODOLOGY_PLANNING_FAILURE',
  'FETCH_METHODOLOGY_PLANNING_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number },
  {
    communityId: number;
    methodologyId: number;
    planner: { type: Planner; tasks: Task[] };
    usingProjects: { projectId: number; projectName: string }[];
    isRetroactive: boolean;
  },
  string,
  null
>();

export const createTasksAsync = createAsyncAction(
  'CREATE_TASKS_REQUEST',
  'CREATE_TASKS_SUCCESS',
  'CREATE_TASKS_FAILURE',
  'CREATE_TASKS_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    methodologyId: number;
    tasks: CreateTaskPayload[];
  },
  {
    communityId: number;
    methodologyId: number;
    tasks: Task[];
  },
  string,
  null
>();

type UpdateTaskPayload = {
  id: number;
  assignmentId: number;
  dateStart: string;
  datePublish: string;
  dateEnd: string;
  taskSendReminder?: boolean;
};

export const updateTasksAsync = createAsyncAction(
  'UPDATE_TASK_REQUEST',
  'UPDATE_TASK_SUCCESS',
  'UPDATE_TASK_FAILURE',
  'UPDATE_TASK_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    methodologyId: number;
    tasks: UpdateTaskPayload[];
  },
  {
    communityId: number;
    methodologyId: number;
    tasks: Task[];
  },
  string,
  null
>();

export const updateTasksOrderAsync = createAsyncAction(
  'UPDATE_TASKS_ORDER_REQUEST',
  'UPDATE_TASKS_ORDER_SUCCESS',
  'UPDATE_TASKS_ORDER_FAILURE',
  'UPDATE_TASKS_ORDER_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    methodologyId: number;
    tasks: Task[];
  },
  {
    communityId: number;
    methodologyId: number;
    tasks: Task[];
  },
  string,
  null
>();

export const setTaskUnlockRuleAsync = createAsyncAction(
  'SET_TASK_UNLOCK_RULE_REQUEST',
  'SET_TASK_UNLOCK_RULE_SUCCESS',
  'SET_TASK_UNLOCK_RULE_FAILURE',
  'SET_TASK_UNLOCK_RULE_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    methodologyId: number;
    taskUnlockRule: Planner;
  },
  {
    communityId: number;
    methodologyId: number;
    taskUnlockRule: Planner;
  },
  string,
  null
>();

export const disableMethodologyAsync = createAsyncAction(
  'DISABLE_METHODOLOGY_REQUEST',
  'DISABLE_METHODOLOGY_SUCCESS',
  'DISABLE_METHODOLOGY_FAILURE',
  'DISABLE_METHODOLOGY_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number },
  { communityId: number; methodologyId: number },
  string,
  null
>();

export const enableMethodologyAsync = createAsyncAction(
  'ENABLE_METHODOLOGY_REQUEST',
  'ENABLE_METHODOLOGY_SUCCESS',
  'ENABLE_METHODOLOGY_FAILURE',
  'ENABLE_METHODOLOGY_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number },
  { communityId: number; methodologyId: number },
  string,
  null
>();

export const deleteMethodologyAsync = createAsyncAction(
  'DELETE_METHODOLOGY_REQUEST',
  'DELETE_METHODOLOGY_SUCCESS',
  'DELETE_METHODOLOGY_FAILURE',
  'DELETE_METHODOLOGY_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number },
  { communityId: number; methodologyId: number },
  string,
  null
>();

export const saveMethodologyDiffAsync = createAsyncAction(
  'SAVE_METHODOLOGY_DIFF_REQUEST',
  'SAVE_METHODOLOGY_DIFF_SUCCESS',
  'SAVE_METHODOLOGY_DIFF_FAILURE',
  'SAVE_METHODOLOGY_DIFF_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    oldMethodology: Methodology;
    newMethodology: Methodology;
  },
  { communityId: number; methodology: Methodology },
  string,
  null
>();

export const saveSectionsStagegateAsync = createAsyncAction(
  'SAVE_SECTIONS_STAGEGATE_REQUEST',
  'SAVE_SECTIONS_STAGEGATE_SUCCESS',
  'SAVE_SECTIONS_STAGEGATE_FAILURE',
  'SAVE_SECTIONS_STAGEGATE_IDLE',
)<
  { bearer: string; communityId: number; methodologyId: number; sections: Section[] },
  { communityId: number; methodologyId: number; sections: Section[] },
  string,
  null
>();

export default {
  fetchMethodologyContentAsync,
  reorderMethodologySectionsAsync,
  reorderMethodologyAssignmentsAsync,
  saveMethodologySectionAsync,
  saveMethodologyAssignmentAsync,
  updateMethodologyAssignmentAsync,
  deleteMethodologySectionAsync,
  deleteMethodologyAssignmentAsync,
  deleteTaskAsync,
  deleteTasksAsync,
  fetchMethodologyPlanningAsync,
  createTasksAsync,
  updateTasksAsync,
  updateTasksOrderAsync,
  setTaskUnlockRuleAsync,
  disableMethodologyAsync,
  enableMethodologyAsync,
  deleteMethodologyAsync,
  saveMethodologyDiffAsync,
  saveSectionsStagegateAsync,
};
