import { Comment } from 'redux/types/account';

export const findParentCommentById = (comments: Comment[], id: number): Comment | undefined =>
  comments.find(c => {
    const sameId = c.id === id;
    if (sameId) return sameId;
    if (c.comments?.length) return findCommentById(c.comments, id);
    return false;
  });

export const findCommentById = (comments: Comment[], id: number): Comment | null => {
  const parent = findParentCommentById(comments, id);
  if (parent?.id === id) return parent;
  else if (parent?.comments?.length) return findCommentById(parent?.comments, id);
  else return null;
};
