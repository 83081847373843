import { Epic } from 'redux-observable';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { saveFormAsync, fetchCommunityFormsAsync, deleteFormAsync, fetchFormByIdAsync } from '../actions/form';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { handleError } from './helpers';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const saveFormEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveFormAsync.request)),
    switchMap(action =>
      from(api.form.saveForm(action.payload.bearer, action.payload.communityId, action.payload.form)).pipe(
        map(saveFormAsync.success),
        catchError(error => handleError(error, saveFormAsync)),
      ),
    ),
  );

export const fetchCommunityFormsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityFormsAsync.request)),
    switchMap(action =>
      from(api.form.fetchCommunityForms(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityFormsAsync.success),
        catchError(error => handleError(error, fetchCommunityFormsAsync)),
      ),
    ),
  );

export const deleteFormEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteFormAsync.request)),
    switchMap(action =>
      from(api.form.deleteForm(action.payload.bearer, action.payload.communityId, action.payload.formId)).pipe(
        map(deleteFormAsync.success),
        catchError(error => handleError(error, deleteFormAsync)),
      ),
    ),
  );

export const fetchFormByIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchFormByIdAsync.request)),
    switchMap(action =>
      from(api.form.fetchFormById(action.payload.bearer, action.payload.communityId, action.payload.formId)).pipe(
        map(fetchFormByIdAsync.success),
        catchError(error => handleError(error, fetchFormByIdAsync)),
      ),
    ),
  );

export default {
  saveFormEpic,
  fetchCommunityFormsEpic,
  deleteFormEpic,
  fetchFormByIdEpic,
};
