import { AxiosResponse } from 'axios';

import { getAxiosInstance } from 'services/api/helper';
import { CommunityFilterAnswerItem } from 'redux/types/account';
import {
  ICommunityData,
  IMembersAndCompanies,
  IEvents,
  IDiscussions,
  IPublicDiscussion,
  IRequests,
  IPublicRequest,
  IPublicEntities,
  IPublicEntity,
} from '../types';

const ai = getAxiosInstance();

export function getPublicOverview(host: string): Promise<ICommunityData> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/PublicOverview/${host}`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getPublicMembersAndCompanies(communityId: number): Promise<IMembersAndCompanies> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/PublicMembersAndCompanies?take=5`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getPublicEvents(communityId: number): Promise<IEvents> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Calendar/PublicEvents/${communityId}`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getPublicDiscussions(communityId: number, skip: number, take: number): Promise<IDiscussions> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/PublicDiscussions?skip=${skip}&take=${take}`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getPublicDiscussion(discussionId: string): Promise<IPublicDiscussion> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/PublicDiscussion/${discussionId}`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getPublicRequests(communityId: number, skip: number, take: number): Promise<IRequests> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/PublicRequests?skip=${skip}&take=${take}`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getPublicRequest(requestId: string): Promise<IPublicRequest> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Request/PublicRequest/${requestId}`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getTabEntities(
  tabId: string,
  skip: number,
  take: number,
  concept: string,
  filters?: CommunityFilterAnswerItem[],
): Promise<IPublicEntities> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Tab/${tabId}/PublicEntities?skip=${skip}&take=${take}`,
      data: {
        concept,
        filters,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function getPublicTabEntity(entityId: string): Promise<IPublicEntity> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Tab/PublicTabEntity/${entityId}`,
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}
