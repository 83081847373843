import React from 'react';
import { Popup } from 'semantic-ui-react';
import { SDG } from 'redux/types/account';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { BigSdg } from '../styled-components/common';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  gap: 0.5em;
  & > span {
    text-align: left;
    line-height: 1.1em;
  }
  width: 20em;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  key: string;
  sdg: SDG;
  trigger: React.ReactNode;
  hideImage?: boolean;
}

const BigSdgPopup: React.FC<Props> = props => {
  const { literals, sdg, trigger } = props;
  const descriptionLiteral = `sdg_${sdg.id}_description`;

  return (
    <Popup
      key={props.key}
      content={
        <Row>
          {!props.hideImage && <BigSdg alt={sdg.name} src={sdg.iconName} />}
          <span>{literals[descriptionLiteral]}</span>
        </Row>
      }
      trigger={trigger}
    />
  );
};

export default connect(mapStateToProps, {})(BigSdgPopup);
