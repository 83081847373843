import React, { FC } from 'react';
import { Calendar, View, EventPropGetter, DateLocalizer, DayLayoutAlgorithm, NavigateAction } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CalendarViewType, CustomEvent } from './types';
import './styles.css';

const CalendarViewsVariant: Record<CalendarViewType, View> = {
  [CalendarViewType.DAY]: 'day',
  [CalendarViewType.WEEK]: 'week',
  [CalendarViewType.MONTH]: 'month',
  [CalendarViewType.WORK_WEEK]: 'work_week',
  [CalendarViewType.AGENDA]: 'agenda',
};

interface CalendarSchedulerProps {
  defaultView: CalendarViewType;
  events: CustomEvent[];
  isToolbarVisible: boolean;
  maxTime: Date;
  minTime: Date;
  views: CalendarViewType[];
  localizer: DateLocalizer;
  onSelectEvent?: (event: CustomEvent | null) => void;
  selectedEvent?: CustomEvent | null;
  userColorMap?: Record<number, string>;
  style?: {};
  timeSlot: number;
  isSelectable: boolean;
  onSelectSlot?: (event: CustomEvent | any) => void;
  onDoubleClickEvent?: (event: CustomEvent) => void;
  noOverlapEvent?: DayLayoutAlgorithm;
  currentUser?: { id: number };
  onNavigate?: (newDate: Date, view: View, action: NavigateAction) => void;
  timeIncrements: number;
  onView?: (view: View) => void;
}

const CalendarScheduler: FC<CalendarSchedulerProps> = ({
  defaultView,
  events,
  isToolbarVisible,
  maxTime,
  minTime,
  style,
  timeSlot,
  views,
  onSelectEvent,
  localizer,
  selectedEvent,
  isSelectable,
  onSelectSlot,
  onDoubleClickEvent,
  noOverlapEvent,
  onNavigate,
  timeIncrements,
  onView,
}) => {
  const [currentDate, setCurrentDate] = React.useState<Date>(new Date());
  const eventStyleGetter: EventPropGetter<CustomEvent> = event => {
    if (event.available) {
      return {
        className: 'available',
      };
    }

    if (event.availableOutside) {
      return {
        className: 'available-outside',
      };
    }

    if (event.selected) {
      return {
        className: 'selected',
      };
    }

    return {};
  };

  return (
    <Calendar
      events={events}
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"
      views={views as View[]}
      defaultView={CalendarViewsVariant[defaultView]}
      style={style}
      toolbar={isToolbarVisible}
      timeslots={timeSlot}
      step={timeIncrements}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      eventPropGetter={eventStyleGetter}
      min={minTime}
      max={maxTime}
      selectable={isSelectable}
      onDoubleClickEvent={onDoubleClickEvent}
      dayLayoutAlgorithm={noOverlapEvent}
      date={currentDate}
      onNavigate={(newDate: Date, view: View, action: NavigateAction) => {
        if (onNavigate) {
          onNavigate(newDate, view, action);
        }
        setCurrentDate(newDate);
      }}
      onView={onView}
    />
  );
};

export default CalendarScheduler;
