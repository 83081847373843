import React, { useMemo } from 'react';
import { Image, ImageProps } from 'semantic-ui-react';
import LogoPlaceholder from '../../assets/common/logo-placeholder.svg';

const IMAGE_SIZES = ['32x32', '64x64', '128x128', '256x256', '512x512', '1024x1024'];

interface LazyImageProps extends ImageProps {
  /** Passed to the <img /> tag refers to when should the image be loaded */
  loading?: 'eager' | 'lazy';
  customSize?: '32x32' | '64x64' | '128x128' | '256x256' | '512x512' | '1024x1024';
  alt?: string;
}

export const LazyImage: React.FC<LazyImageProps> = ({ src, onError, customSize, ...props }) => {
  const customSizeSrc = useMemo(() => {
    if (!customSize) return src;
    if (!src || !src.includes || typeof src.includes !== 'function') return src;
    for (let sizeIndex = 0; sizeIndex < IMAGE_SIZES.length; sizeIndex++) {
      if (src.includes(`_${IMAGE_SIZES[sizeIndex]}.`)) {
        return src.replace(`_${IMAGE_SIZES[sizeIndex]}.`, `_${customSize}.`);
      }
    }
    return src;
  }, [src]);

  const handleOnError = (event: any) => {
    if (customSizeSrc === src) {
      if (onError) {
        onError(event);
      } else {
        event.target.src = LogoPlaceholder;
      }
    } else {
      if (event.target.src.includes(encodeURI(customSizeSrc))) {
        event.target.src = src;
      } else if (event.target.src.includes(encodeURI(src))) {
        if (onError) {
          onError(event);
        } else {
          event.target.src = LogoPlaceholder;
        }
      }
    }
  };

  return (
    <Image
      onError={handleOnError}
      src={customSizeSrc}
      loading={props.loading || 'lazy'}
      alt={props?.alt || 'image unavailable'}
      {...props}
      style={{
        objectFit: 'cover',
        ...(props.style || {}),
        willChange: 'width',
      }}
    />
  );
};

export default LazyImage;
