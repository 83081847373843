import React, { useState, SyntheticEvent, useEffect, useRef, useMemo, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import {
  ContinueButton,
  HFContainer,
  MandatoryInputLabel,
  NoBackgroundButton,
  QuestionContainer,
  QuestionTitle,
  SDGCard,
  SGDContainer,
  StyledForm,
  StyledPaper,
} from '../styled-components/common';
import { useHistory } from 'react-router-dom';
import { getDevelopmentStateList } from '../../services/api/helper';
import { map, append, find, filter, includes } from 'ramda';
import { fetchCountriesAsync, fetchSDGsAsync, fetchProjectCategoriesAsync } from '../../redux/actions/account';
import { Community, Country, SDG, NewProject, Form as FormI, MyStuffItem } from 'redux/types/account';
import { Flag } from '../../redux/types/enums';
import { toast } from '../common/toast';
import BigSdgPopup from '../common/big-sdg-popup';
import ViewForm from 'components/forms/view-form';
import { FormDropdown, StyledMenuItem, StyledInputBase } from 'components/common/popup-filter-type-and-field';
import { InputBase, Tooltip } from '@material-ui/core';
import { isMissingAnswers, uploadSelectedFiles } from 'util/utils';
import { SelectedFile } from 'redux/types/file-upload';
import { clearSelectedFile } from 'redux/actions/file-upload';
import { MAX_FILE_SIZE } from 'redux/types/default-types-values';
import InfoIcon from '@material-ui/icons/Info';
import SelectDropdown from 'components/common/MuiDropdown';
import { theme } from 'components/styled-components/babele-theme';
import defaultProjectPlaceholder from '../../assets/common/project-placeholder.svg';
import blueStar from '../../assets/common/Blue_star.svg';
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const Description = styled.span`
  font-size: 0.9em;
  text-align: left;
`;

export const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  width: 100% !important;

  background: ${props => props.theme.colors.blue.veryLight};
  padding: 0.5em;
  border-radius: 0.2em;
  border: 0.125em solid ${props => props.theme.colors.blue.veryLight};
  :focus {
    background: white;
    border: 0.125em solid ${props => props.theme.colors.blue.normal};
  }
`;

const StyledLabel = styled.span`
  font-size: 0.9em;
  color: ${props => props.theme.colors.black};
  font-weight: 450;
`;

const TooltipTitle = styled('div')`
  max-width: 40em;
  height: 6em;
  border-radius: 0.8em;
  font-size: 1.9em;
  padding: 0.8em 0.5em;
  line-height: 1.1em;
  background-color: transparent;
  font-weight: 700;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  countries: state.countries,
  fetchCountriesFlag: state.loading.fetchCountriesFlag,
  sdgs: state.sdgs,
  fetchSDGsFlag: state.loading.fetchSDGsFlag,
  projectCategories: state.projectCategories,
  fetchProjectCategoriesFlag: state.loading.fetchProjectCategoriesFlag,
  bearer: state.account.session.session.bearer,
  selectedFiles: state.selectedFiles,
  account: state.account.details.user,
});

const mapDispatchToProps = {
  fetchCountries: fetchCountriesAsync.request,
  fetchSDGs: fetchSDGsAsync.request,
  fetchProjectCategories: fetchProjectCategoriesAsync.request,
  clearSelectedFiles: clearSelectedFile,
};

type dispatchType = typeof mapDispatchToProps;

enum PROJECT_FORM_STEPS {
  BASIC = 1,
  BUSINESS_MODEL = 2,
  SDGS = 3,
  LOCATION_AND_SOCIAL = 4,
  ADDITIONAL_INFO = 5,
}

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  createProject?: (param: { bearer: string; project: NewProject }) => void;
  community?: Community;
  communityId?: number;
  showSteps?: number[];
  formMode?: boolean;
  updateFormMode?: (project: NewProject | undefined) => void;
  setProjectId?: (id: number | null) => void;
  defaultProject?: NewProject;
  showMandatoryQuestions?: boolean;
  additionalInfo?: FormI | null;
  isFormSubmitting: boolean;
  defaultProjectId?: number | null;
}

const NewProjectForm: React.FC<Props> = ({
  createProject,
  community,
  bearer,
  literals,
  countries,
  fetchCountries,
  fetchCountriesFlag,
  sdgs,
  additionalInfo,
  fetchSDGs,
  fetchSDGsFlag,
  projectCategories,
  fetchProjectCategories,
  fetchProjectCategoriesFlag,
  showSteps,
  formMode,
  updateFormMode,
  setProjectId,
  defaultProject,
  communityId,
  showMandatoryQuestions,
  isFormSubmitting,
  selectedFiles,
  clearSelectedFiles,
  account,
  defaultProjectId,
}) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [isFilesUploading, setIsFilesUploading] = useState<boolean>(false);
  const [submitFlag, setSubmitFlag] = useState<boolean>(false);
  const lastStep = useRef<number>(PROJECT_FORM_STEPS.LOCATION_AND_SOCIAL);
  const updatedLiterals = useMemo(() => literals, [literals]);
  // select options
  const categories = map(c => ({ key: c.id, value: c.id, text: c.name }), projectCategories);
  const stages = map(
    d => ({ key: d.key, value: d.key, text: d.value }),
    getDevelopmentStateList()[community?.developmentStageType || 1],
  );
  const SDGs = useMemo(() => sdgs || [], [sdgs]);
  const mandatoryString = showMandatoryQuestions ? '*' : '';
  const userProjectsInCommunity: MyStuffItem[] = useMemo(
    () =>
      communityId
        ? account?.ownedProjects?.filter((x: MyStuffItem) => x.communityIds.indexOf(communityId) !== -1) ?? []
        : [],
    [account?.ownedProjects, communityId],
  );

  const defaultProjectIdFromOwnedProjects: number | undefined = useMemo(
    () => userProjectsInCommunity.find((x: MyStuffItem) => x.id === defaultProjectId)?.id,
    [defaultProjectId, userProjectsInCommunity],
  );
  const [existingSelectedProject, setExistingSelectedProject] = useState<number | null>(
    defaultProjectIdFromOwnedProjects || null,
  );
  const [applyingWithExistingProject, setApplyingWithExistingProject] = useState<boolean>(
    !!defaultProjectIdFromOwnedProjects,
  );

  const [newProject, setNewProject] = useState<NewProject>({
    communityId: community?.id || communityId || 0,
    name: defaultProject?.name || '',
    description: defaultProject?.description || '',
    categoryId: defaultProject?.categoryId || categories[0]?.value || 1,
    developmentStage: community?.isDevelopmentStageVisible ? defaultProject?.developmentStage || stages[0].value : '',
    methodologyId: defaultProject?.methodologyId || 0,
    SDGs: defaultProject?.SDGs || [],
    countryId: defaultProject?.countryId || (countries[0] && countries[0].id) || 2,
    city: defaultProject?.city || '',
    websiteUrl: defaultProject?.websiteUrl || '',
    facebookUrl: defaultProject?.facebookUrl || '',
    linkedInUrl: defaultProject?.linkedInUrl || '',
  });
  const countriesOptions = countries.map((c: Country) => ({ key: c.id, value: c.id, text: c.name }));

  const isFileLimitExceeded = useMemo(
    () => selectedFiles.some((selectedFile: SelectedFile) => selectedFile.file.size > MAX_FILE_SIZE),
    [selectedFiles],
  );
  useEffect(() => {
    if (!existingSelectedProject && defaultProjectIdFromOwnedProjects) {
      setExistingSelectedProject(defaultProjectIdFromOwnedProjects);
      setApplyingWithExistingProject(true);
      if (setProjectId) {
        setProjectId(existingSelectedProject);
        if (updateFormMode) updateFormMode(undefined);
      }
    }
  }, [defaultProjectIdFromOwnedProjects]);
  useEffect(() => {
    if (applyingWithExistingProject) {
      if (setProjectId) setProjectId(existingSelectedProject);

      if (updateFormMode) updateFormMode(undefined);
    } else {
      if (updateFormMode) updateFormMode(newProject);

      if (setProjectId) setProjectId(null);
    }
  }, [applyingWithExistingProject, existingSelectedProject]);

  useEffect(() => {
    if (updateFormMode) {
      updateFormMode(newProject);
      if (setProjectId) setProjectId(null);
    }
  }, [newProject]);

  useEffect(() => {
    if (additionalInfo?.questions?.length) {
      lastStep.current = 5;
      setNewProject({ ...newProject, form: additionalInfo });
    }
  }, [additionalInfo]);

  useEffect(() => {
    if (projectCategories.length === 0 && fetchProjectCategoriesFlag !== Flag.Request) {
      fetchProjectCategories({ bearer });
    }
  }, []);

  useEffect(() => {
    !sdgs.length && fetchSDGsFlag !== Flag.Request && fetchSDGs({ bearer });
  }, [sdgs, fetchSDGsFlag]);

  useEffect(() => {
    if (countries.length === 0 && fetchCountriesFlag !== Flag.Request) {
      fetchCountries({ bearer });
    }
  }, []);

  const onCancel = () => history.goBack();

  const onBack = () => setStep(step - 1);

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (isAdditionalInfoPage()) {
      setSubmitFlag(true);
      if (isMissingAnswers(newProject.form?.questions || [], newProject.answers || [])) return;
    }
    let answers = newProject.answers;
    if (selectedFiles.length) {
      setIsFilesUploading(true);
      const uploadedFiles = await uploadSelectedFiles(selectedFiles, bearer);
      if (!uploadedFiles) {
        toast(updatedLiterals.upload_resource_too_big_message, { type: 'error' });
        setIsFilesUploading(false);
        return;
      }
      setIsFilesUploading(false);
      clearSelectedFiles();
      if (uploadedFiles) {
        answers = (newProject.answers || []).map(answer => {
          const matchedAnswerIndex = selectedFiles.findIndex(
            (file: SelectedFile) => file.question.id === answer.formQuestionId,
          );
          if (matchedAnswerIndex > -1) return { ...answer, answer: uploadedFiles[matchedAnswerIndex] };
          return answer;
        });
      }
    }
    if (!community?.isSDGsVisible && step === PROJECT_FORM_STEPS.BUSINESS_MODEL) setStep(step + 2);
    else if (step === PROJECT_FORM_STEPS.SDGS && newProject.SDGs.length === 0)
      toast(updatedLiterals.create_project_service_invalid_sdgs, { type: 'warning' });
    else if (step < lastStep.current) setStep(step + 1);
    else {
      if (createProject)
        createProject({
          bearer,
          project: { ...newProject, answers },
        });
    }
  };

  const handleSDGClick = (id: number) => {
    const alreadyIn = find(s => s === id, newProject.SDGs);
    if (!alreadyIn && newProject.SDGs.length >= lastStep.current) {
      toast(updatedLiterals.create_project_service_max_sdgs, { type: 'warning' });
      return;
    }
    let SDGs: number[] = [];
    if (alreadyIn) SDGs = filter(s => s !== id, newProject.SDGs);
    else SDGs = append(id, newProject.SDGs);

    setNewProject({
      ...newProject,
      SDGs: SDGs,
    });
  };

  const isAdditionalInfoPage = () => {
    return (
      (showSteps?.length
        ? showSteps.indexOf(PROJECT_FORM_STEPS.ADDITIONAL_INFO) !== -1
        : step === PROJECT_FORM_STEPS.ADDITIONAL_INFO) && additionalInfo
    );
  };

  const menuItems = useMemo(() => {
    const options = userProjectsInCommunity.map((x: MyStuffItem) => {
      return {
        key: x.id,
        value: x.id,
        text: x.name,
        img: x.logo || defaultProjectPlaceholder,
      };
    });
    return [
      ...options,
      {
        key: 0,
        value: 0,
        text: <span style={{ color: theme.colors.blue.normal }}>{updatedLiterals.label_start_new_application}</span>,
        img: blueStar,
      },
    ];
  }, [userProjectsInCommunity]);

  return (
    <StyledForm onSubmit={onSubmit}>
      {userProjectsInCommunity && userProjectsInCommunity.length > 0 && (
        <>
          <StyledPaper style={{ justifyContent: 'space-around' }}>
            <Tooltip
              title={<TooltipTitle>{literals.existing_startup_info_message}</TooltipTitle>}
              placement="top-start"
            >
              <InfoIcon color="primary" fontSize="large" />
            </Tooltip>
            <StyledLabel>{updatedLiterals.label_apply_with_existing_startup}</StyledLabel>
            <SelectDropdown
              menuItems={menuItems}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setExistingSelectedProject(e.target.value as number);
                if (e.target.value === 0) {
                  setApplyingWithExistingProject(false);
                  return;
                }
                setApplyingWithExistingProject(true);
              }}
              value={existingSelectedProject}
              styles={{ background: theme.colors.white, width: '16em' }}
              label={updatedLiterals.requests_choose_project}
            />
          </StyledPaper>
        </>
      )}
      {applyingWithExistingProject ? null : (
        <>
          {!formMode && (
            <HFContainer>
              <NoBackgroundButton type="button" onClick={onCancel}>
                <span>{updatedLiterals.create_project_01_cancel_button}</span>
                <i className="material-icons">close</i>
              </NoBackgroundButton>
              {step > PROJECT_FORM_STEPS.BASIC && (
                <NoBackgroundButton type="button" onClick={onBack}>
                  <i className="material-icons">arrow_back</i>
                  <span>{updatedLiterals.create_project_02_back_button}</span>
                </NoBackgroundButton>
              )}
            </HFContainer>
          )}
          {!applyingWithExistingProject &&
            (showSteps?.length
              ? showSteps.indexOf(PROJECT_FORM_STEPS.BASIC) !== -1
              : step === PROJECT_FORM_STEPS.BASIC) && (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2rem', textAlign: 'left' }}>
                {!formMode && <h2>{updatedLiterals.create_project_service_in_platform}</h2>}
                <QuestionContainer>
                  <QuestionTitle id="project-name">
                    {updatedLiterals.create_project_01_title}
                    {mandatoryString}
                  </QuestionTitle>
                  <StyledInputBase
                    required
                    maxLength={50}
                    placeholder={updatedLiterals.input_character_limit_under.replace('{0}', '30')}
                    value={newProject.name}
                    onChange={event => setNewProject({ ...newProject, name: event.target.value })}
                  />
                  {showMandatoryQuestions && isFormSubmitting && !newProject.name && (
                    <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                  )}
                </QuestionContainer>
                <QuestionContainer>
                  <QuestionTitle id="project-description">
                    {updatedLiterals.project_edit_project_short_description}
                    {mandatoryString}
                  </QuestionTitle>
                  <StyledTextarea
                    required
                    rows={3}
                    maxLength={150}
                    placeholder={updatedLiterals.input_character_limit_under.replace('{0}', '150')}
                    value={newProject.description}
                    onChange={event => setNewProject({ ...newProject, description: event.target.value })}
                  />
                  {showMandatoryQuestions && isFormSubmitting && !newProject.description && (
                    <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                  )}
                </QuestionContainer>
                <QuestionContainer>
                  <QuestionTitle id="project-categoryId">
                    {updatedLiterals.project_edit_project_industry}
                    {mandatoryString}
                  </QuestionTitle>
                  <FormDropdown
                    autoWidth
                    onChange={(_e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                      setNewProject({ ...newProject, categoryId: _e.target.value as number });
                    }}
                    renderValue={() => {
                      if (!newProject.categoryId) {
                        return updatedLiterals.create_project_02_choose_category_title;
                      }
                      return (
                        (categories || []).find((category: any) => category.value === newProject.categoryId)?.text || ''
                      );
                    }}
                    value={newProject.categoryId}
                    input={<InputBase />}
                  >
                    {categories.map(category => (
                      <StyledMenuItem key={category.key} value={category.value}>
                        {category.text}
                      </StyledMenuItem>
                    ))}
                  </FormDropdown>
                  {showMandatoryQuestions && isFormSubmitting && !newProject.categoryId && (
                    <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                  )}
                </QuestionContainer>
                {(!community || community?.isDevelopmentStageVisible) && (
                  <QuestionContainer>
                    <QuestionTitle id="project-developmentStage">
                      {updatedLiterals.create_project_03_choose_development_stage_title}
                      {mandatoryString}
                    </QuestionTitle>
                    <FormDropdown
                      autoWidth
                      onChange={(_e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                        setNewProject({ ...newProject, developmentStage: _e.target.value as string });
                      }}
                      renderValue={() => {
                        if (!newProject.developmentStage) {
                          return updatedLiterals.create_project_03_development_stage_placeholder;
                        }
                        return (
                          (stages || []).find((stage: any) => stage.value === newProject.developmentStage)?.text || ''
                        );
                      }}
                      value={newProject.developmentStage}
                      input={<InputBase />}
                    >
                      {stages.map(stage => (
                        <StyledMenuItem key={stage.key} value={stage.value}>
                          {stage.text}
                        </StyledMenuItem>
                      ))}
                    </FormDropdown>
                    {showMandatoryQuestions && isFormSubmitting && !newProject.developmentStage && (
                      <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                    )}
                  </QuestionContainer>
                )}
              </div>
            )}
          {(showSteps?.length
            ? showSteps.indexOf(PROJECT_FORM_STEPS.BUSINESS_MODEL) !== -1
            : step === PROJECT_FORM_STEPS.BUSINESS_MODEL) && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2rem', textAlign: 'left' }}>
              <h2>{updatedLiterals.business_model_template_title}</h2>
              <Description>{updatedLiterals.create_project_02_business_model_template_description}</Description>
              {community?.methodologies
                ?.filter(({ isActive }) => isActive)
                ?.map(m => {
                  return (
                    <Row key={m.id}>
                      <input
                        required
                        type="radio"
                        name="methodology"
                        id={m.id.toString()}
                        value={newProject.methodologyId}
                        style={{ marginRight: '.5em' }}
                        checked={m.id === newProject.methodologyId}
                        onChange={event => setNewProject({ ...newProject, methodologyId: m.id })}
                      />
                      <label htmlFor={m.id.toString()}>{m.name}</label>
                      {showMandatoryQuestions && isFormSubmitting && !newProject.methodologyId && (
                        <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                      )}
                    </Row>
                  );
                })}
            </div>
          )}
          {(showSteps?.length ? showSteps.indexOf(PROJECT_FORM_STEPS.SDGS) !== -1 : step === PROJECT_FORM_STEPS.SDGS) &&
            (!community || community?.isSDGsVisible) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '0.5rem',
                  textAlign: 'left',
                }}
              >
                {!formMode ? (
                  <QuestionContainer>
                    <h2 id="project-SDGs">
                      {updatedLiterals.create_project_05_choose_sdgs}
                      {mandatoryString}
                    </h2>
                    <Description>{updatedLiterals.create_project_05_sdg_description}</Description>
                    <Description style={{ fontSize: '0.8em' }}>
                      {updatedLiterals.create_project_05_sdg_limit_description}
                    </Description>
                  </QuestionContainer>
                ) : (
                  <QuestionTitle id="project-SDGs">
                    {updatedLiterals.create_project_05_choose_sdgs}
                    {mandatoryString}
                  </QuestionTitle>
                )}
                <SGDContainer>
                  {SDGs.map((sdg: SDG) => {
                    return (
                      <BigSdgPopup
                        hideImage={true}
                        key={sdg.id.toString()}
                        sdg={sdg}
                        trigger={
                          <SDGCard
                            key={sdg.id}
                            src={sdg.iconName}
                            active={includes(sdg.id, newProject.SDGs)}
                            onClick={() => handleSDGClick(sdg.id)}
                          />
                        }
                      />
                    );
                  })}
                </SGDContainer>
                {formMode && (
                  <Description style={{ fontSize: '0.8em' }}>
                    {updatedLiterals.create_project_05_sdg_limit_description}
                  </Description>
                )}
                {showMandatoryQuestions && isFormSubmitting && (!newProject.SDGs || newProject.SDGs.length === 0) && (
                  <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                )}
              </div>
            )}
          {(showSteps?.length
            ? showSteps.indexOf(PROJECT_FORM_STEPS.LOCATION_AND_SOCIAL) !== -1
            : step === PROJECT_FORM_STEPS.LOCATION_AND_SOCIAL) && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2rem', textAlign: 'left' }}>
              {!formMode && <h2>{updatedLiterals.create_project_04_additional_information_title}</h2>}
              {!formMode && <Description>{updatedLiterals.create_project_06_location_title}</Description>}
              <Row style={{ gap: '1rem' }}>
                <Column>
                  <QuestionTitle id="project-countryId">
                    {updatedLiterals.create_project_06_location_country}
                    {mandatoryString}
                  </QuestionTitle>
                  <FormDropdown
                    onChange={(_e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                      setNewProject({ ...newProject, countryId: _e.target.value as number });
                    }}
                    renderValue={() => {
                      if (!newProject.countryId) {
                        return updatedLiterals.global_dropdown_select_country;
                      }
                      return (
                        (countriesOptions || []).find((country: any) => country.value === newProject.countryId)?.text ||
                        ''
                      );
                    }}
                    value={newProject.countryId}
                    input={<InputBase />}
                  >
                    {countriesOptions.map((country: any) => (
                      <StyledMenuItem key={country.key} value={country.value}>
                        {country.text}
                      </StyledMenuItem>
                    ))}
                  </FormDropdown>
                  {showMandatoryQuestions && isFormSubmitting && !newProject.countryId && (
                    <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                  )}
                </Column>
                <Column>
                  <QuestionTitle id="project-city">
                    {updatedLiterals.create_project_06_location_city}
                    {mandatoryString}
                  </QuestionTitle>
                  <StyledInputBase
                    required
                    maxLength={30}
                    placeholder={updatedLiterals.create_project_06_location_city}
                    value={newProject.city}
                    onChange={event => setNewProject({ ...newProject, city: event.target.value })}
                  />
                  {showMandatoryQuestions && isFormSubmitting && !newProject.city && (
                    <MandatoryInputLabel>{updatedLiterals.forms_mandatory_answer}</MandatoryInputLabel>
                  )}
                </Column>
              </Row>
              {(!community ||
                community.isWebSiteUrlVisible ||
                community.isFacebookUrlVisible ||
                community.isLinkedinUrlVisible) && (
                <>
                  <Description>{updatedLiterals.create_project_06_connectivity_title}</Description>
                  {community?.isWebSiteUrlVisible && (
                    <QuestionContainer>
                      <QuestionTitle>{updatedLiterals.create_project_06_conectivity_website}</QuestionTitle>
                      <StyledInputBase
                        maxLength={255}
                        placeholder="https://"
                        value={newProject.websiteUrl}
                        type="url"
                        onChange={event => setNewProject({ ...newProject, websiteUrl: event.target.value })}
                      />
                    </QuestionContainer>
                  )}
                  {community?.isFacebookUrlVisible && (
                    <QuestionContainer>
                      <QuestionTitle>{updatedLiterals.create_project_06_connectivity_facebook}</QuestionTitle>
                      <StyledInputBase
                        maxLength={255}
                        placeholder="https://facebook.com"
                        value={newProject.facebookUrl}
                        type="url"
                        onChange={event => setNewProject({ ...newProject, facebookUrl: event.target.value })}
                      />
                    </QuestionContainer>
                  )}
                  {community?.isLinkedinUrlVisible && (
                    <QuestionContainer>
                      <QuestionTitle>{updatedLiterals.create_project_06_connectivity_linkedin}</QuestionTitle>
                      <StyledInputBase
                        maxLength={255}
                        placeholder="https://linkedin.com"
                        value={newProject.linkedInUrl}
                        type="url"
                        onChange={event => setNewProject({ ...newProject, linkedInUrl: event.target.value })}
                      />
                    </QuestionContainer>
                  )}
                </>
              )}
            </div>
          )}
          {(showSteps?.length
            ? showSteps.indexOf(PROJECT_FORM_STEPS.ADDITIONAL_INFO) !== -1
            : step === PROJECT_FORM_STEPS.ADDITIONAL_INFO) &&
            additionalInfo && (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2rem', textAlign: 'left' }}>
                <h2>{updatedLiterals.additional_questions}</h2>
                <ViewForm
                  viewMode={false}
                  hideDetails
                  form={additionalInfo}
                  defaultAnswers={[]}
                  style={{ paddingBottom: '0em' }}
                  setAnswers={answers =>
                    setNewProject({
                      ...newProject,
                      answers: answers.map(answer => ({ ...answer, formId: additionalInfo.id })),
                    })
                  }
                  isFilesUploading={isFilesUploading}
                  isFormSubmitting={submitFlag}
                />
              </div>
            )}
          {(!showSteps || step === PROJECT_FORM_STEPS.SDGS) && (
            <>
              {' '}
              <HFContainer>
                {!showSteps && (
                  <ContinueButton type="submit" disabled={isFileLimitExceeded}>
                    <span>
                      {step < lastStep.current
                        ? updatedLiterals.profile_wizard_short_next_button
                        : updatedLiterals.menu_create_project}
                    </span>
                    <i className="material-icons">arrow_forward</i>
                  </ContinueButton>
                )}
                {step === PROJECT_FORM_STEPS.SDGS && <span>{updatedLiterals.create_project_service_invalid_sdgs}</span>}
              </HFContainer>
            </>
          )}
        </>
      )}
    </StyledForm>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectForm);
