import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface CustomSelectProps {
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  menuItems: Array<{ key: string | number; value: string | number; text: string | ReactNode }>;
  value: any;
}

const SelectContainer = styled.select`
  display: flex;
  flex-direction: column;
  width: 11em;
  overflow-y: auto;
  border: none;
  outline: 0;
`;

const Option = styled.option<{ isSelected: boolean }>`
  padding: 10px;
  white-space: break-spaces;
  cursor: pointer;
  text-align: left;
  color: inherit;
  font-size: 0.8em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background: ${({ isSelected }) => (isSelected ? 'rgba(0, 0, 0, 0.08)' : 'transparent')};
  :hover {
    background: ${({ isSelected }) =>
      isSelected ? 'rgba(0, 0, 0, 0.08) !important' : 'rgba(0, 0, 0, 0.04) !important'};
  }
`;

const CustomSelect: React.FC<CustomSelectProps> = ({ menuItems, value, onChange }) => {
  const handleOptionClick = (optionValue: string | number) => {
    const isSelected = value.includes(optionValue);
    let updatedValue: (string | number)[] = [];

    if (isSelected) {
      updatedValue = value.filter((val: string) => val !== optionValue);
    } else {
      updatedValue = [...value, optionValue];
    }

    onChange({
      target: { value: updatedValue },
    } as React.ChangeEvent<{ value: unknown }>); // Manually create the event object
  };

  return (
    <SelectContainer multiple={true} value={value} onChange={() => {}}>
      {menuItems?.map(option => (
        <Option
          isSelected={value.includes(option.value)}
          key={option.key}
          value={option.value}
          onClick={() => handleOptionClick(option.value)}
        >
          {option.text}
        </Option>
      ))}
    </SelectContainer>
  );
};

export default CustomSelect;
