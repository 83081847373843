import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { MyStuffItem, Country, SDG } from 'redux/types/account';
import { UserProfile } from 'UsersModels';
import { connect } from 'react-redux';
import { map } from 'ramda';
import MessageModal from '../../components/common/message-modal.jsx';
import { fetchUserProfileAsync, followUserAsync, unfollowUserAsync } from '../../redux/actions/users';
import { RootState } from 'StoreModel';
import NavigationHeader from '../../components/common/modal-navigation';
import {
  SecondaryButton,
  Separator,
  NoBackgroundButton,
  Column,
  SgdContainer,
  StyledFlex,
} from '../../components/styled-components/common';
import { MediumLogo } from '../../components/common/logo';
import UserCard from '../../components/common/user-card';
import { Helmet } from 'react-helmet';

import { Flag } from '../../redux/types/enums';
import { toast } from '../../components/common/toast';
import BigSdgPopup from '../../components/common/big-sdg-popup';
import { fetchCommunitySignupDetails } from '../../services/api/community';
import SchedulerOneOnOneMeetingModal from '../../components/common/scheduler-one-on-one-meeting-modal/scheduler-one-on-one-meeting-modal';
import InnerHtmlWrapper from 'components/editor/InnerHtmlWrapper';
import '../../stylesheets/ck-editor.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70em;
`;

const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 40em;
  padding: 1em 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const SecondaryParagraph = styled.p`
  font-size: 1em;
  line-height: 1.6em;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin: 0;
`;

const CommunityName = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  font-weight: bold;
  margin-bottom: 0;
`;

const CommunityShortDesc = styled.p`
  font-size: 0.8em;
  line-height: 1.5em;
`;

const ItemContainer = styled(Row)`
  padding: 0.7em 0;
  width: 100%;
  align-items: center;

  a {
    margin-right: 1em;
  }
`;

const ItemName = styled.p`
  font-size: 1em;
  line-height: 1.6em;
  font-weight: bold;
  margin-bottom: 0;
`;

const ItemDescription = styled.p`
  font-size: 0.8em;
  line-height: 1.5em;
  margin-top: 0.5em;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  &&& {
    color: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.black};
  }
`;

const SdgImg = styled.img`
  height: 7em;
  width: 7em;
  border-radius: 3px;
`;

const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const StyledHThree = styled.h3`
  margin: 0;
`;

const mapDispatchToProps = {
  fetchUserProfile: fetchUserProfileAsync.request,
  followUser: followUserAsync.request,
  unfollowUser: unfollowUserAsync.request,
};

const mapStateToProps = (state: RootState) => ({
  userProfiles: state.users.list,
  followUserFlag: state.loading.followUserFlag,
  unfollowUserFlag: state.loading.unfollowUserFlag,
  currentUser: state.account.details.user,
  currentUserCommunities: state.account.communities.list,
  isFetchingUserProfile: state.loading.fetchUserProfileFlag,
  currentUserOwnedProjects: state.account.details.user.ownedProjects,
  literals: state.literals,
});

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  bearer: string;
}

const UserProfilePage = (props: Props) => {
  const { userId } = useParams<{ userId: string }>();
  const { followUserFlag, unfollowUserFlag, literals, isFetchingUserProfile } = props;
  const [showMore, setShowMore] = useState(true);
  const [showMoreProjects, setShowMoreProjects] = useState(true);
  const [messageModal, setMessageModal] = useState<{ name: string; id: number } | undefined>(undefined);
  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);
  const [community, setCommunity] = useState<any>(null);

  useEffect(() => {
    props.fetchUserProfile({ bearer: props.bearer, id: Number(userId) });
  }, [userId]);

  useEffect(() => {
    if (!props.currentUserOwnedProjects && isFetchingUserProfile === Flag.Success) {
      props.fetchUserProfile({ bearer: props.bearer, id: props.currentUser.id });
    }
  }, [props.currentUserOwnedProjects, isFetchingUserProfile]);

  const profile = props.userProfiles.find((profile: UserProfile) => profile.id === Number(userId));
  const allProjects: MyStuffItem[] = profile?.projects;

  useEffect(() => {
    if (profile)
      fetchCommunitySignupDetails(profile?.primaryCommunityId || 0)
        .then(userCommunityDetails => {
          setCommunity(userCommunityDetails);
        })
        .catch(() => toast('Error in getting primary community', { type: 'error' }));
  }, [profile?.id]);

  const toggleFollowUser = () => {
    if (profile.isFollowedByMe) {
      props.unfollowUser({ bearer: props.bearer, id: profile.id });
    } else {
      props.followUser({ bearer: props.bearer, id: profile.id });
    }
  };

  if (!profile) return null;

  return (
    <React.Fragment>
      <Container>
        <Helmet>
          <title>{profile?.name || 'Profile'}</title>
        </Helmet>
        <NavigationHeader />
        {profile && (
          <ProfileContainer>
            <CenteredColumn>
              <UserCard
                showSendMessage={props.currentUser.id !== Number(userId)}
                sendMessage={() => {
                  setMessageModal(profile);
                }}
                scheduleMeeting={() => {
                  if (!profile.primaryCommunityId && !props.currentUser.primaryCommunityId) {
                    toast(literals.events_private_meeting_no_community_error, { type: 'warning' });
                  } else {
                    setIsEventModalOpen(true);
                  }
                }}
                toggleFollowUser={toggleFollowUser}
                profile={{ ...profile }}
                isLoadingFollowButton={followUserFlag === Flag.Request || unfollowUserFlag === Flag.Request}
              />
              <h2>{literals.menu_communities}</h2>
              <Column style={{ alignItems: 'center' }}>
                {profile.communities.length === 0 ? (
                  <div style={{ alignSelf: 'start' }}>{literals.empty_communities_list_label}</div>
                ) : (
                  map(
                    community => (
                      <ItemContainer key={community.id}>
                        <Link to={`/community/${community.id}`}>
                          <MediumLogo src={community.photo} />
                        </Link>
                        <Column style={{ width: '80%' }}>
                          <CommunityName>{community.name}</CommunityName>
                          <CommunityShortDesc dangerouslySetInnerHTML={{ __html: community.shortDescription }} />
                        </Column>
                        <SecondaryButton disabled color="black">
                          {community.circleName}
                        </SecondaryButton>
                      </ItemContainer>
                    ),
                    showMore ? profile.communities.slice(0, 5) : profile.communities,
                  )
                )}
                {profile?.communities?.length > 5 && (
                  <NoBackgroundButton onClick={() => setShowMore(!showMore)}>
                    {showMore ? literals.community_tag_list_load_more : literals.collapsable_content_collapse}
                  </NoBackgroundButton>
                )}
              </Column>
              <h2>{literals.menu_projects}</h2>
              <Column style={{ alignItems: 'center' }}>
                {allProjects.length === 0 ? (
                  <div style={{ alignSelf: 'start' }}>{literals.empty_projects_list_label}</div>
                ) : (
                  map(
                    project => (
                      <ItemContainer key={project.id}>
                        <Link to={`/project/${project.id}`}>
                          <MediumLogo src={project.logoImage} />
                        </Link>
                        <Column style={{ width: '80%' }}>
                          <ItemName>{project.name}</ItemName>
                          <ItemDescription>{project.description}</ItemDescription>
                        </Column>
                        <SecondaryButton disabled color="black">
                          {project.circleName}
                        </SecondaryButton>
                      </ItemContainer>
                    ),
                    showMoreProjects ? allProjects.slice(0, 5) : allProjects,
                  )
                )}
                {allProjects?.length > 5 && (
                  <NoBackgroundButton onClick={() => setShowMoreProjects(!showMoreProjects)}>
                    {showMoreProjects ? literals.community_tag_list_load_more : literals.collapsable_content_collapse}
                  </NoBackgroundButton>
                )}
              </Column>
              <h2>{literals.menu_my_profile}</h2>
              <StyledProfileContainer>
                {profile?.company && (
                  <StyledFlex style={{ gap: '1em' }}>
                    <StyledHThree>{literals.user_header_company_placeholder}: </StyledHThree>
                    <SecondaryParagraph>{profile.company}</SecondaryParagraph>
                  </StyledFlex>
                )}
                <StyledHThree style={{ marginTop: 0 }}>{literals.about_me_about_me_title}</StyledHThree>
                <InnerHtmlWrapper style={{ textAlign: 'left' }} html={profile.biography} />

                <StyledHThree>{literals.about_me_interests_title}</StyledHThree>
                <Row style={{ flexWrap: 'wrap' }}>
                  {profile.interests.length > 0 ? (
                    map(
                      interest => (
                        <StyledSecondaryButton key={interest} disabled={true}>
                          {interest}
                        </StyledSecondaryButton>
                      ),
                      profile.interests,
                    )
                  ) : (
                    <SecondaryParagraph>{literals.about_me_without_interests_message}</SecondaryParagraph>
                  )}
                </Row>
                <StyledHThree>{literals.about_me_skills_title}</StyledHThree>
                <Row style={{ flexWrap: 'wrap' }}>
                  {profile.skills.length > 0 ? (
                    map(
                      skill => (
                        <StyledSecondaryButton key={skill} disabled={true}>
                          {skill}
                        </StyledSecondaryButton>
                      ),
                      profile.skills,
                    )
                  ) : (
                    <SecondaryParagraph>{literals.about_me_without_skills_messages}</SecondaryParagraph>
                  )}
                </Row>

                <StyledHThree>{literals.request_market_expertise}</StyledHThree>
                <Row>
                  {profile.marketExpertises?.length > 0 ? (
                    profile.marketExpertises?.map((country: Country) => (
                      <StyledSecondaryButton key={country.id} disabled={true}>
                        {country.name}
                      </StyledSecondaryButton>
                    ))
                  ) : (
                    <SecondaryParagraph>{literals.about_me_without_market_expertise}</SecondaryParagraph>
                  )}
                </Row>
                {community?.isUserSDGsVisible && (
                  <>
                    <StyledHThree>{literals.create_project_05_choose_sdgs}</StyledHThree>
                    <Row style={{ flexWrap: 'wrap' }}>
                      {profile.sustainableDevelopmentGoals?.length > 0 ? (
                        profile.sustainableDevelopmentGoals.map((s: SDG) => (
                          <BigSdgPopup
                            key={s.id.toString()}
                            sdg={{
                              ...s,
                              iconName: `${s.iconName}`,
                            }}
                            trigger={
                              <SgdContainer style={{ width: '7em', height: '7em', marginBottom: '0.8em' }}>
                                <SdgImg src={`${s.iconName}`} alt={s.name} style={{ borderRadius: '3px' }} />
                              </SgdContainer>
                            }
                          />
                        ))
                      ) : (
                        <SecondaryParagraph>{literals.about_me_without_sdgs}</SecondaryParagraph>
                      )}
                    </Row>
                  </>
                )}
              </StyledProfileContainer>

              <Separator />
            </CenteredColumn>
          </ProfileContainer>
        )}
      </Container>
      <MessageModal
        bearer={props.bearer}
        member={messageModal}
        setMember={setMessageModal}
        analyticsPage={'user-profile'}
      />
      <SchedulerOneOnOneMeetingModal
        guestUser={profile}
        onClose={() => setIsEventModalOpen(false)}
        showModal={isEventModalOpen}
        communityId={community?.id}
        bearer={props.bearer}
        titlePlaceholder={`${literals.schedule_meeting_modal_title} ${props.currentUser.name} / ${profile.name}`}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
