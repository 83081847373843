import { ImageType } from '../redux/types/enums';
import { getBaseUrl } from 'services/api/helper';

class UploadAdapter {
  constructor(loader, bearer) {
    this.loader = loader;
    this.bearer = bearer;
    this.url = getBaseUrl() + '/api/File/Upload?isBigFile=true';
  }

  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest(reject);
    });
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    let url = this.url;
    let headers = null;
    if (typeof this.url === 'object') {
      url = this.url.url;
      headers = this.url.headers;
    }

    xhr.withCredentials = true;
    xhr.open('POST', url, true);
    if (headers !== null) {
      for (const key in headers) {
        if (typeof headers[key] === 'function') {
          xhr.setRequestHeader(key, headers[key]());
        } else {
          xhr.setRequestHeader(key, headers[key]);
        }
      }
    }

    xhr.responseType = 'text';
  }

  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericError = `Cannot upload file: ${loader.file.name}.`;

    xhr.addEventListener('error', () => reject(genericError));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || xhr.status !== 200) {
        return reject(response && response.error && response.error.message ? response.error.message : genericError);
      }

      resolve({
        default: response,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(reject) {
    this.loader.file
      .then(file => {
        // Prepare the form data.
        const data = new FormData();
        data.append('file', file);
        data.append('folder', 'resource_files');
        // TODO: only append image type if file is an image
        data.append('imageType', ImageType.CkEditorImage.toString());
        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.
        // Send the request.
        this.xhr.setRequestHeader('Authorization', `Bearer ${this.bearer}`);
        this.xhr.send(data);
      })
      .catch(reject);
  }
}

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    const bearer = editor.config.get('bearer');
    return new UploadAdapter(loader, bearer);
  };
}

export default CustomUploadAdapterPlugin;
