import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError, tap } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError, showSuccessMessage } from './helpers';

import {
  fetchMethodologyContentAsync,
  updateMethodologyAssignmentAsync,
  deleteMethodologySectionAsync,
  deleteMethodologyAssignmentAsync,
  reorderMethodologySectionsAsync,
  reorderMethodologyAssignmentsAsync,
  saveMethodologySectionAsync,
  saveMethodologyAssignmentAsync,
  fetchMethodologyPlanningAsync,
  createTasksAsync,
  updateTasksAsync,
  updateTasksOrderAsync,
  setTaskUnlockRuleAsync,
  deleteTaskAsync,
  deleteTasksAsync,
  disableMethodologyAsync,
  deleteMethodologyAsync,
  enableMethodologyAsync,
  saveMethodologyDiffAsync,
  saveSectionsStagegateAsync,
} from '../actions/methodology';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchMethodologyContentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchMethodologyContentAsync.request)),
    switchMap(action =>
      from(
        api.methodology.fetchMethodologyContent(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(fetchMethodologyContentAsync.success),
        catchError(error => handleError(error, fetchMethodologyContentAsync)),
      ),
    ),
  );

export const fetchMethodologyPlanningEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchMethodologyPlanningAsync.request)),
    switchMap(action =>
      from(
        api.methodology.fetchMethodologyPlanningAndProjects(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(fetchMethodologyPlanningAsync.success),
        catchError(error => handleError(error, fetchMethodologyPlanningAsync)),
      ),
    ),
  );

export const reorderMethodologySectionsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(reorderMethodologySectionsAsync.request)),
    switchMap(action =>
      from(
        api.methodology.reorderMethodologySections(
          action.payload.bearer,
          action.payload.newSectionsOrder,
          action.payload.methodologyId,
          action.payload.communityId,
        ),
      ).pipe(
        map(reorderMethodologySectionsAsync.success),
        catchError(error => handleError(error, reorderMethodologySectionsAsync)),
      ),
    ),
  );

export const reorderMethodologyAssignmentsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(reorderMethodologyAssignmentsAsync.request)),
    switchMap(action =>
      from(
        api.methodology.reorderMethodologyAssignments(
          action.payload.bearer,
          action.payload.newAssignmentsOrder,
          action.payload.communityId,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(reorderMethodologyAssignmentsAsync.success),
        catchError(error => handleError(error, reorderMethodologyAssignmentsAsync)),
      ),
    ),
  );

export const saveMethodologySectionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveMethodologySectionAsync.request)),
    switchMap(action =>
      from(
        api.methodology.saveMethodologySection(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.newSection,
          action.payload.currentSectionsOrder,
          action.payload.sectionIndex,
          action.payload.isUpdate,
        ),
      ).pipe(
        map(saveMethodologySectionAsync.success),
        catchError(error => handleError(error, saveMethodologySectionAsync)),
      ),
    ),
  );

export const saveMethodologyAssignmentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveMethodologyAssignmentAsync.request)),
    switchMap(action =>
      from(
        api.methodology.saveMethodologyAssignment(
          action.payload.bearer,
          action.payload.newAssignment,
          action.payload.communityId,
          action.payload.currentAssignmentsOrder,
          action.payload.assignmentIndex,
        ),
      ).pipe(
        map(saveMethodologyAssignmentAsync.success),
        catchError(error => handleError(error, saveMethodologyAssignmentAsync)),
      ),
    ),
  );

export const updateMethodologyAssignmentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateMethodologyAssignmentAsync.request)),
    switchMap(action =>
      from(
        api.methodology.updateMethodologyAssignment(
          action.payload.bearer,
          action.payload.newAssignment,
          action.payload.communityId,
        ),
      ).pipe(
        map(updateMethodologyAssignmentAsync.success),
        catchError(error => handleError(error, updateMethodologyAssignmentAsync)),
      ),
    ),
  );

export const deleteMethodologyAssignmentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteMethodologyAssignmentAsync.request)),
    switchMap(action =>
      from(
        api.methodology.deleteMethodologyAssignment(
          action.payload.bearer,
          action.payload.assignment,
          action.payload.communityId,
        ),
      ).pipe(
        map(deleteMethodologyAssignmentAsync.success),
        catchError(error => handleError(error, deleteMethodologyAssignmentAsync)),
      ),
    ),
  );

export const deleteMethodologySectionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteMethodologySectionAsync.request)),
    switchMap(action =>
      from(
        api.methodology.deleteMethodologySection(
          action.payload.bearer,
          action.payload.section,
          action.payload.communityId,
        ),
      ).pipe(
        map(deleteMethodologySectionAsync.success),
        catchError(error => handleError(error, deleteMethodologySectionAsync)),
      ),
    ),
  );

export const createTasksEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createTasksAsync.request)),
    switchMap(action =>
      from(
        api.methodology.createTask(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
          action.payload.tasks,
        ),
      ).pipe(
        map(createTasksAsync.success),
        tap(() => {
          showSuccessMessage('Saved the planning changes! 🗓');
        }),
        catchError(error => handleError(error, createTasksAsync)),
      ),
    ),
  );

export const deleteTaskEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteTaskAsync.request)),
    switchMap(action =>
      from(
        api.methodology.deleteTask(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
          action.payload.taskId,
        ),
      ).pipe(
        map(deleteTaskAsync.success),
        catchError(error => handleError(error, deleteTaskAsync)),
      ),
    ),
  );

export const deleteTasksEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteTasksAsync.request)),
    switchMap(action =>
      from(
        api.methodology.deleteTasks(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
          action.payload.taskIds,
        ),
      ).pipe(
        map(deleteTasksAsync.success),
        catchError(error => handleError(error, deleteTasksAsync)),
      ),
    ),
  );

export const updateTasksEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateTasksAsync.request)),
    switchMap(action =>
      from(
        api.methodology.createTask(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
          action.payload.tasks,
        ),
      ).pipe(
        map(updateTasksAsync.success),
        tap(() => {
          showSuccessMessage('Saved the planning changes! 🗓');
        }),
        catchError(error => handleError(error, updateTasksAsync)),
      ),
    ),
  );

export const updateTasksOrderEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateTasksOrderAsync.request)),
    switchMap(action =>
      from(
        api.methodology.updateTasksOrder(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
          action.payload.tasks,
        ),
      ).pipe(
        map(updateTasksOrderAsync.success),
        catchError(error => handleError(error, updateTasksOrderAsync)),
      ),
    ),
  );

export const setTaskUnlockRuleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setTaskUnlockRuleAsync.request)),
    switchMap(action =>
      from(
        api.methodology.setTaskUnlockRule(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
          action.payload.taskUnlockRule,
        ),
      ).pipe(
        map(setTaskUnlockRuleAsync.success),
        catchError(error => handleError(error, setTaskUnlockRuleAsync)),
      ),
    ),
  );

export const disableMethodologyEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(disableMethodologyAsync.request)),
    switchMap(action =>
      from(
        api.methodology.disableMethodology(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(disableMethodologyAsync.success),
        catchError(error => handleError(error, disableMethodologyAsync)),
      ),
    ),
  );

export const deleteMethodologyEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteMethodologyAsync.request)),
    switchMap(action =>
      from(
        api.methodology.deleteMethodology(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(deleteMethodologyAsync.success),
        catchError(error => handleError(error, deleteMethodologyAsync)),
      ),
    ),
  );

export const enableMethodologyEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(enableMethodologyAsync.request)),
    switchMap(action =>
      from(
        api.methodology.enableMethodology(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(enableMethodologyAsync.success),
        catchError(error => handleError(error, enableMethodologyAsync)),
      ),
    ),
  );

export const saveMethodologyDiffEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveMethodologyDiffAsync.request)),
    switchMap(action =>
      from(
        api.methodology.saveMethodologyDiff(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.oldMethodology,
          action.payload.newMethodology,
        ),
      ).pipe(
        map(saveMethodologyDiffAsync.success),
        catchError(error => handleError(error, saveMethodologyDiffAsync)),
      ),
    ),
  );

export const saveSectionsStagegateEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveSectionsStagegateAsync.request)),
    switchMap(action =>
      from(
        api.methodology.saveSectionsStagegate(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.methodologyId,
          action.payload.sections,
        ),
      ).pipe(
        map(saveSectionsStagegateAsync.success),
        catchError(error => handleError(error, saveSectionsStagegateAsync)),
      ),
    ),
  );

export default {
  fetchMethodologyContentEpic,
  fetchMethodologyPlanningEpic,
  reorderMethodologySectionsEpic,
  reorderMethodologyAssignmentsEpic,
  saveMethodologySectionEpic,
  saveMethodologyAssignmentEpic,
  updateMethodologyAssignmentEpic,
  deleteMethodologyAssignmentEpic,
  deleteMethodologySectionEpic,
  createTasksEpic,
  deleteTaskEpic,
  deleteTasksEpic,
  updateTasksEpic,
  updateTasksOrderEpic,
  setTaskUnlockRuleEpic,
  disableMethodologyEpic,
  enableMethodologyEpic,
  deleteMethodologyEpic,
  saveMethodologyDiffEpic,
  saveSectionsStagegateEpic,
};
