const global: any = {};
export default function HackHistory(): any {
  return {
    get: () => {
      return global.history;
    },
    set: (val: any) => {
      global.history = val;
    },
  };
}
