export default function usePathParams(pathname) {
  const communityTokens = pathname.match(/community\/([0-9]+)/);
  const communityId = Number(communityTokens?.[1]) ?? 0;
  const projectTokens = pathname.match(/project\/([0-9]+)/);
  const projectId = Number(projectTokens?.[1]) ?? 0;
  const discussionTokens = pathname.match(/discussion\/([0-9]+)/);
  const discussionId = discussionTokens?.[1] ?? 0;
  const entityTokens = pathname.match(/entity\/([0-9]+)/);
  const entityId = Number(entityTokens?.[1]) ?? 0;
  const tabTokens = pathname.match(/tab\/([0-9]+)/);
  const tabId = Number(tabTokens?.[1]) ?? 0;

  return {
    tabId,
    discussionId,
    communityId,
    entityId,
    projectId,
  };
}
