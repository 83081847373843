import { createAsyncAction } from 'typesafe-actions';
import { LogInCalendarData, MeetingsSettings, MeetingsSettingsDto, UserEvent } from '../types/calendar';

export const calendarLogInOutlookAsync = createAsyncAction(
  'OUTLOOK_LOG_IN_REQUEST',
  'OUTLOOK_LOG_IN_SUCCESS',
  'OUTLOOK_LOG_IN_FAILURE',
  'OUTLOOK_LOG_IN_IDLE',
)<
  {
    bearer: string;
    authorizationCode: string;
    redirectUri: string;
  },
  {
    logInData: LogInCalendarData[];
  },
  string,
  null
>();
export const setUserCalendarOutlookAsync = createAsyncAction(
  'OUTLOOK_SET_USER_CALENDAR_REQUEST',
  'OUTLOOK_SET_USER_CALENDAR_SUCCESS',
  'OUTLOOK_SET_USER_CALENDAR_FAILURE',
  'OUTLOOK_SET_USER_CALENDAR_IDLE',
)<
  {
    bearer: string;
    calendarId: string;
  },
  {
    userEvents: UserEvent[];
  },
  string,
  null
>();
export const calendarLogOutOutlookAsync = createAsyncAction(
  'OUTLOOK_LOG_OUT_REQUEST',
  'OUTLOOK_LOG_OUT_SUCCESS',
  'OUTLOOK_LOG_OUT_FAILURE',
  'OUTLOOK_LOG_OUT_IDLE',
)<{ bearer: string }, undefined, string, null>(); // returns type undefined

// export const calendarSignInGoogleAsync = null;
export const calendarLogInGoogleAsync = createAsyncAction(
  'GOOGLE_LOG_IN_REQUEST',
  'GOOGLE_LOG_IN_SUCCESS',
  'GOOGLE_LOG_IN_FAILURE',
  'GOOGLE_LOG_IN_IDLE',
)<
  {
    bearer: string;
    authorizationCode: string;
    redirectUri: string;
  },
  {
    logInData: LogInCalendarData[];
  },
  string,
  null
>();
export const calendarLogOutGoogleAsync = createAsyncAction(
  'GOOGLE_LOG_OUT_REQUEST',
  'GOOGLE_LOG_OUT_SUCCESS',
  'GOOGLE_LOG_OUT_FAILURE',
  'GOOGLE_LOG_OUT_IDLE',
)<{ bearer: string }, undefined, string, null>();
export const setUserCalendarGoogleAsync = createAsyncAction(
  'GOOGLE_SET_USER_CALENDAR_REQUEST',
  'GOOGLE_SET_USER_CALENDAR_SUCCESS',
  'GOOGLE_SET_USER_CALENDAR_FAILURE',
  'GOOGLE_SET_USER_CALENDAR_IDLE',
)<{ bearer: string; calendarId: string }, { userEvents: UserEvent[] }, string, null>();

export const fetchMeetingsSettingsAsync = createAsyncAction(
  'FETCH_MEETING_SETTINGS_REQUEST',
  'FETCH_MEETING_SETTINGS_SUCCESS',
  'FETCH_MEETING_SETTINGS_FAILURE',
  'FETCH_MEETING_SETTINGS_IDLE',
)<
  {
    bearer: string;
    userId: number;
  },
  {
    meetingSettings: MeetingsSettings;
  },
  string,
  null
>();

export const saveMeetingsSettingsAsync = createAsyncAction(
  'SAVE_MEETING_SETTINGS_REQUEST',
  'SAVE_MEETING_SETTINGS_SUCCESS',
  'SAVE_MEETING_SETTINGS_FAILURE',
  'SAVE_MEETING_SETTINGS_IDLE',
)<
  {
    bearer: string;
    meetingSettings: MeetingsSettingsDto;
  },
  {
    meetingSettings: MeetingsSettingsDto;
  },
  string,
  null
>();

export const getUserEventsByMonthAsync = createAsyncAction(
  'FETCH_USER_EVENTS_BY_MONTH_REQUEST',
  'FETCH_USER_EVENTS_BY_MONTH_SUCCESS',
  'FETCH_USER_EVENTS_BY_MONTH_FAILURE',
  'FETCH_USER_EVENTS_BY_MONTH_IDLE',
)<
  {
    bearer: string;
    userId: number;
    month: number;
    year: number;
  },
  {
    userEvents: UserEvent[];
  },
  string,
  null
>();

export const meetingProviderSyncAsync = createAsyncAction(
  'MEETING_PROVIDER_SYNC_REQUEST',
  'MEETING_PROVIDER_SYNC_SUCCESS',
  'MEETING_PROVIDER_SYNC_FAILURE',
  'MEETING_PROVIDER_SYNC_IDLE',
)<
  { bearer: string; authorizationCode: string; redirectUri: string; meetingProviderType: 0 | 1 },
  undefined,
  string,
  null
>();

export const meetingProviderUnSyncAsync = createAsyncAction(
  'MEETING_PROVIDER_UNSYNC_REQUEST',
  'MEETING_PROVIDER_UNSYNC_SUCCESS',
  'MEETING_PROVIDER_UNSYNC_FAILURE',
  'MEETING_PROVIDER_UNSYNC_IDLE',
)<{ bearer: string }, undefined, string, null>();

export default {
  fetchMeetingsSettingsAsync,
  saveMeetingsSettingsAsync,
  getUserEventsByMonthAsync,
  calendarLogInOutlookAsync,
  calendarLogOutOutlookAsync,
  setUserCalendarOutlookAsync,
  meetingProviderSyncAsync,
  meetingProviderUnSyncAsync,
};
