import {
  AggregationFunctionsEnum,
  ChartTypesEnum,
  FormQuestionAnswerType,
  Languages,
  Privacy,
  StatsType,
} from './enums';
import { Form } from './account';
import { MeetingsSettings } from './calendar';

export const DEFAULT_RESOURCE = {
  id: 0,
  tags: [],
  image: '',
  lastUpdated: '',
  postDate: '',
  name: '',
  description: '',
  resourceUrl: '',
  file: '',
  extension: '',
  privacyLevel: 0,
  circles: [],
  resourceType: 0,
  communityId: 0,
  paragraphId: 0,
  usedByRequest: false,
  icon: '',
  iconFile: '',
  communityResourceCircleTags: [],
};

export const DEFAULT_DISCUSSION = {
  id: 0,
  name: '',
  communityId: 0,
  content: '',
  commentCount: 0,
  viewCount: 0,
  image: '',
  postDate: '',
  lastUpdated: '',
  deadlineDate: '',
  deadline: 0,
  daysLeft: 0,
  paragraphId: 0,
  isActive: false,
  userId: 0,
  authorName: '',
  authorPhoto: '',
  discussionTags: [],
  discussionSkillCategories: [],
  isRelatedWithMethodology: false,
  readOnly: false,
  isArchived: false,
  allowLockComments: false,
  isLockedCommentDiscussions: false,
  privacyLevel: 1,
  projectId: 0,
  projectLogoImage: '',
  projectName: '',
  comments: [],
  isFollowing: false,
  followersCount: 0,
  discussionUserFollows: [],
  communityMembersTags: [],
};

export const DEFAULT_ASSIGNMENT = {
  id: 0,
  name: '',
  order: 0,
  description: '',
  details: '',
  template: '',
  sectionId: 0,
  methodologyId: 0,
  isHillary: false,
  taskSendReminder: false,
  privacyLevel: 6,
  methodologyContentCircleList: [],
};
export const DEFAULT_PLANNER = {
  tasks: [],
  plannerType: 0,
};

export const DEFAULT_METHODOLOGY = {
  id: 0,
  name: '',
  description: null,
  videoUrl: null,
  sections: [],
  planner: DEFAULT_PLANNER,
  communityId: undefined,
  isActive: true,
};
export const DEFAULT_PROJECT_TASK_PARAGRAPH = {
  id: 0,
  name: '',
  order: -1,
  isActive: true,
  privacyLevel: 1,
  lastUpdated: '',
  sectionId: 0,
  sectionName: '',
  methodologyParagraphId: 0,
  content: '',
  description: '',
  details: '',
  template: '',
  feedbackText: '',
  wikiLinks: '',
  wikiProblem: '',
  isHillary: false,
  inTutorial: false,
  comments: [],
  canView: true,
  canViewByCalendarPlanning: undefined,
  canViewBySequentialPlanning: undefined,
  dueTask: {
    isDue: false,
    isLastDay: false,
    isPublished: false,
    text: '',
    exists: false,
    canSee: false,
  },
};

export const DEFAULT_COMMENT = {
  id: 0,
  author: {
    id: 0,
    name: '',
    photo: '',
    occupation: '',
  },
  date: '',
  content: '',
  parentId: 0,
  likeCount: 0,
  isLiked: false,
  comments: [],
  canUserEdit: false,
  canUserRemove: false,
  userLikesComment: false,
  userDislikesComment: false,
};

export const DEFAULT_BUSINESS_MODEL_SECTION = {
  id: 0,
  name: '',
  order: 0,
  logo: '',
  isLean: false,
  assignments: [DEFAULT_PROJECT_TASK_PARAGRAPH],
};

export const DEFAULT_BUSINESS_MODEL = {
  canEditBusinessModel: false,
  sections: [DEFAULT_BUSINESS_MODEL_SECTION],
  customPrivacyCircles: [],
};
export const DEFAULT_CIRCLE = {
  id: 0,
  name: '',
  admin: false,
  shortName: '',
};

export const DEFAULT_COUNTRY = {
  id: 0,
  name: '',
};

export const DEFAULT_MEMBER = {
  id: 0,
  name: '',
  photo: '',
  email: '',
  occupation: '',
  country: DEFAULT_COUNTRY,
  skills: [],
  interests: [],
  tags: [],
  userTags: [],
  circle: DEFAULT_CIRCLE,
  isFollowed: false,
};

export const DEFAULT_PROGRESS_OVERVIEW = {
  nonDatedTasks: [],
  slow: [],
  regular: [],
};

export const DEFAULT_PROJECT = {
  id: 0,
  isProjectAdmin: false,
  isMember: false,
  communityIds: [0],
  name: '',
  description: '',
  logo: '',
  cover: '',
  SDGs: [],
  tags: [],
  resourcesTags: [],
  country: '',
  city: '',
  category: '',
  stage: '',
  isPending: false,
  isFollowing: false,
  members: [],
  resources: [],
  canEditDiscussions: false,
  canEditResources: false,
  canEditPrivacy: false,
  discussions: [],
  circles: [],
  feed: [],
  discussionCircles: [],
  resourcesCircles: [],
  discussionTags: [],
  businessModel: DEFAULT_BUSINESS_MODEL,
  projectTasks: [],
  taskUnlockRule: DEFAULT_PLANNER,
  canEditProjectInfo: false,
  websiteUrl: '',
  twitterUrl: '',
  facebookUrl: '',
  googleUrl: '',
  linkedInUrl: '',
  flickrUrl: '',
  categoryId: 0,
  unGoalId: 0,
  projectModel: '',
  projectPrivacy: 0,
  taggedEntityId: 0,
  allowLockComments: false,
  countryId: 0,
  canInviteUser: false,
  discussionCount: 0,
  networkCount: 0,
  viewCount: 0,
  progressDetails: DEFAULT_PROGRESS_OVERVIEW,
};

export const DEFAULT_NEW_PROJECT = {
  communityId: 0,
  name: '',
  description: '',
  categoryId: 0,
  developmentStage: '',
  methodologyId: 0,
  SDGs: [],
  countryId: 0,
  city: '',
  websiteUrl: '',
  facebookUrl: '',
  linkedInUrl: '',
};

export const DEFAULT_REPORT = {
  id: 0,
  title: '',
  type: StatsType.none,
  chartType: ChartTypesEnum.PIE,
  tables: [
    {
      keyColumn: '',
      valueColumn: '',
      aggregationFunction: AggregationFunctionsEnum.NOOP,
    },
  ],
};

export const DEFAULT_COMMUNITY = {
  id: 0,
  allowTasks: false,
  reports: [],
};

export const DEFAULT_REQUEST = {
  id: 0,
  name: '',
  description: '',
  privacyLevel: 0,
  privacyCircleIds: [],
  skillIds: [],
  marketExpertiseIds: [],
  projectResourceIds: [],
  resourcesToUpload: [],
  alreadyUploadedResources: [],
  resourcesToDelete: [],
  requestIndustryIds: [],
};

export const DEFAULT_APPLICATION_DEFINITION = {
  id: 0,
  communityId: 0,
  name: '',
  description: '',
  startDate: '',
  endDate: '',
  acceptedEmail: '',
  rejectedEmail: '',
  createProject: false,
  formId: 0,
  creatorUserId: 0,
  acceptedCircleId: 0,
  acceptedMethodologyId: 0,
  applicationDefinitionCriterias: [],
  applicationDefinitionEvaluators: [],
  evalutatorCircleIds: [],
  evaluationRequired: true,
  isCreatingNewForm: false,
  isEvaluatorSetByCircle: false,
  language: Languages.EN,
};

export const DEFAULT_FORM: Form = {
  id: 0,
  name: '',
  description: '',
  communityId: 0,
  creationDate: '',
  lastEditionDate: '',
  userCreatorId: 0,
  questions: [],
};

export const DEFAULT_FORM_QUESTION = {
  id: 0,
  formId: 0,
  title: '',
  description: '',
  questionOrder: 0,
  mandatoryAnswer: true,
  answerType: FormQuestionAnswerType.ShortTextAnswer,
  answerSubtype: '',
  allowMultipleAnswers: false,
  privacyLevel: Privacy.Public,
  conditionalLogicType: 0,
};

export const DEFAULT_USER = {
  id: 0,
  name: '',
  photo: '',
  email: '',
  admin: false,
  active: true,
  banned: false,
  occupation: '',
  primaryCommunityId: 0,
  profileWizardDone: true,
  countryId: 0,
  city: '',
  company: '',
  language: '',
  notificationActive: true,
  registrationDate: '',
  lastLogin: '',
  ownedProjects: [],
  followedProjects: [],
  communities: [],
  profile: {
    biography: '',
    interests: [],
    skills: [],
    marketExpertises: [],
    sustainableDevelopmentGoals: [],
  },
  settings: {
    notifications: {
      enabled: false,
      list: [],
    },
  },
  requests: [],
};

export const DEFAULT_MEETING_SETTINGS: MeetingsSettings = {
  // TODO Matej - change this DEFAULT data
  userMeetingPreference: {
    id: 0,
    videoCallLink: '',
    minutesDuration: 15,
    bookingType: 1,
    timeZone: '',
  },
  userRecurringAvailabilities: [],
  userWeeklyAvailabilities: [],
};

export const ALL_AVAILABLE_TIMEZONES = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'CET',
  'CST6CDT',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'EET',
  'EST',
  'EST5EDT',
  'Egypt',
  'Eire',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Greenwich',
  'HST',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'MST',
  'MST7MDT',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'Navajo',
  'PRC',
  'PST8PDT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Pacific-New',
  'US/Samoa',
  'UTC',
  'Universal',
  'W-SU',
  'WET',
  'Zulu',
];

export const MAX_FILE_SIZE = 100000000;

export const COVER_LOGO_MAX_FILE_SIZE = 2097152;
