import { combineReducers } from 'redux';
import { selectFile } from 'redux/actions/file-upload';
import { FormQuestion } from 'redux/types/account';
import { SelectedFile } from 'redux/types/file-upload';
import { PayloadAction, createReducer } from 'typesafe-actions';

export const selectedFiles = createReducer([] as SelectedFile[])
  .handleAction(
    selectFile,
    (selectedFiles: SelectedFile[], action: PayloadAction<'SELECT_FILE', { file: File; question: FormQuestion }>) => {
      if (selectedFiles.length === 0) return [action.payload];
      const fileExist = selectedFiles.find(file => file.question.id === action.payload.question.id);
      if (fileExist)
        return selectedFiles.map(file => (file.question.id === action.payload.question.id ? action.payload : file));
      return [...selectedFiles, action.payload];
    },
  )
  .handleAction('CLEAR_SELECTED_FILE', () => []);

const uploadingFileReducer = combineReducers({ selectedFiles });

export default uploadingFileReducer;
export type UploadFileState = ReturnType<typeof uploadingFileReducer>;
