import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA4 from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

import 'util/utils';
import { sendMailToUser } from 'services/api/users';
import { SecondaryButton } from 'components/styled-components/common';
import { toast } from 'components/common/toast';
import Modal from 'components/common/modal-component';
import Editor from 'components/editor';
import { StyledButton } from 'primitives/Button/style';

const SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY || '';

const ModalContent = styled.div`
  display: flex;
  flexdirection: column;
  gap: 2em;
`;

const mapStateToProps = state => ({
  literals: state.literals,
});

const MessageModal = props => {
  const [content, setContent] = useState('');
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const { member, bearer, setMember, literals, analyticsPage } = props;

  useEffect(() => {
    setContent('');
    if (member) {
      ReactGA4.event({ category: 'Message modal', action: `Open message modal: ${analyticsPage}` });
    }
  }, [member]);

  if (!Editor) console.log('No editor');

  const recaptchaOnChange = value => {
    setRecaptchaResponse(value);
  };

  const handleSendMessage = () => {
    if (content.trim().length === 0) {
      toast(literals.group_message_empty_message_error, { type: 'warning' });
      return;
    }
    if (recaptchaResponse === '') {
      toast(literals.auth_check_recaptcha, { type: 'error' });
      return;
    }
    ReactGA4.event({ category: 'Message modal', action: `Send message attempt: ${analyticsPage}` });
    sendMailToUser(bearer, member.id, content, recaptchaResponse)
      .then(() => {
        ReactGA4.event({ category: 'Message modal', action: `Send message success: ${analyticsPage}` });
        toast(literals.group_message_success_message, { type: 'success' });
        setMember(undefined);
      })
      .catch(() => {
        ReactGA4.event({ category: 'Message modal', action: `Send message failure: ${analyticsPage}` });
        toast(literals.group_message_failure_message, { type: 'error' });
      });
  };

  const handleClose = () => {
    ReactGA4.event({ category: 'Message modal', action: `Close message modal: ${analyticsPage}` });
    setMember(undefined);
  };

  return (
    <Modal open={member !== undefined} title={(literals.message_modal_title || '').replace('{0}', member?.name || '')}>
      <ModalContent style={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
        <div style={{ border: '1px solid grey' }}>
          <Editor
            defaultContent=""
            content={content}
            setContent={setContent}
            smallText
            withPadding
            placeholder={literals.group_message_placeholder}
            initAutoFocus
          />
        </div>
        <div style={{ display: 'grid', justifyContent: 'center', gap: '8px' }}>
          <ReCAPTCHA sitekey={SITEKEY} onChange={recaptchaOnChange} />
          <div style={{ margin: '0 auto' }}>
            <SecondaryButton onClick={handleClose}>{literals.send_message_cancel_button}</SecondaryButton>
            <StyledButton onClick={handleSendMessage}>{literals.send_message_send_button}</StyledButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default connect(mapStateToProps, {})(MessageModal);
