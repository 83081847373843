import React, { useEffect, useState } from 'react';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import { Popover, List, ListItem, Checkbox, ListItemText } from '@material-ui/core';
import styled from 'styled-components';
import { Circle, Member, User } from 'redux/types/account';
import { updateSelectedUsers } from 'redux/actions/user-calendar';

type dispatchType = typeof mapDispatchToProps;

interface CircleDropDownProps extends ReturnType<typeof mapStateToProps>, dispatchType {
  circles: Circle[] | undefined;
  members: Member[] | undefined;
  currentUser: User;
  onSelect: any;
  dropdownTitle: string;
}

const mapStateToProps = (state: RootState) => ({
  selectedUsers: state.userCalendar.selectedUsers,
});

const mapDispatchToProps = {
  updateSelectedUsers: updateSelectedUsers,
};

const CircleDropDown: React.FC<CircleDropDownProps> = ({
  circles,
  members,
  selectedUsers,
  updateSelectedUsers,
  currentUser,
  onSelect,
  dropdownTitle,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedCircle, setSelectedCircle] = useState<Circle[]>([]);
  const [memberChecked, setMemberChecked] = useState<{ [key: string]: boolean }>({});
  const [circleChecked, setCircleChecked] = useState<{ [key: string]: boolean }>({});

  const circleMembers =
    selectedCircle.length > 0 ? members?.filter((member: Member) => member?.circle?.id === selectedCircle[0]?.id) : [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHoverCircle = (circle: Circle) => {
    setSelectedCircle([circle]);
  };

  useEffect(() => {
    circles?.forEach((circle: Circle) => {
      const selectedCircleMembers = members?.filter((member: Member) => member.circle?.id === circle.id) || [];

      const allMembersChecked = selectedCircleMembers.every((member: Member) => memberChecked[member.id]);

      setCircleChecked(prevCircleChecked => ({
        ...prevCircleChecked,
        [circle.id]: allMembersChecked,
      }));
    });
  }, [memberChecked, circles, members]);

  useEffect(() => {
    const initialMemberChecked: { [key: string]: boolean } = {};
    selectedUsers?.forEach((user: Member) => {
      initialMemberChecked[user.id] = true;
    });
    setMemberChecked(initialMemberChecked);
  }, [selectedUsers]);

  const handleCheckCircle = (circle: Circle) => {
    setCircleChecked(prevCircleChecked => {
      const updatedCircleChecked: { [key: string]: boolean } = {
        ...prevCircleChecked,
        [circle.id]: !prevCircleChecked[circle.id],
      };

      const updatedMemberChecked = { ...memberChecked };
      const selectedCircleMembers = members?.filter((member: Member) => member?.circle?.id === circle.id) || [];

      selectedCircleMembers.forEach((member: Member) => {
        updatedMemberChecked[member.id] = updatedCircleChecked[circle.id];
      });

      setMemberChecked(updatedMemberChecked);

      let updatedMembers = selectedUsers ? [...selectedUsers] : [];

      if (updatedCircleChecked[circle.id]) {
        selectedCircleMembers.forEach(member => {
          if (!updatedMembers.some(existingMember => existingMember.id === member.id)) {
            updatedMembers.push({ ...member });
          }
        });
      } else {
        updatedMembers = updatedMembers.filter(
          member => !selectedCircleMembers.some(circleMember => circleMember.id === member.id),
        );
      }
      if (updatedMembers.length === 1 && updatedMembers[0].id === currentUser?.id) {
        updateSelectedUsers({
          selectedUsers: [],
        });
      } else {
        updatedMembers = [currentUser, ...updatedMembers.filter(member => member.id !== currentUser?.id)];
        updateSelectedUsers({
          selectedUsers: updatedMembers,
        });

        onSelect(updatedMembers);
      }

      return updatedCircleChecked;
    });
  };

  const handleCheckMember = (member: Member) => {
    setMemberChecked(prevMemberChecked => ({
      ...prevMemberChecked,
      [member.id]: !prevMemberChecked[member.id],
    }));

    const memberAlreadySelected = selectedUsers?.find((selectedMember: Member) => selectedMember?.id === member.id);

    if (memberAlreadySelected) {
      const updatedSelectedUsers = selectedUsers?.filter((selectedMember: Member) => selectedMember?.id !== member.id);

      const allMembersChecked = circleMembers?.every((circleMember: Member) => memberChecked[circleMember.id]);
      setCircleChecked(prevCircleChecked => ({
        ...prevCircleChecked,
        [member?.circle?.id]: allMembersChecked,
      }));

      if (selectedUsers?.length === 2) {
        updateSelectedUsers({
          selectedUsers: [],
        });
      } else {
        updateSelectedUsers({
          selectedUsers: updatedSelectedUsers,
        });
      }
    } else {
      const selectedUsersWithoutCurrent = selectedUsers?.filter(
        (selectedMember: Member) => selectedMember?.id !== currentUser?.id,
      );
      const updatedMembers = [currentUser, ...(selectedUsersWithoutCurrent ?? []), { ...member }];

      setCircleChecked(prevCircleChecked => ({
        ...prevCircleChecked,
        [member?.circle?.id]: true,
      }));

      updateSelectedUsers({
        selectedUsers: updatedMembers,
      });
      onSelect(updatedMembers);
    }
  };

  return (
    <Wrapper>
      <ButtonWrapper onClick={handleClick}>
        <ButtonContent>{dropdownTitle}</ButtonContent>
        <ReversibleArrow $dropdownOpen={Boolean(anchorEl)} />
      </ButtonWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DropdownContent>
          <LeftColumn>
            <List>
              {circles?.map((circle: Circle) => (
                <ListItem
                  key={circle?.id}
                  button
                  onClick={() => handleHoverCircle(circle)}
                  onMouseEnter={() => handleHoverCircle(circle)}
                >
                  <Checkbox
                    checked={circleChecked[circle.id] || false}
                    onClick={() => handleCheckCircle(circle)}
                    color="primary"
                  />
                  <ListItemText primary={circle?.name} />
                </ListItem>
              ))}
            </List>
          </LeftColumn>
          <RightColumn>
            <List>
              {circleMembers?.map((member: Member) => (
                <ListItem key={member.id}>
                  <Checkbox
                    checked={memberChecked[member.id] || false}
                    onChange={() => handleCheckMember(member)}
                    color="primary"
                  />
                  <StyledListItemText primary={member.name} />
                </ListItem>
              ))}
            </List>
          </RightColumn>
        </DropdownContent>
      </Popover>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 2.3em;
  height: 36px;
  border-radius: 10em;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.normal};
  border: solid 2px ${({ theme }) => theme.colors.blue.normal};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

const ReversibleArrow = styled(ArrowIcon)<{ $dropdownOpen: boolean }>`
  ${props => (props.$dropdownOpen ? 'transform: rotateX(180deg)' : '')}
`;

const ButtonContent = styled.div`
  text-transform: uppercase;
`;

const DropdownContent = styled.div`
  display: flex;
`;

const LeftColumn = styled.div`
  flex-grow: 0;
  width: 180px;
  padding: 0px;
  overflow-y: auto;
  max-height: 280px;
`;

const RightColumn = styled.div`
  flex-grow: 0;
  border-left: solid thin;
  overflow-y: auto;
  max-height: 280px;
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default connect(mapStateToProps, mapDispatchToProps)(CircleDropDown);
