import { Form } from 'redux/types/account';
import { getAxiosInstance } from './helper';
import { AxiosResponse } from 'axios';
import { _mapResponseToForm } from './form';
import { prepareFormSaveStep1, prepareFormSaveStep2 } from 'components/forms/utils';

const ai = getAxiosInstance();

export function saveProjectForm(
  bearer: string,
  communityId: number,
  form: Form,
): Promise<{ communityId: number; form: Form }> {
  const initialForm = { ...form };
  const formStep1 = prepareFormSaveStep1(initialForm);

  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/Community/${communityId}/ProjectForm`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: formStep1,
    })
      .then(({ data }) => {
        const formStep2 = prepareFormSaveStep2(initialForm, data);

        ai({
          method: 'PUT',
          url: `/api/Community/${communityId}/ProjectForm`,
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
          data: formStep2,
        }).then((response: AxiosResponse) => {
          resolve({
            communityId,
            form: _mapResponseToForm(response.data),
          });
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectForm(bearer: string, communityId: number): Promise<{ communityId: number; form: Form }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/ProjectForm`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          form: _mapResponseToForm(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  saveProjectForm,
  fetchProjectForm,
};
