import { toUpper, map } from 'ramda';
import {
  Comment,
  SDG,
  CommunityProgressOverview,
  DropdownItem,
  ReportDefinition,
  KPIValue,
  DataObject,
} from 'redux/types/account';
import axios, { AxiosResponse } from 'axios';
import { sanitizeAssetUrl } from '../../util/assets';
import { isLink } from '../../util/utils';
import { ChartTypesEnum, AggregationFunctionsEnum, StatsType } from '../../redux/types/enums';

import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { mentionParse } from '../../util/mention';

const IMG_EXT = ['png', 'jpg', 'jpeg', 'bmp', 'svg'];

const VIDEO_EXT = ['gif', 'mkv', 'avi', 'mov', 'wmv', 'mp4'];

export const buildHeaders = (bearer: string) => ({
  Authorization: `Bearer ${bearer}`,
});

export function getBaseUrl() {
  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'production') return 'https://api.babele.co';
  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'staging') return 'https://api-stag.babele.co';
  return 'https://api-stag.babele.co';
}

export function getAxiosInstance() {
  const ai = axios.create({
    baseURL: getBaseUrl(),
  });
  axiosRetry(ai, {
    retries: 3,
    retryDelay: retryCount => {
      return retryCount * 750;
    },
    retryCondition: e => {
      if (e?.response?.data?.startsWith && typeof (e?.response?.data || '').startsWith === 'function')
        if ((e?.response?.data || '').startsWith('Babele.Domain.Aggregates.')) {
          return false;
        }
      if (e?.config?.url?.includes('/MethodologyContent/')) {
        return true;
      }
      return isNetworkOrIdempotentRequestError(e);
    },
  });
  return ai;
}

export const upperFirst = (str: string) => {
  if (str) {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }
  return '';
};

export const getResourceType = (raw: string): string => {
  if (isLink(raw)) {
    if (raw.includes('dropbox')) return 'DROPBOX';
    if (raw.includes('google')) return 'GOOGLE';
    return 'LINK';
  } else {
    let fileExtension = raw.split('.')[raw.split('.').length - 1];
    if (fileExtension.includes('/') === false) {
      if (IMG_EXT.includes(fileExtension)) {
        fileExtension = 'IMG';
      }
      if (VIDEO_EXT.includes(fileExtension)) {
        fileExtension = 'VIDEO';
      }
      return toUpper(fileExtension);
    } else {
      return fileExtension;
    }
  }
};

export const extractComments = (comments: any[], extractNext: boolean): Comment[] => {
  if (!comments) {
    return [];
  } else {
    return map(
      comment => ({
        id: comment.id,
        parentId: comment.parentId,
        content: mentionParse(comment.content),
        date: comment.lastUpdated,
        likeCount: comment.likeCount,
        isLiked: comment.userLikesComment,
        author: {
          id: comment.authorId,
          name: comment.authorName,
          photo: sanitizeAssetUrl(comment.authorPhoto),
          occupation: comment.authorOccupation || '',
        },
        comments: extractNext ? extractComments(comment.comments, false) : [],
      }),
      comments,
    );
  }
};

// Not used ATM
export function fetchSDGs(bearer: string): Promise<SDG[]> {
  const ai = getAxiosInstance();
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/SustainableDevelopmentGoal/Get',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const sdgs = response.data;
        resolve(sdgs);
      })
      .catch(err => reject(err));
  });
}

// Some hardcoded data which isn't likely to change very often.
// Since we're in an accelerated pre-launch period it's safe to
// use it like this and change these with real requests.
export function getSDGList(): SDG[] {
  return [
    {
      name: 'No Poverty',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-01.png',
      id: 1,
    },
    {
      name: 'Zero Hunger',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-02.png',
      id: 2,
    },
    {
      name: 'Good Health And Well-Being',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-03.png',
      id: 3,
    },
    {
      name: 'Quality Education',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-04.png',
      id: 4,
    },
    {
      name: 'Gender Equality',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-05.png',
      id: 5,
    },
    {
      name: 'Clean Water And Sanitation',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-06.png',
      id: 6,
    },
    {
      name: 'Affordable And Clean Energy',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-07.png',
      id: 7,
    },
    {
      name: 'Decent Work and Economic Growth',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-08.png',
      id: 8,
    },
    {
      name: 'Industry, Innovation And Infrastructure',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-09.png',
      id: 9,
    },
    {
      name: 'Reduced Inequalities',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-10.png',
      id: 10,
    },
    {
      name: 'Sustainable Cities And Communites',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-11.png',
      id: 11,
    },
    {
      name: 'Responsible Consumption And Production',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-12.png',
      id: 12,
    },
    {
      name: 'Climate Action',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-13.png',
      id: 13,
    },
    {
      name: 'Life Below Water',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-14.png',
      id: 14,
    },
    {
      name: 'Life On Land',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-15.png',
      id: 15,
    },
    {
      name: 'Peace, Justice And Strong Institutions',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-16.png',
      id: 16,
    },
    {
      name: 'Partnerships For The Goals',
      iconName: '/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-17.png',
      id: 17,
    },
  ];
}

export function getSDGObject(sdgs: SDG[]): { [id: number]: string } {
  return sdgs.reduce((acc, val) => ({ ...acc, [val.id]: val.name }), {});
}

export function getProjectCategoryList() {
  return [
    { name: 'Arts & Culture', isAddedByUser: false, id: 1 },
    { name: 'Automotive & Aerospace', isAddedByUser: false, id: 2 },
    { name: 'Legal & Financial services', isAddedByUser: false, id: 3 },
    { name: 'Media, News & Publishing', isAddedByUser: false, id: 4 },
    { name: 'Business Services', isAddedByUser: false, id: 5 },
    { name: 'Education', isAddedByUser: false, id: 6 },
    { name: 'Energy & Utility', isAddedByUser: false, id: 7 },
    { name: 'Agriculture & Farming', isAddedByUser: false, id: 8 },
    { name: 'Food & Dining', isAddedByUser: false, id: 9 },
    { name: 'Healthcare', isAddedByUser: false, id: 10 },
    { name: 'Information & Communication Technology', isAddedByUser: false, id: 12 },
    { name: 'Real Estate & Housing', isAddedByUser: false, id: 15 },
    { name: 'Retail & Merchandise', isAddedByUser: false, id: 16 },
    { name: 'Social & Environmental services', isAddedByUser: false, id: 17 },
    { name: 'Sport & Fitness', isAddedByUser: false, id: 18 },
    { name: 'Transportation & Logistics', isAddedByUser: false, id: 19 },
    { name: 'Travel & tourism', isAddedByUser: false, id: 20 },
    { name: 'Animals & Pets', isAddedByUser: false, id: 22 },
    { name: 'Employment & Recruitment', isAddedByUser: false, id: 28 },
    { name: 'Urban development & construction ', isAddedByUser: false, id: 29 },
    { name: 'Entertainment & Leisure', isAddedByUser: false, id: 30 },
    { name: 'Marketing & Communication', isAddedByUser: false, id: 36 },
    { name: 'Recycling & waste management', isAddedByUser: false, id: 37 },
    { name: 'Philantropy & Common good', isAddedByUser: false, id: 38 },
    { name: 'Photography and Film Making', isAddedByUser: false, id: 39 },
    { name: 'Security & Defense', isAddedByUser: false, id: 42 },
    { name: 'Connectivity & Networks', isAddedByUser: false, id: 45 },
    { name: 'Baby care & accessories', isAddedByUser: false, id: 48 },
    { name: 'Beauty & Wellness', isAddedByUser: false, id: 49 },
    { name: 'Clothing, accessories & wearable devices', isAddedByUser: false, id: 51 },
    { name: 'Communities & Associations', isAddedByUser: false, id: 52 },
    { name: 'Electronics & Mechanics', isAddedByUser: false, id: 53 },
    { name: 'Games & hobbies', isAddedByUser: false, id: 54 },
    { name: 'Government & policy making', isAddedByUser: false, id: 55 },
    { name: 'Home & Garden', isAddedByUser: false, id: 56 },
    { name: 'Personal Services', isAddedByUser: false, id: 57 },
  ];
}

export function getProjectCategoryObject(): { [id: number]: string } {
  const categories = getProjectCategoryList();
  return categories.reduce((acc, val) => ({ ...acc, [val.id]: val.name }), {});
}

export function getDevelopmentStateList() {
  return [
    [],
    [
      { key: 'default_development_stage_idea', value: 'Idea' },
      { key: 'default_development_stage_prototype', value: 'Prototype' },
      { key: 'default_development_stage_start_up', value: 'Start up' },
      { key: 'default_development_stage_scaling', value: 'Growth' },
      { key: 'default_development_stage_maturity', value: 'Maturity' },
    ],
    [
      { key: 'default_development_stage_dry_run_only', value: 'Dry-run only (student assignment)' },
      { key: 'default_development_stage_start_up_venture', value: 'Start-up venture (looking for seed funding)' },
      { key: 'default_development_stage_growth_phase', value: 'Growth phase (replicating the business model)' },
    ],
  ];
}

export function getDevelopmentStateObject(): { [id: string]: string } {
  const states = getDevelopmentStateList();
  const mapDevelopmentCategory = (category: { key: string; value: string }[]) => {
    return category.reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
  };
  return states.reduce((acc, val) => ({ ...acc, ...mapDevelopmentCategory(val) }), {});
}

export function getSortingEntity() {
  return [
    { id: 0, name: 'Title Ascending' },
    { id: 1, name: 'Title Descending' },
    { id: 2, name: 'Newest First' },
    { id: 3, name: 'Oldest First' },
  ];
}

export function getSortingProjects() {
  return [
    { id: 1, name: 'Recently created' },
    { id: 2, name: 'Number of views' },
    { id: 3, name: 'Number of comments' },
    { id: 4, name: 'Number of followers' },
    { id: 5, name: 'Recently modified' },
  ];
}

export function getSortingMembersAndNetwork() {
  return [
    { id: 1, name: 'Name' },
    { id: 2, name: 'Comments' },
    { id: 3, name: 'Likes' },
    { id: 4, name: 'Followers' },
    { id: 5, name: 'Activity' },
  ];
}

export function getDiscussionSortingOptions() {
  return [
    { id: 1, name: 'Most Recent' },
    { id: 2, name: 'Comments' },
  ];
}

export function getRequestSortingOptions() {
  return [
    { id: 1, name: 'Most Recent' },
    { id: 2, name: 'Comments' },
  ];
}

export function getProjectOverviewSections(): Array<{ id: keyof CommunityProgressOverview; title: string }> {
  return [
    { id: 'slow', title: '🐌 Slower progress' },
    { id: 'nonDatedTasks', title: '🛵 Faster progress' },
    { id: 'regular', title: 'Regular progress' },
  ];
}
export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let i = 0; i < 10; i++) {
    const year = currentYear - i;
    yearsArray.push({
      key: `${year}`,
      value: year,
      text: `${year}`,
    });
  }

  return yearsArray;
};

export const getMonthName = (monthNumber: number) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  return months[monthNumber - 1];
};
export const filterObjectsByDate = (
  data: DataObject[],
  startingMonth: number,
  startingYear: number,
  frequency: number,
): DataObject[] => {
  return data.filter((obj: DataObject) => {
    const { name } = obj;
    if (frequency === 1) {
      // Monthly frequency
      const [month, year] = name.split("'");
      const monthIndex = getMonthIndex(month);
      // assuming the data is for 21st centuary
      const yearNumber = 2000 + parseInt(year);
      return (
        yearNumber > startingYear ||
        (yearNumber === startingYear &&
          monthIndex >= startingMonth &&
          (monthIndex <= new Date().getMonth() + 1 || yearNumber < new Date().getFullYear()))
      );
    } else if (frequency === 3) {
      // Quarterly frequency
      const [quarter, year] = name.split(' ');
      const yearNumber = parseInt(year);
      const normalizedMonth = Math.floor(startingMonth / 3);
      return yearNumber > startingYear || (yearNumber === startingYear && getQuarterIndex(quarter) >= normalizedMonth);
    } else if (frequency === 12) {
      // Yearly frequency
      const yearNumber = parseInt(name);
      return yearNumber >= startingYear;
    }

    // Invalid frequency, keep the object in the result to avoid filtering it out
    return true;
  });
};

export const getMonthIndex = (monthAbbreviation: string): number => {
  const months: { [key: string]: number } = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sept: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  return months[monthAbbreviation] || 0;
};

export const getQuarterIndex = (quarterAbbreviation: string): number => {
  const quarters: { [key: string]: number } = {
    Q1: 1,
    Q2: 2,
    Q3: 3,
    Q4: 4,
  };
  return quarters[quarterAbbreviation] || 0;
};

export const getQuarterName = (monthNumber: number) => {
  const quarterNumber = Math.ceil(monthNumber / 3);
  return `Q${quarterNumber}`;
};

export const getMonths = () => {
  return [
    { key: 'Jan', value: 1, text: 'Jan' },
    { key: 'Feb', value: 2, text: 'Feb' },
    { key: 'Mar', value: 3, text: 'Mar' },
    { key: 'Apr', value: 4, text: 'Apr' },
    { key: 'May', value: 5, text: 'May' },
    { key: 'June', value: 6, text: 'June' },
    { key: 'July', value: 7, text: 'July' },
    { key: 'Aug', value: 8, text: 'Aug' },
    { key: 'Sept', value: 9, text: 'Sept' },
    { key: 'Oct', value: 10, text: 'Oct' },
    { key: 'Nov', value: 11, text: 'Nov' },
    { key: 'Dec', value: 12, text: 'Dec' },
  ];
};

export const getQuarters = () => {
  return [
    { key: 'Q1', value: 1, text: 'Q1' },
    { key: 'Q2', value: 4, text: 'Q2' },
    { key: 'Q3', value: 7, text: 'Q3' },
    { key: 'Q4', value: 10, text: 'Q4' },
  ];
};

export function getMonthDiff(date1: number, date2: number): number {
  const diffInMs = Math.abs(date2 - date1);
  const diffInMonths = parseInt((diffInMs / (1000 * 60 * 60 * 24 * 30.44)).toFixed(1));
  return diffInMonths;
}

export function fillEmptyKpis(
  startYear: number,
  startMonth: number,
  endYear: number,
  endMonth: number,
  frequency: number,
  kpiValues: KPIValue[],
): KPIValue[] {
  let startDate: any = new Date(startYear, startMonth - 1);
  const endDate: any = new Date(endYear, endMonth);
  const kpiList: KPIValue[] = [];
  const monthRemaining = getMonthDiff(startDate, endDate);
  // if array has less than 12 kpis then fill it with empty kpis
  if (monthRemaining < 12) {
    startDate = new Date(startYear, endMonth - 13);
  }
  while (new Date(startDate) <= new Date(endDate)) {
    const month: number = new Date(startDate).getMonth();
    const year: number = startDate.getFullYear();
    const kpiExist = kpiValues.find((kpi: KPIValue) => kpi.year === year && kpi.month === month + 1);
    if (!kpiExist) kpiList.push({ id: 0, year: year, value: 0, month: month + 1 });
    startDate.setMonth(new Date(startDate).getMonth() + frequency);
  }
  return kpiList.concat(kpiValues);
}

export function getKPIFrequency(): DropdownItem[] {
  return [
    {
      key: 'monthly',
      value: 1,
      text: 'Monthly',
    },
    {
      key: 'quarterly',
      value: 3,
      text: 'Quarterly',
    },
    {
      key: 'yearly',
      value: 12,
      text: 'Yearly',
    },
  ];
}

export function getKPIEvolution(): DropdownItem[] {
  return [
    {
      key: 'biggerIsBetter',
      value: true,
      text: 'BiggerIsBetter',
    },
    {
      key: 'lowerIsBetter',
      value: false,
      text: 'LowerIsBetter',
    },
  ];
}

export function getTemplateReports(): ReportDefinition[] {
  return [
    {
      id: -1,
      title: 'Number of accesses',
      chartType: ChartTypesEnum.BAR,
      type: StatsType.users,
      keyColumn: 'All',
      valueColumn: '# of Accesses',
      keyRename: 'Number of accesses',
      aggregationFunction: AggregationFunctionsEnum.SUM,
      deltaValues: true,
      parentReportId: 0,
    },
    {
      id: -2,
      title: 'Number of comments',
      chartType: ChartTypesEnum.BAR,
      type: StatsType.users,
      keyColumn: 'All',
      valueColumn: 'Comments',
      keyRename: 'Number of comments',
      aggregationFunction: AggregationFunctionsEnum.SUM,
      deltaValues: true,
      parentReportId: 0,
    },
    {
      id: -120,
      title: 'Active vs All users',
      chartType: ChartTypesEnum.BAR,
      type: StatsType.users,
      keyColumn: 'userIsActive',
      keyMinValue: 'true',
      keyMaxValue: 'true',
      keyRename: 'Active users',
      valueColumn: 'userId',
      aggregationFunction: AggregationFunctionsEnum.COUNT,
      parentReportId: 0,
    },
    {
      id: -121,
      title: 'Active vs All users',
      chartType: ChartTypesEnum.BAR,
      type: StatsType.users,
      keyColumn: 'userIsActive',
      keyRename: 'All users',
      valueColumn: 'userId',
      aggregationFunction: AggregationFunctionsEnum.COUNT,
      parentReportId: -120,
    },
    {
      id: -5,
      title: 'Number of ventures',
      chartType: ChartTypesEnum.LINE,
      type: StatsType.projects,
      keyColumn: 'All',
      keyRename: 'Number of projects',
      valueColumn: 'Project ID',
      aggregationFunction: AggregationFunctionsEnum.COUNT,
      parentReportId: 0,
    },
    {
      id: -6,
      title: 'Completed assignments',
      chartType: ChartTypesEnum.BAR,
      type: StatsType.projects,
      keyColumn: 'All',
      keyRename: 'Completed assignments',
      valueColumn: 'Assignments completed',
      aggregationFunction: AggregationFunctionsEnum.SUM,
      parentReportId: 0,
    },
    {
      id: -7,
      title: 'Distribution of venture by category',
      chartType: ChartTypesEnum.PIE,
      type: StatsType.projects,
      keyColumn: 'Category',
      valueColumn: 'Project ID',
      aggregationFunction: AggregationFunctionsEnum.COUNT,
      parentReportId: 0,
    },
    {
      id: -8,
      title: 'Distribution of venture by country',
      chartType: ChartTypesEnum.PIE,
      type: StatsType.projects,
      keyColumn: 'Country',
      valueColumn: 'Project ID',
      aggregationFunction: AggregationFunctionsEnum.COUNT,
      parentReportId: 0,
    },
  ];
}
