import {
  IPublicDiscussionComment,
  IPublicRequestComment,
  IPublicDiscussionCommentAuthor,
  IPublicEntityComment,
} from '../types';

// Function to remove the mention link and return just the username
const MENTION_MATCHING_REGEX = /@\[BabeleUser\{\d+\|@(([^\n](?!BabeleUser)){1,120})}]/gm;

export const replaceMentions = (text: string) => {
  return text.replace(MENTION_MATCHING_REGEX, (match, p1) => {
    return `@${p1}`;
  });
};

// Function to get unique users
export const getUniqueUsers = (
  comments: IPublicDiscussionComment[] | IPublicRequestComment[] | IPublicEntityComment[],
  uniqueUsers: IPublicDiscussionCommentAuthor[] = [],
) => {
  for (const comment of comments) {
    if (!uniqueUsers.some(user => user.authorId === comment.authorId)) {
      uniqueUsers.push({
        authorId: comment.authorId,
        authorName: comment.authorName,
        authorPhoto: comment.authorPhoto,
        authorOccupation: comment.authorOccupation,
      });
    }
    if (comment.comments && comment.comments.length > 0) {
      getUniqueUsers(comment.comments, uniqueUsers);
    }
  }
  return uniqueUsers;
};

export const getCustomDNS = () => {
  let customDNS = window.location.host;

  if (window.location.host === 'app-stag.babele.co' || window.location.host === 'localhost:3000') {
    customDNS = 'some-custom-page.be';
  }

  return customDNS;
};
