import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import ideaIcon from '../../assets/community/idea.svg';
import _CloseIcon from '@material-ui/icons/Close';

const CloseIcon = styled(_CloseIcon)`
  position: absolute;
  right: 5px;
  top: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const TipContainer = styled.div`
  width: 21em;
  background: #fff5e6;
  border: 1px solid #ffcb8e;
  box-sizing: border-box;
  border-radius: 0.25em;
  padding: 1em;
  position: absolute;
  right: 1em;
  top: 11em;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
`;

const TipImage = styled(Image)`
  width: 1.5em;
  height: 1.5em;
`;

const TipBold = styled.span`
  font-size: 0.9375em;
  font-weight: 500;
`;

const TipParagraph = styled.div`
  font-size: 0.9375em;
  line-height: 1.5em;
  margin-top: 0;
  margin-left: 0.5em;
  text-align: left;
`;

const DontShowParagraph = styled.div`
  display: flex;
  font-size: 0.7em;
  align-items: center;
  margin-top: 0.5em;
  & > label:hover {
    cursor: pointer;
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  content: string;
  style?: React.CSSProperties;
  dontShowAgainOption?: boolean;
  showCloseTip?: boolean;
  closeTip?: (options: { dontShowAgain?: boolean }) => void;
}

const TipMessage: React.FC<Props> = props => {
  const { literals, content } = props;
  const [dontShow, setDontShow] = useState<boolean>(false);

  const handleClose = () => {
    if (props.closeTip) {
      props.closeTip({
        dontShowAgain: dontShow,
      });
    }
  };

  return (
    <TipContainer style={props.style || {}}>
      {props.showCloseTip && <CloseIcon onClick={handleClose} />}
      <TipImage alt="arrow-forward" src={ideaIcon} />
      <TipParagraph>
        <TipBold>{literals.global_tip}: </TipBold>
        {content}

        {props.dontShowAgainOption && (
          <DontShowParagraph>
            <label style={{ display: 'flex' }}>
              <input
                checked={dontShow}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDontShow(event.target.checked)}
                type="checkbox"
              />
              <span style={{ marginLeft: '.2em' }}>{literals.tip_message_close_message}</span>
            </label>
          </DontShowParagraph>
        )}
      </TipParagraph>
    </TipContainer>
  );
};

export default connect(mapStateToProps, {})(TipMessage);
