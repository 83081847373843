import { Session } from 'redux/types/account';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const loginAsync = createAsyncAction('LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_FAILURE', 'LOGIN_IDLE')<
  {
    username: string;
    password: string;
    recaptchaResponse: string;
    communityId: number | null;
    circleId: number | null;
    tags: string[] | null;
  },
  Session,
  string,
  null
>();

export const googleSignIn = createAsyncAction(
  'GOOGLE_SIGN_IN_REQUEST',
  'GOOGLE_SIGN_IN_SUCCESS',
  'GOOGLE_SIGN_IN_FAILURE',
  'GOOGLE_SIGN_IN_IDLE',
)<{ credential: string; clientId: string }, { token: string }, string, null>();

export const saveToken = createAction('SAVE_TOKEN')<{ token: string }>();

export const resetSession = createAction('RESET_SESSION')<void>();

export default {
  googleSignIn,
  loginAsync,
  resetSession,
};
