import { createAsyncAction } from 'typesafe-actions';
import { BusinessModel, ProjectTask, SaveParagraph, Circle, Comment, ProjectBusinessModel } from 'redux/types/account';
import { Planner } from '../types/enums';

export const fetchProjectBusinessModelsAsync = createAsyncAction(
  'FETCH_PROJECT_BUSINESS_MODELS_REQUEST',
  'FETCH_PROJECT_BUSINESS_MODELS_SUCCESS',
  'FETCH_PROJECT_BUSINESS_MODELS_FAILURE',
  'FETCH_PROJECT_BUSINESS_MODELS_IDLE',
)<
  { projectId: number; bearer: string },
  { projectId: number; projectBusinessModelList: ProjectBusinessModel[] },
  string,
  null
>();

export const fetchBusinessModelByProjectIdAsync = createAsyncAction(
  'FETCH_BUSINESS_MODEL_REQUEST',
  'FETCH_BUSINESS_MODEL_SUCCESS',
  'FETCH_BUSINESS_MODEL_FAILURE',
  'FETCH_BUSINESS_MODEL_IDLE',
)<
  { projectId: number; methodologyId: number; bearer: string },
  { projectId: number; businessModel: BusinessModel; methodologyId: number },
  string,
  null
>();

export const fetchProjectTasksAsync = createAsyncAction(
  'FETCH_PROJECT_TASKS_REQUEST',
  'FETCH_PROJECT_TASKS_SUCCESS',
  'FETCH_PROJECT_TASKS_FAILURE',
  'FETCH_PROJECT_TASKS_IDLE',
)<
  { projectId: number; methodologyId: number; bearer: string },
  { projectId: number; projectTasks: ProjectTask[]; taskUnlockRule: Planner },
  string,
  null
>();

export const saveProjectTasksAsync = createAsyncAction(
  'SAVE_PROJECT_TASKS_REQUEST',
  'SAVE_PROJECT_TASKS_SUCCESS',
  'SAVE_PROJECT_TASKS_FAILURE',
  'SAVE_PROJECT_TASKS_IDLE',
)<
  { saveParagraph: SaveParagraph; projectId: number; bearer: string; circles: Circle[]; taskId?: number },
  {
    projectTask: {
      id: number;
      content: string;
      isActive: boolean;
      privacyLevel: number;
      sectionId: number;
      customPrivacyCircles?: Circle[];
    };
    projectId: number;
  },
  string,
  null
>();

export const fetchBusinessModelPrivacyCirclesAsync = createAsyncAction(
  'FETCH_BUSINESS_MODEL_PRIVACY_CIRCLES_REQUEST',
  'FETCH_BUSINESS_MODEL_PRIVACY_CIRCLES_SUCCESS',
  'FETCH_BUSINESS_MODEL_PRIVACY_CIRCLES_FAILURE',
  'FETCH_BUSINESS_MODEL_PRIVACY_CIRCLES_IDLE',
)<
  { projectId: number; bearer: string; paragraphId: number },
  {
    projectId: number;
    paragraphId: number;
    circles: Circle[];
  },
  string,
  null
>();

export const addCommentToBusinessModelParagraphAsync = createAsyncAction(
  'ADD_COMMENT_TO_BUSINESS_MODEL_PARAGRAPH_REQUEST',
  'ADD_COMMENT_TO_BUSINESS_MODEL_PARAGRAPH_SUCCESS',
  'ADD_COMMENT_TO_BUSINESS_MODEL_PARAGRAPH_FAILURE',
  'ADD_COMMENT_TO_BUSINESS_MODEL_PARAGRAPH_IDLE',
)<
  {
    bearer: string;
    userId: number;
    parentId: number;
    paragraphId: number;
    content: string;
    projectId: number;
  },
  { projectId: number; paragraphId: number; parentId: number; comment: Comment },
  string,
  null
>();

export const fetchCommentsForBusinessModelParagraphAsync = createAsyncAction(
  'FETCH_COMMENTS_FOR_BUSINESS_MODEL_PARAGRAPH_REQUEST',
  'FETCH_COMMENTS_FOR_BUSINESS_MODEL_PARAGRAPH_SUCCESS',
  'FETCH_COMMENTS_FOR_BUSINESS_MODEL_PARAGRAPH_FAILURE',
  'FETCH_COMMENTS_FOR_BUSINESS_MODEL_PARAGRAPH_IDLE',
)<
  { bearer: string; projectId: number; paragraphId: number },
  { projectId: number; paragraphId: number; comments: Comment[] },
  string,
  null
>();

export const saveSectionStagegateLockedAsync = createAsyncAction(
  'SAVE_SECTION_STAGEGATE_LOCKED_REQUEST',
  'SAVE_SECTION_STAGEGATE_LOCKED_SUCCESS',
  'SAVE_SECTION_STAGEGATE_LOCKED_FAILURE',
  'SAVE_SECTION_STAGEGATE_LOCKED_IDLE',
)<
  { bearer: string; projectId: number; sectionId: number; isStagegateLocked: boolean },
  { projectId: number; sectionId: number; isStagegateLocked: boolean },
  string,
  null
>();

export const toggleApprovedAssignmentAsync = createAsyncAction(
  'TOGGLE_APPROVED_ASSIGNMENT_REQUEST',
  'TOGGLE_APPROVED_ASSIGNMENT_SUCCESS',
  'TOGGLE_APPROVED_ASSIGNMENT_FAILURE',
  'TOGGLE_APPROVED_ASSIGNMENT_IDLE',
)<
  { bearer: string; projectId: number; sectionId: number; assignmentId: number },
  { projectId: number; sectionId: number; assignmentId: number },
  string,
  null
>();

export default {
  fetchBusinessModelByProjectIdAsync,
  fetchProjectTasksAsync,
  saveProjectTasksAsync,
  fetchBusinessModelPrivacyCirclesAsync,
  addCommentToBusinessModelParagraphAsync,
  fetchCommentsForBusinessModelParagraphAsync,
  saveSectionStagegateLockedAsync,
  toggleApprovedAssignmentAsync,
  fetchProjectBusinessModelsAsync,
};
