import React from 'react';
import styled from 'styled-components';

export const CustomButton = styled.div<{ active?: boolean }>`
  border-radius: 1rem;
  padding: 1rem 0.6rem;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  background-color: white;
  min-height: 7em;
  &:hover {
    cursor: pointer;
  }
  ${props => !props.active && `border: 3px solid gray`}
  ${props => props.active && `border: 3px solid blue`}
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonTitle = styled.p`
  font-weight: bold;
  color: black;
`;

const ButtonDesc = styled.p`
  color: gray;
  margin-top: 0;
  font-size: 0.7em;
`;

interface Props {
  imageUrl: string;
  title: string;
  desctiption: string;
  isActive: boolean;
  onClickButton: () => void;
}

const StyledMeetingTypeButton: React.FC<Props> = props => {
  const { imageUrl, title, desctiption, isActive, onClickButton } = props;
  return (
    <CustomButton active={isActive} onClick={onClickButton}>
      <ImageContainer>
        <img style={{ height: '3em' }} src={imageUrl} alt="Tip message" />
      </ImageContainer>
      <div>
        <ButtonTitle>{title}</ButtonTitle>
        <ButtonDesc>{desctiption}</ButtonDesc>
      </div>
    </CustomButton>
  );
};

export default StyledMeetingTypeButton;
