import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

interface INavbarLogo {
  url?: string;
  name?: string;
}
const NavbarLogo = ({ url, name }: INavbarLogo) => {
  const history = useHistory();

  return (
    <Logo onClick={() => history.push('/landing')}>
      <LogoImage src={url} alt={name} />
      <Name>{name}</Name>
    </Logo>
  );
};

const Logo = styled.button`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;

  background: transparent;
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 10px;
  border-radius: 10px;
`;

const Name = styled.span`
  display: none;

  font-size: 15px;
  font-weight: bold;
  word-break: break-all;

  @media (min-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    display: block;
  }
`;

export default NavbarLogo;
