import { createReducer, PayloadAction } from 'typesafe-actions';
import { buildFlagReducer } from '../store/utils';
import {
  fetchMeetingsSettingsAsync,
  saveMeetingsSettingsAsync,
  getUserEventsByMonthAsync,
  calendarLogInOutlookAsync,
  setUserCalendarOutlookAsync,
  setUserCalendarGoogleAsync,
  calendarLogInGoogleAsync,
  calendarLogOutGoogleAsync,
  calendarLogOutOutlookAsync,
  meetingProviderSyncAsync,
  meetingProviderUnSyncAsync,
} from 'redux/actions/calendar';
import { LogInCalendarData, MeetingsSettings, MeetingsSettingsDto, UserEvent } from '../types/calendar';

export const fetchMeetingsSettingsFlag = buildFlagReducer(fetchMeetingsSettingsAsync);
export const saveMeetingsSettingsFlag = buildFlagReducer(saveMeetingsSettingsAsync);
export const getUserEventsByMonthFlag = buildFlagReducer(getUserEventsByMonthAsync);
export const calendarLogInOutlookFlag = buildFlagReducer(calendarLogInOutlookAsync);
export const setUserCalendarOutlookFlag = buildFlagReducer(setUserCalendarOutlookAsync);
export const calendarLogOutOutlookFlag = buildFlagReducer(calendarLogOutOutlookAsync);
export const setUserCalendarGoogleFlag = buildFlagReducer(setUserCalendarGoogleAsync);
export const calendarLogInGoogleFlag = buildFlagReducer(calendarLogInGoogleAsync);
export const calendarLogOutGoogleFlag = buildFlagReducer(calendarLogOutGoogleAsync);
export const meetingProviderSyncFlag = buildFlagReducer(meetingProviderSyncAsync);
export const meetingProviderUnSyncFlag = buildFlagReducer(meetingProviderUnSyncAsync);

export const calendarLogInThirdPartyReducer = createReducer(null)
  .handleAction(
    calendarLogInOutlookAsync.success,
    (
      logInData: LogInCalendarData[],
      action: PayloadAction<'OUTLOOK_LOG_IN_SUCCESS', { authorizationCode: string; email: string }>,
    ) => {
      return action.payload;
    },
  )
  .handleAction(
    calendarLogInGoogleAsync.success,
    (
      logInData: LogInCalendarData[],
      action: PayloadAction<'GOOGLE_LOG_IN_SUCCESS', { authorizationCode: string; email: string }>,
    ) => {
      return action.payload;
    },
  );

export const meetingSettingsReducer = createReducer(null)
  .handleAction(
    fetchMeetingsSettingsAsync.success,
    (
      meetingSettings: MeetingsSettings,
      action: PayloadAction<'FETCH_MEETING_SETTINGS_SUCCESS', { userId: number }>,
    ) => {
      return action.payload;
    },
  )
  .handleAction(
    saveMeetingsSettingsAsync.success,
    (
      meetingsSettings: MeetingsSettingsDto,
      action: PayloadAction<'SAVE_MEETING_SETTINGS_SUCCESS', MeetingsSettingsDto>,
    ) => {
      return action.payload;
    },
  );

export const userEventsByMonthReducer = createReducer(null)
  .handleAction(
    getUserEventsByMonthAsync.success,
    (
      userEvents: UserEvent[],
      action: PayloadAction<'FETCH_USER_EVENTS_BY_MONTH_SUCCESS', { userId: number; month: number; year: number }>,
    ) => {
      return action.payload;
    },
  )
  .handleAction(
    setUserCalendarOutlookAsync.success,
    (userEvents: UserEvent[], action: PayloadAction<'OUTLOOK_SET_USER_CALENDAR_SUCCESS', { calendarId: string }>) => {
      return action.payload;
    },
  )
  .handleAction(
    setUserCalendarGoogleAsync.success,
    (userEvents: UserEvent[], action: PayloadAction<'GOOGLE_SET_USER_CALENDAR_SUCCESS', { calendarId: string }>) => {
      return action.payload;
    },
  );

export type MeetingSettingsState = ReturnType<typeof meetingSettingsReducer>;
export type UserEventsByMonthState = ReturnType<typeof userEventsByMonthReducer>;
