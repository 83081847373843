import { compose } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Flag } from '../types/enums';
import { Literals, Replacement } from 'redux/types/account';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const composeEnhancers =
  (process.env.NODE_ENV === 'development' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const buildFlagReducer = (action: { request: any; success: any; failure: any; cancel: any }) =>
  createReducer(Flag.Idle as Flag)
    .handleAction([action.request], () => Flag.Request)
    .handleAction([action.success], () => Flag.Success)
    .handleAction([action.failure], () => Flag.Failure)
    .handleAction([action.cancel], () => Flag.Idle);

export const replaceLiteralsString = (literals: Literals, replacements: Replacement[]): Literals => {
  const updatedLiterals: Literals = {};

  for (const key in literals) {
    if (Object.prototype.hasOwnProperty.call(literals, key)) {
      let original = literals[key];

      for (const { target, replacement } of replacements) {
        while (original.includes(target)) {
          original = original.replace(target, replacement);
        }
      }
      original = original.charAt(0).toUpperCase() + original.slice(1);

      updatedLiterals[key] = original;
    }
  }

  return updatedLiterals;
};
