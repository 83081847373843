import { appendHrefTarget } from './utils';

export const formatUserHTMLTables = (content: string) => {
  const contentWrapper = document.createElement('div');
  // contentWrapper has to exist on document.body for innerText to interpret <br> correctly
  contentWrapper.setAttribute('style', 'position: absolute; top: 1000vw; left: 1000vw;');
  document.body.append(contentWrapper);
  contentWrapper.innerHTML = content;
  const tables = contentWrapper.getElementsByTagName('table');
  for (let tableIndex = 0; tableIndex < tables.length; tableIndex++) {
    const cells = Array.from(tables[tableIndex].getElementsByTagName('td')) as HTMLElement[];
    for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
      cells[cellIndex].style.minWidth = '10em !important';
      // innerTextLength is the maximum length of a line in the cell
      const innerTextLength = (cells[cellIndex].innerText || '')
        .split('\n')
        .reduce((acc: number, line: string) => Math.max(acc, line.length), 0);
      if (innerTextLength > 25) cells[cellIndex].className = 'min20emImportant';
      else if (innerTextLength > 20) cells[cellIndex].className = 'min15emImportant';
      else if (innerTextLength > 15) cells[cellIndex].className = 'min11emImportant';
      else if (innerTextLength > 10) cells[cellIndex].className = 'min7emImportant';
    }
  }
  document.body.removeChild(contentWrapper);
  return contentWrapper.innerHTML;
};

export const formatYoutubeIframes = (content: string) => {
  const contentWrapper = document.createElement('div');
  contentWrapper.innerHTML = content;
  const iframes = contentWrapper.getElementsByTagName('iframe');
  for (let iframeIndex = 0; iframeIndex < iframes.length; iframeIndex++) {
    const iframe = iframes[iframeIndex];
    (iframe as any).loading = 'lazy';
    if (iframe.src.includes('https://www.youtube.com/embed/')) {
      const wrapper = document.createElement('div');
      iframe.parentNode?.insertBefore(wrapper, iframe);
      wrapper.appendChild(iframe);
      wrapper.className = 'youtube-iframe-wrapper';
      iframe.width = '';
      iframe.height = '';
      iframe.setAttribute('allowFullScreen', '');
    } else if (iframe.src.includes('https://embed.ted.com')) {
      const wrapper = document.createElement('div');
      iframe.parentNode?.insertBefore(wrapper, iframe);
      wrapper.appendChild(iframe);
      wrapper.className = 'ted-iframe-wrapper';
      iframe.width = '';
      iframe.height = '';
    } else if (iframe.src.includes('https://www.slideshare.net/')) {
      iframe.setAttribute('allowFullScreen', '');
    }
    iframe.setAttribute('alt', 'external-media');
  }
  const imgs = contentWrapper.getElementsByTagName('img');
  for (let imgIndex = 0; imgIndex < imgs.length; imgIndex++) {
    const img = imgs[imgIndex];
    (img as any).loading = 'lazy';
  }
  return contentWrapper.innerHTML;
};

export const formatUserContent = (content?: string) => {
  if (!content) return '';
  return formatYoutubeIframes(formatUserHTMLTables(appendHrefTarget(content)));
};
