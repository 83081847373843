import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { SidebarItem, SidebarList, SidebarContainer } from '../styled-components/sidebar';
import { useParams } from 'react-router-dom';
import { Separator } from '../styled-components/common';
import { scrollLayoutToTop } from 'util/utils';

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {}

const Sidebar: React.FC<Props> = ({ literals }) => {
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <SidebarContainer style={{ paddingTop: '3.5em' }}>
      <SidebarList>
        <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}`} hasNotifications={false}>
          ← {literals.create_project_02_back_button}
        </SidebarItem>
        <Separator />
        <SidebarItem
          onClickCapture={scrollLayoutToTop}
          to={`/project/${projectId}/settings/general`}
          hasNotifications={false}
        >
          {literals.project_edit_general_tab}
        </SidebarItem>
      </SidebarList>
    </SidebarContainer>
  );
};

export default connect(mapStateToProps, {})(Sidebar);
