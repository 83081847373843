import { createReducer, PayloadAction } from 'typesafe-actions';
import { TipState } from '../types/enums';
import { Tips } from 'redux/types/account';
import { changeTipStateAsync } from '../actions/account';

const ALWAYS_TIPS = {
  communityList: TipState.Always,
};

export const tipsReducer = createReducer(ALWAYS_TIPS as Tips).handleAction(
  changeTipStateAsync.success,
  (state: Tips, action: PayloadAction<'CHANGE_TIP_STATE', { tip: string; state: TipState }>) => {
    return {
      ...state,
      [action.payload.tip]: action.payload.state,
    };
  },
);

export default tipsReducer;
