import React from 'react';
import { Modal, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { Flag } from '../../redux/types/enums';
import { Circle } from 'redux/types/account';
import styled from 'styled-components';
import _DotIcon from '@material-ui/icons/FiberManualRecord';
import _CheckIcon from '@material-ui/icons/Check';
import { NoBackgroundButton } from '../styled-components/common';

const DoneButton = styled(NoBackgroundButton)`
  &&& {
    margin: auto 0 auto auto;
    padding: 0;
    color: ${({ theme }) => theme.colors.blue.normal};
  }
`;

const DotIcon = styled(_DotIcon)`
  font-size: 0.7em !important;
  width: 2em !important;
  color: ${({ theme }) => theme.colors.grey.dark};
`;

const ChangeCircleName = styled.span`
  margin-left: 1em;
`;

const CheckIcon = styled(_CheckIcon)`
  color: ${({ theme }) => theme.colors.blue.normal};
`;

const ChangeCircleContainer = styled.div<{ isActive: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.4em 0 0.4em 1em;
  margin: 0.2em 0;
  ${({ isActive, theme }) => isActive && `background-color: ${theme.colors.blue.light};`}
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  open: boolean;
  isLoading?: Flag;
  circles: Circle[];
  saveCircle: (circleId: number, circleName: string) => void;
  currentCircleId?: number;
  currentCircleName?: string;
  close: () => void;
}

const ChangeCircleModal: React.FC<Props> = props => {
  const { literals, isLoading, circles, saveCircle, currentCircleId, currentCircleName } = props;
  return (
    <Modal style={{ maxWidth: '30em' }} open={props.open}>
      <Modal.Header>{literals.member_options_change_circle}</Modal.Header>
      <Modal.Content>
        {(isLoading === Flag.Request || circles.length === 0) && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}
        {circles.map((c: Circle) => (
          <ChangeCircleContainer
            key={c.id}
            isActive={c.id === currentCircleId || c.name === currentCircleName}
            onClick={() => {
              if (currentCircleId !== c.id || c.name === currentCircleName) {
                saveCircle(c.id, c.name);
              }
            }}
          >
            {c.id === currentCircleId || c.name === currentCircleName ? <CheckIcon /> : <DotIcon />}
            <ChangeCircleName>{c.name}</ChangeCircleName>
          </ChangeCircleContainer>
        ))}
        <DoneButton
          onClick={() => {
            props.close();
          }}
        >
          {literals.global_done}
        </DoneButton>
      </Modal.Content>
    </Modal>
  );
};

export default connect(mapStateToProps, {})(ChangeCircleModal);
