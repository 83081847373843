import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError } from './helpers';

import {
  fetchCommunityFeedAsync,
  fetchProjectFeedAsync,
  addCommunityPostAsync,
  addProjectPostAsync,
  addCommentToFeedAsync,
  likeFeedPostAsync,
  unlikeFeedPostAsync,
  deleteCommentAsync,
  pinCommunityFeedPostAsync,
  unpinCommunityFeedPostAsync,
  fetchTabEntityCommentsAsync,
} from '../actions/feed';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchCommunityFeedEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityFeedAsync.request)),
    switchMap(action =>
      from(
        api.feed.fetchCommunityFeed(action.payload.bearer, action.payload.id, action.payload.take, action.payload.skip),
      ).pipe(
        map(fetchCommunityFeedAsync.success),
        catchError(error => handleError(error, fetchCommunityFeedAsync)),
      ),
    ),
  );

export const fetchProjectFeedEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectFeedAsync.request)),
    switchMap(action =>
      from(
        api.feed.fetchProjectFeed(
          action.payload.bearer,
          action.payload.project,
          action.payload.take,
          action.payload.skip,
        ),
      ).pipe(
        map(fetchProjectFeedAsync.success),
        catchError(error => handleError(error, fetchProjectFeedAsync)),
      ),
    ),
  );

export const fetchTabEntityFeedEpic: EpicFunction = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchTabEntityCommentsAsync.request)),
    switchMap(action =>
      from(
        api.feed.fetchCommentsTabEntityById(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.tabEntityId,
        ),
      ).pipe(
        map(fetchTabEntityCommentsAsync.success),
        catchError(error => handleError(error, fetchTabEntityCommentsAsync)),
      ),
    ),
  );

export const addCommunityPostEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(addCommunityPostAsync.request)),
    switchMap(action =>
      from(
        api.feed.addCommunityPost(
          action.payload.bearer,
          action.payload.userId,
          action.payload.communityId,
          action.payload.content,
        ),
      ).pipe(
        map(addCommunityPostAsync.success),
        catchError(error => handleError(error, addCommunityPostAsync)),
      ),
    ),
  );

export const addProjectPostEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(addProjectPostAsync.request)),
    switchMap(action =>
      from(
        api.feed.addProjectPost(
          action.payload.bearer,
          action.payload.userId,
          action.payload.project,
          action.payload.content,
        ),
      ).pipe(
        map(addProjectPostAsync.success),
        catchError(error => handleError(error, addProjectPostAsync)),
      ),
    ),
  );

export const addCommentToFeedEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(addCommentToFeedAsync.request)),
    switchMap(action =>
      from(
        api.feed.addCommentToFeed(
          action.payload.bearer,
          action.payload.userId,
          action.payload.itemId,
          action.payload.content,
          action.payload.parentId,
          action.payload.itemType,
          action.payload.projectId,
          action.payload.entityId,
          action.payload.id || 0,
        ),
      ).pipe(
        map(addCommentToFeedAsync.success),
        catchError(error => handleError(error, addCommentToFeedAsync)),
      ),
    ),
  );

export const likeFeedPostEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(likeFeedPostAsync.request)),
    switchMap(action =>
      from(
        api.feed.likeFeedPost(action.payload.id, action.payload.bearer, action.payload.entityId, action.payload.type),
      ).pipe(
        map(likeFeedPostAsync.success),
        catchError(error => handleError(error, likeFeedPostAsync)),
      ),
    ),
  );

export const unlikeFeedPostEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(unlikeFeedPostAsync.request)),
    switchMap(action =>
      from(
        api.feed.unlikeFeedPost(action.payload.id, action.payload.bearer, action.payload.entityId, action.payload.type),
      ).pipe(
        map(unlikeFeedPostAsync.success),
        catchError(error => handleError(error, unlikeFeedPostAsync)),
      ),
    ),
  );

export const deleteCommentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteCommentAsync.request)),
    switchMap(action =>
      from(
        api.feed.deleteComment(
          action.payload.bearer,
          action.payload.postId,
          action.payload.postType,
          action.payload.entityId,
          action.payload.entityType,
          action.payload.itemId || -1,
        ),
      ).pipe(
        map(deleteCommentAsync.success),
        catchError(error => handleError(error, deleteCommentAsync)),
      ),
    ),
  );

export const pinCommunityFeedPostEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(pinCommunityFeedPostAsync.request)),
    switchMap(action =>
      from(
        api.feed.pinCommunityFeedPost(action.payload.bearer, action.payload.postId, action.payload.communityId),
      ).pipe(
        map(pinCommunityFeedPostAsync.success),
        catchError(error => handleError(error, pinCommunityFeedPostAsync)),
      ),
    ),
  );

export const unpinCommunityFeedPostEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(unpinCommunityFeedPostAsync.request)),
    switchMap(action =>
      from(
        api.feed.unpinCommunityFeedPost(action.payload.bearer, action.payload.postId, action.payload.communityId),
      ).pipe(
        map(unpinCommunityFeedPostAsync.success),
        catchError(error => handleError(error, unpinCommunityFeedPostAsync)),
      ),
    ),
  );

export default {
  fetchCommunityFeedEpic,
  fetchProjectFeedEpic,
  addCommunityPostEpic,
  addProjectPostEpic,
  addCommentToFeedEpic,
  likeFeedPostEpic,
  unlikeFeedPostEpic,
  deleteCommentEpic,
  pinCommunityFeedPostEpic,
  unpinCommunityFeedPostEpic,
  fetchTabEntityFeedEpic,
};
