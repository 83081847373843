import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Modal as SemanticUIModal } from 'semantic-ui-react';

const CustomModal = styled(SemanticUIModal)`
  &&& {
    padding: 2.5em;
    border-radius: 1.5em;

    h1 {
      margin-bottom: 1em;
      text-align: center;
      font-size: 2em;
    }

    @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
      font-size: 1.2em;
      padding: 0;
      padding-top: 2em;
    }
  }
`;

interface Props {
  children: ReactNode;
  title: string;
  open: boolean;
  noTitle?: boolean;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

/** @deprecated
 * Please use {@link Modal} instead.
 * */
const ModalComponent: React.FC<Props> = ({ children, title, open, noTitle, style, contentStyle }) => {
  return (
    <CustomModal style={{ ...style }} open={open}>
      {!noTitle && <h1>{title}</h1>}
      <SemanticUIModal.Content style={{ ...contentStyle }}>{children}</SemanticUIModal.Content>
    </CustomModal>
  );
};

export default ModalComponent;
