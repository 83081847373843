import { createAsyncAction } from 'typesafe-actions';
import { UserProfile } from 'UsersModels';

export const fetchUserProfileAsync = createAsyncAction(
  'FETCH_USER_PROFILE_REQUEST',
  'FETCH_USER_PROFILE_SUCCESS',
  'FETCH_USER_PROFILE_FAILURE',
  'FETCH_USER_PROFILE_IDLE',
)<{ bearer: string; id: number }, UserProfile, string, null>();

export const followUserAsync = createAsyncAction(
  'FOLLOW_USER_REQUEST',
  'FOLLOW_USER_SUCCESS',
  'FOLLOW_USER_FAILURE',
  'FOLLOW_USER_IDLE',
)<{ bearer: string; id: number }, number, string, null>();

export const unfollowUserAsync = createAsyncAction(
  'UNFOLLOW_USER_REQUEST',
  'UNFOLLOW_USER_SUCCESS',
  'UNFOLLOW_USER_FAILURE',
  'UNFOLLOW_USER_IDLE',
)<{ bearer: string; id: number }, number, string, null>();

export const userRecurringAvailabilities = createAsyncAction(
  'FOLLOW_USER_REQUEST',
  'FOLLOW_USER_SUCCESS',
  'FOLLOW_USER_FAILURE',
  'FOLLOW_USER_IDLE',
)<{ bearer: string; id: number }, number, string, null>();

export default {
  fetchUserProfileAsync,
  followUserAsync,
  unfollowUserAsync,
  userRecurringAvailabilities,
};
