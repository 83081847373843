import { deleteFormAsync, fetchCommunityFormsAsync, saveFormAsync } from '../actions/form';
import { buildFlagReducer } from '../store/utils';

export const saveFormFlag = buildFlagReducer(saveFormAsync);

export const fetchCommunityFormsFlag = buildFlagReducer(fetchCommunityFormsAsync);

export const deleteFormFlag = buildFlagReducer(deleteFormAsync);

export default {
  saveFormFlag,
  fetchCommunityFormsFlag,
  deleteFormFlag,
};
