import { Literals, ApplicationDefinition } from 'redux/types/account';
import { ApplicationStatus } from '../redux/types/enums';
import { toast } from '../components/common/toast';

export const getStatusLiteral = (status: ApplicationStatus, literals: Literals) => {
  switch (status) {
    case ApplicationStatus.Apply:
    case ApplicationStatus.Draft:
      return literals.application_status_draft;
    case ApplicationStatus.Pending:
      return literals.application_status_pending;
    case ApplicationStatus.Accepted:
      return literals.application_status_accepted;
    case ApplicationStatus.Denied:
      return literals.application_status_denied;
    default:
      return '';
  }
};

export const validateInputApplicationDefinition = (
  applicationDefinition: ApplicationDefinition,
  literals: Literals,
) => {
  let valid = true;

  if (!applicationDefinition.name) {
    toast(literals.application_validation_name || '', { type: 'warning' });
    valid = false;
  }
  if (!applicationDefinition.description) {
    toast(literals.application_validation_description || '', { type: 'warning' });
    valid = false;
  }
  if (applicationDefinition.evaluationRequired) {
    const emptyCriterias = applicationDefinition?.applicationDefinitionCriterias?.some(
      evaluationCriteria => !evaluationCriteria.trim(),
    );
    if (
      applicationDefinition.applicationDefinitionCriterias.length === 0 ||
      emptyCriterias ||
      (applicationDefinition?.evalutatorCircleIds?.length === 0 &&
        applicationDefinition?.applicationDefinitionEvaluators?.length === 0)
    ) {
      toast(literals.application_validation_evaluation || '', { type: 'warning' });
      valid = false;
    }
  }
  if (applicationDefinition.isCreatingNewForm === false && applicationDefinition.formId === 0) {
    toast(literals.application_validation_form || '', { type: 'warning' });
    valid = false;
  }
  // acceptedCircleId will be null for not inviting users to community
  if (applicationDefinition.createProject && !applicationDefinition.acceptedCircleId) {
    toast(literals.application_validation_accepted_circle, { type: 'warning' });
    valid = false;
  }
  return valid;
};

// backend appends some underscores and the date to the file
// filename__date.ext
export const parseFileName = (path: string) => {
  const filePath = path?.split('/') || [];
  const name = filePath[filePath.length - 1];
  const parts = name?.split('_') || [];
  const extenstion = parts[parts.length - 1]?.split('.') || [];
  const fileName = `${parts.filter((_: string, index: number, arr: string[]) => index < arr.length - 1).join('_')}.${
    extenstion[extenstion.length - 1]
  }`;
  return fileName;
};
