import React, { useEffect, useRef, SyntheticEvent, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { Notification } from 'redux/types/account';
import { DangerousInnerHTML } from '../styled-components/common';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { useHistory, useLocation } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Status = styled.div<{ isRead: boolean; disabled: boolean }>`
  width: 0.6em !important;
  height: 0.6em !important;
  background: ${({ theme, isRead }) => (isRead ? 'transparent' : theme.colors.orange.normal)};
  border: 1px solid ${({ theme, isRead }) => (isRead ? theme.colors.blue.normal : theme.colors.orange.normal)};
  border-radius: 10px;
  padding: 0;
  margin-right: 0.5em;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;

const NotificationHTML = styled(DangerousInnerHTML)<{ showPointer: boolean; hideAcceptButtons?: boolean }>`
  overflow: hidden !important;
  width: 95% !important;
  margin: 0.5em 0;

  &:hover {
    ${({ showPointer }) => showPointer && `cursor: pointer`}
  }

  .notification-table {
    width: 100% !important;
  }

  .profile-photo {
    width: 50px !important;
    display: flex;

    img {
      width: 50px !important;
      height: 50px !important;
      object-fit: cover !important;
      border-radius: 50%;
    }
  }
  .notification-text {
    width: 100% !important;
    padding-left: 15px !important;
    vertical-align: middle !important;

    .notification-content {
      display: none;
    }

    .notification-datetime {
      display: none;
    }

    div:last-child {
      display: none;
    }

    .notification-title {
      font-size: 0.9em !important;
      color: black !important;
      text-align: left;

      div:last-child {
        display: block;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }

      a {
        color: black !important;
        font-weight: bold;
      }

      .button-link {
        background: transparent !important;
        border: 0 !important;
        padding: 0.5em 1em !important;
        font-weight: 700 !important;
      }

      .green-small-button {
        color: ${({ theme }) => theme.colors.blue.normal} !important;
        ${({ hideAcceptButtons }) => hideAcceptButtons && `display: none;`}
      }

      .red-small-button {
        color: ${({ theme }) => theme.colors.orange.dark} !important;
        ${({ hideAcceptButtons }) => hideAcceptButtons && `display: none;`}
      }
    }
  }
`;

const mapStateToProps = (state: RootState) => ({
  userId: state.account.details.user.id,
  literals: state.literals,
});

const mapDispatchToProps = {};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  notification: Notification;
  markAsRead: any;
  bearer: string;
  myStuffEntities: {
    communities: { [id: number]: boolean };
    projects: { [id: number]: boolean };
    tabEntities?: { [id: number]: boolean };
  };
}

const NotificationRow: React.FC<Props> = ({ notification, markAsRead, bearer, literals, myStuffEntities }) => {
  const [entityId, setEntityId] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();

  const hideAcceptButtons =
    entityId !== undefined &&
    (myStuffEntities.communities[entityId] ||
      myStuffEntities.projects[entityId] ||
      myStuffEntities?.tabEntities?.[entityId]);

  const getEntityIdFromHref = (_href: string | undefined): number | undefined => {
    if (!_href) return undefined;
    const href = _href.split('/');
    if (href.length < 3) return undefined;

    if (href[href.length - 3] === 'tab-entity') {
      return parseInt(href[href.length - 2]);
    } else if (href[href.length - 3] === 'community') {
      return parseInt(href[href.length - 2]);
    } else if (href[href.length - 3] === 'project') {
      return parseInt(href[href.length - 2]);
    } else if (href[href.length - 2] === 'request') {
      const communityIdParams = href[href.length - 1].split('?');
      const communityId = parseInt(communityIdParams[communityIdParams.length - 1].substr(12));
      return communityId;
    } else {
      return undefined;
    }
  };

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!(event.target as any).href) return;
    const href = (event.target as any).href.split('/');

    const elementId = parseInt(href[href.length - 2]);

    if (href[href.length - 3] === 'community') {
      if (href[href.length - 1] === 'accept-invitation') {
        history.push(`/community/${elementId}/accept-invitation`, { backgroundModal: location });
      } else if (href[href.length - 1] === 'decline-invitation') {
        history.push(`/community/${elementId}/decline-invitation`, { backgroundModal: location });
      }
    } else if (href[href.length - 3] === 'project') {
      if (href[href.length - 1] === 'accept-invitation') {
        history.push(`/project/${elementId}/accept-invitation`, { backgroundModal: location });
      } else if (href[href.length - 1] === 'decline-invitation') {
        history.push(`/project/${elementId}/decline-invitation`, { backgroundModal: location });
      }
    } else if (href[href.length - 2] === 'request') {
      let communityId = href[href.length - 1].split('?');
      communityId = communityId[communityId.length - 1];
      communityId = parseInt(communityId.substr(12));
      if (href[href.length - 1].startsWith('accept-invitation')) {
        history.push(`/request/accept-invitation?communityId=${communityId}`, { backgroundModal: location });
      } else if (href[href.length - 1].startsWith('decline-invitation')) {
        history.push(`/request/decline-invitation?communityId=${communityId}`, { backgroundModal: location });
      }
    } else if (href[href.length - 3] === 'tab-entity') {
      if (href[href.length - 1] === 'accept-invitation') {
        history.push(`/tab-entity/${elementId}/accept-invitation`, { backgroundModal: location });
      } else if (href[href.length - 1] === 'decline-invitation') {
        history.push(`/tab-entity/${elementId}/decline-invitation`, { backgroundModal: location });
      }
    }
  };

  useEffect(() => {
    if (ref && ref.current && ref.current.getElementsByClassName) {
      const acceptButton = ref.current.getElementsByClassName('green-small-button')[0];
      const declineButton =
        ref.current.getElementsByClassName('red-small-button')[0] ||
        ref.current.getElementsByClassName('green-small-button')[1];
      if (acceptButton && declineButton) {
        (acceptButton as any).addEventListener('click', handleClick);
        (declineButton as any).addEventListener('click', handleClick);
        setEntityId(
          getEntityIdFromHref((acceptButton as any).href) || getEntityIdFromHref((declineButton as any).href),
        );
      }

      if (notification.interestLink && ref.current.getElementsByTagName) {
        const anchors = ref.current.getElementsByTagName('a');
        for (let i = 0; i < anchors.length; i++) {
          const a = anchors[i];
          if (a === acceptButton || a === declineButton) continue;
          a.href = '';
          a.onclick = (evt: any) => {
            evt.preventDefault();
          };
        }
      }
    }
  }, []);

  const handleMouseDownCapture = (evt: MouseEvent) => {
    if (notification.isRead) return;
    if (evt.button === 0 || evt.button === 1) markAsRead({ bearer, id: notification.id });
  };

  const handleClickCapture = (evt: SyntheticEvent) => {
    // console.log(notification);
    if (notification.interestLink) {
      evt.preventDefault();
      evt.stopPropagation();
      let url = notification.interestLink;
      // if the url is from the origin, then we can redirect there
      // without page reload, but if it's outside origin we redirect
      // there (with refresh obviously)
      if (url.substr(0, window.origin.length) === window.origin) {
        url = url.substr(window.origin.length);
        history.push(url);
      } else {
        window.open(url, '_blank');
      }
    }
  };

  return (
    <Container>
      <Status
        isRead={notification.isRead}
        as="button"
        onClick={() => markAsRead({ bearer, id: notification.id })}
        title={literals.notifications_mark_as_read}
        disabled={notification.isRead}
      />
      <NotificationHTML
        showPointer={notification.interestLink !== ''}
        title={notification.interestLink}
        onClickCapture={handleClickCapture}
        onMouseDownCapture={handleMouseDownCapture}
        ref={ref}
        dangerouslySetInnerHTML={{ __html: notification.content }}
        hideAcceptButtons={hideAcceptButtons}
      />
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationRow);
