import React, { useEffect, useState } from 'react';
import momentTimezone from 'moment-timezone';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { Day, UserWeeklyAvailability } from 'redux/types/calendar';
import { convertTimeToUTC, convertUTCToTime } from 'util/calendar';

const UnavailableMessage = styled.span`
  display: inline-block;
  color: gray;
  margin: 0 1rem;
`;

const DayName = styled.span`
  display: inline-block;
  text-transform: uppercase;
  margin: 0 1rem;
  font-weight: bolder;
  color: black;
  width: 10em;
`;

const FormatedInput = styled.input`
  margin: 0 0.4rem;
  border-radius: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.grey.medium};
  padding: 0.8em 0.5em;
  width: 6rem;
`;

const Container = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: left;
  align-items: center;
`;

interface IProps {
  day: Day;
  weeklyAvailabilities: UserWeeklyAvailability[];
  handleChangeWeeklyAvailability: (day: Day, startTime: string, endTime: string, id: number | null) => void;
  removeDay: (day: Day) => void;
  literals: any;
}

const WeeklyHoursItem: React.FC<IProps> = ({
  day,
  weeklyAvailabilities,
  handleChangeWeeklyAvailability,
  removeDay,
  literals,
}) => {
  const [isCheck, setIsCheck] = useState<boolean>(weeklyAvailabilities.length > 0);

  useEffect(() => {
    if (isCheck) {
      if (weeklyAvailabilities.length === 0) {
        handleChangeWeeklyAvailability(day, '09:00', '17:00', null);
      }
    } else {
      removeDay(day);
    }
  }, [isCheck]);

  return (
    <Container>
      <Checkbox checked={isCheck} onChange={event => setIsCheck(event.target.checked)} color={'primary'} />
      <DayName>{Day[day]}</DayName>
      {isCheck ? (
        weeklyAvailabilities.map(weeklyAvailability => {
          return (
            <div key={weeklyAvailability.id}>
              <FormatedInput
                defaultValue={weeklyAvailability.startTime.slice(0, 5)}
                onChange={e => {
                  handleChangeWeeklyAvailability(
                    day,
                    e.target.value,
                    weeklyAvailability.endTime,
                    weeklyAvailability.id,
                  );
                }}
                type="text"
              />
              <span>-</span>
              <FormatedInput
                defaultValue={weeklyAvailability.endTime.slice(0, 5)}
                onChange={e => {
                  handleChangeWeeklyAvailability(
                    day,
                    weeklyAvailability.startTime,
                    e.target.value,
                    weeklyAvailability.id,
                  );
                }}
                type="text"
              />
            </div>
          );
        })
      ) : (
        <UnavailableMessage>{literals.calendar_sync_unavailable_message}</UnavailableMessage>
      )}
    </Container>
  );
};

export default WeeklyHoursItem;
