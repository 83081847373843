import React, { useState, useEffect, FormEvent, useRef } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { Separator, BlueDot } from '../styled-components/common';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import { Project, Community, ProjectAwaitingFeedback, ProjectNextParagraphs } from 'redux/types/account';
import { map, filter, forEach, find } from 'ramda';
import { Flag } from '../../redux/types/enums';
import { Logo } from '../common/logo';
import { appendHrefTarget } from '../../util/utils';
import moment from 'moment';
import { StyledButton } from 'primitives/Button/style';
import { StyledFilters } from 'components/common/popup-filter-type-and-field';
import InnerHtmlWrapper from 'components/editor/InnerHtmlWrapper';

const Card = styled.div`
  width: 100%;
  min-height: 20em;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 0.5em;
  padding: 1em 0 1em 1em;
  margin-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a,
  span {
    font-size: 0.9em;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 4em);

  #yourProjects {
    font-size: 0.7em;
    line-height: 0.7em;
    color: ${({ theme }) => theme.colors.grey.dark};
  }
`;

const CustomLink = styled(Link)`
  &&& {
    color: ${({ theme }) => theme.colors.grey.dark};
    font-weight: bold;
    font-size: 0.9em;
  }
`;

const StyledDropdownItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 15em;
`;

const LogoContainer = styled.div`
  margin-right: 0.8em;
  border: solid 2px ${({ theme }) => theme.colors.grey.light};
  border-radius: 12px;
  background-color: white;
  z-index: 1;
  flex-shrink: 0;
`;

const ProjectName = styled.div`
  flex: 1;
  font-size: 0.9em !important;
`;
const Paragraph = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5em;
  position: relative;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`;

const OutlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 2em;
`;

const PastOutline = styled.div`
  width: 100%;
  border-bottom: solid 2px ${({ theme }) => theme.colors.grey.light};
`;

const CurrentParagraph = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  #label {
    color: ${({ theme }) => theme.colors.blue.normal};
    font-size: 0.6em;
    padding: 0.7em 0;
  }

  #name {
    font-size: 1.4em;
    font-weight: bold;
  }

  #description {
    padding-top: 1em;
  }
`;

const ParagraphNameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const NextParagraphName = styled.span`
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 9em;
  line-height: 1.2em;
  text-align: left;
  position: relative;
  top: -0.3em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const GreenDot = styled.div`
  position: absolute;
  z-index: 1;
  left: -1.17em;
  width: 0.5em;
  height: 0.5em;
  background: ${({ theme }) => theme.colors.green.dark};
  border-radius: 0.5em;
  margin: 0 1em 0 0;
`;

const DotLine = styled.div`
  position: absolute;
  top: -3.1em;
  left: -1.07em;
  width: 0;
`;

const Dot = styled.div`
  width: 0.3em;
  height: 0.3em;
  background: ${({ theme }) => theme.colors.blue.light};
  border-radius: 50%;
  margin: 0.2em 1em 0.2em 0;
`;

const GreenLine = styled(GreenDot)<{ $lineIndex?: number }>`
  width: 0.5em;
  height: 2em;
  background: ${({ theme }) => theme.colors.green.light};
  position: absolute;
  z-index: 0;
  top: -1.3em;
  left: -1.17em;
  border-radius: 0;
  ${({ $lineIndex, theme }) =>
    $lineIndex === 0 &&
    `background: transparent;
     background-image: linear-gradient(white, white, ${theme.colors.green.light}, ${theme.colors.green.light})`}
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 17em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionLogoContainer = styled.div`
  margin-right: 0.3em;
  border: solid 1px ${({ theme }) => theme.colors.grey.light};
  border-radius: 3px;
`;

const ProjectTitle = styled.span`
  font-size: 1.1em !important;
  font-weight: bold;
  border: 0 solid;
  padding: 0;
  margin: 0;
  line-height: 1.8em;
  width: 11em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
`;

const BMContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 2em;
`;

const OneLineText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const mapStateToProps = (state: RootState) => ({
  projects: state.account.projects.list,
  user: state.account.details.user,
  isFetchingProjectsAwaitingFeedback: state.loading.fetchProjectsAwaitingFeedbackFlag,
  isFetchingProjectsNextParagraphs: state.loading.fetchProjectsNextParagraphsFlag,
  literals: state.literals,
});

const DropdownItem = styled(Dropdown.Item)`
  padding: 0.5em !important;
  width: 100%;
`;

const StyledEntrepreneurDropdown = styled(StyledFilters)`
  &&& {
    .MuiSelect-select {
      font-size: 1.3em !important;
      font-weight: bold;
      line-height: 1.8em;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      padding-left: 0;
    }
    margin-top: 1em;
    padding-left: 0;
    border: unset;
    width: auto;
    max-width: 90%;
  }
`;

const mapDispatchToProps = {};

interface IDropdownOption {
  key: number;
  text: string;
  value: number;
  logo: string;
}

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  community: Community;
}

export enum OutputLineType {
  Section = 0,
  Paragraph = 1,
}

const CtaEntrepreneurs: React.FC<Props> = ({
  community,
  projects,
  isFetchingProjectsAwaitingFeedback,
  isFetchingProjectsNextParagraphs,
  literals,
}) => {
  const { communityId, projectId } = useParams<{ communityId: string; projectId: string }>();
  const [projectSelected, setProjectSelected] = useState<IDropdownOption | undefined>(undefined);
  const [hideCta, setHideCta] = useState<boolean>(false);
  const [pNextDescription, setPNextDescription] = useState<string>('');
  const [projectOptions, setProjectOptions] = useState<IDropdownOption[]>([]);
  const [nextParagraph, setNextParagraph] = useState<{
    id: number;
    name: string;
    description: string;
    taskDateStart?: string;
  }>({
    id: 0,
    name: '',
    description: '',
  });
  const [outline, setOutline] = useState<
    { name: string; type: OutputLineType; id: number; logo?: string; taskDateStart?: string }[]
  >([]);
  const [isMentor, setIsMentor] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (projectId && projects && projects.length > 0) {
      const currentProject = find((p: Project) => p.id === Number(projectId), projects);
      if (
        !currentProject?.canEditProjectInfo &&
        community.projectsAwaitingFeedback &&
        community.projectsAwaitingFeedback?.length === 0
      ) {
        setHideCta(true);
      }
    }
  }, [projects, community.projectsAwaitingFeedback]);

  useEffect(() => {
    setNextTask();
  }, [projectId, communityId, community.projectsNextParagraphs]);

  useEffect(() => {
    if (
      projectSelected !== undefined &&
      !isMentor &&
      community.projectsNextParagraphs &&
      community.projectsNextParagraphs.length > 0
    ) {
      const currentP = filter(
        (p: ProjectNextParagraphs) => p.id === projectSelected.value,
        community.projectsNextParagraphs,
      );
      if (currentP.length > 0) {
        setNextParagraph({
          id: currentP[0].nextParagraph.id,
          name: currentP[0].nextParagraph.name,
          description: currentP[0].nextParagraph.description,
          taskDateStart: currentP[0].nextParagraph.taskDateStart,
        });
        setPNextDescription(nextParagraph.description);
        // Solved assignments => the last 3 assignments / sections completed
        // If there are no solved assignments will display the section of the
        // next assignment
        const outline = getSolvedOutline(currentP[0]);
        const nextParagraphSection = currentP[0].sections.find(
          section => section.assignments.find(a => a.id === currentP[0].nextParagraph.id) !== undefined,
        );
        if (outline.length > 0) setOutline(outline);
        else if (nextParagraphSection)
          setOutline([
            {
              type: 0,
              id: nextParagraphSection.id,
              name: nextParagraphSection.name,
              logo: nextParagraphSection.logo,
            },
          ]);
        setNextParagraph(currentP[0].nextParagraph);
      }
    }
    if (projectSelected !== undefined && isMentor && community.projectsAwaitingFeedback) {
      const currentP = filter(
        (p: ProjectAwaitingFeedback) => p.id === projectSelected.value,
        community.projectsAwaitingFeedback,
      );
      if (currentP.length > 0) {
        setNextParagraph({
          id: currentP[0].sections[0].paragraphs[0].id,
          name: currentP[0].sections[0].paragraphs[0].name,
          description: '',
        });
        setPNextDescription('');
        setOutline([]);
      }
    }
  }, [projectSelected, projects, community]);

  const isPublished = (assignment: any) => {
    return assignment?.content?.trim() !== '';
  };

  const getSolvedOutline = (p: ProjectNextParagraphs) => {
    const outline = p.sections
      .map((section: any) => {
        const finishedAssignments = section.assignments.filter((assignment: any) => isPublished(assignment));
        if (finishedAssignments.length === 0) return [];
        else
          return [
            { id: section.id, type: 0, name: section.name, logo: section.logo },
            ...finishedAssignments.map((assignment: any) => {
              return { id: assignment.id, name: assignment.name, type: 1 };
            }),
          ];
      })
      .reduce((acc, value) => [...acc, ...value], []);
    return outline.splice(Math.max(outline.length - 3, 0));
  };

  const setNextTask = () => {
    if (community.projectsNextParagraphs && community.projectsNextParagraphs.length > 0) {
      const projectOptions = map((p: ProjectNextParagraphs) => {
        return {
          key: p.id,
          text: p.name,
          value: p.id,
          logo: p.logo,
        };
      }, community.projectsNextParagraphs);

      if (projectId) {
        const projectMatch = find((p: IDropdownOption) => p.key === Number(projectId), projectOptions);
        if (projectMatch) {
          setProjectOptions([projectMatch]);
          setProjectSelectedById(projectMatch.value, [projectMatch]);
        } else {
          setProjectOptions(projectOptions);
          setProjectSelectedById(projectOptions[0].value, projectOptions);
          setTaskAwaitingFeedback();
        }
      } else {
        setProjectOptions(projectOptions);
        setProjectSelectedById(projectOptions[0].value, projectOptions);
      }
    } else {
      setProjectOptions([]);
    }
  };

  const canAccessTask = () => {
    if (!nextParagraph.taskDateStart) return true;
    return !moment(nextParagraph.taskDateStart, 'YYYY-MM-DDTHH-mm-ssZ').isAfter(moment());
  };

  const setTaskAwaitingFeedback = () => {
    if (community.projectsAwaitingFeedback && community.projectsAwaitingFeedback.length > 0) {
      forEach((p: ProjectAwaitingFeedback) => {
        if (p.id === Number(projectId)) {
          const projectOptions = [
            {
              key: p.id,
              text: p.name,
              value: p.id,
              logo: p.logo,
            },
          ];
          setProjectOptions(projectOptions);
          setProjectSelectedById(p.id, projectOptions);
          setIsMentor(true);
        }
      }, community.projectsAwaitingFeedback);
    }
  };

  const handleProjectChange = (e: FormEvent, data: any) => {
    e.persist();
    const { value } = data;
    setProjectSelectedById(value);
  };

  const setProjectSelectedById = (id: number, _projectOptions?: IDropdownOption[]) => {
    const project = (_projectOptions || projectOptions).find((project: IDropdownOption) => project.value === id);
    setProjectSelected(project);
  };

  if (hideCta) return null;
  if (projectOptions.length === 0) return null;
  return (
    <Card>
      {isFetchingProjectsNextParagraphs === Flag.Request || isFetchingProjectsAwaitingFeedback === Flag.Request ? (
        <LoaderContainer>
          <Dimmer inverted active>
            <Loader inverted>{literals.project_list_loading_tag_message}</Loader>
          </Dimmer>
        </LoaderContainer>
      ) : (
        <>
          {projectOptions.length > 0 && (
            <>
              <Header>
                <LogoContainer>
                  <Logo
                    customSize={'64x64'}
                    style={{ width: '3em', height: '3em' }}
                    src={projectSelected?.logo || ''}
                  />
                </LogoContainer>
                <Column>
                  <span id="yourProjects">{literals.community_cta_your_projects}</span>
                  {projectOptions.length === 1 ? (
                    <ProjectTitle>{projectOptions[0].text}</ProjectTitle>
                  ) : (
                    <StyledEntrepreneurDropdown
                      disableUnderline
                      applyPadding={true}
                      displayEmpty
                      value={
                        projectOptions.find((o: IDropdownOption) => o.value === (projectSelected?.value || 0))?.text ||
                        ''
                      }
                      ref={dropdownRef}
                      disabled={projectOptions.length <= 1}
                      renderValue={() => {
                        if (!projectOptions || projectOptions.length === 0) {
                          return literals.community_list_advanced_search_project;
                        }
                        return (
                          projectOptions.find((o: IDropdownOption) => o.value === (projectSelected?.value || 0))
                            ?.text || ''
                        );
                      }}
                    >
                      <Dropdown.Menu style={{ width: '15em', textAlign: 'left' }}>
                        {projectOptions.map((option: IDropdownOption) => (
                          <DropdownItem onClick={(e: FormEvent) => handleProjectChange(e, option)} key={option.value}>
                            <StyledDropdownItem>
                              <LogoContainer>
                                <Logo
                                  loading="eager"
                                  customSize={'64x64'}
                                  style={{ width: '2.5em', height: '2.5em' }}
                                  src={option.logo}
                                />
                              </LogoContainer>
                              <ProjectName>{option.text}</ProjectName>
                            </StyledDropdownItem>
                          </DropdownItem>
                        ))}
                      </Dropdown.Menu>
                    </StyledEntrepreneurDropdown>
                  )}
                </Column>
              </Header>
              <OutlineContainer>
                <PastOutline>
                  {outline.map(
                    (o: { name: string; type: OutputLineType; id: number; logo?: string }, index: number) => {
                      if (o.type === OutputLineType.Paragraph) {
                        return (
                          <Paragraph key={o.id}>
                            <GreenLine $lineIndex={index} />
                            <GreenDot />
                            <OneLineText>{o.name}</OneLineText>
                          </Paragraph>
                        );
                      } else {
                        return (
                          <Paragraph key={o.id}>
                            <GreenLine $lineIndex={index} />
                            <GreenDot />
                            {o.logo && (
                              <SectionLogoContainer
                                style={{ position: 'absolute', left: '-1.675em', zIndex: 2, border: '0' }}
                              >
                                <Logo
                                  loading="eager"
                                  customSize={'64x64'}
                                  style={{
                                    width: '1.5em',
                                    height: '1.5em',
                                    maxWidth: 'none',
                                    borderRadius: '4px',
                                  }}
                                  src={o.logo}
                                />
                              </SectionLogoContainer>
                            )}
                            <span
                              style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                marginRight: '.5em',
                              }}
                            >
                              {o.name}
                            </span>
                          </Paragraph>
                        );
                      }
                    },
                  )}
                </PastOutline>
                <CurrentParagraph>
                  <span style={{ fontWeight: 'bold' }} id="label">
                    {literals.community_cta_current_assignment}
                  </span>
                  <ParagraphNameContainer>
                    <DotLine>
                      {Array(7)
                        .fill('')
                        .map((i, index) => (
                          <Dot key={index} />
                        ))}
                    </DotLine>
                    <BlueDot style={{ marginLeft: '-1.15em', position: 'absolute', top: '0.25em' }} />
                    <NextParagraphName id="name">{nextParagraph.name}</NextParagraphName>
                  </ParagraphNameContainer>
                  {pNextDescription && (
                    <InnerHtmlWrapper
                      html={appendHrefTarget(pNextDescription)}
                      style={{ fontWeight: 'bold', fontSize: '0.9em', marginBottom: '0.7em' }}
                    />
                  )}
                </CurrentParagraph>
                {canAccessTask() ? (
                  <StyledButton
                    as={Link}
                    to={`/project/${projectSelected?.value || 0}/business-model?paragraphId=${nextParagraph.id}`}
                    onClick={() => {}}
                    style={{ marginTop: '1em', textTransform: 'none' }}
                  >
                    {isMentor ? literals.community_cta_start_mentoring : literals.community_cta_start_assignment}
                  </StyledButton>
                ) : (
                  <span>
                    {(literals.business_model_assignment_availability_date || '').replace(
                      '{0}',
                      moment(nextParagraph.taskDateStart, 'YYYY-MM-DDTHH-mm-ssZ').format('YYYY-MM-DD'),
                    )}
                  </span>
                )}
              </OutlineContainer>
              <Separator style={{ width: 'calc(100% - 2em)', marginLeft: '2em' }} />
              <BMContainer>
                <CustomLink to={`/project/${projectSelected?.value || 0}/business-model`}>
                  {literals.community_cta_explore_business_model}
                </CustomLink>
              </BMContainer>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CtaEntrepreneurs);
