import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '../styled-components/common';
import { Form, Rating, RatingProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { reviewAdvisor, reviewEntrepreneur } from '../../services/api/community';
import { Request } from 'redux/types/account';
import { toast } from './toast';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import isFeedbackFormValid from 'util/predicates/isFeedbackFormValid';
import { StyledButton } from 'primitives/Button/style';
import isRequestAdvisor from 'util/predicates/isRequestAdvisor';
import { fetchRequestById } from 'services/api/project';

const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  label {
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 1em;
  }
`;

const RatingRow = styled.div`
  margin: 1em 0 2em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface FormState {
  rating: number;
  feedback: string;
  comment: string;
}

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

const mapDispatchToProps = {};

type IRequestRateAdvisorProps = {
  closeModal: (options?: { refetchRequest?: boolean }) => void;
  onSubmit?: () => void;
  userId: number;
  bearer: string;
  request: Request;
} & typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const GiveFeedbackForm: React.FC<IRequestRateAdvisorProps> = ({
  request,
  closeModal,
  onSubmit,
  userId,
  bearer,
  literals,
}) => {
  const [formState, setFormState] = useState<FormState>({
    rating: 0,
    feedback: '',
    comment: '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [fullRequestDetails, setFullRequestDetails] = useState<Request | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const isAdvisor = fullRequestDetails ? isRequestAdvisor(userId, fullRequestDetails) : false;

  useEffect(() => {
    fetchRequestById(bearer, request.id).then(fullReq => {
      setFullRequestDetails(fullReq.request);
      setLoading(false);
    });
  }, []);

  const handleRate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, { rating }: RatingProps) =>
    setFormState({ ...formState, rating: Number(rating) || 0 });

  useEffect(() => {
    setIsFormValid(isFeedbackFormValid(formState, isAdvisor));
  }, [setIsFormValid, formState, isAdvisor]);

  const handleOnSubmit = async () => {
    if (!isFeedbackFormValid(formState, isAdvisor)) {
      return false;
    }
    const { rating, feedback, comment } = formState;
    setLoading(true);

    try {
      if (fullRequestDetails && isAdvisor) {
        if (fullRequestDetails?.details?.advisorId) {
          await reviewAdvisor(
            bearer,
            fullRequestDetails?.details?.advisorId,
            fullRequestDetails?.id,
            rating,
            feedback,
            comment,
          );
        } else {
          toast(literals.request_feedback_unidentified_advisor, { type: 'error' });
        }
      } else {
        await reviewEntrepreneur(bearer, request.id, rating, comment, null);
      }
      toast(literals.request_feedback_save_success, { type: 'success' });
      closeModal({ refetchRequest: true });
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      toast(literals.request_feedback_save_fail, { type: 'error' });
    }
    setLoading(false);
  };

  return (
    <Form loading={loading} onSubmit={handleOnSubmit}>
      <Container>
        {isAdvisor && (
          <>
            <label style={{ fontWeight: 'bold' }}>{literals.request_feedback_advisor_title}</label>
            <Form.TextArea
              name="feedback"
              style={{ marginTop: 10 }}
              placeholder={literals.request_feedback_advisor_placeholder}
              onChange={(e: any) => setFormState({ ...formState, feedback: e.target.value })}
            />
          </>
        )}
        <RateContainer>
          <label>{literals.request_feedback_rating_title}</label>
          <RatingRow>
            <span style={{ marginRight: '1em' }}>{literals.request_feedback_rating_minimum}</span>
            <Rating icon="heart" size={'huge'} maxRating={5} onRate={handleRate} />
            <span style={{ marginLeft: '1em' }}>{literals.request_feedback_rating_maximum}</span>
          </RatingRow>
        </RateContainer>
        <label style={{ fontWeight: 'bold' }}>{literals.request_feedback_comment_title}</label>
        <Form.TextArea
          style={{ marginTop: 10 }}
          placeholder={literals.request_feedback_comment_placeholder}
          name="comment"
          onChange={(e: any) => setFormState({ ...formState, comment: e.target.value })}
        />
        <StyledButton type="submit" disabled={!isFormValid}>
          {literals.global_submit}
        </StyledButton>
        <SecondaryButton onClick={closeModal}>{literals.confirm_cancel_button}</SecondaryButton>
      </Container>
    </Form>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GiveFeedbackForm);
