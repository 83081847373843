import {
  User,
  Notification,
  NotificationSetting,
  SkillCategory,
  Literals,
  Country,
  SDG,
  MyStuff,
  ProjectCategory,
} from 'redux/types/account';
import { createAsyncAction, createAction } from 'typesafe-actions';
import { Languages, TipState } from '../types/enums';

export const fetchNotificationsAsync = createAsyncAction(
  'FETCH_NOTIFICATIONS_REQUEST',
  'FETCH_NOTIFICATIONS_SUCCESS',
  'FETCH_NOTIFICATIONS_FAILURE',
  'FETCH_NOTIFICATIONS_IDLE',
)<{ bearer: string; take: number; skip: number }, { skip: number; notifications: Notification[] }, string, null>();

export const markNotificationAsReadAsync = createAsyncAction(
  'MARK_NOTIFICATION_AS_READ_REQUEST',
  'MARK_NOTIFICATION_AS_READ_SUCCESS',
  'MARK_NOTIFICATION_AS_READ_FAILURE',
  'MARK_NOTIFICATION_AS_READ_IDLE',
)<{ bearer: string; id: number }, { id: number }, string, null>();

export const markAllNotificationsAsReadAsync = createAsyncAction(
  'MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST',
  'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS',
  'MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE',
  'MARK_ALL_NOTIFICATIONS_AS_READ_IDLE',
)<{ bearer: string }, null, string, null>();

export const fetchCurrentUserAsync = createAsyncAction(
  'FETCH_USER_DATA_REQUEST',
  'FETCH_USER_DATA_SUCCESS',
  'FETCH_USER_DATA_FAILURE',
  'FETCH_USER_DATA_IDLE',
)<string, User, string, null>();

export const reacceptTermsAndConditionsAsync = createAsyncAction(
  'REACCEPT_TERMS_AND_CONDITIONS_REQUEST',
  'REACCEPT_TERMS_AND_CONDITIONS_SUCCESS',
  'REACCEPT_TERMS_AND_CONDITIONS_FAILURE',
  'REACCEPT_TERMS_AND_CONDITIONS_IDLE',
)<string, User, string, null>();

export const fetchMyStuffAsync = createAsyncAction(
  'FETCH_MY_STUFF_REQUEST',
  'FETCH_MY_STUFF_SUCCESS',
  'FETCH_MY_STUFF_FAILURE',
  'FETCH_MY_STUFF_IDLE',
)<{ bearer: string }, MyStuff, string, null>();

export const fetchNotificationSettingsAsync = createAsyncAction(
  'FETCH_NOTIFICATION_SETTINGS_REQUEST',
  'FETCH_NOTIFICATION_SETTINGS_SUCCESS',
  'FETCH_NOTIFICATION_SETTINGS_FAILURE',
  'FETCH_NOTIFICATION_SETTINGS_IDLE',
)<{ bearer: string; userId: number }, NotificationSetting[], string, null>();

export const saveNotificationSettingsAsync = createAsyncAction(
  'SAVE_NOTIFICATION_SETTINGS_REQUEST',
  'SAVE_NOTIFICATION_SETTINGS_SUCCESS',
  'SAVE_NOTIFICATION_SETTINGS_FAILURE',
  'SAVE_NOTIFICATION_SETTINGS_IDLE',
)<{ bearer: string; userId: number; settings: NotificationSetting[] }, NotificationSetting[], string, null>();

export const toggleNotificationsAsync = createAsyncAction(
  'TOGGLE_NOTIFICATIONS_REQUEST',
  'TOGGLE_NOTIFICATIONS_SUCCESS',
  'TOGGLE_NOTIFICATIONS_FAILURE',
  'TOGGLE_NOTIFICATIONS_IDLE',
)<{ bearer: string; state: boolean }, { state: boolean }, string, null>();

export const saveProfileAsync = createAsyncAction(
  'SAVE_PROFILE_REQUEST',
  'SAVE_PROFILE_SUCCESS',
  'SAVE_PROFILE_FAILURE',
  'SAVE_PROFILE_IDLE',
)<
  {
    bearer: string;
    rawUser?: any;
    userId: number;
    country: number;
    city: string;
    role: string;
    bio: string;
    file: File | null;
    photo: string;
    firstName: string;
    lastName: string;
    interests: number[];
    skills: number[];
    language: Languages;
    marketExpertises: Country[];
    sustainableDevelopmentGoals: SDG[];
    primaryCommunityId: number;
    linkedInUrl?: string;
    company: string;
  },
  User,
  string,
  null
>();

export const fetchSkillsAsync = createAsyncAction(
  'FETCH_SKILLS_REQUEST',
  'FETCH_SKILLS_SUCCESS',
  'FETCH_SKILLS_FAILURE',
  'FETCH_SKILLS_IDLE',
)<{ bearer: string }, SkillCategory[], string, null>();

export const updateSkillAsync = createAsyncAction(
  'UPDATE_SKILL_REQUEST',
  'UPDATE_SKILL_SUCCESS',
  'UPDATE_SKILL_FAILURE',
  'UPDATE_SKILL_IDLE',
)<any, any, string, null>();

export const getLiteralsAsync = createAsyncAction(
  'GET_LITERALS_REQUEST',
  'GET_LITERALS_SUCCESS',
  'GET_LITERALS_FAILURE',
  'GET_LITERALS_IDLE',
)<
  { language: Languages; communityId?: number; bearer?: string },
  {
    language: Languages;
    literals: Literals;
    communityId?: number;
  },
  string,
  null
>();

export const changeTipStateAsync = createAsyncAction(
  'CHANGE_TIP_STATE_REQUEST',
  'CHANGE_TIP_STATE_SUCCESS',
  'CHANGE_TIP_STATE_FAILURE',
  'CHANGE_TIP_STATE_IDLE',
)<{ tip: string; state: TipState }, { tip: string; state: TipState }, string, null>();

export const clearHasPendingRequestsAsync = createAsyncAction(
  'CLEAR_HAS_PENDING_REQUESTS_REQUEST',
  'CLEAR_HAS_PENDING_REQUESTS_SUCCESS',
  'CLEAR_HAS_PENDING_REQUESTS_FAILURE',
  'CLEAR_HAS_PENDING_REQUESTS_IDLE',
)<{ bearer: string }, null, string, null>();

export const fetchCountriesAsync = createAsyncAction(
  'FETCH_COUNTRIES_REQUEST',
  'FETCH_COUNTRIES_SUCCESS',
  'FETCH_COUNTRIES_FAILURE',
  'FETCH_COUNTRIES_IDLE',
)<{ bearer: string; loadGlobalOption?: boolean }, Country[], string, null>();

export const fetchSDGsAsync = createAsyncAction(
  'FETCH_SDGS_REQUEST',
  'FETCH_SDGS_SUCCESS',
  'FETCH_SDGS_FAILURE',
  'FETCH_SDGS_IDLE',
)<{ bearer: string }, SDG[], string, null>();

export const fetchProjectCategoriesAsync = createAsyncAction(
  'FETCH_PROJECT_CATEGORIES_REQUEST',
  'FETCH_PROJECT_CATEGORIES_SUCCESS',
  'FETCH_PROJECT_CATEGORIES_FAILURE',
  'FETCH_PROJECT_CATEGORIES_IDLE',
)<{ bearer: string; loadAllOption?: boolean }, ProjectCategory[], string, null>();

export const syncCalendarUpdate = createAction('SYNC_CALENDAR_UPDATE')<{
  isCalendarSynchronized: boolean;
  synchronizedCalendarType: 0 | 1;
  synchronizedCalendarAccount: string | null;
}>();

export const syncMeetingProviderUpdate = createAction('SYNC_MEETING_PROVIDER_UPDATE')<{
  hasMeetingProvider: boolean;
  meetingProviderType: 0 | 1;
  meetingProviderAccount: string | null;
}>();

export default {
  fetchNotificationsAsync,
  markNotificationAsReadAsync,
  markAllNotificationsAsReadAsync,
  fetchCurrentUserAsync,
  fetchMyStuffAsync,
  fetchNotificationSettingsAsync,
  saveNotificationSettingsAsync,
  toggleNotificationsAsync,
  saveProfileAsync,
  updateSkillAsync,
  fetchSkillsAsync,
  getLiteralsAsync,
  changeTipStateAsync,
  reacceptTermsAndConditionsAsync,
  clearHasPendingRequestsAsync,
  fetchCountriesAsync,
  fetchSDGsAsync,
  fetchProjectCategoriesAsync,
  syncCalendarUpdate,
  syncMeetingProviderUpdate,
};
