import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const VariantEnum = {
  Text: 'text',
  Outlined: 'outlined',
  Secondary: 'secondary',
};

export const StyledButton = styled(Button)<any>`
  &&& {
    border-radius: 10em;
    padding: 0.6em 2em;
    background-color: ${({ theme }) => theme.colors.blue.normal};
    border: 1px solid ${({ theme }) => theme.colors.blue.normal};
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.7em;
    margin: 0.2em;
    text-transform: uppercase;
    white-space: nowrap;
    box-sizing: border-box;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
    font-weight: bold;
    line-height: 1em;
    white-space: nowrap;
  }

  ${({ variant, theme, color }) =>
    variant === VariantEnum.Secondary &&
    `
    border: 2px solid ${theme.colors.grey.light};
    background-color: ${theme.colors.white};
    color: ${color || theme.colors.grey.dark};
  `}

  ${({ variant }) =>
    variant === VariantEnum.Text &&
    `
    background-color: transparent;
    border: none;
    color: #000;
  `}
  
  ${({ variant }) =>
    variant === VariantEnum.Outlined &&
    `
    background-color: transparent;
    border-color: #eee;
    color: #000;
  `}

   ${({ style }) => style && style}
`;
