import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA4 from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';

import Modal from './modal-component';
import { SecondaryButton, ReportIssueButton } from 'components/styled-components/common';
import { reportIssue } from 'services/api/users';
import { toast } from './toast';
import { getToken } from 'redux/selectors/account';
import { StyledButton } from 'primitives/Button/style';
import Editor from 'components/editor';

const SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY || '';

const mapStateToProps = state => ({
  literals: state.literals,
  bearer: getToken(state),
});

const MessageModal = props => {
  const [content, setContent] = useState('');
  const [isReportingIssue, setIsReportingIssue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const { bearer, literals } = props;

  useEffect(() => {
    setContent('');
  }, [isReportingIssue]);

  const recaptchaOnChange = value => {
    setRecaptchaResponse(value);
  };

  const handleSendMessage = () => {
    if (content.trim().length === 0) {
      toast(literals.group_message_empty_message_error, { type: 'warning' });
      return;
    }
    if (recaptchaResponse === '') {
      toast(literals.auth_check_recaptcha, { type: 'error' });
      return;
    }
    ReactGA4.event({ category: 'Report issue', action: 'Report issue' });
    setIsLoading(true);
    reportIssue(bearer, content, recaptchaResponse)
      .then(() => {
        toast(literals.group_message_success_message, { type: 'success' });
        setIsReportingIssue(false);
      })
      .catch(() => {
        ReactGA4.event({ category: 'Report issue', action: 'Report issue failed' });
        toast(literals.group_message_failure_message, { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpenReportIssueModal = () => {
    ReactGA4.event({ category: 'Report issue', action: 'Open report issue modal' });
    setIsReportingIssue(true);
  };

  const handleCloseReportIssueModal = () => {
    ReactGA4.event({ category: 'Report issue', action: 'Close report issue modal' });
    setIsReportingIssue(false);
  };
  if (bearer === '') {
    return null;
  }
  return (
    <>
      <ReportIssueButton onClick={handleOpenReportIssueModal} className="marker-app">
        {literals.report_issue_button}
      </ReportIssueButton>
      <Modal open={isReportingIssue} title={literals.report_issue_button}>
        <Editor
          content={content}
          placeholder={literals.group_message_placeholder}
          lightToolbar
          setContent={setContent}
          initAutoFocus
        />
        <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '1em' }}>
          <ReCAPTCHA sitekey={SITEKEY} onChange={recaptchaOnChange} />
          <SecondaryButton onClick={handleCloseReportIssueModal}>{literals.send_message_cancel_button}</SecondaryButton>
          <StyledButton disabled={isLoading} loading={isLoading} onClick={handleSendMessage}>
            {literals.send_message_send_button}
          </StyledButton>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, {})(MessageModal);
