import React, { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Project, KpiValue } from 'redux/types/account';
import { RootState } from 'StoreModel';
import { fetchPendingKpiAsync, saveKpiValuesAsync } from '../../redux/actions/project';
import { SecondaryButton, StyledCardDescription } from '../styled-components/common';
import { Button, Image, Modal } from 'semantic-ui-react';
import { forEach, sort } from 'ramda';
import { getMonthName } from '../../services/api/helper';
import { Flag } from '../../redux/types/enums';
import kpiLogo from '../../assets/project/KPIs.jpg';
import { StyledButton } from 'primitives/Button/style';

const KpiCard = styled.div`
  width: 100%;
  height: 50%;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 0.5em;
  margin: 7px 0 0 0;
  padding: 1em;
  gap: 1em;
  margin-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: flex-end;
`;

const KpiSteps = styled.div`
  padding: 0 1em 0.3em 0;
  font-size: 0.9em;
  color: ${props => props.theme.colors.grey.medium};
  font-weight: 500;
`;

const EditKpiModalTitle = styled.h2`
  text-align: center;
`;

const EditKpiForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  max-height: 350px;
  overflow: auto;
`;

const Description = styled.span`
  padding: 0.5em 0;
  align-self: flex-start;
`;

const FormFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Inputlabel = styled.div``;

const EditKpiModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`;

const InputField = styled.input`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  text-align: center;
  width: 100px;
  margin-left: 20px;
`;

const KpiTableHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid ${props => props.theme.colors.blue.veryLight};
  padding-bottom: 1em;
  text-align: center;
`;

const Label = styled.div`
  font-weight: 500;
  width: 33%;
`;

type KpiSet = {
  key: number;
  month: number;
  year: number;
  kpis: KpiValue[];
};

const mapStateToProps = (state: RootState) => ({
  isSavingKpi: state.loading.saveKpiValuesFlag,
  isFetchingKpis: state.loading.fetchPendingKpiFlag,
  literals: state.literals,
  projects: state.account.projects.list,
});

const mapDispatchToProps = {
  fetchPendingKpis: fetchPendingKpiAsync.request,
  saveKpiValues: saveKpiValuesAsync.request,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  project: Project;
  bearer: string;
}

const KpiUpdate: React.FC<Props> = ({
  project,
  bearer,
  fetchPendingKpis,
  isSavingKpi,
  isFetchingKpis,
  literals,
  saveKpiValues,
}) => {
  const [kpiSets, setKpiSets] = useState<KpiSet[]>([]);

  const isSaving = isSavingKpi === Flag.Request;

  const [isKpiModalOpen, setIsKpiModalOpen] = useState<boolean>(false);

  const invalidInputs = ['e', 'E', '+', '-'];

  const toggleKpiModal = () => {
    setIsKpiModalOpen(!isKpiModalOpen);
  };

  useEffect(() => {
    if (project.isMember || project.isProjectAdmin) {
      fetchPendingKpis({ bearer, projectId: project.id });
    }
  }, [bearer, project.id]);

  useEffect(() => {
    if (isSavingKpi === Flag.Success && (project.isMember || project.isProjectAdmin)) {
      fetchPendingKpis({ bearer, projectId: project.id });
    }
  }, [bearer, isSaving]);

  useEffect(() => {
    if (project.kpiValues && project.kpiValues?.length > 0 && isFetchingKpis === Flag.Success) {
      const auxKpiSets: KpiSet[] = [];
      auxKpiSets.push({
        key: 0,
        month: project.kpiValues[0].month,
        year: project.kpiValues[0].year,
        kpis: [],
      });

      forEach((kpiV: KpiValue) => {
        const kpiSetIndex = auxKpiSets.findIndex(
          (kpiS: KpiSet) => kpiV.month === kpiS.month && kpiV.year === kpiS.year,
        );
        if (kpiSetIndex !== -1) {
          auxKpiSets[kpiSetIndex].kpis.push(kpiV);
        } else {
          auxKpiSets.push({
            key: 0,
            month: kpiV.month,
            year: kpiV.year,
            kpis: new Array(kpiV),
          });
        }
      }, project.kpiValues);

      // Sort ASC by month and year
      let sortedKpiSets = sort((a: KpiSet, b: KpiSet) => {
        if (a.year === b.year) {
          return a.month - b.month;
        } else {
          return a.year - b.year;
        }
      }, auxKpiSets);

      sortedKpiSets = sortedKpiSets.map((k: KpiSet, index: number) => {
        return {
          ...k,
          key: index + 1,
        };
      });

      setKpiSets(sortedKpiSets);
      const inputElems: HTMLInputElement[] = Array.from(document.querySelectorAll('.kpi-input'));

      for (const inputElem of inputElems) {
        inputElem.value = '';
      }
    }
  }, [project.kpiValues, isFetchingKpis]);

  const handleSaveKpis = (event: SyntheticEvent) => {
    event.preventDefault();
    saveKpiValues({ bearer, kpis: kpiSets[0].kpis });
  };

  const getDateLabel = (kpiSet: KpiSet) => {
    return `${getMonthName(kpiSet.month)} ${kpiSet.year}`;
  };
  const onKpiInputChange = (e: any) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value, name },
    } = e;

    const currentKpiSet = kpiSets[0];
    currentKpiSet.kpis.forEach((k: KpiValue) => {
      if (k.name === name) {
        k.value = Number(value);
      }
    });
  };

  function handleKeyDown(e: KeyboardEvent) {
    if (invalidInputs.includes(e.key)) {
      e.preventDefault();
    }
  }

  if (!project) return null;
  if (!project.isMember && !project.isProjectAdmin) return null;
  if (project.kpiValues && project.kpiValues.length === 0) return null;
  if (isSavingKpi === Flag.Failure) return null;
  if (kpiSets && kpiSets.length === 0) return null;

  return (
    <KpiCard>
      <Image src={kpiLogo} alt={literals.update_kpi_image_alt_message} />
      <StyledCardDescription>{literals.kpi_update_reminder_message}</StyledCardDescription>
      <StyledButton onClick={toggleKpiModal}>{literals.project_kpi_input_now_button}</StyledButton>
      {isKpiModalOpen && (
        <Modal style={{ maxWidth: '40em' }} open={true}>
          <Modal.Content style={{ paddingLeft: '5em', paddingRight: '5em' }}>
            <EditKpiModal>
              <EditKpiModalTitle>Add KPIs</EditKpiModalTitle>
              <Description>{literals.kpi_update_kpi_description}</Description>
              <EditKpiForm onSubmit={handleSaveKpis}>
                <KpiTableHead>
                  <Label className="cell">
                    <span>{literals.kpi}</span>
                  </Label>
                  <Label className="cell">
                    <span>{getDateLabel(kpiSets[0])}</span>
                  </Label>
                </KpiTableHead>
                {kpiSets[0].kpis &&
                  kpiSets[0].kpis.map((kpi: KpiValue) => (
                    <FormFieldWrapper key={kpi.id}>
                      <Inputlabel>{kpi.name}</Inputlabel>
                      <InputField
                        type="number"
                        className="kpi-input"
                        name={kpi.name}
                        placeholder={literals.kpi_input_placeholder}
                        onChange={(e: any) => onKpiInputChange(e)}
                        onKeyDown={(e: any) => handleKeyDown(e)}
                        step="any"
                        required
                      />
                    </FormFieldWrapper>
                  ))}
                <Footer>
                  <SecondaryButton onClick={toggleKpiModal}>{literals.global_close}</SecondaryButton>

                  <StyledButton style={{ marginTop: '1.5em' }} type="Submit">
                    {literals.global_submit}
                  </StyledButton>
                  <KpiSteps>
                    <span>{`${kpiSets[0].key} / ${kpiSets.length}`}</span>
                  </KpiSteps>
                </Footer>
              </EditKpiForm>
            </EditKpiModal>
          </Modal.Content>
        </Modal>
      )}
    </KpiCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KpiUpdate);
