import React, { ReactChild, ReactFragment, ReactPortal } from 'react';
import { FormatedTitleImage, TitleFlexContainer } from '../styled-components/user-profile-calendar-components';
import CalendarInfotip from '../account/calendar-components/calendar-infotip';
import CheckIcon from '../../assets/sidebar-icons/check-icon.svg';

interface IProps {
  iconPath: string;
  isSync?: boolean;
  title: string;
  imageAlt?: string;
  infotipChildren?: ReactChild | ReactFragment | ReactPortal;
}
const TitleWithIcon: React.FC<IProps> = ({ infotipChildren, isSync, iconPath, title, imageAlt }) => {
  return (
    <TitleFlexContainer>
      <FormatedTitleImage src={iconPath} alt={imageAlt} />
      <h2 style={{ marginTop: '0' }}>{title}</h2>
      {infotipChildren ? <CalendarInfotip>{infotipChildren}</CalendarInfotip> : null}
      {isSync ? <img style={{ width: '1em' }} src={CheckIcon} alt="Green check icon" /> : null}
    </TitleFlexContainer>
  );
};

export default TitleWithIcon;
