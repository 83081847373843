import React from 'react';
import styled from 'styled-components';

import { useEditorLanguage } from './utils';

const DangerousInnerHTML = styled.div`
  width: 100%;
  min-height: 1em;
  font-size: 0.9em !important;

  * {
    max-width: 100%;
  }

  & .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  & .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  & .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  img {
    object-fit: contain;
    height: auto !important;
    width: 100%;
  }
`;

const InnerHtmlWrapper = ({ html, style }: { html: string; style?: any }) => {
  const { languageContentClass } = useEditorLanguage();

  return (
    <DangerousInnerHTML
      className={`ck-content ${languageContentClass}`}
      dangerouslySetInnerHTML={{ __html: html }}
      style={style}
    />
  );
};

export default InnerHtmlWrapper;
