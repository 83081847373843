import React, { useEffect, useState, useMemo } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import CtaEntrepreneurs from '../panels/cta-entrepreneurs';
import CtaMentors from '../panels/cta-mentors';
import { useParams } from 'react-router-dom';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import {
  fetchProjectsAwaitingFeedbackAsync,
  fetchProjectsNextParagraphsAsync,
  fetchCommunityProgressTrackingAsync,
} from '../../redux/actions/community';
import { find } from 'ramda';
import { Community, Project, Circle, Member } from 'redux/types/account';
import CommunityProgressPanel from '../panels/community-progress-panel';
import { DEFAULT_PROGRESS_OVERVIEW } from '../../redux/types/default-types-values';
import KpiUpdate from 'components/panels/kpi-update';
import CoachingSessionPanel from '../panels/coaching-session';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  box-sizing: border-box;
  padding: 2em 1em 0em 0em;
  overflow: auto;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    display: none;
  }
`;

const mapStateToProps = (state: RootState) => ({
  bearer: state.account.session.session.bearer,
  selectedCommunity: state.account.selectedCommunity,
  projects: state.account.projects.list,
  currentUserId: state.account.details.user.id,
});

const mapDispatchToProps = {
  fetchFeedbackProjects: fetchProjectsAwaitingFeedbackAsync.request,
  fetchProjectsNextParagraphs: fetchProjectsNextParagraphsAsync.request,
  fetchProgress: fetchCommunityProgressTrackingAsync.request,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends dispatchType, ReturnType<typeof mapStateToProps> {
  style?: React.CSSProperties;
  isCommunityLayout?: boolean;
}

const CommunityStickyFix = styled.div<{ $isCommunityLayout?: boolean }>`
  width: 100%;
  ${({ $isCommunityLayout }) =>
    $isCommunityLayout &&
    `
    position: sticky;
    padding: 0em 0em 2em 0em;
    top: 0em;
    overflow: auto;
    max-height: 100vh;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    `}
  & div:last-child {
    margin-bottom: 0em !important;
  }
`;

const Panels: React.FC<Props> = ({
  fetchProjectsNextParagraphs,
  fetchFeedbackProjects,
  bearer,
  selectedCommunity,
  projects,
  fetchProgress,
  style,
  isCommunityLayout,
  currentUserId,
}) => {
  const { communityId, projectId } = useParams<{ communityId: string; projectId: string }>();
  const [delayedRender, setDelayedRender] = useState<boolean>(false);
  const project = find((p: Project) => projectId === p.id.toString(), projects);
  const community: Community | null = selectedCommunity;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedRender(true);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [community?.id]);

  useEffect(() => {
    if (!delayedRender) return;
    if (!community) return;
    fetchFeedbackProjects({ bearer, communityId: community.id });
    fetchProjectsNextParagraphs({
      bearer,
      communityId: community.id,
      projectId: projectId ? Number(projectId) : undefined,
    });
    if (communityId && community.canSeeProjectOverview) {
      fetchProgress({ bearer, communityId: community.id });
    }
  }, [community?.id, community?.canSeeProjectOverview, bearer, delayedRender]);

  const mentorCircle = useMemo(() => (project?.circles || []).find((c: Circle) => c.name === 'Mentors'), [
    project?.circles,
  ]);
  const currentUserMember = useMemo(() => (project?.members || []).find((m: Member) => m.id === currentUserId), [
    project?.members,
  ]);
  const isProjectMentorCircle = mentorCircle?.id === currentUserMember?.circle?.id;
  const showAddCoachingSessionPanel =
    isProjectMentorCircle ||
    (community && community.permissions?.permissionCanCoachTeamMembers) ||
    community?.isCommunityAdmin;

  if (!community) return null;
  return (
    <Container key={projectId} style={{ ...style }}>
      <CommunityStickyFix $isCommunityLayout={isCommunityLayout}>
        {!delayedRender ? (
          <div style={{ height: '5em' }}>
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          </div>
        ) : (
          <React.Fragment>
            {project && project.isProjectAdmin && <KpiUpdate project={project} bearer={bearer} />}
            <CtaEntrepreneurs community={community} />
            {communityId && community.canSeeProjectOverview && community.canEditCommunityInfo && (
              <CommunityProgressPanel progress={community.progressOverview || DEFAULT_PROGRESS_OVERVIEW} />
            )}
            {showAddCoachingSessionPanel && project && <CoachingSessionPanel project={project} bearer={bearer} />}
            {communityId && <CtaMentors community={community} />}
          </React.Fragment>
        )}
      </CommunityStickyFix>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Panels);
