import * as React from 'react';
import styled from 'styled-components';

import { getCustomDNS } from './utils';
import { ICommunityData } from './types';
import { getPublicOverview } from './api';

import Navbar from './components/navbar/Navbar';

const LandingPageLayout = () => {
  const [communityData, setCommunityData] = React.useState<ICommunityData | null>(null);

  React.useEffect(() => {
    getPublicOverview(getCustomDNS())
      .then((response: ICommunityData) => {
        setCommunityData(response);
      })
      .catch(() => {});
  }, []);

  return (
    <NavbarContainer>
      <Navbar logoUrl={communityData?.logoImage} communityName={communityData?.name} tabs={communityData?.tabs} />
    </NavbarContainer>
  );
};

const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default LandingPageLayout;
