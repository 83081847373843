import React, { ReactNode } from 'react';
import { StyledFilters, StyledMenuItem } from './popup-filter-type-and-field';
import { InputBase, makeStyles } from '@material-ui/core';
import CustomSelect from './drop-downs/CustomSelect';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
interface FilterSelectProps extends ReturnType<typeof mapStateToProps> {
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  menuItems: Array<{ key: string | number; value: string | number; text: string | ReactNode; img?: string }>;
  value: any;
  styles?: React.CSSProperties; // Add styles prop
  label?: string;
  showLabel?: boolean;
  isSorting?: boolean;
  multiple?: boolean;
  isFilterSelected?: boolean;
  isAdvancedFilterSelected?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

const SelectDropdown: React.FC<FilterSelectProps> = ({
  onChange,
  menuItems,
  value,
  styles,
  label = '',
  showLabel,
  multiple = false,
  isFilterSelected = false,
  isAdvancedFilterSelected = false,
  isSorting = false,
  literals,
  ...props
}) => {
  const useStyles = makeStyles({
    paper: {
      overflowY: 'auto',
      height: 'fit-content',
      maxHeight: '250px',
      position: 'relative',
      width: 'inherit',
      maxWidth: '200px !important',
      '& li': {
        whiteSpace: 'break-spaces',
      },
    },
  });
  const classes = useStyles();
  const getRenderValue = () => {
    if (isSorting) return literals.community_sort_by_label;
    if (Array.isArray(value)) {
      return value.map(item => menuItems.find(i => i.value === item)?.text).join(', ');
    } else if (value !== undefined && (typeof value === 'string' || typeof value === 'number')) {
      return menuItems.find(item => item.value === value)?.text || label;
    } else return label;
  };
  return !isAdvancedFilterSelected && multiple ? (
    <CustomSelect menuItems={menuItems} onChange={onChange} value={value} />
  ) : (
    <StyledFilters
      value={value}
      onChange={_e => onChange(_e)}
      input={<InputBase />}
      displayEmpty
      style={{ minWidth: 'unset', width: '14em', ...styles }}
      renderValue={getRenderValue}
      isFilterSelected={isFilterSelected}
      MenuProps={{
        classes: {
          paper: classes.paper,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      multiple={multiple}
      {...props}
    >
      {showLabel && (
        <StyledMenuItem disabled value="">
          {label}
        </StyledMenuItem>
      )}
      {menuItems.map(item => (
        <StyledMenuItem
          key={item.key}
          value={item.value}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {item.img && <img src={item.img} alt={''} width={30} height={30} style={{ marginRight: '0.5em' }} />}
          <span>{item.text}</span>
        </StyledMenuItem>
      ))}
    </StyledFilters>
  );
};

export default connect(mapStateToProps)(SelectDropdown);
