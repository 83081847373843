import { Session } from 'redux/types/account';
import { createReducer, PayloadAction } from 'typesafe-actions';
import { googleSignIn, loginAsync, resetSession, saveToken } from '../actions/session';
import { buildFlagReducer } from '../store/utils';
import { persistReducer } from 'redux-persist';
import { getPersistKey } from 'util/persist';
import storage from 'redux-persist/es/storage';

export const loginFlag = buildFlagReducer(loginAsync);

export const session = createReducer({} as Session)
  .handleAction(googleSignIn.success, (state: Session, action: PayloadAction<'GOOGLE_SIGN_IN_SUCCESS', Session>) => ({
    bearer: action.payload,
  }))
  .handleAction(loginAsync.success, (state: Session, action: PayloadAction<'LOGIN_SUCCESS', Session>) => action.payload)
  .handleAction(resetSession, (state: Session) => ({
    bearer: '',
    refresh: '',
  }))
  .handleAction(saveToken, (state: Session, action: PayloadAction<'SAVE_TOKEN', { token: string }>) => ({
    bearer: action.payload.token,
    refresh: '',
  }));

const persistSessionConfig = {
  key: getPersistKey('session'),
  storage,
};

export const persistedSessionReducer = persistReducer(persistSessionConfig, session);
