import React, { useState, useEffect } from 'react';
import { SecondaryButton } from '../styled-components/common';
import styled from 'styled-components';
import { Request } from 'redux/types/account';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { fetchUserProfile } from '../../services/api/users';
import { toast } from './toast';
import { UserProfile } from 'UsersModels';
import { getTimezoneFromLocation } from '../../services/api/google';
import { Grid } from 'semantic-ui-react';
import { StyledButton } from 'primitives/Button/style';
import { scheduleRequestSessionAsync } from 'redux/actions/request';
import { Flag } from 'redux/types/enums';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  align-items: flex-start;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
  &&& {
    margin-right: 0.4em;
    input {
      width: 75px;
      background-color: ${({ theme }) => theme.colors.blue.veryLight};
      border: 0;
      @media (max-width: 870px) {
        font-size: 0.8em;
        width: 64px;
      }
      @media (max-width: 767px) {
        font-size: 1em;
        width: 75px;
      }
      @media (max-width: 501px) {
        font-size: 0.8em;
        width: 64px;
      }
    }
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  &&& {
    input {
      width: 40px;
      background-color: ${({ theme }) => theme.colors.blue.veryLight};
      border: 0;
      @media (max-width: 870px) {
        font-size: 0.8em;
        width: 31px;
      }
      @media (max-width: 767px) {
        font-size: 1em;
        width: 40px;
      }
      @media (max-width: 501px) {
        font-size: 0.8em;
        width: 31px;
      }
    }
  }
`;

const Footer = styled.div`
  margin-top: 1.5em;
  display: flex;
  flex-direction: row-reverse;
`;

const TitleInput = styled.input`
  &&& {
    width: 100% !important;
    border: 0 !important;
    font-weight: bold !important;
    font-size: 1.6em !important;
    padding-left: 1px !important;
    margin-bottom: 1em;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }

  &:focus {
    // border: 0;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  text-transform: lowercase !important;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const StyledIntervalButton = styled(StyledSecondaryButton)<{ active: boolean }>`
  &&& {
    &:hover {
      border-color: ${props => props.theme.colors.blue.normal};
      background-color: ${props => props.theme.colors.blue.normal};
      color: ${props => props.theme.colors.white};
    }

    ${props =>
      props.active &&
      `border-color: ${props.theme.colors.blue.normal};
       color: ${props.theme.colors.blue.normal};`}

    border-radius: 7px;
    padding: 1.5em 1em;
    font-size: 0.8em;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const DateTimePickerRow = styled(Row)`
  // justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledIntervalButtonRow = styled(Row)`
  margin: 0.5em 0 1em 0;
`;

const UserLocalTime = styled.div`
  background-color: ${props => props.theme.colors.yellow.light};
  color: ${props => props.theme.colors.yellow.dark};
  padding: 1em;
  text-align: center;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  isSchedulingMeeting: state.loading.scheduleRequestSessionFlag,
});

const mapDispatchToProps = {
  scheduleRequestSession: scheduleRequestSessionAsync.request,
};

type IRequestScheduleMeetingProps = {
  closeModal: (options?: { refetchRequest?: boolean }) => void;
  bearer: string;
  request: Request;
  currentUserId: number;
} & typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const RequestScheduleMeeting: React.FC<IRequestScheduleMeetingProps> = ({
  closeModal,
  request,
  literals,
  bearer,
  currentUserId,
  scheduleRequestSession,
  isSchedulingMeeting,
}) => {
  const title = `Meet with ${request.userFirstName} ${request.userLastName}`;
  const topic = request.name;
  const meetingTitlePlaceholder = `${title} / ${topic}`;
  const description = request.description;

  const [startDatetime, setStartDateTime] = useState(moment());
  const [endDatetime, setEndDateTime] = useState(moment().add(1, 'hours'));
  const [schedulingMeeting, setSchedulingMeeting] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState<string>('');
  const [eventMinutesDuration, setEventMinutesDuration] = useState<number>(30);
  const [userDetails, setUserDetails] = useState<{ name: string; address: string; timezone: string }>({
    name: '',
    address: '',
    timezone: '',
  });

  useEffect(() => {
    if (request.userId) {
      fetchUserProfile(bearer, request.userId).then((user: UserProfile) => {
        const address = `${user.countryName}, ${user.city}`;
        getTimezoneFromLocation(bearer, `${user.countryName}, ${user.city}`).then((timezone: string) => {
          setUserDetails({
            name: user.name,
            address,
            timezone,
          });
        });
      });
    }
  }, [request.userId]);

  useEffect(() => {
    if (isSchedulingMeeting === Flag.Success) {
      toast(literals.request_meeting_schedule_success, { type: 'success' });
      setSchedulingMeeting(false);
      closeModal({ refetchRequest: true });
    }
    if (isSchedulingMeeting === Flag.Failure) {
      setSchedulingMeeting(false);
      toast(literals.request_meeting_schedule_fail, { type: 'warning' });
    }
  }, [isSchedulingMeeting]);

  const handleIntervalChange = (interval: number) => {
    setEndDateTime(moment(startDatetime).add(interval, 'minutes'));
    setEventMinutesDuration(interval);
  };

  return (
    <Container>
      <TitleInput
        placeholder={`${meetingTitlePlaceholder}✏️`}
        value={meetingTitle}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setMeetingTitle(evt.target.value)}
      />
      <Content>
        <Grid style={{ width: '100%' }}>
          <Grid.Row style={{ width: '100% !important' }}>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <DateTimePickerRow>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div>
                    <StyledDatePicker
                      autoOk={true}
                      margin="normal"
                      id="startDate"
                      label={literals.event_edit_start_date_label && ''}
                      format="DD/MM/YYYY"
                      value={startDatetime}
                      variant={'inline'}
                      onChange={(date: MaterialUiPickersDate): void => {
                        setStartDateTime(date as Moment);
                        setEndDateTime(moment(date).add(eventMinutesDuration, 'minutes'));
                      }}
                    />
                    <StyledTimePicker
                      autoOk={true}
                      margin="normal"
                      id="startTime"
                      format="HH:mm"
                      label={literals.event_edit_start_time_label && ''}
                      value={startDatetime}
                      variant={'inline'}
                      onChange={(date: MaterialUiPickersDate): void => {
                        setStartDateTime(date as Moment);
                        setEndDateTime(moment(date).add(eventMinutesDuration, 'minutes'));
                      }}
                    />
                  </div>
                  <div style={{ alignSelf: 'center', margin: '0em 0.4em' }}>to</div>
                  <div>
                    <StyledDatePicker
                      autoOk={true}
                      margin="normal"
                      id="endDate"
                      label={literals.event_edit_end_date_label && ''}
                      format="DD/MM/YYYY"
                      value={endDatetime}
                      variant={'inline'}
                      onChange={(date: MaterialUiPickersDate): void => {
                        if (moment(date).isBefore(startDatetime)) {
                          toast(literals.event_edit_end_date_in_the_past_error, { type: 'warning' });
                          return;
                        }
                        setEndDateTime(date as Moment);
                      }}
                      minDate={startDatetime}
                    />
                    <StyledTimePicker
                      autoOk={true}
                      margin="normal"
                      id="endTime"
                      format="HH:mm"
                      label={literals.event_edit_end_time_label && ''}
                      value={endDatetime}
                      variant={'inline'}
                      onChange={(date: MaterialUiPickersDate): void => {
                        if (moment(date).isBefore(startDatetime)) {
                          toast(literals.event_edit_end_date_in_the_past_error, { type: 'warning' });
                          return;
                        }
                        setEndDateTime(date as Moment);
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </DateTimePickerRow>
              <StyledIntervalButtonRow>
                <StyledIntervalButton
                  active={eventMinutesDuration === 30}
                  type="button"
                  onClick={() => handleIntervalChange(30)}
                >
                  {(literals.event_duration_X_minutes || '').replace('{0}', '30')}
                </StyledIntervalButton>
                <StyledIntervalButton
                  active={eventMinutesDuration === 45}
                  type="button"
                  onClick={() => handleIntervalChange(45)}
                >
                  {(literals.event_duration_X_minutes || '').replace('{0}', '45')}
                </StyledIntervalButton>
                <StyledIntervalButton
                  active={eventMinutesDuration === 60}
                  type="button"
                  onClick={() => handleIntervalChange(60)}
                >
                  {(literals.event_duration_X_minutes || '').replace('{0}', '60')}
                </StyledIntervalButton>
              </StyledIntervalButtonRow>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              {userDetails.timezone.length > 0 && (
                <UserLocalTime
                  dangerouslySetInnerHTML={{
                    __html: (literals.schedule_meeting_user_local_time_single || '')
                      .replace(`{0}`, userDetails.name)
                      .replace(`{1}`, userDetails.address)
                      .replace(
                        `{2}`,
                        `<strong>${moment(startDatetime).tz(userDetails.timezone).format('HH:mm')}</strong>`,
                      ),
                  }}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Content>
      <Footer style={{ alignSelf: 'flex-end' }}>
        <StyledButton
          disabled={schedulingMeeting}
          loading={schedulingMeeting}
          onClick={() => {
            setSchedulingMeeting(true);
            scheduleRequestSession({
              bearer,
              requestId: request.id,
              projectId: request.projectId,
              advisorId: currentUserId,
              summary: meetingTitle.length === 0 ? meetingTitlePlaceholder : meetingTitle,
              description,
              start: startDatetime,
              end: endDatetime,
            });
          }}
        >
          {schedulingMeeting ? literals.request_meeting_scheduling_button : literals.request_meeting_schedule_button}
        </StyledButton>
        <SecondaryButton onClick={closeModal}>{literals.confirm_cancel_button}</SecondaryButton>
      </Footer>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestScheduleMeeting);
