import accountReducer, {
  literalsReducer,
  countriesReducer,
  sdgsReducer,
  projectCategoriesReducer,
} from '../reducers/account';
import projectSearchOverview from '../reducers/project-overview';
import usersReducer from '../reducers/users';
import loadingReducer from '../reducers/loading';
import tipsReducer from '../reducers/tips';
import { combineReducers } from 'redux';
import projectsRequestFeedbackReducer from '../reducers/project-request-feedback';
import { userEventsByMonthReducer, meetingSettingsReducer, calendarLogInThirdPartyReducer } from '../reducers/calendar';
import userCalendar from '../reducers/user-calendar';
import { selectedFiles } from 'redux/reducers/file-upload';

/*
 * Reducers are basically a function (state, action) => newState.
 * They compute the nextState based on the currentState and the action.
 * If they return undefined they initialize the state with the default values.
 * ( i.e. createReducer(false as boolean), here false is the default state )
 * This works recursively (for nested combinedReducers).
 * Creating this wrapper at root level allows us to reset the entire store.
 * It has a similar behaviour with a .handleAction( RESET_SESSION, state => defaultValue )
 * attached to each individual createReducer.
 * This is not mutating the state, since it only resets the parameter and not the
 * content of state.
 *
 * more details:
 * https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store?page=1&tab=votes
 */

const rootReducer = combineReducers({
  account: accountReducer,
  users: usersReducer,
  loading: loadingReducer,
  literals: literalsReducer,
  tips: tipsReducer,
  countries: countriesReducer,
  sdgs: sdgsReducer,
  projectCategories: projectCategoriesReducer,
  projectSearchOverview: projectSearchOverview,
  projectRequestFeedbacks: projectsRequestFeedbackReducer,
  meetingSettings: meetingSettingsReducer,
  userEventsByMonth: userEventsByMonthReducer,
  calendarLogInData: calendarLogInThirdPartyReducer,
  userCalendar: userCalendar,
  selectedFiles: selectedFiles,
});

export default rootReducer;
