import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { acceptProjectInvitation, declineProjectInvitation, fetchProjectById } from '../../services/api/project';
import {
  acceptCommunityInvitation,
  declineCommunityInvitation,
  fetchCommunityDetails,
} from '../../services/api/community';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Project, Community, TabEntity } from 'redux/types/account';
import styled from 'styled-components';

import { NoBackgroundButton, SecondaryButton } from '../styled-components/common';
import { fetchCommunitiesAsync } from '../../redux/actions/community';
import { acceptTabEntityInvitation, declineTabEntityInvitation, fetchTabEntityById } from 'services/api/tab';
import { StyledButton } from 'primitives/Button/style';

export const Center = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & span {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
  }
  & button {
    font-size: 1em !important;
  }
  ${({ fullScreen }) => fullScreen && `height: 100vh`};
`;

export const BlueNoBackgroundButton = styled(NoBackgroundButton)`
  &&& {
    color: ${({ theme }) => theme.colors.blue.normal};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1em;
`;

const mapStateToProps = (state: RootState) => ({
  userId: state.account.details.user.id,
  userDetails: state.account.details.user,
  literals: state.literals,
});

const mapDispatchToProps = {
  fetchCommunities: fetchCommunitiesAsync.request,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  bearer: string;
  entityType: string;
  responseType: string;
  fullScreen?: boolean;
}

const InvitationResponse: React.FC<Props> = ({
  fullScreen,
  bearer,
  userId,
  entityType,
  responseType,
  literals,
  fetchCommunities,
  userDetails,
}) => {
  const { entityId } = useParams<{ entityId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [entityName, setEntityName] = useState<string>('');
  const [entityUrl, setEntityUrl] = useState<string>('');
  const [isFetchingName, setIsFetchingName] = useState<boolean>(true);
  const [entityIsFound, setEntityIsFound] = useState<boolean>(false);
  const history = useHistory();

  const invitationMap: any = {
    community: {
      accept: literals.community_invitation_accept_success_message,
      decline: literals.community_invitation_decline_success_message,
    },
    project: {
      accept: literals.project_invitation_accept_success_message,
      decline: literals.project_invitation_decline_success_message,
    },
    tabEntity: {
      accept: literals.project_invitation_accept_success_message,
      decline: literals.project_invitation_decline_success_message,
    },
  };

  const goToButtonMap: any = {
    community: literals.community_see_community,
    project: literals.project_see_project,
    tabEntity: literals.tab_entity_see_entity,
  };

  useEffect(() => {
    if (!userId) return;
    const entityIdNumber = parseInt(entityId || '-1');
    if (entityType === 'project') {
      fetchProjectById(bearer, entityIdNumber)
        .then((response: Project) => {
          setEntityName(response.name || '');
          setIsFetchingName(false);
        })
        .catch(errorFetch);

      if (responseType === 'accept') {
        acceptProjectInvitation(bearer, entityIdNumber, userId)
          .then(successResponse)
          .then(() => {
            fetchCommunities(bearer);
          })
          .catch(errorResponse);
      } else {
        declineProjectInvitation(bearer, entityIdNumber, userId).then(successResponse).catch(errorResponse);
      }
    } else if (entityType === 'tabEntity') {
      fetchTabEntityById(bearer, entityIdNumber)
        .then((response: TabEntity) => {
          const { id, tabId, form } = response;
          setEntityUrl(`/community/${form.communityId}/tab/${tabId}/entity/${id}`);
          setEntityName(response.title);
          setIsFetchingName(false);
          setIsSuccess(true);
        })
        .catch(errorFetch);
      if (responseType === 'accept') {
        acceptTabEntityInvitation(bearer, entityIdNumber, userId).then(successResponse).catch(errorResponse);
      } else {
        declineTabEntityInvitation(bearer, entityIdNumber, userId).then(successResponse).catch(errorResponse);
      }
    } else {
      fetchCommunityDetails(entityIdNumber, bearer)
        .then((response: Community) => {
          setEntityName(response.name || '');
          setIsFetchingName(false);
        })
        .catch(errorFetch);
      if (responseType === 'accept') {
        acceptCommunityInvitation(bearer, entityIdNumber, userId)
          .then(successResponse)
          .then(() => {
            fetchCommunities(bearer);
          })
          .catch(errorResponse);
      } else {
        declineCommunityInvitation(bearer, entityIdNumber, userId).then(successResponse).catch(errorResponse);
      }
    }
  }, [entityId, bearer, userId]);

  const successResponse = () => {
    setIsLoading(false);
  };
  const errorResponse = () => {
    // We want to display a good message even when the users try to respond to the same invitation more than once
    // #1169  https://github.com/createtogether/babele-web-client/issues/1169
    const numberEntityId = parseInt(entityId);
    if (entityType === 'project') {
      if (
        userDetails.ownedProjects.some((project: any) => project.id === numberEntityId) ||
        userDetails.followedProjects.some((project: any) => project.id === numberEntityId)
      ) {
        setEntityIsFound(true);
      } else {
        setEntityIsFound(false);
      }
    } else {
      if (userDetails.communities.some((community: any) => community.id === numberEntityId)) {
        setEntityIsFound(true);
      } else {
        setEntityIsFound(false);
      }
    }

    setIsSuccess(false);
    setIsLoading(false);
  };

  const errorFetch = () => {
    setIsFetchingName(false);
  };

  const gotoEntity = () => {
    if (entityUrl) history.push(entityUrl);
    else history.push(`/${entityType}/${entityId}`);
    setEntityUrl('');
  };

  return (
    <>
      {isLoading || isFetchingName ? (
        <div>
          <Dimmer active inverted>
            <Loader inverted>{literals.project_list_loading_tag_message}</Loader>
          </Dimmer>
        </div>
      ) : (
        <div>
          {isSuccess ? (
            <Center fullScreen={fullScreen}>
              <span>{invitationMap[entityType][responseType].replace('{0}', entityName)}</span>
              <Row>
                <SecondaryButton
                  onClick={() => {
                    if (history.length === 2) history.push('/');
                    else history.goBack();
                  }}
                >
                  {literals.global_go_back}
                </SecondaryButton>
                {responseType === 'accept' && (
                  <StyledButton
                    onClick={() => {
                      gotoEntity();
                    }}
                  >
                    {goToButtonMap[entityType]}
                  </StyledButton>
                )}
              </Row>
            </Center>
          ) : (
            <Center fullScreen={fullScreen}>
              <span>
                {entityIsFound
                  ? (literals.invitation_already_accepted_message || '').replace('{0}', entityName)
                  : (literals.invitation_already_declined_message || '').replace('{0}', entityName)}
              </span>
              <Row>
                <SecondaryButton
                  onClick={() => {
                    if (history.length === 2) history.push('/');
                    else history.goBack();
                  }}
                >
                  {literals.global_go_back}
                </SecondaryButton>
                {entityIsFound && (
                  <StyledButton
                    onClick={() => {
                      gotoEntity();
                    }}
                  >
                    {goToButtonMap[entityType]}
                  </StyledButton>
                )}
              </Row>
              {/* Pre invitation hotfix #1169
              <span>
                {entityType === 'project'
                  ? responseType === 'accept'
                    ? literals.project_invitation_accept_failure_message.replace('{0}', entityName)
                    : literals.project_invitation_decline_failure_message.replace('{0}', entityName)
                  : responseType === 'accept'
                  ? literals.community_invitation_accept_failure_message.replace('{0}', entityName)
                  : literals.community_invitation_decline_failure_message.replace('{0}', entityName)}
              </span>
              <BlueNoBackgroundButton
                onClick={() => {
                  if (history.length === 2) history.push('/');
                  else history.goBack();
                }}
              >
                {literals.global_go_back}
              </BlueNoBackgroundButton>
                  */}
            </Center>
          )}
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationResponse);
