import React from 'react';
import { objectUserEventParser } from '../../../util/calendar';
import { CalendarViewType, IUserCalendarEvent } from '../../common/calendar-scheduler/types';
import { Calendar, DateLocalizer, EventPropGetter, View, NavigateAction } from 'react-big-calendar';
import { UserEvent } from '../../../redux/types/calendar';

const CalendarViewsVariant: Record<CalendarViewType, View> = {
  [CalendarViewType.DAY]: 'day',
  [CalendarViewType.WEEK]: 'week',
  [CalendarViewType.MONTH]: 'month',
  [CalendarViewType.WORK_WEEK]: 'work_week',
  [CalendarViewType.AGENDA]: 'agenda',
};
interface IProps {
  localizer: DateLocalizer;
  userEvents: UserEvent[];
  maxTime: Date;
  minTime: Date;
  views: CalendarViewType[];
  defaultView: CalendarViewType;
  onNavigate?: (newDate: Date, view: View, action: NavigateAction) => void;
  onView?: (view: View) => void;
  style?: {};
}

const UserEventsCalendar: React.FC<IProps> = ({
  localizer,
  userEvents,
  minTime,
  maxTime,
  views,
  defaultView,
  style,
  onNavigate,
  onView,
}) => {
  const eventStyleGetter: EventPropGetter<IUserCalendarEvent> = (event, start, end) => {
    const color = '#d2e3c8';

    const style = {
      backgroundColor: color,
      borderColor: `1px solid ${color}`,
      fontSize: '0.7em',
      color: '#302E37',
    };

    return { style };
  };

  return (
    <Calendar
      className="user-events-calendar"
      style={style}
      events={objectUserEventParser(userEvents)}
      localizer={localizer}
      defaultView={CalendarViewsVariant[defaultView]}
      startAccessor="start"
      endAccessor="end"
      eventPropGetter={eventStyleGetter}
      views={views as View[]}
      min={minTime}
      max={maxTime}
      onNavigate={onNavigate}
      onView={onView}
    ></Calendar>
  );
};

export default UserEventsCalendar;
