import {
  FeedItem,
  CommunityPost,
  Comment,
  ProjectPost,
  GetTabEntityComment,
  TabEntityComment,
} from 'redux/types/account';
import { map } from 'ramda';
import { AxiosResponse, Method } from 'axios';

import { CommentType } from '../../redux/types/enums';
import { sanitizeAssetUrl } from '../../util/assets';
import { buildHeaders, extractComments, getAxiosInstance } from './helper';
import { mentionParse } from '../../util/mention';

const mapComments = (data: any[]): TabEntityComment[] => {
  return data?.reduce((a, v) => {
    const y = {
      ...v,
      date: v.lastUpdated,
      author: { id: v.authorId, name: v.authorName, occupation: v.authorOccupation, photo: v.authorPhoto },
    };
    if (v.comments?.length) {
      const comments = mapComments(v.comments);
      return [...a, { ...y, comments: comments?.length ? comments : [] }] as any;
    }
    return [...a, y] as TabEntityComment[];
  }, []);
};

const ai = getAxiosInstance();

export function fetchCommunityFeed(
  bearer: string,
  communityId: number,
  take: number,
  skip: number,
): Promise<{
  id: number;
  feed: FeedItem[];
  skip: number;
  take: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/LiveFeed/GetCommunity',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        take,
        skip,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const resp = {
          id: communityId,
          skip,
          take,
          feed: data.map((post: any, index: number, feedArr: any[]) => {
            const comments: Comment[] = extractComments(post.item.comments, true);
            switch (post.itemType) {
              case CommentType.CommunityPost:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  likeCount: post.item.likeCount,
                  title: post.item.name,
                  isLiked: post.item.userLikesComment,
                  isPinned: index < feedArr.length - take,
                  content: mentionParse(post.item.content),
                  comments,
                  author: {
                    id: post.item.authorId,
                    name: post.item.authorName,
                    photo: sanitizeAssetUrl(post.item.authorPhoto),
                    occupation: post.item.authorOccupation || '',
                  },
                };
              case CommentType.Discussion:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  title: post.item.name,
                  content: mentionParse(post.item.content),
                  image: sanitizeAssetUrl(post.item.image),
                  comments,
                  author: {
                    id: post.item.userId,
                    name: post.item.authorName,
                    photo: sanitizeAssetUrl(post.item.authorPhoto),
                    occupation: post.item.authorOccupation || '',
                  },
                  project: {
                    id: post.project.id,
                    name: post.project.name,
                    logo: sanitizeAssetUrl(post.project.logoImage),
                  },
                };
              case CommentType.ProjectPost:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  likeCount: post.item.likeCount,
                  isLiked: post.item.userLikesComment,
                  content: mentionParse(post.item.content),
                  comments,
                  author: {
                    id: post.item.authorId,
                    name: post.item.authorName,
                    photo: sanitizeAssetUrl(post.item.authorPhoto),
                    occupation: post.item.authorOccupation || '',
                  },
                  project: {
                    id: post.project.id,
                    name: post.project.name,
                    logo: sanitizeAssetUrl(post.project.logoImage),
                  },
                };
              case CommentType.ProgressUpdate:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  likeCount: post.item.likeCount || 0,
                  isLiked: post.item.isLiked,
                  content: mentionParse(post.item.content),
                  title: post.item.name,
                  section: post.item.section.name,
                  comments,
                  project: {
                    id: post.project.id,
                    name: post.project.name,
                    logo: sanitizeAssetUrl(post.project.logoImage),
                  },
                };
              default:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  title: post.item.name,
                  commentCount: post.item.commentCount,
                  likeCount: post.item.likeCount,
                  isLiked: post.item.userLikesComment,
                  content: mentionParse(post.item.content),
                  image: sanitizeAssetUrl(post.item.image),
                  comments,
                  author: {
                    id: post.item.authorId || post.item.userId,
                    name: post.item.authorName,
                    photo: sanitizeAssetUrl(post.item.authorPhoto),
                    occupation: post.item.authorOccupation || '',
                  },
                };
            }
          }),
        };
        resolve(resp);
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectFeed(
  bearer: string,
  project: { id: number; name: string; logo: string },
  take: number,
  skip: number,
): Promise<{
  id: number;
  feed: FeedItem[];
  skip: number;
  take: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/LiveFeed/GetProject',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId: project.id,
        take,
        skip,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const resp = {
          id: project.id,
          skip,
          take,
          feed: map(post => {
            const comments: Comment[] = extractComments(post.item.comments, true);
            switch (post.itemType) {
              case CommentType.Discussion:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  title: post.item.name,
                  content: mentionParse(post.item.content),
                  image: sanitizeAssetUrl(post.item.image),
                  comments,
                  author: {
                    id: post.item.userId,
                    name: post.item.authorName,
                    photo: sanitizeAssetUrl(post.item.authorPhoto),
                    occupation: post.item.authorOccupation || '',
                  },
                  project,
                };
              case CommentType.ProjectPost:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  likeCount: post.item.likeCount,
                  isLiked: post.item.userLikesComment,
                  content: mentionParse(post.item.content),
                  comments,
                  author: {
                    id: post.item.authorId,
                    name: post.item.authorName,
                    photo: sanitizeAssetUrl(post.item.authorPhoto),
                    occupation: post.item.authorOccupation || '',
                  },
                  project,
                };
              case CommentType.ProgressUpdate:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  likeCount: post.item.likeCount || 0,
                  isLiked: post.item.isLiked,
                  content: mentionParse(post.item.content),
                  title: post.item.name,
                  section: post.item.section.name,
                  comments,
                  project,
                };
              default:
                return {
                  id: post.item.id,
                  type: post.itemType,
                  date: post.item.lastUpdated,
                  commentCount: post.item.commentCount,
                  content: mentionParse(post.item.content),
                  title: post.item.name,
                  image: sanitizeAssetUrl(post.item.image),
                  comments,
                  author: {
                    id: post.item.userId,
                    name: post.item.authorName,
                    photo: sanitizeAssetUrl(post.item.authorPhoto),
                    occupation: post.item.authorOccupation || '',
                  },
                  project,
                };
            }
          }, data),
        };
        resolve(resp);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommentsTabEntityById(
  bearer: string,
  communityId: number,
  tabEntityId: number,
): Promise<GetTabEntityComment> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/TabEntity/${tabEntityId}/Comments`,
      headers: buildHeaders(bearer),
      data: {},
    })
      .then((response: AxiosResponse) => resolve({ list: mapComments(response.data?.list), communityId, tabEntityId }))
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
}

export function likeFeedPost(
  id: number,
  bearer: string,
  entityId: number,
  type: CommentType,
): Promise<{
  id: number;
  entityId: number;
  type: CommentType;
}> {
  return new Promise((resolve, reject) => {
    let url: string = '';
    let method: Method = 'GET';
    switch (type) {
      case CommentType.CommunityPost:
        url = '/api/Comment/LikeCommunityComment';
        break;
      case CommentType.ProgressUpdate:
        url = '/api/BusinessModelContent/LikeParagraph';
        break;
      case CommentType.ProjectPost:
        url = '/api/Comment/LikeProjectComment';
        break;
      case CommentType.BusinessModelParagraph:
        url = '/api/Comment/LikeParagraphComment';
        break;
      case CommentType.Discussion:
        url = '/api/Comment/LikeDiscussionComment';
        break;
      case CommentType.CommunityDiscussion:
        url = '/api/Comment/LikeCommunityDiscussionComment';
        break;
      case CommentType.Request:
        url = '/api/Comment/LikeRequestComment';
        break;
      case CommentType.TabEntity:
        url = '/api/Comment/LikeTabEntityComment';
        method = 'POST';
        break;
      default:
        url = '/api/Comment/LikeCommunityComment';
        break;
    }
    ai({
      method,
      url,
      headers: buildHeaders(bearer),
      params: {
        id: id,
      },
    })
      .then(_r => {
        resolve({
          id: id,
          entityId,
          type,
        });
      })
      .catch(err => reject(err));
  });
}

export function unlikeFeedPost(
  id: number,
  bearer: string,
  entityId: number,
  type: CommentType,
): Promise<{
  id: number;
  entityId: number;
  type: CommentType;
}> {
  return new Promise((resolve, reject) => {
    let url: string = '';
    let method: Method = 'GET';
    switch (type) {
      case CommentType.CommunityPost:
        url = '/api/Comment/UnlikeCommunityComment';
        break;
      case CommentType.ProgressUpdate:
        url = '/api/BusinessModelContent/UnlikeParagraph';
        break;
      case CommentType.ProjectPost:
        url = '/api/Comment/UnlikeProjectComment';
        break;
      case CommentType.BusinessModelParagraph:
        url = '/api/Comment/UnlikeParagraphComment';
        break;
      case CommentType.Discussion:
        url = '/api/Comment/UnlikeDiscussionComment';
        break;
      case CommentType.CommunityDiscussion:
        url = '/api/Comment/UnlikeCommunityDiscussionComment';
        break;
      case CommentType.Request:
        url = '/api/Comment/UnlikeRequestComment';
        break;
      case CommentType.TabEntity:
        url = '/api/Comment/UnlikeTabEntityComment';
        method = 'POST';
        break;
      default:
        url = '/api/Comment/UnlikeCommunityComment';
        break;
    }
    ai({
      method,
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: id,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          id: id,
          entityId,
          type,
        });
      })
      .catch(err => reject(err));
  });
}

export function addCommunityPost(
  bearer: string,
  userId: number,
  communityId: number,
  content: string,
): Promise<{ communityId: number; post: CommunityPost }> {
  return new Promise((resolve, reject) => {
    const postData: { userId: number; communityId: number; content: string; parentId?: number } = {
      userId,
      communityId,
      content,
    };
    ai({
      method: 'POST',
      url: '/api/Comment/SaveCommunityComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: postData,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId,
          post: {
            id: data.id,
            type: CommentType.CommunityPost,
            date: data.lastUpdated,
            commentCount: data.commentCount,
            likeCount: data.likeCount,
            isLiked: data.userLikesComment,
            isPinned: false,
            content: mentionParse(data.content),
            comments: [],
            author: {
              id: data.authorId,
              name: data.authorName,
              photo: sanitizeAssetUrl(data.authorPhoto),
              occupation: data.authorOccupation || '',
            },
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function addProjectPost(
  bearer: string,
  userId: number,
  project: { id: number; name: string; logo: string },
  content: string,
): Promise<{ post: ProjectPost }> {
  return new Promise((resolve, reject) => {
    const postData: { userId: number; projectId: number; content: string; parentId?: number } = {
      userId,
      projectId: project.id,
      content,
    };
    ai({
      method: 'POST',
      url: '/api/Comment/SaveProjectComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: postData,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          post: {
            id: data.id,
            type: CommentType.ProjectPost,
            date: data.lastUpdated,
            commentCount: data.commentCount,
            likeCount: data.likeCount,
            isLiked: data.userLikesComment,
            content: mentionParse(data.content),
            comments: [],
            author: {
              id: data.authorId,
              name: data.authorName,
              photo: sanitizeAssetUrl(data.authorPhoto),
              occupation: data.authorOccupation || '',
            },
            project,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function addCommentToFeed(
  bearer: string,
  userId: number,
  itemId: number,
  content: string,
  parentId: number,
  itemType: CommentType,
  projectId: number,
  entityId: number,
  id: number,
): Promise<{ entityId: number; projectId: number; comment: Comment; itemType: CommentType; itemId: number }> {
  return new Promise((resolve, reject) => {
    let url = '';
    const postData: any = {
      userId,
      content,
      parentId,
      id,
    };
    switch (itemType) {
      case CommentType.CommunityPost:
        url = '/api/Comment/SaveCommunityComment';
        postData.communityId = itemId;
        break;
      case CommentType.ProjectPost:
        url = '/api/Comment/SaveProjectComment';
        postData.projectId = itemId;
        break;
      case CommentType.Discussion:
        url = '/api/Comment/SaveDiscussionComment';
        postData.discussionId = itemId;
        if (parentId === itemId) delete postData.parentId;
        break;
      case CommentType.CommunityDiscussion:
        url = '/api/Comment/SaveCommunityDiscussionComment';
        postData.communityDiscussionId = itemId;
        if (parentId === itemId) delete postData.parentId;
        break;
      case CommentType.BusinessModelParagraph:
        url = '/api/Comment/SaveParagraphComment';
        postData.paragraphId = itemId;
        break;
      case CommentType.TabEntity:
        url = '/api/Comment/SaveTabEntityComment';
        postData.tabEntityId = itemId;
        break;
    }

    ai({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: postData,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          projectId,
          entityId,
          comment: {
            id: data.id,
            author: {
              id: data.authorId,
              name: data.authorName,
              photo: sanitizeAssetUrl(data.authorPhoto),
              occupation: data.authorOccupation || '',
            },
            date: data.lastUpdated,
            content: mentionParse(data.content),
            parentId: data.parentId,
            likeCount: data.likeCount,
            isLiked: data.userLikesComment,
            comments: [],
            skipExpandablePost: true,
          },
          itemType: itemType,
          itemId,
        });
      })
      .catch(err => reject(err));
  });
}
export function deleteComment(
  bearer: string,
  postId: number,
  postType: CommentType,
  entityId: number,
  entityType: string,
  itemId: number,
): Promise<{ postId: number; postType: CommentType; entityId: number; entityType: string; itemId: number }> {
  return new Promise((resolve, reject) => {
    let url = '';
    let method: Method = 'GET';
    switch (postType) {
      case CommentType.CommunityPost:
        url = '/api/Comment/RemoveCommunityComment';
        break;
      case CommentType.ProjectPost:
        url = '/api/Comment/RemoveProjectComment';
        break;
      case CommentType.Discussion:
        url = '/api/Comment/RemoveDiscussionComment';
        break;
      case CommentType.CommunityDiscussion:
        url = '/api/Comment/RemoveCommunityDiscussionComment';
        break;
      case CommentType.BusinessModelParagraph:
        url = '/api/Comment/RemoveParagraphComment';
        break;
      case CommentType.TabEntity:
        url = '/api/Comment/RemoveTabEntityComment';
        method = 'DELETE';
        break;
    }
    ai({
      method,
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: postId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          postId,
          postType,
          entityId,
          entityType,
          itemId,
        });
      })
      .catch(err => reject(err));
  });
}

export function pinCommunityFeedPost(
  bearer: string,
  postId: number,
  communityId: number,
): Promise<{ postId: number; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Comment/PinCommunityComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: postId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({ postId, communityId });
      })
      .catch(err => reject(err));
  });
}

export function unpinCommunityFeedPost(
  bearer: string,
  postId: number,
  communityId: number,
): Promise<{ postId: number; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Comment/UnpinCommunityComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: postId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({ postId, communityId });
      })
      .catch(err => reject(err));
  });
}

export default {
  fetchCommunityFeed,
  fetchProjectFeed,
  likeFeedPost,
  unlikeFeedPost,
  addCommunityPost,
  addProjectPost,
  addCommentToFeed,
  deleteComment,
  pinCommunityFeedPost,
  unpinCommunityFeedPost,
  fetchCommentsTabEntityById,
};
