import styled from 'styled-components';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import React from 'react';

export const Dropdown = styled(SemanticDropdown)`
  &&& {
    border: 0;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.grey.dark};
    background: ${props => props.theme.colors.blue.veryLight};

    width: 100%;
    padding: 0.7em;

    div.text {
      margin: 0 !important;
    }

    a.ui.label {
      background: rgba(59, 115, 255, 0.2);
      border: none;
      padding: 1px 7px;
      font-style: normal;
      font-weight: 500;
      font-size: 1em;
      line-height: 24px;
      color: #36455b;
      box-shadow: none;
      display: inline;
    }

    a.ui.label > .delete.icon {
      margin-left: 4px !important;
    }

    div,
    span {
      font-size: 1em;
      color: ${({ theme }) => theme.colors.black} !important;
    }

    /*
        Change placeholder text color
    div.default.text {
      color: ${({ theme }) => theme.colors.grey.dark} !important;
    }
    */
    i {
      position: relative !important;
      padding: 0 !important;
      top: inherit !important;
      right: inherit !important;
      margin: 0 0.2em !important;
    }
    &.ui .menu {
      height: 16em;
      overflow: auto;
      border-color: ${({ theme }) => theme.colors.grey.light} !important;
      width: 100%;
    }
  }
`;

export const RequiredDropdown: React.VFC<DropdownProps> = props => <Dropdown search {...props} />;
