import logger from './logger-service';
import account from './api/account';
import community from './api/community';
import discussion from './api/discussion';
import feed from './api/feed';
import fileUpload from './api/file-upload';
import methodology from './api/methodology';
import project from './api/project';
import session from './api/session';
import users from './api/users';
import businessModel from './api/business-model';
import applicationManager from './api/application-manager';
import form from './api/form';
import literals from './literals';
import tab from './api/tab';
import projectForm from './api/project-form';
import projectSearchOverview from './api/project-overview';
import userCalendar from './api/user-calendar';
import calendar from './api/calendar';

export default {
  logger,
  api: {
    account,
    community,
    discussion,
    feed,
    fileUpload,
    methodology,
    project,
    session,
    users,
    businessModel,
    literals,
    applicationManager,
    form,
    tab,
    projectForm,
    projectSearchOverview,
    userCalendar,
    calendar,
  },
};
