import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { SecondaryButton, SgdContainer, StyledFlex } from '../styled-components/common';
import { Skill, Interest, Country, SDG } from 'redux/types/account';
import { formatUserContent } from '../../util/ckeditor';
import BigSdgPopup from '../common/big-sdg-popup';
import { theme } from 'components/styled-components/babele-theme';
import InnerHtmlWrapper from 'components/editor/InnerHtmlWrapper';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const RowWrap = styled(Row)`
  flex-wrap: wrap;
`;

const Note = styled.p`
  font-size: 1em;
  line-height: 1.1em;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin: 0;
`;

const SdgImg = styled.img`
  height: 7em;
  width: 7em;
  border-radius: 3px;
`;

const StyledHeadingThree = styled.h3`
  line-height: 1.5em;
  margin: 0;
`;

const StyledProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  profile: {
    biography: string;
    interests: Interest[];
    skills: Skill[];
    marketExpertises: Country[];
    sustainableDevelopmentGoals: SDG[];
    company: string;
  };
}

const Profile: React.FC<Props> = ({ profile, literals }) => {
  const profileBiography = useMemo(() => formatUserContent(profile.biography), [profile.biography]);

  return (
    <Container>
      <StyledHeadingThree>{literals.about_me_about_me_title}</StyledHeadingThree>
      <StyledProfileWrapper>
        <InnerHtmlWrapper html={profileBiography} style={{ lineHeight: '1.5em', textAlign: 'left' }} />

        {profile?.company && (
          <StyledFlex style={{ display: 'flex', alignItems: 'center', gap: '1em', margin: '0' }}>
            <StyledHeadingThree>{literals.user_header_company_placeholder}:</StyledHeadingThree>
            <span>{profile.company}</span>
          </StyledFlex>
        )}
        <StyledHeadingThree>{literals.about_me_interests_title}</StyledHeadingThree>
        <RowWrap>
          {profile.interests.length > 0 ? (
            profile.interests.map((interest: Interest) => (
              <SecondaryButton style={{ color: theme.colors.grey.dark100 }} key={interest.id} disabled={true}>
                {interest.name}
              </SecondaryButton>
            ))
          ) : (
            <Note>{literals.about_me_without_interests_message}</Note>
          )}
        </RowWrap>
        <StyledHeadingThree>{literals.about_me_skills_title}</StyledHeadingThree>
        <RowWrap>
          {profile.skills.length > 0 ? (
            profile.skills.map((skill: Skill) => (
              <SecondaryButton key={skill.id} disabled={true}>
                {skill.name}
              </SecondaryButton>
            ))
          ) : (
            <Note>{literals.about_me_without_skills_messages}</Note>
          )}
        </RowWrap>
        <StyledHeadingThree>{literals.request_market_expertise}</StyledHeadingThree>
        <RowWrap>
          {profile.marketExpertises?.length > 0 ? (
            profile.marketExpertises?.map((country: Country) => (
              <SecondaryButton key={country.id} disabled={true}>
                {country.name}
              </SecondaryButton>
            ))
          ) : (
            <Note>{literals.about_me_without_market_expertise}</Note>
          )}
        </RowWrap>
        <StyledHeadingThree>{literals.create_project_05_choose_sdgs}</StyledHeadingThree>
        <RowWrap style={{ paddingBottom: '2em' }}>
          {profile.sustainableDevelopmentGoals.length > 0 ? (
            profile.sustainableDevelopmentGoals.map(s => (
              <BigSdgPopup
                key={s.id.toString()}
                sdg={{
                  ...s,
                  iconName: `/Images/branding.babele/SDGs/Icons_Square/${s.iconName}.png`,
                }}
                trigger={
                  <SgdContainer style={{ width: '7em', height: '7em', marginBottom: '0.8em' }}>
                    <SdgImg
                      src={`/Images/branding.babele/SDGs/Icons_Square/${s.iconName}.png`}
                      alt={s.name}
                      style={{ borderRadius: '3px' }}
                    />
                  </SgdContainer>
                }
              />
            ))
          ) : (
            <Note>{literals.about_me_without_sdgs}</Note>
          )}
        </RowWrap>
      </StyledProfileWrapper>

      {/**
      <StyledHeadingThree>{literals.professional_experience_title}</StyledHeadingThree>
      <Note>To be implemented.</Note>
      **/}
    </Container>
  );
};

export default connect(mapStateToProps, {})(Profile);
