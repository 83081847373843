import { append, map } from 'ramda';
import { Comment, Community, FeedItem } from 'redux/types/account';
import { recursiveCommentLike } from '../reducersHelpers/recursiveCommentLike';

export const updateCommentsFromCommunity = (community: Community, comment: Comment) => {
  const isNewComment = !community.feed?.some(
    (feedItem: FeedItem) =>
      feedItem.id === comment.id ||
      feedItem.comments.some(
        (commentItem: Comment) =>
          commentItem.id === comment.id ||
          commentItem.comments.some((secondLevelComment: Comment) => secondLevelComment.id === comment.id),
      ),
  );
  if (!isNewComment) {
    return {
      ...community,
      feed: community.feed?.map((feedItem: FeedItem) => {
        if (feedItem.id === comment.id) {
          return {
            ...feedItem,
            content: comment.content,
          };
        } else {
          return {
            ...feedItem,
            comments: feedItem.comments.map((feedComment: Comment) => {
              if (feedComment.id === comment.id) {
                return {
                  ...feedComment,
                  content: comment.content,
                };
              } else {
                return {
                  ...feedComment,
                  comments: feedComment.comments.map((secondLevelComment: Comment) => {
                    if (secondLevelComment.id === comment.id) {
                      return {
                        ...secondLevelComment,
                        content: comment.content,
                      };
                    } else {
                      return secondLevelComment;
                    }
                  }),
                };
              }
            }),
          };
        }
      }),
    };
  } else {
    return {
      ...community,
      feed: community.feed?.map((feedItem: FeedItem) => {
        if (feedItem.id !== comment.parentId) {
          const commentedFeed = feedItem.comments.find(c => c.id === comment.parentId);
          if (commentedFeed) {
            const comments = map(feedComment => {
              if (feedComment.id !== comment.parentId) {
                return feedComment;
              } else {
                return {
                  ...feedComment,
                  comments: append(comment, feedComment.comments),
                };
              }
            }, feedItem.comments);
            // add comment to level 1 comment
            return {
              ...feedItem,
              comments,
              commentCount:
                feedItem.commentCount + // add 1 only if the parent is found
                (feedItem.comments.some(c => c.id === comment.parentId) ? 1 : 0),
            };
          } else {
            return feedItem;
          }
        } else {
          // add comment to post
          return {
            ...feedItem,
            comments: append(comment, feedItem.comments),
            commentCount: feedItem.commentCount + 1,
          };
        }
      }, community.feed as FeedItem[]),
    };
  }
};

export const commentLikeFromCommunity = (community: Community, id: number, like: boolean) => {
  if (community.feed) {
    return {
      ...community,
      feed: community.feed.map((f: FeedItem) => {
        if (f.id === id)
          return { ...f, isLiked: like, likeCount: 'likeCount' in f ? f.likeCount + (like ? 1 : -1) : like ? 1 : 0 };
        return {
          ...f,
          comments: recursiveCommentLike(f.comments, id, like),
        };
      }),
    };
  } else {
    return community;
  }
};

export const deleteCommentFromCommunity = (community: Community, id: number) => {
  const isFeedPost = community.feed?.some((feedItem: FeedItem) => feedItem.id === id);
  if (isFeedPost) {
    return {
      ...community,
      feed: community.feed?.filter((feedItem: FeedItem) => feedItem.id !== id) || [],
    };
  } else {
    return {
      ...community,
      feed: community.feed?.map((feedItem: FeedItem) => {
        const isFirstLevelComment = feedItem.comments.find((firstComment: Comment) => firstComment.id === id);
        if (isFirstLevelComment) {
          return {
            ...feedItem,
            commentCount: feedItem.commentCount - 1 - isFirstLevelComment.comments.length,
            comments: feedItem.comments.filter((firstComment: Comment) => firstComment.id !== id),
          };
        } else {
          return {
            ...feedItem,
            commentCount: feedItem.commentCount - 1,
            comments: feedItem.comments.map((firstComment: Comment) => {
              return {
                ...firstComment,
                comments: firstComment.comments.filter((secondComment: Comment) => secondComment.id !== id),
              };
            }),
          };
        }
      }),
    };
  }
};
