import { of } from 'rxjs';
import { AxiosError } from 'axios';
import { store } from '../store';
import { toast } from '../../components/common/toast';
import { fetchCommunitiesAsync } from '../actions/community';
import { RootState } from 'StoreModel';

export const getErrorMessage = (error: AxiosError) => {
  const { literals }: RootState = store.getState();
  let literalsErrorMessage = '';
  if (!error.response || !error.response.data || !error.response.data.replaceAll) return;
  const errorLiteral = error?.response?.data.replaceAll('.', '');
  const errorLiteral2 = error?.response?.data.split('.');
  if (error?.response?.data && literals) {
    literalsErrorMessage = literals[errorLiteral] || literals[errorLiteral2[errorLiteral2.length - 1]];
  }
  return literalsErrorMessage;
};

export const toastException = (error: AxiosError) => {
  const literalsErrorMessage = getErrorMessage(error);
  if (literalsErrorMessage) toast(literalsErrorMessage, { type: 'error' });
};

export const handleError = (error: AxiosError, action?: any, errorMessage?: string) => {
  const storeState: RootState = store.getState();
  const { literals } = storeState;
  if (
    error?.response?.data ===
    'Babele.Domain.Aggregates.MethodologyAgg.Exceptions.MethodologyCannotBeEnabledOrUsedException'
  ) {
    toast(literals.methodology_no_sections_exception || 'This methodology has no sections', { type: 'error' });
  }
  if (
    error?.response?.data === 'Babele.Domain.Aggregates.ApplicationAgg.Exceptions.MethodologyAlreadyAssignedToProject'
  ) {
    toast(literals.methodology_no_sections_exception || 'This methodology is already assigned to the project', {
      type: 'error',
    });
  }
  if (error?.response?.data === 'Babele.Services.Exceptions.UserHasRevokedPermissionToApi') {
    toast(literals.user_has_revoked_permission_to_api || 'Your account has been unsynced', {
      type: 'error',
    });
  }
  if (
    error?.response?.status === 500 &&
    error?.response?.data === 'Babele.Domain.Aggregates.CommunityAgg.Exceptions.CannotAccessToCommunityException'
  ) {
    store.dispatch(fetchCommunitiesAsync.request(storeState.account.session.session.bearer));
  }
  if (error?.response?.status === 413) {
    toast(literals.upload_resource_too_big_message || 'File is too large. Please try again.', { type: 'error' });
  }
  if (error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === null) {
    // 401 means expired or bad bearer
    toast(errorMessage || literals.expired_session_message || 'Your session expired. Please log in again.', {
      type: 'warning',
    });
    window.localStorage.clear();
    store.dispatch({ type: 'RESET_SESSION' });
  } else {
    let literalsErrorMessage;
    if (error?.response?.data && literals && typeof error?.response?.data === 'string') {
      const exceptionName = (error?.response?.data || '').split('.');
      literalsErrorMessage =
        literals[(error?.response?.data || '').replaceAll('.', '')] ||
        literals[exceptionName[exceptionName.length - 1]];
    }
    if (error?.message && literals && literals[error?.message]) literalsErrorMessage = literals[error?.message];
    if (error?.message !== 'hideErrorToast')
      toast(errorMessage || literalsErrorMessage || 'Could not perform this action. Please try again.', {
        type: 'error',
      });
  }
  return of(action?.failure(error.message));
};

export const showSuccessMessage = (message: string) => {
  toast(message, { type: 'success' });
};
