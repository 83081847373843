import { Form } from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';

export const saveFormAsync = createAsyncAction(
  'SAVE_FORM_REQUEST',
  'SAVE_FORM_SUCCESS',
  'SAVE_FORM_FAILURE',
  'SAVE_FORM_IDLE',
)<{ bearer: string; form: Form; communityId: number }, { communityId: number; form: Form }, string, null>();

export const fetchCommunityFormsAsync = createAsyncAction(
  'FETCH_COMMUNITY_FORMS_REQUEST',
  'FETCH_COMMUNITY_FORMS_SUCCESS',
  'FETCH_COMMUNITY_FORMS_FAILURE',
  'FETCH_COMMUNITY_FORMS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; forms: Form[] }, string, null>();

export const deleteFormAsync = createAsyncAction(
  'DELETE_FORM_REQUEST',
  'DELETE_FORM_SUCCESS',
  'DELETE_FORM_FAILURE',
  'DELETE_FORM_IDLE',
)<{ bearer: string; communityId: number; formId: number }, { communityId: number; formId: number }, string, null>();

export const fetchFormByIdAsync = createAsyncAction(
  'FETCH_FORM_BY_ID_REQUEST',
  'FETCH_FORM_BY_ID_SUCCESS',
  'FETCH_FORM_BY_ID_FAILURE',
  'FETCH_FORM_BY_ID_IDLE',
)<{ bearer: string; communityId: number; formId: number }, { communityId: number; form: Form }, string, null>();

export default {
  saveFormAsync,
  fetchCommunityFormsAsync,
  deleteFormAsync,
  fetchFormByIdAsync,
};
