import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError } from './helpers';

import { googleSignIn, loginAsync } from '../actions/session';
import { googleSignInFetch } from 'services/api/session';
import { store } from 'redux/store';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const login: EpicFunction = (action$, state$, { api }) => {
  const storeState: RootState = store.getState();
  const { literals } = storeState;
  let errorMessage = null;
  return action$.pipe(
    filter(isActionOf(loginAsync.request)),
    switchMap(action =>
      from(api.session.login(action.payload)).pipe(
        map(loginAsync.success),
        catchError(error => {
          if (error.response?.data === 'account_is_not_active') errorMessage = literals.account_inactive_login_message;
          else errorMessage = literals.wrong_login_credentials_message;
          return handleError(error, loginAsync, errorMessage);
        }),
      ),
    ),
  );
};

export const googleSignInRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(googleSignIn.request)),
    switchMap(action =>
      from(googleSignInFetch(action.payload)).pipe(
        map(googleSignIn.success),
        catchError(error => handleError(error, googleSignIn, 'Something went wrong')),
      ),
    ),
  );

export default {
  googleSignInRequestEpic,
  login,
};
