import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';

import CkEditor from '../application/ckeditor-description';
import '../../stylesheets/ck-editor.css';

const LongTextEditorItem = CkEditor as React.FC<any>;
const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  withDescription?: boolean;
  value: string;
  placeholder: string;
  setDescription: (desc: string) => void;
  initAutoFocus?: boolean;
  type?: string;
  style?: any;
  transparentBackground?: boolean;
  placeholderStyle?: any;
  className?: string;
}

const LongTextEditor: React.FC<Props> = ({
  setDescription,
  placeholder,
  withDescription = true,
  literals,
  initAutoFocus = true,
  value,
  type = 'long-text-answer',
  style,
  transparentBackground = false,
  placeholderStyle,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <>
      {withDescription && isEditing ? (
        <LongTextEditorItem
          {...rest}
          transparentBackground={transparentBackground}
          style={style}
          type={type}
          description={value}
          initAutoFocus={initAutoFocus}
          placeholder={placeholder}
          setDescription={setDescription}
        />
      ) : null}
      {withDescription && !isEditing ? (
        <div
          onClick={() => setIsEditing(true)}
          style={placeholderStyle}
          dangerouslySetInnerHTML={{ __html: value || literals.add_forms_description }}
        />
      ) : null}
    </>
  );
};

export default connect(mapStateToProps, {})(LongTextEditor);
