import { FeedItem, CommunityPost, Comment, ProjectPost, GetTabEntityComment } from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';
import { CommentType } from '../types/enums';

export const fetchCommunityFeedAsync = createAsyncAction(
  'FETCH_COMMUNITY_FEED_REQUEST',
  'FETCH_COMMUNITY_FEED_SUCCESS',
  'FETCH_COMMUNITY_FEED_FAILURE',
  'FETCH_COMMUNITY_FEED_IDLE',
)<
  { bearer: string; id: number; take: number; skip: number },
  { id: number; feed: FeedItem[]; skip: number; take: number },
  string,
  null
>();

export const fetchProjectFeedAsync = createAsyncAction(
  'FETCH_PROJECT_FEED_REQUEST',
  'FETCH_PROJECT_FEED_SUCCESS',
  'FETCH_PROJECT_FEED_FAILURE',
  'FETCH_PROJECT_FEED_IDLE',
)<
  { bearer: string; project: { id: number; name: string; logo: string }; take: number; skip: number },
  { id: number; feed: FeedItem[]; skip: number; take: number },
  string,
  null
>();

export const fetchTabEntityCommentsAsync = createAsyncAction(
  'FETCH_TAB_ENTITY_FEED_REQUEST',
  'FETCH_TAB_ENTITY_FEED_SUCCESS',
  'FETCH_TAB_ENTITY_FEED_FAILURE',
  'FETCH_TAB_ENTITY_FEED_IDLE',
)<{ bearer: string; communityId: number; tabEntityId: number }, GetTabEntityComment, string, null>();

export const addCommunityPostAsync = createAsyncAction(
  'ADD_COMMUNITY_POST_REQUEST',
  'ADD_COMMUNITY_POST_SUCCESS',
  'ADD_COMMUNITY_POST_FAILURE',
  'ADD_COMMUNITY_POST_IDLE',
)<
  { bearer: string; userId: number; communityId: number; content: string },
  { communityId: number; post: CommunityPost },
  string,
  null
>();

export const likeFeedPostAsync = createAsyncAction(
  'LIKE_FEED_POST_REQUEST',
  'LIKE_FEED_POST_SUCCESS',
  'LIKE_FEED_POST_FAILURE',
  'LIKE_FEED_POST_IDLE',
)<
  { id: number; bearer: string; entityId: number; type: CommentType },
  { id: number; entityId: number; type: CommentType },
  string,
  null
>();

export const unlikeFeedPostAsync = createAsyncAction(
  'UNLIKE_FEED_POST_REQUEST',
  'UNLIKE_FEED_POST_SUCCESS',
  'UNLIKE_FEED_POST_FAILURE',
  'UNLIKE_FEED_POST_IDLE',
)<
  { id: number; bearer: string; entityId: number; type: CommentType },
  { id: number; entityId: number; type: CommentType },
  string,
  null
>();

export const addProjectPostAsync = createAsyncAction(
  'ADD_PROJECT_POST_REQUEST',
  'ADD_PROJECT_POST_SUCCESS',
  'ADD_PROJECT_POST_FAILURE',
  'ADD_PROJECT_POST_IDLE',
)<
  { bearer: string; userId: number; project: { id: number; name: string; logo: string }; content: string },
  { post: ProjectPost },
  string,
  null
>();

export const addCommentToFeedAsync = createAsyncAction(
  'ADD_COMMENT_TO_FEED_REQUEST',
  'ADD_COMMENT_TO_FEED_SUCCESS',
  'ADD_COMMENT_TO_FEED_FAILURE',
  'ADD_COMMENT_TO_FEED_IDLE',
)<
  {
    bearer: string;
    userId: number;
    itemId: number;
    content: string;
    parentId: number;
    itemType: CommentType;
    projectId: number;
    entityId: number;
    id?: number;
  },
  { entityId: number; projectId: number; comment: Comment; itemType: CommentType; itemId: number },
  string,
  null
>();

export const deleteCommentAsync = createAsyncAction(
  'DELETE_COMMENT_REQUEST',
  'DELETE_COMMENT_SUCCESS',
  'DELETE_COMMENT_FAILURE',
  'DELETE_COMMENT_IDLE',
)<
  { bearer: string; postId: number; postType: CommentType; entityId: number; entityType: string; itemId?: number },
  { postId: number; postType: CommentType; entityId: number; entityType: string; itemId: number },
  string,
  null
>();

export const pinCommunityFeedPostAsync = createAsyncAction(
  'PIN_COMMUNITY_FEED_REQUEST',
  'PIN_COMMUNITY_FEED_SUCCESS',
  'PIN_COMMUNITY_FEED_FAILURE',
  'PIN_COMMUNITY_FEED_IDLE',
)<{ bearer: string; postId: number; communityId: number }, { postId: number; communityId: number }, string, null>();

export const unpinCommunityFeedPostAsync = createAsyncAction(
  'UNPIN_COMMUNITY_FEED_REQUEST',
  'UNPIN_COMMUNITY_FEED_SUCCESS',
  'UNPIN_COMMUNITY_FEED_FAILURE',
  'UNPIN_COMMUNITY_FEED_IDLE',
)<{ bearer: string; postId: number; communityId: number }, { postId: number; communityId: number }, string, null>();

export default {
  fetchCommunityFeedAsync,
  fetchProjectFeedAsync,
  addCommunityPostAsync,
  likeFeedPostAsync,
  unlikeFeedPostAsync,
  addProjectPostAsync,
  addCommentToFeedAsync,
  deleteCommentAsync,
  pinCommunityFeedPostAsync,
  unpinCommunityFeedPostAsync,
};
