import { Epic } from 'redux-observable';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import {
  saveApplicationDefinitionAsync,
  fetchCommunityApplicationDefinitionsAsync,
  deleteApplicationDefinitionAsync,
  fetchApplicationDefinitionByIdAsync,
  saveApplicationEntryAsync,
  fetchApplicationEntryByIdAsync,
  fetchCurrentUserApplicationsAsync,
  fetchApplicationEntriesByDefinitionIdAsync,
  fetchEvaluationAnswersByApplicationEntryIdAsync,
  saveApplicationEntryTagsAsync,
  saveApplicationEntryScoresAsync,
  fetchApplicationEntryScoresAsync,
  updateApplicationEntryStatusAsync,
  fetchApplicationFiltersAsync,
  saveCommentToApplicationEntryAsync,
  fetchApplicationEntryCommentsAsync,
  deleteApplicationEntryCommentAsync,
  likeApplicationEntryCommentAsync,
  deleteApplicationEntriesAsync,
  fetchApplicationDefinitionOverviewByIdAsync,
  fetchApplicationDefinitionPreviewByidAsync,
} from '../actions/application-manager';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { handleError } from './helpers';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const saveApplicationDefinitionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveApplicationDefinitionAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.saveApplicationDefinition(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinition,
        ),
      ).pipe(
        map(saveApplicationDefinitionAsync.success),
        catchError(error => handleError(error, saveApplicationDefinitionAsync)),
      ),
    ),
  );

export const fetchCommunityApplicationDefinitionsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityApplicationDefinitionsAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchCommunityApplicationDefinitions(action.payload.bearer, action.payload.communityId),
      ).pipe(
        map(fetchCommunityApplicationDefinitionsAsync.success),
        catchError(error => handleError(error, fetchCommunityApplicationDefinitionsAsync)),
      ),
    ),
  );

export const deleteApplicationDefinitionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteApplicationDefinitionAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.deleteApplicationDefinition(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
        ),
      ).pipe(
        map(deleteApplicationDefinitionAsync.success),
        catchError(error => handleError(error, deleteApplicationDefinitionAsync)),
      ),
    ),
  );

export const fetchApplicationDefinitionByIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationDefinitionByIdAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchApplicationDefinitionById(
          action.payload.bearer,
          action.payload.applicationDefinitionId,
        ),
      ).pipe(
        map(fetchApplicationDefinitionByIdAsync.success),
        catchError(error => handleError(error, fetchApplicationDefinitionByIdAsync)),
      ),
    ),
  );

export const fetchApplicationDefinitionOverviewByIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationDefinitionOverviewByIdAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchApplicationDefinitionOverviewById(
          action.payload.bearer,
          action.payload.applicationDefinitionId,
        ),
      ).pipe(
        map(fetchApplicationDefinitionOverviewByIdAsync.success),
        catchError(error => handleError(error, fetchApplicationDefinitionOverviewByIdAsync)),
      ),
    ),
  );

export const fetchApplicationDefinitionPreviewByidEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationDefinitionPreviewByidAsync.request)),
    switchMap(action =>
      from(api.applicationManager.fetchApplicationDefinitionPreviewById(action.payload.applicationDefinitionId)).pipe(
        map(fetchApplicationDefinitionPreviewByidAsync.success),
        catchError(error => handleError(error, fetchApplicationDefinitionPreviewByidAsync)),
      ),
    ),
  );

export const saveApplicationEntryEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveApplicationEntryAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.saveApplicationEntry(
          action.payload.bearer,
          action.payload.applicationEntry,
          action.payload.submitApplication,
        ),
      ).pipe(
        map(saveApplicationEntryAsync.success),
        catchError(error => handleError(error, saveApplicationEntryAsync)),
      ),
    ),
  );

export const fetchApplicationEntryByIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationEntryByIdAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchApplicationEntryById(action.payload.bearer, action.payload.applicationEntryId),
      ).pipe(
        map(fetchApplicationEntryByIdAsync.success),
        catchError(error => handleError(error, fetchApplicationEntryByIdAsync)),
      ),
    ),
  );

export const fetchCurrentUserApplicationsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCurrentUserApplicationsAsync.request)),
    switchMap(action =>
      from(api.applicationManager.fetchCurrentUserApplications(action.payload.bearer)).pipe(
        map(fetchCurrentUserApplicationsAsync.success),
        catchError(error => handleError(error, fetchCurrentUserApplicationsAsync)),
      ),
    ),
  );

export const fetchApplicationEntriesByDefinitionIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationEntriesByDefinitionIdAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchApplicationEntriesByDefinitionId(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.filtering,
          action.payload.sorting,
          action.payload.skip,
          action.payload.take,
          action.payload.concept,
          action.payload.status,
        ),
      ).pipe(
        map(fetchApplicationEntriesByDefinitionIdAsync.success),
        catchError(error => handleError(error, fetchApplicationEntriesByDefinitionIdAsync)),
      ),
    ),
  );

export const fetchEvaluationAnswersByApplicationEntryIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchEvaluationAnswersByApplicationEntryIdAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchEvaluationAnswersByApplicationEntryId(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
        ),
      ).pipe(
        map(fetchEvaluationAnswersByApplicationEntryIdAsync.success),
        catchError(error => handleError(error, fetchEvaluationAnswersByApplicationEntryIdAsync)),
      ),
    ),
  );

export const saveApplicationEntryTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveApplicationEntryTagsAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.saveApplicationEntryTags(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
          action.payload.tags,
        ),
      ).pipe(
        map(saveApplicationEntryTagsAsync.success),
        catchError(error => handleError(error, saveApplicationEntryTagsAsync)),
      ),
    ),
  );

export const saveApplicationEntryScoresEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveApplicationEntryScoresAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.saveApplicationEntryScores(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
          action.payload.scores,
        ),
      ).pipe(
        map(saveApplicationEntryScoresAsync.success),
        catchError(error => handleError(error, saveApplicationEntryScoresAsync)),
      ),
    ),
  );

export const fetchApplicationEntryScoresEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationEntryScoresAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchApplicationEntryScores(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
        ),
      ).pipe(
        map(fetchApplicationEntryScoresAsync.success),
        catchError(error => handleError(error, fetchApplicationEntryScoresAsync)),
      ),
    ),
  );

export const updateApplicationEntryStatusEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateApplicationEntryStatusAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.updateApplicationEntryStatus(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryIds,
          action.payload.status,
          action.payload.methodologyId,
          action.payload.newProjects,
          action.payload.applicantEmails,
        ),
      ).pipe(
        map(updateApplicationEntryStatusAsync.success),
        catchError(error => handleError(error, updateApplicationEntryStatusAsync)),
      ),
    ),
  );

export const deleteApplicationEntriesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteApplicationEntriesAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.deleteApplicationEntries(
          action.payload.bearer,
          action.payload.applicationEntryIds,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
        ),
      ).pipe(
        map(deleteApplicationEntriesAsync.success),
        catchError(error => handleError(error, deleteApplicationEntriesAsync)),
      ),
    ),
  );

export const fetchApplicationFiltersEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationFiltersAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchApplicationFilters(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
        ),
      ).pipe(
        map(fetchApplicationFiltersAsync.success),
        catchError(error => handleError(error, fetchApplicationFiltersAsync)),
      ),
    ),
  );

export const saveCommentToApplicationEntryEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveCommentToApplicationEntryAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.saveCommentToApplicationEntry(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
          action.payload.parentId,
          action.payload.content,
          action.payload.id,
        ),
      ).pipe(
        map(saveCommentToApplicationEntryAsync.success),
        catchError(error => handleError(error, saveCommentToApplicationEntryAsync)),
      ),
    ),
  );

export const fetchApplicationEntryCommentsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchApplicationEntryCommentsAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.fetchApplicationEntryComments(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
        ),
      ).pipe(
        map(fetchApplicationEntryCommentsAsync.success),
        catchError(error => handleError(error, fetchApplicationEntryCommentsAsync)),
      ),
    ),
  );

export const deleteApplicationEntryCommentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteApplicationEntryCommentAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.deleteApplicationEntryComment(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
          action.payload.commentId,
        ),
      ).pipe(
        map(deleteApplicationEntryCommentAsync.success),
        catchError(error => handleError(error, deleteApplicationEntryCommentAsync)),
      ),
    ),
  );

export const likeApplicationEntryCommentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(likeApplicationEntryCommentAsync.request)),
    switchMap(action =>
      from(
        api.applicationManager.likeApplicationEntryComment(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.applicationDefinitionId,
          action.payload.applicationEntryId,
          action.payload.commentId,
          action.payload.isLike,
        ),
      ).pipe(
        map(likeApplicationEntryCommentAsync.success),
        catchError(error => handleError(error, likeApplicationEntryCommentAsync)),
      ),
    ),
  );

export default {
  saveApplicationDefinitionEpic,
  fetchCommunityApplicationDefinitionsEpic,
  deleteApplicationDefinitionEpic,
  fetchApplicationDefinitionByIdEpic,
  fetchApplicationDefinitionOverviewByIdEpic,
  fetchApplicationDefinitionPreviewByidEpic,
  saveApplicationEntryEpic,
  fetchApplicationEntryByIdEpic,
  fetchCurrentUserApplicationsEpic,
  fetchApplicationEntriesByDefinitionIdEpic,
  fetchEvaluationAnswersByApplicationEntryIdEpic,
  saveApplicationEntryTagsEpic,
  saveApplicationEntryScoresEpic,
  fetchApplicationEntryScoresEpic,
  updateApplicationEntryStatusEpic,
  fetchApplicationFiltersEpic,
  saveCommentToApplicationEntryEpic,
  fetchApplicationEntryCommentsEpic,
  deleteApplicationEntryCommentEpic,
  likeApplicationEntryCommentEpic,
  deleteApplicationEntriesEpic,
};
