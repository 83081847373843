import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError, tap } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError, showSuccessMessage } from './helpers';

import {
  fetchNotificationsAsync,
  markNotificationAsReadAsync,
  markAllNotificationsAsReadAsync,
  fetchCurrentUserAsync,
  fetchMyStuffAsync,
  fetchNotificationSettingsAsync,
  saveNotificationSettingsAsync,
  toggleNotificationsAsync,
  saveProfileAsync,
  fetchSkillsAsync,
  getLiteralsAsync,
  changeTipStateAsync,
  reacceptTermsAndConditionsAsync,
  clearHasPendingRequestsAsync,
  fetchCountriesAsync,
  fetchSDGsAsync,
  fetchProjectCategoriesAsync,
  updateSkillAsync,
} from '../actions/account';
import { updateSkillFetch } from 'services/api/account';
import accountSessionBearerSelector from 'redux/selectors/account/accountSessionBearerSelector';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchCurrentUserData: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCurrentUserAsync.request)),
    switchMap(action =>
      from(api.account.fetchCurrentUserData(action.payload)).pipe(
        map(fetchCurrentUserAsync.success),
        catchError(error => handleError(error, fetchCurrentUserAsync)),
      ),
    ),
  );

export const reacceptTermsAndConditionsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(reacceptTermsAndConditionsAsync.request)),
    switchMap(action =>
      from(api.account.reacceptTermsAndConditions(action.payload)).pipe(
        map(reacceptTermsAndConditionsAsync.success),
        catchError(error => handleError(error, reacceptTermsAndConditionsAsync)),
      ),
    ),
  );

export const fetchNotificationsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchNotificationsAsync.request)),
    switchMap(action =>
      from(api.account.fetchNotifications(action.payload.bearer, action.payload.take, action.payload.skip)).pipe(
        map(fetchNotificationsAsync.success),
        catchError(error => handleError(error, fetchNotificationsAsync)),
      ),
    ),
  );

export const markNotificationAsReadEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(markNotificationAsReadAsync.request)),
    switchMap(action =>
      from(api.account.markNotificationAsRead(action.payload.bearer, action.payload.id)).pipe(
        map(markNotificationAsReadAsync.success),
        catchError(error => handleError(error, markNotificationAsReadAsync)),
      ),
    ),
  );

export const markAllNotificationsAsReadEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(markAllNotificationsAsReadAsync.request)),
    switchMap(action =>
      from(api.account.markAllNotificationsAsRead(action.payload.bearer)).pipe(
        map(markAllNotificationsAsReadAsync.success),
        catchError(error => handleError(error, markAllNotificationsAsReadAsync)),
      ),
    ),
  );

export const fetchMyStuffEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchMyStuffAsync.request)),
    switchMap(action =>
      from(api.account.fetchMyStuff(action.payload.bearer)).pipe(
        map(fetchMyStuffAsync.success),
        catchError(error => handleError(error, fetchMyStuffAsync)),
      ),
    ),
  );

export const fetchNotificationSettingsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchNotificationSettingsAsync.request)),
    switchMap(action =>
      from(api.account.fetchNotificationSettings(action.payload.bearer, action.payload.userId)).pipe(
        map(fetchNotificationSettingsAsync.success),
        catchError(error => handleError(error, fetchNotificationSettingsAsync)),
      ),
    ),
  );

export const saveNotificationSettingsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveNotificationSettingsAsync.request)),
    switchMap(action =>
      from(
        api.account.saveNotificationSetting(action.payload.bearer, action.payload.userId, action.payload.settings),
      ).pipe(
        map(saveNotificationSettingsAsync.success),
        tap(() => {
          showSuccessMessage('Settings saved! 🌟');
        }),
        catchError(error => handleError(error, saveNotificationSettingsAsync)),
      ),
    ),
  );

export const toggleNotificationsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(toggleNotificationsAsync.request)),
    switchMap(action =>
      from(api.account.toggleNotifications(action.payload.bearer, action.payload.state)).pipe(
        map(toggleNotificationsAsync.success),
        tap(() => {
          showSuccessMessage('Settings saved! 🌟');
        }),
        catchError(error => handleError(error, toggleNotificationsAsync)),
      ),
    ),
  );

export const saveProfileEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveProfileAsync.request)),
    switchMap(action =>
      from(
        api.account.saveProfile(
          action.payload.bearer,
          action.payload.rawUser || {},
          action.payload.userId,
          action.payload.country,
          action.payload.city,
          action.payload.role,
          action.payload.bio,
          action.payload.file,
          action.payload.photo,
          action.payload.firstName,
          action.payload.lastName,
          action.payload.interests,
          action.payload.skills,
          action.payload.language,
          action.payload.marketExpertises,
          action.payload.sustainableDevelopmentGoals,
          action.payload.primaryCommunityId,
          action.payload.company,
          action.payload.linkedInUrl,
        ),
      ).pipe(
        map(saveProfileAsync.success),
        tap(() => {
          showSuccessMessage('Profile saved! 🌟');
        }),
        catchError(error => handleError(error, saveProfileAsync)),
      ),
    ),
  );

export const fetchSkillsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchSkillsAsync.request)),
    switchMap(action =>
      from(api.account.fetchStandardSkillList(action.payload.bearer)).pipe(
        map(fetchSkillsAsync.success),
        catchError(error => handleError(error, fetchSkillsAsync)),
      ),
    ),
  );

export const updateSkillEpic: EpicFunction = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateSkillAsync.request)),
    switchMap(action =>
      from(updateSkillFetch(accountSessionBearerSelector(state$.value), action.payload)).pipe(
        map(() => updateSkillAsync.success(action.payload)),
        catchError(error => handleError(error, updateSkillAsync)),
      ),
    ),
  );

export const getLiteralsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(getLiteralsAsync.request)),
    switchMap(action =>
      from(api.literals.getLiterals(action.payload.language, action.payload.communityId, action.payload.bearer)).pipe(
        map(getLiteralsAsync.success),
        catchError(error => handleError(error, getLiteralsAsync)),
      ),
    ),
  );

export const changeTipStateEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(changeTipStateAsync.request)),
    switchMap(action =>
      from(api.account.changeTipState(action.payload.tip, action.payload.state)).pipe(
        map(changeTipStateAsync.success),
        catchError(error => handleError(error, changeTipStateAsync)),
      ),
    ),
  );

export const clearHasPendingRequestsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(clearHasPendingRequestsAsync.request)),
    switchMap(action =>
      from(api.account.clearHasPendingRequests(action.payload.bearer)).pipe(
        map(clearHasPendingRequestsAsync.success),
        catchError(error => handleError(error, clearHasPendingRequestsAsync)),
      ),
    ),
  );

export const fetchCountriesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCountriesAsync.request)),
    switchMap(action =>
      from(api.account.fetchCountries(action.payload.bearer, action.payload.loadGlobalOption)).pipe(
        map(fetchCountriesAsync.success),
        catchError(error => handleError(error, fetchCountriesAsync)),
      ),
    ),
  );

export const fetchSDGsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchSDGsAsync.request)),
    switchMap(action =>
      from(api.account.fetchSDGs(action.payload.bearer)).pipe(
        map(fetchSDGsAsync.success),
        catchError(error => handleError(error, fetchSDGsAsync)),
      ),
    ),
  );

export const fetchProjectCategoriesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectCategoriesAsync.request)),
    switchMap(action =>
      from(api.account.fetchProjectCategories(action.payload.bearer, action.payload.loadAllOption)).pipe(
        map(fetchProjectCategoriesAsync.success),
        catchError(error => handleError(error, fetchProjectCategoriesAsync)),
      ),
    ),
  );

export default {
  updateSkillEpic,
  fetchCurrentUserData,
  fetchNotificationsEpic,
  markNotificationAsReadEpic,
  markAllNotificationsAsReadEpic,
  fetchMyStuffEpic,
  fetchNotificationSettingsEpic,
  saveNotificationSettingsEpic,
  toggleNotificationsEpic,
  saveProfileEpic,
  fetchSkillsEpic,
  getLiteralsEpic,
  changeTipStateEpic,
  reacceptTermsAndConditionsEpic,
  clearHasPendingRequestsEpic,
  fetchCountriesEpic,
  fetchSDGsEpic,
  fetchProjectCategoriesEpic,
};
