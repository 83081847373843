import React, { useEffect, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { User, NotificationSetting } from 'redux/types/account';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import { DropdownProps, Loader } from 'semantic-ui-react';

import {
  fetchNotificationSettingsAsync,
  saveNotificationSettingsAsync,
  toggleNotificationsAsync,
} from '../../redux/actions/account';
import { groupBy, prop } from 'ramda';
import { Separator, Column } from '../styled-components/common';
import { NotificationFrequency, Flag } from '../../redux/types/enums';
import Toggle from '../common/toggle';
import { Dropdown } from '../common/dropdown';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled(Column)`
  width: 100%;
`;

const SectionName = styled.p<{ $open: boolean }>`
  font-weight: 500;
  font-size: 0.8em;
  margin: 0.5em 0;
  text-align: left;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.grey.dark};
  text-transform: uppercase;
  & > * {
    ${({ $open }) => !$open && `transform: rotate(-90deg)`}
  }
`;

const Description = styled.p`
  line-height: 1.5em;
  width: 69%;
  margin: 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-start;

  span {
    color: ${({ theme }) => theme.colors.grey.dark};
  }

  * {
    margin-right: 1em;
  }
`;

const ToggleLabel = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8em !important;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface IOpenGroups {
  UserNotificationTypes: boolean;
  RequestNotificationType: boolean;
  TabEntityFollowerNotificationTypes: boolean;
  CommunityMemberNotificationTypes: boolean;
  CommunityAdminNotificationTypes: boolean;
}

const mapDispatchToProps = {
  fetchSettings: fetchNotificationSettingsAsync.request,
  saveSettings: saveNotificationSettingsAsync.request,
  toggleNotificationsAsync: toggleNotificationsAsync.request,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends dispatchType, ReturnType<typeof mapStateToProps> {
  account: User;
  bearer: string;
  isFetching: Flag;
  options?: string | undefined;
}

const Notifications: React.FC<Props> = ({
  fetchSettings,
  bearer,
  account,
  isFetching,
  saveSettings,
  toggleNotificationsAsync,
  options,
  literals,
}) => {
  const ungrouppedSettings = account.settings?.notifications?.list;
  const [settings, setSettings] = useState<any>([]);
  const [notificationsActive, setNotificationsState] = useState<boolean>(
    account.settings?.notifications.enabled || false,
  );
  const [openGroups, setOpenGroups] = useState<IOpenGroups>({
    UserNotificationTypes: false,
    RequestNotificationType: false,
    TabEntityFollowerNotificationTypes: false,
    CommunityMemberNotificationTypes: false,
    CommunityAdminNotificationTypes: false,
  });

  const toggleSection = (
    category:
      | 'UserNotificationTypes'
      | 'RequestNotificationType'
      | 'TabEntityFollowerNotificationTypes'
      | 'CommunityMemberNotificationTypes'
      | 'CommunityAdminNotificationTypes',
  ): void => {
    setOpenGroups({
      ...openGroups,
      [category]: !openGroups[category],
    });
  };
  const frequencyOptions = [
    {
      key: NotificationFrequency.Never,
      value: NotificationFrequency.Never,
      text: literals.notification_frequency_never,
    },
    {
      key: NotificationFrequency.Immediate,
      value: NotificationFrequency.Immediate,
      text: literals.notification_frequency_immediate,
    },
    {
      key: NotificationFrequency.DailyDigest,
      value: NotificationFrequency.DailyDigest,
      text: literals.notification_frequency_daily,
    },
    {
      key: NotificationFrequency.WeeklyDigest,
      value: NotificationFrequency.WeeklyDigest,
      text: literals.notification_frequency_weekly,
    },
  ];

  useEffect((): void => {
    if (options === 'off') {
      setNotificationsState(false);
      toggleNotificationsAsync({ bearer, state: false });
    } else if (options === 'on') {
      setNotificationsState(true);
      toggleNotificationsAsync({ bearer, state: true });
    }
  }, [options]);

  // fetch notifications settings
  useEffect((): void => {
    fetchSettings({ bearer, userId: account.id });
  }, [bearer]);

  // when the fetch is done
  useEffect((): void => {
    if (ungrouppedSettings?.length > 0) {
      const groupByCategory = groupBy(prop('category') as (a: NotificationSetting) => string);
      const grouppedSettings = groupByCategory(ungrouppedSettings);
      setSettings(grouppedSettings);
    }
  }, [ungrouppedSettings]);

  const onSettingChange = (setting: NotificationSetting, notificationFrequency: number): void => {
    const newSettingsArray = ungrouppedSettings?.map(
      (s: NotificationSetting): NotificationSetting => {
        if (s.notificationType === setting.notificationType) {
          return { ...setting, notificationFrequency };
        }
        return s;
      },
    );

    saveSettings({ bearer, userId: account.id, settings: newSettingsArray });
  };

  const onToggleSettings = (event: React.ChangeEvent<HTMLInputElement>, data: { checked: boolean }): void => {
    setNotificationsState(data.checked); // show the UI change before the request is finished
    toggleNotificationsAsync({ bearer, state: data.checked });
  };

  useEffect((): void => {
    setNotificationsState(account.settings?.notifications.enabled);
  }, [account.settings?.notifications.enabled]);

  if (isFetching === Flag.Request) {
    return (
      <div style={{ position: 'relative' }}>
        <Loader active />
      </div>
    );
  }

  return (
    <Container>
      <Row style={{ justifyContent: 'space-between', marginTop: '1em' }}>
        <span>{literals.user_settings_receive_email_notifications}</span>
        <ToggleContainer>
          <ToggleLabel>{literals.global_no}</ToggleLabel>
          <Toggle toggle checked={notificationsActive} onChange={onToggleSettings} />
          <ToggleLabel>{literals.global_yes}</ToggleLabel>
        </ToggleContainer>
      </Row>
      <Separator />
      {Object.keys(settings).map((category, index, arr) => (
        <Column key={category}>
          <SectionName
            $open={openGroups[category as keyof IOpenGroups]}
            onClick={() => toggleSection(category as keyof IOpenGroups)}
          >
            <ArrowIcon /> {literals[category] || category.replace(/([A-Z])/g, ' $1').trim()}
          </SectionName>
          {openGroups[category as keyof IOpenGroups] &&
            settings[category].map((setting: NotificationSetting) => (
              <Row key={setting.notificationType} style={{ margin: '.5em 0' }}>
                <Description>{literals[setting.notificationType] || setting.description}</Description>
                <Dropdown
                  style={{ width: '28%' }}
                  options={frequencyOptions}
                  value={setting.notificationFrequency}
                  selection={true}
                  clearable={false}
                  disabled={!notificationsActive}
                  onChange={(event: SyntheticEvent, data: DropdownProps): void =>
                    onSettingChange(setting, data.value as number)
                  }
                />
              </Row>
            ))}
          {index !== arr.length - 1 && <Separator />}
        </Column>
      ))}
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
