import { MultipleMeetingSettings, SelectedUserOption } from 'services/types/user-calendar';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const fetchMultipleMeetingSettingsAsync = createAsyncAction(
  'FETCH_USER_CALENDAR_REQUEST',
  'FETCH_USER_CALENDAR_SUCCESS',
  'FETCH_USER_CALENDAR_FAILURE',
  'FETCH_USER_CALENDAR_CANCEL',
)<{ bearer: string; usersId: number[] }, { multipleMeetingSettings: MultipleMeetingSettings[] }, string, null>();

export const updateSelectedUsers = createAction('UPDATE_SELECTED_USERS')<{
  selectedUsers: SelectedUserOption[] | any;
}>();

export const updateUserMultipleMeetingSettings = createAction('UPDATE_USERS_MULTIPLE_MEETING_SETTINGS')<{
  multipleMeetingSettings: MultipleMeetingSettings[];
}>();

export default { fetchMultipleMeetingSettingsAsync };
