import { useCallback, useEffect } from 'react';

export default function useClickAway({ el, onClickAway }) {
  const myClick = useCallback(
    e => {
      let triggerOnClickAway = true;
      if (Array.isArray(el)) {
        el.forEach(elem => {
          if (elem?.current?.contains?.(e.target)) {
            triggerOnClickAway = false;
          }
        });
      } else if (el?.current?.contains?.(e.target)) {
        triggerOnClickAway = false;
      }
      // console.log('useClickAway', el, e);
      if (triggerOnClickAway) {
        onClickAway();
      }
    },
    [el, onClickAway],
  );
  useEffect(() => {
    document.body.addEventListener('click', myClick);

    return () => {
      document.body.removeEventListener('click', myClick);
    };
  }, []);
}
