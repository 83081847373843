import { Community, MentionUser } from 'redux/types/account';
import { fetchMentionUsers } from '../services/api/community';
import { store } from '../redux/store';
import { RootState } from 'StoreModel';

const MENTION_MATCHING_REGEX = /@\[BabeleUser\{\d+\|@(([^\n](?!BabeleUser)){1,120})}]/gm;

export const getMentions = (community: Community) => {
  return (query: string) => {
    const state: RootState = store.getState();
    const randomId = Math.random().toString(36).substring(2, 15);
    (window as any).isFetchingMentionUsers = {
      ...((window as any).isFetchingMentionUsers || {}),
      [community.id]: randomId,
    };
    const bearer = state.account.session.session.bearer;
    const cachedResults = sessionStorage.getItem(`${community.id}-${query}`);
    if (cachedResults) {
      const parsedCachedResults = JSON.parse(cachedResults);
      return parsedCachedResults;
    }
    return new Promise((resolve, reject) => {
      fetchMentionUsers(bearer, community.id, query).then((response: { mentionUsers: MentionUser[] }) => {
        sessionStorage.setItem(`${community.id}-${query}`, JSON.stringify(response.mentionUsers));
        if (
          (window as any).isFetchingMentionUsers &&
          (window as any).isFetchingMentionUsers[community.id] === randomId
        ) {
          resolve(response.mentionUsers);
        }
      });
    });
  };
};

// <a data-user-id={id} href=...>userName</a>
// converted to
// @[BabeleUser{id}]
export const mentionStringify = (content?: string): string => {
  if (!content) return '';
  const a = document.createElement('div');
  a.innerHTML = content;
  const mentions = Array.from(a.getElementsByClassName('mention'));
  mentions.forEach((mention: any) => {
    const mentioneeId = parseInt(mention.attributes['data-user-id']?.value);
    const mentioneeName = mention.attributes['data-mention']?.value;
    if (mentioneeId && mentioneeName) {
      mention.replaceWith(`@[BabeleUser{${mentioneeId}|${mentioneeName}}]`);
    }
  });
  return a.innerHTML;
};

// @[BabeleUser{id}]
// converted to
// <a data-user-id={id} href=...>userName</a>
export const mentionParse = (content?: string): string => {
  if (!content) return '';
  const re = new RegExp(MENTION_MATCHING_REGEX);
  const myArray = content.matchAll(re);
  // if we substitute the matches from beginning, the second (and next) matches will have
  // wrong indexes, so we have to do the substitutions from the end to beginning, this way
  // the indexes will still be valid
  const substitutions: { index: number; deleteCount: number; replacement: string }[] = [];
  for (const match of myArray) {
    const mentioneeIdAndName = match[0].slice(13, match[0].length - 2);
    const separatorIndex = mentioneeIdAndName.indexOf('|');
    const mentioneeId = mentioneeIdAndName.slice(0, separatorIndex);
    const mentioneeName = mentioneeIdAndName.slice(separatorIndex + 1, mentioneeIdAndName.length);
    substitutions.push({
      index: match.index || 0,
      deleteCount: match[0].length,
      replacement: `<a class="mention" data-mention="${mentioneeName}" data-user-id="${mentioneeId}" href="/user/${mentioneeId}">${mentioneeName}</a>`,
    });
  }
  substitutions.sort((a: { index: number }, b: { index: number }) => b.index - a.index);
  const splitContent = content.split('');
  substitutions.forEach((substitution: { index: number; deleteCount: number; replacement: string }) => {
    splitContent.splice(substitution.index, substitution.deleteCount, substitution.replacement);
  });
  return splitContent.join('');
};

export function MentionCustomization(editor: any) {
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        'data-user-id': true,
      },
    },
    model: {
      key: 'mention',
      value: (viewItem: any) => {
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          userId: viewItem.getAttribute('data-user-id'),
        });
        return mentionAttribute;
      },
    },
    converterPriority: 'high',
  });
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue: any, { writer }: any) => {
      if (!modelAttributeValue) return;
      return writer.createAttributeElement(
        'a',
        {
          class: 'mention',
          'data-mention': modelAttributeValue.id,
          'data-user-id': modelAttributeValue.userId,
          href: `/user/${modelAttributeValue.userId}`,
        },
        {
          priority: 20,
          id: modelAttributeValue.uid,
        },
      );
    },
    converterPriority: 'high',
  });
}
