import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError, tap } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError, showSuccessMessage } from './helpers';
import { toast } from '../../components/common/toast';
import { AxiosError } from 'axios';

import {
  fetchProjectByIdAsync,
  fetchProjectOverviewAsync,
  // fetchProjectSearchOverviewAsync, //! NEW
  fetchProjectMembersAsync,
  fetchProjectResourcesAsync,
  fetchProjectResourcesTagsAsync,
  fetchProjectDiscussionsAsync,
  fetchProjectCirclesAsync,
  inviteProjectMembersAsync,
  applyToTeamProjectAsync,
  createProjectAsync,
  updateProjectAsync,
  updateProjectLogoAsync,
  fetchProjectRequestsAsync,
  updateProjectCoverAsync,
  saveProjectCircleAsync,
  saveProjectCirclesAsync,
  deleteProjectCircleAsync,
  editProjectCircleAsync,
  removeUserFromProjectAsync,
  removeProjectAsync,
  followProjectAsync,
  unfollowProjectAsync,
  saveProjectDiscussionTagsAsync,
  fetchProjectProgressDetailsAsync,
  fetchPendingKpiAsync,
  saveKpiValuesAsync,
  respondProjectJoinRequestAsync,
  removeProjectInvitationAsync,
  saveRequestCommentAsync,
  fetchProjectRequestsTagsAsync,
  updateProjectRequestTagsAsync,
  fetchProjectMembersPendingByUserAsync,
  fetchProjectMembersByCircleAsync,
  fetchProjectKPIDefinitionAsync,
  upsertProjectKPIAsync,
  deleteProjectKpiAsync,
  editProjectTagsAsync,
  deleteProjectTagsAsync,
  updateEditedCommunityProjectTagsAsync,
  updateDeletedCommunityProjectTagsAsync,
} from '../actions/project';
import { store } from 'redux/store';
import { scheduleRequestSessionAsync } from 'redux/actions/request';
import { scheduleRequestSession } from 'services/api/project';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchProjectEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectByIdAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectById(action.payload.bearer, action.payload.id)).pipe(
        map(fetchProjectByIdAsync.success),
        catchError(error => handleError(error, fetchProjectByIdAsync)),
      ),
    ),
  );

export const fetchProjectRequestsTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectRequestsTagsAsync.request)),
    switchMap(action =>
      from(api.project.fetchRequestsTags(action.payload.projectId, action.payload.bearer)).pipe(
        map(fetchProjectRequestsTagsAsync.success),
        catchError(error => handleError(error, fetchProjectRequestsTagsAsync)),
      ),
    ),
  );

export const updateProjectRequestTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateProjectRequestTagsAsync.request)),
    switchMap(action =>
      from(
        api.project.updateRequestTagsById(
          action.payload.bearer,
          action.payload.id,
          action.payload.tags,
          action.payload.projectId,
        ),
      ).pipe(
        map(updateProjectRequestTagsAsync.success),
        catchError(error => handleError(error, updateProjectRequestTagsAsync)),
      ),
    ),
  );

export const saveCommentRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveRequestCommentAsync.request)),
    switchMap(action =>
      from(
        api.project.saveRequestComment(
          action.payload.bearer,
          action.payload.userId,
          action.payload.itemId,
          action.payload.content,
          action.payload.parentId,
          action.payload.requestId,
          action.payload.projectId,
        ),
      ).pipe(
        map(saveRequestCommentAsync.success),
        catchError(error => handleError(error, saveRequestCommentAsync)),
      ),
    ),
  );

export const fetchProjectRequestsEpic: EpicFunction = (action$, state$, { api }) => {
  const storeState: RootState = store.getState();
  const { literals } = storeState;
  return action$.pipe(
    filter(isActionOf(fetchProjectRequestsAsync.request)),
    switchMap(action =>
      from(
        api.project.fetchProjectRequests(
          action.payload.projectId,
          action.payload.bearer,
          action.payload.sorting,
          action.payload.filtering,
          action.payload.skip,
          action.payload.take,
        ),
      ).pipe(
        map(fetchProjectRequestsAsync.success),
        catchError((error: AxiosError) =>
          handleError(error, fetchProjectRequestsAsync, literals.requests_unauthorised_error_message),
        ),
      ),
    ),
  );
};

export const fetchProjectOverviewEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectOverviewAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectOverview(action.payload.bearer, action.payload.id)).pipe(
        map(fetchProjectOverviewAsync.success),
        catchError(error => handleError(error, fetchProjectOverviewAsync)),
      ),
    ),
  );

export const saveProjectDiscussionTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveProjectDiscussionTagsAsync.request)),
    switchMap(action =>
      from(
        api.project.saveProjectDiscussionTags(
          action.payload.bearer,
          action.payload.discussionId,
          action.payload.projectId,
          action.payload.tags,
        ),
      ).pipe(
        map(saveProjectDiscussionTagsAsync.success),
        catchError(error => handleError(error, saveProjectDiscussionTagsAsync)),
      ),
    ),
  );

export const fetchProjectMembersEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectMembersAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectMembers(action.payload.bearer, action.payload.id, action.payload.sorting)).pipe(
        map(fetchProjectMembersAsync.success),
        catchError(error => handleError(error, fetchProjectMembersAsync)),
      ),
    ),
  );

export const fetchProjectMembersPendingByUserEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectMembersPendingByUserAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectMembersPendingByUser(action.payload.bearer, action.payload.projectId)).pipe(
        map(fetchProjectMembersPendingByUserAsync.success),
        catchError(error => handleError(error, fetchProjectMembersPendingByUserAsync)),
      ),
    ),
  );

export const respondProjectJoinRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(respondProjectJoinRequestAsync.request)),
    switchMap(action =>
      from(
        api.project.respondProjectJoinRequest(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.userId,
          action.payload.type,
        ),
      ).pipe(
        map(respondProjectJoinRequestAsync.success),
        catchError(error => handleError(error, respondProjectJoinRequestAsync)),
      ),
    ),
  );

export const removeProjectInvitationEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(removeProjectInvitationAsync.request)),
    switchMap(action =>
      from(
        api.project.removeProjectInvitation(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.userId,
          action.payload.userEmail,
        ),
      ).pipe(
        map(removeProjectInvitationAsync.success),
        catchError(error => handleError(error, removeProjectInvitationAsync)),
      ),
    ),
  );

export const fetchProjectResourcesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectResourcesAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectResources(action.payload.bearer, action.payload.id, action.payload.sorting)).pipe(
        map(fetchProjectResourcesAsync.success),
        catchError(error => handleError(error, fetchProjectResourcesAsync)),
      ),
    ),
  );

export const fetchProjectResourcesTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectResourcesTagsAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectResourcesTags(action.payload.bearer, action.payload.id)).pipe(
        map(fetchProjectResourcesTagsAsync.success),
        catchError(error => handleError(error, fetchProjectResourcesTagsAsync)),
      ),
    ),
  );

export const fetchProjectDiscussionsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectDiscussionsAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectDiscussions(action.payload.bearer, action.payload.id, action.payload.sorting)).pipe(
        map(fetchProjectDiscussionsAsync.success),
        catchError(error => handleError(error, fetchProjectDiscussionsAsync)),
      ),
    ),
  );

export const fetchProjectCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectCirclesAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectCircles(action.payload.bearer, action.payload.id)).pipe(
        map(fetchProjectCirclesAsync.success),
        catchError(error => handleError(error, fetchProjectCirclesAsync)),
      ),
    ),
  );

export const saveProjectCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveProjectCircleAsync.request)),
    switchMap(action =>
      from(api.project.saveProjectCircle(action.payload.bearer, action.payload.id, action.payload.circle)).pipe(
        map(saveProjectCircleAsync.success),
        tap(() => {
          showSuccessMessage('Circle saved successfully');
        }),
        catchError(error => handleError(error, saveProjectCircleAsync)),
      ),
    ),
  );

export const saveProjectCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveProjectCirclesAsync.request)),
    switchMap(action =>
      from(
        api.project.saveProjectCircles(action.payload.bearer, action.payload.projectId, action.payload.circles),
      ).pipe(
        map(saveProjectCirclesAsync.success),
        tap(() => {
          showSuccessMessage('Circles saved successfully');
        }),
        catchError(error => handleError(error, saveProjectCirclesAsync)),
      ),
    ),
  );

export const deleteProjectCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteProjectCircleAsync.request)),
    switchMap(action =>
      from(
        api.project.deleteProjectCircle(action.payload.projectId, action.payload.bearer, action.payload.circleId),
      ).pipe(
        map(deleteProjectCircleAsync.success),
        catchError(error => handleError(error, deleteProjectCircleAsync)),
      ),
    ),
  );

export const editProjectCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(editProjectCircleAsync.request)),
    switchMap(action =>
      from(
        api.project.editProjectCircle(
          action.payload.id,
          action.payload.bearer,
          action.payload.circleId,
          action.payload.userId,
        ),
      ).pipe(
        map(editProjectCircleAsync.success),
        catchError(error => handleError(error, editProjectCircleAsync)),
      ),
    ),
  );

export const inviteProjectMembersEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(inviteProjectMembersAsync.request)),
    switchMap(action =>
      from(api.project.inviteProjectAndCommunityMembers(action.payload.bearer, action.payload.data)).pipe(
        map(inviteProjectMembersAsync.success),
        tap(() => {
          showSuccessMessage('Successfully sent the invites! 🦄');
        }),
        catchError(error => handleError(error, inviteProjectMembersAsync)),
      ),
    ),
  );

export const applyToTeamProjectEpic: EpicFunction = (action$, state$, { api }) => {
  return action$.pipe(
    filter(isActionOf(applyToTeamProjectAsync.request)),
    switchMap(action =>
      from(api.project.applyToTeamProject(action.payload.bearer, action.payload.projectId)).pipe(
        map(applyToTeamProjectAsync.success),
        tap(() => {
          showSuccessMessage('The request is sent to project`s team! 🦄');
        }),
        catchError((error: AxiosError) => {
          if (
            error?.response?.status === 500 &&
            error?.response?.data ===
              'Babele.Domain.Aggregates.ProjectUserAgg.Exceptions.InvalidApplyToTeamAlreadyTeamOrPendingException'
          ) {
            toast('You already applied. Your request is pending, please wait!', { type: 'warning' });
            return of(applyToTeamProjectAsync.failure('Could not perform this action. Please try again.'));
          }
          return handleError(error, applyToTeamProjectAsync);
        }),
      ),
    ),
  );
};

export const createProjectEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createProjectAsync.request)),
    switchMap(action =>
      from(api.project.createProject(action.payload.bearer, action.payload.project)).pipe(
        map(createProjectAsync.success),
        tap(() => {
          showSuccessMessage('Successfully created the project! 💼');
        }),
        catchError(error => handleError(error, createProjectAsync)),
      ),
    ),
  );

export const updateProjectEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateProjectAsync.request)),
    switchMap(action =>
      from(
        api.project.updateProject(action.payload.project, action.payload.bearer, true, action.payload.project.cover),
      ).pipe(
        map(updateProjectAsync.success),
        tap(() => {
          showSuccessMessage('Successfully updated the project! 💼');
        }),
        catchError(error => handleError(error, updateProjectAsync)),
      ),
    ),
  );

export const updateProjectLogo: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateProjectLogoAsync.request)),
    switchMap(action =>
      from(api.project.updateProjectLogo(action.payload.project, action.payload.bearer, action.payload.logo)).pipe(
        map(updateProjectLogoAsync.success),
        tap(() => {
          showSuccessMessage('Successfully updated project! 💼');
        }),
        catchError(error => handleError(error, updateProjectLogoAsync)),
      ),
    ),
  );

export const updateProjectCover: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateProjectCoverAsync.request)),
    switchMap(action =>
      from(api.project.updateProjectCover(action.payload.project, action.payload.bearer, action.payload.cover)).pipe(
        map(updateProjectCoverAsync.success),
        tap(() => {
          showSuccessMessage('Successfully updated project! 💼');
        }),
        catchError(error => handleError(error, updateProjectCoverAsync)),
      ),
    ),
  );

export const removeUserFromProject: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(removeUserFromProjectAsync.request)),
    switchMap(action =>
      from(api.project.removeUserFromProject(action.payload.bearer, action.payload.id, action.payload.userId)).pipe(
        map(removeUserFromProjectAsync.success),
        catchError(error => handleError(error, removeUserFromProjectAsync)),
      ),
    ),
  );

export const removeProject: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(removeProjectAsync.request)),
    switchMap(action =>
      from(api.project.removeProject(action.payload.bearer, action.payload.project.id)).pipe(
        map(removeProjectAsync.success),
        tap(() => {
          const [communityId] = action.payload.project.communityIds;
          if (communityId) {
            action.payload.history.replace(`/community/${communityId}/projects`);
          }
        }),
        catchError(error => handleError(error, removeProjectAsync)),
      ),
    ),
  );

export const followProjectEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(followProjectAsync.request)),
    switchMap(action =>
      from(api.project.followProject(action.payload.bearer, action.payload.projectId)).pipe(
        map(followProjectAsync.success),
        catchError(error => handleError(error, followProjectAsync)),
      ),
    ),
  );
export const unfollowProjectEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(unfollowProjectAsync.request)),
    switchMap(action =>
      from(api.project.unfollowProject(action.payload.bearer, action.payload.projectId)).pipe(
        map(unfollowProjectAsync.success),
        catchError(error => handleError(error, unfollowProjectAsync)),
      ),
    ),
  );

export const fetchProjectProgressDetailsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectProgressDetailsAsync.request)),
    switchMap(action =>
      from(
        api.project.fetchProjectProgressDetails(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(fetchProjectProgressDetailsAsync.success),
        catchError(error => handleError(error, fetchProjectProgressDetailsAsync)),
      ),
    ),
  );

export const fetchPendingKpiEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchPendingKpiAsync.request)),
    switchMap(action =>
      from(api.project.fetchPendingKPI(action.payload.bearer, action.payload.projectId)).pipe(
        map(fetchPendingKpiAsync.success),
        catchError(error => handleError(error, fetchPendingKpiAsync)),
      ),
    ),
  );

export const saveKpiValuesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveKpiValuesAsync.request)),
    switchMap(action =>
      from(api.project.saveKpiValues(action.payload.bearer, action.payload.kpis)).pipe(
        map(saveKpiValuesAsync.success),
        catchError(error => handleError(error, saveKpiValuesAsync)),
      ),
    ),
  );

export const fetchProjectMembersByCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectMembersByCircleAsync.request)),
    switchMap(action =>
      from(
        api.project.fetchProjectMembersByCircle(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.circleId,
          action.payload.skip,
          action.payload.take,
        ),
      ).pipe(
        map(fetchProjectMembersByCircleAsync.success),
        catchError(error => handleError(error, fetchProjectMembersByCircleAsync)),
      ),
    ),
  );

export const fetchProjectKPIEpic: EpicFunction = (action$, state$, { api }) => {
  const storeState: RootState = store.getState();
  const { literals } = storeState;
  return action$.pipe(
    filter(isActionOf(fetchProjectKPIDefinitionAsync.request)),
    switchMap(action =>
      from(api.project.fetchProjectKPIs(action.payload.bearer, action.payload.projectId)).pipe(
        map(fetchProjectKPIDefinitionAsync.success),
        catchError(error =>
          handleError(error, fetchProjectKPIDefinitionAsync, literals.kpi_unauthorised_error_message),
        ),
      ),
    ),
  );
};

export const upsertProjectKPIEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(upsertProjectKPIAsync.request)),
    switchMap(action =>
      from(api.project.upsertKPI(action.payload.bearer, action.payload.communityId, action.payload.kpi)).pipe(
        map(upsertProjectKPIAsync.success),
        catchError(error => of(upsertProjectKPIAsync.failure(error.message))),
      ),
    ),
  );

export const deleteProjectKPIEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteProjectKpiAsync.request)),
    switchMap(action =>
      from(api.project.deleteKPI(action.payload.bearer, action.payload.communityId, action.payload.kpi)).pipe(
        map(deleteProjectKpiAsync.success),
        catchError(error => of(deleteProjectKpiAsync.failure(error.message))),
      ),
    ),
  );

export const editProjectTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(editProjectTagsAsync.request)),
    switchMap(action =>
      from(
        api.project.editProjectTags(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.tagsList,
          action.payload.tagType,
        ),
      ).pipe(
        map(editProjectTagsAsync.success),
        catchError(error => handleError(error, editProjectTagsAsync)),
      ),
    ),
  );

export const deleteProjectTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteProjectTagsAsync.request)),
    switchMap(action =>
      from(
        api.project.deleteProjectTags(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.tags,
          action.payload.tagType,
        ),
      ).pipe(
        map(deleteProjectTagsAsync.success),
        catchError(error => handleError(error, deleteProjectTagsAsync)),
      ),
    ),
  );

export const updateEditedCommunityProjectTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateEditedCommunityProjectTagsAsync.request)),
    switchMap(action =>
      of({ communityId: action.payload.communityId, tagsList: action.payload.tagsList }).pipe(
        map(updateEditedCommunityProjectTagsAsync.success),
        catchError(error => handleError(error, updateEditedCommunityProjectTagsAsync)),
      ),
    ),
  );

export const updateDeletedCommunityProjectTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateDeletedCommunityProjectTagsAsync.request)),
    switchMap(action =>
      of({ communityId: action.payload.communityId, tags: action.payload.tags }).pipe(
        map(updateDeletedCommunityProjectTagsAsync.success),
        catchError(error => handleError(error, updateDeletedCommunityProjectTagsAsync)),
      ),
    ),
  );

export const scheduleRequestSessionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(scheduleRequestSessionAsync.request)),
    switchMap(action =>
      from(
        scheduleRequestSession(
          action.payload.bearer,
          action.payload.requestId,
          action.payload.projectId,
          action.payload.advisorId,
          action.payload.summary,
          action.payload.description,
          action.payload.start,
          action.payload.end,
        ),
      ).pipe(
        map(scheduleRequestSessionAsync.success),
        catchError(error => handleError(error, scheduleRequestSessionAsync)),
      ),
    ),
  );

export default {
  fetchProjectEpic,
  fetchProjectOverviewEpic,
  // fetchProjectSearchOverviewEpic, //! NEW
  saveProjectDiscussionTagsEpic,
  fetchProjectMembersEpic,
  respondProjectJoinRequestEpic,
  removeProjectInvitationEpic,
  fetchProjectResourcesEpic,
  fetchProjectResourcesTagsEpic,
  fetchProjectDiscussionsEpic,
  fetchProjectCirclesEpic,
  saveProjectCircleEpic,
  fetchProjectRequestsEpic,
  fetchProjectRequestsTagsEpic,
  updateProjectRequestTagsEpic,
  saveProjectCirclesEpic,
  deleteProjectCircleEpic,
  editProjectCircleEpic,
  inviteProjectMembersEpic,
  applyToTeamProjectEpic,
  createProjectEpic,
  updateProjectEpic,
  updateProjectLogo,
  updateProjectCover,
  removeUserFromProject,
  removeProject,
  followProjectEpic,
  unfollowProjectEpic,
  fetchProjectProgressDetailsEpic,
  fetchPendingKpiEpic,
  saveKpiValuesEpic,
  saveCommentRequestEpic,
  fetchProjectMembersPendingByUserEpic,
  fetchProjectMembersByCircleEpic,
  fetchProjectKPIEpic,
  upsertProjectKPIEpic,
  deleteProjectKPIEpic,
  editProjectTagsEpic,
  deleteProjectTagsEpic,
  updateEditedCommunityProjectTagsEpic,
  updateDeletedCommunityProjectTagsEpic,
  scheduleRequestSessionEpic,
};
