import { ProjectRequestFeedback } from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';

export const fetchProjectRequestFeedbackListAsync = createAsyncAction(
  'FETCH_REQUEST_FEEDBACK_REQUEST',
  'FETCH_REQUEST_FEEDBACK_SUCCESS',
  'FETCH_REQUEST_FEEDBACK_FAILURE',
  'FETCH_REQUEST_FEEDBACK_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    projectId: number;
  },
  ProjectRequestFeedback[],
  string,
  null
>();

export default {
  fetchProjectRequestFeedbackListAsync,
};
