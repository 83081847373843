import { useState, useEffect } from 'react';
import { theme } from '../components/styled-components/babele-theme';

interface WindowDimensions {
  width: number;
  height: number;
  isMobile: boolean;
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
    isMobile: width <= Number(theme.sizes.mobileWidth.split('px')[0]),
  };
}

export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
