import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { uploadFileAsync } from '../actions/file-upload';
import { handleError } from './helpers';

export const uploadFileEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(uploadFileAsync.request)),
    switchMap(action =>
      from(api.fileUpload.uploadFile(action.payload.bearer, action.payload, action.payload.imageType)).pipe(
        map(uploadFileAsync.success),
        catchError(error => handleError(error, uploadFileAsync)),
      ),
    ),
  );

export default {
  uploadFileEpic,
};
