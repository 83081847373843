import {
  fetchRequestsPendingFeedbackAsync,
  submitPendingFeedbackAsync,
  saveCoachingSessionAsync,
} from 'redux/actions/request';
import { buildFlagReducer } from 'redux/store/utils';

export const submitPendingFeedbackFlag = buildFlagReducer(submitPendingFeedbackAsync);
export const fetchRequestsPendingFeedbackFlag = buildFlagReducer(fetchRequestsPendingFeedbackAsync);
export const saveCoachingSessionFlag = buildFlagReducer(saveCoachingSessionAsync);
