import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError, tap } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError, showSuccessMessage } from './helpers';

import {
  fetchDiscussionAsync,
  fetchCirclesByDiscussionsAsync,
  fetchDiscussionsTagsAsync,
  addCommentToDiscussionAsync,
  createNewDiscussionAsync,
  updateDiscussionIsArchivedAsync,
  deleteDiscussionAsync,
  fetchDiscussionPrivacyCirclesAsync,
  followDiscussionAsync,
  unfollowDiscussionAsync,
} from '../actions/discussion';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const deleteDiscussionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteDiscussionAsync.request)),
    switchMap(action =>
      from(
        api.discussion.deleteDiscussion(
          action.payload.bearer,
          action.payload.discussionId,
          action.payload.entityId,
          action.payload.entityType,
        ),
      ).pipe(
        map(deleteDiscussionAsync.success),
        catchError(error => handleError(error, deleteDiscussionAsync)),
      ),
    ),
  );

export const updateDiscussionIsArchivedEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateDiscussionIsArchivedAsync.request)),
    switchMap(action =>
      from(
        api.discussion.updateDiscussionIsArchived(
          action.payload.bearer,
          action.payload.discussion,
          action.payload.isArchived,
        ),
      ).pipe(
        map(updateDiscussionIsArchivedAsync.success),
        catchError(error => handleError(error, updateDiscussionIsArchivedAsync)),
      ),
    ),
  );

export const addCommentToDiscussionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(addCommentToDiscussionAsync.request)),
    switchMap(action =>
      from(
        api.discussion.addCommentToDiscussion(
          action.payload.bearer,
          action.payload.userId,
          action.payload.itemId,
          action.payload.content,
          action.payload.parentId,
          action.payload.itemType,
          action.payload.entityId,
          action.payload.entityType,
          action.payload.discussionId,
        ),
      ).pipe(
        map(addCommentToDiscussionAsync.success),
        catchError(error => handleError(error, addCommentToDiscussionAsync)),
      ),
    ),
  );

export const fetchDiscussionPrivacyCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchDiscussionPrivacyCirclesAsync.request)),
    switchMap(action =>
      from(
        api.discussion.fetchDiscussionPrivacyCircles(
          action.payload.bearer,
          action.payload.entityId,
          action.payload.entityType,
          action.payload.discussionId,
          action.payload?.discussionPrivacyCircles,
        ),
      ).pipe(
        map(fetchDiscussionPrivacyCirclesAsync.success),
        catchError(error => handleError(error, fetchDiscussionPrivacyCirclesAsync)),
      ),
    ),
  );

export const fetchCirclesByDiscussionsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCirclesByDiscussionsAsync.request)),
    switchMap(action =>
      from(
        api.discussion.fetchCustomCirclesByDiscussion(
          action.payload.entityId,
          action.payload.entityType,
          action.payload.bearer,
        ),
      ).pipe(
        map(fetchCirclesByDiscussionsAsync.success),
        catchError(error => handleError(error, fetchCirclesByDiscussionsAsync)),
      ),
    ),
  );

export const fetchDiscussionsTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchDiscussionsTagsAsync.request)),
    switchMap(action =>
      from(
        api.discussion.fetchDiscussionsTags(action.payload.entityId, action.payload.entityType, action.payload.bearer),
      ).pipe(
        map(fetchDiscussionsTagsAsync.success),
        catchError(error => handleError(error, fetchDiscussionsTagsAsync)),
      ),
    ),
  );

export const createNewDiscussionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createNewDiscussionAsync.request)),
    switchMap(action =>
      from(
        api.discussion.createNewDiscussion(
          action.payload.discussion,
          action.payload.bearer,
          action.payload.privacyCircles,
          action.payload.cover,
          action.payload.discussionSkillCategories,
          action.payload.circleTags,
        ),
      ).pipe(
        map(createNewDiscussionAsync.success),
        tap(() => {
          if (action.payload.discussion.id) {
            showSuccessMessage('Discussion updated successfully! 🤓');
          } else {
            showSuccessMessage('Discussion created successfully! 🤓');
          }
        }),
        catchError(error => handleError(error, createNewDiscussionAsync)),
      ),
    ),
  );

export const fetchDiscussionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchDiscussionAsync.request)),
    switchMap(action =>
      from(
        api.discussion.fetchDiscussion(
          action.payload.discussionId,
          action.payload.bearer,
          action.payload.entityId,
          action.payload.entityType,
        ),
      ).pipe(
        map(fetchDiscussionAsync.success),
        catchError(error => handleError(error, fetchDiscussionAsync)),
      ),
    ),
  );

export const followDiscussionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(followDiscussionAsync.request)),
    switchMap(action =>
      from(
        api.discussion.followDiscussion(
          action.payload.bearer,
          action.payload.entityType,
          action.payload.entityId,
          action.payload.discussionId,
        ),
      ).pipe(
        map(followDiscussionAsync.success),
        catchError(error => handleError(error, followDiscussionAsync)),
      ),
    ),
  );

export const unfollowDiscussionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(unfollowDiscussionAsync.request)),
    switchMap(action =>
      from(
        api.discussion.unfollowDiscussion(
          action.payload.bearer,
          action.payload.entityType,
          action.payload.entityId,
          action.payload.discussionId,
        ),
      ).pipe(
        map(unfollowDiscussionAsync.success),
        catchError(error => handleError(error, unfollowDiscussionAsync)),
      ),
    ),
  );

export default {
  deleteDiscussionEpic,
  updateDiscussionIsArchivedEpic,
  addCommentToDiscussionEpic,
  fetchDiscussionPrivacyCirclesEpic,
  fetchCirclesByDiscussionsEpic,
  fetchDiscussionsTagsEpic,
  createNewDiscussionEpic,
  fetchDiscussionEpic,
  followDiscussionEpic,
  unfollowDiscussionEpic,
};
