import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError, tap } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError, showSuccessMessage } from './helpers';
import { AxiosError } from 'axios';

import {
  fetchCommunitiesAsync,
  fetchCommunityDetailsAsync,
  fetchCommunityMembersAsync,
  fetchCommunityResourcesAsync,
  createResourceAsync,
  createResourcesAsync,
  deleteResourceAsync,
  fetchCommunityRequestsAsync,
  fetchCommunityDiscussionsAsync,
  updateCommunityInfoAsync,
  fetchCommunityProjectsAsync,
  acceptCommunityProjectAsync,
  denyCommunityProjectAsync,
  fetchCommunityCirclesAsync,
  inviteCommunityMembersAsync,
  fetchResourceCirclesAsync,
  fetchResourcesTagsAsync,
  fetchCommunityMembersCountriesAsync,
  fetchCommunityMembersSkillsAsync,
  fetchCommunityMembersInterestsAsync,
  setAllowTasksAsync,
  fetchProjectsAwaitingFeedbackAsync,
  fetchCommunityProjectsCountriesAsync,
  fetchCommunityProjectsCategoriesAsync,
  fetchCommunityProjectsStagesAsync,
  fetchCommunityProjectsSDGsAsync,
  fetchCommunityProjectsTagsAsync,
  fetchProjectsNextParagraphsAsync,
  saveCommunityProjectsTagsAsync,
  saveCommunityCircleAsync,
  saveCommunityCirclesAsync,
  deleteCommunityCircleAsync,
  editCommunityCircleAsync,
  removeUserFromCommunityAsync,
  fetchCommunityProgressTrackingAsync,
  searchCommunityProgressTrackingAsync,
  fetchKPIAsync,
  upsertKPIAsync,
  deleteKPIAsync,
  downloadKPIAsync,
  fetchCommunityCalendarEventsAsync,
  fetchCommunityCalendarEventDetailsAsync,
  upsertCommunityCalendarEventAsync,
  deleteCommunityCalendarEventAsync,
  removeCommunityInvitationAsync,
  respondCommunityJoinRequestAsync,
  upsertCommunityReportAsync,
  deleteCommunityReportAsync,
  fetchCommunityReportsAsync,
  fetchAllCommunityRequestsAsync,
  fetchCommunityMembersPendingByUserAsync,
  fetchCommunityMembersByCircleAsync,
  updateCommunityCircleAsync,
  fetchCommunityCircleToCirclePermissionAsync,
  fetchCommunityCircleToCirclePermissionSaveAsync,
  fetchCommunityProjectFormAsync,
  updateCommunityProjectFormAsync,
  deleteCommunityTagsAsync,
  editCommunityTagsAsync,
  deleteCommunityProjectTagsAsync,
  fetchCommunityMembersTagsAsync,
  saveCommunityMembersTagsAsync,
  updateMethodologyNameAsync,
} from '../actions/community';
import { fetchDiscussionTagsAsync, saveDiscussionTagsAsync } from '../actions/discussion';
import { updateCommunityRequestTagsAsync, fetchCommunityRequestsTagsAsync } from '../actions/request';
import {
  fetchCommunityCircleToCirclePermission,
  fetchCommunityCircleToCirclePermissionSave,
  updateCommunityCircle,
} from 'services/api/community';
import accountSessionBearerSelector from 'redux/selectors/account/accountSessionBearerSelector';
import { editCommunityProjectTagsAsync } from 'redux/actions/community';
import { renameMethodology } from 'services/api/super-admin';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const removeCommunityInvitationEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(removeCommunityInvitationAsync.request)),
    switchMap(action =>
      from(
        api.community.removeCommunityInvitation(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.userEmail,
          action.payload.userId,
        ),
      ).pipe(
        map(removeCommunityInvitationAsync.success),
        catchError(error => handleError(error, removeCommunityInvitationAsync)),
      ),
    ),
  );

export const respondCommunityJoinRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(respondCommunityJoinRequestAsync.request)),
    switchMap(action =>
      from(
        api.community.respondCommunityJoinRequest(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.userId,
          action.payload.type,
        ),
      ).pipe(
        map(respondCommunityJoinRequestAsync.success),
        catchError(error => handleError(error, respondCommunityJoinRequestAsync)),
      ),
    ),
  );

export const fetchCommunitiesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunitiesAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunities(action.payload)).pipe(
        map(fetchCommunitiesAsync.success),
        catchError(error => handleError(error, fetchCommunitiesAsync)),
      ),
    ),
  );

export const updateCommunityInfoEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateCommunityInfoAsync.request)),
    switchMap(action =>
      from(
        api.community.updateCommunityInfo(
          action.payload.community,
          action.payload.bearer,
          action.payload.logoFile,
          action.payload.coverFile,
        ),
      ).pipe(
        map(updateCommunityInfoAsync.success),
        catchError(error => handleError(error, updateCommunityInfoAsync)),
      ),
    ),
  );

export const fetchCommunityDetailsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityDetailsAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityDetails(
          action.payload.id,
          action.payload.bearer,
          action.payload.fetchForTabRenamings,
          action.payload.silentCall,
        ),
      ).pipe(
        map(fetchCommunityDetailsAsync.success),
        catchError(error => handleError(error, fetchCommunityDetailsAsync)),
      ),
    ),
  );

export const fetchCommunityCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityCirclesAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityCircles(action.payload.communityId, action.payload.bearer)).pipe(
        map(fetchCommunityCirclesAsync.success),
        catchError(error => handleError(error, fetchCommunityCirclesAsync)),
      ),
    ),
  );

export const saveCommunityCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveCommunityCircleAsync.request)),
    switchMap(action =>
      from(
        api.community.saveCommunityCircle(action.payload.bearer, action.payload.communityId, action.payload.circle),
      ).pipe(
        map(saveCommunityCircleAsync.success),
        tap(() => {
          showSuccessMessage('Circle saved successfully');
        }),
        catchError((error: AxiosError) => {
          let errorMessage = '';
          if (
            error?.response?.status === 500 &&
            error?.response?.data ===
              'Babele.Domain.Aggregates.CommunityCircleAgg.Exceptions.CommunityCircleCannotBeEdited'
          ) {
            errorMessage = 'Circle can not be edited';
          } else {
            errorMessage = 'Something went wrong when saving the circle';
          }

          return handleError(error, saveCommunityCircleAsync, errorMessage);
        }),
      ),
    ),
  );

export const saveCommunityCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveCommunityCirclesAsync.request)),
    switchMap(action =>
      from(
        api.community.saveCommunityCircles(action.payload.bearer, action.payload.communityId, action.payload.circles),
      ).pipe(
        map(saveCommunityCirclesAsync.success),
        tap(() => {
          showSuccessMessage('Circles saved successfully');
        }),
        catchError((error: AxiosError) => {
          return handleError(error, saveCommunityCirclesAsync);
        }),
      ),
    ),
  );

export const deleteCommunityCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteCommunityCircleAsync.request)),
    switchMap(action =>
      from(
        api.community.deleteCommunityCircle(action.payload.communityId, action.payload.bearer, action.payload.circleId),
      ).pipe(
        map(deleteCommunityCircleAsync.success),
        catchError(error => handleError(error, deleteCommunityCircleAsync)),
      ),
    ),
  );

export const editCommunityCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(editCommunityCircleAsync.request)),
    switchMap(action =>
      from(
        api.community.editCommunityCircle(
          action.payload.communityId,
          action.payload.bearer,
          action.payload.circleId,
          action.payload.userId,
          action.payload.circleName,
        ),
      ).pipe(
        map(editCommunityCircleAsync.success),
        catchError(error => handleError(error, editCommunityCircleAsync)),
      ),
    ),
  );

export const fetchCommunityMembersCountriesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityMembersCountriesAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityMembersCountries(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityMembersCountriesAsync.success),
        catchError(error => handleError(error, fetchCommunityMembersCountriesAsync)),
      ),
    ),
  );

export const fetchCommunityMembersSkillsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityMembersSkillsAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityMembersSkills(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityMembersSkillsAsync.success),
        catchError(error => handleError(error, fetchCommunityMembersSkillsAsync)),
      ),
    ),
  );

export const fetchProjectsAwaitingFeedbackEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectsAwaitingFeedbackAsync.request)),
    switchMap(action =>
      from(api.community.fetchProjectsAwaitingFeedback(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchProjectsAwaitingFeedbackAsync.success),
        catchError(error => handleError(error, fetchProjectsAwaitingFeedbackAsync)),
      ),
    ),
  );

export const fetchProjectsNextParagraphsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectsNextParagraphsAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchProjectsNextParagraphs(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.projectId,
        ),
      ).pipe(
        map(fetchProjectsNextParagraphsAsync.success),
        catchError(error => handleError(error, fetchProjectsNextParagraphsAsync)),
      ),
    ),
  );

export const fetchCommunityMembersInterestsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityMembersInterestsAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityMembersInterests(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityMembersInterestsAsync.success),
        catchError(error => handleError(error, fetchCommunityMembersInterestsAsync)),
      ),
    ),
  );

export const fetchCommunityMembersEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityMembersAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityMembers(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.sorting,
          action.payload.filtering,
          action.payload.skip,
          action.payload.take,
          action.payload.fetchAll,
        ),
      ).pipe(
        map(fetchCommunityMembersAsync.success),
        catchError(error => handleError(error, fetchCommunityMembersAsync)),
      ),
    ),
  );

export const fetchCommunityMembersPendingByUserEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityMembersPendingByUserAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityMembersPendingByUser(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityMembersPendingByUserAsync.success),
        catchError(error => handleError(error, fetchCommunityMembersPendingByUserAsync)),
      ),
    ),
  );

export const inviteCommunityMembersEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(inviteCommunityMembersAsync.request)),
    switchMap(action =>
      from(api.community.inviteCommunityMembers(action.payload.bearer, action.payload.data)).pipe(
        map(inviteCommunityMembersAsync.success),
        tap(() => {
          showSuccessMessage('Successfully sent the invites! 🤓');
        }),
        catchError(error => handleError(error, inviteCommunityMembersAsync)),
      ),
    ),
  );

export const fetchCommunityCircleToCirclePermissionRequestEpic: EpicFunction = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityCircleToCirclePermissionAsync.request)),
    switchMap(action =>
      from(
        fetchCommunityCircleToCirclePermission(action.payload.communityId, accountSessionBearerSelector(state$.value)),
      ).pipe(
        map(fetchCommunityCircleToCirclePermissionAsync.success),
        catchError(error => handleError(error, fetchCommunityCircleToCirclePermissionAsync)),
      ),
    ),
  );

export const fetchCommunityCircleToCirclePermissionRequestSaveEpic: EpicFunction = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityCircleToCirclePermissionSaveAsync.request)),
    switchMap(action =>
      from(fetchCommunityCircleToCirclePermissionSave(action.payload, accountSessionBearerSelector(state$.value))).pipe(
        map(fetchCommunityCircleToCirclePermissionSaveAsync.success),
        catchError(error => handleError(error, fetchCommunityCircleToCirclePermissionSaveAsync)),
      ),
    ),
  );

export const fetchCommunityResourcesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityResourcesAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityResources(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.circles,
          action.payload.sorting,
          action.payload.filtering,
          action.payload.skip,
          action.payload.take,
        ),
      ).pipe(
        map(fetchCommunityResourcesAsync.success),
        catchError(error => handleError(error, fetchCommunityResourcesAsync)),
      ),
    ),
  );

export const fetchResourcesTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchResourcesTagsAsync.request)),
    switchMap(action =>
      from(api.community.fetchResourcesTags(action.payload.communityId, action.payload.bearer)).pipe(
        map(fetchResourcesTagsAsync.success),
        catchError(error => handleError(error, fetchResourcesTagsAsync)),
      ),
    ),
  );

export const createResourceEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createResourceAsync.request)),
    switchMap(action =>
      from(
        api.community.createResource(
          action.payload.resource,
          action.payload.bearer,
          action.payload.entityId,
          action.payload.paragraphId,
          action.payload.file,
          action.payload.isCommunityResource,
          action.payload.iconFile,
        ),
      ).pipe(
        map(createResourceAsync.success),
        catchError(error => handleError(error, createResourceAsync)),
      ),
    ),
  );

export const createResourcesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createResourcesAsync.request)),
    switchMap(action =>
      from(
        api.community.createResources(
          action.payload.resources,
          action.payload.bearer,
          action.payload.entityId,
          action.payload.paragraphId,
          action.payload.files,
          action.payload.isCommunityResource,
          action.payload.icons,
        ),
      ).pipe(
        map(createResourcesAsync.success),
        catchError(error => handleError(error, createResourcesAsync)),
      ),
    ),
  );

export const deleteResourceEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteResourceAsync.request)),
    switchMap(action =>
      from(
        api.community.deleteResource(
          action.payload.resourceId,
          action.payload.entityId,
          action.payload.bearer,
          action.payload.isCommunityResource,
        ),
      ).pipe(
        map(deleteResourceAsync.success),
        catchError(error => handleError(error, deleteResourceAsync)),
      ),
    ),
  );

export const fetchCommunityProjectsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProjectsAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityProjects(
          action.payload.communityId,
          action.payload.bearer,
          action.payload.sorting,
          action.payload.filtering,
          action.payload.skip,
          action.payload.take,
        ),
      ).pipe(
        map(fetchCommunityProjectsAsync.success),
        catchError(error => handleError(error, fetchCommunityProjectsAsync)),
      ),
    ),
  );

export const fetchCommunityRequestsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityRequestsAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityRequests(
          action.payload.communityId,
          action.payload.bearer,
          action.payload.sorting,
          action.payload.filtering,
          action.payload.skip,
          action.payload.take,
        ),
      ).pipe(
        map(fetchCommunityRequestsAsync.success),
        catchError(error => handleError(error, fetchCommunityRequestsAsync)),
      ),
    ),
  );

export const fetchAllCommunityRequestsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchAllCommunityRequestsAsync.request)),
    switchMap(action =>
      from(api.community.fetchAllCommunityRequests(action.payload.communityId, action.payload.bearer)).pipe(
        map(fetchAllCommunityRequestsAsync.success),
        catchError(error => handleError(error, fetchAllCommunityRequestsAsync)),
      ),
    ),
  );

export const updateCommunicyRequestTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateCommunityRequestTagsAsync.request)),
    switchMap(action =>
      from(
        api.community.updateRequestTagsById(
          action.payload.bearer,
          action.payload.id,
          action.payload.tags,
          action.payload.communityId,
        ),
      ).pipe(
        map(updateCommunityRequestTagsAsync.success),
        catchError(error => handleError(error, updateCommunityRequestTagsAsync)),
      ),
    ),
  );

export const fetchCommunityDiscussionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityDiscussionsAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityDiscussions(
          action.payload.communityId,
          action.payload.bearer,
          action.payload.sorting,
          action.payload.filtering,
          action.payload.skip,
          action.payload.take,
        ),
      ).pipe(
        map(fetchCommunityDiscussionsAsync.success),
        catchError(error => handleError(error, fetchCommunityDiscussionsAsync)),
      ),
    ),
  );

export const saveDiscussionTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveDiscussionTagsAsync.request)),
    switchMap(action =>
      from(
        api.discussion.saveDiscussionTags(
          action.payload.bearer,
          action.payload.discussionId,
          action.payload.discussionType,
          action.payload.communityId,
          action.payload.tags,
        ),
      ).pipe(
        map(saveDiscussionTagsAsync.success),
        catchError(error => handleError(error, saveDiscussionTagsAsync)),
      ),
    ),
  );

export const fetchDiscussionTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchDiscussionTagsAsync.request)),
    switchMap(action =>
      from(api.discussion.fetchDiscussionTags(action.payload.communityId, action.payload.bearer)).pipe(
        map(fetchDiscussionTagsAsync.success),
        catchError(error => handleError(error, fetchDiscussionTagsAsync)),
      ),
    ),
  );

export const fetchCommunityRequestsTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityRequestsTagsAsync.request)),
    switchMap(action =>
      from(api.community.fetchRequestsTags(action.payload.communityId, action.payload.bearer)).pipe(
        map(fetchCommunityRequestsTagsAsync.success),
        catchError(error => handleError(error, fetchCommunityRequestsTagsAsync)),
      ),
    ),
  );

export const acceptCommunityProjectEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(acceptCommunityProjectAsync.request)),
    switchMap(action =>
      from(
        api.community.acceptProject(action.payload.communityId, action.payload.bearer, action.payload.projectId),
      ).pipe(
        map(acceptCommunityProjectAsync.success),
        catchError(error => handleError(error, acceptCommunityProjectAsync)),
      ),
    ),
  );

export const fetchCommunityProjectsCountriesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProjectsCountriesAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityProjectsCountries(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityProjectsCountriesAsync.success),
        catchError(error => handleError(error, fetchCommunityProjectsCountriesAsync)),
      ),
    ),
  );

export const updateCommunityCircleRequestEpic: EpicFunction = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateCommunityCircleAsync.request)),
    map(act => act.payload),
    switchMap(({ communityId, circleId, name, permission }) =>
      from(
        updateCommunityCircle(accountSessionBearerSelector(state$.value), communityId, circleId, name, permission),
      ).pipe(
        map(updateCommunityCircleAsync.success),
        catchError(error => handleError(error, updateCommunityCircleAsync)),
      ),
    ),
  );

export const fetchCommunityProjectsCategoriesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProjectsCategoriesAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityProjectsCategories(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityProjectsCategoriesAsync.success),
        catchError(error => handleError(error, fetchCommunityProjectsCategoriesAsync)),
      ),
    ),
  );

export const fetchCommunityProjectsStagesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProjectsStagesAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityProjectsStages(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityProjectsStagesAsync.success),
        catchError(error => handleError(error, fetchCommunityProjectsStagesAsync)),
      ),
    ),
  );

export const fetchCommunityProjectsSDGsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProjectsSDGsAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityProjectsSDGs(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityProjectsSDGsAsync.success),
        catchError(error => handleError(error, fetchCommunityProjectsSDGsAsync)),
      ),
    ),
  );

export const fetchCOmmunityProjectsTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProjectsTagsAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityProjectsTags(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityProjectsTagsAsync.success),
        catchError(error => handleError(error, fetchCommunityProjectsTagsAsync)),
      ),
    ),
  );

export const saveCommunityProjectsTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveCommunityProjectsTagsAsync.request)),
    switchMap(action =>
      from(
        api.community.saveCommunityProjectsTags(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.communityId,
          action.payload.tags,
        ),
      ).pipe(
        map(saveCommunityProjectsTagsAsync.success),
        catchError(error => handleError(error, saveCommunityProjectsTagsAsync)),
      ),
    ),
  );

export const denyCommunityProjectEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(denyCommunityProjectAsync.request)),
    switchMap(action =>
      from(api.community.denyProject(action.payload.communityId, action.payload.bearer, action.payload.projectId)).pipe(
        map(denyCommunityProjectAsync.success),
        catchError(error => handleError(error, denyCommunityProjectAsync)),
      ),
    ),
  );

export const fetchResourceCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchResourceCirclesAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchResourceCircles(
          action.payload.bearer,
          action.payload.entityId,
          action.payload.isCommunityResource,
          action.payload?.resourceId,
        ),
      ).pipe(
        map(fetchResourceCirclesAsync.success),
        catchError(error => handleError(error, fetchResourceCirclesAsync)),
      ),
    ),
  );

export const setAllowTasksEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setAllowTasksAsync.request)),
    switchMap(action =>
      from(
        api.community.setAllowTasks(action.payload.bearer, action.payload.communityId, action.payload.allowTasks),
      ).pipe(
        map(setAllowTasksAsync.success),
        catchError(error => handleError(error, setAllowTasksAsync)),
      ),
    ),
  );

export const removeUserFromCommunityEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(removeUserFromCommunityAsync.request)),
    switchMap(action =>
      from(
        api.community.removeUserFromCommunity(action.payload.bearer, action.payload.communityId, action.payload.userId),
      ).pipe(
        map(removeUserFromCommunityAsync.success),
        catchError(error => handleError(error, removeUserFromCommunityAsync)),
      ),
    ),
  );

export const fetchCommunityProgressTrackingEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProgressTrackingAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityProgressTracking(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityProgressTrackingAsync.success),
        catchError(error => of(fetchCommunityProgressTrackingAsync.failure(error.message))),
      ),
    ),
  );

export const searchCommunityProgressTrackingEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(searchCommunityProgressTrackingAsync.request)),
    switchMap(action =>
      from(
        api.community.searchCommunityProgressTracking(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.concept,
          action.payload.categoryId,
          action.payload.countryId,
          action.payload.sustainableDevelopmentGoalsIds,
          action.payload.tags,
        ),
      ).pipe(
        map(searchCommunityProgressTrackingAsync.success),
        catchError(error => handleError(error, searchCommunityProgressTrackingAsync)),
      ),
    ),
  );

export const fetchKPIEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchKPIAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchKPI(action.payload.bearer, action.payload.methodologyId, action.payload.communityId),
      ).pipe(
        map(fetchKPIAsync.success),
        catchError(error => of(fetchKPIAsync.failure(error.message))),
      ),
    ),
  );

export const upsertKPIEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(upsertKPIAsync.request)),
    switchMap(action =>
      from(api.community.upsertKPI(action.payload.bearer, action.payload.communityId, action.payload.kpi)).pipe(
        map(upsertKPIAsync.success),
        catchError(error => of(upsertKPIAsync.failure(error.message))),
      ),
    ),
  );

export const deleteKPIEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteKPIAsync.request)),
    switchMap(action =>
      from(api.community.deleteKPI(action.payload.bearer, action.payload.communityId, action.payload.kpi)).pipe(
        map(deleteKPIAsync.success),
        catchError(error => of(deleteKPIAsync.failure(error.message))),
      ),
    ),
  );

export const downloadKPIEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(downloadKPIAsync.request)),
    switchMap(action =>
      from(api.community.downloadKPI(action.payload.bearer, action.payload.methodologyId)).pipe(
        map(downloadKPIAsync.success),
        catchError(error => of(downloadKPIAsync.failure(error.message))),
      ),
    ),
  );

export const fetchCommunityCalendarEventsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityCalendarEventsAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityCalendarEvents(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.dateFrom,
          action.payload.dateTo,
        ),
      ).pipe(
        map(fetchCommunityCalendarEventsAsync.success),
        catchError(error => handleError(error, fetchCommunityCalendarEventsAsync)),
      ),
    ),
  );

export const fetchCommunityCalendarEventDetailsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityCalendarEventDetailsAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityCalendarEventDetails(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.eventId,
        ),
      ).pipe(
        map(fetchCommunityCalendarEventDetailsAsync.success),
        catchError(error => of(fetchCommunityCalendarEventDetailsAsync.failure(error.message))),
      ),
    ),
  );

export const deleteCommunityCalendarEventEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteCommunityCalendarEventAsync.request)),
    switchMap(action =>
      from(
        api.community.deleteCommunityCalendarEvent(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.eventId,
        ),
      ).pipe(
        map(deleteCommunityCalendarEventAsync.success),
        catchError(error => of(deleteCommunityCalendarEventAsync.failure(error.message))),
      ),
    ),
  );

export const upsertCommunityCalendarEventEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(upsertCommunityCalendarEventAsync.request)),
    switchMap(action =>
      from(
        api.community.upsertCommunityCalendarEvent(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.eventToSave,
        ),
      ).pipe(
        map(upsertCommunityCalendarEventAsync.success),
        catchError(error => of(upsertCommunityCalendarEventAsync.failure(error.message))),
      ),
    ),
  );

export const upsertCommunityReportEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(upsertCommunityReportAsync.request)),
    switchMap(action =>
      from(
        api.community.upsertCommunityReport(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.reportToSave,
        ),
      ).pipe(
        map(upsertCommunityReportAsync.success),
        tap(() => {
          showSuccessMessage('Report added successfully');
        }),
        catchError(error => of(upsertCommunityReportAsync.failure(error.message))),
      ),
    ),
  );

export const deleteCommunityReportEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteCommunityReportAsync.request)),
    switchMap(action =>
      from(
        api.community.deleteStatisticsReport(action.payload.bearer, action.payload.communityId, action.payload.id),
      ).pipe(
        map(deleteCommunityReportAsync.success),
        tap(() => {
          showSuccessMessage('Report deleted successfully');
        }),
        catchError(error => of(deleteCommunityReportAsync.failure(error.message))),
      ),
    ),
  );

export const fetchCommunityReportsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityReportsAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityReports(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityReportsAsync.success),
        catchError(error => of(fetchCommunityReportsAsync.failure(error.message))),
      ),
    ),
  );

export const fetchCommunityProjectFormEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityProjectFormAsync.request)),
    switchMap(action =>
      from(api.projectForm.fetchProjectForm(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityProjectFormAsync.success),
        catchError(error => of(fetchCommunityProjectFormAsync.failure(error.message))),
      ),
    ),
  );

export const updateCommunityProjectFormEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateCommunityProjectFormAsync.request)),
    switchMap(action =>
      from(
        api.projectForm.saveProjectForm(action.payload.bearer, action.payload.communityId, action.payload.form),
      ).pipe(
        map(updateCommunityProjectFormAsync.success),
        catchError(error => of(updateCommunityProjectFormAsync.failure(error.message))),
      ),
    ),
  );

export const fetchCommunityMembersByCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityMembersByCircleAsync.request)),
    switchMap(action =>
      from(
        api.community.fetchCommunityMembersByCircle(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.circleId,
          action.payload.skip,
          action.payload.take,
        ),
      ).pipe(
        map(fetchCommunityMembersByCircleAsync.success),
        catchError(error => handleError(error, fetchCommunityMembersByCircleAsync)),
      ),
    ),
  );

export const editCommunityTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(editCommunityTagsAsync.request)),
    switchMap(action =>
      from(
        api.community.editCommunityTags(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.tagsList,
          action.payload.tagType,
        ),
      ).pipe(
        map(editCommunityTagsAsync.success),
        catchError(error => handleError(error, editCommunityTagsAsync)),
      ),
    ),
  );

export const deleteCommunityTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteCommunityTagsAsync.request)),
    switchMap(action =>
      from(
        api.community.deleteCommunityTags(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.tagsList,
          action.payload.tagType,
        ),
      ).pipe(
        map(deleteCommunityTagsAsync.success),
        catchError(error => handleError(error, deleteCommunityTagsAsync)),
      ),
    ),
  );

export const editCommunityProjectTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(editCommunityProjectTagsAsync.request)),
    switchMap(action =>
      from(
        api.community.editCommunityProjectTags(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.tagsList,
        ),
      ).pipe(
        map(editCommunityProjectTagsAsync.success),
        catchError(error => handleError(error, editCommunityProjectTagsAsync)),
      ),
    ),
  );

export const deleteCommunityProjectTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteCommunityProjectTagsAsync.request)),
    switchMap(action =>
      from(
        api.community.deleteCommunityProjectTags(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.tags,
        ),
      ).pipe(
        map(deleteCommunityProjectTagsAsync.success),
        catchError(error => handleError(error, deleteCommunityProjectTagsAsync)),
      ),
    ),
  );

export const fetchCommunityMembersTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityMembersTagsAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityMembersTags(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityMembersTagsAsync.success),
        catchError(error => handleError(error, fetchCommunityMembersTagsAsync)),
      ),
    ),
  );

export const saveCommunityMembersTagsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveCommunityMembersTagsAsync.request)),
    switchMap(action =>
      from(
        api.community.saveCommunityMembersTags(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.userId,
          action.payload.userCommunityId,
          action.payload.userTags,
        ),
      ).pipe(
        map(saveCommunityMembersTagsAsync.success),
        catchError(error => handleError(error, saveCommunityMembersTagsAsync)),
      ),
    ),
  );

export const updateMethodologyNameEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateMethodologyNameAsync.request)),
    switchMap(action =>
      from(renameMethodology(action.payload.bearer, action.payload.id, action.payload.name)).pipe(
        map(updateMethodologyNameAsync.success),
        catchError(error => handleError(error, updateMethodologyNameAsync)),
      ),
    ),
  );

export default {
  fetchCommunityCircleToCirclePermissionRequestSaveEpic,
  fetchCommunityCircleToCirclePermissionRequestEpic,
  removeCommunityInvitationEpic,
  respondCommunityJoinRequestEpic,
  fetchCommunitiesEpic,
  updateCommunityInfoEpic,
  fetchCommunityDetailsEpic,
  fetchCommunityCirclesEpic,
  saveCommunityCircleEpic,
  saveCommunityCirclesEpic,
  deleteCommunityCircleEpic,
  editCommunityCircleEpic,
  fetchCommunityMembersCountriesEpic,
  fetchCommunityMembersSkillsEpic,
  fetchProjectsAwaitingFeedbackEpic,
  fetchProjectsNextParagraphsEpic,
  fetchCommunityMembersInterestsEpic,
  fetchCommunityMembersEpic,
  inviteCommunityMembersEpic,
  fetchCommunityResourcesEpic,
  fetchResourcesTagsEpic,
  createResourceEpic,
  createResourcesEpic,
  deleteResourceEpic,
  fetchAllCommunityRequestsEpic,
  fetchCommunityProjectsEpic,
  fetchCommunityRequestsEpic,
  updateCommunicyRequestTagsEpic,
  fetchCommunityRequestsTagsEpic,
  fetchCommunityDiscussionEpic,
  saveDiscussionTagsEpic,
  fetchDiscussionTagsEpic,
  acceptCommunityProjectEpic,
  fetchCommunityProjectsCountriesEpic,
  fetchCommunityProjectsCategoriesEpic,
  fetchCommunityProjectsStagesEpic,
  fetchCommunityProjectsSDGsEpic,
  fetchCOmmunityProjectsTagsEpic,
  saveCommunityProjectsTagsEpic,
  denyCommunityProjectEpic,
  fetchResourceCirclesEpic,
  setAllowTasksEpic,
  removeUserFromCommunityEpic,
  fetchCommunityProgressTrackingEpic,
  searchCommunityProgressTrackingEpic,
  fetchKPIEpic,
  upsertKPIEpic,
  deleteKPIEpic,
  downloadKPIEpic,
  fetchCommunityCalendarEventsEpic,
  fetchCommunityCalendarEventDetailsEpic,
  deleteCommunityCalendarEventEpic,
  upsertCommunityCalendarEventEpic,
  upsertCommunityReportEpic,
  deleteCommunityReportEpic,
  fetchCommunityReportsEpic,
  fetchCommunityMembersPendingByUserEpic,
  fetchCommunityMembersByCircleEpic,
  updateCommunityCircleRequestEpic,
  fetchCommunityProjectFormEpic,
  updateCommunityProjectFormEpic,
  editCommunityTagsEpic,
  deleteCommunityTagsEpic,
  editCommunityProjectTagsEpic,
  deleteCommunityProjectTagsEpic,
  fetchCommunityMembersTagsEpic,
  saveCommunityMembersTagsEpic,
  updateMethodologyNameEpic,
};
