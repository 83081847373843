import {
  Assignment,
  BusinessModel,
  BusinessModelAssignment,
  BusinessModelSection,
  Project,
  ProjectDueTask,
  ProjectTask,
  Methodology,
  Section,
} from 'redux/types/account';
import { Planner } from '../redux/types/enums';
import moment from 'moment';
import { toast } from '../components/common/toast';
import { gdprConsent, featuresCookieCategories } from './utils';
import {
  MethodologyAction,
  MethodologyActionTypes,
  MethodologyActionCreateSection,
  MethodologyActionDeleteSection,
  MethodologyActionReorderSection,
  MethodologyActionEditSection,
  MethodologyActionCreateAssignment,
  MethodologyActionDeleteAssignment,
  MethodologyActionReorderAssignment,
  MethodologyActionEditAssignment,
} from '../redux/types/methodology-undo';

export const getCalendarTimes = (paragraph: BusinessModelAssignment, project: Project) => {
  const { projectTasks } = project;
  const currentTasks = projectTasks?.filter((task: ProjectTask) => task.paragraph.id === paragraph.id);
  if (currentTasks?.length === 0) {
    return {
      earliestStartTime: null,
      latestEndTime: null,
    };
  }
  const earliestStartTime = currentTasks
    ?.map((task: ProjectTask) => new Date(task.startDate || '2222-2-2').getTime())
    .reduce((a, c) => Math.min(a, c));
  const latestEndTime = currentTasks
    ?.map((task: ProjectTask) => new Date(task.endDate || '1990-9-1').getTime())
    .reduce((a, c) => Math.max(a, c));
  return { earliestStartTime, latestEndTime };
};

export const getDueTask = (
  paragraph: BusinessModelAssignment,
  project: Project,
  taskUnlockRule: Planner | undefined,
): ProjectDueTask => {
  const currentTime = new Date().getTime();
  const { earliestStartTime, latestEndTime } = getCalendarTimes(paragraph, project);
  const isDue = latestEndTime ? currentTime > latestEndTime : false;

  let isLastDay = false;
  if (latestEndTime) isLastDay = currentTime < latestEndTime && (latestEndTime - currentTime) / 1000 / 60 / 60 / 24 < 1;
  const isPublished = !(paragraph.content.trim() === '');
  const dueText = `${isDue ? 'Past Due' : 'Due'} ${(latestEndTime && moment(latestEndTime).format('MMM DD')) || ''}`;
  const publishText = paragraph.isHillary
    ? `Assignment read on ${moment(paragraph.lastUpdated).format('MMM D, YYYY')}`
    : `Published ${moment(paragraph.lastUpdated).format('MMM D, YYYY')}`;
  let canSee = true;
  if (taskUnlockRule === Planner.Cal) canSee = earliestStartTime ? currentTime > earliestStartTime : false;
  return {
    isDue,
    isLastDay,
    isPublished,
    text: isPublished ? publishText : taskUnlockRule === Planner.Cal ? dueText : '',
    exists: true,
    canSee,
  };
};

export const isPublished = (paragraph: BusinessModelAssignment, tasks: ProjectTask[]) => {
  if (paragraph.content.trim().length > 0) return true;
  if (!tasks) return false;
  const status = tasks.find(task => task.paragraph.id === paragraph.id)?.status;
  return status === 2 || status === 3;
};

export const canView = (
  assignment: BusinessModelAssignment | undefined,
  businessModel: BusinessModel | undefined,
  tasks: ProjectTask[] | undefined | null,
  planning: Planner | undefined,
) => {
  // if the assignment is completed it is accesible
  if (!assignment) return false;
  if (planning === undefined) return false;
  if (assignment?.content && assignment.content.trim() !== '') return true;

  // freestyle is always accesible
  if (planning === Planner.Free) return true;
  if (planning === Planner.Stagegate) {
    if (!businessModel) return false;
    const section = businessModel.sections.find((section: BusinessModelSection) => section.id === assignment.sectionId);
    if (!section) return false;
    if (section.isStagegateLocked) {
      return false;
    } else {
      return true;
    }
  }

  if (!businessModel || !tasks) return false;
  const task = tasks.find((task: ProjectTask) => task.paragraph.id === assignment.id);
  if (planning === Planner.Cal) {
    if (!task) return false;
    const startDate = new Date(task.startDate).getTime();
    const currentTime = new Date().getTime();
    return currentTime > startDate;
  } else {
    // for sequential planning we will look only at the assignments from before the current assignment.
    //
    // take all assignments in order. If any previous assignment, before current assignment,
    // is not completed, then the current assignment is not accessible

    for (let iS = 0; iS < businessModel.sections.length; iS++) {
      const section = businessModel.sections[iS];
      for (let iA = 0; iA < section.assignments.length; iA++) {
        if (section.assignments[iA].id === assignment.id) {
          // current assignment
          return true;
        } else {
          // const previousAssignment = section.assignments[iA];
          // if this is not solved, then current assignment is not the first unsolved assignment, so it is not accesible
          // i.e. previousAssignment is the first unsolved assignment, and it should be the visible one (besides already
          // completed assignments)

          // TO BE FIXED
          // if (previousAssignment.content && previousAssignment.content.trim().length === 0) {
          if (!isPublished(section.assignments[iA], tasks)) {
            return false;
          }
        }
      }
    }
  }
};

export const canViewByCalendarPlanning = (
  paragraph: BusinessModelAssignment,
  project: Project,
  taskUnlockRule?: Planner,
): boolean | undefined => {
  if (taskUnlockRule === Planner.Cal) {
    const dueTask = getDueTask(paragraph, project, taskUnlockRule);
    return dueTask.canSee;
  }
  return undefined;
};

export const canViewBySequentialPlanning = (
  paragraph: BusinessModelAssignment,
  project: Project,
  taskUnlockRule?: Planner,
): boolean | undefined => {
  if (taskUnlockRule === Planner.Seq) {
    const newSection = project.businessModel.sections.find((s: BusinessModelSection) => s.id === paragraph.sectionId);
    if (!newSection) return false;
    const canSee = project.businessModel.sections.every((s: BusinessModelSection) => {
      if (s.order < newSection.order)
        return s.assignments.every((assignment: BusinessModelAssignment) =>
          isPublished(assignment, project.projectTasks || []),
        );
      else if (s.order === newSection?.order)
        return s.assignments
          .filter((a: BusinessModelAssignment) => a.order < paragraph.order)
          .every((assignment: BusinessModelAssignment) => isPublished(assignment, project.projectTasks || []));
      else return true;
    });
    return canSee;
  }
  return undefined;
};

export const canViewParagraph = (
  paragraph: BusinessModelAssignment,
  project: Project,
  taskUnlockRule?: Planner,
): boolean => {
  // before setting a new assignment we need to check if it is visible for the user
  // taskUnlockRule = 0 freestyle. Check by community in component
  //                  1 calendar planning
  //                  2 sequential planning
  //

  const canViewParagraphByCalendarPlanning = canViewByCalendarPlanning(paragraph, project, taskUnlockRule);
  if (canViewParagraphByCalendarPlanning !== undefined) {
    return canViewParagraphByCalendarPlanning;
  }

  const canViewParagraphBySequentialPlanning = canViewBySequentialPlanning(paragraph, project, taskUnlockRule);
  if (canViewParagraphBySequentialPlanning !== undefined) {
    return canViewParagraphBySequentialPlanning;
  }

  return true;
};

export const saveAssignmentMissingFieldToast = (assignment: Assignment) => {
  // Returns false if the assignment does not have all fields completed and will toast an error message accordingly.
  let errorMessage = null;
  if (!assignment.name || assignment.name.trim().length === 0) {
    errorMessage = 'You need to complete a name for the assignment';
  }
  /* else if (!assignment.description || assignment.description.trim().length === 0) {
    errorMessage = 'You need to complete a description for the assignment';
  } else if (!assignment.details || assignment.details.trim().length === 0) {
    errorMessage = 'You need to complete details for the assignment';
  } */

  if (errorMessage === null) {
    return true;
  } else {
    toast(errorMessage, { type: 'warning' });
    return false;
  }
};

export const setAssignmentCache = (paragraphId: number, content: string | undefined): void => {
  const assignmentCache = JSON.parse(localStorage.getItem('assignmentCache') || `{}`);
  assignmentCache[paragraphId] = content;
  if (gdprConsent(featuresCookieCategories.assignmentCache)) {
    localStorage.setItem('assignmentCache', JSON.stringify(assignmentCache));
  }
};

export const getAssignmentCache = (paragraphId: number): string | undefined => {
  const assignmentCache = JSON.parse(localStorage.getItem('assignmentCache') || `{}`);
  return assignmentCache[paragraphId];
};

export const isAssignmentCached = (paragraphId: number): boolean => {
  return !!getAssignmentCache(paragraphId);
};

export const clearAllAssignmentCache = () => {
  localStorage.removeItem('assignmentCache');
};

const _updateMethodologyOrder = (methodology: Methodology) => {
  return {
    ...methodology,
    sections: methodology.sections.map((section: Section, sectionIndex: number) => ({
      ...section,
      order: sectionIndex,
      assignments: section.assignments.map((assignment: Assignment, assignmentIndex: number) => ({
        ...assignment,
        order: assignmentIndex,
        sectionId: section.id,
      })),
    })),
  };
};

const _applyCreateSectionToMethodology = (methodology: Methodology, action: MethodologyActionCreateSection) => {
  const newSection: Section = {
    id: action.id,
    name: action.name,
    order: action.order,
    logo: action.logo,
    methodologyId: methodology.id,
    isLean: false,
    isActive: true,
    assignments: [],
  };
  const newMethodology = {
    ...methodology,
    sections: [...methodology.sections.slice(0, action.order), newSection, ...methodology.sections.slice(action.order)],
  };
  return _updateMethodologyOrder(newMethodology);
};

const _applyDeleteSectionToMethodology = (methodology: Methodology, action: MethodologyActionDeleteSection) => {
  const newMethodology = {
    ...methodology,
    sections: methodology.sections.filter((s: Section, index: number) => index !== action.order),
  };
  return _updateMethodologyOrder(newMethodology);
};

const _applyDeleteAssignmentToMethodology = (methodology: Methodology, action: MethodologyActionDeleteAssignment) => {
  const newMethodology = {
    ...methodology,
    sections: methodology.sections.map((section: Section, sectionIndex: number) => {
      if (sectionIndex !== action.sectionOrder) return section;
      return {
        ...section,
        assignments: section.assignments.filter(
          (assignment: Assignment, assignmentIndex: number) => assignmentIndex !== action.order,
        ),
      };
    }),
  };
  return _updateMethodologyOrder(newMethodology);
};

const _applyReorderSectionToMethodology = (methodology: Methodology, action: MethodologyActionReorderSection) => {
  const newSections = [...methodology.sections];
  const section = newSections.splice(action.oldOrder, 1)[0];
  newSections.splice(action.newOrder, 0, section);

  const newMethodology = {
    ...methodology,
    sections: newSections,
  };
  return _updateMethodologyOrder(newMethodology);
};

const _applyEditSectionToMethodology = (methodology: Methodology, action: MethodologyActionEditSection) => {
  const newMethodology = {
    ...methodology,
    sections: methodology.sections.map((section: Section, sectionIndex: number) => {
      if (sectionIndex !== action.order) return section;
      return {
        ...section,
        name: action.name,
        logo: action.logo,
      };
    }),
  };
  return _updateMethodologyOrder(newMethodology);
};

const _applyReorderAssignmentToMethodology = (methodology: Methodology, action: MethodologyActionReorderAssignment) => {
  const sourceSection = methodology.sections[action.oldSectionOrder];
  const assignment = sourceSection.assignments[action.oldOrder];
  const newSections = methodology.sections
    .map((section: Section, sectionIndex: number) => {
      if (sectionIndex !== action.oldSectionOrder) return section;
      return {
        ...section,
        assignments: section.assignments.filter(
          (assignment: Assignment, assignmentIndex: number) => assignmentIndex !== action.oldOrder,
        ),
      };
    })
    .map((section: Section, sectionIndex: number) => {
      if (sectionIndex !== action.newSectionOrder) return section;
      return {
        ...section,
        assignments: [
          ...section.assignments.slice(0, action.newOrder),
          { ...assignment, sectionId: section.id, sectionName: section.name },
          ...section.assignments.slice(action.newOrder),
        ],
      };
    });
  const newMethodology = {
    ...methodology,
    sections: newSections,
  };
  return _updateMethodologyOrder(newMethodology);
};

const _applyCreateAssignmentToMethodology = (methodology: Methodology, action: MethodologyActionCreateAssignment) => {
  const newAssignment: Assignment = {
    id: action.id,
    name: action.name,
    order: action.order,
    description: action.description,
    details: action.details,
    template: action.template,
    sectionId: 0,
    methodologyId: methodology.id,
    privacyLevel: action.privacyLevel,
    sectionName: '',
    isHillary: action.isHillary,
    taskSendReminder: false,
  };
  const newMethodology = {
    ...methodology,
    sections: methodology.sections.map((section: Section, sectionIndex: number) => {
      if (sectionIndex !== action.sectionOrder) return section;
      return {
        ...section,
        assignments: [
          ...section.assignments.slice(0, action.order),
          { ...newAssignment, sectionId: section.id, sectionName: section.name },
          ...section.assignments.slice(action.order),
        ],
      };
    }),
  };
  return _updateMethodologyOrder(newMethodology);
};

const _applyEditAssignmentToMethodology = (methodology: Methodology, action: MethodologyActionEditAssignment) => {
  const newMethodology = {
    ...methodology,
    sections: methodology.sections.map((section: Section, sectionIndex: number) => {
      if (sectionIndex !== action.sectionOrder) return section;
      return {
        ...section,
        assignments: section.assignments.map((assignment: Assignment, assignmentIndex: number) => {
          if (assignmentIndex !== action.order) return assignment;
          return {
            ...assignment,
            name: action.name,
            description: action.description,
            details: action.details,
            template: action.template,
          };
        }),
      };
    }),
  };
  return _updateMethodologyOrder(newMethodology);
};

export const applyActionToMethodology = (methodology: Methodology, action: MethodologyAction): Methodology => {
  switch (action.type) {
    case MethodologyActionTypes.CREATE_SECTION:
      return _applyCreateSectionToMethodology(methodology, action);

    case MethodologyActionTypes.DELETE_SECTION:
      return _applyDeleteSectionToMethodology(methodology, action);

    case MethodologyActionTypes.REORDER_SECTION:
      return _applyReorderSectionToMethodology(methodology, action);

    case MethodologyActionTypes.EDIT_SECTION:
      return _applyEditSectionToMethodology(methodology, action);

    case MethodologyActionTypes.CREATE_ASSIGNMENT:
      return _applyCreateAssignmentToMethodology(methodology, action);

    case MethodologyActionTypes.DELETE_ASSIGNMENT:
      return _applyDeleteAssignmentToMethodology(methodology, action);

    case MethodologyActionTypes.REORDER_ASSIGNMENT:
      return _applyReorderAssignmentToMethodology(methodology, action);

    case MethodologyActionTypes.EDIT_ASSIGNMENT:
      return _applyEditAssignmentToMethodology(methodology, action);

    default:
      return methodology;
  }
};
