import {
  ApplicationDefinition,
  ApplicationEntry,
  FormQuestionAnswer,
  ApplicationScore,
  Comment,
  Country,
  Interest,
  SDG,
  NewProject,
  FilterAnswer,
  ApplicationFilters,
} from 'redux/types/account';
import { ApplicationStatus } from '../types/enums';
import { createAsyncAction } from 'typesafe-actions';

export const saveApplicationDefinitionAsync = createAsyncAction(
  'SAVE_APPLICATION_DEFINITION_REQUEST',
  'SAVE_APPLICATION_DEFINITION_SUCCESS',
  'SAVE_APPLICATION_DEFINITION_FAILURE',
  'SAVE_APPLICATION_DEFINITION_IDLE',
)<
  { bearer: string; applicationDefinition: ApplicationDefinition; communityId: number },
  { communityId: number; applicationDefinition: ApplicationDefinition },
  string,
  null
>();

export const fetchCommunityApplicationDefinitionsAsync = createAsyncAction(
  'FETCH_COMMUNITY_APPLICATION_DEFINITIONS_REQUEST',
  'FETCH_COMMUNITY_APPLICATION_DEFINITIONS_SUCCESS',
  'FETCH_COMMUNITY_APPLICATION_DEFINITIONS_FAILURE',
  'FETCH_COMMUNITY_APPLICATION_DEFINITIONS_IDLE',
)<
  { bearer: string; communityId: number },
  { communityId: number; applicationDefinitions: ApplicationDefinition[] },
  string,
  null
>();

export const deleteApplicationDefinitionAsync = createAsyncAction(
  'DELETE_APPLICATION_DEFINITION_REQUEST',
  'DELETE_APPLICATION_DEFINITION_SUCCESS',
  'DELETE_APPLICATION_DEFINITION_FAILURE',
  'DELETE_APPLICATION_DEFINITION_IDLE',
)<
  { bearer: string; communityId: number; applicationDefinitionId: number },
  { communityId: number; applicationDefinitionId: number },
  string,
  null
>();

export const fetchApplicationDefinitionOverviewByIdAsync = createAsyncAction(
  'FETCH_APPLICATION_DEFINITION_OVERVIEW_BY_ID_REQUEST',
  'FETCH_APPLICATION_DEFINITION_OVERVIEW_BY_ID_SUCCESS',
  'FETCH_APPLICATION_DEFINITION_OVERVIEW_BY_ID_FAILURE',
  'FETCH_APPLICATION_DEFINITION_OVERVIEW_BY_ID_IDLE',
)<
  { bearer?: string; applicationDefinitionId: number },
  { applicationDefinition: ApplicationDefinition },
  string,
  null
>();

export const fetchApplicationDefinitionPreviewByidAsync = createAsyncAction(
  'FETCH_APPLICATION_DEFINITION_PREVIEW_BY_ID_REQUEST',
  'FETCH_APPLICATION_DEFINITION_PREVIEW_BY_ID_SUCCESS',
  'FETCH_APPLICATION_DEFINITION_PREVIEW_BY_ID_FAILURE',
  'FETCH_APPLICATION_DEFINITION_PREVIEW_BY_ID_IDLE',
)<{ applicationDefinitionId: number }, { applicationDefinition: ApplicationDefinition }, string, null>();

export const fetchApplicationDefinitionByIdAsync = createAsyncAction(
  'FETCH_APPLICATION_DEFINITION_BY_ID_REQUEST',
  'FETCH_APPLICATION_DEFINITION_BY_ID_SUCCESS',
  'FETCH_APPLICATION_DEFINITION_BY_ID_FAILURE',
  'FETCH_APPLICATION_DEFINITION_BY_ID_IDLE',
)<
  { bearer: string; applicationDefinitionId: number },
  { applicationDefinition: ApplicationDefinition },
  string,
  null
>();

export const saveApplicationEntryAsync = createAsyncAction(
  'SAVE_APPLICATION_ENTRY_REQUEST',
  'SAVE_APPLICATION_ENTRY_SUCCESS',
  'SAVE_APPLICATION_ENTRY_FAILURE',
  'SAVE_APPLICATION_ENTRY_IDLE',
)<
  { bearer: string; applicationEntry: ApplicationEntry; submitApplication: boolean },
  { applicationEntry: ApplicationEntry },
  string,
  null
>();

export const fetchApplicationEntryByIdAsync = createAsyncAction(
  'FETCH_APPLICATION_ENTRY_BY_ID_REQUEST',
  'FETCH_APPLICATION_ENTRY_BY_ID_SUCCESS',
  'FETCH_APPLICATION_ENTRY_BY_ID_FAILURE',
  'FETCH_APPLICATION_ENTRY_BY_ID_IDLE',
)<{ bearer: string; applicationEntryId: number }, { applicationEntry: ApplicationEntry }, string, null>();

export const fetchCurrentUserApplicationsAsync = createAsyncAction(
  'FETCH_CURRENT_USER_APPLICATIONS_REQUEST',
  'FETCH_CURRENT_USER_APPLICATIONS_SUCCESS',
  'FETCH_CURRENT_USER_APPLICATIONS_FAILURE',
  'FETCH_CURRENT_USER_APPLICATIONS_IDLE',
)<{ bearer: string }, { applicationEntries: ApplicationEntry[] }, string, null>();

export const fetchApplicationEntriesByDefinitionIdAsync = createAsyncAction(
  'FETCH_APPLICATION_ENTRIES_BY_DEFINITION_ID_REQUEST',
  'FETCH_APPLICATION_ENTRIES_BY_DEFINITION_ID_SUCCESS',
  'FETCH_APPLICATION_ENTRIES_BY_DEFINITION_ID_FAILURE',
  'FETCH_APPLICATION_ENTRIES_BY_DEFINITION_ID_IDLE',
)<
  {
    bearer: string;
    applicationDefinitionId: number;
    communityId: number;
    filtering: FilterAnswer;
    sorting: { orderBy: number | undefined };
    skip: number;
    take: number;
    concept: string | null;
    status: ApplicationStatus | null;
  },
  {
    communityId: number;
    applicationDefinitionId: number;
    applicationEntries: ApplicationEntry[];
    count: number;
    applicationDefinitionFilters: ApplicationFilters;
  },
  string,
  null
>();

export const fetchEvaluationAnswersByApplicationEntryIdAsync = createAsyncAction(
  'FETCH_EVALUATION_ANSWERS_BY_APPLICATION_ENTRY_ID_REQUEST',
  'FETCH_EVALUATION_ANSWERS_BY_APPLICATION_ENTRY_ID_SUCCESS',
  'FETCH_EVALUATION_ANSWERS_BY_APPLICATION_ENTRY_ID_FAILURE',
  'FETCH_EVALUATION_ANSWERS_BY_APPLICATION_ENTRY_ID_IDLE',
)<
  { bearer: string; communityId: number; applicationDefinitionId: number; applicationEntryId: number },
  {
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    formAnswers: FormQuestionAnswer[];
  },
  string,
  null
>();

export const saveApplicationEntryTagsAsync = createAsyncAction(
  'SAVE_APPLICATION_ENTRY_TAGS_REQUEST',
  'SAVE_APPLICATION_ENTRY_TAGS_SUCCESS',
  'SAVE_APPLICATION_ENTRY_TAGS_FAILURE',
  'SAVE_APPLICATION_ENTRY_TAGS_IDLE',
)<
  { bearer: string; communityId: number; applicationDefinitionId: number; applicationEntryId: number; tags: string[] },
  { communityId: number; applicationDefinitionId: number; applicationEntryId: number; tags: string[] },
  string,
  null
>();

export const saveApplicationEntryScoresAsync = createAsyncAction(
  'SAVE_APPLICATION_ENTRY_SCORE_REQUEST',
  'SAVE_APPLICATION_ENTRY_SCORE_SUCCESS',
  'SAVE_APPLICATION_ENTRY_SCORE_FAILURE',
  'SAVE_APPLICATION_ENTRY_SCORE_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    scores: ApplicationScore[];
  },
  { communityId: number; applicationDefinitionId: number; applicationEntryId: number; scores: ApplicationScore[] },
  string,
  null
>();

export const fetchApplicationEntryScoresAsync = createAsyncAction(
  'FETCH_APPLICATION_ENTRY_SCORES_REQUEST',
  'FETCH_APPLICATION_ENTRY_SCORES_SUCCESS',
  'FETCH_APPLICATION_ENTRY_SCORES_FAILURE',
  'FETCH_APPLICATION_ENTRY_SCORES_IDLE',
)<
  { bearer: string; communityId: number; applicationDefinitionId: number; applicationEntryId: number },
  {
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    scores: ApplicationScore[];
  },
  string,
  null
>();

export const updateApplicationEntryStatusAsync = createAsyncAction(
  'UPDATE_APPLICATION_ENTRY_STATUS_REQUEST',
  'UPDATE_APPLICATION_ENTRY_STATUS_SUCCESS',
  'UPDATE_APPLICATION_ENTRY_STATUS_FAILURE',
  'UPDATE_APPLICATION_ENTRY_STATUS_IDLE',
)<
  {
    bearer: string;
    communityId?: number;
    applicationDefinitionId?: number;
    applicationEntryIds: number[];
    status: ApplicationStatus;
    methodologyId?: number;
    newProjects?: (NewProject | undefined)[];
    applicantEmails?: (string | undefined)[];
  },
  { communityId?: number; applicationDefinitionId?: number; applicationEntryIds: number[]; status: ApplicationStatus },
  string,
  null
>();

export const deleteApplicationEntriesAsync = createAsyncAction(
  'DELETE_APPLICATION_ENTRY_REQUEST',
  'DELETE_APPLICATION_ENTRY_SUCCESS',
  'DELETE_APPLICATION_ENTRY_FAILURE',
  'DELETE_APPLICATION_ENTRY_IDLE',
)<
  {
    bearer: string;
    applicationEntryIds: number[];
    communityId: number;
    applicationDefinitionId: number;
  },
  {
    applicationEntryIds: number[];
    communityId: number;
    applicationDefinitionId: number;
  },
  string,
  null
>();

export const fetchApplicationFiltersAsync = createAsyncAction(
  'FETCH_APPLICATION_FILTERS_REQUEST',
  'FETCH_APPLICATION_FILTERS_SUCCESS',
  'FETCH_APPLICATION_FILTERS_FAILURE',
  'FETCH_APPLICATION_FILTERS_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    applicationDefinitionId: number;
  },
  {
    communityId: number;
    applicationDefinitionId: number;
    tags: string[];
    countries: Country[];
    industries: Interest[];
    sdgs: SDG[];
  },
  string,
  null
>();

export const saveCommentToApplicationEntryAsync = createAsyncAction(
  'SAVE_COMMENT_TO_APPLICATION_ENTRY_REQUEST',
  'SAVE_COMMENT_TO_APPLICATION_ENTRY_SUCCESS',
  'SAVE_COMMENT_TO_APPLICATION_ENTRY_FAILURE',
  'SAVE_COMMENT_TO_APPLICATION_ENTRY_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    parentId: number;
    content: string;
    id: number;
  },
  {
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    parentId: number;
    comment: Comment;
  },
  string,
  null
>();

export const fetchApplicationEntryCommentsAsync = createAsyncAction(
  'FETCH_APPLICATION_ENTRY_COMMENTS_REQUEST',
  'FETCH_APPLICATION_ENTRY_COMMENTS_SUCCESS',
  'FETCH_APPLICATION_ENTRY_COMMENTS_FAILURE',
  'FETCH_APPLICATION_ENTRY_COMMENTS_IDLE',
)<
  { bearer: string; communityId: number; applicationDefinitionId: number; applicationEntryId: number },
  { communityId: number; applicationDefinitionId: number; applicationEntryId: number; comments: Comment[] },
  string,
  null
>();

export const deleteApplicationEntryCommentAsync = createAsyncAction(
  'DELETE_APPLICATION_ENTRY_COMMENT_REQUEST',
  'DELETE_APPLICATION_ENTRY_COMMENT_SUCCESS',
  'DELETE_APPLICATION_ENTRY_COMMENT_FAILURE',
  'DELETE_APPLICATION_ENTRY_COMMENT_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    commentId: number;
  },
  { communityId: number; applicationDefinitionId: number; applicationEntryId: number; commentId: number },
  string,
  null
>();

export const likeApplicationEntryCommentAsync = createAsyncAction(
  'LIKE_APPLICATION_ENTRY_COMMENT_REQUEST',
  'LIKE_APPLICATION_ENTRY_COMMENT_SUCCESS',
  'LIKE_APPLICATION_ENTRY_COMMENT_FAILURE',
  'LIKE_APPLICATION_ENTRY_COMMENT_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    commentId: number;
    isLike: boolean;
  },
  {
    communityId: number;
    applicationDefinitionId: number;
    applicationEntryId: number;
    commentId: number;
    isLike: boolean;
  },
  string,
  null
>();

export default {
  saveApplicationDefinitionAsync,
  fetchCommunityApplicationDefinitionsAsync,
  deleteApplicationDefinitionAsync,
  saveApplicationEntryAsync,
  fetchApplicationEntryByIdAsync,
  fetchCurrentUserApplicationsAsync,
  fetchApplicationEntriesByDefinitionIdAsync,
  fetchEvaluationAnswersByApplicationEntryIdAsync,
  saveApplicationEntryTagsAsync,
  saveApplicationEntryScoresAsync,
  fetchApplicationEntryScoresAsync,
  updateApplicationEntryStatusAsync,
  fetchApplicationFiltersAsync,
  fetchApplicationEntryCommentsAsync,
  deleteApplicationEntryCommentAsync,
  likeApplicationEntryCommentAsync,
  deleteApplicationEntriesAsync,
};
