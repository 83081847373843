import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { acceptRequestInvitation, declineRequestInvitation } from '../../services/api/project';
import { fetchCommunityDetails } from '../../services/api/community';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Community } from 'redux/types/account';

import { SecondaryButton } from '../styled-components/common';
import { fetchCommunitiesAsync } from '../../redux/actions/community';
import { Center, Row } from './invitation-response';
import { StyledButton } from 'primitives/Button/style';

const mapStateToProps = (state: RootState) => ({
  userId: state.account.details.user.id,
  userDetails: state.account.details.user,
  literals: state.literals,
});

const mapDispatchToProps = {
  fetchCommunities: fetchCommunitiesAsync.request,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  bearer: string;
  responseType: string;
  fullScreen?: boolean;
}

const InvitationResponse: React.FC<Props> = ({
  fullScreen,
  bearer,
  userId,
  responseType,
  literals,
  fetchCommunities,
  userDetails,
}) => {
  const useQuery = (): URLSearchParams => new URLSearchParams(useLocation().search);
  const queryParams: URLSearchParams = useQuery();
  const communityId: string | null = queryParams.get('communityId') || queryParams.get('comunityId');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [entityName, setEntityName] = useState<string>('');
  const [isFetchingName, setIsFetchingName] = useState<boolean>(true);
  const [entityIsFound, setEntityIsFound] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!communityId) return;
    fetchCommunityDetails(Number(communityId), bearer)
      .then((response: Community) => {
        setEntityName(response.name || '');
        setIsFetchingName(false);
      })
      .catch(errorFetch);
    if (responseType === 'accept') {
      acceptRequestInvitation(bearer, Number(communityId))
        .then(successResponse)
        .then(() => {
          fetchCommunities(bearer);
        })
        .catch(errorResponse);
    } else {
      declineRequestInvitation(bearer, Number(communityId)).then(successResponse).catch(errorResponse);
    }
  }, [bearer]);

  const successResponse = () => {
    setIsLoading(false);
  };

  const errorResponse = () => {
    const numberCommunityId = parseInt(communityId || '');
    if (userDetails.communities.some((community: any) => community.id === numberCommunityId)) {
      setEntityIsFound(true);
    } else {
      setEntityIsFound(false);
    }
    setIsSuccess(false);
    setIsLoading(false);
  };

  const errorFetch = () => {
    setIsFetchingName(false);
  };

  const gotoEntity = () => {
    history.push(`/community/${communityId}/requests`);
  };

  return (
    <>
      {isLoading || isFetchingName ? (
        <div>
          <Dimmer active inverted>
            <Loader inverted>{literals.project_list_loading_tag_message}</Loader>
          </Dimmer>
        </div>
      ) : (
        <div>
          {isSuccess ? (
            <Center fullScreen={fullScreen}>
              <span>
                {responseType === 'accept'
                  ? literals.community_invitation_accept_success_message.replace('{0}', entityName)
                  : literals.community_invitation_decline_success_message.replace('{0}', entityName)}
              </span>

              <Row>
                <SecondaryButton
                  onClick={() => {
                    if (history.length === 2) history.push('/');
                    else history.goBack();
                  }}
                >
                  {literals.global_go_back}
                </SecondaryButton>
                {responseType === 'accept' && (
                  <StyledButton
                    onClick={() => {
                      gotoEntity();
                    }}
                  >
                    {`${literals.community_see_community}`}
                  </StyledButton>
                )}
              </Row>
            </Center>
          ) : (
            <Center fullScreen={fullScreen}>
              <span>
                {entityIsFound
                  ? (literals.invitation_already_accepted_message || '').replace('{0}', entityName)
                  : (literals.invitation_already_declined_message || '').replace('{0}', entityName)}
              </span>
              <Row>
                <SecondaryButton
                  onClick={() => {
                    if (history.length === 2) history.push('/');
                    else history.goBack();
                  }}
                >
                  {literals.global_go_back}
                </SecondaryButton>
                {entityIsFound && (
                  <StyledButton
                    onClick={() => {
                      gotoEntity();
                    }}
                  >
                    {`${literals.community_see_community}`}
                  </StyledButton>
                )}
              </Row>
              {/*
              <span>
                {responseType === 'accept'
                  ? literals.community_invitation_accept_failure_message.replace('{0}', entityName)
                  : literals.community_invitation_decline_failure_message.replace('{0}', entityName)}
              </span>
              <Row>
                <SecondaryButton
                  onClick={() => {
                    if (history.length === 2) history.push('/');
                    else history.goBack();
                  }}
                >
                  {literals.global_go_back}
                </SecondaryButton>
              </Row>
                  */}
            </Center>
          )}
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationResponse);
