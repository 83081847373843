import React, { FC } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

interface SelectedUserProps {
  name: string;
  color?: string;
  onRemoveUser?: (name: string) => void;
  avatar: string;
  isCurrentUser: boolean;
  allowRemovingCurrentUser?: boolean;
}

const SelectedUser: FC<SelectedUserProps> = ({
  name,
  onRemoveUser,
  avatar,
  isCurrentUser,
  allowRemovingCurrentUser,
}) => {
  const handleRemoveClick = () => {
    if (onRemoveUser) {
      onRemoveUser(name);
    }
  };

  return (
    <Container>
      <Avatar src={avatar} />
      <DetailsContainer>
        <PersonName>{name}</PersonName>
      </DetailsContainer>
      {(!isCurrentUser || allowRemovingCurrentUser) && onRemoveUser && (
        <RemoveButton onClick={handleRemoveClick}>
          <CloseIcon style={{ color: 'black', width: 16 }} />
        </RemoveButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const Avatar = styled.img`
  border-radius: 20px;
  width: 28px;
`;

const RemoveButton = styled.button`
  outline: none;
  background: none;
  cursor: pointer;
  margin-top: 4px;
  padding-top: 2px;
`;

const DetailsContainer = styled.div``;

const PersonName = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
`;

export default SelectedUser;
