import {
  Community,
  CommunityMembers,
  CommunityDetails,
  CommunityResources,
  Resource,
  Project,
  Circle,
  CommunityDiscussions,
  Sorting,
  Filtering,
  Country,
  Skill,
  Interest,
  ProjectAwaitingFeedback,
  ProjectNextParagraphs,
  CommunityProgressOverview,
  KpiDefinition,
  ProjectCategory,
  ProjectStage,
  SDG,
  CalendarEvent,
  Report,
  CommunityRequests,
  RequestStatus,
  MentionUser,
  Member,
  Form,
  CommunityProjectsFilterAnswer,
  Tag,
  TagType,
  CommunityProjectsFilters,
  CommunityMembersFilters,
  CommunityRequestsFilters,
} from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';
import { Response } from '../types/enums';
import { AxiosError } from 'axios';
import { EmailInvitationDto } from 'ApiModels';

export const fetchCommunitiesAsync = createAsyncAction(
  'FETCH_COMMUNITIES_REQUEST',
  'FETCH_COMMUNITIES_SUCCESS',
  'FETCH_COMMUNITIES_FAILURE',
  'FETCH_COMMUNITIES_IDLE',
)<string, Community[], string, null>();

export const updateCommunityInfoAsync = createAsyncAction(
  'UPDATE_COMMUNITY_INFO_REQUEST',
  'UPDATE_COMMUNITY_INFO_SUCCESS',
  'UPDATE_COMMUNITY_INFO_FAILURE',
  'UPDATE_COMMUNITY_INFO_IDLE',
)<
  { community: Community; bearer: string; logoFile: File | string; coverFile: File | string },
  Community,
  string,
  null
>();

export const fetchCommunityDetailsAsync = createAsyncAction(
  'FETCH_COMMUNITY_DETAILS_REQUEST',
  'FETCH_COMMUNITY_DETAILS_SUCCESS',
  'FETCH_COMMUNITY_DETAILS_FAILURE',
  'FETCH_COMMUNITY_DETAILS_IDLE',
)<
  { id: number; bearer: string; fetchForTabRenamings?: boolean; silentCall?: boolean },
  CommunityDetails,
  string,
  null
>();

export const fetchCommunityCirclesAsync = createAsyncAction(
  'FETCH_COMMUNITY_CIRCLES_REQUEST',
  'FETCH_COMMUNITY_CIRCLES_SUCCESS',
  'FETCH_COMMUNITY_CIRCLES_FAILURE',
  'FETCH_COMMUNITY_CIRCLES_IDLE',
)<{ communityId: number; bearer: string }, { communityId: number; circles: Circle[] }, string, null>();

export const updateCommunityCircleAsync = createAsyncAction(
  'FETCH_UPDATE_COMMUNITY_CIRCLE_REQUEST',
  'FETCH_UPDATE_COMMUNITY_CIRCLE_SUCCESS',
  'FETCH_UPDATE_COMMUNITY_CIRCLE_FAILURE',
  'FETCH_UPDATE_COMMUNITY_CIRCLE_IDLE',
)<
  { communityId: number; circleId: number; name: string; permission: any },
  { communityId: number; circleId: number; permission: any },
  string,
  null
>();

export const saveCommunityCircleAsync = createAsyncAction(
  'SAVE_COMMUNITY_CIRCLE_REQUEST',
  'SAVE_COMMUNITY_CIRCLE_SUCCESS',
  'SAVE_COMMUNITY_CIRCLE_FAILURE',
  'SAVE_COMMUNITY_CIRCLE_IDLE',
)<{ communityId: number; circle: Circle; bearer: string }, { communityId: number; circle: Circle }, string, null>();

export const saveCommunityCirclesAsync = createAsyncAction(
  'SAVE_COMMUNITY_CIRCLES_REQUEST',
  'SAVE_COMMUNITY_CIRCLES_SUCCESS',
  'SAVE_COMMUNITY_CIRCLES_FAILURE',
  'SAVE_COMMUNITY_CIRCLES_IDLE',
)<
  { communityId: number; circles: Circle[]; bearer: string },
  { communityId: number; circles: Circle[] },
  string,
  null
>();

export const deleteCommunityCircleAsync = createAsyncAction(
  'DELETE_COMMUNITY_CIRCLE_REQUEST',
  'DELETE_COMMUNITY_CIRCLE_SUCCESS',
  'DELETE_COMMUNITY_CIRCLE_FAILURE',
  'DELETE_COMMUNITY_CIRCLE_IDLE',
)<{ communityId: number; bearer: string; circleId: number }, { communityId: number; circleId: number }, string, null>();

export const editCommunityCircleAsync = createAsyncAction(
  'EDIT_COMMUNITY_CIRCLE_REQUEST',
  'EDIT_COMMUNITY_CIRCLE_SUCCESS',
  'EDIT_COMMUNITY_CIRCLE_FAILURE',
  'EDIT_COMMUNITY_CIRCLE_IDLE',
)<
  { communityId: number; bearer: string; circleId: string; userId: string; circleName?: string },
  { communityId: number; circleId: number; userId: number; circleName?: string },
  string,
  null
>();

export const fetchCommunityMembersAsync = createAsyncAction(
  'FETCH_COMMUNITY_MEMBERS_REQUEST',
  'FETCH_COMMUNITY_MEMBERS_SUCCESS',
  'FETCH_COMMUNITY_MEMBERS_FAILURE',
  'FETCH_COMMUNITY_MEMBERS_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    sorting: Sorting;
    filtering: Filtering;
    skip: number;
    take: number;
    fetchAll?: boolean;
  },
  {
    list: CommunityMembers;
    skip: number;
    take: number;
    isCommunityAdmin: boolean;
    totalMembersCount?: number;
    membersCount?: number;
    memberFilters?: CommunityMembersFilters;
  },
  string,
  null
>();

export const fetchCommunityMembersPendingByUserAsync = createAsyncAction(
  'FETCH_COMMUNITY_MEMBERS_PENDING_BY_USER_REQUEST',
  'FETCH_COMMUNITY_MEMBERS_PENDING_BY_USER_SUCCESS',
  'FETCH_COMMUNITY_MEMBERS_PENDING_BY_USER_FAILURE',
  'FETCH_COMMUNITY_MEMBERS_PENDING_BY_USER_IDLE',
)<{ bearer: string; communityId: number }, { id: number; pendingByUsers?: Member[] }, string, null>();

export const removeCommunityInvitationAsync = createAsyncAction(
  'REMOVE_COMMUNITY_INVITATION_REQUEST',
  'REMOVE_COMMUNITY_INVITATION_SUCCESS',
  'REMOVE_COMMUNITY_INVITATION_FAILURE',
  'REMOVE_COMMUNITY_INVITATION_IDLE',
)<
  { bearer: string; communityId: number; userEmail: string; userId: number },
  { communityId: number; userEmail: string; userId: number },
  string,
  null
>();

export const respondCommunityJoinRequestAsync = createAsyncAction(
  'RESPOND_COMMUNITY_JOIN_REQUEST_REQUEST',
  'RESPOND_COMMUNITY_JOIN_REQUEST_SUCCESS',
  'RESPOND_COMMUNITY_JOIN_REQUEST_FAILURE',
  'RESPOND_COMMUNITY_JOIN_REQUEST_IDLE',
)<
  { bearer: string; communityId: number; userId: number; type: Response },
  { communityId: number; userId: number; type: Response },
  string,
  null
>();

export const fetchCommunityMembersCountriesAsync = createAsyncAction(
  'FETCH_COMMUNITY_MEMBERS_COUNTRIES_REQUEST',
  'FETCH_COMMUNITY_MEMBERS_COUNTRIES_SUCCESS',
  'FETCH_COMMUNITY_MEMBERS_COUNTRIES_FAILURE',
  'FETCH_COMMUNITY_MEMBERS_COUNTRIES_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; countries: Country[] }, string, null>();

export const fetchCommunityMembersSkillsAsync = createAsyncAction(
  'FETCH_COMMUNITY_MEMBERS_SKILLS_REQUEST',
  'FETCH_COMMUNITY_MEMBERS_SKILLS_SUCCESS',
  'FETCH_COMMUNITY_MEMBERS_SKILLS_FAILURE',
  'FETCH_COMMUNITY_MEMBERS_SKILLS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; skills: Skill[] }, string, null>();

export const fetchCommunityMembersInterestsAsync = createAsyncAction(
  'FETCH_COMMUNITY_MEMBERS_INTERESTS_REQUEST',
  'FETCH_COMMUNITY_MEMBERS_INTERESTS_SUCCESS',
  'FETCH_COMMUNITY_MEMBERS_INTERESTS_FAILURE',
  'FETCH_COMMUNITY_MEMBERS_INTERESTS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; interests: Interest[] }, string, null>();

export const inviteCommunityMembersAsync = createAsyncAction(
  'INVITE_COMMUNITY_MEMBERS_REQUEST',
  'INVITE_COMMUNITY_MEMBERS_SUCCESS',
  'INVITE_COMMUNITY_MEMBERS_FAILURE',
  'INVITE_COMMUNITY_MEMBERS_IDLE',
)<{ bearer: string; data: EmailInvitationDto[] }, (EmailInvitationDto | AxiosError)[], string, null>();

export const fetchCommunityResourcesAsync = createAsyncAction(
  'FETCH_COMMUNITY_RESOURCES_REQUEST',
  'FETCH_COMMUNITY_RESOURCES_SUCCESS',
  'FETCH_COMMUNITY_RESOURCES_FAILURE',
  'FETCH_COMMUNITY_RESOURCES_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    circles: Circle[];
    sorting: Sorting;
    filtering: Filtering;
    skip: number;
    take: number;
  },
  { list: CommunityResources; skip: number; take: number; resourcesCount?: number; totalResourcesCount?: number },
  string,
  null
>();

export const fetchCommunityCircleToCirclePermissionAsync = createAsyncAction(
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_REQUEST',
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_SUCCESS',
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_FAILURE',
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_IDLE',
)<
  {
    communityId: number;
  },
  { communityId: number; list: [] },
  string,
  null
>();

export const fetchCommunityCircleToCirclePermissionSaveAsync = createAsyncAction(
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_SAVE_REQUEST',
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_SAVE_SUCCESS',
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_SAVE_FAILURE',
  'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_SAVE_IDLE',
)<any, any, string, null>();

export const createResourceAsync = createAsyncAction(
  'CREATE_RESOURCE_REQUEST',
  'CREATE_RESOURCE_SUCCESS',
  'CREATE_RESOURCE_FAILURE',
  'CREATE_RESOURCE_IDLE',
)<
  {
    resource: Resource;
    bearer: string;
    entityId: number;
    paragraphId?: number;
    file: File | string;
    isCommunityResource: boolean;
    isExternal: boolean;
    iconFile: File | string;
  },
  { resource: Resource; entityId: number; isCommunityResource: boolean },
  string,
  null
>();

export const createResourcesAsync = createAsyncAction(
  'CREATE_RESOURCES_REQUEST',
  'CREATE_RESOURCES_SUCCESS',
  'CREATE_RESOURCES_FAILURE',
  'CREATE_RESOURCES_IDLE',
)<
  {
    resources: Resource[];
    bearer: string;
    entityId: number;
    paragraphId?: number;
    files: (File | string)[];
    isCommunityResource: boolean;
    isExternal: boolean;
    icons: (File | string)[];
  },
  { resources: Resource[]; entityId: number; isCommunityResource: boolean },
  string,
  null
>();

export const deleteResourceAsync = createAsyncAction(
  'DELETE_RESOURCE_REQUEST',
  'DELETE_RESOURCE_SUCCESS',
  'DELETE_RESOURCE_FAILURE',
  'DELETE_RESOURCE_IDLE',
)<
  { resourceId: number; entityId: number; bearer: string; isCommunityResource: boolean },
  { resourceId: number; entityId: number; isCommunityResource: boolean },
  string,
  null
>();

export const fetchCommunityRequestsAsync = createAsyncAction(
  'FETCH_COMMUNITY_REQUESTS_REQUEST',
  'FETCH_COMMUNITY_REQUESTS_SUCCESS',
  'FETCH_COMMUNITY_REQUESTS_FAILURE',
  'FETCH_COMMUNITY_REQUESTS_IDLE',
)<
  { communityId: number; bearer: string; sorting: Sorting; filtering: Filtering; skip: number; take: number },
  {
    list: CommunityRequests;
    skip: number;
    take: number;
    count: number;
    totalRequestsCount?: number;
    requestsCount?: number;
    requestFilters?: CommunityRequestsFilters;
  },
  string,
  null
>();

export const fetchAllCommunityRequestsAsync = createAsyncAction(
  'FETCH_ALL_COMMUNITY_REQUESTS_REQUEST',
  'FETCH_ALL_COMMUNITY_REQUESTS_SUCCESS',
  'FETCH_ALL_COMMUNITY_REQUESTS_FAILURE',
  'FETCH_ALL_COMMUNITY_REQUESTS_IDLE',
)<{ communityId: number; bearer: string }, { list: RequestStatus[]; communityId: number }, string, null>();

export const fetchCommunityDiscussionsAsync = createAsyncAction(
  'FETCH_COMMUNITY_DISCUSSIONS_REQUEST',
  'FETCH_COMMUNITY_DISCUSSIONS_SUCCESS',
  'FETCH_COMMUNITY_DISCUSSIONS_FAILURE',
  'FETCH_COMMUNITY_DISCUSSIONS_IDLE',
)<
  { communityId: number; bearer: string; sorting: Sorting; filtering: Filtering; skip: number; take: number },
  { list: CommunityDiscussions; skip: number; take: number; totalDiscussionsCount?: number; discussionsCount?: number },
  string,
  null
>();

export const fetchCommunityProjectsAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROJECTS_REQUEST',
  'FETCH_COMMUNITY_PROJECTS_SUCCESS',
  'FETCH_COMMUNITY_PROJECTS_FAILURE',
  'FETCH_COMMUNITY_PROJECTS_IDLE',
)<
  {
    communityId: number;
    bearer: string;
    sorting: Sorting;
    filtering: Partial<CommunityProjectsFilterAnswer>;
    skip: number;
    take: number;
  },
  {
    list: Project[];
    skip: number;
    take: number;
    projectsCount: number;
    communityId: number;
    totalProjectsCount?: number;
    canCreateProjects: boolean;
    projectFilters: CommunityProjectsFilters;
  },
  string,
  null
>();

export const acceptCommunityProjectAsync = createAsyncAction(
  'ACCEPT_COMMUNITY_PROJECT_REQUEST',
  'ACCEPT_COMMUNITY_PROJECT_SUCCESS',
  'ACCEPT_COMMUNITY_PROJECT_FAILURE',
  'ACCEPT_COMMUNITY_PROJECT_IDLE',
)<{ communityId: number; bearer: string; projectId: number }, { projectId: number }, string, null>();

export const denyCommunityProjectAsync = createAsyncAction(
  'DENY_COMMUNITY_PROJECT_REQUEST',
  'DENY_COMMUNITY_PROJECT_SUCCESS',
  'DENY_COMMUNITY_PROJECT_FAILURE',
  'DENY_COMMUNITY_PROJECT_IDLE',
)<{ communityId: number; bearer: string; projectId: number }, { projectId: number }, string, null>();

export const fetchResourceCirclesAsync = createAsyncAction(
  'FETCH_RESOURCE_CIRCLES_REQUEST',
  'FETCH_RESOURCE_CIRCLES_SUCCESS',
  'FETCH_RESOURCE_CIRCLES_FAILURE',
  'FETCH_RESOURCE_CIRCLES_IDLE',
)<
  { bearer: string; entityId: number; resourceId?: number; isCommunityResource: boolean },
  { entityId: number; resourceId?: number; circles: Circle[]; isCommunityResource: boolean },
  string,
  null
>();

export const fetchResourcesTagsAsync = createAsyncAction(
  'FETCH_RESOURCES_TAGS_REQUEST',
  'FETCH_RESOURCES_TAGS_SUCCESS',
  'FETCH_RESOURCES_TAGS_FAILURE',
  'FETCH_RESOURCES_TAGS_IDLE',
)<{ communityId: number; bearer: string }, { resourcesTags: string[]; communityId: number }, string, null>();

export const setAllowTasksAsync = createAsyncAction(
  'SET_ALLOW_TASKS_REQUEST',
  'SET_ALLOW_TASKS_SUCCESS',
  'SET_ALLOW_TASKS_FAILURE',
  'SET_ALLOW_TASKS_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    allowTasks: boolean;
  },
  { id: number; allowTasks: boolean },
  string,
  null
>();

export const fetchProjectsAwaitingFeedbackAsync = createAsyncAction(
  'FETCH_PROJECTS_AWAITING_FEEDBACK_REQUEST',
  'FETCH_PROJECTS_AWAITING_FEEDBACK_SUCCESS',
  'FETCH_PROJECTS_AWAITING_FEEDBACK_FAILURE',
  'FETCH_PROJECTS_AWAITING_FEEDBACK_IDLE',
)<
  { bearer: string; communityId: number },
  { communityId: number; projects: ProjectAwaitingFeedback[] },
  string,
  null
>();

export const fetchProjectsNextParagraphsAsync = createAsyncAction(
  'FETCH_PROJECTS_NEXT_PARAGRAPHS_REQUEST',
  'FETCH_PROJECTS_NEXT_PARAGRAPHS_SUCCESS',
  'FETCH_PROJECTS_NEXT_PARAGRAPHS_FAILURE',
  'FETCH_PROJECTS_NEXT_PARAGRAPHS_IDLE',
)<
  { bearer: string; communityId: number; projectId?: number },
  { communityId: number; projects: ProjectNextParagraphs[] },
  string,
  null
>();

export const fetchCommunityProjectsCountriesAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROJECTS_COUNTRIES_REQUEST',
  'FETCH_COMMUNITY_PROJECTS_COUNTRIES_SUCCESS',
  'FETCH_COMMUNITY_PROJECTS_COUNTRIES_FAILURE',
  'FETCH_COMMUNITY_PROJECTS_COUNTRIES_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; countries: Country[] }, string, null>();

export const fetchCommunityProjectsCategoriesAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROJECTS_CATEGORIES_REQUEST',
  'FETCH_COMMUNITY_PROJECTS_CATEGORIES_SUCCESS',
  'FETCH_COMMUNITY_PROJECTS_CATEGORIES_FAILURE',
  'FETCH_COMMUNITY_PROJECTS_CATEGORIES_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; categories: ProjectCategory[] }, string, null>();

export const fetchCommunityProjectsStagesAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROJECTS_STAGES_REQUEST',
  'FETCH_COMMUNITY_PROJECTS_STAGES_SUCCESS',
  'FETCH_COMMUNITY_PROJECTS_STAGES_FAILURE',
  'FETCH_COMMUNITY_PROJECTS_STAGES_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; stages: ProjectStage[] }, string, null>();

export const fetchCommunityProjectsSDGsAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROJECTS_SDGS_REQUEST',
  'FETCH_COMMUNITY_PROJECTS_SDGS_SUCCESS',
  'FETCH_COMMUNITY_PROJECTS_SDGS_FAILURE',
  'FETCH_COMMUNITY_PROJECTS_SDGS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; sdgs: SDG[] }, string, null>();

export const fetchCommunityProjectsTagsAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROJECTS_TAGS_REQUEST',
  'FETCH_COMMUNITY_PROJECTS_TAGS_SUCCESS',
  'FETCH_COMMUNITY_PROJECTS_TAGS_FAILURE',
  'FETCH_COMMUNITY_PROJECTS_TAGS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; tags: string[] }, string, null>();

export const saveCommunityProjectsTagsAsync = createAsyncAction(
  'SAVE_COMMUNITY_PROJECTS_TAGS_REQUEST',
  'SAVE_COMMUNITY_PROJECTS_TAGS_SUCCESS',
  'SAVE_COMMUNITY_PROJECTS_TAGS_FAILURE',
  'SAVE_COMMUNITY_PROJECTS_TAGS_IDLE',
)<
  { bearer: string; projectId: number; communityId: number; tags: string[] },
  { projectId: number; communityId: number; tags: string[] },
  string,
  null
>();

export const removeUserFromCommunityAsync = createAsyncAction(
  'REMOVE_USER_FROM_COMMUNITY_REQUEST',
  'REMOVE_USER_FROM_COMMUNITY_SUCCESS',
  'REMOVE_USER_FROM_COMMUNITY_FAILURE',
  'REMOVE_USER_FROM_COMMUNITY_IDLE',
)<{ bearer: string; communityId: number; userId: number }, { communityId: number; userId: number }, string, null>();

export const fetchCommunityProgressTrackingAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROGRESS_TRACKING_REQUEST',
  'FETCH_COMMUNITY_PROGRESS_TRACKING_SUCCESS',
  'FETCH_COMMUNITY_PROGRESS_TRACKING_FAILURE',
  'FETCH_COMMUNITY_PROGRESS_TRACKING_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    concept?: string;
    categoryId?: number;
    countryId?: number;
    sustainableDevelopmentGoalsIds?: number;
    tags?: string[];
  },
  { progressOverview: CommunityProgressOverview; communityId: number },
  string,
  null
>();

export const searchCommunityProgressTrackingAsync = createAsyncAction(
  'SEARCH_COMMUNITY_PROGRESS_TRACKING_REQUEST',
  'SEARCH_COMMUNITY_PROGRESS_TRACKING_SUCCESS',
  'SEARCH_COMMUNITY_PROGRESS_TRACKING_FAILURE',
  'SEARCH_COMMUNITY_PROGRESS_TRACKING_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    concept?: string;
    categoryId?: number;
    countryId?: number;
    sustainableDevelopmentGoalsIds?: number;
    tags?: string[];
  },
  {
    data: CommunityProgressOverview;
    communityId: number;
  },
  string,
  null
>();

export const fetchKPIAsync = createAsyncAction(
  'FETCH_KPI_REQUEST',
  'FETCH_KPI_SUCCESS',
  'FETCH_KPI_FAILURE',
  'FETCH_KPI_IDLE',
)<
  { bearer: string; methodologyId: number; communityId: number },
  { methodologyId: number; communityId: number; kpis: KpiDefinition[] },
  string,
  null
>();

export const upsertKPIAsync = createAsyncAction(
  'UPSERT_KPI_REQUEST',
  'UPSERT_KPI_SUCCESS',
  'UPSERT_KPI_FAILURE',
  'UPSERT_KPI_IDLE',
)<
  { bearer: string; communityId: number; kpi: KpiDefinition },
  { communityId: number; kpi: KpiDefinition },
  string,
  null
>();

export const deleteKPIAsync = createAsyncAction(
  'DELETE_KPI_REQUEST',
  'DELETE_KPI_SUCCESS',
  'DELETE_KPI_FAILURE',
  'DELETE_KPI_IDLE',
)<
  { bearer: string; communityId: number; kpi: KpiDefinition },
  { communityId: number; kpi: KpiDefinition },
  string,
  null
>();

export const downloadKPIAsync = createAsyncAction(
  'DOWNLAOD_KPI_REQUEST',
  'DOWNLAOD_KPI_SUCCESS',
  'DOWNLAOD_KPI_FAILURE',
  'DOWNLOAD_KPI_IDLE',
)<{ bearer: string; methodologyId: number }, string, string, null>();

export const fetchCommunityCalendarEventsAsync = createAsyncAction(
  'FETCH_COMMUNITY_CALENDAR_EVENTS_REQUEST',
  'FETCH_COMMUNITY_CALENDAR_EVENTS_SUCCESS',
  'FETCH_COMMUNITY_CALENDAR_EVENTS_FAILURE',
  'FETCH_COMMUNITY_CALENDAR_EVENTS_IDLE',
)<
  { bearer: string; communityId: number; dateFrom: string; dateTo: string },
  { communityId: number; eventList: CalendarEvent[] },
  string,
  null
>();

export const fetchCommunityCalendarEventDetailsAsync = createAsyncAction(
  'FETCH_COMMUNITY_CALENDAR_EVENT_DETAILS_REQUEST',
  'FETCH_COMMUNITY_CALENDAR_EVENT_DETAILS_SUCCESS',
  'FETCH_COMMUNITY_CALENDAR_EVENT_DETAILS_FAILURE',
  'FETCH_COMMUNITY_CALENDAR_EVENT_DETAILS_IDLE',
)<
  { bearer: string; communityId: number; eventId: string },
  { communityId: number; event: CalendarEvent },
  string,
  null
>();

export const upsertCommunityCalendarEventAsync = createAsyncAction(
  'UPSERT_COMMUNITY_CALENDAR_EVENT_REQUEST',
  'UPSERT_COMMUNITY_CALENDAR_EVENT_SUCCESS',
  'UPSERT_COMMUNITY_CALENDAR_EVENT_FAILURE',
  'UPSERT_COMMUNITY_CALENDAR_EVENT_IDLE',
)<
  { bearer: string; communityId: number; eventToSave: CalendarEvent },
  { communityId: number; event: CalendarEvent },
  string,
  null
>();

export const deleteCommunityCalendarEventAsync = createAsyncAction(
  'DELETE_COMMUNITY_CALENDAR_EVENT_REQUEST',
  'DELETE_COMMUNITY_CALENDAR_EVENT_SUCCESS',
  'DELETE_COMMUNITY_CALENDAR_EVENT_FAILURE',
  'DELETE_COMMUNITY_CALENDAR_EVENT_IDLE',
)<{ bearer: string; communityId: number; eventId: string }, { communityId: number; eventId: string }, string, null>();

export const upsertCommunityReportAsync = createAsyncAction(
  'UPSERT_COMMUNITY_REPORT_REQUEST',
  'UPSERT_COMMUNITY_REPORT_SUCCESS',
  'UPSERT_COMMUNITY_REPORT_FAILURE',
  'UPSERT_COMMUNITY_REPORT_IDLE',
)<
  { bearer: string; communityId: number; reportToSave: Report },
  { communityId: number; report: Report },
  string,
  null
>();

export const deleteCommunityReportAsync = createAsyncAction(
  'DELETE_COMMUNITY_REPORT_REQUEST',
  'DELETE_COMMUNITY_REPORT_SUCCESS',
  'DELETE_COMMUNITY_REPORT_FAILURE',
  'DELETE_COMMUNITY_REPORT_IDLE',
)<{ bearer: string; communityId: number; id: number }, { communityId: number; id: number }, string, null>();

export const fetchCommunityReportsAsync = createAsyncAction(
  'FETCH_COMMUNITY_REPORTS_REQUEST',
  'FETCH_COMMUNITY_REPORTS_SUCCESS',
  'FETCH_COMMUNITY_REPORTS_FAILURE',
  'FETCH_COMMUNITY_REPORTS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; reports: Report[] }, string, null>();

export const fetchCommunityProjectFormAsync = createAsyncAction(
  'FETCH_COMMUNITY_PROJECT_FORM_REQUEST',
  'FETCH_COMMUNITY_PROJECT_FORM_SUCCESS',
  'FETCH_COMMUNITY_PROJECT_FORM_FAILURE',
  'FETCH_COMMUNITY_PROJECT_FORM_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; form: Form }, string, null>();

export const updateCommunityProjectFormAsync = createAsyncAction(
  'UPDATE_COMMUNITY_PROJECT_FORM_REQUEST',
  'UPDATE_COMMUNITY_PROJECT_FORM_SUCCESS',
  'UPDATE_COMMUNITY_PROJECT_FORM_FAILURE',
  'UPDATE_COMMUNITY_PROJECT_FORM_IDLE',
)<{ bearer: string; communityId: number; form: Form }, { communityId: number; form: Form }, string, null>();

export const fetchMentionUsersAsync = createAsyncAction(
  'FETCH_MENTION_USERS_REQUEST',
  'FETCH_MENTION_USERS_SUCCESS',
  'FETCH_MENTION_USERS_FAILURE',
  'FETCH_MENTION_USERS_IDLE',
)<null, { communityId: number; mentionUsers: MentionUser[] }, string, null>();

export const fetchCommunityMembersByCircleAsync = createAsyncAction(
  'FETCH_MEMBERS_BY_CIRCLE_REQUEST',
  'FETCH_MEMBERS_BY_CIRCLE_SUCCESS',
  'FETCH_MEMBERS_BY_CIRCLE_FAILURE',
  'FETCH_MEMBERS_BY_CIRCLE_IDLE',
)<
  { bearer: string; communityId: number; circleId: number; skip: number; take: number },
  { communityId: number; circleId: number; skip: number; take: number; members: Member[] },
  string,
  null
>();

export const editCommunityTagsAsync = createAsyncAction(
  'EDIT_COMMUNITY_TAGS_REQUEST',
  'EDIT_COMMUNITY_TAGS_SUCCESS',
  'EDIT_COMMUNITY_TAGS_FAILURE',
  'EDIT_COMMUNITY_TAGS_IDLE',
)<
  { bearer: string; communityId: number; tagsList: Tag[]; tagType: TagType },
  { communityId: number; tagsList: Tag[]; tagType: TagType },
  string,
  null
>();

export const deleteCommunityTagsAsync = createAsyncAction(
  'DELETE_COMMUNITY_TAGS_REQUEST',
  'DELETE_COMMUNITY_TAGS_SUCCESS',
  'DELETE_COMMUNITY_TAGS_FAILURE',
  'DELETE_COMMUNITY_TAGS_IDLE',
)<
  { bearer: string; communityId: number; tagsList: string[]; tagType: TagType },
  { communityId: number; tagsList: string[]; tagType: TagType },
  string,
  null
>();

export const editCommunityProjectTagsAsync = createAsyncAction(
  'EDIT_COMMUNITY_PROJECT_TAGS_REQUEST',
  'EDIT_COMMUNITY_PROJECT_TAGS_SUCCESS',
  'EDIT_COMMUNITY_PROJECT_TAGS_FAILURE',
  'EDIT_COMMUNITY_PROJECT_TAGS_IDLE',
)<{ bearer: string; communityId: number; tagsList: Tag[] }, { communityId: number; tagsList: Tag[] }, string, null>();

export const deleteCommunityProjectTagsAsync = createAsyncAction(
  'DELETE_COMMUNITY_PROJECT_TAGS_REQUEST',
  'DELETE_COMMUNITY_PROJECT_TAGS_SUCCESS',
  'DELETE_COMMUNITY_PROJECT_TAGS_FAILURE',
  'DELETE_COMMUNITY_PROJECT_TAGS_IDLE',
)<{ bearer: string; communityId: number; tags: string[] }, { communityId: number; tags: string[] }, string, null>();

export const fetchCommunityMembersTagsAsync = createAsyncAction(
  'FETCH_COMMUNITY_MEMBERS_TAGS_REQUEST',
  'FETCH_COMMUNITY_MEMBERS_TAGS_SUCCESS',
  'FETCH_COMMUNITY_MEMBERS_TAGS_FAILURE',
  'FETCH_COMMUNITY_MEMBERS_TAGS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; tags: string[] }, string, null>();

export const saveCommunityMembersTagsAsync = createAsyncAction(
  'SAVE_COMMUNITY_MEMBERS_TAGS_REQUEST',
  'SAVE_COMMUNITY_MEMBERS_TAGS_SUCCESS',
  'SAVE_COMMUNITY_MEMBERS_TAGS_FAILURE',
  'SAVE_COMMUNITY_MEMBERS_TAGS_IDLE',
)<
  { bearer: string; communityId: number; userId: number; userCommunityId: number; userTags: string[] },
  { communityId: number; userId: number; userCommunityId: number; userTags: string[] },
  string,
  null
>();

export const updateMethodologyNameAsync = createAsyncAction(
  'UPDATE_METHODOLOGY_NAME_REQUEST',
  'UPDATE_METHODOLOGY_NAME_SUCCESS',
  'UPDATE_METHODOLOGY_NAME_FAILURE',
  'UPDATE_METHODOLOGY_NAME_IDLE',
)<{ bearer: string; id: number; name: string }, { id: number; name: string }, string, null>();

export default {
  fetchCommunitiesAsync,
  updateCommunityInfoAsync,
  fetchCommunityDetailsAsync,
  fetchCommunityCirclesAsync,
  saveCommunityCircleAsync,
  saveCommunityCirclesAsync,
  deleteCommunityCircleAsync,
  editCommunityCircleAsync,
  fetchCommunityMembersAsync,
  removeCommunityInvitationAsync,
  respondCommunityJoinRequestAsync,
  fetchCommunityMembersCountriesAsync,
  fetchCommunityMembersSkillsAsync,
  fetchCommunityMembersInterestsAsync,
  inviteCommunityMembersAsync,
  fetchCommunityResourcesAsync,
  createResourceAsync,
  createResourcesAsync,
  deleteResourceAsync,
  fetchCommunityRequestAsync: fetchCommunityRequestsAsync,
  fetchCommunityDiscussionAsync: fetchCommunityDiscussionsAsync,
  fetchCommunityProjectsAsync,
  acceptCommunityProjectAsync,
  denyCommunityProjectAsync,
  fetchResourceCirclesAsync,
  fetchResourcesTagsAsync,
  setAllowTasksAsync,
  fetchProjectsAwaitingFeedbackAsync,
  fetchProjectsNextParagraphsAsync,
  fetchCommunityProjectsCountriesAsync,
  fetchCommunityProjectsCategoriesAsync,
  fetchCommunityProjectsStagesAsync,
  fetchCommunityProjectsSDGsAsync,
  fetchCommunityProjectsTagsAsync,
  saveCommunityProjectsTagsAsync,
  removeUserFromCommunityAsync,
  fetchCommunityProgressTrackingAsync,
  searchCommunityProgressTrackingAsync,
  fetchKPIAsync,
  upsertKPIAsync,
  deleteKPIAsync,
  downloadKPIAsync,
  fetchCommunityCalendarEventsAsync,
  fetchCommunityCalendarEventDetailsAsync,
  upsertCommunityCalendarEventAsync,
  deleteCommunityCalendarEventAsync,
  upsertCommunityReportAsync,
  deleteCommunityReportAsync,
  fetchCommunityReportsAsync,
  fetchMentionUsersAsync,
  fetchCommunityMembersPendingByUserAsync,
  fetchCommunityMembersByCircleAsync,
  updateCommunityCircleAsync,
  fetchCommunityProjectFormAsync,
  updateCommunityProjectFormAsync,
  editCommunityTagsAsync,
  deleteCommunityTagsAsync,
  editCommunityProjectTagsAsync,
  deleteCommunityProjectTagsAsync,
  fetchCommunityMembersTagsAsync,
  saveCommunityMembersTagsAsync,
  updateMethodologyNameAsync,
};
