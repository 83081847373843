import { AxiosResponse } from 'axios';
import moment from 'moment';
import { getAxiosInstance } from './helper';

const ai = getAxiosInstance();

export function getGeolocation(
  bearer: string,
  address: string,
): Promise<{
  lat: number;
  lng: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Maps/GetGeocode',
      params: {
        address,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        if (data.length === 0) {
          reject(new Error('No results'));
        }
        const geolocation = data[0].geometry.location;
        resolve({
          ...geolocation,
        });
      })
      .catch(err => reject(err));
  });
}

export function getTimezone(bearer: string, lng: number, lat: number): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Maps/GetTimezone',
      params: {
        latitude: lat,
        longitude: lng,
        timestamp: moment().unix(),
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data.timeZoneId as string);
      })
      .catch(err => reject(err));
  });
}

export function getTimezoneFromLocation(bearer: string, address: string): Promise<string> {
  return new Promise((resolve, reject) => {
    getGeolocation(bearer, address)
      .then((location: { lng: number; lat: number }) => {
        getTimezone(bearer, location.lng, location.lat)
          .then((timezone: string) => {
            resolve(timezone as string);
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
  });
}
