import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { fetchProjectRequestFeedbackListAsync } from '../actions/project-request-feedback';
import { from, of } from 'rxjs';
import { Epic } from 'redux-observable';
import { RootAction, RootState, Services } from 'StoreModel';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchRequestFeedbacksEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectRequestFeedbackListAsync.request)),
    switchMap(action =>
      from(
        api.project.fetchProjectRequestFeedbackList(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.projectId,
        ),
      ).pipe(
        map(fetchProjectRequestFeedbackListAsync.success),
        catchError(error => of(fetchProjectRequestFeedbackListAsync.failure(error.message))),
      ),
    ),
  );

export default { fetchRequestFeedbacksEpic };
