import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { CommunityTab } from 'redux/types/account';

interface ISL {
  $active: boolean;
  $hasNotifications?: boolean;
  $greyText?: boolean;
}

interface IND {
  $hasNotifications: boolean;
}

export interface CommunitySidebarTabItem extends CommunityTab {
  url: string;
}

export const SidebarList = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.4em;
  }
`;

export const SidebarItemText = styled.span`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
`;

export const SidebarContainer = styled.div`
  margin-top: 2.5em;
  position: sticky;
  top: 2em;
  max-height: calc(100vh - 2.5em);
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SidebarLink = styled(Link)<ISL>`
  min-height: 2em;
  padding: 0 1.1em 0 0.8em;
  text-align: left;
  color: ${props => (props.$greyText ? props.theme.colors.grey.dark : props.theme.colors.blue.normal)};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  ${({ $active, theme }) => $active && `background: linear-gradient(to left, ${theme.colors.blue.light} 80%, #fff);`};
  width: 100%;

  :hover {
    color: ${props => (props.$greyText ? props.theme.colors.grey.medium : props.theme.colors.blue.normal)};
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    width: inherit;
    display: flex;
    justify-content: center;
    padding: 0 1.1em;

    span {
      padding-right: 0;
      ${({ $hasNotifications }) => $hasNotifications && `padding-right: 0.8em;`}
    }
  }
`;

const NotificationDot = styled.div<IND>`
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.orange.normal};
  ${({ $hasNotifications }) => $hasNotifications !== true && `display: none;`}
`;

const LinkLabel = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.7em;
  width: 100%;
  overflow: hidden;
  text-transform: capitalize;

  svg {
    min-width: 1.3em;
  }
  img {
    width: 1.3em;
  }
`;

export const SidebarItemContainer = styled.div`
  margin-left: -0.8em;
  margin-bottom: 0.3em;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    width: inherit;
  }
`;

export const SidebarLogo = styled.div<{ shrinked: boolean }>`
  margin-left: ${({ shrinked }) => (shrinked ? '-1.1em' : '0')};
`;

interface Props {
  to: string;
  hasNotifications?: boolean;
  greyText?: boolean;
  alternativeActivePaths?: string[];
  onClickCapture?: any;
  testId?: string;
}

export const SidebarItem: React.FC<Props> = ({
  to,
  hasNotifications = false,
  children,
  greyText,
  alternativeActivePaths,
  onClickCapture,
  testId,
}) => {
  const [active, setActive] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setActive(
      pathname === to ||
        alternativeActivePaths?.some((it: string) => pathname.includes(it)) ||
        (to === '/home' && pathname.split('/').length === 3),
    );
  }, [pathname, to]);

  return (
    <SidebarItemContainer>
      <SidebarLink
        data-cy={testId}
        onClickCapture={onClickCapture}
        to={to}
        $active={active}
        $hasNotifications={hasNotifications}
        $greyText={greyText}
      >
        <LinkLabel>{children}</LinkLabel>
        <NotificationDot $hasNotifications={hasNotifications} />
      </SidebarLink>
    </SidebarItemContainer>
  );
};
