import {
  BusinessModel,
  BusinessModelAssignment,
  BusinessModelSection,
  Circle,
  Comment,
  Discussion,
  FeedItem,
  KpiValue,
  Member,
  Project,
  ProjectPost,
  ProjectProgressDetails,
  ProjectTask,
  Request,
  Resource,
  RequestFilteringSkill,
  projectKpiDefinition,
  KpiDefinition,
  ProjectRequestFeedback,
  Tag,
  TagType,
  ProjectBusinessModel,
  CommunityRequestsFilters,
  InvitedAdvisor,
} from 'redux/types/account';
import { createReducer, PayloadAction } from 'typesafe-actions';
import { append, clone, concat, difference, filter, find, findIndex, map, prepend, propEq, update } from 'ramda';
import {
  acceptCommunityProjectAsync,
  createResourceAsync,
  createResourcesAsync,
  deleteResourceAsync,
  denyCommunityProjectAsync,
  fetchCommunityProjectsAsync,
  fetchResourceCirclesAsync,
  saveCommunityProjectsTagsAsync,
} from '../actions/community';
import { gdprConsent, featuresCookieCategories } from 'util/utils';
import { createTransform } from 'redux-persist';
import { getPersistKey } from 'util/persist';
import storage from 'redux-persist/es/storage';

import {
  fetchRequestAsync,
  deleteRequestAsync,
  saveRequestAsync,
  deleteRequestCommentAsync,
  fetchProjectFilteringSkillsAsync,
  closeRequestAsync,
  scheduleRequestSessionAsync,
} from '../actions/request';

import {
  addCommentToDiscussionAsync,
  createNewDiscussionAsync,
  deleteDiscussionAsync,
  fetchCirclesByDiscussionsAsync,
  fetchDiscussionAsync,
  fetchDiscussionPrivacyCirclesAsync,
  fetchDiscussionsTagsAsync,
  updateDiscussionIsArchivedAsync,
  followDiscussionAsync,
  unfollowDiscussionAsync,
} from '../actions/discussion';

import {
  addCommentToFeedAsync,
  addProjectPostAsync,
  deleteCommentAsync,
  fetchProjectFeedAsync,
  likeFeedPostAsync,
  unlikeFeedPostAsync,
} from '../actions/feed';

import {
  saveRequestCommentAsync,
  applyToTeamProjectAsync,
  createProjectAsync,
  deleteProjectCircleAsync,
  editProjectCircleAsync,
  fetchPendingKpiAsync,
  fetchProjectByIdAsync,
  fetchProjectCirclesAsync,
  fetchProjectDiscussionsAsync,
  fetchProjectMembersAsync,
  fetchProjectOverviewAsync,
  // fetchProjectSearchOverviewAsync, //! NEWW
  fetchProjectProgressDetailsAsync,
  fetchProjectResourcesAsync,
  fetchProjectResourcesTagsAsync,
  followProjectAsync,
  inviteProjectMembersAsync,
  removeProjectAsync,
  removeProjectInvitationAsync,
  removeUserFromProjectAsync,
  respondProjectJoinRequestAsync,
  saveKpiValuesAsync,
  saveProjectCircleAsync,
  saveProjectCirclesAsync,
  saveProjectDiscussionTagsAsync,
  unfollowProjectAsync,
  fetchProjectRequestsAsync,
  updateProjectAsync,
  updateProjectCoverAsync,
  updateProjectLogoAsync,
  fetchProjectRequestsTagsAsync,
  updateProjectRequestTagsAsync,
  fetchProjectMembersPendingByUserAsync,
  fetchProjectMembersByCircleAsync,
  fetchProjectKPIDefinitionAsync,
  upsertProjectKPIAsync,
  deleteProjectKpiAsync,
  editProjectTagsAsync,
  deleteProjectTagsAsync,
  updateEditedCommunityProjectTagsAsync,
  updateDeletedCommunityProjectTagsAsync,
  editProjectRequestTags,
  deleteProjectRequestTags,
} from '../actions/project';

import { followUserAsync, unfollowUserAsync } from '../actions/users';
import {
  addCommentToBusinessModelParagraphAsync,
  fetchBusinessModelByProjectIdAsync,
  fetchBusinessModelPrivacyCirclesAsync,
  fetchCommentsForBusinessModelParagraphAsync,
  fetchProjectBusinessModelsAsync,
  fetchProjectTasksAsync,
  saveProjectTasksAsync,
  saveSectionStagegateLockedAsync,
  toggleApprovedAssignmentAsync,
} from '../actions/business-model';
import {
  canViewByCalendarPlanning,
  canViewBySequentialPlanning,
  canViewParagraph,
  getDueTask,
} from '../../util/business-model';
import { CommentType, Planner, RequestStatusEnum, Response } from '../types/enums';
import { buildFlagReducer } from '../store/utils';
import { recursiveCommentLike } from './reducersHelpers/recursiveCommentLike';
import { fetchProjectRequestFeedbackListAsync } from '../actions/project-request-feedback';
import { fetchProjectBusinessModels } from 'services/api/business-model';

export const fetchProjectMembersByCircleFlag = buildFlagReducer(fetchProjectMembersByCircleAsync);

export const saveRequestFlag = buildFlagReducer(saveRequestAsync);

export const toggleApprovedAssignmentFlag = buildFlagReducer(toggleApprovedAssignmentAsync);

export const fetchPendingKpiFlag = buildFlagReducer(fetchPendingKpiAsync);

export const removeProjectInvitationFlag = buildFlagReducer(removeProjectInvitationAsync);

export const respondProjectJoinRequestFlag = buildFlagReducer(respondProjectJoinRequestAsync);

export const fetchCirclesByDiscussionsFlag = buildFlagReducer(fetchCirclesByDiscussionsAsync);

export const fetchDiscussionsTagsFlag = buildFlagReducer(fetchDiscussionsTagsAsync);

export const fetchProjectRequestsFlag = buildFlagReducer(fetchProjectRequestsAsync);

export const createNewDiscussionFlag = buildFlagReducer(createNewDiscussionAsync);

export const updateDiscussionIsArchivedFlag = buildFlagReducer(updateDiscussionIsArchivedAsync);

export const deleteDiscussionFlag = buildFlagReducer(deleteDiscussionAsync);

export const fetchProjectFeedFlag = buildFlagReducer(fetchProjectFeedAsync);

export const addProjectPostFlag = buildFlagReducer(addProjectPostAsync);

export const fetchProjectByIdFlag = buildFlagReducer(fetchProjectByIdAsync);

export const fetchProjectOverviewFlag = buildFlagReducer(fetchProjectOverviewAsync);

// export const fetchProjectSearchOverviewFlag = buildFlagReducer(fetchProjectSearchOverviewAsync); //! NEEW

export const fetchProjectMembersFlag = buildFlagReducer(fetchProjectMembersAsync);

export const fetchProjectResourcesFlag = buildFlagReducer(fetchProjectResourcesAsync);

export const fetchProjectResourcesTagsFlag = buildFlagReducer(fetchProjectResourcesTagsAsync);

export const fetchProjectDiscussionsFlag = buildFlagReducer(fetchProjectDiscussionsAsync);

export const fetchProjectCirclesFlag = buildFlagReducer(fetchProjectCirclesAsync);

export const inviteProjectMembersFlag = buildFlagReducer(inviteProjectMembersAsync);

export const applyToTeamProjectFlag = buildFlagReducer(applyToTeamProjectAsync);

export const createProjectFlag = buildFlagReducer(createProjectAsync);

export const updateProjectFlag = buildFlagReducer(updateProjectAsync);

export const removeProjectFlag = buildFlagReducer(removeProjectAsync);

export const updateProjectLogoFlag = buildFlagReducer(updateProjectLogoAsync);

export const updateProjectCoverFlag = buildFlagReducer(updateProjectCoverAsync);

export const saveProjectCircleFlag = buildFlagReducer(saveProjectCircleAsync);

export const saveProjectCirclesFlag = buildFlagReducer(saveProjectCirclesAsync);

export const deleteProjectCircleFlag = buildFlagReducer(deleteProjectCircleAsync);

export const editProjectCircleFlag = buildFlagReducer(editProjectCircleAsync);

export const removeUserFromProjectFlag = buildFlagReducer(removeUserFromProjectAsync);

export const followProjectFlag = buildFlagReducer(followProjectAsync);

export const unfollowProjectFlag = buildFlagReducer(unfollowProjectAsync);

export const saveProjectDiscussionTagsFlag = buildFlagReducer(saveProjectDiscussionTagsAsync);

export const fetchBusinessModelByProjectIdFlag = buildFlagReducer(fetchBusinessModelByProjectIdAsync);

export const fetchProjectTasksFlag = buildFlagReducer(fetchProjectTasksAsync);

export const saveProjectTasksFlag = buildFlagReducer(saveProjectTasksAsync);

export const saveSectionStagegateLockedFlag = buildFlagReducer(saveSectionStagegateLockedAsync);

export const fetchBusinessModelPrivacyCirclesFlag = buildFlagReducer(fetchBusinessModelPrivacyCirclesAsync);

export const fetchCommentsForBusinessModelParagraphFlag = buildFlagReducer(fetchCommentsForBusinessModelParagraphAsync);

export const addCommentToRequestFlag = buildFlagReducer(saveRequestCommentAsync);

export const fetchProjectProgressDetailsFlag = buildFlagReducer(fetchProjectProgressDetailsAsync);

export const saveKpiValuesFlag = buildFlagReducer(saveKpiValuesAsync);

export const fetchDiscussionPrivacyCirclesFlag = buildFlagReducer(fetchDiscussionPrivacyCirclesAsync);

export const fetchProjectRequestFeedbackListFlag = buildFlagReducer(fetchProjectRequestFeedbackListAsync);

export const fetchProjectKPIDefinitionFlag = buildFlagReducer(fetchProjectKPIDefinitionAsync);

export const upsertProjectKPIFlag = buildFlagReducer(upsertProjectKPIAsync);

export const deleteProjectKPIFlag = buildFlagReducer(deleteProjectKpiAsync);

export const editProjectTagsFlag = buildFlagReducer(editProjectTagsAsync);

export const deleteProjectTagsFlag = buildFlagReducer(deleteProjectTagsAsync);

export const scheduleRequestSessionFlag = buildFlagReducer(scheduleRequestSessionAsync);

export const projectsReducer = createReducer([] as Project[])
  .handleAction(
    fetchCommunityProjectsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_COMMUNITY_PROJECTS_SUCCESS',
        {
          list: Project[];
          skip: number;
          take: number;
          projectsCount: number;
          communityId: number;
          canApproveParagraph: boolean;
          canInviteToProjects: boolean;
        }
      >,
    ) => {
      const incomingProjects = action.payload.list;
      if (projects.length === 0) return incomingProjects;
      if (action.payload.skip === 0) {
        return incomingProjects.map((project: Project) => {
          const toBeReplaced = projects.find((p: Project) => p.id === project.id);
          if (toBeReplaced) {
            return {
              ...project,
              feed: toBeReplaced.feed,
            };
          } else {
            return project;
          }
        });
      }

      let newProjectsList = clone(projects);
      incomingProjects.forEach(incomingProject => {
        const index = findIndex(p => p.id === incomingProject.id, newProjectsList);
        if (index === -1) newProjectsList = append(incomingProject, newProjectsList);

        const toBeReplacedProject = newProjectsList[index] || {};
        newProjectsList = update(
          index,
          {
            ...toBeReplacedProject,
            ...incomingProject,
            canEditPrivacy: toBeReplacedProject.canEditPrivacy,
          },
          newProjectsList,
        );
      });
      return clone(newProjectsList);
    },
  )
  .handleAction(
    acceptCommunityProjectAsync.success,
    (projects: Project[], action: PayloadAction<'ACCEPT_COMMUNITY_PROJECT_SUCCESS', { projectId: number }>) => {
      return map((project: Project) => {
        if (project.id !== action.payload.projectId) return { ...project };
        return {
          ...project,
          isPending: false,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchProjectRequestsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_REQUESTS_SUCCESS',
        {
          list: Request[];
          canCreateRequests: boolean;
          skip: number;
          take: number;
          count: number;
          projectId: number;
          hasPendingRequestFeedback: boolean;
          hasDefaultCircles: boolean;
          totalRequestsCount?: number;
          requestFilter?: CommunityRequestsFilters;
        }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          if (action.payload.take === 0)
            return {
              ...project,
              canCreateRequests: action.payload.canCreateRequests,
              hasPendingRequestFeedback: action.payload.hasPendingRequestFeedback,
              requestsHasDefaultPrivacyCircle: action.payload.hasDefaultCircles,
              totalRequestsCount: action.payload.totalRequestsCount || project.totalRequestsCount || undefined,
              requestFilter: action.payload.requestFilter,
            };
          if (action.payload.skip === 0) {
            return {
              ...project,
              requestsCount: action.payload.count,
              canCreateRequests: action.payload.canCreateRequests,
              hasPendingRequestFeedback: action.payload.hasPendingRequestFeedback,
              requests: [...action.payload.list].map((request: Request) => {
                const localRequest = (project.requests || []).find((r: Request) => r.id === request.id);
                if (!localRequest) return request;
                return {
                  ...localRequest,
                  ...request,
                };
              }),
              requestsHasDefaultPrivacyCircle: action.payload.hasDefaultCircles,
              totalRequestsCount: action.payload.totalRequestsCount || project.totalRequestsCount || undefined,
              requestFilter: action.payload.requestFilter,
            };
          }
          return {
            ...project,
            canCreateRequests: action.payload.canCreateRequests,
            hasPendingRequestFeedback: action.payload.hasPendingRequestFeedback,
            requests: [...(project.requests || []), ...action.payload.list],
            requestsHasDefaultPrivacyCircle: action.payload.hasDefaultCircles,
            requestFilter: action.payload.requestFilter,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchProjectRequestsTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'FETCH_PROJECT_REQUESTS_TAGS_SUCCESS', { requestsTags: string[]; projectId: number }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            requestsTags: action.payload.requestsTags,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchProjectFilteringSkillsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_FILTERING_SKILLS_SUCCESS',
        { projectId: number; skills: RequestFilteringSkill[] }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        return {
          ...project,
          requestSkills: action.payload.skills,
        };
      });
    },
  )
  .handleAction(
    updateProjectRequestTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'UPDATE_REQUEST_TAGS_SUCCESS',
        { tags: Array<string>; requestId: number; projectId: number }
      >,
    ) => {
      return map(community => {
        if (community.id !== action.payload.projectId) {
          return community;
        } else {
          let diffTagAdded: string[] = [];
          let diffTagRemoved: string[] = [];
          const newRequests = community.requests?.map(r => {
            if (r.id !== action.payload.requestId) {
              return r;
            }

            diffTagAdded = difference(action.payload.tags, r.tags);

            diffTagRemoved = difference(r.tags, action.payload.tags);
            return {
              ...r,
              tags: action.payload.tags,
            };
          });

          let requestsTags: string[] = community.requestsTags || [];

          if (diffTagAdded.length > 0) {
            if (community.requestsTags?.find(tag => tag === diffTagAdded[0]) === undefined) {
              requestsTags.push(diffTagAdded[0]);
            }
          }

          if (diffTagRemoved.length > 0) {
            if (newRequests?.find(r => r.tags.includes(diffTagRemoved[0])) === undefined) {
              requestsTags = requestsTags.filter(t => t !== diffTagRemoved[0]);
            }
          }

          return {
            ...community,
            requestsTags: requestsTags.filter(
              (tag: string, index: number, arr: string[]) => arr.indexOf(tag) === index,
            ),
            requests: newRequests,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchRequestAsync.success,
    (projects: Project[], action: PayloadAction<'FETCH_REQUEST_SUCCESS', { request: Request }>) => {
      return map(project => {
        if (project.id !== action.payload.request.projectId) return { ...project };

        const newRequests: Request[] = project.requests || [];

        if (!newRequests.find(r => r.id === action.payload.request.id)) {
          newRequests.push(action.payload.request);
        } else {
          project.requests?.forEach((r, index) => {
            if (r.id === action.payload.request.id) {
              newRequests.splice(index, 1, {
                ...r,
                ...action.payload.request,
              });
            }
          });
        }

        return {
          ...project,
          requests: [...newRequests],
        };
      }, projects);
    },
  )
  .handleAction(
    saveRequestAsync.success,
    (projects: Project[], action: PayloadAction<'SAVE_REQUEST_SUCCESS', { projectId: number; request: Request }>) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        const alreadyExistsInList = (project.requests || []).find((r: Request) => r.id === action.payload.request.id);
        if (alreadyExistsInList) {
          return {
            ...project,
            requests: (project.requests || []).map((r: Request) => {
              if (r.id !== action.payload.request.id) return r;
              else
                return {
                  ...r,
                  ...action.payload.request,
                };
            }),
          };
        } else {
          return {
            ...project,
            requests: [action.payload.request, ...(project.requests || [])],
          };
        }
      });
    },
  )
  .handleAction(
    scheduleRequestSessionAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'SCHEDULE_REQUEST_SESSION_SUCCESS',
        {
          projectId: number;
          requestId: number;
          requestStatus: RequestStatusEnum;
          advisorId: number;
          start: string;
          location: string;
          attendeeUsers: InvitedAdvisor[];
        }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        const { requestId, requestStatus, advisorId, start, location, attendeeUsers } = action.payload;
        return {
          ...project,
          requests: (project.requests || []).map((r: Request) => {
            if (r.id !== requestId) return r;
            else
              return {
                ...r,
                requestStatus: requestStatus,
                invitedAdvisors: attendeeUsers,
                details: {
                  ...r.details,
                  advisorId: advisorId,
                  startDate: start,
                  sessionLink: location,
                },
              };
          }),
        };
      });
    },
  )
  .handleAction(
    deleteRequestAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'DELETE_REQUEST_SUCCESS', { entityType: string; entityId: number; request: Request }>,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return projects.map((project: Project) => {
        if (project.id !== action.payload.entityId) return project;
        return {
          ...project,
          requests: (project.requests || []).filter((r: Request) => r.id !== action.payload.request.id),
        };
      });
    },
  )
  .handleAction(
    closeRequestAsync.success,
    (projects: Project[], action: PayloadAction<'DELETE_REQUEST_SUCCESS', { requestId: number }>) => {
      return projects.map((project: Project) => {
        return {
          ...project,
          requests: (project.requests || []).map(request => {
            if (request.id !== action.payload.requestId) return request;
            else return { ...request, requestStatus: 2 };
          }),
        };
      });
    },
  )
  .handleAction(
    denyCommunityProjectAsync.success,
    (projects: Project[], action: PayloadAction<'DENY_COMMUNITY_PROJECT_SUCCESS', { projectId: number }>) => {
      return filter((project: Project) => project.id !== action.payload.projectId, projects);
    },
  )
  .handleAction(
    fetchProjectByIdAsync.success,
    (projects: Project[], action: PayloadAction<'FETCH_PROJECT_SUCCESS', Project>) => {
      const remoteProject = action.payload;
      const isExistingProject = find(project => project.id === remoteProject.id, projects);

      if (!isExistingProject) return append(remoteProject, projects);
      return map(project => {
        if (project.id !== remoteProject.id) return project;
        return {
          ...project,
          ...remoteProject,
          businessModel: project.businessModel,
          canEditPrivacy: project.canEditPrivacy,
          country: project.country,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchProjectOverviewAsync.success,
    (projects: Project[], action: PayloadAction<'FETCH_PROJECT_OVERVIEW_SUCCESS', Project>) => {
      const remoteProject = action.payload;
      const isExistingProject = find(p => p.id === remoteProject.id, projects);
      if (!isExistingProject) return append(remoteProject, projects);
      return map(project => {
        if (project.id !== remoteProject.id) return project;
        return {
          ...project,
          ...remoteProject,
          canEditPrivacy: project.canEditPrivacy,
          businessModel: project.businessModel,
          communityIds: project.communityIds,
          isProjectAdmin: project.isProjectAdmin,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchProjectRequestFeedbackListAsync.success,
    (
      projectRequestFeedbacks: ProjectRequestFeedback[],
      action: PayloadAction<'FETCH_REQUEST_FEEDBACK_SUCCESS', ProjectRequestFeedback>,
    ) => {
      const remoteProjectFeedback = action.payload;
      const existingProjectFeedback = find(
        p => p.eventId === remoteProjectFeedback.eventId && p.advisorId === remoteProjectFeedback.advisorId,
        projectRequestFeedbacks,
      );

      if (!existingProjectFeedback) return append(existingProjectFeedback, projectRequestFeedbacks);
      return map(projectFeedback => {
        if (
          projectFeedback.eventId !== remoteProjectFeedback.eventId &&
          projectFeedback.advisorId === remoteProjectFeedback.advisorId
        )
          return projectFeedback;
        return {
          ...projectFeedback,
          ...remoteProjectFeedback,
        };
      }, projectRequestFeedbacks);
    },
  )
  .handleAction(
    fetchProjectMembersAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_MEMBERS_SUCCESS',
        {
          id: number;
          members: Member[];
          pendingApplyToTeam: Member[];
          pendingByUsers: Member[];
          canInviteUser: boolean;
          isProjectAdmin: boolean;
          isMember: boolean;
        }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.id) return project;
        return {
          ...project,
          members: action.payload.members,
          pendingApplyToTeam: action.payload.pendingApplyToTeam,
          pendingByUsers: action.payload.pendingByUsers,
          canInviteUser: action.payload.canInviteUser,
          isProjectAdmin: action.payload.isProjectAdmin,
          isMember: action.payload.isMember,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchProjectMembersPendingByUserAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'FETCH_PROJECT_MEMBERS_PENDING_BY_USER_SUCCESS', { id: number; pendingByUsers: Member[] }>,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.id) return project;
        return {
          ...project,
          pendingByUsers: action.payload.pendingByUsers,
        };
      });
    },
  )
  .handleAction(
    respondProjectJoinRequestAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'RESPOND_PROJECT_JOIN_REQUEST_SUCCESS',
        { projectId: number; userId: number; type: Response }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) return project;
        if (action.payload.type === Response.Deny) {
          return {
            ...project,
            pendingApplyToTeam: (project.pendingApplyToTeam || []).filter(
              (m: Member) => m.id !== action.payload.userId,
            ),
          };
        } else {
          let user = (project.pendingApplyToTeam || []).find((m: Member) => m.id === action.payload.userId);
          const teamCircleId = project.circles?.find((c: Circle) => c.name === 'Team')?.id;
          if (teamCircleId && user && user.circle)
            user = {
              ...user,
              circle: {
                ...user.circle,
                id: teamCircleId,
              },
            };
          return {
            ...project,
            members: user
              ? [user, ...(project.members?.filter((m: Member) => m.id !== user?.id) || [])]
              : project.members || [],
            pendingApplyToTeam: (project.pendingApplyToTeam || []).filter(
              (m: Member) => m.id !== action.payload.userId,
            ),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    removeProjectInvitationAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'REMOVE_PROJECT_INVITATION_SUCCESS',
        { projectId: number; userId: number; userEmail: string }
      >,
    ) => {
      return projects.map(project => {
        if (project.id !== action.payload.projectId) return project;
        return {
          ...project,
          pendingByUsers: (project.pendingByUsers || []).filter(
            (member: Member) => member.email !== action.payload.userEmail,
          ),
        };
      });
    },
  )
  .handleAction(
    fetchProjectResourcesAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_RESOURCES_SUCCESS',
        { id: number; canEditResources: boolean; resources: Resource[]; canEditPrivacy: boolean }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.id) return project;
        return {
          ...project,
          canEditResources: action.payload.canEditResources,
          canEditPrivacy: action.payload.canEditPrivacy,
          resources: action.payload.resources,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchProjectResourcesTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'FETCH_PROJECT_RESOURCES_TAGS_SUCCESS', { id: number; tags: string[] }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.id) return project;
        return {
          ...project,
          resourcesTags: action.payload.tags,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchProjectDiscussionsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_DISCUSSIONS_SUCCESS',
        { id: number; canEditDiscussions: boolean; discussions: Discussion[]; canEditPrivacy: boolean }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.id) return project;
        return {
          ...project,
          canEditPrivacy: action.payload.canEditPrivacy,
          canEditDiscussions: action.payload.canEditDiscussions,
          discussions: action.payload.discussions,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchDiscussionAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_DISCUSSION_SUCCESS',
        {
          entityId: number;
          entityType: string;
          discussion: Discussion;
          canEditDiscussions: boolean;
          canEditPrivacy: boolean;
        }
      >,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return map(project => {
        if (project.id !== action.payload.entityId) return project;
        if (!project.discussions || project.discussions.length === 0) {
          return {
            ...project,
            canEditDiscussions: action.payload.canEditDiscussions,
            canEditPrivacy: action.payload.canEditPrivacy,
            discussions: append(action.payload.discussion, []),
          };
        }
        const existingDiscussion = (project.discussions || []).find(
          (d: Discussion) => d.id === action.payload.discussion.id,
        );
        if (existingDiscussion) {
          return {
            ...project,
            canEditDiscussions: action.payload.canEditDiscussions,
            canEditPrivacy: action.payload.canEditPrivacy,
            discussions: project.discussions.map((d: Discussion) => {
              if (d.id === action.payload.discussion.id) {
                return {
                  ...d,
                  ...action.payload.discussion,
                };
              } else {
                return {
                  ...d,
                };
              }
            }),
          };
        } else {
          return {
            ...project,
            canEditDiscussions: action.payload.canEditDiscussions,
            canEditPrivacy: action.payload.canEditPrivacy,
            discussions: [action.payload.discussion, ...(project.discussions || [])],
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchDiscussionPrivacyCirclesAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_DISCUSSION_PRIVACY_CIRCLES_SUCCESS',
        { entityId: number; entityType: string; discussionId: number; circles: Circle[] }
      >,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return projects.map((p: Project) => {
        if (p.id !== action.payload.entityId) return p;
        return {
          ...p,
          discussions: p.discussions?.map((d: Discussion) => {
            if (d.id !== action.payload.discussionId) return d;
            return {
              ...d,
              privacyCircles: action.payload.circles,
            };
          }),
        };
      });
    },
  )
  .handleAction(
    createNewDiscussionAsync.success,
    (projects: Project[], action: PayloadAction<'CREATE_NEW_DISCUSSION_SUCCESS', Discussion>) => {
      return map(project => {
        if (project.id !== action.payload.projectId) return project;
        if (project.discussions) {
          const index = findIndex(propEq('id', action.payload.id))(project.discussions);
          if (index === -1) {
            return {
              ...project,
              discussions: append(action.payload, project.discussions),
            };
          } else {
            return {
              ...project,
              discussions: update(index, action.payload, project.discussions),
            };
          }
        } else {
          return {
            ...project,
            discussions: append(action.payload, []),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    deleteDiscussionAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'DELETE_DISCUSSION_SUCCESS',
        { entityId: number; entityType: string; discussionId: number }
      >,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return map(project => {
        if (project.id !== action.payload.entityId) return project;
        return {
          ...project,
          discussions: (project.discussions || []).filter((d: Discussion) => d.id !== action.payload.discussionId),
        };
      }, projects);
    },
  )
  .handleAction(
    updateDiscussionIsArchivedAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'UPDATE_DISCUSSION_IS_ARCHIVED_SUCCESS', { discussion: Discussion }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.discussion.projectId) return project;
        return {
          ...project,
          discussions: (project.discussions || []).map((d: Discussion) => {
            if (d.id !== action.payload.discussion.id) return d;
            return {
              ...d,
              isArchived: action.payload.discussion.isArchived,
            };
          }),
        };
      }, projects);
    },
  )
  .handleAction(
    fetchProjectCirclesAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'FETCH_PROJECT_CIRCLES_SUCCESS', { id: number; circles: Circle[] }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.id) return project;
        return {
          ...project,
          circles: action.payload.circles.map((circle: Circle) => ({
            ...circle,
            eventMembers: (project.circles || []).find((c: Circle) => c.id === circle.id)?.eventMembers,
          })),
        };
      }, projects);
    },
  )
  .handleAction(
    saveProjectCircleAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'SAVE_PROJECT_CIRCLE_SUCCESS', { projectId: number; circle: Circle }>,
    ) => {
      return map(p => {
        if (p.id !== action.payload.projectId) {
          return p;
        } else {
          let found = false;
          const circles = (p.circles || []).map((c: Circle) => {
            if (c.id === action.payload.circle.id) {
              found = true;
              return {
                ...c,
                name: action.payload.circle.name,
              };
            } else {
              return c;
            }
          });
          return {
            ...p,
            circles: found ? circles : [...(p.circles || []), action.payload.circle],
          };
        }
      }, projects);
    },
  )
  .handleAction(
    saveProjectCirclesAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'SAVE_PROJECT_CIRCLES_SUCCESS', { projectId: number; circles: Circle[] }>,
    ) => {
      return map(p => {
        if (p.id !== action.payload.projectId) {
          return p;
        } else {
          return {
            ...p,
            circles: (p.circles || []).map((c: Circle) => {
              const newCircle = action.payload.circles.find((circ: Circle) => circ.id === c.id);
              if (newCircle) {
                return {
                  ...c,
                  name: newCircle.name,
                };
              } else {
                return c;
              }
            }),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    deleteProjectCircleAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'DELETE_PROJECT_CIRCLE_SUCCESS', { projectId: number; circleId: number }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          const newCircles = clone(project.circles);
          let foundIndex: number = -1;
          const foundNewCircle = project.circles?.filter((circle: Circle, index: number) => {
            if (circle.id === action.payload.circleId) {
              foundIndex = index;
              return true;
            } else {
              return false;
            }
          });
          if (newCircles && foundNewCircle && foundNewCircle.length > 0) {
            newCircles.splice(foundIndex, 1);
            return {
              ...project,
              circles: newCircles,
            };
          } else {
            return {
              ...project,
            };
          }
        }
      }, projects);
    },
  )
  .handleAction(
    editProjectCircleAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'EDIT_PROJECT_CIRCLE_SUCCESS', { id: number; circleId: number; userId: number }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.id) {
          return project;
        } else {
          const previousCircleId = (project.members ? project.members : []).find(
            (member: Member) => member.id === action.payload.userId,
          )?.circle.id;
          return {
            ...project,
            members: map(
              (member: Member) => {
                if (member.id === action.payload.userId) {
                  // TODO: refactor: add name and isAdmin in circle
                  return {
                    ...member,
                    circle: { id: action.payload.circleId, name: '', admin: false },
                  };
                }
                return {
                  ...member,
                };
              },
              project.members ? project.members : [],
            ),
            circles: project.circles?.map((circle: Circle) => {
              if (circle.id === action.payload.circleId) {
                return {
                  ...circle,
                  userCount: (circle.userCount || 0) + 1,
                };
              } else if (circle.id === previousCircleId) {
                return {
                  ...circle,
                  userCount: (circle.userCount || 1) - 1,
                };
              } else {
                return circle;
              }
            }),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    followUserAsync.success,
    (projects: Project[], action: PayloadAction<'FOLLOW_USER_SUCCESS', number>) => {
      return map(
        project => ({
          ...project,
          members: project.members
            ? map(member => {
                if (member.id !== action.payload) return member;
                else
                  return {
                    ...member,
                    isFollowed: true,
                  };
              }, project.members)
            : [],
        }),
        projects,
      );
    },
  )
  .handleAction(
    unfollowUserAsync.success,
    (projects: Project[], action: PayloadAction<'UNFOLLOW_USER_SUCCESS', number>) => {
      return map(
        project => ({
          ...project,
          members: project.members
            ? map(member => {
                if (member.id !== action.payload) return member;
                else
                  return {
                    ...member,
                    isFollowed: false,
                  };
              }, project.members)
            : [],
        }),
        projects,
      );
    },
  )
  .handleAction(
    fetchCirclesByDiscussionsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_CIRCLES_BY_DISCUSSIONS_SUCCESS',
        { entityId: number; entityType: string; circles: Circle[] }
      >,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return map((project: Project) => {
        if (project.id !== action.payload.entityId) return project;
        return {
          ...project,
          discussionCircles: action.payload.circles,
        };
      }, projects);
    },
  )
  .handleAction(
    fetchDiscussionsTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'FETCH_DISCUSSIONS_TAGS_SUCCESS', { entityId: number; entityType: string; tags: string[] }>,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return map(project => {
        if (project.id !== action.payload.entityId) return project;
        return {
          ...project,
          discussionTags: action.payload.tags,
        };
      }, projects);
    },
  )
  .handleAction(
    saveCommunityProjectsTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'SAVE_COMMUNITY_PROJECTS_TAGS_SUCCESS',
        { projectId: number; communityId: number; tags: string[] }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            tags: action.payload.tags,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchProjectFeedAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'FETCH_PROJECT_FEED_SUCCESS', { id: number; feed: FeedItem[]; skip: number; take: number }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.id) {
          return project;
        } else {
          if (action.payload.skip === 0) {
            return {
              ...project,
              feed: action.payload.feed,
              feedAllLoaded: action.payload.feed.length < action.payload.take,
            };
          } else {
            return {
              ...project,
              feed: concat(project.feed as FeedItem[], action.payload.feed),
              feedAllLoaded: action.payload.feed.length < action.payload.take,
            };
          }
        }
      }, projects);
    },
  )
  .handleAction(
    addProjectPostAsync.success,
    (projects: Project[], action: PayloadAction<'ADD_PROJECT_POST_SUCCESS', { post: ProjectPost }>) => {
      return map(project => {
        if (project.id !== action.payload.post.project.id) {
          return project;
        } else {
          return {
            ...project,
            feed: prepend(action.payload.post, project.feed as FeedItem[]),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    likeFeedPostAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'LIKE_FEED_POST_SUCCESS', { id: number; entityId: number; type: CommentType }>,
    ) =>
      map(project => {
        if (project.id !== action.payload.entityId) return project;
        if (action.payload.type === CommentType.Discussion) {
          return {
            ...project,
            discussions: (project.discussions || []).map((d: Discussion) => {
              return {
                ...d,
                comments: recursiveCommentLike(d.comments, action.payload.id, true),
              };
            }),
          };
        }
        if (action.payload.type === CommentType.BusinessModelParagraph) {
          return {
            ...project,
            businessModel: {
              ...project.businessModel,
              sections: project.businessModel.sections.map((s: BusinessModelSection) => {
                return {
                  ...s,
                  assignments: s.assignments.map((a: BusinessModelAssignment) => {
                    return {
                      ...a,
                      comments: recursiveCommentLike(a.comments, action.payload.id, true),
                    };
                  }),
                };
              }),
            },
          };
        }
        if (action.payload.type === CommentType.Request) {
          return {
            ...project,
            requests: (project.requests || []).map((r: Request) => {
              const defaultDetails = {
                postDate: '',
                lastUpdated: '',
                privacyLevel: 0,
              };
              return {
                ...r,
                details: {
                  ...(r.details || defaultDetails),
                  comments: recursiveCommentLike(r.details?.comments || [], action.payload.id, true),
                },
              };
            }),
          };
        }
        if (project.feed) {
          return {
            ...project,
            feed: project.feed.map((f: FeedItem) => {
              if (f.id === action.payload.id)
                return {
                  ...f,
                  isLiked: true,
                  likeCount: 'likeCount' in f ? f.likeCount + 1 : 1,
                };
              return {
                ...f,
                comments: recursiveCommentLike(f.comments, action.payload.id, true),
              };
            }),
          };
        } else {
          return project;
        }
      }, projects),
  )
  .handleAction(
    unlikeFeedPostAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'UNLIKE_FEED_POST_SUCCESS', { id: number; entityId: number; type: CommentType }>,
    ) =>
      map(project => {
        if (project.id !== action.payload.entityId) return project;
        if (action.payload.type === CommentType.Discussion) {
          return {
            ...project,
            discussions: (project.discussions || []).map((discussion: Discussion) => {
              return {
                ...discussion,
                comments: recursiveCommentLike(discussion.comments, action.payload.id, false),
              };
            }),
          };
        }
        if (action.payload.type === CommentType.BusinessModelParagraph) {
          return {
            ...project,
            businessModel: {
              ...project.businessModel,
              sections: project.businessModel.sections.map((businessModelSection: BusinessModelSection) => {
                return {
                  ...businessModelSection,
                  assignments: businessModelSection.assignments.map((a: BusinessModelAssignment) => {
                    return {
                      ...a,
                      comments: recursiveCommentLike(a.comments, action.payload.id, false),
                    };
                  }),
                };
              }),
            },
          };
        }
        if (action.payload.type === CommentType.Request) {
          return {
            ...project,
            requests: (project.requests || []).map((r: Request) => {
              const defaultDetails = {
                postDate: '',
                lastUpdated: '',
                privacyLevel: 0,
              };
              return {
                ...r,
                details: {
                  ...(r.details || defaultDetails),
                  comments: recursiveCommentLike(r.details?.comments || [], action.payload.id, false),
                },
              };
            }),
          };
        }
        if (project.feed) {
          return {
            ...project,
            feed: project.feed.map((feedItem: FeedItem) => {
              if (feedItem.id === action.payload.id)
                return { ...feedItem, isLiked: false, likeCount: 'likeCount' in feedItem ? feedItem.likeCount - 1 : 0 };
              return {
                ...feedItem,
                comments: recursiveCommentLike(feedItem.comments, action.payload.id, false),
              };
            }),
          };
        } else {
          return project;
        }
      }, projects),
  )
  .handleAction(
    saveRequestCommentAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'SAVE_REQUEST_COMMENT_SUCCESS', { projectId: number; requestId: number; comment: Comment }>,
    ) => {
      const {
        payload: { projectId, requestId, comment },
      } = action;
      return map(project => {
        if (project.id !== projectId) return project;
        const defaultDetails = {
          postDate: '',
          lastUpdated: '',
          privacyLevel: 0,
        };
        return {
          ...project,
          requests: (project.requests || []).map((request: Request) => {
            if (request.id !== requestId) return request;
            const comments = request.details?.comments || [];
            if (comment.parentId === 0) {
              if (comments.find((c: Comment) => c.id === comment.id)) {
                return {
                  ...request,
                  details: {
                    ...(request.details || defaultDetails),
                    comments: comments.map((c: Comment) => {
                      if (c.id !== comment.id) return c;
                      return { ...c, content: comment.content };
                    }),
                  },
                };
              } else {
                return {
                  ...request,
                  commentCount: request.commentCount + 1,
                  details: {
                    ...(request.details || defaultDetails),
                    comments: [comment, ...comments],
                  },
                };
              }
            } else {
              const parentComment = (request.details?.comments || []).find((c: Comment) => c.id === comment.parentId);
              const newComment = parentComment && parentComment.comments.find((c: Comment) => c.id === comment.id);
              return {
                ...request,
                commentCount: request.commentCount + (newComment ? 0 : 1),
                details: {
                  ...(request.details || defaultDetails),
                  comments: comments.map((c1: Comment) => {
                    if (c1.id !== comment.parentId) return c1;
                    if (c1.comments.find((c2: Comment) => c2.id === comment.id)) {
                      return {
                        ...c1,
                        comments: c1.comments.map((c2: Comment) => {
                          if (c2.id !== comment.id) return c2;
                          return { ...c2, content: comment.content };
                        }),
                      };
                    } else {
                      return {
                        ...c1,
                        comments: [...c1.comments, comment],
                      };
                    }
                  }),
                },
              };
            }
          }),
        };
      }, projects);
    },
  )
  .handleAction(
    deleteRequestCommentAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'DELETE_REQUEST_COMMENT_SUCCESS',
        { projectId: number; requestId: number; commentId: number }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        return {
          ...project,
          requests: (project.requests || []).map((request: Request) => {
            if (request.id !== action.payload.requestId) return request;
            const defaultDetails = {
              postDate: '',
              lastUpdated: '',
              privacyLevel: 0,
            };
            const comments = request.details?.comments || [];
            const deletedComment = comments.find((c: Comment) => c.id === action.payload.commentId);
            if (deletedComment) {
              return {
                ...request,
                commentCount: request.commentCount - 1 - deletedComment.comments.length,
                details: {
                  ...(request.details || defaultDetails),
                  comments: comments.filter((c: Comment) => c.id !== action.payload.commentId),
                },
              };
            } else {
              return {
                ...request,
                commentCount: request.commentCount - 1,
                details: {
                  ...(request.details || defaultDetails),
                  comments: comments.map((c: Comment) => {
                    return {
                      ...c,
                      comments: c.comments.filter((reply: Comment) => reply.id !== action.payload.commentId),
                    };
                  }),
                },
              };
            }
          }),
        };
      });
    },
  )
  .handleAction(
    addCommentToDiscussionAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'ADD_COMMENT_DISCUSSIONS_SUCCESS',
        { discussionId: number; entityId: number; entityType: string; comment: Comment }
      >,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return map(project => {
        if (project.id !== action.payload.entityId) return project;
        if (project.discussions) {
          return {
            ...project,
            discussions: map(discussion => {
              if (discussion.id !== action.payload.discussionId) {
                return discussion;
              } else {
                if (action.payload.comment.parentId === 0) {
                  const newComments = clone(discussion.comments);
                  return {
                    ...discussion,
                    commentCount: discussion.commentCount + 1,
                    comments: prepend(action.payload.comment, newComments),
                  };
                } else {
                  return {
                    ...discussion,
                    commentCount: discussion.commentCount + 1,
                    comments: map((comment: Comment) => {
                      if (comment.id === action.payload.comment.parentId) {
                        const newComments = clone(comment.comments);
                        return {
                          ...comment,
                          comments: append(action.payload.comment, newComments),
                        };
                      } else {
                        return {
                          ...comment,
                        };
                      }
                    }, discussion.comments as Comment[]),
                  };
                }
              }
            }, project.discussions),
          };
        } else {
          return project;
        }
      }, projects);
    },
  )
  .handleAction(
    addCommentToFeedAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'ADD_COMMENT_TO_FEED_SUCCESS',
        { entityId: number; projectId: number; comment: Comment; itemType: CommentType; itemId: number }
      >,
    ) => {
      if (action.payload.itemType === CommentType.Discussion)
        return projects.map((project: Project) => {
          if (project.id !== action.payload.entityId) {
            return project;
          } else {
            return {
              ...project,
              discussions:
                project.discussions?.map((discussion: Discussion) => {
                  if (discussion.id !== action.payload.itemId) {
                    return discussion;
                  } else {
                    return {
                      ...discussion,
                      comments: discussion.comments.map((firstComment: Comment) => {
                        if (firstComment.id === action.payload.comment.parentId) {
                          if (
                            !firstComment.comments.find(
                              (secondComment: Comment) => secondComment.id === action.payload.comment.id,
                            )
                          ) {
                            return {
                              ...firstComment,
                              comments: [...firstComment.comments, action.payload.comment],
                            };
                          }
                        }
                        if (firstComment.id !== action.payload.comment.id) {
                          return {
                            ...firstComment,
                            comments: firstComment.comments.map((secondComment: Comment) => {
                              if (secondComment.id !== action.payload.comment.id) {
                                return secondComment;
                              } else {
                                return {
                                  ...secondComment,
                                  content: action.payload.comment.content,
                                };
                              }
                            }),
                          };
                        } else {
                          return {
                            ...firstComment,
                            content: action.payload.comment.content,
                          };
                        }
                      }),
                    };
                  }
                }) || [],
            };
          }
        });
      if (action.payload.itemType === CommentType.BusinessModelParagraph)
        return projects.map((project: Project) => {
          if (project.id !== action.payload.entityId) {
            return project;
          } else {
            return {
              ...project,
              businessModel: {
                ...project.businessModel,
                sections: project.businessModel.sections.map((section: BusinessModelSection) => {
                  return {
                    ...section,
                    assignments: section.assignments.map((assignment: BusinessModelAssignment) => {
                      if (assignment.id !== action.payload.itemId) {
                        return assignment;
                      } else {
                        return {
                          ...assignment,
                          comments: assignment.comments.map((firstComment: Comment) => {
                            if (firstComment.id === action.payload.comment.parentId) {
                              if (
                                !firstComment.comments.find(
                                  (secondComment: Comment) => secondComment.id === action.payload.comment.id,
                                )
                              ) {
                                return {
                                  ...firstComment,
                                  comments: [...firstComment.comments, action.payload.comment],
                                };
                              }
                            }
                            if (firstComment.id !== action.payload.comment.id) {
                              return {
                                ...firstComment,
                                comments: firstComment.comments.map((secondComment: Comment) => {
                                  if (secondComment.id !== action.payload.comment.id) {
                                    return secondComment;
                                  } else {
                                    return {
                                      ...secondComment,
                                      content: action.payload.comment.content,
                                    };
                                  }
                                }),
                              };
                            } else {
                              return {
                                ...firstComment,
                                content: action.payload.comment.content,
                              };
                            }
                          }),
                        };
                      }
                    }),
                  };
                }),
              },
            };
          }
        });
      return map(project => {
        if (project.id !== action.payload.entityId) {
          return project;
        } else {
          const isNewComment = !project.feed?.some(
            (feedItem: FeedItem) =>
              feedItem.id === action.payload.comment.id ||
              feedItem.comments.some(
                (commentItem: Comment) =>
                  commentItem.id === action.payload.comment.id ||
                  commentItem.comments.some(
                    (secondLevelComment: Comment) => secondLevelComment.id === action.payload.comment.id,
                  ),
              ),
          );

          if (!isNewComment) {
            return {
              ...project,
              feed: project.feed?.map((feedItem: FeedItem) => {
                if (feedItem.id === action.payload.comment.id) {
                  return {
                    ...feedItem,
                    content: action.payload.comment.content,
                  };
                } else {
                  return {
                    ...feedItem,
                    comments: feedItem.comments.map((comment: Comment) => {
                      if (comment.id === action.payload.comment.id) {
                        return {
                          ...comment,
                          content: action.payload.comment.content,
                        };
                      } else {
                        return comment;
                      }
                    }),
                  };
                }
              }),
            };
          } else {
            return {
              ...project,
              feed: map((feedItem: FeedItem) => {
                if (feedItem.id === action.payload.comment.id) {
                  return {
                    ...feedItem,
                    content: action.payload.comment.content,
                  };
                }
                if (feedItem.id !== action.payload.comment.parentId) {
                  // add comment to level 1 comment
                  const comments = map(comment => {
                    if (comment.id !== action.payload.comment.parentId) {
                      return comment;
                    } else {
                      return {
                        ...comment,
                        comments: append(action.payload.comment, comment.comments),
                      };
                    }
                  }, feedItem.comments);
                  return {
                    ...feedItem,
                    comments,
                    commentCount:
                      feedItem.commentCount + // only add +1 if the comment is found in the children comments
                      (feedItem.comments.some(comment => comment.id === action.payload.comment.parentId) ? 1 : 0),
                  };
                } else {
                  // add comment to post
                  return {
                    ...feedItem,
                    comments: append(action.payload.comment, feedItem.comments),
                    commentCount: feedItem.commentCount + 1,
                  };
                }
              }, project.feed as FeedItem[]),
            };
          }
        }
      }, projects);
    },
  )
  .handleAction(
    createProjectAsync.success,
    (projects: Project[], action: PayloadAction<'CREATE_PROJECT_SUCCESS', Project>) => {
      return append(action.payload, projects);
    },
  )
  .handleAction(
    updateProjectAsync.success,
    (projects: Project[], action: PayloadAction<'UPDATE_PROJECT_SUCCESS', { project: Project }>) => {
      return map(project => {
        if (project.id !== action.payload.project.id) return project;
        return { ...project, ...action.payload.project };
      }, projects);
    },
  )
  .handleAction(
    removeProjectAsync.success,
    (projects: Project[], action: PayloadAction<'REMOVE_PROJECT_SUCCESS', { projectId: number }>) => {
      return filter((p: Project) => p.id !== action.payload.projectId, projects);
    },
  )
  .handleAction(
    fetchResourceCirclesAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_RESOURCE_CIRCLES_SUCCESS',
        { entityId: number; circles: Circle[]; resourceId?: number; isCommunityResource: boolean }
      >,
    ) => {
      if (action.payload.isCommunityResource) return projects;
      const newProjects = [...projects];
      const projectIndex = projects.findIndex((p: Project) => p.id === action.payload.entityId);
      if (projectIndex !== -1) {
        return map(project => {
          if (project.id !== action.payload.entityId) return project;
          return {
            ...project,
            resourcesCircles: action.payload.circles,
          };
        }, projects);
      }
      const resourceIndex = projects[projectIndex].resources?.findIndex((resource: Resource) => {
        return resource.id === action.payload?.resourceId;
      });

      if (resourceIndex !== -1) {
        return map(project => {
          if (project.id !== action.payload.entityId) return project;
          return {
            ...project,
            resourcesCircles: action.payload.circles,
          };
        }, projects);
      }

      // @ts-ignore
      if (!newProjects?.[projectIndex]?.resources?.[resourceIndex]?.circles) return projects;

      // @ts-ignore
      newProjects[projectIndex].resources[resourceIndex].circles = action.payload.circles.filter(
        (c: Circle) => c.isActive,
      );
      return newProjects;
    },
  )
  .handleAction(
    createResourceAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'CREATE_RESOURCE_SUCCESS',
        { resource: Resource; entityId: number; isCommunityResource: boolean }
      >,
    ) => {
      if (action.payload.isCommunityResource) return projects;
      return map(project => {
        if (project.id !== action.payload.entityId) {
          return project;
        } else {
          if (project.resources?.some((resource: Resource) => resource.id === action.payload.resource.id)) {
            return {
              ...project,
              resources: project.resources?.map((resource: Resource) => {
                if (resource.id === action.payload.resource.id) {
                  return action.payload.resource;
                } else {
                  return resource;
                }
              }),
            };
          } else {
            return {
              ...project,
              resources: prepend(action.payload.resource, project.resources ? project.resources : []),
            };
          }
        }
      }, projects);
    },
  )
  .handleAction(
    createResourcesAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'CREATE_RESOURCES_SUCCESS',
        { resources: Resource[]; entityId: number; isCommunityResource: boolean }
      >,
    ) => {
      if (action.payload.isCommunityResource) return projects;
      return map(project => {
        if (project.id !== action.payload.entityId) {
          return project;
        } else {
          const duplicateResources = [...action.payload.resources, ...(project.resources || [])];
          const duplicateResourcesIds = duplicateResources.map((resource: Resource) => resource.id);
          return {
            ...project,
            resources: duplicateResources.filter(
              (r: Resource, idx: number) => duplicateResourcesIds.indexOf(r.id) === idx,
            ),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    deleteResourceAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'DELETE_RESOURCE_SUCCESS',
        { resourceId: number; entityId: number; isCommunityResource: boolean }
      >,
    ) => {
      if (action.payload.isCommunityResource) return projects;
      return map(project => {
        if (project.id !== action.payload.entityId) {
          return project;
        } else {
          return {
            ...project,
            resources: filter(
              (resource: Resource) => resource.id !== action.payload.resourceId,
              project.resources ? project.resources : [],
            ),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchProjectBusinessModelsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_BUSINESS_MODELS_SUCCESS',
        { projectId: number; projectBusinessModelList: ProjectBusinessModel[] }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        return {
          ...project,
          projectBusinessModels: action.payload.projectBusinessModelList,
        };
      });
    },
  )
  .handleAction(
    fetchBusinessModelByProjectIdAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_BUSINESS_MODEL_SUCCESS',
        { projectId: number; businessModel: BusinessModel; methodologyId: number }
      >,
    ) => {
      return map((project: Project) => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          const matchingProjectBusinessModel = project.projectBusinessModels?.find(
            x => x.methodologyId === action.payload.methodologyId,
          );
          return {
            ...project,
            businessModel: {
              ...action.payload.businessModel,
              customPrivacyCircles:
                action.payload.businessModel.customPrivacyCircles.length > 0
                  ? action.payload.businessModel.customPrivacyCircles
                  : project.businessModel.customPrivacyCircles,
              sections: action.payload.businessModel.sections.map((businessModelSection: BusinessModelSection) => {
                const currentStoredSection = project.businessModel.sections.filter(
                  (storeS: BusinessModelSection) => storeS.id === businessModelSection.id,
                );
                if (currentStoredSection.length === 0) return businessModelSection;
                return {
                  ...businessModelSection,
                  assignments: businessModelSection.assignments.map(
                    (businessModelAssignment: BusinessModelAssignment) => {
                      const currentStoredAssignment = currentStoredSection[0].assignments.filter(
                        (storeA: BusinessModelAssignment) => storeA.id === businessModelAssignment.id,
                      );
                      const assigment = {
                        ...businessModelAssignment,
                        canView: canViewParagraph(
                          businessModelAssignment,
                          project,
                          matchingProjectBusinessModel?.taskUnlockRule,
                        ),
                        canViewByCalendarPlanning: canViewByCalendarPlanning(
                          businessModelAssignment,
                          project,
                          matchingProjectBusinessModel?.taskUnlockRule,
                        ),
                        canViewBySequentialPlanning: canViewBySequentialPlanning(
                          businessModelAssignment,
                          project,
                          matchingProjectBusinessModel?.taskUnlockRule,
                        ),
                        dueTask: getDueTask(
                          businessModelAssignment,
                          project,
                          matchingProjectBusinessModel?.taskUnlockRule,
                        ),
                      };

                      if (currentStoredAssignment.length === 0) return assigment;
                      return {
                        ...assigment,
                        comments: currentStoredAssignment[0].comments,
                        customPrivacyCircles: currentStoredAssignment[0].customPrivacyCircles,
                      };
                    },
                  ),
                };
              }),
            },
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchProjectTasksAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_TASKS_SUCCESS',
        { projectId: number; projectTasks: ProjectTask[]; taskUnlockRule: Planner }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            taskUnlockRule: action.payload.taskUnlockRule,
            projectTasks: action.payload.projectTasks,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    saveProjectTasksAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'SAVE_PROJECT_TASKS_SUCCESS',
        {
          projectTask: {
            id: number;
            content: string;
            isActive: boolean;
            privacyLevel: number;
            sectionId: number;
            customPrivacyCircles?: Circle[];
          };
          projectId: number;
        }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId || !project.businessModel) {
          return project;
        } else {
          if (project.businessModel.sections && project.businessModel.sections.length > 0) {
            return {
              ...project,
              businessModel: {
                ...project.businessModel,
                sections: map((businessModelSection: BusinessModelSection) => {
                  if (businessModelSection.id === action.payload.projectTask.sectionId) {
                    return {
                      ...businessModelSection,
                      assignments: map((businessModelAssignment: BusinessModelAssignment) => {
                        if (businessModelAssignment.id === action.payload.projectTask.id) {
                          const customPrivacyCircles = action.payload.projectTask.customPrivacyCircles;
                          return {
                            ...businessModelAssignment,
                            content: action.payload.projectTask.content,
                            isActive: action.payload.projectTask.isActive,
                            privacyLevel: action.payload.projectTask.privacyLevel,
                            customPrivacyCircles:
                              customPrivacyCircles && customPrivacyCircles.length > 0
                                ? customPrivacyCircles
                                : businessModelAssignment.customPrivacyCircles,
                          };
                        } else {
                          return {
                            ...businessModelAssignment,
                          };
                        }
                      }, businessModelSection.assignments),
                    };
                  } else {
                    return {
                      ...businessModelSection,
                    };
                  }
                }, project.businessModel.sections),
              },
            };
          } else {
            return { ...project };
          }
        }
      }, projects);
    },
  )
  .handleAction(
    saveSectionStagegateLockedAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'SAVE_SECTION_STAGEGATE_LOCKED_SUCCESS',
        { projectId: number; sectionId: number; isStagegateLocked: boolean }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        return {
          ...project,
          businessModel: {
            ...project.businessModel,
            sections: project.businessModel.sections.map((section: BusinessModelSection) => {
              if (section.id !== action.payload.sectionId) return section;
              return {
                ...section,
                isStagegateLocked: action.payload.isStagegateLocked,
              };
            }),
          },
        };
      });
    },
  )
  .handleAction(
    fetchBusinessModelPrivacyCirclesAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_BUSINESS_MODEL_PRIVACY_CIRCLES_SUCCESS',
        { projectId: number; paragraphId: number; circles: Circle[] }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId || !project.businessModel) {
          return project;
        } else {
          return {
            ...project,
            businessModel: {
              ...project.businessModel,
              customPrivacyCircles: action.payload.circles,
              sections: project.businessModel.sections.map((businessModelSection: BusinessModelSection) => ({
                ...businessModelSection,
                assignments: businessModelSection.assignments.map(
                  (businessModelAssignment: BusinessModelAssignment) => {
                    if (businessModelAssignment.id !== action.payload.paragraphId) return businessModelAssignment;
                    return {
                      ...businessModelAssignment,
                      customPrivacyCircles: action.payload.circles.filter((circle: Circle) => circle.isActive),
                    };
                  },
                ),
              })),
            },
          };
        }
      }, projects);
    },
  )
  .handleAction(
    fetchCommentsForBusinessModelParagraphAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_COMMENTS_FOR_BUSINESS_MODEL_PARAGRAPH_SUCCESS',
        { projectId: number; paragraphId: number; comments: Comment[] }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            businessModel: {
              ...project.businessModel,
              sections: map((businessModelSection: BusinessModelSection) => {
                return {
                  ...businessModelSection,
                  assignments: map((businessModelAssignment: BusinessModelAssignment) => {
                    if (businessModelAssignment.id === action.payload.paragraphId) {
                      return {
                        ...businessModelAssignment,
                        comments: action.payload.comments,
                      };
                    } else {
                      return {
                        ...businessModelAssignment,
                      };
                    }
                  }, businessModelSection.assignments),
                };
              }, project.businessModel.sections),
            },
          };
        }
      }, projects);
    },
  )
  .handleAction(
    addCommentToBusinessModelParagraphAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'ADD_COMMENT_TO_BUSINESS_MODEL_PARAGRAPH_SUCCESS',
        { projectId: number; paragraphId: number; parentId: number; comment: Comment }
      >,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId || !project.businessModel) {
          return project;
        } else {
          return {
            ...project,
            businessModel: {
              ...project.businessModel,
              sections: map((businessModelSection: BusinessModelSection) => {
                return {
                  ...businessModelSection,
                  assignments: map((businessModelAssignment: BusinessModelAssignment) => {
                    if (businessModelAssignment.id === action.payload.paragraphId) {
                      if (action.payload.comment.parentId === 0) {
                        const newComments = clone(businessModelAssignment.comments);
                        return {
                          ...businessModelAssignment,
                          comments: prepend(action.payload.comment, newComments),
                        };
                      } else {
                        return {
                          ...businessModelAssignment,
                          comments: map((comment: Comment) => {
                            if (comment.id === action.payload.comment.parentId) {
                              const newComments = clone(comment.comments);
                              return {
                                ...comment,
                                comments: append(action.payload.comment, newComments),
                              };
                            } else {
                              return {
                                ...comment,
                              };
                            }
                          }, businessModelAssignment.comments as Comment[]),
                        };
                      }
                    } else {
                      return {
                        ...businessModelAssignment,
                      };
                    }
                  }, businessModelSection.assignments),
                };
              }, project.businessModel.sections),
            },
          };
        }
      }, projects);
    },
  )
  .handleAction(
    updateProjectLogoAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'UPDATE_PROJECT_LOGO_SUCCESS', { projectId: number; logo: string }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            logo: action.payload.logo,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    updateProjectCoverAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'UPDATE_PROJECT_COVER_SUCCESS', { projectId: number; cover: string }>,
    ) => {
      return map(project => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            cover: action.payload.cover,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    removeUserFromProjectAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'REMOVE_USER_FROM_PROJECT_SUCCESS', { userId: number; id: number }>,
    ) => {
      return map((project: Project) => {
        if (project.id !== action.payload.id) {
          return project;
        } else {
          if (project.members) {
            const previousCircleId = (project.members ? project.members : []).find(
              (member: Member) => member.id === action.payload.userId,
            )?.circle.id;
            return {
              ...project,
              members: filter(user => user.id !== action.payload.userId, project.members),
              circles: project.circles?.map((circle: Circle) => {
                if (circle.id === previousCircleId) {
                  return {
                    ...circle,
                    userCount: (circle.userCount || 1) - 1,
                  };
                } else {
                  return circle;
                }
              }),
            };
          } else {
            return project;
          }
        }
      }, projects);
    },
  )
  .handleAction(
    followProjectAsync.success,
    (projects: Project[], action: PayloadAction<'FOLLOW_PROJECT_SUCCESS', { projectId: number }>) => {
      return map((project: Project) => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            isFollowing: true,
            networkCount: (project.networkCount || 0) + 1,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    unfollowProjectAsync.success,
    (projects: Project[], action: PayloadAction<'UNFOLLOW_PROJECT_SUCCESS', { projectId: number }>) => {
      return map((project: Project) => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            isFollowing: false,
            networkCount: (project.networkCount || 1) - 1,
          };
        }
      }, projects);
    },
  )
  .handleAction(
    saveProjectDiscussionTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'SAVE_PROJECT_DISCUSSION_TAGS_SUCCESS',
        { discussionId: number; projectId: number; tags: string[] }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            discussions: project.discussions?.map((discussion: Discussion) => {
              if (discussion.id !== action.payload.discussionId) {
                return discussion;
              } else {
                return {
                  ...discussion,
                  discussionTags: action.payload.tags,
                };
              }
            }),
          };
        }
      });
    },
  )
  .handleAction(
    fetchProjectProgressDetailsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_PROGRESS_DETAILS_SUCCESS',
        { progressDetails: ProjectProgressDetails; projectId: number }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            progressDetails: action.payload.progressDetails,
          };
        }
      });
    },
  )
  .handleAction(
    fetchPendingKpiAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'FETCH_PENDING_KPI_SUCCESS', { projectId: number; kpis: KpiValue[] }>,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) {
          return project;
        } else {
          return {
            ...project,
            kpiValues: action.payload.kpis,
          };
        }
      });
    },
  )
  .handleAction(
    saveKpiValuesAsync.success,
    (projects: Project[], action: PayloadAction<'SAVE_KPI_VALUE_SUCCESS', { kpis: KpiValue[] }>) => {
      if (action.payload.kpis.length === 0) return projects;
      return projects.map((project: Project) => {
        if (project.id !== action.payload.kpis[0].projectId) {
          return project;
        } else {
          return {
            ...project,
            kpiValues: project.kpiValues?.map((kpi: KpiValue) => {
              const updatedKpi = action.payload.kpis.find(
                (k: KpiValue) => k.name === kpi.name && k.year === kpi.year && k.month === kpi.month,
              );
              return updatedKpi || kpi;
            }),
          };
        }
      });
    },
  )

  .handleAction(
    deleteProjectKpiAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'DELETE_PROJECT_KPI_SUCCESS', { communityId: number; kpi: KpiDefinition }>,
    ) => {
      return map((project: Project): Project => {
        if (project.id !== action.payload.kpi.projectId) return project;
        if (project.kpis) {
          return {
            ...project,
            kpis: (project.kpis || []).filter((k: KpiDefinition) => k.id !== action.payload.kpi.projectId),
          };
        } else {
          return {
            ...project,
            kpis: [],
          };
        }
      }, projects);
    },
  )

  .handleAction(
    deleteCommentAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'DELETE_COMMENT_SUCCESS',
        { postId: number; postType: CommentType; entityId: number; entityType: string; itemId: number }
      >,
    ) => {
      if (action.payload.postType === CommentType.Discussion)
        return projects.map((project: Project) => {
          if (project.id !== action.payload.entityId) {
            return project;
          } else {
            return {
              ...project,
              discussions:
                project.discussions?.map((discussion: Discussion) => {
                  if (discussion.id !== action.payload.itemId) {
                    return discussion;
                  } else {
                    const isFirstLevelComment = discussion.comments.find(
                      (firstComment: Comment) => firstComment.id === action.payload.postId,
                    );
                    if (isFirstLevelComment) {
                      return {
                        ...discussion,
                        commentCount: discussion.commentCount - 1 - isFirstLevelComment.comments.length,
                        comments: discussion.comments.filter(
                          (firstComment: Comment) => firstComment.id !== action.payload.postId,
                        ),
                      };
                    } else {
                      return {
                        ...discussion,
                        commentCount: discussion.commentCount - 1,
                        comments: discussion.comments.map((firstComment: Comment) => {
                          return {
                            ...firstComment,
                            comments: firstComment.comments.filter(
                              (secondComment: Comment) => secondComment.id !== action.payload.postId,
                            ),
                          };
                        }),
                      };
                    }
                  }
                }) || [],
            };
          }
        });
      if (action.payload.entityType === 'project')
        return projects.map((project: Project) => {
          if (project.id !== action.payload.entityId) return project;
          const isFeedPost = project.feed?.some((feedItem: FeedItem) => feedItem.id === action.payload.postId);
          if (isFeedPost) {
            return {
              ...project,
              feed: project.feed?.filter((feedItem: FeedItem) => feedItem.id !== action.payload.postId) || [],
            };
          } else {
            return {
              ...project,
              feed: project.feed?.map((feedItem: FeedItem) => {
                const isFirstLevelComment = feedItem.comments.find(
                  (firstComment: Comment) => firstComment.id === action.payload.postId,
                );
                if (isFirstLevelComment) {
                  return {
                    ...feedItem,
                    commentCount: feedItem.commentCount - 1 - isFirstLevelComment.comments.length,
                    comments: feedItem.comments.filter(
                      (firstComment: Comment) => firstComment.id !== action.payload.postId,
                    ),
                  };
                } else {
                  return {
                    ...feedItem,
                    commentCount: feedItem.commentCount - 1,
                    comments: feedItem.comments.map((firstComment: Comment) => {
                      return {
                        ...firstComment,
                        comments: firstComment.comments.filter(
                          (secondComment: Comment) => secondComment.id !== action.payload.postId,
                        ),
                      };
                    }),
                  };
                }
              }),
            };
          }
        });
      if (action.payload.entityType === 'business-model')
        return projects.map((p: Project) => {
          if (p.id !== action.payload.entityId) {
            return p;
          } else {
            return {
              ...p,
              businessModel: {
                ...p.businessModel,
                sections: p.businessModel.sections.map((section: BusinessModelSection) => {
                  return {
                    ...section,
                    assignments: section.assignments.map((assignment: BusinessModelAssignment) => {
                      if (assignment.id !== action.payload.itemId) {
                        return assignment;
                      } else {
                        const isFirstLevelComment = assignment.comments.some(
                          (comment: Comment) => comment.id === action.payload.postId,
                        );
                        if (isFirstLevelComment) {
                          return {
                            ...assignment,
                            comments: assignment.comments.filter(
                              (firstComment: Comment) => firstComment.id !== action.payload.postId,
                            ),
                          };
                        } else {
                          return {
                            ...assignment,
                            comments: assignment.comments.map((firstComment: Comment) => {
                              return {
                                ...firstComment,
                                comments: firstComment.comments.filter(
                                  (secondComment: Comment) => secondComment.id !== action.payload.postId,
                                ),
                              };
                            }),
                          };
                        }
                      }
                    }),
                  };
                }),
              },
            };
          }
        });
      return projects;
    },
  )
  .handleAction(
    followDiscussionAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FOLLOW_DISCUSSION_SUCCESS',
        { entityType: string; entityId: number; discussionId: number }
      >,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return projects.map((project: Project) => {
        if (project.id !== action.payload.entityId) return project;
        return {
          ...project,
          discussions: (project.discussions || []).map((discussion: Discussion) => {
            if (discussion.id !== action.payload.discussionId) return discussion;
            return {
              ...discussion,
              isFollowing: true,
            };
          }),
        };
      });
    },
  )
  .handleAction(
    unfollowDiscussionAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'UNFOLLOW_DISCUSSION_SUCCESS',
        { entityType: string; entityId: number; discussionId: number }
      >,
    ) => {
      if (action.payload.entityType !== 'project') return projects;
      return projects.map((project: Project) => {
        if (project.id !== action.payload.entityId) return project;
        return {
          ...project,
          discussions: (project.discussions || []).map((discussion: Discussion) => {
            if (discussion.id !== action.payload.discussionId) return discussion;
            return {
              ...discussion,
              isFollowing: false,
            };
          }),
        };
      });
    },
  )
  .handleAction(
    fetchProjectMembersByCircleAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_MEMBERS_BY_CIRCLE_SUCCESS',
        { projectId: number; circleId: number; skip: number; take: number; members: Member[] }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        return {
          ...project,
          circles: (project.circles || []).map((circle: Circle) => {
            if (circle.id !== action.payload.circleId) return circle;
            if (action.payload.skip === 0) {
              return {
                ...circle,
                eventMembers: action.payload.members,
              };
            } else {
              return {
                ...circle,
                eventMembers: [...(circle.eventMembers || []), ...action.payload.members],
              };
            }
          }),
        };
      });
    },
  )
  .handleAction(
    toggleApprovedAssignmentAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'TOGGLE_APPROVED_ASSIGNMENT_SUCCESS',
        { projectId: number; sectionId: number; assignmentId: number }
      >,
    ) => {
      return projects.map((project: Project) => {
        if (project.id !== action.payload.projectId) return project;
        return {
          ...project,
          businessModel: {
            ...project.businessModel,
            sections: project.businessModel.sections.map((section: BusinessModelSection) => {
              if (section.id !== action.payload.sectionId) return section;
              return {
                ...section,
                assignments: section.assignments.map((assignment: BusinessModelAssignment) => {
                  if (assignment.id !== action.payload.assignmentId) return assignment;
                  return {
                    ...assignment,
                    isApproved: !assignment.isApproved,
                  };
                }),
              };
            }),
          },
        };
      });
    },
  )
  .handleAction(
    fetchProjectKPIDefinitionAsync.success,
    (
      projects: Project[],
      action: PayloadAction<
        'FETCH_PROJECT_KPI_DEFINITION_SUCCESS',
        { projectId: number; kpis: projectKpiDefinition[] }
      >,
    ) => {
      const projectsArr = map((project: Project): Project => {
        if (project.id !== action.payload.projectId) return project;
        return { ...project, kpis: action.payload.kpis };
      }, projects);
      return projectsArr;
    },
  )
  .handleAction(
    upsertProjectKPIAsync.success,
    (projects: Project[], action: PayloadAction<'UPSERT_KPI_SUCCESS', { communityId: number; kpi: KpiDefinition }>) => {
      return map((project: Project): Project => {
        if (project.id !== action.payload.kpi.projectId || !project.kpis) return project;
        return {
          ...project,
          kpis: project.kpis.map((kpi: KpiDefinition) => {
            if (kpi.id === action.payload.kpi.id) return action.payload.kpi;
            return kpi;
          }),
        };
      }, projects);
    },
  )
  .handleAction(
    editProjectTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'EDIT_PROJECT_TAGS_SUCCESS', { projectId: number; tagsList: Tag[]; tagType: TagType }>,
    ) => {
      return map((project: Project): Project => {
        if (project.id !== action.payload.projectId) return project;
        if (action.payload.tagType === TagType.PROJECT_DISCUSSION) {
          return {
            ...project,
            discussionTags: project.discussionTags?.map((tag: string) => {
              const tagObj = action.payload.tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
              if (tagObj) return tagObj.newTagName;
              return tag;
            }),
            discussions: project.discussions?.map((discussion: Discussion) => {
              return {
                ...discussion,
                discussionTags: discussion.discussionTags?.map((tag: string) => {
                  const tagObj = action.payload.tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
                  if (tagObj) return tagObj.newTagName;
                  return tag;
                }),
              };
            }),
          };
        } else {
          return {
            ...project,
            resourcesTags: project.resourcesTags?.map((tag: string) => {
              const tagObj = action.payload.tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
              if (tagObj) return tagObj.newTagName;
              return tag;
            }),
            resources: project.resources?.map((resource: Resource) => {
              return {
                ...resource,
                tags: resource.tags?.map((tag: string) => {
                  const tagObj = action.payload.tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
                  if (tagObj) return tagObj.newTagName;
                  return tag;
                }),
              };
            }),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    deleteProjectTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'DELETE_PROJECT_TAGS_SUCCESS', { projectId: number; tags: string[]; tagType: TagType }>,
    ) => {
      return map((project: Project): Project => {
        if (project.id !== action.payload.projectId) return project;
        if (action.payload.tagType === TagType.PROJECT_DISCUSSION) {
          return {
            ...project,
            discussionTags: project.discussionTags?.filter((tag: string) => !action.payload.tags.includes(tag)),
            discussions: project.discussions?.map((discussion: Discussion) => {
              return {
                ...discussion,
                discussionTags: discussion.discussionTags?.filter((tag: string) => !action.payload.tags.includes(tag)),
              };
            }),
          };
        } else {
          return {
            ...project,
            resourcesTags: project.resourcesTags?.filter((tag: string) => !action.payload.tags.includes(tag)),
            resources: project.resources?.map((resource: Resource) => {
              return {
                ...resource,
                tags: resource.tags?.filter((tag: string) => !action.payload.tags.includes(tag)),
              };
            }),
          };
        }
      }, projects);
    },
  )
  .handleAction(
    updateEditedCommunityProjectTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'UPDATE_EDITED_COMMUNITY_PROJECT_TAGS_SUCCESS', { communityId: number; tagsList: Tag[] }>,
    ) =>
      map((project: Project) => {
        if (project.communityIds[0] !== action.payload.communityId) return project;
        return {
          ...project,
          tags: project.tags.map((tag: string) => {
            const tagObj = action.payload.tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
            if (tagObj) return tagObj.newTagName;
            return tag;
          }),
        };
      }, projects),
  )
  .handleAction(
    updateDeletedCommunityProjectTagsAsync.success,
    (
      projects: Project[],
      action: PayloadAction<'UPDATE_DELETED_COMMUNITY_PROJECT_TAGS_SUCCESS', { communityId: number; tags: string[] }>,
    ) =>
      map((project: Project) => {
        if (project.communityIds[0] !== action.payload.communityId) return project;
        return {
          ...project,
          tags: project.tags.filter((tag: string) => !action.payload.tags.includes(tag)),
        };
      }, projects),
  )
  .handleAction(
    editProjectRequestTags,
    (projects: Project[], action: PayloadAction<'EDIT_PROJECT_REQUEST_TAGS', { projectId: string; tags: Tag[] }>) =>
      map((project: Project) => {
        if (project.id !== parseInt(action.payload.projectId)) return project;

        const requests = project.requests?.map((request: Request) => {
          return {
            ...request,
            tags: request.tags.map(
              (tag: string) =>
                action.payload.tags.find((paylodTag: Tag) => paylodTag.currentTagName === tag)?.newTagName || tag,
            ),
          };
        });
        const requestFilter = { ...project.requestFilter };
        requestFilter.tags = action.payload.tags.map((tag: Tag) => tag.newTagName);
        return { ...project, requests, requestFilter };
      }, projects),
  )
  .handleAction(
    deleteProjectRequestTags,
    (projects: Project[], action: PayloadAction<'DELETE_PROJECT_REQUEST_TAGS', { projectId: string; tag: string }>) =>
      map((project: Project) => {
        if (project.id !== parseInt(action.payload.projectId)) return project;

        const requests = project.requests?.map((request: Request) => {
          return {
            ...request,
            tags: request.tags.filter((rTag: string) => rTag !== action.payload.tag),
          };
        });
        const requestFilter = { ...project.requestFilter };
        requestFilter.tags = (requestFilter?.tags || []).filter((rTag: string) => rTag !== action.payload.tag);
        const requestTags = project.requestsTags?.filter(rTag => rTag !== action.payload.tag);
        return { ...project, requestTags, requests, requestFilter };
      }, projects),
  );

export const projectTransform = createTransform<Project[], any[], { list: Project[] }>(
  (state: Project[]) => {
    if (!gdprConsent(featuresCookieCategories.account)) {
      return [];
    }
    return state.map((project: Project) => ({
      id: project.id,
      communityIds: project.communityIds,
      name: project.name,
      logo: project.logo,
      cover: project.cover,
      country: project.country,
      city: project.city,
      isFollowing: project.isFollowing,
      canEditPrivacy: project.canEditPrivacy,
      mainCommunityId: project.mainCommunityId,
      feed: (project.feed || []).filter((feedItem: any, feedItemIndex: number) => feedItemIndex < 3),
    }));
  },
  (state: any[]) => {
    return state.map((project: any) => ({
      id: project.id,
      communityIds: project.communityIds,
      name: project.name,
      description: '',
      logo: project.logo,
      cover: project.cover,
      businessModel: {
        canEditBusinessModel: false,
        sections: [],
        customPrivacyCircles: [],
      },
      SDGs: [],
      tags: [],
      country: project.country,
      city: project.city,
      category: '',
      stage: '',
      isFollowing: project.isFollowing,
      canEditPrivacy: project.canEditPrivacy,
      mainCommunityId: project.mainCommunityId,
      feed: (project.feed || []).filter((feedItem: any, feedItemIndex: number) => feedItemIndex < 3),
    }));
  },
  { whitelist: ['list'] },
);

export const persistProjectConfig = {
  key: getPersistKey('projects'),
  storage,
  transforms: [projectTransform],
  whitelist: ['list'],
};
