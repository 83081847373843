import {
  Resource,
  Project,
  Circle,
  Member,
  Discussion,
  NewProject,
  Sorting,
  ProjectProgressDetails,
  KpiValue,
  Comment,
  Filtering,
  Request,
  KpiDefinition,
  Tag,
  TagType,
  CommunityRequestsFilters,
} from 'redux/types/account';
import { Response } from '../types/enums';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { History } from 'history';
import { EmailInvitationDto } from 'ApiModels';
import { AxiosError } from 'axios';

export const fetchProjectByIdAsync = createAsyncAction(
  'FETCH_PROJECT_REQUEST',
  'FETCH_PROJECT_SUCCESS',
  'FETCH_PROJECT_FAILURE',
  'FETCH_PROJECT_IDLE',
)<{ bearer: string; id: number }, Project, string, null>();

export const fetchProjectOverviewAsync = createAsyncAction(
  'FETCH_PROJECT_OVERVIEW_REQUEST',
  'FETCH_PROJECT_OVERVIEW_SUCCESS',
  'FETCH_PROJECT_OVERVIEW_FAILURE',
  'FETCH_PROJECT_OVERVIEW_IDLE',
)<{ bearer: string; id: number }, Project, string, null>();

export const fetchProjectKPIDefinitionAsync = createAsyncAction(
  'FETCH_PROJECT_KPI_DEFINITION_REQUEST',
  'FETCH_PROJECT_KPI_DEFINITION_SUCCESS',
  'FETCH_PROJECT_KPI_DEFINITION_FAILURE',
  'FETCH_PROJECT_KPI_DEFINITION_IDLE',
)<{ projectId: number; bearer: string }, { projectId: number; kpis: KpiDefinition[] }, string, null>();

export const upsertProjectKPIAsync = createAsyncAction(
  'UPSERT_PROJECT_KPI_REQUEST',
  'UPSERT_PROJECT_KPI_SUCCESS',
  'UPSERT_PROJECT_KPI_FAILURE',
  'UPSERT_PROJECT_KPI_IDLE',
)<
  { bearer: string; communityId: number; kpi: KpiDefinition },
  { communityId: number; kpi: KpiDefinition },
  string,
  null
>();

export const deleteProjectKpiAsync = createAsyncAction(
  'DELETE_PROJECT_KPI_REQUEST',
  'DELETE_PROJECT_KPI_FAILURE',
  'DELETE_PROJECT_KPI_SUCCESS',
  'DELETE_PROJECT_KPI_IDLE',
)<
  { bearer: string; communityId: number; kpi: KpiDefinition },
  { communityId: number; kpi: KpiDefinition },
  string,
  null
>();

export const fetchProjectRequestsAsync = createAsyncAction(
  'FETCH_PROJECT_REQUESTS_REQUEST',
  'FETCH_PROJECT_REQUESTS_SUCCESS',
  'FETCH_PROJECT_REQUESTS_FAILURE',
  'FETCH_PROJECT_REQUESTS_IDLE',
)<
  { projectId: number; bearer: string; sorting: Sorting; filtering: Filtering; skip: number; take: number },
  {
    list: Request[];
    canCreateRequests: boolean;
    skip: number;
    take: number;
    projectId: number;
    hasPendingRequestFeedback: boolean;
    hasDefaultCircles: boolean;
    count: number;
    totalRequestsCount?: number;
    requestFilter?: CommunityRequestsFilters;
  },
  string,
  null
>();

export const updateProjectRequestTagsAsync = createAsyncAction(
  'UPDATE_REQUEST_TAGS_REQUEST',
  'UPDATE_REQUEST_TAGS_SUCCESS',
  'UPDATE_REQUEST_TAGS_FAILURE',
  'UPDATE_REQUEST_TAGS_IDLE',
)<
  { id: number; bearer: string; tags: Array<string>; projectId: number },
  { tags: Array<string>; requestId: number; projectId: number },
  string,
  null
>();

export const fetchProjectRequestsTagsAsync = createAsyncAction(
  'FETCH_PROJECT_REQUESTS_TAGS_REQUEST',
  'FETCH_PROJECT_REQUESTS_TAGS_SUCCESS',
  'FETCH_PROJECT_REQUESTS_TAGS_FAILURE',
  'FETCH_PROJECT_REQUESTS_TAGS_IDLE',
)<{ projectId: number; bearer: string }, { requestsTags: string[]; projectId: number }, string, null>();

export const fetchProjectMembersAsync = createAsyncAction(
  'FETCH_PROJECT_MEMBERS_REQUEST',
  'FETCH_PROJECT_MEMBERS_SUCCESS',
  'FETCH_PROJECT_MEMBERS_FAILURE',
  'FETCH_PROJECT_MEMBERS_IDLE',
)<
  { bearer: string; id: number; sorting: Sorting },
  {
    id: number;
    members: Member[];
    pendingApplyToTeam: Member[];
    pendingByUsers: Member[];
    canInviteUser: boolean;
    isProjectAdmin: boolean;
    isMember: boolean;
  },
  string,
  null
>();

export const fetchProjectMembersByCircleAsync = createAsyncAction(
  'FETCH_PROJECT_MEMBERS_BY_CIRCLE_REQUEST',
  'FETCH_PROJECT_MEMBERS_BY_CIRCLE_SUCCESS',
  'FETCH_PROJECT_MEMBERS_BY_CIRCLE_FAILURE',
  'FETCH_PROJECT_MEMBERS_BY_CIRCLE_IDLE',
)<
  { bearer: string; projectId: number; circleId: number; skip: number; take: number },
  { projectId: number; circleId: number; skip: number; take: number; members: Member[] },
  string,
  null
>();

export const fetchProjectMembersPendingByUserAsync = createAsyncAction(
  'FETCH_PROJECT_MEMBERS_PENDING_BY_USER_REQUEST',
  'FETCH_PROJECT_MEMBERS_PENDING_BY_USER_SUCCESS',
  'FETCH_PROJECT_MEMBERS_PENDING_BY_USER_FAILURE',
  'FETCH_PROJECT_MEMBERS_PENDING_BY_USER_IDLE',
)<{ bearer: string; projectId: number }, { id: number; pendingByUsers: Member[] }, string, null>();

export const respondProjectJoinRequestAsync = createAsyncAction(
  'RESPOND_PROJECT_JOIN_REQUEST_REQUEST',
  'RESPOND_PROJECT_JOIN_REQUEST_SUCCESS',
  'RESPOND_PROJECT_JOIN_REQUEST_FAILURE',
  'RESPOND_PROJECT_JOIN_REQUEST_IDLE',
)<
  { bearer: string; projectId: number; userId: number; type: Response },
  { projectId: number; userId: number; type: Response },
  string,
  null
>();

export const removeProjectInvitationAsync = createAsyncAction(
  'REMOVE_PROJECT_INVITATION_REQUEST',
  'REMOVE_PROJECT_INVITATION_SUCCESS',
  'REMOVE_PROJECT_INVITATION_FAILURE',
  'REMOVE_PROJECT_INVITATION_IDLE',
)<
  { bearer: string; projectId: number; userId: number; userEmail: string },
  { projectId: number; userId: number; userEmail: string },
  string,
  null
>();

export const saveProjectDiscussionTagsAsync = createAsyncAction(
  'SAVE_PROJECT_DISCUSSION_TAGS_REQUEST',
  'SAVE_PROJECT_DISCUSSION_TAGS_SUCCESS',
  'SAVE_PROJECT_DISCUSSION_TAGS_FAILURE',
  'SAVE_PROJECT_DISCUSSION_TAGS_IDLE',
)<
  { bearer: string; discussionId: number; projectId: number; tags: string[] },
  { discussionId: number; projectId: number; tags: string[] },
  string,
  null
>();

export const inviteProjectMembersAsync = createAsyncAction(
  'INVITE_PROJECT_MEMBERS_REQUEST',
  'INVITE_PROJECT_MEMBERS_SUCCESS',
  'INVITE_PROJECT_MEMBERS_FAILURE',
  'INVITE_PROJECT_MEMBERS_IDLE',
)<{ bearer: string; data: EmailInvitationDto[] }, (EmailInvitationDto | AxiosError)[], string, null>();

export const applyToTeamProjectAsync = createAsyncAction(
  'APPLY_TO_TEAM_PROJECT_REQUEST',
  'APPLY_TO_TEAM_PROJECT_SUCCESS',
  'APPLY_TO_TEAM_PROJECT_FAILURE',
  'APPLY_TO_TEAM_PROJECT_IDLE',
)<{ bearer: string; projectId: number }, null, string, null>();

export const fetchProjectResourcesAsync = createAsyncAction(
  'FETCH_PROJECT_RESOURCES_REQUEST',
  'FETCH_PROJECT_RESOURCES_SUCCESS',
  'FETCH_PROJECT_RESOURCES_FAILURE',
  'FETCH_PROJECT_RESOURCES_IDLE',
)<
  { bearer: string; id: number; sorting?: Sorting },
  { id: number; canEditResources: boolean; resources: Resource[]; canEditPrivacy: boolean },
  string,
  null
>();

export const fetchProjectResourcesTagsAsync = createAsyncAction(
  'FETCH_PROJECT_RESOURCES_TAGS_REQUEST',
  'FETCH_PROJECT_RESOURCES_TAGS_SUCCESS',
  'FETCH_PROJECT_RESOURCES_TAGS_FAILURE',
  'FETCH_PROJECT_RESOURCES_TAGS_IDLE',
)<{ bearer: string; id: number }, { id: number; tags: string[] }, string, null>();

export const fetchProjectDiscussionsAsync = createAsyncAction(
  'FETCH_PROJECT_DISCUSSIONS_REQUEST',
  'FETCH_PROJECT_DISCUSSIONS_SUCCESS',
  'FETCH_PROJECT_DISCUSSION_FAILURE',
  'FETCH_PROJECT_DISCUSSION_IDLE',
)<
  { bearer: string; id: number; sorting: Sorting },
  { id: number; canEditDiscussions: boolean; discussions: Discussion[]; canEditPrivacy: boolean },
  string,
  null
>();

export const fetchProjectCirclesAsync = createAsyncAction(
  'FETCH_PROJECT_CIRCLES_REQUEST',
  'FETCH_PROJECT_CIRCLES_SUCCESS',
  'FETCH_PROJECT_CIRCLES_FAILURE',
  'FETCH_PROJECT_CIRCLES_IDLE',
)<{ bearer: string; id: number }, { id: number; circles: Circle[] }, string, null>();

export const saveProjectCircleAsync = createAsyncAction(
  'SAVE_PROJECT_CIRCLE_REQUEST',
  'SAVE_PROJECT_CIRCLE_SUCCESS',
  'SAVE_PROJECT_CIRCLE_FAILURE',
  'SAVE_PROJECT_CIRCLE_IDLE',
)<{ id: number; circle: Circle; bearer: string }, { projectId: number; circle: Circle }, string, null>();

export const saveProjectCirclesAsync = createAsyncAction(
  'SAVE_PROJECT_CIRCLES_REQUEST',
  'SAVE_PROJECT_CIRCLES_SUCCESS',
  'SAVE_PROJECT_CIRCLES_FAILURE',
  'SAVE_PROJECT_CIRCLES_IDLE',
)<{ projectId: number; circles: Circle[]; bearer: string }, { projectId: number; circles: Circle[] }, string, null>();

export const deleteProjectCircleAsync = createAsyncAction(
  'DELETE_PROJECT_CIRCLE_REQUEST',
  'DELETE_PROJECT_CIRCLE_SUCCESS',
  'DELETE_PROJECT_CIRCLE_FAILURE',
  'DELETE_PROJECT_CIRCLE_IDLE',
)<{ projectId: number; bearer: string; circleId: number }, { projectId: number; circleId: number }, string, null>();

export const editProjectCircleAsync = createAsyncAction(
  'EDIT_PROJECT_CIRCLE_REQUEST',
  'EDIT_PROJECT_CIRCLE_SUCCESS',
  'EDIT_PROJECT_CIRCLE_FAILURE',
  'EDIT_PROJECT_CIRCLE_IDLE',
)<
  { id: number; bearer: string; circleId: string; userId: string },
  { id: number; circleId: number; userId: number },
  string,
  null
>();

export const createProjectAsync = createAsyncAction(
  'CREATE_PROJECT_REQUEST',
  'CREATE_PROJECT_SUCCESS',
  'CREATE_PROJECT_FAILURE',
  'CREATE_PROJECT_IDLE',
)<{ bearer: string; project: NewProject }, Project, string, null>();

export const updateProjectLogoAsync = createAsyncAction(
  'UPDATE_PROJECT_LOGO_REQUEST',
  'UPDATE_PROJECT_LOGO_SUCCESS',
  'UPDATE_PROJECT_LOGO_FAILURE',
  'UPDATE_PROJECT_LOGO_IDLE',
)<{ project: Project; bearer: string; logo: File | string }, { projectId: number; logo: string }, string, null>();

export const updateProjectCoverAsync = createAsyncAction(
  'UPDATE_PROJECT_COVER_REQUEST',
  'UPDATE_PROJECT_COVER_SUCCESS',
  'UPDATE_PROJECT_COVER_FAILURE',
  'UPDATE_PROJECT_COVER_IDLE',
)<{ project: Project; bearer: string; cover: File | string }, { projectId: number; cover: string }, string, null>();

export const removeUserFromProjectAsync = createAsyncAction(
  'REMOVE_USER_FROM_PROJECT_REQUEST',
  'REMOVE_USER_FROM_PROJECT_SUCCESS',
  'REMOVE_USER_FROM_PROJECT_FAILURE',
  'REMOVE_USER_FROM_PROJECT_IDLE',
)<{ bearer: string; userId: number; id: number }, { userId: number; id: number }, string, null>();

export const updateProjectAsync = createAsyncAction(
  'UPDATE_PROJECT_REQUEST',
  'UPDATE_PROJECT_SUCCESS',
  'UPDATE_PROJECT_FAILURE',
  'UPDATE_PROJECT_IDLE',
)<{ project: Project; bearer: string }, { project: Project }, string, null>();

export const removeProjectAsync = createAsyncAction(
  'REMOVE_PROJECT_REQUEST',
  'REMOVE_PROJECT_SUCCESS',
  'REMOVE_PROJECT_FAILURE',
  'REMOVE_PROJECT_IDLE',
)<{ project: Project; bearer: string; history: History }, { projectId: number }, string, null>();

export const followProjectAsync = createAsyncAction(
  'FOLLOW_PROJECT_REQUEST',
  'FOLLOW_PROJECT_SUCCESS',
  'FOLLOW_PROJECT_FAILURE',
  'FOLLOW_PROJECT_IDLE',
)<{ bearer: string; projectId: number }, { projectId: number }, string, null>();

export const unfollowProjectAsync = createAsyncAction(
  'UNFOLLOW_PROJECT_REQUEST',
  'UNFOLLOW_PROJECT_SUCCESS',
  'UNFOLLOW_PROJECT_FAILURE',
  'UNFOLLOW_PROJECT_IDLE',
)<{ bearer: string; projectId: number }, { projectId: number }, string, null>();

export const fetchProjectProgressDetailsAsync = createAsyncAction(
  'FETCH_PROJECT_PROGRESS_DETAILS_REQUEST',
  'FETCH_PROJECT_PROGRESS_DETAILS_SUCCESS',
  'FETCH_PROJECT_PROGRESS_DETAILS_FAILURE',
  'FETCH_PROJECT_PROGRESS_DETAILS_IDLE',
)<
  { bearer: string; projectId: number; methodologyId: number },
  { progressDetails: ProjectProgressDetails; projectId: number },
  string,
  null
>();

export const fetchPendingKpiAsync = createAsyncAction(
  'FETCH_PENDING_KPI_REQUEST',
  'FETCH_PENDING_KPI_SUCCESS',
  'FETCH_PENDING_KPI_FAILURE',
  'FETCH_PENDING_KPI_IDLE',
)<{ bearer: string; projectId: number }, { projectId: number; kpis: KpiValue[] }, string, null>();

export const saveKpiValuesAsync = createAsyncAction(
  'SAVE_KPI_VALUE_REQUEST',
  'SAVE_KPI_VALUE_SUCCESS',
  'SAVE_KPI_VALUE_FAILURE',
  'SAVE_KPI_VALUE_IDLE',
)<{ bearer: string; kpis: KpiValue[] }, { kpis: KpiValue[] }, string, null>();

export const saveRequestCommentAsync = createAsyncAction(
  'SAVE_REQUEST_COMMENT_REQUEST',
  'SAVE_REQUEST_COMMENT_SUCCESS',
  'SAVE_REQUEST_COMMENT_FAILURE',
  'SAVE_REQUEST_COMMENT_IDLE',
)<
  {
    bearer: string;
    userId: number;
    itemId: number;
    content: string;
    parentId: number;
    requestId: number;
    projectId: number;
  },
  { projectId: number; requestId: number; comment: Comment },
  string,
  null
>();

export const editProjectTagsAsync = createAsyncAction(
  'EDIT_PROJECT_TAGS_REQUEST',
  'EDIT_PROJECT_TAGS_SUCCESS',
  'EDIT_PROJECT_TAGS_FAILURE',
  'EDIT_PROJECT_TAGS_IDLE',
)<
  { bearer: string; projectId: number; tagsList: Tag[]; tagType: TagType },
  { projectId: number; tagsList: Tag[]; tagType: TagType },
  string,
  null
>();

export const deleteProjectTagsAsync = createAsyncAction(
  'DELETE_PROJECT_TAGS_REQUEST',
  'DELETE_PROJECT_TAGS_SUCCESS',
  'DELETE_PROJECT_TAGS_FAILURE',
  'DELETE_PROJECT_TAGS_IDLE',
)<
  { bearer: string; projectId: number; tags: string[]; tagType: TagType },
  { projectId: number; tags: string[]; tagType: TagType },
  string,
  null
>();

export const updateEditedCommunityProjectTagsAsync = createAsyncAction(
  'UPDATE_EDITED_COMMUNITY_PROJECT_TAGS_REQUEST',
  'UPDATE_EDITED_COMMUNITY_PROJECT_TAGS_SUCCESS',
  'UPDATE_EDITED_COMMUNITY_PROJECT_TAGS_FAILURE',
  'UPDATE_EDITED_COMMUNITY_PROJECT_TAGS_IDLE',
)<{ communityId: number; tagsList: Tag[] }, { communityId: number; tagsList: Tag[] }, string, null>();

export const updateDeletedCommunityProjectTagsAsync = createAsyncAction(
  'UPDATE_DELETED_COMMUNITY_PROJECT_TAGS_REQUEST',
  'UPDATE_DELETED_COMMUNITY_PROJECT_TAGS_SUCCESS',
  'UPDATE_DELETED_COMMUNITY_PROJECT_TAGS_FAILURE',
  'UPDATE_DELETED_COMMUNITY_PROJECT_TAGS_IDLE',
)<{ communityId: number; tags: string[] }, { communityId: number; tags: string[] }, string, null>();

export const editProjectRequestTags = createAction('EDIT_PROJECT_REQUEST_TAGS')<{
  projectId: string;
  tags: Tag[];
}>();

export const deleteProjectRequestTags = createAction('DELETE_PROJECT_REQUEST_TAGS')<{
  projectId: string;
  tag: string;
}>();

export default {
  saveRequestCommentAsync,
  fetchProjectRequestsAsync,
  fetchProjectByIdAsync,
  // fetchProjectOverviewAsync, // !NEEWWWW
  fetchProjectMembersAsync,
  respondProjectJoinRequestAsync,
  removeProjectInvitationAsync,
  saveProjectDiscussionTagsAsync,
  inviteProjectMembersAsync,
  applyToTeamProjectAsync,
  fetchProjectResourcesAsync,
  fetchProjectResourcesTagsAsync,
  fetchProjectDiscussionsAsync,
  fetchProjectCirclesAsync,
  saveProjectCircleAsync,
  saveProjectCirclesAsync,
  deleteProjectCircleAsync,
  editProjectCircleAsync,
  createProjectAsync,
  updateProjectLogoAsync,
  updateProjectCoverAsync,
  removeUserFromProjectAsync,
  updateProjectAsync,
  removeProjectAsync,
  followProjectAsync,
  unfollowProjectAsync,
  fetchProjectProgressDetailsAsync,
  fetchPendingKpiAsync,
  saveKpiValuesAsync,
  fetchProjectMembersPendingByUserAsync,
  fetchProjectMembersByCircleAsync,
  fetchProjectKPIDefinitionAsync,
  upsertProjectKPIAsync,
  deleteProjectKpiAsync,
  editProjectTagsAsync,
  deleteProjectTagsAsync,
  updateEditedCommunityProjectTagsAsync,
  updateDeletedCommunityProjectTagsAsync,
  editProjectRequestTags,
  deleteProjectRequestTags,
};
