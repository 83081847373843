import { clone } from 'ramda';
import { fetchTabEntityCommentsAsync } from 'redux/actions/feed';
import {
  deleteTabAsync,
  deleteTabEntityCircleAsync,
  editTabEntityCircleAsync,
  fetchCommunityTabEntityCircleMembersAsync,
  fetchCommunityTabEntityCirclesAsync,
  fetchCommunityTabsAsync,
  fetchTabByIdAsync,
  fetchTabEntitiesByIdAsync,
  followOrUnFollowEntityAsync,
} from 'redux/actions/tab';
import {
  Comment,
  Community,
  CommunityTab,
  Discussion,
  Member,
  Request,
  Resource,
  Tag,
  TagType,
} from 'redux/types/account';
import { StateType } from 'typesafe-actions';
import { TabEntityComment, TabEntityCircle } from '../../types/account';
import { CommentType } from 'redux/types/enums';
import { findCommentById } from '../reducersHelpers';
import { recursiveCommentLike } from '../reducersHelpers/recursiveCommentLike';
import { saveTabEntityCircleAsync } from '../../actions/tab';
import {
  deleteCommunityProjectTagsAsync,
  deleteCommunityTagsAsync,
  editCommunityProjectTagsAsync,
  editCommunityTagsAsync,
  fetchCommunityMembersTagsAsync,
  saveCommunityMembersTagsAsync,
  updateMethodologyNameAsync,
} from 'redux/actions/community';

export const fetchTabEntityCommentsActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, tabEntityId, list } }: StateType<typeof fetchTabEntityCommentsAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  let comments = selectedCommunity.tabsEntitiesComments?.length
    ? selectedCommunity.tabsEntitiesComments.filter(c => c.tabEntityId !== tabEntityId)
    : [];
  comments = list?.length ? list.concat(comments) : comments;
  comments = comments.map(c => ({ ...c, isLiked: !!c.userLikesComment }));

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabsEntitiesComments: comments,
  };

  return updatedSelectedCommunity;
};

export const deleteTabActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, tabId } }: StateType<typeof deleteTabAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  const updatedTabs = (selectedCommunity.tabs || []).filter(tab => tab.id !== tabId);

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabs: updatedTabs,
  };

  return updatedSelectedCommunity;
};

export const updateOrCreateTabActionHandler = (
  selectedCommunity: Community | null,
  { payload: { tab } }: StateType<typeof fetchTabByIdAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== tab.communityId) {
    return selectedCommunity;
  }

  const modified = { ...selectedCommunity, tabs: clone(selectedCommunity.tabs ?? []) };
  const currentTab = modified.tabs.find(it => it.id === tab.id);
  currentTab ? Object.assign(currentTab, tab) : modified.tabs.push(tab);

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabs: modified.tabs,
  };

  return updatedSelectedCommunity;
};

export const followOrUnFollowEntityActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, tabId, entityId, isFollowing } }: StateType<typeof followOrUnFollowEntityAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  const updatedTabs = (selectedCommunity.tabs || []).map(tab => {
    if (tab.id !== tabId) {
      return tab;
    }

    const updatedEntities = (tab.entities || []).map(entity => {
      if (entity.id !== entityId) {
        return entity;
      }
      return { ...entity, isFollowing };
    });

    return {
      ...tab,
      entities: updatedEntities,
    };
  });

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabs: updatedTabs,
  };

  return updatedSelectedCommunity;
};

export const fetchTabEntitiesByIdActionHandler = (
  selectedCommunity: Community | null,
  {
    payload: { communityId, tabId, entities, count, entitiesFilter, skip },
  }: StateType<typeof fetchTabEntitiesByIdAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }
  let updatedTabs: CommunityTab[];
  if (skip === 0) {
    updatedTabs = (selectedCommunity.tabs || []).map(tab => {
      if (tab.id !== tabId) {
        return tab;
      }
      return { ...tab, entities: entities?.length ? entities : [], totalEntityCount: count };
    });
  } else {
    updatedTabs = (selectedCommunity.tabs || []).map(tab => {
      if (tab.id !== tabId) {
        return tab;
      }
      return { ...tab, entities: [...tab.entities, ...(entities || [])], totalEntityCount: count };
    });
  }

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabs: updatedTabs,
    entityFilters: entitiesFilter,
  };

  return updatedSelectedCommunity;
};

export const fetchCommunityTabsActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, tabs } }: StateType<typeof fetchCommunityTabsAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabs: tabs,
  };

  return updatedSelectedCommunity;
};

export const updateTabCommentsFromCommunity = (community: Community, tabEntityId: number, comment: Comment) => {
  const commentParsed: TabEntityComment = { ...comment, tabEntityId, type: CommentType.TabEntity };
  let comments = clone(community.tabsEntitiesComments);

  if (comments?.length) {
    // check is edition o creation
    let commentFound = findCommentById(comments, comment.id);
    if (commentFound) commentFound = Object.assign(commentFound, commentParsed);
    else {
      // add new comment to parent comments
      if (commentParsed.parentId) {
        const parent = findCommentById(comments, commentParsed.parentId);
        if (parent) {
          if (parent.comments?.length) parent.comments.push(commentParsed);
          else parent.comments = [commentParsed];
        }
      }
      // add new root comment
      else comments.push(commentParsed);
    }
  } else comments = [commentParsed];
  return { ...community, tabsEntitiesComments: comments };
};

export const tabEntityCommentLike = (community: Community, id: number, like: boolean): Community => {
  let comments = clone(community.tabsEntitiesComments) as Comment[];
  comments = recursiveCommentLike(comments, id, like);
  return { ...community, tabsEntitiesComments: comments as TabEntityComment[] };
};

export const tabEntityDeleteComment = (community: Community, id: number) => {
  let comments = clone(community.tabsEntitiesComments) as Comment[];
  const commentFound = findCommentById(comments, id);
  if (commentFound) {
    if (commentFound.parentId) {
      const parent = findCommentById(comments, commentFound.parentId);
      if (parent && parent?.comments?.length) parent.comments = parent?.comments.filter(c => c.id !== id);
    } else {
      comments = comments.filter(c => c.id !== id);
    }
  }
  return { ...community, tabsEntitiesComments: comments as TabEntityComment[] };
};

export const fetchCommunityTabEntityCirclesActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, circles, tabEntityId } }: StateType<typeof fetchCommunityTabEntityCirclesAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  let circlesFound = selectedCommunity.tabsEntitiesCircles?.length
    ? selectedCommunity.tabsEntitiesCircles.filter(c => c.tabEntityId !== tabEntityId)
    : [];
  circlesFound = circles?.length ? circlesFound.concat(circles) : circlesFound;

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabsEntitiesCircles: circlesFound as TabEntityCircle[],
  };

  return updatedSelectedCommunity;
};

export const fetchCommunityTabEntityCircleMembersActionHandler = (
  selectedCommunity: Community | null,
  {
    payload: { communityId, tabEntityId, tabEntityCircleId, skip, members, take },
  }: StateType<typeof fetchCommunityTabEntityCircleMembersAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  const updatedTabsEntitiesCircles = (selectedCommunity.tabsEntitiesCircles || []).map(circle => {
    if (circle.id === tabEntityCircleId) {
      if (circle) {
        if (skip === 0) {
          circle = {
            ...circle,
            eventMembers: members,
          };
        } else {
          circle = {
            ...circle,
            eventMembers: [...(circle.eventMembers || []), ...members],
          };
        }
      }
    }

    return circle;
  });

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabsEntitiesCircles: updatedTabsEntitiesCircles,
  };

  return updatedSelectedCommunity;
};

export const saveTabEntityCircleActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, circle } }: StateType<typeof saveTabEntityCircleAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  const modified = { ...selectedCommunity, tabsEntitiesCircles: clone(selectedCommunity.tabsEntitiesCircles ?? []) };
  const currentCircle = modified.tabsEntitiesCircles.find(it => it.id === circle.id);
  currentCircle ? Object.assign(currentCircle, circle) : modified.tabsEntitiesCircles.push(circle);

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabsEntitiesCircles: modified.tabsEntitiesCircles,
  };

  return updatedSelectedCommunity;
};

export const deleteTabEntityCircleActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, id } }: StateType<typeof deleteTabEntityCircleAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  const updatedTabsEntitiesCircles = selectedCommunity.tabsEntitiesCircles?.filter(circle => circle.id !== id);

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabsEntitiesCircles: updatedTabsEntitiesCircles,
  };

  return updatedSelectedCommunity;
};

export const editTabEntityCircleAsyncActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, circleId } }: StateType<typeof editTabEntityCircleAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) {
    return selectedCommunity;
  }

  const updatedTabsEntitiesCircles = (selectedCommunity.tabsEntitiesCircles || []).map(circle => {
    if (circle.id === circleId) {
      circle.eventMembers = (circle.eventMembers || []).map(member => {
        return member;
      });
    }

    return circle;
  });

  const updatedSelectedCommunity: Community = {
    ...selectedCommunity,
    tabsEntitiesCircles: updatedTabsEntitiesCircles,
  };

  return updatedSelectedCommunity;
};

export const editCommunityTagsActionHandler = (
  community: Community | null,
  { payload: { communityId, tagsList, tagType } }: StateType<typeof editCommunityTagsAsync.success>,
) => {
  if (community) {
    if (tagType === TagType.COMMUNITY_DISCUSSION) {
      const discussionTags = community.discussionTags?.map((tag: string) => {
        const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
        if (tagObj) return tagObj.newTagName;
        return tag;
      });
      const communityDiscussions = community.communityDiscussions?.map((discussion: Discussion) => {
        return {
          ...discussion,
          discussionTags: discussion.discussionTags?.map((tag: string) => {
            const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
            if (tagObj) return tagObj.newTagName;
            return tag;
          }),
        };
      });
      return {
        ...community,
        discussionTags,
        communityDiscussions,
      };
    } else if (tagType === TagType.RESOURCE) {
      const resourcesTags = community.resourcesTags?.map((tag: string) => {
        const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
        if (tagObj) return tagObj.newTagName;
        return tag;
      });
      const resources = community.resources?.map((resource: Resource) => {
        return {
          ...resource,
          tags: resource.tags?.map((tag: string) => {
            const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
            if (tagObj) return tagObj.newTagName;
            return tag;
          }),
        };
      });
      return {
        ...community,
        resourcesTags,
        resources,
      };
    } else if (tagType === TagType.USER) {
      const userTags = community.communityMembersTags?.map((tag: string) => {
        const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
        if (tagObj) return tagObj.newTagName;
        return tag;
      });
      const members = community.members?.map((member: Member) => {
        return {
          ...member,
          userTags: member.userTags?.map((tag: string) => {
            const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
            if (tagObj) return tagObj.newTagName;
            return tag;
          }),
        };
      });

      const memberFilters = { ...community.memberFilters };
      memberFilters.tags = (memberFilters.tags || []).map(tag => {
        const tagObj = tagsList.find(tagObj => tagObj.currentTagName === tag);
        return tagObj ? tagObj.newTagName : tag;
      });
      return {
        ...community,
        memberFilters,
        userTags,
        members,
      };
    } else {
      const requestsTags = community.requestsTags?.map((tag: string) => {
        const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
        if (tagObj) return tagObj.newTagName;
        return tag;
      });
      const requestFilters = { ...community.requestFilters };
      requestFilters.tags = (requestFilters.tags || []).map(tag => {
        const tagObj = tagsList.find(tagObj => tagObj.currentTagName === tag);
        return tagObj ? tagObj.newTagName : tag;
      });
      const requests = community.openRequests?.map((request: Request) => {
        return {
          ...request,
          tags: request.tags?.map((tag: string) => {
            const tagObj = tagsList.find((tagObj: Tag) => tagObj.currentTagName === tag);
            if (tagObj) return tagObj.newTagName;
            return tag;
          }),
        };
      });
      return {
        ...community,
        requestFilters,
        requestsTags,
        openRequests: requests,
      };
    }
  } else {
    return community;
  }
};

export const deleteCommunityTagsActionHandler = (
  community: Community | null,
  { payload: { communityId, tagsList, tagType } }: StateType<typeof deleteCommunityTagsAsync.success>,
) => {
  if (community) {
    if (tagType === TagType.COMMUNITY_DISCUSSION) {
      const discussionTags = community.discussionTags?.filter((tag: string) => !tagsList.includes(tag));
      const communityDiscussions = community.communityDiscussions?.map((discussion: Discussion) => {
        return {
          ...discussion,
          discussionTags: discussion.discussionTags?.filter((tag: string) => !tagsList.includes(tag)),
        };
      });
      return {
        ...community,
        discussionTags,
        communityDiscussions,
      };
    } else if (tagType === TagType.RESOURCE) {
      const resourcesTags = community.resourcesTags?.filter((tag: string) => !tagsList.includes(tag));
      const resources = community.resources?.map((resource: Resource) => {
        return {
          ...resource,
          tags: resource.tags?.filter((tag: string) => !tagsList.includes(tag)),
        };
      });
      return {
        ...community,
        resourcesTags,
        resources,
      };
    } else if (tagType === TagType.USER) {
      const userTags = community.communityMembersTags?.filter((tag: string) => !tagsList.includes(tag));
      const users = community.members?.map((member: Member) => {
        return {
          ...member,
          userTags: member.userTags?.filter((tag: string) => !tagsList.includes(tag)),
        };
      });
      const memberFilters = { ...community.memberFilters };
      memberFilters.tags = (memberFilters.tags || []).filter(tag => !tagsList.includes(tag));
      return {
        ...community,
        memberFilters,
        userTags,
        members: users,
      };
    } else {
      const requestsTags = community.requestsTags?.filter((tag: string) => !tagsList.includes(tag));
      const requests = community.openRequests?.map((request: Request) => {
        return {
          ...request,
          tags: request.tags?.filter((tag: string) => !tagsList.includes(tag)),
        };
      });
      const requestFilters = { ...community.requestFilters };
      requestFilters.tags = (requestFilters.tags || []).filter(tag => !tagsList.includes(tag));
      return {
        ...community,
        requestFilters,
        requestsTags,
        openRequests: requests,
      };
    }
  } else {
    return community;
  }
};

export const editCommunityProjectTagsActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, tagsList } }: StateType<typeof editCommunityProjectTagsAsync.success>,
) => {
  if (!selectedCommunity || selectedCommunity.id !== communityId) return selectedCommunity;
  return {
    ...selectedCommunity,
    projectFilters: { ...selectedCommunity.projectFilters, tags: tagsList.map((tag: Tag) => tag.newTagName) },
  };
};

export const deleteCommunityProjectTagsActionHandler = (
  selectedCommunity: Community | null,
  { payload: { communityId, tags } }: StateType<typeof deleteCommunityProjectTagsAsync.success>,
) => {
  if (selectedCommunity) {
    if (selectedCommunity.id !== communityId) return selectedCommunity;
    return {
      ...selectedCommunity,
      projectFilters: {
        ...selectedCommunity.projectFilters,
        tags: selectedCommunity.communityProjectsTags?.filter((tag: string) => !tags.includes(tag)),
      },
    };
  } else {
    return selectedCommunity;
  }
};

export const fetchCommunityMembersTagsActionHandler = (
  community: Community,
  { payload: { communityId, tags } }: StateType<typeof fetchCommunityMembersTagsAsync.success>,
) => {
  if (!community || community.id !== communityId) return community;
  return {
    ...community,
    communityMembersTags: tags,
  };
};

export const saveCommunityMembersTagsActionHandler = (
  community: Community,
  {
    payload: { communityId, userId, userCommunityId, userTags },
  }: StateType<typeof saveCommunityMembersTagsAsync.success>,
) => {
  if (!community || community.id !== communityId) return community;
  const memberFilters = community?.memberFilters;
  if (memberFilters)
    memberFilters.tags = memberFilters.tags.concat(
      userTags.filter(element => !community.memberFilters?.tags?.includes(element)),
    );
  return {
    ...community,
    members: community.members?.map((member: Member) => {
      if (member.id !== userId) return member;
      return {
        ...member,
        userTags,
      };
    }),
    communityMembersTags: (community.communityMembersTags || []).concat(
      userTags.filter(element => !community.communityMembersTags?.includes(element)),
    ),
    memberFilters,
  };
};

export const updateMethodologyNameActionHandler = (
  community: Community,
  { payload: { id, name } }: StateType<typeof updateMethodologyNameAsync.success>,
) => {
  return {
    ...community,
    methodologies: community.methodologies?.map(methodology => {
      if (methodology.id === id) {
        return {
          ...methodology,
          name,
        };
      }
      return methodology;
    }),
  };
};
