import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@rturtu/ckeditor5-inline-emoji-build';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { decode } from 'html-entities';

import { ckEditorImageDefaultConfig, injectAttachButtonInToolbars, removeMultipleNewLines } from 'util/utils';
import { emojiList } from 'util/emoji-list';
import UploadPlugin from 'util/upload-adapter';
import { MentionCustomization, getMentions, mentionStringify } from 'util/mention';
import { getBaseUrl } from 'services/api/helper';
import { Community, Literals } from 'redux/types/account';
import { useEditorLanguage } from './utils';

const oembedOrigin = getBaseUrl();

const plugins = [
  'Essentials',
  'Bold',
  'Italic',
  'Heading',
  'Link',
  'List',
  'Image',
  'EasyImage',
  'MediaEmbed',
  'Table',
  'TableToolbar',
  'PasteFromOffice',
  'Emoji',
  'Autoformat',
  'TableProperties',
  'TableCellProperties',
  'ImageToolbar',
  'ImageStyle',
  'ImageResize',
  'ImageCaption',
  'TodoList',
  'Font',
  'Oembed',
  'GeneralHtmlSupport',
  'Mention',
];

const toolbarLight = ['link', 'imageUpload', 'mediaEmbed', 'emoji', 'bulletedList', 'numberedList', 'oembedUrl'];
const toolbarFull = [
  'heading',
  'bold',
  'italic',
  'fontColor',
  'fontBackgroundColor',
  'link',
  'bulletedList',
  'numberedList',
  'todoList',
  'imageUpload',
  'mediaEmbed',
  'insertTable',
  'tableColumn',
  'tableRow',
  'mergeTableCells',
  'emoji',
  'oembedUrl',
];

const mapStateToProps = (state: RootState) => ({
  community: state.account.selectedCommunity,
  bearer: state.account.session.session.bearer,
  literals: state.literals,
});

interface IEditor {
  content: string | undefined;
  defaultContent?: string | undefined;
  placeholder?: string;
  withMentions?: boolean;
  disabled?: boolean;
  initAutoFocus?: boolean;
  lightToolbar?: boolean;
  smallText?: boolean;
  withPadding?: boolean;
  setContent?: (data: string) => void;
  onBlur?: () => void;
  community: Community;
  bearer: string;
  literals: Literals;
}

const Editor = ({
  content,
  defaultContent = '',
  placeholder = '',
  withMentions = false,
  disabled = false,
  initAutoFocus = false,
  lightToolbar = false,
  smallText = false,
  withPadding = false,
  setContent = () => {},
  onBlur,
  community,
  bearer,
  literals,
}: IEditor) => {
  const mentionFeed = withMentions ? useMemo(() => getMentions(community), [community]) : '';
  const { editorLanguage, languageContentClass } = useEditorLanguage();

  return (
    <div
      className={`ck-content ${languageContentClass} ${smallText ? 'ck-content-small' : ''} ${
        withPadding ? 'ck-content-padding' : ''
      }`}
    >
      <CKEditor
        editor={InlineEditor}
        data={content}
        onError={() => {
          console.log('error');
        }}
        onChange={(_: unknown, editor: { getData(): string }) => {
          setContent(editor.getData());
        }}
        onInit={(editor: { getData(): string; editing: { view: { focus: () => void } } }) => {
          if (defaultContent) {
            setContent(defaultContent);
          }
          if (initAutoFocus) editor.editing.view.focus();
          injectAttachButtonInToolbars(bearer, literals.upload_resource_too_big_message);
        }}
        onFocus={() => {
          (window as any).lastFocusedEditorSetter = (content: string) => {
            setContent(content);
          };
        }}
        onBlur={onBlur}
        disabled={disabled}
        config={{
          bearer,
          oembedOrigin,
          mention: withMentions
            ? {
                feeds: [
                  {
                    marker: '@',
                    feed: mentionFeed,
                    minimumCharacters: 1,
                  },
                ],
              }
            : {},
          htmlSupport: {
            allow: [
              {
                name: 'iframe',
                attributes: true,
                classes: true,
                styles: true,
              },
            ],
          },
          htmlEmbed: {
            showPreviews: true,
            sanitizeHtml: (inputHtml: string) => {
              let sanitizedHTML = removeMultipleNewLines(decode(inputHtml, { level: 'all' }), true);
              if (withMentions) {
                sanitizedHTML = mentionStringify(sanitizedHTML);
              }

              return {
                html: sanitizedHTML,
                hasChanged: sanitizedHTML !== inputHtml,
              };
            },
          },
          placeholder,
          image: ckEditorImageDefaultConfig,
          mediaEmbed: {
            previewsInData: true,
          },
          link: {
            addTargetToExternalLinks: true,
          },
          language: editorLanguage,
          emoji: emojiList,
          toolbar: lightToolbar ? toolbarLight : toolbarFull,
          plugins,
          extraPlugins: withMentions ? [UploadPlugin, MentionCustomization] : [UploadPlugin],
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(Editor);
