import React, { useEffect, useRef, useState } from 'react';
import { Popover, IconButton } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  label: string;
  isEditingTags?: boolean;
  isFilterSelected?: boolean;
  outsideClick?: () => void;
  popoverButtonComponent?: React.ComponentType<any>;
  customPropChildren?: any;
}

const StyledPopoverButton = styled(IconButton)<{ isFilterSelected?: boolean }>`
  &&& {
    width: auto;
    padding: 1em;
    height: 3em;
    border: 1px solid rgb(217, 217, 217);
    border: ${({ isFilterSelected, theme }) =>
      isFilterSelected ? `1px solid ${theme.colors.blue.normal}` : '1px solid rgb(217, 217, 217)'};
    border-radius: 7px;
    margin: 0.2em;
    color: ${({ theme }) => theme.colors.grey.thirdMedium};
    font-size: 0.8em !important;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
`;

const BasicPopover: React.FC<Props> = ({
  children,
  label,
  isEditingTags,
  isFilterSelected,
  outsideClick,
  popoverButtonComponent,
  popoverButtonComponent: PopoverButton = StyledPopoverButton, // Use StyledPopoverButton as default if no prop is provided
  customPropChildren,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isEditingTags || !isFilterSelected) {
      handleClose();
    }
  }, [isEditingTags, isFilterSelected]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      anchorEl &&
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node) &&
      !anchorEl.contains(event.target as Node) &&
      !(event?.target as HTMLElement).classList.contains('MuiListItem-root') &&
      !(event?.target as HTMLElement).classList.contains('MuiPaper-root') &&
      !(event?.target as HTMLElement).classList.contains('MuiList-root')
    ) {
      if (outsideClick) outsideClick();
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [anchorEl]);

  return (
    <div>
      <PopoverButton
        name={label}
        style={{ cursor: 'pointer' }}
        aria-describedby={id}
        onClick={handleClick}
        edge="end"
        isFilterSelected={isFilterSelected}
      >
        {popoverButtonComponent && customPropChildren ? (
          <>{customPropChildren}</>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p>{label}</p>
            <ArrowDropDown />
          </div>
        )}
      </PopoverButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div ref={popoverRef} style={{ padding: '1em' }}>
          {children}
        </div>
      </Popover>
    </div>
  );
};

export default BasicPopover;
