import { AxiosResponse } from 'axios';
import { getAxiosInstance } from './helper';
import {
  UserEvent,
  MeetingsSettings,
  LogInCalendarData,
  MeetingsSettingsDto,
  CommunitySettings,
  CommunityCalendarLogin,
  CommunityCalendarLogout,
  CommunityMeetingLogin,
  CommunityMeetingLogout,
} from '../../redux/types/calendar';
import { DataWithBearer } from 'util/types';

const ai = getAxiosInstance();

export function calendarLogInOutlook(
  bearer: string,
  authorizationCode: string,
  redirectUri: string,
): Promise<{
  logInData: LogInCalendarData[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/OutlookCalendar/CalendarLogIn',
      data: {
        authorizationCode,
        redirectUri,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          logInData: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function setUserCalendarOutlook(bearer: string, calendarId: string): Promise<{ userEvents: UserEvent[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/OutlookCalendar/SetUserCalendar',
      data: {
        calendarId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          userEvents: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function calendarLogOutOutlook(bearer: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/OutlookCalendar/CalendarLogOut',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function calendarLogInGoogle(
  bearer: string,
  authorizationCode: string,
  redirectUri: string,
): Promise<{ logInData: LogInCalendarData[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Google/CalendarLogIn',
      data: {
        authorizationCode,
        redirectUri,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          logInData: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function communityCalendarLogInGoogle(
  data: DataWithBearer<CommunityCalendarLogin>,
): Promise<{ logInData: LogInCalendarData[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: 'api/Google/CommunityCalendarLogin',
      data: data.data,
      headers: {
        Authorization: `Bearer ${data.bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          logInData: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function communityCalendarLogOutGoogle(
  data: DataWithBearer<CommunityCalendarLogout>,
): Promise<{ logInData: LogInCalendarData[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `api/Google/CommunityCalendarLogOut/${data.data.communityId}`,
      headers: {
        Authorization: `Bearer ${data.bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          logInData: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function communityCalendarLogInOutlook(
  data: DataWithBearer<CommunityCalendarLogin>,
): Promise<{ logInData: LogInCalendarData[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: 'api/OutlookCalendar/CommunityCalendarLogin',
      data: data.data,
      headers: {
        Authorization: `Bearer ${data.bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          logInData: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function communityCalendarLogOutOutlook(data: DataWithBearer<CommunityCalendarLogout>): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `api/OutlookCalendar/CommunityCalendarLogOut/${data.data.communityId}`,
      headers: {
        Authorization: `Bearer ${data.bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityCalendarSettings(bearer: string, communityId: number): Promise<CommunitySettings> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/CommunityCalendar/GetMeetingSettings/${communityId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function saveCommunityCalendarSettings({ bearer, data }: DataWithBearer<CommunitySettings>): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/CommunityCalendar/SaveMeetingSettings',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then((response: AxiosResponse) => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function communityMeetingProviderSync(data: DataWithBearer<CommunityMeetingLogin>): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/MeetingProvider/CommunitySynchronize',
      data: data.data,
      headers: {
        Authorization: `Bearer ${data.bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function communityMeetingProviderUnSync(data: DataWithBearer<CommunityCalendarLogout>): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/MeetingProvider/CommunityDeSynchronize/${data.data.communityId}`,
      headers: {
        Authorization: `Bearer ${data.bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function setUserCalendarGoogle(bearer: string, calendarId: string): Promise<{ userEvents: UserEvent[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Google/SetUserCalendar',
      data: {
        calendarId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({ userEvents: response.data });
      })
      .catch(err => reject(err));
  });
}

export function calendarLogOutGoogle(bearer: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Google/CalendarLogOut',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function saveMeetingsSettings(
  bearer: string,
  meetingSettings: MeetingsSettingsDto,
): Promise<{
  meetingSettings: MeetingsSettingsDto;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/UserCalendar/SaveMeetingSettings',
      data: meetingSettings,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          meetingSettings: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function getMeetingsSettings(bearer: string, userId: number): Promise<{ meetingSettings: MeetingsSettings }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/UserCalendar/GetMeetingSettings',
      params: {
        userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          meetingSettings: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function getUserEventsByMonth(
  bearer: string,
  userId: number,
  month: number,
  year: number,
): Promise<{ userEvents: UserEvent[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/UserCalendar/GetUserEventsByMonth',
      params: {
        userId,
        month,
        year,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          userEvents: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function meetingProviderSync(
  bearer: string,
  authorizationCode: string,
  redirectUri: string,
  meetingProviderType: 0 | 1,
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/MeetingProvider/UserSynchronize',
      data: {
        authorizationCode,
        redirectUri,
        meetingProviderType,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function meetingProviderUnSync(bearer: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/MeetingProvider/UserDeSynchronize',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export default {
  calendarLogInOutlook,
  calendarLogInGoogle,
  calendarLogOutGoogle,
  calendarLogOutOutlook,
  getMeetingsSettings,
  saveMeetingsSettings,
  getUserEventsByMonth,
  setUserCalendarOutlook,
  setUserCalendarGoogle,
  meetingProviderUnSync,
  meetingProviderSync,
  communityMeetingProviderSync,
  communityMeetingProviderUnSync,
};
