import styled from 'styled-components';
import { Input, Select } from '@material-ui/core';
import Button from 'primitives/Button';

export const Container = styled.div`
  padding: 1em 0;
`;

export const SyncCalendarContainerFlex = styled.div`
  margin-top: 2em;
  margin-left: 1rem;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
`;

export const MeetingPreferencesFlex = styled.div`
  margin: 1rem 0 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
`;

export const CalendarPlatformOptionButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2.5rem;
  background-color: whitesmoke;
  border-radius: 0.7rem;
  border: 2px solid #c7c6c6;
  width: 10em;
  :hover {
    cursor: pointer;
  }
`;

export const CalendarSyncWizardOptionButton = styled.a`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: whitesmoke;
  width: 13em;
  border-radius: 0.7rem;
  border: 2px solid #c7c6c6;
  :hover {
    cursor: pointer;
  }
`;

export const FormatedP = styled.p`
  margin: 0.5rem 0 1em 1rem;
  padding-bottom: 0.5rem;
`;

export const FormatedH3 = styled.h3`
  margin-left: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: black;
  padding-bottom: 0.5rem;
  line-height: 25px;
`;

export const FormatedTitleImage = styled.img`
  height: 30px;
`;

export const FormatedInput = styled(Input)`
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.grey.medium};
  border-radius: 0.8rem;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
`;

export const FormatedSelect = styled(Select)`
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.grey.medium};
  border-radius: 0.8rem;
  width: 100%;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
  & .MuiSelect-select.MuiSelect-select {
    text-align: left;
  }
`;

export const SaveChangesButton = styled(Button)`
  padding: 0.3rem 2rem;
  border-radius: 1.5rem;
  color: white;
  font-weight: bolder;
  font-size: 1rem;
`;

export const SaveChangesPlacementFlex = styled.div`
  margin: 2em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

export const CalendarSchedulerContainer = styled.div`
  margin: 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  background-color: white;
`;

export const TimeOptionMeetButton = styled.button<{ active?: boolean }>`
  padding: 1em;
  background-color: white;
  border-radius: 1.5rem;
  color: black;
  font-size: 1.2em;
  ${props => !props.active && `border: 2px solid gray`}
  ${props => props.active && `border: 2px solid blue`}
`;

export const TitleFlexContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 1rem 0 1rem 1rem;
  color: black;
`;
