import { fetchTabEntityCommentsAsync } from 'redux/actions/feed';
import {
  deleteTabAsync,
  fetchCommunityTabsAsync,
  saveTabAsync,
  fetchTabByIdAsync,
  fetchTabEntitiesByIdAsync,
  followOrUnFollowEntityAsync,
  inviteTabEntityMembersAsync,
  fetchCommunityTabEntityCirclesAsync,
  fetchCommunityTabEntityCircleMembersAsync,
  removeTabEntityInvitationAsync,
} from '../../actions/tab';
import { buildFlagReducer } from '../../store/utils';

export const saveTabFlag = buildFlagReducer(saveTabAsync);

export const fetchCommunityTabsFlag = buildFlagReducer(fetchCommunityTabsAsync);

export const deleteTabFlag = buildFlagReducer(deleteTabAsync);

export const fetchTabByIdFlag = buildFlagReducer(fetchTabByIdAsync);

export const fetchTabEntitiesByIdFlag = buildFlagReducer(fetchTabEntitiesByIdAsync);

export const followOrUnFollowEntityFlag = buildFlagReducer(followOrUnFollowEntityAsync);

export const inviteTabEntityMembersFlag = buildFlagReducer(inviteTabEntityMembersAsync);

export const fetchTabEntityCommentsFlag = buildFlagReducer(fetchTabEntityCommentsAsync);

export const fetchCommunityTabEntityCirclesFlag = buildFlagReducer(fetchCommunityTabEntityCirclesAsync);

export const fetchCommunityTabEntityCircleMembersFlag = buildFlagReducer(fetchCommunityTabEntityCircleMembersAsync);

export const removeTabEntityInvitationFlag = buildFlagReducer(removeTabEntityInvitationAsync);

export default {
  saveTabFlag,
  fetchCommunityTabsFlag,
  deleteTabFlag,
  fetchTabByIdFlag,
  fetchTabEntitiesByIdFlag,
  followOrUnFollowEntityFlag,
  inviteTabEntityMembersFlag,
  fetchTabEntityCommentsFlag,
  fetchCommunityTabEntityCirclesFlag,
  fetchCommunityTabEntityCircleMembersFlag,
  removeTabEntityInvitationFlag,
};
