import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Image from 'components/common/lazyImage';
import { PrimaryButton, StyledCardDescription } from 'components/styled-components/common';
import { useHistory } from 'react-router';
import AddCoachingModal from 'components/requests/add-coaching-modal';
import { Circle, Community, Project, Member } from 'redux/types/account';
import { fetchProjectMembers } from '../../services/api/project';
import { createNewRequestAsync } from 'redux/actions/request';
import { StyledButton } from 'primitives/Button/style';

const Card = styled.div`
  width: 100%;
  min-height: 5em;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 0.5em;
  padding: 1em;
  margin-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;

  a,
  span {
    font-size: 0.9em;
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

const mapDispatchToProps = {
  createNewRequest: createNewRequestAsync.request,
};

type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  project: Project;
  bearer: string;
  buttonView?: boolean;
  defaultSummary?: string;
  defaultDescription?: string;
}

const CoachingSessionCard: React.FC<Props> = ({
  literals,
  project,
  bearer,
  createNewRequest,
  buttonView,
  ...props
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [teamMembers, setTeamMembers] = useState<Member[]>(project.members || []);

  const teamCircles = useMemo(() => (project.circles || []).filter((c: Circle) => c.name === 'Team'), [
    project.circles,
  ]);

  const filteredTeamMembers = useMemo(
    () =>
      (teamCircles.length === 1 && teamMembers.filter((member: Member) => member.circle.id === teamCircles[0].id)) ||
      [],
    [teamCircles, teamMembers],
  );

  const history = useHistory();
  const addRequestModal = (history.location.state as any)?.addRequestModal || false;

  const fetchTeamAddRequestModal = async () => {
    if (teamMembers.length) return teamMembers;
    if (!project) return [];
    const members = await fetchProjectMembers(bearer, project.id, {});
    setTeamMembers(members.members);
    return members.members;
  };

  const handleSearchMembers = (concept: string) => {
    const conceptLowercase = concept.toLowerCase();
    const filterMembers = (members: Member[]): { list: Member[] } => {
      const filteredMembers = members.filter(
        (member: Member) =>
          member.name.toLowerCase().includes(conceptLowercase) || member.email.toLowerCase().includes(conceptLowercase),
      );
      return { list: filteredMembers };
    };
    return new Promise<{ list: Member[] }>(resolve => {
      if (teamMembers.length) resolve(filterMembers(filteredTeamMembers));
      else fetchTeamAddRequestModal().then(members => resolve(filterMembers(members)));
    });
  };

  return (
    <>
      {addRequestModal && (
        <AddCoachingModal
          projectId={Number(projectId || '0')}
          defaultDecision={'coach'}
          circles={[]}
          teamCircles={teamCircles}
          fetchMembers={async () => []}
          fetchTeam={fetchTeamAddRequestModal}
          searchMembers={handleSearchMembers}
          fetchCircles={() => {}}
          teamMemberScheduling={false}
          defaultSummary={props.defaultSummary}
          defaultDescription={props.defaultDescription}
        />
      )}
      {buttonView ? (
        <StyledButton
          onClick={() => {
            createNewRequest({ projectId: project.id, isProjectAdmin: true });
          }}
        >
          {literals.coaching_schedule_session}
        </StyledButton>
      ) : (
        <Card>
          <Image src={'/static/coaching-session/coach.png'} alt={literals.resources_upload_resources} />
          <StyledCardDescription>{literals.coaching_session_panel_description}</StyledCardDescription>
          <StyledButton
            onClick={() => {
              createNewRequest({ projectId: project.id, isProjectAdmin: true });
            }}
          >
            {literals.coaching_schedule_session}
          </StyledButton>
        </Card>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachingSessionCard);
