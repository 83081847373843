import React from 'react';
import styled from 'styled-components';
import Image from './lazyImage';
import { ImageSizeType } from 'redux/types/account';

import LogoPlaceholder from '../../assets/common/logo-placeholder.svg';

const BigImage = styled(Image)`
  width: 10em;
  height: 10em;
  object-fit: cover;
  border-radius: 10px;
  border: '2px solid ${({ theme }) => theme.colors.grey.light}';
`;

interface LogoProps {
  src: string | undefined;
  style?: any | undefined;
  loading?: 'eager' | 'lazy';
  rounded?: boolean;
  fluid?: boolean;
  customSize?: ImageSizeType;
  onClick?: any;
}
export const Logo: React.FC<LogoProps> = props => {
  const { src, ...rest } = props;
  return <BigImage src={src || LogoPlaceholder} {...rest} />;
};

const MediumImage = styled(Image)`
  width: 3.5em;
  height: 3.5em;
  object-fit: cover;
  border-radius: 5px;
  border: '2px solid ${({ theme }) => theme.colors.grey.light}';
`;

interface MediumLogoProps {
  src: string | undefined;
  loading?: 'eager' | 'lazy';
  customSize?: ImageSizeType;
  alt?: string;
}
export const MediumLogo: React.FC<MediumLogoProps> = props => {
  if (!props.src) return <MediumImage loading={props.loading} src={LogoPlaceholder} />;
  return <MediumImage {...props} />;
};
