import { useSelector } from 'react-redux';

export const useEditorLanguage = () => {
  const userLanguage = useSelector((state: any) => {
    return state?.account?.details?.user?.language;
  });

  return {
    editorLanguage: userLanguage?.split('-')[0] || 'en',
    languageContentClass: userLanguage === 'ar-AR' ? 'ck-content-right' : 'ck-content-left',
  };
};
