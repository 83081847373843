import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { uniqBy, prop, clone } from 'ramda';
import {
  Container,
  SyncCalendarContainerFlex,
  CalendarPlatformOptionButton,
  FormatedH3,
  FormatedInput,
  FormatedP,
  MeetingPreferencesFlex,
  FormatedSelect,
  SaveChangesButton,
  SaveChangesPlacementFlex,
  CalendarSchedulerContainer,
} from '../styled-components/user-profile-calendar-components';
import { Grid, Divider, MenuItem } from '@material-ui/core';
import BulkImage from '../../assets/sidebar-icons/bulb.svg';
import SettingSlidersImage from '../../assets/sidebar-icons/settings-sliders.svg';
import CalendarClockImage from '../../assets/sidebar-icons/calendar-clock.svg';
import CalendarPeopleImage from '../../assets/sidebar-icons/calendar-people-image.png';
import {
  fetchMeetingsSettingsAsync,
  saveMeetingsSettingsAsync,
  getUserEventsByMonthAsync,
  calendarLogInOutlookAsync,
  setUserCalendarOutlookAsync,
  calendarLogOutOutlookAsync,
  calendarLogOutGoogleAsync,
  setUserCalendarGoogleAsync,
  calendarLogInGoogleAsync,
  meetingProviderSyncAsync,
  meetingProviderUnSyncAsync,
} from '../../redux/actions/calendar';
import { Day, LogInCalendarData, MeetingsSettings, UserEvent } from '../../redux/types/calendar';
import StyledMeetingTypeButton from './calendar-components/styled-meeting-type-button';
import { ALL_AVAILABLE_TIMEZONES, DEFAULT_MEETING_SETTINGS } from '../../redux/types/default-types-values';
import GoogleCalendarLogo from '../../assets/logos/google-calendar-logo.png';
import OutlookLogo from '../../assets/logos/outlook-logo.png';
import ZoomLogo from '../../assets/logos/zoom-logo.png';
import MicrosoftTeamsLogo from '../../assets/logos/microsoft-teams-logo.png';
import {
  CalendarProvider,
  BookingType,
  AVAILABLE_MINUTES_DURATION,
  Flag,
  MeetingProvider,
} from '../../redux/types/enums';
import { Dimmer, Loader } from 'semantic-ui-react';
import { fetchCurrentUserAsync, syncCalendarUpdate, syncMeetingProviderUpdate } from '../../redux/actions/account';
import CalendarScheduler from 'components/common/calendar-scheduler/calendar-scheduler';
import moment from 'moment';
import {
  getAccountCalendarUri,
  getUrlWithRedirectUri,
  getCalendarRedirectUri,
  URLType,
  getMonthsForCurrentWeek,
  getSurroundingMonths,
  mergeConsecutiveSlots,
} from '../../util/calendar';
import { CalendarViewType, CustomEvent } from '../../components/common/calendar-scheduler/types';
import UserEventsCalendar from './calendar-components/user-events-calendar';
import { NavigateAction, View, momentLocalizer } from 'react-big-calendar';
import CalendarSyncModal from './calendar-components/calendar-sync-modal';
import WeeklyHoursItem from './calendar-components/weekly-hours-item';
import SyncPlatformPanel from './calendar-components/sync-platform-panel';
import TitleWithIcon from '../common/title-with-icon';
import { toast } from '../common/toast';
import './styles.css';
import { YearMonth } from 'services/types/user-calendar';
import momentTimeZone from 'moment-timezone';
import { UserMeetingPreference } from '../../services/types/user-calendar';

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  account: state.account.details.user,
  calendarSettings: state.meetingSettings,
  userEventsByMonth: state.userEventsByMonth,
  calendarLogInData: state.calendarLogInData,
  isFetchingMeetingsSettings: state.loading.fetchMeetingsSettingsFlag,
  isSavingMeetingSettings: state.loading.saveMeetingsSettingsFlag,
  isFetchingUserEventsByMonth: state.loading.getUserEventsByMonthFlag,
  isSettingUpOutlookCalendar: state.loading.setUserCalendarOutlookFlag,
  isSettingUpGoogleCalendar: state.loading.setUserCalendarGoogleFlag,
  isLoggingOutGoogleCalendar: state.loading.calendarLogOutGoogleFlag,
  isLoggingInGoogleCalendar: state.loading.calendarLogInGoogleFlag,
  isLoggingInOutlookCalendar: state.loading.calendarLogInOutlookFlag,
  isLoggingOutOutlookCalendar: state.loading.calendarLogOutOutlookFlag,
  isMeetingProviderSync: state.loading.meetingProviderSyncFlag,
  isMeetingProviderUnSync: state.loading.meetingProviderUnSyncFlag,
});

const mapDispatchToProps = {
  fetchMeetingsSettings: fetchMeetingsSettingsAsync.request,
  saveMeetingsSettings: saveMeetingsSettingsAsync.request,
  getUserEventsByMonth: getUserEventsByMonthAsync.request,
  calendarLogInOutlook: calendarLogInOutlookAsync.request,
  setUserCalendarOutlook: setUserCalendarOutlookAsync.request,
  calendarLogOutOutlook: calendarLogOutOutlookAsync.request,
  calendarLogInGoogle: calendarLogInGoogleAsync.request,
  calendarLogOutGoogle: calendarLogOutGoogleAsync.request,
  setUserCalendarGoogle: setUserCalendarGoogleAsync.request,
  meetingProviderSync: meetingProviderSyncAsync.request,
  meetingProviderUnSync: meetingProviderUnSyncAsync.request,
  fetchCurrentUserData: fetchCurrentUserAsync.request,
  syncCalendarUpdate: syncCalendarUpdate,
  syncMeetingProviderUpdate: syncMeetingProviderUpdate,
};
type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  bearer: string;
  syncCalendarData: { code: string; provider: 0 | 1 } | null;
  syncMeetingProviderData: { code: string; meetingProvider: 0 | 1 } | null;
}

// Updating the localizer to have Monday as the first day of the week
// Explanation https://github.com/jquense/react-big-calendar/issues/1661
moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 4,
  },
});

const CalendarSettings: React.FC<Props> = ({
  literals,
  meetingProviderSync,
  meetingProviderUnSync,
  syncCalendarUpdate,
  syncCalendarData,
  calendarSettings,
  bearer,
  account,
  fetchMeetingsSettings,
  saveMeetingsSettings,
  getUserEventsByMonth,
  userEventsByMonth,
  isFetchingUserEventsByMonth,
  isSavingMeetingSettings,
  isFetchingMeetingsSettings,
  calendarLogInData,
  setUserCalendarOutlook,
  calendarLogInOutlook,
  calendarLogOutOutlook,
  setUserCalendarGoogle,
  calendarLogOutGoogle,
  calendarLogInGoogle,
  isSettingUpGoogleCalendar,
  isSettingUpOutlookCalendar,
  isLoggingOutGoogleCalendar,
  isLoggingOutOutlookCalendar,
  isMeetingProviderUnSync,
  isMeetingProviderSync,
  syncMeetingProviderData,
  isLoggingInGoogleCalendar,
  isLoggingInOutlookCalendar,
  syncMeetingProviderUpdate,
  fetchCurrentUserData,
}) => {
  const production = process.env.REACT_APP_DEPLOYMENT_TYPE === 'production';
  const urlParams = new URLSearchParams(window.location.search);
  const urlState = urlParams.get('state');
  const redirectTo =
    urlState && production && window.location.origin.includes('app.babele') && !urlState.includes('app.babele')
      ? decodeURIComponent(urlState)
      : null;

  const [userMeetingsSettings, setUserMeetingsSettings] = useState<MeetingsSettings | null>(DEFAULT_MEETING_SETTINGS);
  const [userEvents, setUserEvents] = useState<UserEvent[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  // Tracking the months we fetched events for, so we don't fetch unnecessarily
  // These will be strings in the format YYYY-M, i.e 2024-2
  const [fetchedMonths, setFetchedMonths] = useState<YearMonth[]>([]);
  const [view, setView] = useState<View>('week');
  // Initialize it with the browser local timezone
  const [currentUserTimeZone, setCurrentUserTimeZone] = useState<string>(momentTimeZone.tz.guess());

  const localizer = momentLocalizer(moment);
  moment.tz.setDefault(currentUserTimeZone);
  const currentPath = window.location.href;
  const defaultMeetingSettings = clone(DEFAULT_MEETING_SETTINGS);
  // If the user doesn't have any timezone set, this will be the current browser local timezone
  // we need to set this as the default when the user is saving their meeting preferences, otherwise
  // we won't be able to accurately calculate meetings availabilities
  defaultMeetingSettings.userMeetingPreference.timeZone = currentUserTimeZone;

  const onCalendarNavigate = (newDate: Date, view: View, action: NavigateAction) => {
    setCurrentDate(newDate);
  };

  useEffect(() => {
    if (redirectTo) {
      window.location.href = `${redirectTo}${window.location.search}`;
    }
  }, []);

  const defaultStartOfDay = momentTimeZone.tz(currentUserTimeZone);
  const defaultEndOfDay = momentTimeZone.tz(currentUserTimeZone);
  defaultStartOfDay.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
  defaultEndOfDay.set({ hour: 22, minute: 0, second: 0, millisecond: 0 });

  useEffect(() => {
    if (!account.id) return;
    fetchMeetingsSettings({ bearer, userId: account.id });
  }, [account.id]);

  // Reset moment locale to the default browser one when the component unmounts
  // so it doesn't interfere with other components using moment
  useEffect(() => {
    return () => {
      moment.tz.setDefault(momentTimeZone.tz.guess());
    };
  }, []);

  useEffect(() => {
    if (!account.id) return;
    const monthsToShow: YearMonth[] = [];

    // A week can span over 2 months
    if (view === 'week') {
      monthsToShow.push(...getMonthsForCurrentWeek(currentDate));
    }

    // In the month calendar view, some days from the previous and next
    // month can also be visible
    if (view === 'month') {
      monthsToShow.push(...getSurroundingMonths(currentDate));
    }

    // Only fetch months that we haven't fetched already
    const monthsToFetch: YearMonth[] = monthsToShow.filter(({ year, month }) => {
      let isNotFetched = true;

      fetchedMonths.forEach(fetchedMonth => {
        if (fetchedMonth.year === year && fetchedMonth.month === month) isNotFetched = false;
      });

      return isNotFetched;
    });

    setFetchedMonths(fetched => [...fetched, ...monthsToFetch]);

    monthsToFetch.forEach(({ year, month }) => {
      getUserEventsByMonth({
        bearer,
        userId: account.id,
        month,
        year,
      });
    });
  }, [account.id, currentDate, view]);

  useEffect(() => {
    if (
      !account?.isCalendarSynchronized &&
      (isLoggingInGoogleCalendar === Flag.Success || isLoggingInOutlookCalendar === Flag.Success)
    ) {
      setIsVisible(true);
    }
    if (isLoggingInGoogleCalendar === Flag.Failure || isLoggingInOutlookCalendar === Flag.Failure) {
      toast(literals.calendar_sync_toast_calendar_log_in_error, { type: 'error' });
    }
  }, [isLoggingInGoogleCalendar, isLoggingInOutlookCalendar]);

  useEffect(() => {
    if (!syncCalendarData || account?.isCalendarSynchronized || redirectTo) return;
    const currentExtractBaseUrl =
      process.env.REACT_APP_DEPLOYMENT_TYPE === 'production' && !window.location.origin.includes('app.babele.co')
        ? getCalendarRedirectUri()
        : getAccountCalendarUri();
    if (syncCalendarData.provider === CalendarProvider.Google)
      calendarLogInGoogle({ bearer, authorizationCode: syncCalendarData.code, redirectUri: currentExtractBaseUrl });
    else if (syncCalendarData.provider === CalendarProvider.Outlook)
      calendarLogInOutlook({ bearer, authorizationCode: syncCalendarData.code, redirectUri: currentExtractBaseUrl });
  }, [syncCalendarData]);

  useEffect(() => {
    if (!syncMeetingProviderData || account.hasMeetingProvider || redirectTo) return;

    const currentExtractBaseUrl =
      process.env.REACT_APP_DEPLOYMENT_TYPE === 'production' && !window.location.origin.includes('app.babele.co')
        ? getCalendarRedirectUri()
        : getAccountCalendarUri();

    meetingProviderSync({
      bearer,
      authorizationCode: syncMeetingProviderData.code,
      redirectUri:
        currentExtractBaseUrl +
        `/provider-${syncMeetingProviderData.meetingProvider === MeetingProvider.MSTeams ? 'msteams' : 'zoom'}`,
      meetingProviderType: syncMeetingProviderData.meetingProvider,
    });
  }, [syncMeetingProviderData]);

  useEffect(() => {
    if (!calendarLogInData || account?.isCalendarSynchronized) return;
    setIsVisible(true);
  }, [calendarLogInData]);

  const setUserCalendar = (calendar: LogInCalendarData) => {
    if (!calendar) return;
    if (syncCalendarData?.provider === CalendarProvider.Google) {
      setUserCalendarGoogle({ bearer, calendarId: calendar.id });
      setIsVisible(false);
    } else if (syncCalendarData?.provider === CalendarProvider.Outlook) {
      setUserCalendarOutlook({ bearer, calendarId: calendar.id });
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (!calendarSettings) {
      setUserMeetingsSettings(defaultMeetingSettings);
      return;
    }

    const clonedSettings = clone(calendarSettings);
    const { meetingSettings }: { meetingSettings: MeetingsSettings } = clonedSettings;

    if (!meetingSettings?.userMeetingPreference?.timeZone) {
      meetingSettings.userMeetingPreference.timeZone = momentTimeZone.tz.guess();
    }

    setUserMeetingsSettings(calendarSettings.meetingSettings);
  }, [calendarSettings]);

  useEffect(() => {
    if (userMeetingsSettings?.userMeetingPreference?.timeZone) {
      setCurrentUserTimeZone(userMeetingsSettings?.userMeetingPreference.timeZone);
    }
  }, [userMeetingsSettings?.userMeetingPreference.timeZone]);

  useEffect(() => {
    if (!userEventsByMonth) return;
    setUserEvents(currentEvents => {
      const allEvents = [...currentEvents, ...userEventsByMonth.userEvents];
      const uniqueEvents = uniqBy(prop('id'), allEvents);
      return uniqueEvents;
    });
  }, [userEventsByMonth]);

  useEffect(() => {
    if (isSettingUpGoogleCalendar === Flag.Success || isSettingUpOutlookCalendar === Flag.Success) {
      toast(literals.calendar_sync_toast_calendar_sync_success, { type: 'success' });
      fetchCurrentUserData(bearer);
    }
    if (isSettingUpGoogleCalendar === Flag.Failure || isSettingUpOutlookCalendar === Flag.Failure) {
      toast(literals.calendar_sync_toast_calendar_sync_error, { type: 'error' });
    }
    window.history.pushState({}, document.title, `${window.location.origin}${window.location.pathname}`);
  }, [isSettingUpGoogleCalendar, isSettingUpOutlookCalendar]);

  useEffect(() => {
    if (isMeetingProviderSync === Flag.Success && syncMeetingProviderData) {
      toast(literals.calendar_sync_toast_meeting_provider_success, { type: 'success' });
      fetchCurrentUserData(bearer);
    }
    if (isMeetingProviderSync === Flag.Failure) {
      toast(literals.calendar_sync_toast_meeting_provider_error, { type: 'error' });
    }
    window.history.pushState({}, document.title, `${window.location.origin}${window.location.pathname}`);
  }, [isMeetingProviderSync]);

  useEffect(() => {
    if (isMeetingProviderUnSync === Flag.Success) {
      syncMeetingProviderUpdate({
        hasMeetingProvider: false,
        meetingProviderType: 0,
        meetingProviderAccount: null,
      });
      toast(literals.calendar_sync_toast_meeting_provider_unsync_success, { type: 'success' });
    }
    if (isMeetingProviderUnSync === Flag.Failure) {
      toast(literals.calendar_sync_toast_meeting_provider_unsync_error, { type: 'error' });
    }
  }, [isMeetingProviderUnSync]);

  useEffect(() => {
    if (isLoggingOutGoogleCalendar === Flag.Success || isLoggingOutOutlookCalendar === Flag.Success) {
      syncCalendarUpdate({
        isCalendarSynchronized: false,
        synchronizedCalendarType: 0,
        synchronizedCalendarAccount: null,
      });
      toast(literals.calendar_sync_toast_calendar_unsync_success, { type: 'success' });
    }
    if (isLoggingOutGoogleCalendar === Flag.Failure || isLoggingOutOutlookCalendar === Flag.Failure) {
      toast(literals.calendar_sync_toast_calendar_unsync_error, { type: 'error' });
    }
  }, [isLoggingOutGoogleCalendar, isLoggingOutOutlookCalendar]);

  useEffect(() => {
    if (isSavingMeetingSettings === Flag.Success) {
      toast(literals.calendar_sync_toast_save_changes_success, { type: 'success' });
    }
    if (isSavingMeetingSettings === Flag.Failure) {
      toast(literals.calendar_sync_toast_save_changes_error, { type: 'error' });
    }
  }, [isSavingMeetingSettings]);

  const events: any = userMeetingsSettings?.userRecurringAvailabilities?.flatMap(event => {
    const startTime = new Date(event.startDateTime);
    const endTime = new Date(event.endDateTime);

    return {
      start: startTime,
      end: endTime,
      eventId: event.id,
    };
  });

  const handleChangeVideoCallLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setUserMeetingsSettings(prevState => {
      if (prevState === null) return null;
      return {
        ...prevState,
        userMeetingPreference: {
          ...prevState.userMeetingPreference,
          videoCallLink: event.target.value,
        },
      };
    });
  };

  const handleSaveMeetingSettings = () => {
    if (userMeetingsSettings) {
      saveMeetingsSettings({
        bearer,
        meetingSettings: {
          ...userMeetingsSettings,
          userRecurringAvailabilities: mergeConsecutiveSlots(userMeetingsSettings.userRecurringAvailabilities),
          userWeeklyAvailabilities: userMeetingsSettings.userWeeklyAvailabilities.map(x => {
            return {
              id: x.newEntry ? 0 : x.id,
              day: x.day,
              startTime: x.startTime.length > 7 ? x.startTime : x.startTime + ':00',
              endTime: x.endTime.length > 7 ? x.endTime : x.endTime + ':00',
            };
          }),
        },
      });
    }
  };

  const logOutCalendar = () => {
    if (account.synchronizedCalendarType === CalendarProvider.Google) calendarLogOutGoogle({ bearer });
    else if (account.synchronizedCalendarType === CalendarProvider.Outlook) calendarLogOutOutlook({ bearer });
  };

  const handleChangeWeeklyAvailability = (day: Day, startTime: string, endTime: string, id: number | null) => {
    let existingWeeklyAvailability = null;
    let newEntry = true;
    if (id) {
      existingWeeklyAvailability = userMeetingsSettings?.userWeeklyAvailabilities?.find(item => item.id === id);
      newEntry = false;
    }
    if (existingWeeklyAvailability) {
      // update existing weekly availability
      setUserMeetingsSettings(prevState => {
        if (prevState === null) return null;
        return {
          ...prevState,
          userWeeklyAvailabilities: prevState.userWeeklyAvailabilities?.map(item => {
            if (item.id === id) {
              return {
                ...item,
                startTime: startTime,
                endTime: endTime,
                newEntry: newEntry,
              };
            }
            return item;
          }),
        };
      });
    } else {
      const newId =
        id ??
        (userMeetingsSettings?.userWeeklyAvailabilities?.length
          ? userMeetingsSettings?.userWeeklyAvailabilities?.length + 1
          : 1);
      setUserMeetingsSettings(prevState => {
        if (prevState === null) return null;
        return {
          ...prevState,
          userWeeklyAvailabilities: [
            ...prevState.userWeeklyAvailabilities,
            {
              id: newId,
              day: day,
              startTime: startTime,
              endTime: endTime,
              newEntry: newEntry,
            },
          ],
        };
      });
    }
  };
  function removeDay(day: Day) {
    setUserMeetingsSettings(prevState => {
      if (prevState === null) return null;
      return {
        ...prevState,
        userWeeklyAvailabilities: prevState.userWeeklyAvailabilities?.filter(item => item.day !== day),
      };
    });
  }

  const handleChangeBookingType = (type: 0 | 1) => {
    setUserMeetingsSettings(prevState => {
      if (prevState === null) return null;
      return {
        ...prevState,
        userMeetingPreference: {
          ...prevState.userMeetingPreference,
          bookingType: type,
        },
      };
    });
  };

  const handleViewChange = (view: View) => {
    setView(view);
  };

  const handleSelectSlot = (event: CustomEvent) => {
    const newEvent = {
      id: 0,
      startDateTime: new Date(event.start).toISOString(),
      endDateTime: new Date(event.end).toISOString(),
    };

    setUserMeetingsSettings((prev: any) => {
      if (!prev) return null;

      const updatedRecurringAvailabilities = prev.userRecurringAvailabilities.filter((existingEvent: any) => {
        const startDateTime = new Date(existingEvent.startDateTime).toISOString();
        const endDateTime = new Date(existingEvent.endDateTime).toISOString();

        const isExistingEventBetween = newEvent.startDateTime <= startDateTime && newEvent.endDateTime >= endDateTime;
        const isExistingEventIncluding = newEvent.startDateTime >= startDateTime && newEvent.endDateTime <= endDateTime;

        return !(isExistingEventBetween || isExistingEventIncluding);
      });

      return {
        ...prev,
        userRecurringAvailabilities: [...updatedRecurringAvailabilities, newEvent],
      };
    });
  };

  const handleSelectEvent = (eventToRemove: any) => {
    setUserMeetingsSettings(prevMeetingsSettings => {
      if (!prevMeetingsSettings) return null;

      const updatedRecurringAvailabilities = prevMeetingsSettings.userRecurringAvailabilities.filter(existingEvent => {
        const startDateTime = new Date(existingEvent.startDateTime).toISOString();
        const endDateTime = new Date(existingEvent.endDateTime).toISOString();
        const eventToRemoveStartDateTime = new Date(eventToRemove.start).toISOString();
        const eventToRemoveEndDateTime = new Date(eventToRemove.end).toISOString();

        if (eventToRemoveStartDateTime === startDateTime && eventToRemoveEndDateTime === endDateTime) {
          return false;
        }
        return true;
      });

      return {
        ...prevMeetingsSettings,
        userRecurringAvailabilities: updatedRecurringAvailabilities,
      };
    });
  };

  const StyledCalendarPlatformOptionButton = styled(CalendarPlatformOptionButton)`
    height: 3.5rem;
    min-width: 10rem;
    padding: 1.5rem;
  `;
  const StyledImage = styled.img`
    height: 2.5rem;
  `;
  return (
    <Container>
      {!account.isCalendarSynchronized ? (
        <>
          <TitleWithIcon
            iconPath={CalendarClockImage}
            infotipChildren={
              <div style={{ padding: '1.5rem' }}>
                <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={CalendarPeopleImage} alt="Calendar Image" />
                <p style={{ fontSize: '1.5rem' }}>{literals.calendar_sync_infotip}</p>
              </div>
            }
            imageAlt={'Setting Slider Image'}
            title={literals.calendar_sync_sync_your_calendar}
          />
          <FormatedH3>{literals.calendar_sync_subtitle}</FormatedH3>
          <FormatedP style={{ marginTop: '0' }}>{literals.calendar_sync_description}</FormatedP>
          <SyncCalendarContainerFlex>
            <StyledCalendarPlatformOptionButton href={getUrlWithRedirectUri(currentPath, URLType.GoogleCalendar)}>
              <StyledImage src={GoogleCalendarLogo} alt="Google Calendar logo" />
            </StyledCalendarPlatformOptionButton>
            <StyledCalendarPlatformOptionButton href={getUrlWithRedirectUri(currentPath, URLType.Outlook)}>
              <StyledImage src={OutlookLogo} alt="Outlook logo" />
            </StyledCalendarPlatformOptionButton>
          </SyncCalendarContainerFlex>
        </>
      ) : (
        <>
          <TitleWithIcon
            isSync={true}
            iconPath={CalendarClockImage}
            imageAlt={'Setting Slider Image'}
            title={literals.calendar_sync_is_sync}
          />
          <UserEventsCalendar
            localizer={localizer}
            userEvents={userEvents}
            defaultView={CalendarViewType.WEEK}
            minTime={defaultStartOfDay.toDate()}
            maxTime={defaultEndOfDay.toDate()}
            views={[CalendarViewType.WEEK, CalendarViewType.MONTH]}
            style={{ margin: '4em 0', height: '620px' }}
            onNavigate={onCalendarNavigate}
            onView={handleViewChange}
          />
          <SyncPlatformPanel
            alt={account.synchronizedCalendarType === CalendarProvider.Google ? 'Google Calendar logo' : 'Outlook Logo'}
            literals={literals}
            logoUrl={account.synchronizedCalendarType === CalendarProvider.Google ? GoogleCalendarLogo : OutlookLogo}
            providerSyncAccount={account.synchronizedCalendarAccount}
            onUnsync={logOutCalendar}
          />
        </>
      )}
      {userMeetingsSettings ? (
        <div>
          <FormatedH3>{literals.calendar_user_profile_timezone_desc}</FormatedH3>
          <FormatedSelect
            value={
              userMeetingsSettings.userMeetingPreference?.timeZone
                ? userMeetingsSettings.userMeetingPreference?.timeZone
                : ''
            }
            onChange={event => {
              setUserMeetingsSettings(prevState => {
                if (prevState === null) return null;
                return {
                  ...prevState,
                  userMeetingPreference: {
                    ...prevState.userMeetingPreference,
                    timeZone: event.target.value as string,
                  },
                };
              });
            }}
            name="timezone"
            id="timezone"
          >
            {ALL_AVAILABLE_TIMEZONES.map((timezone, index) => (
              <MenuItem key={index} value={timezone}>
                {timezone}
              </MenuItem>
            ))}
          </FormatedSelect>
          <Divider style={{ margin: '2em 0' }} />
          <TitleWithIcon
            iconPath={SettingSlidersImage}
            infotipChildren={
              <div style={{ padding: '1.5rem' }}>
                <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={CalendarPeopleImage} alt="Calendar Image" />
                <p style={{ fontSize: '1.5rem' }}>{literals.calendar_sync_meeting_type_infotip}</p>
              </div>
            }
            imageAlt={'Setting Slider Image'}
            title={literals.calendar_user_profile_meeting_preferences}
          />
          <FormatedH3>{literals.calendar_user_profile_book_type_desc}</FormatedH3>
          <FormatedP>{literals.calendar_sync_meeting_type_desc}</FormatedP>
          <Grid style={{ marginBottom: '3em' }} container>
            <Grid item sm={6}>
              <StyledMeetingTypeButton
                onClickButton={() => handleChangeBookingType(BookingType.DirectBooking)}
                isActive={userMeetingsSettings.userMeetingPreference?.bookingType === BookingType.DirectBooking}
                imageUrl={BulkImage}
                title={literals.calendar_user_profile_direct_booking}
                desctiption={literals.calendar_user_profile_direct_booking_desc}
              />
            </Grid>
            <Grid item sm={6}>
              <StyledMeetingTypeButton
                onClickButton={() => handleChangeBookingType(BookingType.FixedTimeslots)}
                isActive={userMeetingsSettings.userMeetingPreference?.bookingType === BookingType.FixedTimeslots}
                imageUrl={CalendarClockImage}
                title={literals.calendar_user_profile_fixed_timeslots}
                desctiption={literals.calendar_user_profile_fixed_timeslots_desc}
              />
            </Grid>
          </Grid>
          {userMeetingsSettings.userMeetingPreference?.bookingType === BookingType.DirectBooking ? (
            <>
              <FormatedH3>{literals.calendar_sync_weekly_hours}</FormatedH3>
              <FormatedP>{literals.calendar_sync_booking_type_direct_booking}</FormatedP>
              <WeeklyHoursItem
                literals={literals}
                day={Day.Monday}
                weeklyAvailabilities={userMeetingsSettings.userWeeklyAvailabilities.filter(x => x.day === 1)}
                handleChangeWeeklyAvailability={handleChangeWeeklyAvailability}
                removeDay={removeDay}
              />
              <Divider style={{ margin: '1rem', width: '60%' }} />
              <WeeklyHoursItem
                literals={literals}
                day={Day.Tuesday}
                weeklyAvailabilities={userMeetingsSettings.userWeeklyAvailabilities.filter(x => x.day === 2)}
                handleChangeWeeklyAvailability={handleChangeWeeklyAvailability}
                removeDay={removeDay}
              />
              <Divider style={{ margin: '1rem', width: '60%' }} />
              <WeeklyHoursItem
                literals={literals}
                day={Day.Wednesday}
                weeklyAvailabilities={userMeetingsSettings.userWeeklyAvailabilities.filter(x => x.day === 3)}
                handleChangeWeeklyAvailability={handleChangeWeeklyAvailability}
                removeDay={removeDay}
              />
              <Divider style={{ margin: '1rem', width: '60%' }} />
              <WeeklyHoursItem
                literals={literals}
                day={Day.Thursday}
                weeklyAvailabilities={userMeetingsSettings.userWeeklyAvailabilities.filter(x => x.day === 4)}
                handleChangeWeeklyAvailability={handleChangeWeeklyAvailability}
                removeDay={removeDay}
              />
              <Divider style={{ margin: '1rem', width: '60%' }} />
              <WeeklyHoursItem
                literals={literals}
                day={Day.Friday}
                weeklyAvailabilities={userMeetingsSettings.userWeeklyAvailabilities.filter(x => x.day === 5)}
                handleChangeWeeklyAvailability={handleChangeWeeklyAvailability}
                removeDay={removeDay}
              />
              <Divider style={{ margin: '1rem', width: '60%' }} />
              <WeeklyHoursItem
                literals={literals}
                day={Day.Saturday}
                weeklyAvailabilities={userMeetingsSettings.userWeeklyAvailabilities.filter(x => x.day === 6)}
                handleChangeWeeklyAvailability={handleChangeWeeklyAvailability}
                removeDay={removeDay}
              />
              <Divider style={{ margin: '1rem', width: '60%' }} />
              <WeeklyHoursItem
                literals={literals}
                day={Day.Sunday}
                weeklyAvailabilities={userMeetingsSettings.userWeeklyAvailabilities.filter(x => x.day === 0)}
                handleChangeWeeklyAvailability={handleChangeWeeklyAvailability}
                removeDay={removeDay}
              />
            </>
          ) : (
            <>
              <FormatedH3>{literals.calendar_sync_fixed_timeslots}</FormatedH3>
              <FormatedP>{literals.calendar_sync_booking_type_fixed_timeslots}</FormatedP>
              <CalendarSchedulerContainer className="user-fixed-availability-calendar">
                <CalendarScheduler
                  events={events}
                  localizer={localizer}
                  isToolbarVisible={true}
                  defaultView={CalendarViewType.WEEK}
                  timeSlot={2}
                  timeIncrements={30}
                  onSelectSlot={handleSelectSlot}
                  minTime={defaultStartOfDay.toDate()}
                  maxTime={defaultEndOfDay.toDate()}
                  views={[CalendarViewType.WEEK]}
                  style={{ height: 600, width: 800 }}
                  isSelectable={true}
                  onSelectEvent={handleSelectEvent}
                  noOverlapEvent="no-overlap"
                  onNavigate={onCalendarNavigate}
                />
              </CalendarSchedulerContainer>
            </>
          )}

          <Divider style={{ margin: '2em 0' }} />
          <MeetingPreferencesFlex>
            {!account.hasMeetingProvider ? (
              <>
                <TitleWithIcon
                  iconPath={SettingSlidersImage}
                  infotipChildren={
                    <div style={{ padding: '1.5rem' }}>
                      <img
                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                        src={CalendarPeopleImage}
                        alt="Calendar Image"
                      />
                      <p style={{ fontSize: '1.5rem' }}>{literals.calendar_sync_meeting_provider_infotip}</p>
                    </div>
                  }
                  imageAlt={'Setting Slider Image'}
                  title={literals.calendar_sync_meeting_provider_title}
                />
                <FormatedH3>{literals.calendar_user_profile_link_desc}</FormatedH3>
                <FormatedP>{literals.calendar_user_profile_create_link_desc}</FormatedP>
                <StyledCalendarPlatformOptionButton href={getUrlWithRedirectUri(currentPath, URLType.Zoom)}>
                  <StyledImage src={ZoomLogo} alt="Zoom logo" />
                </StyledCalendarPlatformOptionButton>
                <StyledCalendarPlatformOptionButton href={getUrlWithRedirectUri(currentPath, URLType.MSTeams)}>
                  <StyledImage src={MicrosoftTeamsLogo} alt="Microsoft Teams logo" />
                </StyledCalendarPlatformOptionButton>
              </>
            ) : (
              <>
                <TitleWithIcon
                  isSync={true}
                  iconPath={SettingSlidersImage}
                  imageAlt={'Setting Slider Image'}
                  title={literals.calendar_sync_meeting_provider_is_sync}
                />
                <SyncPlatformPanel
                  literals={literals}
                  providerSyncAccount={account.meetingProviderAccount}
                  logoUrl={account.meetingProviderType === MeetingProvider.Zoom ? ZoomLogo : MicrosoftTeamsLogo}
                  onUnsync={() => meetingProviderUnSync({ bearer })}
                />
              </>
            )}
          </MeetingPreferencesFlex>

          {!account.hasMeetingProvider && (
            <>
              <FormatedP>{literals.calendar_sync_fixed_meeting_link_desc}:</FormatedP>
              <FormatedInput
                onChange={handleChangeVideoCallLink}
                value={
                  userMeetingsSettings.userMeetingPreference?.videoCallLink
                    ? userMeetingsSettings.userMeetingPreference?.videoCallLink
                    : ''
                }
                style={{ width: '100%' }}
                type="url"
              />
            </>
          )}
          <Divider style={{ margin: '2em 0' }} />
          <SaveChangesPlacementFlex>
            <SaveChangesButton
              disabled={userMeetingsSettings === calendarSettings?.meetingSettings}
              onClick={() => handleSaveMeetingSettings()}
            >
              {literals.calendar_user_profile_save_changes}
            </SaveChangesButton>
          </SaveChangesPlacementFlex>
        </div>
      ) : null}
      {isSavingMeetingSettings === Flag.Request ? (
        <Dimmer inverted active>
          <Loader inverted>{literals.project_list_loading_tag_message}</Loader>
        </Dimmer>
      ) : null}
      <CalendarSyncModal
        onSaveChangesSetUserCalendar={setUserCalendar}
        onClose={() => setIsVisible(false)}
        bearer={bearer}
        isVisible={isVisible}
      />
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSettings);
