import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { MyStuffItem } from 'redux/types/account';
import { toast } from '../common/toast';
import ProfilePhoto from '../../components/common/lazyImage';
import defaultProfilePicture from '../../assets/common/profile-placeholder.svg';
import { fetchCountriesAsync, saveProfileAsync, fetchCurrentUserAsync } from '../../redux/actions/account';
import { resetRedirectUrl } from '../../services/api/account';
import { Flag } from '../../redux/types/enums';
import { Dropdown } from '../../components/common/dropdown';
import { StyledInput } from '../../routes/account/edit-profile';
import userAtDesk from '../../assets/common/user-at-desk.png';
import { useHistory } from 'react-router-dom';
import dashDotCircle from '../../assets/common/dash_dot_circle.png';
import blueEarth from '../../assets/common/blue_earth.png';
import Button from 'primitives/Button';
import { Dimmer, Loader } from 'semantic-ui-react';
import CloseIcon from '@material-ui/icons/Close';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const CloseButton = styled.button`
  background: transparent;
  border: 0;
  padding: 5px;
`;

const CloseButtonIcon = styled(CloseIcon)`
  &&& {
    width: 32px;
    height: 32px;
  }
`;

const SourceInput = styled.input`
  &&& {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

const Label = styled.span`
  font-weight: 600;
  font-size: 0.9em;
  margin: 1em 0 0.5em 0;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 1.2em;
  margin: 2em 0em 1em 0em;
`;

const FinalTitle = styled.span`
  font-weight: 600;
  font-size: 1.4em;
  margin: 0 0 1em 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue.normal};
`;

const CardImage = styled(ProfilePhoto)`
  &&& {
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    max-width: 5em;
    max-height: 5em;
    border-radius: 50%;
    margin-right: 1em;
    object-fit: cover;
  }
`;

const DashDotCircle = styled(ProfilePhoto)`
  &&& {
    position: absolute;
    height: 11em;
    width: 11em;
    transform: translate(-50%, 0);
    left: 50%;
    top: 0.5em;
  }
`;

const GreyText = styled.span`
  color: ${({ theme }) => theme.colors.grey.dark};
`;

const Card = styled(Row)`
  padding: 1em;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 5px;
  margin: 1em 0em;
  &:hover {
    cursor: pointer;
  }
`;

const ProfilePhotoContainer = styled.div<{ $isLandscape: boolean }>`
  height: 10em;
  width: 10em;
  margin-top: 1em;
  overflow: hidden;
  position: relative;
  & > label > img {
    max-width: unset !important;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: relative;
    margin: 0 !important;
    ${({ $isLandscape }) =>
      $isLandscape
        ? 'height: 100%!important; width: auto!important;'
        : 'width: 100%!important; height: auto!important;'}
  }
`;

const mapStateToProps = (state: RootState) => ({
  bearer: state.account.session.session.bearer,
  literals: state.literals,
  account: state.account.details.user,
  countries: state.countries,
  fetchCountriesFlag: state.loading.fetchCountriesFlag,
  saveProfileFlag: state.loading.saveProfileFlag,
});

const mapDispatchToProps = {
  fetchCountries: fetchCountriesAsync.request,
  saveProfile: saveProfileAsync.request,
  cancelSaveProfileFlag: saveProfileAsync.cancel,
  fetchCurrentUserData: fetchCurrentUserAsync.request,
};

type dispatchType = typeof mapDispatchToProps;

interface Props extends dispatchType, ReturnType<typeof mapStateToProps> {}

const CompleteProfileModal: React.FC<Props> = props => {
  const {
    literals,
    account,
    countries,
    fetchCountriesFlag,
    fetchCountries,
    bearer,
    saveProfile,
    saveProfileFlag,
    cancelSaveProfileFlag,
    fetchCurrentUserData,
  } = props;
  const history = useHistory();
  const [step, setStep] = useState<number>(1);
  const [user, setUser] = useState<any>({
    photo: account.photo || '',
    countryId: account.countryId || undefined,
    occupation: account.occupation || '',
    file: undefined,
    isLandscape: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applicationRedirectUrl, setApplicationRedirectUrl] = useState<string | null>(null);

  const TOTAL_STEPS = 4;
  const countriesOptions: { text: string; value: number; key: number }[] = (countries || []).map((c: any) => ({
    key: c.id,
    value: c.id,
    text: c.name,
  }));

  useEffect(() => {
    if (account.redirectAfterFirstLogin) {
      setApplicationRedirectUrl(account.redirectAfterFirstLogin);
    }
  }, [account]);

  useEffect(() => {
    if (bearer) fetchCurrentUserData(bearer);
  }, [bearer]);

  useEffect(() => {
    if (account.countryId > 0) {
      // history.push((history.location.state as any).backgroundModal || '/');
    } else {
      if (countries.length === 0 && fetchCountriesFlag !== Flag.Request) {
        fetchCountries({ bearer });
      }
    }
  }, []);

  const handleImageUpload = (event: any) => {
    if (!event || !event.target) return;
    event.preventDefault();

    if (event.target.files.length === 0) return;

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    if (file.size > 2097152) {
      toast(literals.user_settings_profile_picture_size_limit, { type: 'warning' });
      return;
    }

    reader.onload = (onLoadEvent: any) => {
      if (onLoadEvent && onLoadEvent.target && onLoadEvent.target.result) {
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          setUser((user: any) => ({
            ...user,
            file: file,
            photo: onLoadEvent.target.result,
            isLandscape: img.naturalWidth > img.naturalHeight,
          }));
        };
      }
    };
    reader.onerror = onErrorEvent => {
      if (onErrorEvent && onErrorEvent.target && onErrorEvent.target.error) {
        if (onErrorEvent.target.error.name === 'NotReadableError') {
          toast(literals.apply_to_team_project_service_on_project_applt_to_team_error, { type: 'error' });
        }
      }
    };
  };

  useEffect(() => {
    if (saveProfileFlag === Flag.Success) {
      cancelSaveProfileFlag(null);
      setStep(TOTAL_STEPS);
    }
  }, [saveProfileFlag]);

  const handleSaveProfile = () => {
    if (user.city === '') return;
    if (user.countryId === undefined) return;
    if (user.occupation === '') return;

    saveProfile({
      bearer,
      userId: account.id,
      language: account.language || 'en-EN',
      rawUser: account.rawUser,
      country: user.countryId,
      city: user.city,
      role: user.occupation,
      bio: account.profile?.biography || '',
      file: user.file,
      photo: user.photo,
      firstName: account.firstName,
      lastName: account.lastName,
      marketExpertises: account.profile?.marketExpertises || [],
      sustainableDevelopmentGoals: account.profile?.sustainableDevelopmentGoals || [],
      primaryCommunityId: account.primaryCommunityId,
      linkedInUrl: account.linkedInUrl || '',
      interests: account.profile?.interests.map((c: { id: number }) => c.id) || [],
      skills: account.profile?.skills.map((c: { id: number }) => c.id) || [],
      company: account.company,
    });
  };

  const communities = account.communities || [];

  const community =
    communities.find((community: MyStuffItem) => community.id === account.primaryCommunityId) ||
    (communities.length && communities[0]) ||
    undefined;
  const project = account.ownedProjects?.find((project: MyStuffItem) => project.circleName === 'Team');

  useEffect(() => {
    if (applicationRedirectUrl) {
      setIsLoading(true);
      resetRedirectUrl(bearer)
        .then(() => {
          fetchCurrentUserData(bearer);
          history.push(applicationRedirectUrl);
        })
        .catch(() => {
          setIsLoading(false);
          toast(literals.apply_to_team_project_service_on_project_applt_to_team_error, { type: 'error' });
        });
    } else {
      setIsLoading(false);
    }
  }, [applicationRedirectUrl]);

  if (isLoading) {
    return (
      <Dimmer
        style={{
          scale: '0.7',
          width: '100%',
        }}
        active
        inverted
      >
        <Loader />
      </Dimmer>
    );
  }

  return (
    <Container>
      {step < TOTAL_STEPS && (
        <Row style={{ justifyContent: 'space-between' }}>
          {step > 1 ? (
            <Button variant="outlined" onClick={() => setStep(step => step - 1)}>
              {literals.create_project_02_back_button}
            </Button>
          ) : (
            <span />
          )}
          <GreyText>{(literals.step_out_of || '').replace('{0}', step).replace('{1}', TOTAL_STEPS - 1)}</GreyText>
        </Row>
      )}
      {step === 1 && (
        <div>
          <Row style={{ justifyContent: 'center', position: 'relative' }}>
            <DashDotCircle src={dashDotCircle} />
            <ProfilePhotoContainer $isLandscape={user.isLandscape}>
              <ProfilePhoto
                src={user.photo}
                circular={true}
                as="label"
                htmlFor="complete-profile-photo"
                onError={(event: any) => (event.target.src = defaultProfilePicture)}
                style={{
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer',
                }}
              />
            </ProfilePhotoContainer>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <SourceInput type="file" id="complete-profile-photo" onChange={handleImageUpload} accept="image/*" />
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <Title>{literals.signup_process_upload_picture_title}</Title>
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <GreyText>{literals.signup_process_upload_picture_description}</GreyText>
          </Row>
          <Row style={{ justifyContent: 'center', marginTop: '2em' }}>
            <Button
              onClick={() => {
                setStep(step => step + 1);
              }}
            >
              {literals.signup_process_upload_location_button}
            </Button>
          </Row>
        </div>
      )}
      {step === 2 && (
        <div>
          <Row style={{ justifyContent: 'center' }}>
            <ProfilePhoto style={{ width: '10em', height: '10em' }} src={blueEarth} />
          </Row>
          <Row>
            <Label>{literals.create_project_06_location_country}</Label>
          </Row>
          <Row>
            <Dropdown
              placeholder={literals.global_dropdown_select_country}
              selection={true}
              clearable={false}
              type="user-country"
              options={countriesOptions}
              required
              onChange={(event: React.SyntheticEvent, data: any) => setUser({ ...user, countryId: data.value })}
              value={user.countryId}
              search={true}
            />
          </Row>
          <Row>
            <Label>{literals.create_project_06_location_city}</Label>
          </Row>
          <Row>
            <StyledInput
              maxLength={30}
              type="user-city"
              placeholder={literals.input_character_limit_under.replace('{0}', '30')}
              value={user.city}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, city: evt.target.value })}
              style={{ width: '100%' }}
            />
          </Row>
          <Row style={{ textAlign: 'center', margin: '1em 0em' }}>
            <GreyText>{literals.signup_process_location_description}</GreyText>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <Button disabled={!user.countryId || !user.city} onClick={() => setStep(step => step + 1)}>
              {literals.signup_process_occupation_button}
            </Button>
          </Row>
        </div>
      )}
      {step === 3 && (
        <div>
          <Row>
            <ProfilePhoto src={userAtDesk} style={{ maxWidth: '20em', width: '100%' }} />
          </Row>
          <Row>
            <StyledInput
              $centerPlaceholder={true}
              maxLength={30}
              type="user-role"
              placeholder={literals.signup_process_occupation_placeholder}
              value={user.occupation}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setUser({ ...user, occupation: evt.target.value })
              }
              style={{ width: '100%', margin: '1em 0em' }}
            />
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <GreyText>{literals.signup_process_occupation_description}</GreyText>
          </Row>
          <Row style={{ marginTop: '1em', justifyContent: 'center' }}>
            <Button disabled={saveProfileFlag === Flag.Request || !user.occupation} onClick={handleSaveProfile}>
              {literals.signup_process_final_button}
            </Button>
          </Row>
        </div>
      )}
      {step === 4 && (
        <div>
          <CloseButtonContainer>
            <CloseButton onClick={() => history.push('/account/mystuff')}>
              <CloseButtonIcon />
            </CloseButton>
          </CloseButtonContainer>
          <Row
            style={{
              justifyContent: community ? 'flex-start' : 'center',
            }}
          >
            <FinalTitle>
              {community
                ? (literals.signup_process_final_title || '').replace(
                    '{0}',
                    account.rawUser?.primaryCommunity?.name || '',
                  )
                : literals.signup_process_final_title_welcome}
            </FinalTitle>
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <GreyText>{literals.signup_process_final_description}</GreyText>
          </Row>
          <Card
            style={{ marginTop: '1.5em' }}
            onClick={() => history.push('/edit-profile', { editProfileStartStep: 2 })}
          >
            <CardImage src={user.photo || ''} />
            <Row style={{ flexDirection: 'column' }}>
              <Title style={{ margin: '0em 0em 0.4em 0em', lineHeight: '1em' }}>
                {literals.signup_process_card_profile_title}
              </Title>
              <GreyText style={{ lineHeight: '1em' }}>{literals.signup_process_card_profile_description}</GreyText>
            </Row>
          </Card>
          {community && (
            <Card onClick={() => history.push(`/community/${community.id}`)}>
              <CardImage src={community?.logo} />
              <Row style={{ flexDirection: 'column' }}>
                <Title style={{ margin: '0em 0em 0.4em 0em', lineHeight: '1em' }}>
                  {literals.signup_process_card_community_title}
                </Title>
                <GreyText style={{ lineHeight: '1em' }}>
                  {(literals.signup_process_card_community_description || '').replace('{0}', community.name)}
                </GreyText>
              </Row>
            </Card>
          )}
          {project && (
            <Card onClick={() => history.push(`/project/${project.id}`)}>
              <CardImage src={project.logo} />
              <Row style={{ flexDirection: 'column' }}>
                <Title style={{ margin: '0em 0em 0.4em 0em', lineHeight: '1em' }}>
                  {literals.signup_process_card_project_title}
                </Title>
                <GreyText style={{ lineHeight: '1em' }}>
                  {(literals.signup_process_card_project_description || '').replace('{0}', project.name)}
                </GreyText>
              </Row>
            </Card>
          )}
        </div>
      )}
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfileModal);
