import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { Notification } from 'redux/types/account';

import LoadingBar from '../common/loading-bar';
import NotificationRow from './NotificationRow';
import { map } from 'ramda';
import { ContinueReadingButton } from '../styled-components/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    font-size: 1.5em;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;

  span {
    color: ${({ theme }) => theme.colors.blue.normal};
    font-size: 0.8em;
    line-height: 1em;
    margin-top: -1em;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1em 0;
  justify-content: center;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  notifications: Notification[];
  isFetching: boolean;
  bearer: string;
  fetchMoreNotifications: () => void;
  markAsRead: any;
  myStuffEntities: {
    communities: { [id: number]: boolean };
    projects: { [id: number]: boolean };
    tabEntities?: { [id: number]: boolean };
  };
}

const NotificationFeed: React.FC<Props> = ({
  notifications,
  isFetching,
  fetchMoreNotifications,
  bearer,
  markAsRead,
  literals,
  myStuffEntities,
}) => {
  return (
    <Container>
      {isFetching && (
        <LoadingContainer>
          <LoadingBar />
          <span>{literals.notifications_fetching_last_notifications} 🚀</span>
        </LoadingContainer>
      )}
      {map(
        n => (
          <NotificationRow
            myStuffEntities={myStuffEntities}
            key={n.id}
            bearer={bearer}
            markAsRead={markAsRead}
            notification={n}
          />
        ),
        notifications,
      )}
      <Row>
        {isFetching ? (
          <div style={{ width: '2em' }}>
            <LoadingBar />
          </div>
        ) : (
          <ContinueReadingButton onClick={fetchMoreNotifications}>{literals.global_load_more}</ContinueReadingButton>
        )}
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps, {})(NotificationFeed);
