import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { Circle, EventAttendance, EventAttendee, Member, User } from 'redux/types/account';
import { UserEvent, UserRecurringAvailability } from '../redux/types/calendar';
import { CustomEvent } from 'components/common/calendar-scheduler/types';
import { MultipleMeetingSettings, UserWeeklyAvailabilityUser, YearMonth } from 'services/types/user-calendar';

export const convertArrayToObject = (array: any[], keyName: string, valueName: string) => {
  interface IObject {
    [key: string]: string;
  }

  const object: IObject = {};

  for (const item of array) {
    object[item[keyName]] = item[valueName];
  }

  return object;
};

export const generateEventAttendanceFromEntityCircles = (entity: {
  name?: string;
  members?: Member[];
  circles?: Circle[];
}): EventAttendance => {
  const attendance: EventAttendance = (entity.circles || []).reduce(
    (acc: EventAttendance, circle: Circle): EventAttendance => {
      return {
        ...acc,
        [circle.name]: {
          count: circle.userCount || 0,
          users: (circle.eventMembers || []).map(
            (member: Member): EventAttendee => ({
              id: member.id,
              name: member.name,
              email: member.email,
              photoUrl: member.photo,
            }),
          ),
        },
      };
    },
    {} as EventAttendance,
  );
  return attendance;
};

export const objectUserEventParser = (userEvents: UserEvent[]) => {
  if (userEvents.length === 0) return [];
  return userEvents.map(userEvent => ({
    title: userEvent.subject,
    start: new Date(userEvent.start),
    end: new Date(userEvent.end),
  }));
};

export const generateUserColors = (users: any, palette: string[]): Record<number, string> => {
  return users?.reduce((userColorMap: Record<number, string>, user: any, index: any) => {
    userColorMap[user?.id] = palette[index % palette.length];
    return userColorMap;
  }, {});
};

export const splitAvailabilitiesIntoChunks = (availabilities: CustomEvent[], duration: number) => {
  const result: CustomEvent[] = [];
  for (let i = 0; i < availabilities.length; i++) {
    const start = new Date(availabilities[i].start).getTime();
    const end = new Date(availabilities[i].end).getTime();

    if (!isNaN(start) && !isNaN(end)) {
      const availabilityDuration = (end - start) / (1000 * 60);

      if (availabilityDuration < duration) {
        continue;
      }

      let chunkStart = start;
      let chunkEnd = chunkStart + duration * 60000;

      while (chunkEnd <= end) {
        result.push({
          eventId: availabilities[i].eventId,
          userId: availabilities[i].userId,
          start: new Date(chunkStart),
          end: new Date(chunkEnd),
        });

        chunkStart = chunkEnd;
        chunkEnd = chunkStart + duration * 60 * 1000;
      }
    }
  }

  return result;
};

export const getUserAvailabilitiesEvents = (
  usersAvailability: MultipleMeetingSettings[],
): UserWeeklyAvailabilityUser[] => {
  let weeklyEvents: UserWeeklyAvailabilityUser[] = [];
  usersAvailability?.forEach((user: MultipleMeetingSettings) => {
    if (!user?.userRecurringAvailabilities) {
      return [];
    }

    if (user?.userWeeklyAvailabilities && user.userMeetingPreference?.bookingType === 0) {
      const weeklyUserAvailabilities: UserWeeklyAvailabilityUser[] = user?.userWeeklyAvailabilities.flatMap(
        availability => {
          return {
            ...availability,
            userId: user?.userId,
            timeZone: user?.userMeetingPreference?.timeZone,
          };
        },
      );

      weeklyEvents = weeklyEvents.concat(weeklyUserAvailabilities);
    }
  });
  return weeklyEvents;
};

export const getUserFixedAvailabilitiesEvents = (usersAvailability: MultipleMeetingSettings[]) => {
  if (!usersAvailability) return [];

  const fixedAvailabilities = usersAvailability
    .filter(user => user.userMeetingPreference?.bookingType === 1)
    .flatMap(user => {
      return user.userRecurringAvailabilities?.map(availability => ({
        start: new Date(availability.startDateTime),
        end: new Date(availability.endDateTime),
        userId: user?.userId,
        eventId: Math.random(),
      }));
    });

  return (fixedAvailabilities as unknown) as CustomEvent[];
};

export const durationInMs = (minutesDuration: number) => {
  return minutesDuration * 60 * 1000;
};

export const formattedDate = (selectedDate: string | number | Date) => {
  const date = new Date(selectedDate);

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12; // Convert to 12-hour format

  const dayWithSuffix =
    day +
    (day % 10 === 1 && day !== 11
      ? 'st'
      : day % 10 === 2 && day !== 12
      ? 'nd'
      : day % 10 === 3 && day !== 13
      ? 'rd'
      : 'th');

  return `${dayOfWeek}, ${month} ${dayWithSuffix} at ${hour12}:${minute.toString().padStart(2, '0')} ${period} CET`;
};

export function changeSecondsToZero(timeString: string) {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}:00`;
}

export const getUrlWithRedirectUri = (
  redirectUri: string,
  urlType: 'zoom' | 'msteams' | 'google-calendar' | 'outlook',
): string => {
  let url = '';

  const fullRedirectUrl =
    process.env.REACT_APP_DEPLOYMENT_TYPE === 'production' && !redirectUri.includes('app.babele.co')
      ? getCalendarRedirectUri()
      : getAccountCalendarUri();

  switch (urlType) {
    case URLType.GoogleCalendar:
      url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar.readonly openid&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${fullRedirectUrl}&client_id=${process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID}&prompt=consent`;
      break;
    case URLType.Zoom:
      url = `https://zoom.us/oauth/authorize?response_type=code&client_id=FAthNcR3TbqoLEWf3f6IQ&redirect_uri=${fullRedirectUrl}/provider-zoom`;
      break;
    case URLType.MSTeams:
      url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_TEAMS_CALENDAR_CLIENT_ID}&response_type=code&redirect_uri=${fullRedirectUrl}/provider-msteams&scope=onlinemeetings.readwrite offline_access user.read&response_mode=query`;
      break;
    case URLType.Outlook:
      url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MSFT_CALENDAR_CLIENT_ID}&response_type=code&redirect_uri=${fullRedirectUrl}&scope=calendars.read offline_access user.read&response_mode=query`;
      break;
    default:
      url = '';
  }

  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'production' && !redirectUri.includes('app.babele.co')) {
    url += `&state=${encodeURIComponent(window.location.origin)}/account/settings`;
  }

  return url;
};

export const getCommunityUrlWithRedirectUri = (
  redirectUri: string,
  urlType: 'zoom' | 'msteams' | 'google-calendar' | 'outlook',
): string => {
  let url = '';
  switch (urlType) {
    case URLType.GoogleCalendar:
      url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events openid&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${redirectUri}&client_id=${process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID}&prompt=consent`;
      break;
    case URLType.Zoom:
      url = `https://zoom.us/oauth/authorize?response_type=code&client_id=FAthNcR3TbqoLEWf3f6IQ&redirect_uri=${redirectUri}/provider-zoom`;
      break;
    case URLType.MSTeams:
      url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_TEAMS_CALENDAR_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}/provider-msteams&scope=onlinemeetings.readwrite offline_access user.read&response_mode=query`;
      break;
    case URLType.Outlook:
      url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MSFT_CALENDAR_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&scope=calendars.readwrite mailboxsettings.read offline_access user.read&response_mode=query`;
      break;
    default:
      url = '';
  }

  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'production') {
    url += `&state=${encodeURIComponent(window.location.href)}`;
  }

  return url;
};

export function getAccountCalendarUri(): string {
  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'production') return 'https://app.babele.co/account/settings';
  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'staging') return 'https://app-stag.babele.co/account/settings';
  return 'http://localhost:3000/account/settings';
}

export function getCalendarRedirectUri(): string {
  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'production') return 'https://app.babele.co/calendar-redirect';
  if (process.env.REACT_APP_DEPLOYMENT_TYPE === 'staging') return 'https://app-stag.babele.co/calendar-redirect';
  return 'http://localhost:3000/calendar-redirect';
}

export function removeUrlQuery(url: string): string {
  const urlObject = new URL(url);
  urlObject.search = '';
  return urlObject.toString();
}

export enum URLType {
  Zoom = 'zoom',
  MSTeams = 'msteams',
  GoogleCalendar = 'google-calendar',
  Outlook = 'outlook',
}

export function getWeekStartDate(date: Date): Date {
  const currentDate = new Date(date);

  const currentDay = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

  // Calculate the start of the week (Sunday)
  currentDate.setDate(currentDate.getDate() - currentDay);
  currentDate.setHours(0, 0, 0, 0); // Set the time to midnight

  const weekStartDate = new Date(currentDate);
  return weekStartDate;
}

type DayWithEvents = {
  day: number;
  events: CustomEvent[];
};

type AvailabilityRange = {
  day: number;
  start: Date;
  end: Date;
};

type AvailabilityRangeForUser = {
  day: number;
  start: Date;
  end: Date;
  userId: number;
};

export function getMondayStartTime(date: Date) {
  const now = new Date(date);
  const currentDay = now.getDay(); // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

  // Calculate the time difference between the current day and Monday (assuming Monday is the start of the week)
  let daysToMonday = currentDay - 1; // If today is Monday, this will be 0; otherwise, it will be a negative value

  if (daysToMonday < 0) {
    // If today is Sunday, set daysToMonday to 6 to get the previous Monday
    daysToMonday = 6;
  }

  // Calculate the time difference in milliseconds
  const timeDifference = now.getTime() - daysToMonday * 24 * 60 * 60 * 1000;

  // Create a new Date object with the calculated time
  const mondayStart = new Date(timeDifference);

  // Set the time to 00:00:00 for the start of the day
  mondayStart.setHours(0, 0, 0, 0);

  return mondayStart;
}

export function getMondayStartTimeLocalized(date: Date, timezone: string) {
  if (date) {
    let safeTimezone: string;
    if (timezone && Boolean(momentTimezone.tz.zone(timezone as string))) {
      safeTimezone = timezone;
    } else {
      safeTimezone = momentTimezone.tz.guess();
    }
    const mDate = moment(date).tz(safeTimezone);
    // Get the start of the current week (Sunday)
    const startOfWeek = mDate.startOf('isoWeek');
    // Set the day to Monday
    const mondayOfCurrentWeek = startOfWeek.day(1);

    return mondayOfCurrentWeek;
  }

  const now = new Date();
  const mDate = moment(now).tz(momentTimezone.tz.guess());
  const startOfWeek = mDate.startOf('week');

  const mondayOfCurrentWeek = startOfWeek.day(1);
  return mondayOfCurrentWeek;
}

const addDaysToDate = (startDate: Date, days: number) => {
  const result = new Date(startDate.getTime());
  result.setDate(result.getDate() + days);
  return result;
};

const getEndOfTheWeek = (weekStartdate: Date) => {
  return addDaysToDate(weekStartdate, 7);
};

const availabilityReducer = (event: CustomEvent) => (
  isAvailableAcc: boolean,
  availability: CustomEvent | AvailabilityRangeForUser,
) => {
  if (event.start >= availability.start && event.end <= availability.end) return isAvailableAcc && true;
  return false;
};

// First create an entire week of events, from 07:00 to 22:00
// Then cross-reference that with the user availabilities and user meetings
// If an event matches all the user availabilities and doesn't conflict with , mark it as available
// Because we take into account the user's timezone, but the calendar library works with native
// Date objects, we first create moment dates with the user timezones and then we convert them
// to native Date. Then we set the moment locale on the calendar library
export function createWeeklyEventsWithAvailabilityLocalized(
  currentDate: Date,
  duration: number,
  userWeeklyAvailabilities: UserWeeklyAvailabilityUser[],
  userFixedAvailabilities: CustomEvent[],
  userScheduledEvents: CustomEvent[],
  selectedUserIds: number[],
  userTimeZone: string,
  eventTitleLocalized: string,
  currentUser: User,
): CustomEvent[] {
  // Initialize the resulting array
  const allEvents: CustomEvent[] = [];
  const startTime = '07:00:00';
  const endTime = '22:00:00';
  const allWeekEvents: DayWithEvents[] = [];
  const availabilityRanges: AvailabilityRangeForUser[] = [];
  const weekStartDate = getMondayStartTimeLocalized(currentDate, userTimeZone).toDate();
  const weekEndDate = getEndOfTheWeek(weekStartDate);
  const now = new Date();

  // Filter out timeslots and meetings that aren't in this week
  const filteredFixedAvailabilities = userFixedAvailabilities.filter(({ start, end }) => {
    return start >= weekStartDate && end < weekEndDate;
  });
  const filteredScheduledEvents = userScheduledEvents.filter(({ start, end }) => {
    return start >= weekStartDate && end < weekEndDate;
  });

  // Generate the entire week of events with the set interval/duration
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    const dayWithEvents: DayWithEvents = {
      day: dayIndex,
      events: [],
    };

    const dayMilliseconds = dayIndex * 24 * 60 * 60 * 1000;

    // We need this as .toISOString converts to UTC before outputing the string,
    // so we need to substract the timezone offset before calling toISOString.
    // Note: getTimezoneOffset returns the offset in minutes, we have to convert to ms
    const timezoneOffsetInMs = getMondayStartTimeLocalized(currentDate, userTimeZone).utcOffset() * 60 * 1000;

    const date = new Date(weekStartDate.getTime() + dayMilliseconds + timezoneOffsetInMs + 1000)
      .toISOString()
      .split('T')[0];

    const start = moment(`${date}T${startTime}`).tz(userTimeZone).toDate();
    const end = moment(`${date}T${endTime}`).tz(userTimeZone).toDate();

    const allDayEvent = {
      eventId: Math.random(),
      start,
      end,
    };

    const splitEvents = splitAvailabilitiesIntoChunks([allDayEvent], duration).map(event => ({
      ...event,
      available: false,
    }));

    dayWithEvents.events = splitEvents;
    allWeekEvents.push(dayWithEvents);
  }

  // No user availability, so return the entire week of events
  // without any marked as available
  if (!userWeeklyAvailabilities?.length && !filteredFixedAvailabilities?.length) {
    const events: CustomEvent[] = allWeekEvents.reduce((allEv: CustomEvent[], dailyEv: DayWithEvents) => {
      return allEv.concat(dailyEv.events);
    }, []);

    return events;
  }

  // Get the daily availabilities for all the users
  for (const availability of userWeeklyAvailabilities) {
    const { startTime, endTime, day, timeZone, userId } = availability;
    let safeTimeZone: string;

    if (timeZone && Boolean(momentTimezone.tz.zone(timeZone as string))) {
      safeTimeZone = timeZone;
    } else {
      safeTimeZone = momentTimezone.tz.guess();
    }

    const userWeekStartDay = getMondayStartTimeLocalized(currentDate, safeTimeZone);

    // Calculate the date based on the provided week start date and the day
    // Since we're using localization with Monday as the first day, then Monday needs to be
    // the 0 day for the math to work out correctly and Sunday needs to be 6 as the last day
    // of the week
    const dayIndex = day === 0 ? 6 : day - 1;
    const dayMilliseconds = dayIndex * 24 * 60 * 60 * 1000;
    // We need this as .toISOString converts to UTC before outputing the string,
    // so we need to substract the timezone offset before calling toISOString.
    const timezoneOffsetInMs = userWeekStartDay.utcOffset() * 60 * 1000;

    const date = new Date(userWeekStartDay.toDate().getTime() + dayMilliseconds + timezoneOffsetInMs + 1000)
      .toISOString()
      .split('T')[0];
    // Use the provided startTime and endTime directly and format them to "yyyy-MM-ddTHH:mm:ssZ" format
    const start = momentTimezone.tz(`${date} ${startTime}`, safeTimeZone).toDate();
    const end = momentTimezone.tz(`${date} ${endTime}`, safeTimeZone).toDate();

    availabilityRanges.push({
      start,
      end,
      day: dayIndex,
      userId: availability.userId,
    });
  }

  for (let day = 0; day < 7; day++) {
    const dayStart = addDaysToDate(weekStartDate, day);
    const dayEnd = addDaysToDate(weekStartDate, day + 1);
    const availabilitiesForTheDay = availabilityRanges.filter(availability => availability.day === day);
    const fixedAvailabilitiesForTheDay = filteredFixedAvailabilities.filter(({ start, end }) => {
      // Check if the fixed slot availability overlaps the current day
      return (start >= dayStart && start < dayEnd) || (end >= dayStart && start < dayEnd);
    });
    const scheduledEventsForTheDay = filteredScheduledEvents.filter(({ start, end }) => {
      // Calendar events spanning multiple days
      const containsDay = start <= dayStart && end >= dayEnd;
      const overlapsDay = (start < dayStart && end > dayStart) || (start < dayEnd && end > dayEnd);
      // Calendar events between the current day 00:00 and 23:59
      const isInThisDay = start >= dayStart && end < dayEnd;

      return containsDay || overlapsDay || isInThisDay;
    });
    const eventsForTheDay = allWeekEvents.find(event => event.day === day);
    let usersAvailableForTheDay: number[] = [];

    availabilitiesForTheDay.forEach(({ userId }) => {
      usersAvailableForTheDay.push(userId);
    });

    fixedAvailabilitiesForTheDay.forEach(({ userId }) => {
      if (userId) usersAvailableForTheDay.push(userId);
    });

    usersAvailableForTheDay = Array.from(new Set(usersAvailableForTheDay));

    // There's at least one user who isn't available on this day, so there's no need to do
    // any more calculations for this day
    if (usersAvailableForTheDay.length < selectedUserIds.length) {
      continue;
    }

    if (eventsForTheDay?.events?.length) {
      // For every event from this day, check if it's within the time intervals for all the
      // availabilities and doesn't conflict with any scheduled meeting
      eventsForTheDay.events.forEach(event => {
        // Events in the past shouldn't be shown as available
        if (event.start < now) return;

        const currentEventAvailabilityReducer = availabilityReducer(event);

        // Check if the event is within all the recurring availabilities for the day
        const matchedDailyAvailabilities = availabilitiesForTheDay.length
          ? availabilitiesForTheDay.reduce(currentEventAvailabilityReducer, true)
          : true;

        const matchedDailyAvailabilitiesForRest = availabilitiesForTheDay.length
          ? availabilitiesForTheDay
              .filter(availability => availability.userId !== currentUser.id)
              .reduce(currentEventAvailabilityReducer, true)
          : true;

        // Check if the event is within at least one fixed slot availability for each user
        // Users can have multiple fixed availabilities for the day. If we find a single user
        // that has slots for the day, but none of the slots contain the current calendar slot
        // then the current calendar slot isn't available for everyone
        let matchesFixedAvailabilities = true;

        usersAvailableForTheDay.forEach(userId => {
          const availabilitiesPerUser = fixedAvailabilitiesForTheDay.filter(
            fixedAvailability => fixedAvailability.userId === userId,
          );

          // This user has no fixed availabilities for today, so we don't need to check
          if (!availabilitiesPerUser.length) return;

          // Find one matching availability for this user and this calendar slot
          const matchedAvailability = availabilitiesPerUser.find(availability => {
            return event.start >= availability.start && event.end <= availability.end;
          });

          if (!matchedAvailability) matchesFixedAvailabilities = false;
        });

        let matchesFixedAvailabilityForRest = true;
        usersAvailableForTheDay
          .filter(userId => userId !== currentUser.id)
          .forEach(userId => {
            const availabilitiesPerUser = fixedAvailabilitiesForTheDay.filter(
              fixedAvailability => fixedAvailability.userId === userId,
            );

            // This user has no fixed availabilities for today, so we don't need to check
            if (!availabilitiesPerUser.length) return;

            const matchedAvailabilityForRest = availabilitiesPerUser.find(availability => {
              return event.start >= availability.start && event.end <= availability.end;
            });

            if (!matchedAvailabilityForRest) {
              matchesFixedAvailabilityForRest = false;
            }
          });

        // Check if the event conflicts with any scheduled meetings the users have on this day
        const noConflictsWithScheduledMeetings = scheduledEventsForTheDay.reduce((conflictsAcc, meeting) => {
          let noConflicts = true;

          if (meeting.start >= event.start && meeting.start < event.end) {
            noConflicts = false;
          }
          if (meeting.start <= event.start && meeting.end > event.start) {
            noConflicts = false;
          }
          return noConflicts && conflictsAcc;
        }, true);

        const eventAvailable =
          matchedDailyAvailabilities && matchesFixedAvailabilities && noConflictsWithScheduledMeetings;
        event.available = eventAvailable;
        if (eventAvailable) {
          event.title = eventTitleLocalized;
        }

        const eventAvailableOutside =
          matchesFixedAvailabilityForRest && matchedDailyAvailabilitiesForRest && noConflictsWithScheduledMeetings;
        event.availableOutside = eventAvailableOutside;
        if (eventAvailableOutside) {
          event.title = eventTitleLocalized;
        }
      });
    }
  }

  allWeekEvents.forEach(dayEvent => {
    allEvents.push(...dayEvent.events);
  });

  return allEvents;
}

export function createWeeklyEvents(
  weekStartDate: Date,
  duration: number,
  userWeeklyAvailabilities?: UserWeeklyAvailabilityUser[],
): CustomEvent[] {
  // Define a mapping for Day enum to its corresponding string values
  if (!userWeeklyAvailabilities) return [];
  // Initialize the resulting array
  const convertedData: CustomEvent[] = [];

  // Loop through the original data
  for (const availability of userWeeklyAvailabilities) {
    const { startTime, endTime, day } = availability;

    // Calculate the date based on the provided week start date and the day
    const dayIndex = day as number; // Convert enum to number
    const dayMilliseconds = dayIndex * 24 * 60 * 60 * 1000;
    // We need this as .toISOString converts to UTC before outputing the string,
    // so we need to substract the timezone offset before calling toISOString.
    // Note: getTimezoneOffset returns the offset in minutes, we have to convert to ms
    let timezoneOffsetInMs = weekStartDate.getTimezoneOffset() * 60 * 1000;

    // The getTimeOffset() doesn't seem to account for daylight saving time, so in the summer
    // the offset seems to be off by an hour, so we need to adjust
    if (moment().isDST()) {
      timezoneOffsetInMs -= 60 * 60 * 1000;
    }

    const date = new Date(weekStartDate.getTime() + dayMilliseconds - timezoneOffsetInMs + 1000)
      .toISOString()
      .split('T')[0];

    // Use the provided startTime and endTime directly and format them to "yyyy-MM-ddTHH:mm:ssZ" format
    const start = new Date(`${date}T${startTime}`);
    const end = new Date(`${date}T${endTime}`);
    // Add the converted item to the resulting array
    convertedData.push({
      eventId: Math.random(),
      userId: availability.userId,
      start,
      end,
    });
  }
  if (duration) {
    return splitAvailabilitiesIntoChunks(convertedData, duration);
  } else {
    return convertedData;
  }
}

export const getMonthsForCurrentWeek = (date: Date): YearMonth[] => {
  // Get the day of the week as integer
  const currentDay = date.getDay();

  // Calculate the start date of the current week (Sunday)
  const startDate = new Date(date);
  startDate.setDate(date.getDate() - currentDay);

  // Calculate the end date of the current week (Saturday)
  const endDate = new Date(date);
  endDate.setDate(date.getDate() + (6 - currentDay));

  // Get the month for the start and end dates. Months are zero based, but
  // in our API they aren't, so adding 1
  const startMonth = {
    year: startDate.getFullYear(),
    month: startDate.getMonth() + 1,
  };

  const endMonth = {
    year: endDate.getFullYear(),
    month: endDate.getMonth() + 1,
  };

  // Handle the case where the week spans two different months
  if (startMonth.year !== endMonth.year || startMonth.month !== endMonth.month) {
    return [startMonth, endMonth];
  } else {
    return [startMonth];
  }
};

export const getSurroundingMonths = (date: Date): YearMonth[] => {
  // In our API, months aren't 0 based
  const currentMonth = date.getMonth() + 1;
  const currentMonthYear = date.getFullYear();

  let previousMonth: number;
  let previousMonthYear: number;
  let nextMonth: number;
  let nextMonthYear: number;

  // If the current month is December, the next one is January next year
  if (currentMonth === 12) {
    nextMonth = 1;
    nextMonthYear = currentMonthYear + 1;
  } else {
    nextMonth = currentMonth + 1;
    nextMonthYear = currentMonthYear;
  }

  // If the current month is January, the previous one is December previous year
  if (currentMonth === 1) {
    previousMonth = 12;
    previousMonthYear = currentMonthYear - 1;
  } else {
    previousMonth = currentMonth - 1;
    previousMonthYear = currentMonthYear;
  }

  return [
    {
      month: previousMonth,
      year: previousMonthYear,
    },
    {
      month: currentMonth,
      year: currentMonthYear,
    },
    {
      month: nextMonth,
      year: nextMonthYear,
    },
  ];
};

export function convertTimeToUTC(time: string, timezone: string): string {
  if (!isValidTimeFormat(time)) return time;

  const originalMoment = momentTimezone.tz(time, 'HH:mm', timezone);
  const utcMoment = originalMoment.clone().utc();
  const utcTimeString = utcMoment.format('HH:mm');

  return utcTimeString;
}

export function convertUTCToTime(utcTime: string, targetTimezone: string): string {
  if (!isValidTimeFormat(utcTime)) return utcTime;

  const utcMoment = momentTimezone.utc(utcTime, 'HH:mm');
  const targetMoment = utcMoment.clone().tz(targetTimezone);
  const targetTimeString = targetMoment.format('HH:mm');

  return targetTimeString;
}

// Check if a string is in the 'HH:mm' or 'HH:mm:ss' time format
export function isValidTimeFormat(timeString: string): boolean {
  const minutesRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  const secondsRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

  return minutesRegex.test(timeString) || secondsRegex.test(timeString);
}

export enum Duration {
  DURATION_30 = 30,
  DURATION_45 = 45,
  DURATION_60 = 60,
}

export const timesToMeetingString = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  timezone: string,
) => {
  const startDatetime = moment(`${startDate}:${startTime}`, 'YYYY-MM-DD:HH:mm').tz(timezone);
  const endDatetime = moment(`${endDate}:${endTime}`, 'YYYY-MM-DD:HH:mm').tz(timezone);

  const formattedStart = startDatetime.format('DD/MM/YYYY HH:mm');
  const formattedEnd = endDatetime.format('DD/MM/YYYY HH:mm');
  return `${formattedStart} to ${formattedEnd}`;
};

export const timesToMeetingObject = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  timezone: string,
) => {
  const startDatetime = moment(`${startDate}:${startTime}`, 'YYYY-MM-DD:HH:mm').tz(timezone);
  const endDatetime = moment(`${endDate}:${endTime}`, 'YYYY-MM-DD:HH:mm').tz(timezone);

  const formattedStartDate = startDatetime.format('YYYY-MM-DD');
  const formattedStartTime = startDatetime.format('HH:mm');
  const formattedEndDate = endDatetime.format('YYYY-MM-DD');
  const formattedEndTime = endDatetime.format('HH:mm');

  return {
    startDate: formattedStartDate,
    startTime: formattedStartTime,
    endDate: formattedEndDate,
    endTime: formattedEndTime,
  };
};

export const mergeConsecutiveSlots = (slots: UserRecurringAvailability[] | null): UserRecurringAvailability[] => {
  if (!slots?.length) {
    return [];
  }

  slots.sort((a: UserRecurringAvailability, b: UserRecurringAvailability) => {
    return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime();
  });

  return slots.reduce((mergedSlots: UserRecurringAvailability[], slot: UserRecurringAvailability) => {
    if (mergedSlots.length > 0) {
      const lastSlot = mergedSlots[mergedSlots.length - 1];

      if (new Date(lastSlot.endDateTime).getTime() === new Date(slot.startDateTime).getTime()) {
        lastSlot.endDateTime = slot.endDateTime;
        return mergedSlots;
      }
    }

    return [...mergedSlots, slot];
  }, []);
};
