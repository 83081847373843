import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import { Popover, List, ListItem, Checkbox, ListItemText } from '@material-ui/core';
import { Member, User } from 'redux/types/account';
import { updateSelectedUsers } from 'redux/actions/user-calendar';

const mapStateToProps = (state: RootState) => ({
  selectedUsers: state.userCalendar.selectedUsers,
});

const mapDispatchToProps = {
  updateSelectedUsers: updateSelectedUsers,
};

type dispatchType = typeof mapDispatchToProps;

interface TagsDropDownProps extends ReturnType<typeof mapStateToProps>, dispatchType {
  tags: string[] | undefined;
  members: Member[] | undefined;
  currentUser: User;
  onSelect: any;
  dropdownTitle: string;
}

const TagsDropDown = ({
  tags,
  members,
  selectedUsers,
  updateSelectedUsers,
  currentUser,
  onSelect,
  dropdownTitle,
}: TagsDropDownProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [memberChecked, setMemberChecked] = useState<{ [key: string]: boolean }>({});
  const [tagChecked, setTagChecked] = useState<{ [key: string]: boolean }>({});

  const tagMembers = React.useMemo(() => {
    return selectedTag.length > 0 ? members?.filter(member => member.userTags.includes(selectedTag[0])) : [];
  }, [selectedTag, members]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHoverTag = (tag: string) => {
    setSelectedTag([tag]);
  };

  useEffect(() => {
    tags?.forEach((tag: string) => {
      const selectedTagMembers =
        members?.filter((member: Member) => {
          return member.userTags.includes(tag);
        }) || [];
      const allMembersChecked =
        selectedTagMembers.length > 0 && selectedTagMembers.every((member: Member) => memberChecked[member.id]);

      setTagChecked(prevTagChecked => ({
        ...prevTagChecked,
        [tag]: allMembersChecked,
      }));
    });
  }, [memberChecked, tags, members]);

  useEffect(() => {
    const initialMemberChecked: { [key: string]: boolean } = {};
    selectedUsers?.forEach((user: Member) => {
      initialMemberChecked[user.id] = true;
    });
    setMemberChecked(initialMemberChecked);
  }, [selectedUsers]);

  const handleCheckTag = useCallback(
    (tag: string) => {
      setTagChecked(prevTagChecked => {
        const updatedTagChecked = { ...prevTagChecked, [tag]: !prevTagChecked[tag] };
        const selectedTagMembers = members?.filter((member: Member) => member.userTags.includes(tag)) || [];

        const updatedMemberChecked = { ...memberChecked };
        selectedTagMembers.forEach((member: Member) => {
          updatedMemberChecked[member.id] = updatedTagChecked[tag];
        });
        setMemberChecked(updatedMemberChecked);

        let updatedSelectedUsers = [...(selectedUsers ?? [])];

        if (updatedTagChecked[tag]) {
          selectedTagMembers.forEach((member: Member) => {
            if (!updatedSelectedUsers.some(existingMember => existingMember.id === member.id)) {
              updatedSelectedUsers.push({ ...member });
            }
          });
        } else {
          updatedSelectedUsers = updatedSelectedUsers.filter(
            member => !selectedTagMembers.some(tagMember => tagMember.id === member.id),
          );
        }

        if (updatedSelectedUsers.length === 1 && updatedSelectedUsers[0].id === currentUser?.id) {
          updateSelectedUsers({ selectedUsers: [] });
        } else {
          updatedSelectedUsers = [currentUser, ...updatedSelectedUsers.filter(member => member.id !== currentUser.id)];
          updateSelectedUsers({ selectedUsers: updatedSelectedUsers });
          onSelect(updatedSelectedUsers);
        }

        return updatedTagChecked;
      });
    },
    [members, memberChecked, selectedUsers, currentUser, updateSelectedUsers, onSelect],
  );

  const handleCheckMember = useCallback(
    (member: Member) => {
      setMemberChecked(prevMemberChecked => ({
        ...prevMemberChecked,
        [member.id]: !prevMemberChecked[member.id],
      }));

      const memberAlreadySelected = selectedUsers?.find((selectedMember: Member) => selectedMember.id === member.id);

      if (memberAlreadySelected) {
        const updatedSelectedUsers = selectedUsers?.filter((selectedUser: Member) => selectedUser.id !== member.id);
        const allMembersChecked = !!(
          tagMembers &&
          tagMembers.length > 0 &&
          tagMembers.every((tagMember: Member) => memberChecked[tagMember.id])
        );

        setTagChecked(prevTagChecked => ({
          ...prevTagChecked,
          [member.userTags[0]]: allMembersChecked,
        }));

        if (selectedUsers.length === 2) {
          updateSelectedUsers({ selectedUsers: [] });
        } else {
          updateSelectedUsers({ selectedUsers: updatedSelectedUsers });
          onSelect(updatedSelectedUsers);
        }
      } else {
        const selectedUserWithoutCurrent = selectedUsers?.filter(
          (selectedUser: Member) => selectedUser.id !== currentUser.id,
        );
        const updatedMembers = [currentUser, ...(selectedUserWithoutCurrent ?? []), { ...member }];

        setTagChecked(prevTagChecked => ({
          ...prevTagChecked,
          [member.userTags[0]]: true,
        }));

        updateSelectedUsers({ selectedUsers: updatedMembers });
        onSelect(updatedMembers);
      }
    },
    [selectedUsers, memberChecked, currentUser, tagMembers, updateSelectedUsers, onSelect],
  );

  return (
    <Wrapper>
      <ButtonWrapper onClick={handleClick}>
        <ButtonContent>{dropdownTitle}</ButtonContent>
        <ReversibleArrow $dropdownOpen={Boolean(anchorEl)} />
      </ButtonWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DropdownContent>
          <LeftColumn>
            <List>
              {tags?.map((tag: string) => (
                <ListItem
                  key={`tag-${tag}`}
                  button
                  onClick={() => handleHoverTag(tag)}
                  onMouseEnter={() => handleHoverTag(tag)}
                >
                  <Checkbox checked={tagChecked[tag] || false} onClick={() => handleCheckTag(tag)} color="primary" />
                  <ListItemText primary={tag} />
                </ListItem>
              ))}
            </List>
          </LeftColumn>
          <RightColumn>
            <List>
              {tagMembers?.map((member: Member) => (
                <ListItem key={member.id}>
                  <Checkbox
                    checked={memberChecked[member.id] || false}
                    onChange={() => handleCheckMember(member)}
                    color="primary"
                  />
                  <StyledListItemText primary={member.name} />
                </ListItem>
              ))}
            </List>
          </RightColumn>
        </DropdownContent>
      </Popover>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 2.3em;
  height: 36px;
  border-radius: 10em;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.normal};
  border: solid 2px ${({ theme }) => theme.colors.blue.normal};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

const ReversibleArrow = styled(ArrowIcon)<{ $dropdownOpen: boolean }>`
  ${props => (props.$dropdownOpen ? 'transform: rotateX(180deg)' : '')}
`;

const ButtonContent = styled.div`
  text-transform: uppercase;
`;

const DropdownContent = styled.div`
  display: flex;
`;

const LeftColumn = styled.div`
  flex-grow: 0;
  width: 180px;
  padding: 0px;
  overflow-y: auto;
  max-height: 280px;
`;

const RightColumn = styled.div`
  flex-grow: 0;
  border-left: solid thin;
  overflow-y: auto;
  max-height: 280px;
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default connect(mapStateToProps, mapDispatchToProps)(TagsDropDown);
