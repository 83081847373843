// Object containing all the literals used on the landing page

export const landingPageLiterals = {
  discussionOneFollower: '{0} started following',
  discussionTwoFollowers: '{0} and {1} started following',
  discussionManyFollowers: '{0} and {1} others started following',
  commentsOneCommented: '{0} Commented',
  commentsTwoCommented: '{0} and {1} Other User Commented',
  commentsManyCommented: '{0} and {1} Other Users Commented',
  buttonTextFollow: 'follow',
  tabSearch: 'Search',
  is_any_of: 'Is any of',
  is_none_of: 'Is none of',
  clear_all_filters: 'Clear all filters',
  advanced_search_label: 'Advanced Filter',
  values: 'Values',
  landingWelcome: 'Welcome',
  landingNavigateTo: 'Navigate to',
  buttonTextExpand: 'Click here to expand',
  buttonTextShowLess: 'Click here to show less',
  buttonTextSignIn: 'Sign in to Post',
  buttonTextLogin: 'Login',
  buttonTextViewAll: 'View All',
  buttonTextCollapse: 'Show Less',
  landingDiscussionsTitle: 'Featured Conversations',
  landingRequestsTitle: 'Featured Requests',
  landingEventsTitle: 'Upcoming Events',
  landingNoEvents: 'No upcoming events',
  eventStartEndTime: 'Time {0} to {1}',
  landingMembers: 'Members',
  landingCompanies: 'Companies',
  landingNoMembers: 'No members',
  landingNoCompanies: 'No companies',
  landingCommunityTitle: 'Join the Community',
  landingCommunityBodyMessage: 'Join discussions, share your expertise, and connect with like-minded individuals',
  landingCommunityFooterMessage: 'Alternatively you can click {0} to create your account',
  here: 'here',
  tooltipLogin: 'Login to see more',
  userInfoAuthor: 'Author',
  userInfoPrivacy: 'Public',
  landingEntityDescription: 'Description',
  loading: 'Loading',
} as const;
