import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { CommunitySidebarTabItem, SidebarItem, SidebarItemText, SidebarList } from '../styled-components/sidebar';
import { Separator } from '../styled-components/common';
import { Logo } from './logo';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { scrollLayoutToTop } from 'util/utils';
import { fetchCommunityTabsAsync } from 'redux/actions/tab';
import usePathParams from 'hooks/usePathParams';
import { Community, Literals } from 'redux/types/account';
import { Flag, Languages } from 'redux/types/enums';
import { getLiterals } from '../../services/literals';

export const FullWidthSidebarContainer = styled.div`
  background-color: white;
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
  overflow: auto;
`;

const MenuOpenIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    font-size: 3em;
    cursor: pointer;
  }
`;

const MenuCloseIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 6em;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blue.lightGrey};
  margin-bottom: 1em;
  font-size: 0.7em;

  svg {
    font-size: 3em;
    cursor: pointer;
    margin-right: 0.3em;
  }
`;

const mapDispatchToProps = {
  fetchTabs: fetchCommunityTabsAsync.request,
};

const mapStateToProps = (state: RootState) => ({
  selectedCommunity: state.account.selectedCommunity,
  notifications: state.account.details.notifications,
  isLiteralsLoaded: state.loading.getLiteralsFlag,
  literals: state.literals,
  bearer: state.account.session.session.bearer,
  userLanguage: state.account.details.user.userLanguage as Languages,
});

type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  /** User token */
  bearer: string;
}

interface CommunityTitleProps {
  children?: string;
}
const CommunityTitle: React.FC<CommunityTitleProps> = props => {
  const { children } = props;
  if (children) {
    if (children.length > 13) {
      return <h3 style={{ lineHeight: '1.5em' }}>{children}</h3>;
    }
    return <h2 style={{ lineHeight: '1.5em' }}>{children}</h2>;
  } else {
    return null;
  }
};

const FullWidthCommunitySidebar: React.FC<Props> = ({
  selectedCommunity,
  literals,
  fetchTabs,
  bearer,
  isLiteralsLoaded,
  userLanguage,
}) => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState<boolean>(false);
  const [loadingLiterals, setLoadingLiterals] = useState<boolean>(true);
  const [customLiterals, setCustomLiterals] = useState<Literals>({});
  const { communityId } = useParams<{ communityId: string }>();
  const { pathname } = useLocation();
  const { entityId, tabId } = usePathParams(pathname);
  const location = useLocation();
  const community: Community | null = selectedCommunity;
  const tabs: CommunitySidebarTabItem[] = useMemo(
    () =>
      community?.tabs?.length
        ? community.tabs.map(tab => ({
            ...tab,
            url: `/community/${communityId}/tab/${tab.id}`,
          }))
        : [],
    [community?.tabs],
  );

  useEffect(() => {
    if (setSideMenuIsOpen) setSideMenuIsOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    bearer && communityId && !tabs.length && fetchTabs({ bearer, communityId: +communityId });
  }, [selectedCommunity]);

  useEffect(() => {
    if (isLiteralsLoaded === Flag.Request) {
      setLoadingLiterals(true);
    }
  }, [isLiteralsLoaded]);

  useEffect(() => {
    if (loadingLiterals) {
      const communityIdNumber = parseInt(communityId);
      getLiterals(userLanguage || 'en-EN', communityIdNumber, bearer).then(response => {
        setCustomLiterals(response.literals);
        setLoadingLiterals(false);
      });
    }
  }, [communityId, loadingLiterals, userLanguage, bearer]);

  if (!community) {
    return null;
  }

  if (!sideMenuIsOpen) {
    return (
      <MenuOpenIconContainer>
        <MenuIcon onClick={() => setSideMenuIsOpen(true)} />
      </MenuOpenIconContainer>
    );
  }
  return (
    <FullWidthSidebarContainer>
      <MenuCloseIconContainer>
        <CloseIcon onClick={() => setSideMenuIsOpen(false)} />
      </MenuCloseIconContainer>
      <Logo src={community.logo} />
      <CommunityTitle>{community.name}</CommunityTitle>
      {isLiteralsLoaded === Flag.Success && (
        <SidebarList>
          <Separator />
          {community.isHomeTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}`} hasNotifications={false}>
              {literals.menu_home}
            </SidebarItem>
          )}
          {!!tabId && !!entityId && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${community.id}/tab/${tabId}/entity/${entityId}/network`}
              hasNotifications={false}
            >
              {literals.project_ctrl_initializer_menu_option_network}
            </SidebarItem>
          )}
          <Separator />
          {community.isProjectsTabVisible && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/projects`}
              hasNotifications={false}
            >
              {customLiterals.primitive_startups[0].toUpperCase() + customLiterals.primitive_startups.slice(1)}
            </SidebarItem>
          )}
          {community.isForumTabVisible && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/discussions`}
              hasNotifications={false}
            >
              {literals.community_menu_forum_option || 'Forum'}
            </SidebarItem>
          )}
          {community.isMembersTabVisible && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/members`}
              hasNotifications={false}
            >
              {literals.community_menu_members_option}
            </SidebarItem>
          )}
          {community.isResourcesTabVisible && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/resources`}
              hasNotifications={false}
            >
              {literals.community_menu_resources_option}
            </SidebarItem>
          )}
          {community.isMember && community.isEventsTabVisible && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/events`}
              hasNotifications={false}
            >
              {literals.events_title}
            </SidebarItem>
          )}
          {community.isRequestsTabVisible && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/requests`}
              hasNotifications={false}
            >
              {literals.sidebar_option_requests}
            </SidebarItem>
          )}
          {community.isApplicationsTabVisible && community.canViewApplicationsTab && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/application-definitions`}
              hasNotifications={false}
            >
              {literals.sidebar_applications}
            </SidebarItem>
          )}
          {!!tabs?.length &&
            tabs.map(tab => (
              <SidebarItem key={tab.id} onClickCapture={scrollLayoutToTop} to={tab.url}>
                <SidebarItemText>{tab.name}</SidebarItemText>
              </SidebarItem>
            ))}
          {community.isAboutTabVisible && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/about`}
              hasNotifications={false}
            >
              {literals.community_menu_about_option}
            </SidebarItem>
          )}
          <Separator />
        </SidebarList>
      )}
    </FullWidthSidebarContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FullWidthCommunitySidebar);
