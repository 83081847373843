import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import { ProjectAwaitingFeedback, Community } from 'redux/types/account';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Logo } from '../common/logo';
import { Flag } from '../../redux/types/enums';

const Card = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 0.5em;
  padding: 1em;
  margin-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  min-height: 50vh;
  overflow: hidden;

  a,
  span {
    font-size: 0.9em;
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 17em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1em;
`;

const LogoContainer = styled.div`
  margin-right: 0.8em;
  border-radius: 0;
  font-size: 0.83em;
`;

const ProjectName = styled.div`
  width: 15em;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 0.83em;
  font-weight: 500;
`;

const Header = styled.h3`
  width: 100%;
  font-size: 1.3em;
  line-height: 1.3em;
  margin-bottom: 1em;
`;

const mapStateToProps = (state: RootState) => ({
  isFetchingProjectsAwaitingFeedback: state.loading.fetchProjectsAwaitingFeedbackAsync,
  literals: state.literals,
});

const mapDispatchToProps = {};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  community: Community;
}

const CtaMentors: React.FC<Props> = ({ isFetchingProjectsAwaitingFeedback, community, literals }) => {
  const { communityId } = useParams<{ communityId: string }>();
  const [communityProjects, setCommunityProjects] = useState<ProjectAwaitingFeedback[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (community && community.projectsAwaitingFeedback && community.projectsAwaitingFeedback.length > 0) {
      setCommunityProjects(community.projectsAwaitingFeedback);
    } else {
      setCommunityProjects([]);
    }
  }, [community.projectsAwaitingFeedback, communityId]);

  const handleParagraphClick = (p: ProjectAwaitingFeedback) => {
    history.push(`/project/${p.id}/business-model?paragraphId=${p.sections[0].paragraphs[0].id}`);
  };

  if (!communityProjects) return null;
  if (communityProjects.length === 0) return null;
  return (
    <Card>
      {isFetchingProjectsAwaitingFeedback === Flag.Request ? (
        <LoaderContainer>
          <Dimmer inverted active>
            <Loader inverted>{literals.project_list_loading_tag_message}</Loader>
          </Dimmer>
        </LoaderContainer>
      ) : (
        <>
          <Header>{literals.community_cta_projects_await_feedback.replace('{0}', communityProjects.length)}</Header>
          {communityProjects.map((p: ProjectAwaitingFeedback) => (
            <StyledItem key={p.id} onClick={() => handleParagraphClick(p)}>
              <LogoContainer>
                <Logo customSize={'32x32'} style={{ width: '2em', height: '2em', borderRadius: '0' }} src={p.logo} />
              </LogoContainer>
              <ProjectName
                title={`${p.sections[0].name} / ${p.sections[0].paragraphs[0].name}`}
              >{`${p.sections[0].name} / ${p.sections[0].paragraphs[0].name}`}</ProjectName>
            </StyledItem>
          ))}
        </>
      )}
    </Card>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CtaMentors);
