import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { CkEditorContainer } from 'components/styled-components/common';
import Editor from 'components/editor';

const ComposerCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .toolbar {
    border: none;
    padding: 0;
  }

  .ck.ck-editor__editable_inline {
    padding: 0;
  }
`;

const ComposerBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  textarea {
    font-size: 0.9em;
  }
`;

const EditorColor = styled(CkEditorContainer)`
  & > * {
    padding: 0.5em 1em !important;
    background-color: ${({ theme, $transparentBackground }) =>
      ($transparentBackground && 'transparent') || theme.colors.blue.veryLight};
  }
`;

const mapStateToProps = state => ({
  literals: state.literals,
});

const ApplicationDescription = ({
  description,
  setDescription,
  literals,
  style,
  transparentBackground,
  initAutoFocus,
}) => {
  return (
    <ComposerCard>
      <ComposerBody>
        <EditorColor style={{ width: '100%', ...style }} $transparentBackground={transparentBackground}>
          <Editor
            content={description}
            placeholder={literals.community_edit_description_placeholder}
            initAutoFocus={initAutoFocus}
            smallText
            withPadding
            setContent={setDescription}
          />
        </EditorColor>
      </ComposerBody>
    </ComposerCard>
  );
};

export default connect(mapStateToProps, {})(ApplicationDescription);
