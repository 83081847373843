import { createReducer, PayloadAction } from 'typesafe-actions';
import { buildFlagReducer } from '../store/utils';

import {
  fetchMultipleMeetingSettingsAsync,
  updateSelectedUsers,
  updateUserMultipleMeetingSettings,
} from 'redux/actions/user-calendar';
import { MultipleMeetingSettings, SelectedUserOption } from 'services/types/user-calendar';
import { UserCalendar } from 'redux/types/user-calendar';

export const fetchUserRecurrentAsyncFlag = buildFlagReducer(fetchMultipleMeetingSettingsAsync);

export const userCalendarReducer = createReducer({} as UserCalendar)
  .handleAction(
    fetchMultipleMeetingSettingsAsync.success,
    (
      state: UserCalendar,
      action: PayloadAction<
        'UPDATE_USERS_MULTIPLE_MEETING_SETTINGS',
        { multipleMeetingSettings: MultipleMeetingSettings[] }
      >,
    ) => {
      return {
        ...state,
        multipleMeetingSettings: action.payload.multipleMeetingSettings,
      };
    },
  )
  .handleAction(
    updateSelectedUsers,
    (state: UserCalendar, action: PayloadAction<'UPDATE_SELECTED_USERS', { selectedUsers: SelectedUserOption[] }>) => {
      return {
        ...state,
        selectedUsers: action.payload.selectedUsers,
      };
    },
  )
  .handleAction(
    updateUserMultipleMeetingSettings,
    (state: UserCalendar, action: PayloadAction<any, { multipleMeetingSettings: MultipleMeetingSettings[] }>) => {
      return {
        ...state,
        multipleMeetingSettings: action.payload.multipleMeetingSettings,
      };
    },
  );

export default userCalendarReducer;
export type AccountState = ReturnType<typeof userCalendarReducer>;
