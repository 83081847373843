import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError, tap } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError, showSuccessMessage } from './helpers';

import {
  fetchBusinessModelByProjectIdAsync,
  fetchProjectTasksAsync,
  saveProjectTasksAsync,
  fetchBusinessModelPrivacyCirclesAsync,
  addCommentToBusinessModelParagraphAsync,
  fetchCommentsForBusinessModelParagraphAsync,
  saveSectionStagegateLockedAsync,
  toggleApprovedAssignmentAsync,
  fetchProjectBusinessModelsAsync,
} from '../actions/business-model';
import accountSessionBearerSelector from 'redux/selectors/account/accountSessionBearerSelector';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchProjectBusinessModelsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectBusinessModelsAsync.request)),
    switchMap(action =>
      from(api.businessModel.fetchProjectBusinessModels(action.payload.projectId, action.payload.bearer)).pipe(
        map(fetchProjectBusinessModelsAsync.success),
        catchError(error => handleError(error, fetchProjectBusinessModelsAsync)),
      ),
    ),
  );

export const fetchBusinessModelEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchBusinessModelByProjectIdAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.fetchBusinessModel(
          action.payload.projectId,
          action.payload.methodologyId,
          action.payload.bearer,
        ),
      ).pipe(
        map(fetchBusinessModelByProjectIdAsync.success),
        catchError(error => handleError(error, fetchBusinessModelByProjectIdAsync)),
      ),
    ),
  );

export const fetchProjectTasksEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectTasksAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.fetchProjectTasks(
          action.payload.projectId,
          action.payload.bearer,
          action.payload.methodologyId,
        ),
      ).pipe(
        map(fetchProjectTasksAsync.success),
        catchError(error => handleError(error, fetchProjectTasksAsync)),
      ),
    ),
  );

export const saveProjectTasksEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveProjectTasksAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.saveProjectTasks(
          action.payload.saveParagraph,
          action.payload.projectId,
          action.payload.bearer,
          action.payload.circles,
          action.payload.taskId,
        ),
      ).pipe(
        map(saveProjectTasksAsync.success),
        catchError(error => handleError(error, saveProjectTasksAsync)),
      ),
    ),
  );

export const fetchBusinessModelPrivacyCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchBusinessModelPrivacyCirclesAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.fetchCustomCirclesByParagraph(
          action.payload.projectId,
          action.payload.bearer,
          action.payload.paragraphId,
        ),
      ).pipe(
        map(fetchBusinessModelPrivacyCirclesAsync.success),
        catchError(error => handleError(error, fetchBusinessModelPrivacyCirclesAsync)),
      ),
    ),
  );

export const addCommentToBusinessModelParagraphEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(addCommentToBusinessModelParagraphAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.addCommentToBusinessModelParagraph(
          action.payload.bearer,
          action.payload.userId,
          action.payload.parentId,
          action.payload.paragraphId,
          action.payload.content,
          action.payload.projectId,
        ),
      ).pipe(
        map(addCommentToBusinessModelParagraphAsync.success),
        tap(() => {
          showSuccessMessage('Comment added!');
        }),
        catchError(error => handleError(error, addCommentToBusinessModelParagraphAsync)),
      ),
    ),
  );

export const fetchCommentsForBusinessModelParagraphEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommentsForBusinessModelParagraphAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.fetchCommentsForBusinessModelParagraph(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.paragraphId,
        ),
      ).pipe(
        map(fetchCommentsForBusinessModelParagraphAsync.success),
        catchError(error => handleError(error, fetchCommentsForBusinessModelParagraphAsync)),
      ),
    ),
  );

export const saveSectionStagegateLockedEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveSectionStagegateLockedAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.saveSectionStagegateLocked(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.sectionId,
          action.payload.isStagegateLocked,
        ),
      ).pipe(
        map(saveSectionStagegateLockedAsync.success),
        catchError(error => handleError(error, saveSectionStagegateLockedAsync)),
      ),
    ),
  );

export const toggleApprovedAssignmentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(toggleApprovedAssignmentAsync.request)),
    switchMap(action =>
      from(
        api.businessModel.toggleApprovedAssignment(
          accountSessionBearerSelector(state$.value),
          action.payload.projectId,
          action.payload.sectionId,
          action.payload.assignmentId,
        ),
      ).pipe(
        map(toggleApprovedAssignmentAsync.success),
        catchError(error => handleError(error, toggleApprovedAssignmentAsync)),
      ),
    ),
  );

export default {
  fetchBusinessModelEpic,
  fetchProjectTasksEpic,
  saveProjectTasksEpic,
  fetchBusinessModelPrivacyCirclesEpic,
  addCommentToBusinessModelParagraphEpic,
  fetchCommentsForBusinessModelParagraphEpic,
  saveSectionStagegateLockedEpic,
  toggleApprovedAssignmentEpic,
  fetchProjectBusinessModelsEpic,
};
