import { Comment } from 'redux/types/account';
import { findCommentById } from './';

export const recursiveCommentLike = (comments: Comment[], id: number, like: boolean): Comment[] => {
  if (!comments) return [] as Comment[];
  const commentFound = findCommentById(comments, id);
  if (!commentFound) return comments;
  commentFound.isLiked = like;
  commentFound.likeCount = commentFound.likeCount + (like ? 1 : -1);
  return comments;
};
