import { DEVICE } from '../../../constants';
import styled from 'styled-components';

export const StyledContainer = styled('div')<any>`
  padding: 2em;
  background: #fff;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 21%) 1px 1px 6px 1px;

  @media ${DEVICE.sm} {
    width: 600px;
    min-height: 150px;
  }
`;

export const Contents = styled('div')<any>`
  width: 100%;
  @media ${DEVICE.sm} {
    width: 600px;
    min-height: 150px;
  }
`;
