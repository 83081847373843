import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { includes, find, startsWith } from 'ramda';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import md5 from 'md5';
import { Popup } from 'semantic-ui-react';
import useWindowDimensions from '../../util/windowSize';
import { scrollLayoutToTop } from 'util/utils';
import Image from '../common/lazyImage';
import _NotificationsIcon from '@material-ui/icons/Notifications';
import { getUserDetails } from '../../redux/selectors/account';
import { Notification, Community, Project } from 'redux/types/account';
import defaultProfilePicture from '../../assets/common/profile-placeholder.svg';
import defaultCommunityPicture from '../../assets/common/community-placeholder.svg';
import TipMessage from '../../components/panels/tip-message';
import { changeTipStateAsync } from '../../redux/actions/account';
import { TipState } from '../../redux/types/enums';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  height: 100vh;
  width: 5em;
  z-index: 10;
  position: relative;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    padding: 1em;
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue.lightGrey};
  }
`;

const CommunitiesContainer = styled.div`
  height: 100%;
  overflow-x: visible;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
    overflow-x: auto;
    white-space: nowrap;
    padding-left: 1em;
  }
`;

interface ICommunityLogoContainer {
  active: boolean;
  invited: boolean;
}

const CommunityLogoContainer = styled.div<ICommunityLogoContainer>`
  position: relative;
  display: flex;
  height: 3.5em;
  width: 3.5em;
  padding: 0.6em;
  align-items: center;
  border-radius: 0 0.5em 0.5em 0;
  ${({ active, theme }) => active === true && `background-color: ${theme.colors.blue.light};`};

  img {
    ${({ invited, theme }) => invited === true && `border: 2px solid ${theme.colors.blue.normal}`};
    width: 2.3em !important;
    height: 2.3em !important;
    object-fit: cover;
  }

  ::before {
    content: '';
    position: absolute;
    background-color: transparent;
    top: -1.4em;
    left: 0;
    height: 1.4em;
    width: 0.7em;
    border-bottom-left-radius: 0.7em;
    box-shadow: 0 0.7em 0 0 ${({ active, theme }) => (active ? theme.colors.blue.veryLight : 'transparent')};
  }

  ::after {
    content: '';
    position: absolute;
    background-color: transparent;
    bottom: -1.4em;
    left: 0;
    height: 1.4em;
    width: 0.7em;
    border-top-left-radius: 0.7em;
    box-shadow: 0 -0.7em 0 0 ${({ active, theme }) => (active ? theme.colors.blue.veryLight : 'transparent')};
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    min-width: 4em;
    min-height: 4em;
    padding: 0 0.5em;
    border-radius: 0 0 0.5em 0.5em;

    img {
      width: inherit !important;
      height: inherit !important;
    }

    ::before {
      content: '';
      position: absolute;
      background-color: transparent;
      top: 0;
      left: 4em;
      width: 1.4em;
      height: 0.7em;
      border-top-left-radius: 0.7em;
      border-bottom-left-radius: 0;
      box-shadow: -0.7em 0 0 0 ${({ active, theme }) => (active ? theme.colors.blue.veryLight : 'transparent')};
    }

    ::after {
      content: '';
      position: absolute;
      background-color: transparent;
      bottom: -1.4em;
      left: -1.4em;
      top: 0;
      width: 1.4em;
      height: 0.7em;
      border-top-right-radius: 0.7em;
      border-top-left-radius: 0;
      box-shadow: 0.7em 0 0 0 ${({ active, theme }) => (active ? theme.colors.blue.veryLight : 'transparent')};
    }
  }
`;

const ActiveCommunityMark = styled.div`
  position: absolute;
  left: 0;
  width: 0.2em;
  height: 2.75em;
  z-index: 1;
  background: ${({ theme }) => theme.colors.blue.normal};
  /* border-radius: 0px 3px 3px 0px; */

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    top: 0;
    left: 20%;
    border-radius: 0 0 3px 3px;
    height: 0.2em;
    width: 2.75em;
  }
`;

const UserAvatarContainer = styled.div`
  margin: 1em 0 1.8em 0;
  height: 3.5em;
  width: 3.5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    width: 2.4em !important;
    height: 2.4em !important;
  }

  img {
    object-fit: cover !important;
    width: 2.4em !important;
    height: 2.4em !important;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    margin: 0 3em 0 0;
    width: 4em !important;
    height: 4em !important;
    flex-direction: row;

    a {
      width: inherit !important;
      height: inherit !important;
    }

    img {
      width: inherit !important;
      height: inherit !important;
    }
  }
`;

const NotificationsIcon = styled(_NotificationsIcon)`
  color: ${({ theme }) => theme.colors.black};
  margin: 0 auto;
  font-size: 1.5em !important;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    margin: unset;
    font-size: 2.5em !important;
    margin-right: 0.15em;
  }
`;

const NotificationContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey.light};
  position: absolute;
  height: 2.8em;
  display: flex;
  flex-direction: column-reverse;
  top: 3.7em;
  width: 2em;
  z-index: 1;
  align-content: center;
  border-radius: 5px;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    flex-direction: row-reverse;
    align-items: center;
    width: 4.5em;
    left: 3.7em;
    height: 4em;
    top: unset;
  }
`;

const NotificationIconDot = styled.div`
  position: absolute;
  right: 0.1em;
  bottom: 0.1em;
  width: 0.7em;
  height: 0.7em;
  background: ${({ theme }) => theme.colors.orange.normal};
  border-radius: 10px;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    bottom: unset;
    transform: translate(0, 0.6em);
    width: 1em;
    height: 1em;
  }
`;

const mapDispatchToProps = {
  changeTipState: changeTipStateAsync.request,
};
type dispatchType = typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  communities: state.account.communities.list,
  selectedCommunity: state.account.selectedCommunity,
  projects: state.account.projects.list,
  user: getUserDetails(state.account),
  notifications: state.account.details.notifications,
  communityListTip: state.tips.communityList,
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const Dock: React.FC<Props> = props => {
  const { communities, selectedCommunity, projects, user, changeTipState, communityListTip, literals } = props;
  const { communityId, projectId } = useParams<{
    communityId: string;
    projectId: string;
  }>();
  const location = useLocation();
  const windowSize = useWindowDimensions();
  const history = useHistory();
  const hasUnreadNotifications: boolean = (props.notifications || []).some((n: Notification) => !n.isRead);

  const isOnCommunityPage = history.location.pathname.substr(1, 9) === 'community';

  const isUsersFirstWeek = moment(user?.registrationDate || '')
    .add(7, 'days')
    .isAfter(moment());
  const communityTipFirstWeek = moment('2021-10-31T00:00:00Z').isAfter(moment());
  const showCommunityTipBasedOnTime = isUsersFirstWeek || communityTipFirstWeek;

  useEffect(() => {
    if (isOnCommunityPage) {
      if (communityListTip & TipState.Prevent)
        changeTipState({ tip: 'communityList', state: communityListTip & ~TipState.Prevent });
    }
  }, [isOnCommunityPage]);

  const isSelected = (community: Community) => {
    if (communityId && communityId === community.id.toString()) return true;
    if (selectedCommunity && selectedCommunity.id === community.id) return true;
    if (projectId) {
      const currentProject = find((p: Project) => projectId === p.id.toString(), projects);
      if (currentProject?.communityIds?.includes(community.id)) return true;
    }
    return false;
  };

  if (windowSize.isMobile) {
    if (startsWith('/account', location.pathname)) return null;
  }

  return (
    <Container className="community-dock">
      <UserAvatarContainer onClickCapture={scrollLayoutToTop}>
        <NotificationContainer>
          <NotificationsIcon
            onClick={() => {
              history.push(`/account/notifications`);
            }}
          />
          {hasUnreadNotifications && <NotificationIconDot />}
        </NotificationContainer>
        <Popup
          content="My profile"
          position="left center"
          size="tiny"
          basic={true}
          inverted={true}
          style={{
            padding: '0.5em',
            fontSize: '0.8em',
            lineHeight: '1em',
            borderRadius: '0.25em',
            backgroundColor: 'black',
          }}
          trigger={
            <Image
              customSize={'128x128'}
              style={{ zIndex: '1' }}
              src={user?.photo}
              onError={(event: any) => (event.target.src = defaultProfilePicture)}
              circular={true}
              as={Link}
              to="/account"
              alt="profile"
            />
          }
        />
      </UserAvatarContainer>
      <CommunitiesContainer>
        {!windowSize.isMobile &&
          !isOnCommunityPage &&
          communityListTip === TipState.Always &&
          showCommunityTipBasedOnTime &&
          communities.length && (
            <TipMessage
              style={{
                position: 'absolute',
                top: '8em',
                left: '4em',
                zIndex: 10,
              }}
              dontShowAgainOption={true}
              showCloseTip={true}
              closeTip={(options: { dontShowAgain?: boolean }) => {
                if (options.dontShowAgain) {
                  changeTipState({ tip: 'communityList', state: TipState.PreventAndNever });
                } else {
                  changeTipState({ tip: 'communityList', state: TipState.Prevent });
                }
              }}
              content={literals.community_list_tip_message}
            />
          )}
        {communities.map((community: Community | null) => {
          if (community && community.id !== 163) {
            const active = isSelected(community);
            const invited = includes(community.id, []);
            return (
              <Popup
                key={community.id ? md5(community.id.toString()) : -1}
                content={invited ? 'INVITED' : community.name}
                position="left center"
                size="tiny"
                basic={true}
                inverted={true}
                style={{
                  padding: '0.5em',
                  fontSize: '0.8em',
                  lineHeight: '1em',
                  borderRadius: '0.25em',
                  backgroundColor: invited ? '#3B73FF' : 'black',
                }}
                trigger={
                  <CommunityLogoContainer invited={invited} active={active}>
                    {active && <ActiveCommunityMark />}
                    <Image
                      onClickCapture={scrollLayoutToTop}
                      src={community.logo}
                      rounded={true}
                      customSize={'64x64'}
                      onError={(event: any) => (event.target.src = defaultCommunityPicture)}
                      as={Link}
                      fluid={true}
                      to={`/community/${community.id}`}
                      alt={community.name}
                    />
                  </CommunityLogoContainer>
                }
              />
            );
          } else {
            return null;
          }
        })}
      </CommunitiesContainer>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dock);
