import literals from './literals.json';
import { Literals } from 'redux/types/account';
import { Languages } from '../../redux/types/enums';
import { getAxiosInstance } from '../api/helper';
import { AxiosResponse } from 'axios';

const ai = getAxiosInstance();

const filterEmptyKeys = (literals: Literals): Literals => {
  if (!literals) return {};

  return Object.keys(literals)
    .filter((key: string) => literals[key].length)
    .reduce(
      (acc: Literals, key: string) => ({
        ...acc,
        [key]: literals[key],
      }),
      {},
    );
};

export function getLiterals(
  language: Languages,
  communityId?: number,
  bearer?: string,
  communityLiteralsOnly?: boolean,
): Promise<{ language: Languages; literals: Literals; communityId?: number }> {
  return new Promise((resolve, reject) => {
    if (!communityId || !bearer)
      resolve({
        language,
        literals: {
          ...literals['en-EN'],
          ...filterEmptyKeys(literals[language] as Literals),
        },
      });
    ai({
      method: 'GET',
      url: 'api/CommunityLiteral/GetByCommunity',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        if (communityLiteralsOnly) resolve({ language, literals: response.data });
        else {
          resolve({
            language,
            communityId,
            literals: {
              ...literals['en-EN'],
              ...filterEmptyKeys(literals[language] as Literals),
              ...response.data,
            },
          });
        }
      })
      .catch(err => reject(err));
  });
}

export function saveLiterals(bearer: string, communityId: number, literals: Literals): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/CommunityLiteral/Save/${communityId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: literals,
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export default {
  getLiterals,
  saveLiterals,
};
