import { Comment, Community, Discussion } from 'redux/types/account';
import { recursiveCommentLike } from '../reducersHelpers/recursiveCommentLike';

export const updateDiscussionCommentsFromCommunity = (community: Community, itemId: number, comment: Comment) => {
  return {
    ...community,
    communityDiscussions: (community.communityDiscussions || []).map((discussion: Discussion) => {
      if (discussion.id !== itemId) {
        return discussion;
      } else {
        return {
          ...discussion,
          comments: discussion.comments.map((firstComment: Comment) => {
            if (firstComment.id === comment.parentId) {
              if (!firstComment.comments.find((secondComment: Comment) => secondComment.id === comment.id)) {
                return {
                  ...firstComment,
                  comments: [...firstComment.comments, comment],
                };
              }
            }
            if (firstComment.id !== comment.id) {
              return {
                ...firstComment,
                comments: firstComment.comments.map((secondComment: Comment) => {
                  if (secondComment.id !== comment.id) {
                    return secondComment;
                  } else {
                    return {
                      ...secondComment,
                      content: comment.content,
                    };
                  }
                }),
              };
            } else {
              return {
                ...firstComment,
                content: comment.content,
              };
            }
          }),
        };
      }
    }),
  };
};

export const discussionCommentLike = (community: Community, id: number, like: boolean) => {
  return {
    ...community,
    communityDiscussions: (community.communityDiscussions || []).map((d: Discussion) => {
      return {
        ...d,
        comments: recursiveCommentLike(d.comments, id, like),
      };
    }),
  };
};

export const discussionDeleteComment = (community: Community, id: number, postId: number) => {
  return {
    ...community,
    communityDiscussions: (community.communityDiscussions || []).map((discussion: Discussion) => {
      if (discussion.id !== id) return discussion;
      const isFirstLevelComment = (discussion.comments || []).find((c: Comment) => c.id === postId);
      return {
        ...discussion,
        commentCount: discussion.commentCount - 1 - (isFirstLevelComment ? isFirstLevelComment.comments.length : 0),
        comments: (discussion.comments || [])
          .filter((parentComment: Comment) => parentComment.id !== postId)
          .map((parentComment: Comment) => {
            return {
              ...parentComment,
              comments: (parentComment.comments || []).filter((childComment: Comment) => childComment.id !== postId),
            };
          }),
      };
    }),
  };
};
