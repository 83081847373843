import { ApplicationStatus } from '../types/enums';
import { createReducer, PayloadAction } from 'typesafe-actions';
import {
  deleteApplicationDefinitionAsync,
  deleteApplicationEntryCommentAsync,
  fetchApplicationDefinitionByIdAsync,
  fetchApplicationDefinitionOverviewByIdAsync,
  fetchApplicationDefinitionPreviewByidAsync,
  fetchApplicationEntriesByDefinitionIdAsync,
  fetchApplicationEntryByIdAsync,
  fetchApplicationEntryScoresAsync,
  fetchCommunityApplicationDefinitionsAsync,
  fetchCurrentUserApplicationsAsync,
  fetchEvaluationAnswersByApplicationEntryIdAsync,
  likeApplicationEntryCommentAsync,
  saveApplicationDefinitionAsync,
  saveApplicationEntryAsync,
  saveApplicationEntryScoresAsync,
  saveApplicationEntryTagsAsync,
  saveCommentToApplicationEntryAsync,
  updateApplicationEntryStatusAsync,
} from '../actions/application-manager';
import { ApplicationDefinition, ApplicationEntry, Comment } from 'redux/types/account';
import { buildFlagReducer } from '../store/utils';
import { gdprConsent, featuresCookieCategories } from 'util/utils';
import { createTransform } from 'redux-persist';
import { recursiveCommentLike } from './reducersHelpers';

export const saveApplicationDefinitionFlag = buildFlagReducer(saveApplicationDefinitionAsync);

export const fetchApplicationDefinitionOverviewByIdFlag = buildFlagReducer(fetchApplicationDefinitionOverviewByIdAsync);

export const saveCommentToApplicationEntryFlag = buildFlagReducer(saveCommentToApplicationEntryAsync);

export const fetchEvaluationAnswersByApplicationEntryIdFlag = buildFlagReducer(
  fetchEvaluationAnswersByApplicationEntryIdAsync,
);

export const fetchApplicationEntriesByDefinitionIdFlag = buildFlagReducer(fetchApplicationEntriesByDefinitionIdAsync);

export const saveApplicationEntryScoresFlag = buildFlagReducer(saveApplicationEntryScoresAsync);

export const fetchApplicationEntryScoresFlag = buildFlagReducer(fetchApplicationEntryScoresAsync);

export const saveApplicationEntryTagsFlag = buildFlagReducer(saveApplicationEntryTagsAsync);

export const saveApplicationEntryFlag = buildFlagReducer(saveApplicationEntryAsync);

export const fetchCommunityApplicationDefinitionsFlag = buildFlagReducer(fetchCommunityApplicationDefinitionsAsync);

export const deleteApplicationDefinitionFlag = buildFlagReducer(deleteApplicationDefinitionAsync);

export const fetchApplicationEntryByIdFlag = buildFlagReducer(fetchApplicationEntryByIdAsync);

export const applicationDefinitionsTransform = createTransform<ApplicationDefinition[], ApplicationDefinition[]>(
  (state: ApplicationDefinition[]) => {
    if (!gdprConsent(featuresCookieCategories.account)) {
      return [];
    }
    return state;
  },
  (state: ApplicationDefinition[]) => {
    return state;
  },
  { whitelist: ['applicationDefinitions'] },
);

export const applicationDefinitionsReducer = createReducer([] as ApplicationDefinition[])
  .handleAction(
    fetchApplicationDefinitionByIdAsync.success,
    (
      applicationDefinitions: ApplicationDefinition[],
      action: PayloadAction<
        'FETCH_APPLICATION_DEFINITION_BY_ID_SUCCESS',
        { applicationDefinition: ApplicationDefinition }
      >,
    ) => {
      if (
        applicationDefinitions.find(
          (applicationDefinition: ApplicationDefinition) =>
            applicationDefinition.id === action.payload.applicationDefinition.id,
        )
      ) {
        return applicationDefinitions.map((applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== action.payload.applicationDefinition.id) return applicationDefinition;
          return action.payload.applicationDefinition;
        });
      } else {
        return [...applicationDefinitions, action.payload.applicationDefinition];
      }
    },
  )
  .handleAction(
    fetchApplicationDefinitionOverviewByIdAsync.success,
    (
      applicationDefinitions: ApplicationDefinition[],
      action: PayloadAction<
        'FETCH_APPLICATION_DEFINITION_OVERVIEW_BY_ID_SUCCESS',
        { applicationDefinition: ApplicationDefinition }
      >,
    ) => {
      if (
        applicationDefinitions.find(
          (applicationDefinition: ApplicationDefinition) =>
            applicationDefinition.id === action.payload.applicationDefinition.id,
        )
      ) {
        return applicationDefinitions.map((applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== action.payload.applicationDefinition.id) return applicationDefinition;
          return action.payload.applicationDefinition;
        });
      } else {
        return [...applicationDefinitions, action.payload.applicationDefinition];
      }
    },
  )
  .handleAction(
    fetchApplicationDefinitionPreviewByidAsync.success,
    (
      applicationDefinitions: ApplicationDefinition[],
      action: PayloadAction<
        'FETCH_APPLICATION_DEFINITION_PREVIEW_BY_ID_SUCCESS',
        { applicationDefinition: ApplicationDefinition }
      >,
    ) => {
      if (
        applicationDefinitions.find(
          (applicationDefinition: ApplicationDefinition) =>
            applicationDefinition.id === action.payload.applicationDefinition.id,
        )
      ) {
        return applicationDefinitions.map((applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== action.payload.applicationDefinition.id) return applicationDefinition;
          return action.payload.applicationDefinition;
        });
      } else {
        return [...applicationDefinitions, action.payload.applicationDefinition];
      }
    },
  );

export const applicationsTransform = createTransform<ApplicationEntry[], ApplicationEntry[]>(
  (state: ApplicationEntry[]) => {
    if (!gdprConsent(featuresCookieCategories.account)) {
      return [];
    }
    return state;
  },
  (state: ApplicationEntry[]) => {
    return state;
  },
  { whitelist: ['applications'] },
);

export const applicationsReducer = createReducer([] as ApplicationEntry[])
  .handleAction(
    saveApplicationEntryAsync.success,
    (
      applications: ApplicationEntry[],
      action: PayloadAction<'SAVE_APPLICATION_ENTRY_SUCCESS', { applicationEntry: ApplicationEntry }>,
    ) => {
      if (applications.find((application: ApplicationEntry) => application.id === action.payload.applicationEntry.id)) {
        return applications.map((application: ApplicationEntry) => {
          if (application.id !== action.payload.applicationEntry.id) return application;
          return action.payload.applicationEntry;
        });
      } else {
        return [...applications, action.payload.applicationEntry];
      }
    },
  )
  .handleAction(
    saveCommentToApplicationEntryAsync.success,
    (
      applications: ApplicationEntry[],
      action: PayloadAction<
        'SAVE_COMMENT_TO_APPLICATION_ENTRY_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          parentId: number;
          comment: Comment;
        }
      >,
    ) => {
      if (applications.find((application: ApplicationEntry) => application.id === action.payload.applicationEntryId)) {
        return applications.map((application: ApplicationEntry) => {
          if (application.id !== action.payload.applicationEntryId) return application;
          const comments = application.comments || [];
          console.log('parent Id', action.payload);
          if (action.payload.parentId === 0) {
            if (comments.some((comment: Comment) => comment.id === action.payload.comment.id)) {
              return {
                ...application,
                comments: comments.map((comment: Comment) => {
                  if (comment.id !== action.payload.comment.id) return comment;
                  return {
                    ...comment,
                    content: action.payload.comment.content,
                  };
                }),
              };
            } else {
              return {
                ...application,
                comments: [action.payload.comment, ...comments],
              };
            }
          } else {
            return {
              ...application,
              comments: comments.map((parentComment: Comment) => {
                if (parentComment.id !== action.payload.parentId) return parentComment;
                if (parentComment.comments.some((comment: Comment) => comment.id === action.payload.comment.id)) {
                  return {
                    ...parentComment,
                    comments: parentComment.comments.map((comment: Comment) => {
                      if (comment.id !== action.payload.comment.id) return comment;
                      return {
                        ...comment,
                        content: action.payload.comment.content,
                      };
                    }),
                  };
                } else {
                  return {
                    ...parentComment,
                    comments: [...parentComment.comments, action.payload.comment],
                  };
                }
              }),
            };
          }
        });
      }
    },
  )
  .handleAction(
    deleteApplicationEntryCommentAsync.success,
    (
      applications: ApplicationEntry[],
      action: PayloadAction<
        'DELETE_APPLICATION_ENTRY_COMMENT_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          commentId: number;
        }
      >,
    ) => {
      return applications.map((applicationEntry: ApplicationEntry) => {
        if (applicationEntry.id !== action.payload.applicationEntryId) return applicationEntry;
        return {
          ...applicationEntry,
          comments: (applicationEntry.comments || [])
            .filter((firstLevelComment: Comment) => firstLevelComment.id !== action.payload.commentId)
            .map((firstLevelComment: Comment) => ({
              ...firstLevelComment,
              comments: firstLevelComment.comments.filter(
                (secondLevelComment: Comment) => secondLevelComment.id !== action.payload.commentId,
              ),
            })),
        };
      });
    },
  )
  .handleAction(
    likeApplicationEntryCommentAsync.success,
    (
      applications: ApplicationEntry[],
      action: PayloadAction<
        'LIKE_APPLICATION_ENTRY_COMMENT_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          commentId: number;
          isLike: boolean;
        }
      >,
    ) => {
      return applications.map((applicationEntry: ApplicationEntry) => {
        if (applicationEntry.id !== action.payload.applicationEntryId) return applicationEntry;
        return {
          ...applicationEntry,
          comments: recursiveCommentLike(
            applicationEntry.comments || [],
            action.payload.commentId,
            action.payload.isLike,
          ),
        };
      });
    },
  )
  .handleAction(
    fetchApplicationEntryByIdAsync.success,
    (
      applications: ApplicationEntry[],
      action: PayloadAction<'FETCH_APPLICATION_ENTRY_BY_ID_REQUEST', { applicationEntry: ApplicationEntry }>,
    ) => {
      if (applications.find((application: ApplicationEntry) => application.id === action.payload.applicationEntry.id)) {
        return applications.map((application: ApplicationEntry) => {
          if (application.id !== action.payload.applicationEntry.id) return application;
          return action.payload.applicationEntry;
        });
      } else {
        return [...applications, action.payload.applicationEntry];
      }
    },
  )
  .handleAction(
    fetchCurrentUserApplicationsAsync.success,
    (
      applications: ApplicationEntry[],
      action: PayloadAction<'FETCH_CURRENT_USER_APPLICATIONS_SUCCESS', { applicationEntries: ApplicationEntry[] }>,
    ) => {
      return action.payload.applicationEntries;
    },
  )
  .handleAction(
    updateApplicationEntryStatusAsync.success,
    (
      applications: ApplicationEntry[],
      action: PayloadAction<
        'UPDATE_APPLICATION_ENTRY_STATUS_SUCCESS',
        {
          communityId?: number;
          applicationDefinitionId?: number;
          applicationEntryIds: number[];
          status: ApplicationStatus;
        }
      >,
    ) => {
      return applications.map((application: ApplicationEntry) => {
        if (action.payload.applicationEntryIds.indexOf(application.id) < 0) return application;
        return {
          ...application,
          status: action.payload.status,
        };
      });
    },
  );

export default {
  saveApplicationDefinitionFlag,
  fetchCommunityApplicationDefinitionsFlag,
  deleteApplicationDefinitionFlag,
  saveApplicationEntryFlag,
  saveApplicationEntryTagsFlag,
  saveApplicationEntryScoresFlag,
  fetchApplicationEntryScoresFlag,
  fetchApplicationEntriesByDefinitionIdFlag,
  fetchEvaluationAnswersByApplicationEntryIdFlag,
  saveCommentToApplicationEntryFlag,
  fetchApplicationDefinitionOverviewByIdFlag,
};
