import React from 'react';
import { theme } from 'components/styled-components/babele-theme';
import { StyledButton } from './style';

interface Props {
  variant?: 'contained' | 'text' | 'outlined';
  children?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'button' | 'submit';
  size?: 'small' | 'medium' | 'large';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style?: any;
}

const sizeHeightMap = {
  small: 'auto',
  medium: '25px',
  large: '30px',
};

const sizePaddingMap = {
  small: '0.6em 2em',
  medium: '0 2em',
  large: '0 2em',
};

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 */
function Button({
  variant = 'contained',
  type = 'button',
  size = 'small',
  fullWidth = false,
  disabled,
  onClick,
  children,
  style = {},
}: Props) {
  const height: string = sizeHeightMap[size];
  const padding: string = sizePaddingMap[size];
  return (
    <StyledButton
      style={{
        height,
        padding,
        width: fullWidth ? '100%' : 'auto',
        ...style,
      }}
      type={type}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      theme={theme}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
