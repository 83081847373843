import { Epic } from 'redux-observable';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import {
  saveTabAsync,
  fetchCommunityTabsAsync,
  deleteTabAsync,
  fetchTabByIdAsync,
  followOrUnFollowEntityAsync,
  fetchTabEntitiesByIdAsync,
  inviteTabEntityMembersAsync,
  removeTabEntityInvitationAsync,
  fetchCommunityTabEntityCirclesAsync,
  fetchCommunityTabEntityCircleMembersAsync,
  saveTabEntityCircleAsync,
  deleteTabEntityCircleAsync,
  editTabEntityCircleAsync,
  removeUserFromTabEntityAsync,
} from '../actions/tab';
import { filter, switchMap, map, catchError, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { handleError, showSuccessMessage } from './helpers';
import { store } from 'redux/store';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const saveTabEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveTabAsync.request)),
    switchMap(({ payload }) =>
      from(api.tab.saveTab(payload.bearer, payload.tab)).pipe(
        map(saveTabAsync.success),
        catchError(error => handleError(error, saveTabAsync)),
      ),
    ),
  );

export const fetchCommunityTabsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityTabsAsync.request)),
    switchMap(({ payload }) =>
      from(api.tab.fetchCommunityTabs(payload.bearer, payload.communityId)).pipe(
        map(fetchCommunityTabsAsync.success),
        catchError(error => handleError(error, fetchCommunityTabsAsync)),
      ),
    ),
  );

export const deleteTabEpic: EpicFunction = (action$, state$, { api }) => {
  const storeState: RootState = store.getState();
  const { literals } = storeState;
  return action$.pipe(
    filter(isActionOf(deleteTabAsync.request)),
    switchMap(({ payload }) =>
      from(api.tab.deleteTab(payload.bearer, payload.communityId, payload.tabId)).pipe(
        map(deleteTabAsync.success),
        catchError(error => {
          if (
            error.response.data.includes(
              'Babele.Domain.Aggregates.TabAgg.Exceptions.CanNotDeleteTabIfAlreadyHasEntitiesException',
            )
          ) {
            const errorMessage = literals.cannot_delete_entity_existing;
            return handleError(error, deleteTabAsync, errorMessage);
          } else {
            return handleError(error, deleteTabAsync);
          }
        }),
      ),
    ),
  );
};

export const fetchTabByIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchTabByIdAsync.request)),
    switchMap(({ payload }) =>
      from(api.tab.fetchTabById(payload.bearer, payload.communityId, payload.tabId)).pipe(
        map(fetchTabByIdAsync.success),
        catchError(error => handleError(error, fetchTabByIdAsync)),
      ),
    ),
  );

export const fetchTabEntitiesByIdEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchTabEntitiesByIdAsync.request)),
    switchMap(({ payload }) =>
      from(
        api.tab.fetchTabEntitiesById(
          payload.bearer,
          payload.communityId,
          payload.tabId,
          payload.skip,
          payload.take,
          payload.sorting,
          payload.concept,
          payload.entitiesFilter,
        ),
      ).pipe(
        map(fetchTabEntitiesByIdAsync.success),
        catchError(error => handleError(error, fetchTabEntitiesByIdAsync)),
      ),
    ),
  );

export const followOrUnFollowEntityEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(followOrUnFollowEntityAsync.request)),
    switchMap(({ payload }) =>
      from(
        api.tab.followOrUnFollow(
          payload.bearer,
          payload.communityId,
          payload.tabId,
          payload.entityId,
          payload.isFollowing,
        ),
      ).pipe(
        map(followOrUnFollowEntityAsync.success),
        catchError(error => handleError(error, followOrUnFollowEntityAsync)),
      ),
    ),
  );

export const inviteTabEntityMembersEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(inviteTabEntityMembersAsync.request)),
    switchMap(action =>
      from(api.tab.inviteTabEntityAndCommunityMembers(action.payload.bearer, action.payload.data)).pipe(
        map(inviteTabEntityMembersAsync.success),
        tap(() => {
          showSuccessMessage('Successfully sent the invites! 🤓');
        }),
        catchError(error => handleError(error, inviteTabEntityMembersAsync)),
      ),
    ),
  );

export const removeInviteTabEntityMemberEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(removeTabEntityInvitationAsync.request)),
    switchMap(action =>
      from(
        api.tab.removeInviteTabEntityMember(
          action.payload.bearer,
          action.payload.tabEntityId,
          action.payload.userId,
          action.payload.userEmail,
        ),
      ).pipe(
        map(removeTabEntityInvitationAsync.success),
        tap(() => {
          showSuccessMessage('Successfully removed the invitation! 🤓');
        }),
        catchError(error => handleError(error, removeTabEntityInvitationAsync)),
      ),
    ),
  );

export const fetchCommunityTabEntityCirclesEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityTabEntityCirclesAsync.request)),
    switchMap(({ payload }) =>
      from(api.tab.fetchTabEntityCirclesByTabEntityId(payload.bearer, payload.communityId, payload.tabEntityId)).pipe(
        map(fetchCommunityTabEntityCirclesAsync.success),
        catchError(error => handleError(error, fetchCommunityTabEntityCirclesAsync)),
      ),
    ),
  );

export const fetchCommunityTabEntityCircleMembersEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityTabEntityCircleMembersAsync.request)),
    switchMap(({ payload }) =>
      from(
        api.tab.fetchTabEntityMemberByTabEntityId(
          payload.bearer,
          payload.communityId,
          payload.tabEntityId,
          payload.tabEntityCircleId,
          payload.skip,
          payload.take,
        ),
      ).pipe(
        map(fetchCommunityTabEntityCircleMembersAsync.success),
        catchError(error => handleError(error, fetchCommunityTabEntityCircleMembersAsync)),
      ),
    ),
  );

export const saveTabEntityCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveTabEntityCircleAsync.request)),
    switchMap(({ payload }) =>
      from(api.tab.saveTabEntityCircle(payload.bearer, payload.communityId, payload.id, payload.circle)).pipe(
        map(saveTabEntityCircleAsync.success),
        catchError(error => handleError(error, saveTabEntityCircleAsync)),
      ),
    ),
  );

export const deleteTabEntityCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteTabEntityCircleAsync.request)),
    switchMap(({ payload }) =>
      from(api.tab.deleteTabEntityCircle(payload.bearer, payload.communityId, payload.circleId)).pipe(
        map(deleteTabEntityCircleAsync.success),
        catchError(error => handleError(error, deleteTabEntityCircleAsync)),
      ),
    ),
  );

export const editTabEntityCircleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(editTabEntityCircleAsync.request)),
    switchMap(action =>
      from(
        api.tab.editTabEntityCircle(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.id,
          action.payload.circleId,
          action.payload.userId,
        ),
      ).pipe(
        map(editTabEntityCircleAsync.success),
        catchError(error => handleError(error, editTabEntityCircleAsync)),
      ),
    ),
  );

export const removeUserFromTabEntityEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(removeUserFromTabEntityAsync.request)),
    switchMap(action =>
      from(
        api.project.removeUserFromTabEntity(
          action.payload.bearer,
          action.payload.id,
          action.payload.communityId,
          action.payload.userId,
        ),
      ).pipe(
        tap(() => {
          showSuccessMessage('Member removed from network!');
        }),
        map(removeUserFromTabEntityAsync.success),
        catchError(error => handleError(error, removeUserFromTabEntityAsync)),
      ),
    ),
  );

export default {
  editTabEntityCircleEpic,
  removeUserFromTabEntityEpic,
  deleteTabEntityCircleEpic,
  saveTabEntityCircleEpic,
  saveTabEpic,
  fetchCommunityTabsEpic,
  deleteTabEpic,
  fetchTabByIdEpic,
  fetchTabEntitiesByIdEpic,
  followOrUnFollowEntityEpic,
  inviteTabEntityMembersEpic,
  fetchCommunityTabEntityCirclesEpic,
  fetchCommunityTabEntityCircleMembersEpic,
  removeInviteTabEntityMemberEpic,
};
