import { Section, Assignment, Task, CreateTaskPayload, Methodology } from 'redux/types/account';
import { map, filter, clone, sort } from 'ramda';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { mentionParse } from '../../util/mention';

import { Planner, Privacy } from '../../redux/types/enums';
import { getAxiosInstance } from './helper';

const ai = getAxiosInstance();

export function fetchMethodologyContent(
  bearer: string,
  communityId: number,
  methodologyId: number,
): Promise<{ communityId: number; methodologyId: number; sections: Section[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/MethodologyContent/GetMethodology',
      params: {
        id: methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId: communityId,
          methodologyId: methodologyId,
          sections: map(
            (s: any) => {
              return {
                id: s.id,
                name: s.name,
                order: s.order,
                logo: s.logo,
                methodologyId: s.methodologyId,
                isLean: s.isLean,
                isActive: s.isActive,
                isStagegated: s.isStagegated,
                assignments: map(
                  (a: any) => {
                    return {
                      id: a.id,
                      name: a.name,
                      order: a.order,
                      privacyLevel: a.privacyLevel,
                      methodologyContentCircleList: a.methodologyContentCircleList,
                      details: mentionParse(a.infoText),
                      description: mentionParse(a.wikiReferences),
                      template: mentionParse(a.defaultContent),
                      methodologyId: methodologyId,
                      sectionId: s.id,
                      feedbackText: a.feedbackText,
                      wikiLinks: a.wikiLinks,
                      wikiProblem: a.wikiProblem,
                      isHillary: a.isHillary,
                      inTutorial: a.inTutorial,
                      sectionName: s.name,
                      taskSendReminder: a.taskSendReminder,
                    };
                  },
                  filter(
                    (d: any) => d.isActive === true,
                    s.methodologyParagraphs.sort((a: any, b: any) => {
                      if (a.order > b.order) return 1;
                      if (a.order < b.order) return -1;
                      return 0;
                    }),
                  ),
                ),
              };
            },
            filter((d: any) => d.isActive === true, data),
          ).sort((a: any, b: any) => a.order - b.order),
        });
      })
      .catch(err => reject(err));
  });
}

export function reorderMethodologySections(
  bearer: string,
  newSectionsOrder: { id: number; order: number }[],
  methodologyId: number,
  communityId: number,
): Promise<{ newSectionsOrder: { id: number; order: number }[]; methodologyId: number; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/MethodologyContent/UpdateSectionsOrder/methodology/${methodologyId}`,
      data: newSectionsOrder,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          newSectionsOrder,
          methodologyId,
          communityId,
        });
      })
      .catch(err => reject(err));
  });
}

export function reorderMethodologyAssignments(
  bearer: string,
  newAssignmentsOrder: { id: number; order: number }[],
  communityId: number,
  methodologyId: number,
): Promise<{
  newAssignmentsOrder: { id: number; order: number }[];
  communityId: number;
  methodologyId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `api/MethodologyContent/UpdateParagraphsOrder/Methodology/${methodologyId}`,
      data: newAssignmentsOrder,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          newAssignmentsOrder,
          communityId,
          methodologyId,
        });
      })
      .catch(err => reject(err));
  });
}

function _saveMethodologySection(
  bearer: string,
  section: { id: number; methodologyId: number; name: string; logo: string; isStagegated?: boolean },
): Promise<Section> {
  return new Promise((resolve, reject) => {
    if (section.isStagegated === undefined) {
      section.isStagegated = false;
    }
    ai({
      method: 'POST',
      url: '/api/MethodologyContent/SaveSection',
      data: section,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          ...section,
          ...data,
        });
      })
      .catch(err => reject(err));
  });
}

async function _saveMultipleMethodologyAssignments(bearer: string, assignments: Assignment[]) {
  const createdAssignments: Assignment[] = [] as Assignment[];
  for (let i = 0; i < assignments.length; i++) {
    const currentAssignment = await updateMethodologyAssignment(bearer, { ...assignments[i] }, 0);
    createdAssignments.push(currentAssignment.assignment);
  }
  return createdAssignments;
}

async function _saveMultipleMethodologySections(
  bearer: string,
  sections: { id: number; methodologyId: number; name: string; logo: string; isStagegated?: boolean }[],
) {
  const createdSections: Section[] = [] as Section[];
  for (let i = 0; i < sections.length; i++) {
    const answer = await _saveMethodologySection(bearer, sections[i]);
    createdSections.push(answer);
  }
  return createdSections;
}

export function saveMethodologySection(
  bearer: string,
  communityId: number,
  newSection: { id: number; isLean: boolean; methodologyId: number; name: string; methodology?: any },
  currentSectionsOrder: { id: number; order: number }[],
  sectionIndex: number,
  isUpdate: boolean,
): Promise<{ communityId: number; section: Section; sectionIndex: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/MethodologyContent/SaveSection',
      data: newSection,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        if (!isUpdate) {
          const newSectionsOrder = clone(currentSectionsOrder);
          newSectionsOrder.splice(sectionIndex, 0, { id: data.id, order: sectionIndex });
          reorderMethodologySections(
            bearer,
            newSectionsOrder.map((section, index: number) => {
              return {
                id: section.id,
                order: index,
              };
            }),
            newSection.methodologyId,
            communityId,
          )
            .then(r => {
              resolve({
                communityId: communityId,
                section: {
                  id: data.id,
                  name: data.name,
                  order: data.order,
                  logo: data.logo,
                  methodologyId: data.methodologyId,
                  isLean: data.isLean,
                  assignments: [],
                },
                sectionIndex: sectionIndex,
              });
            })
            .catch(err => reject(err));
        } else {
          resolve({
            communityId: communityId,
            section: { ...newSection, order: 0, logo: data.logo, assignments: [] },
            sectionIndex: sectionIndex,
          });
        }
      })
      .catch(err => reject(err));
  });
}

export function updateMethodologyAssignment(
  bearer: string,
  newAssignment: Assignment,
  communityId: number,
): Promise<{ communityId: number; assignment: Assignment }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/MethodologyContent/SaveParagraph',
      data: {
        id: newAssignment.id,
        name: newAssignment.name,
        order: newAssignment.order,
        infoText: newAssignment.details,
        defaultContent: newAssignment.template,
        wikiReferences: newAssignment.description,
        methodologySectionId: newAssignment.sectionId,
        methodologyId: newAssignment.methodologyId,
        feedbackText: newAssignment.feedbackText,
        wikiLinks: newAssignment.wikiLinks,
        wikiProblem: newAssignment.wikiProblem,
        isHillary: newAssignment.isHillary,
        inTutorial: newAssignment.inTutorial,
        privacyLevel: newAssignment.privacyLevel,
        teamCanChoosePrivacy: newAssignment.privacyLevel === Privacy.TeamCanChoose,
        methodologyContentCircleList: newAssignment.methodologyContentCircleList,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId: communityId,
          assignment: {
            id: data.id,
            name: data.name,
            order: data.order,
            description: mentionParse(data.wikiReferences),
            details: mentionParse(data.infoText),
            template: mentionParse(data.defaultContent),
            sectionId: data.methodologySectionId,
            methodologyId: data.methodologyId,
            isHillary: newAssignment.isHillary,
            taskSendReminder: data.taskSendReminder,
            privacyLevel: data.privacyLevel,
            methodologyContentCircleList: data.methodologyContentCircleList,
          },
        });
      })
      .catch(err => reject(err));
  });
}

function _deleteMethodologySection(bearer: string, sectionId: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/MethodologyContent/RemoveSection',
      params: {
        sectionId: sectionId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve(true))
      .catch(err => reject(err));
  });
}

async function _deleteMultipleMethodologySections(bearer: string, sectionIds: number[]): Promise<boolean> {
  for (let i = 0; i < sectionIds.length; i++) {
    await _deleteMethodologySection(bearer, sectionIds[i]);
  }
  return true;
}

export function deleteMethodologySection(
  bearer: string,
  section: Section,
  communityId: number,
): Promise<{ section: Section; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/MethodologyContent/RemoveSection',
      params: {
        sectionId: section.id,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId: communityId,
          section: section,
        });
      })
      .catch(err => reject(err));
  });
}

function _deleteMethodologyAssignment(bearer: string, assignmentId: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/MethodologyContent/RemoveParagraph',
      params: {
        paragraphId: assignmentId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve(true))
      .catch(err => reject(err));
  });
}

async function _deleteMultipleMethodologyAssignments(bearer: string, assignmentIds: number[]) {
  for (let i = 0; i < assignmentIds.length; i++) {
    await _deleteMethodologyAssignment(bearer, assignmentIds[i]);
  }
  return true;
}

export function deleteMethodologyAssignment(
  bearer: string,
  assignment: Assignment,
  communityId: number,
): Promise<{ assignment: Assignment; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/MethodologyContent/RemoveParagraph',
      params: {
        paragraphId: assignment.id,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId: communityId,
          assignment: assignment,
        });
      })
      .catch(err => reject(err));
  });
}

export function saveMethodologyAssignment(
  bearer: string,
  newAssignment: {
    id: number;
    name: string;
    description?: string;
    details?: string;
    template?: string;
    wikiLinks?: string;
    wikiProblem?: string;
    feedbackText?: string;
    inTutorial?: boolean;
    privacyLevel?: number;
    methodologyContentCircleList?: {
      communityCircleId: number;
      projectCircleType?: string;
    };
    isActive: true;
    methodologyId: number;
    methodologySectionId: number;
    isReadingAssignment: boolean;
  },
  communityId: number,
  currentAssignmentsOrder: { id: number; order: number }[],
  assignmentIndex: number,
): Promise<{ communityId: number; assignment: Assignment; assignmentIndex: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/MethodologyContent/SaveParagraph',
      data: {
        isHillary: newAssignment.isReadingAssignment,
        id: newAssignment.id,
        name: newAssignment.name,
        wikiReferences: newAssignment.description,
        infoText: newAssignment.details,
        defaultContent: newAssignment.template,
        isActive: newAssignment.isActive,
        wikiLinks: newAssignment.wikiLinks,
        wikiProblem: newAssignment.wikiProblem,
        feedbackText: newAssignment.feedbackText,
        inTutorial: newAssignment.inTutorial,
        methodologyId: newAssignment.methodologyId,
        methodologySectionId: newAssignment.methodologySectionId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const newAssignmentsOrder = clone(currentAssignmentsOrder);
        reorderMethodologyAssignments(
          bearer,
          newAssignmentsOrder.map((a, index: number) => {
            return {
              id: a.id,
              order: index,
            };
          }),
          communityId,
          newAssignment.methodologyId,
        )
          .then(r => {
            const { newAssignmentsOrder } = r;
            const { order } = newAssignmentsOrder.find(({ id }) => id === data.id) || data;
            resolve({
              communityId: communityId,
              assignment: {
                id: data.id,
                name: data.name,
                order: order,
                description: mentionParse(data.wikiReferences),
                details: mentionParse(data.infoText),
                template: mentionParse(data.defaultContent),
                sectionId: data.methodologySectionId,
                methodologyId: data.methodologyId,
                isHillary: data.isHillary,
                taskSendReminder: data.taskSendReminder,
                privacyLevel: data.privacyLevel,
                methodologyContentCircleList: data.methodologyContentCircleList,
              },
              assignmentIndex: assignmentIndex,
            });
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
  });
}

export function fetchMethodologyPlanningAndProjects(
  bearer: string,
  communityId: number,
  methodologyId: number,
): Promise<{
  communityId: number;
  methodologyId: number;
  planner: { type: Planner; tasks: Task[] };
  usingProjects: { projectId: number; projectName: string }[];
  isRetroactive: boolean;
}> {
  return new Promise((resolve, reject) => {
    Promise.all([
      fetchMethodologyPlanning(bearer, communityId, methodologyId),
      fetchMethodologyProjects(bearer, methodologyId),
    ])
      .then(responses => {
        resolve({ ...responses[0], usingProjects: responses[1] });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchMethodologyProjects(
  bearer: string,
  methodologyId: number,
): Promise<{ projectId: number; projectName: string }[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Project/GetByMethodology`,
      params: {
        methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(
          response.data.map((entry: any): { projectId: number; projectName: string } => {
            return {
              projectId: entry.id,
              projectName: entry.name,
            };
          }),
        );
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchMultipleMethodologyProjects(
  bearer: string,
  methodologyIds: number[],
): Promise<{ projectId: number; projectName: string; methodologyId: number }[]> {
  return new Promise((resolve, reject) => {
    Promise.all(methodologyIds.map((methodologyId: number) => fetchMethodologyProjects(bearer, methodologyId)))
      .then((responses: { projectId: number; projectName: string }[][]) => {
        const resolveResponse = responses
          .map((response: { projectId: number; projectName: string }[], index: number) =>
            response.map((singleProject: { projectId: number; projectName: string }) => ({
              projectId: singleProject.projectId,
              projectName: singleProject.projectName,
              methodologyId: methodologyIds[index],
            })),
          )
          .reduce(
            (
              acc: { projectId: number; projectName: string; methodologyId: number }[],
              val: { projectId: number; projectName: string; methodologyId: number }[],
            ) => [...acc, ...val],
            [],
          );
        resolve(resolveResponse);
      })
      .catch(err => reject(err));
  });
}

export function fetchMethodologyPlanning(
  bearer: string,
  communityId: number,
  methodologyId: number,
): Promise<{
  communityId: number;
  methodologyId: number;
  planner: { type: Planner; tasks: Task[] };
  isRetroactive: boolean;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/MethodologyTask/GetAll`,
      params: {
        methodologyId: methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const tasks = map(
          (task: any) => ({
            id: task.id,
            startDate: task.dateStart,
            publishDate: task.datePublish,
            endDate: task.dateEnd,
            assignmentId: task.id,
            order: task.order,
            taskSendReminder: task.taskSendReminder,
          }),
          response.data.tasks,
        );
        const sortedTasks = sort((a: any, b: any) => {
          if (moment(a.startDate).isBefore(moment(b.startDate))) return -1;
          if (moment(a.startDate).isAfter(moment(b.startDate))) return 1;
          return 0;
        }, tasks);
        resolve({
          communityId,
          methodologyId,
          isRetroactive: response.data.isRetroactive,
          planner: {
            type: response.data.taskUnlockRule,
            tasks: sortedTasks,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function createTask(
  bearer: string,
  communityId: number,
  methodologyId: number,
  tasks: CreateTaskPayload[],
): Promise<{
  communityId: number;
  methodologyId: number;
  tasks: Task[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/MethodologyTask/SaveMultiple`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tasks.map(task => {
        return {
          ...task,
          methodologyId,
          isParagraph: true,
          sendReminder: task.taskSendReminder === undefined ? true : task.taskSendReminder,
          id: task.assignmentId,
        };
      }),
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          methodologyId,
          tasks: tasks.map((task: CreateTaskPayload, index: number) => ({
            id: response.data[index] || 0,
            startDate: task.dateStart,
            endDate: task.dateEnd,
            publishDate: task.datePublish,
            assignmentId: response.data[index] || 0,
            order: task.order || 0,
            taskSendReminder: task.taskSendReminder || true,
          })),
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

type UpdateTaskPayload = {
  id: number;
  assignmentId: number;
  dateStart: string;
  datePublish: string;
  dateEnd: string;
  taskSendReminder?: boolean;
};

// updateTasks is not used anymore, createTask function is prefered as
// it uses the SaveMultiple endpoint instead of multiple Save requests
export function updateTasks(
  bearer: string,
  communityId: number,
  methodologyId: number,
  tasks: UpdateTaskPayload[],
): Promise<{
  communityId: number;
  methodologyId: number;
  tasks: Task[];
}> {
  return new Promise((resolve, reject) => {
    Promise.all(
      tasks.map(
        task =>
          new Promise((resolve, reject) =>
            ai({
              method: 'POST',
              url: `/api/MethodologyTask/Save`,
              headers: {
                Authorization: `Bearer ${bearer}`,
              },
              data: {
                communityId,
                ...task,
                isParagraph: true,
                taskSendReminder: task.taskSendReminder || false,
                methodologyId,
                methodologyParagraphId: task.assignmentId,
              },
            })
              .then(() => {
                resolve({
                  startDate: task.dateStart,
                  endDate: task.dateEnd,
                  publishDate: task.datePublish,
                  assignmentId: task.assignmentId,
                  id: task.id,
                  taskSendReminder: true,
                } as Task);
              })
              .catch(err => {
                reject(err);
              }),
          ),
      ),
    )
      .then(tasks => {
        resolve({
          communityId,
          methodologyId,
          tasks: tasks as Task[],
        });
      })
      .catch(err => reject(err));
  });
}

export function updateTasksOrder(
  bearer: string,
  communityId: number,
  methodologyId: number,
  tasks: Task[],
): Promise<{
  communityId: number;
  methodologyId: number;
  tasks: Task[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/MethodologyTask/UpdateOrder`,
      params: {
        methodologyId: methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tasks.map((t: Task) => {
        return { id: t.id, order: t.order };
      }),
    })
      .then(() => {
        resolve({
          communityId,
          methodologyId,
          tasks,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function deleteTasks(
  bearer: string,
  communityId: number,
  methodologyId: number,
  taskIds: number[],
): Promise<{ communityId: number; methodologyId: number; taskIds: number[] }> {
  return new Promise((resolve, reject) => {
    Promise.all(taskIds.map((taskId: number) => deleteTask(bearer, communityId, methodologyId, taskId)))
      .then(() => {
        resolve({
          communityId,
          methodologyId,
          taskIds,
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteTask(
  bearer: string,
  communityId: number,
  methodologyId: number,
  taskId: number,
): Promise<{ communityId: number; methodologyId: number; taskId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/MethodologyTask/Remove`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: taskId,
      },
    })
      .then(() => resolve({ taskId, methodologyId, communityId }))
      .catch(err => reject(err));
  });
}

export function setTaskUnlockRule(
  bearer: string,
  communityId: number,
  methodologyId: number,
  taskUnlockRule: Planner,
): Promise<{ communityId: number; methodologyId: number; taskUnlockRule: Planner }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Methodology/UpdateTaskUnLockRule/`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        taskUnlockRule: taskUnlockRule,
        methodologyId: methodologyId,
      },
    })
      .then(() =>
        resolve({
          taskUnlockRule,
          communityId,
          methodologyId,
        }),
      )
      .catch(err => reject(err));
  });
}

export function disableMethodology(
  bearer: string,
  communityId: number,
  methodologyId: number,
): Promise<{
  communityId: number;
  methodologyId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Methodology/Disable',
      params: {
        id: methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() =>
        resolve({
          communityId,
          methodologyId,
        }),
      )
      .catch(err => reject(err));
  });
}

export function deleteMethodology(
  bearer: string,
  communityId: number,
  methodologyId: number,
): Promise<{
  communityId: number;
  methodologyId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/Methodology/${communityId}/${methodologyId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() =>
        resolve({
          communityId,
          methodologyId,
        }),
      )
      .catch(err => reject(err));
  });
}

export function enableMethodology(
  bearer: string,
  communityId: number,
  methodologyId: number,
): Promise<{
  communityId: number;
  methodologyId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Methodology/Enable',
      params: {
        id: methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() =>
        resolve({
          communityId,
          methodologyId,
        }),
      )
      .catch(err => reject(err));
  });
}

export async function saveSectionsStagegate(
  bearer: string,
  communityId: number,
  methodologyId: number,
  sections: Section[],
) {
  const createdSections: Section[] = await _saveMultipleMethodologySections(
    bearer,
    sections.map((section: Section) => ({
      id: section.id,
      name: section.name,
      logo: section.logo,
      isStagegated: section.isStagegated,
      methodologyId: section.methodologyId,
      order: section.order,
    })),
  );
  return {
    communityId,
    methodologyId,
    sections: createdSections,
  };
}

export async function saveMethodologyDiff(
  bearer: string,
  communityId: number,
  oldMethodology: Methodology,
  newMethodology: Methodology,
) {
  const reduceAssignments = (acc: Assignment[], val: Section) => [...acc, ...val.assignments];
  let newAssignments = newMethodology.sections.reduce(reduceAssignments, []);
  let oldAssignments = oldMethodology.sections.reduce(reduceAssignments, []);

  // step 1, 2: delete old sections and assignments that are no longer available
  const sectionsToDelete = oldMethodology.sections
    .filter((section: Section) => !newMethodology.sections.find((newSection: Section) => newSection.id === section.id))
    .map((section: Section) => section.id);
  const assignmentsToDelete = oldAssignments
    .filter(
      (assignment: Assignment) =>
        !newAssignments.find((newAssignment: Assignment) => newAssignment.id === assignment.id),
    )
    .map((assignment: Assignment) => assignment.id);
  await _deleteMultipleMethodologySections(bearer, sectionsToDelete);
  await _deleteMultipleMethodologyAssignments(bearer, assignmentsToDelete);
  // Step 3: Create all sections
  const sectionsToCreate = newMethodology.sections.filter((section: Section) => section.id < 0);
  const createdSections: Section[] = await _saveMultipleMethodologySections(
    bearer,
    sectionsToCreate.map((section: Section) => ({ ...section, id: 0 })),
  );
  let error = false;
  newMethodology = {
    ...newMethodology,
    sections: newMethodology.sections.map((section: Section) => {
      if (section.id <= 0) {
        const createdIndex = sectionsToCreate.findIndex(
          (sectionToCreate: Section) => sectionToCreate.id === section.id,
        );
        if (createdIndex < 0) {
          error = true;
          return section;
        }
        return {
          ...section,
          id: createdSections[createdIndex].id,
          assignments: section.assignments.map((assignment: Assignment) => ({
            ...assignment,
            sectionId: createdSections[createdIndex].id,
          })),
        };
      }
      return section;
    }),
  };
  newAssignments = newMethodology.sections.reduce(reduceAssignments, []);
  oldAssignments = oldMethodology.sections.reduce(reduceAssignments, []);

  // step 3.2: Save edited methodology sections
  const sectionsToEdit = newMethodology.sections.filter((section: Section) => {
    const oldSection = oldMethodology.sections.find((oldSection: Section) => oldSection.id === section.id);
    if (oldSection) {
      if (oldSection.name !== section.name || oldSection.logo !== section.logo) {
        return true;
      }
    }
    return false;
  });

  await _saveMultipleMethodologySections(bearer, sectionsToEdit);
  // Step 4: Create all assignments
  const assignmentsToCreate = newMethodology.sections
    .reduce(reduceAssignments, [])
    .filter((assignment: Assignment) => assignment.id < 0);
  const createdAssignments: Assignment[] = await _saveMultipleMethodologyAssignments(
    bearer,
    assignmentsToCreate.map((assignment: Assignment) => ({ ...assignment, id: 0 })),
  );
  newMethodology = {
    ...newMethodology,
    sections: newMethodology.sections.map((section: Section) => ({
      ...section,
      assignments: section.assignments.map((assignment: Assignment) => {
        if (assignment.id < 0) {
          const createdIndex = assignmentsToCreate.findIndex(
            (assignmentToCreate: Assignment) => assignmentToCreate.id === assignment.id,
          );
          if (createdIndex < 0) {
            error = true;
            return assignment;
          }
          return {
            ...assignment,
            id: createdAssignments[createdIndex].id,
          };
        }
        return assignment;
      }),
    })),
  };

  newAssignments = newMethodology.sections.reduce(reduceAssignments, []);
  oldAssignments = oldMethodology.sections.reduce(reduceAssignments, []);

  const assignmentsToEdit = newAssignments.filter((assignment: Assignment) => {
    const oldAssignment = oldAssignments.find((oldAssignment: Assignment) => oldAssignment.id === assignment.id);

    if (oldAssignment) {
      if (
        oldAssignment.name !== assignment.name ||
        oldAssignment.details !== assignment.details ||
        oldAssignment.description !== assignment.description ||
        oldAssignment.template !== assignment.template ||
        oldAssignment.sectionId !== assignment.sectionId ||
        oldAssignment.privacyLevel !== assignment.privacyLevel ||
        oldAssignment.methodologyContentCircleList !== assignment.methodologyContentCircleList
      ) {
        return true;
      }
    }
    return false;
  });
  // step 4.2: save edited assignments
  await _saveMultipleMethodologyAssignments(bearer, assignmentsToEdit);
  const sectionsToReorder = newMethodology.sections.map((section: Section, index: number) => ({
    id: section.id,
    order: index,
  }));
  // step 5: reorder sections
  const reorderedSections = await reorderMethodologySections(bearer, sectionsToReorder, newMethodology.id, 0);
  const assignmentsToReorder = newMethodology.sections
    .reduce((acc: Assignment[], val: Section) => [...acc, ...val.assignments], [])
    .map((assignment: Assignment, index: number) => ({ id: assignment.id, order: index }));
  // step 6: reorder assignments
  const reorderedAssignments = await reorderMethodologyAssignments(bearer, assignmentsToReorder, 0, newMethodology.id);
  if (
    reorderedSections.newSectionsOrder.length !== sectionsToReorder.length ||
    reorderedAssignments.newAssignmentsOrder.length !== assignmentsToReorder.length
  ) {
    error = true;
  }
  if (error) {
    console.error('Error saving methodology diff');
  }
  return {
    communityId,
    methodology: newMethodology,
  };
}

export default {
  fetchMethodologyContent,
  reorderMethodologySections,
  reorderMethodologyAssignments,
  saveMethodologySection,
  updateMethodologyAssignment,
  deleteMethodologySection,
  deleteMethodologyAssignment,
  saveMethodologyAssignment,
  fetchMethodologyPlanning,
  fetchMethodologyPlanningAndProjects,
  createTask,
  updateTasks,
  updateTasksOrder,
  deleteTasks,
  deleteTask,
  setTaskUnlockRule,
  disableMethodology,
  enableMethodology,
  saveMethodologyDiff,
  saveSectionsStagegate,
  deleteMethodology,
};
