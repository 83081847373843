import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

const RouteChangeTracker = () => {
  const history = useHistory();
  history.listen((location, action) => {
    ReactGA4.set({ page: location.pathname + location.search });
    ReactGA4.event({
      category: 'Pageview',
      action: 'Pageview',
      value: location.pathname + location.search,
    });
    ReactGA4.pageview(location.pathname);
  });
  return <div></div>;
};
export default RouteChangeTracker;
