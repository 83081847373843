import { Z_INDEX_STACK } from '../../constants';
import styled from 'styled-components';

export const ModalStyled = styled('div')<any>`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX_STACK.MODAL};
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
`;

export const BackdropStyled = styled('div')<any>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX_STACK.BACKDROP};
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  backdrop-filter: ${({ blur }) => (blur ? 'blur(3px) brightness(95%)' : '')};
`;
