import React from 'react';
import CommunitySidebar from '../community/sidebar';
import ProjectSidebar from '../project/sidebar';
import BackSidebar from '../common/back-sidebar';
import { useLocation } from 'react-router-dom';
import { startsWith, endsWith } from 'ramda';
import useWindowDimensions from '../../util/windowSize';
import FullWidthCommunitySidebar from './fullWidthCommunitySidebar';
import FullWidthProjectSidebar from './fullWidthProjectSidebar';
import SuperAdminSidebar from '../super-admin/sidebar';
import CommunitySettingsSidebar from '../community/settings-sidebar';
import ProjectSettingsSidebar from '../project/settings-sidebar';
import { LayoutType } from 'redux/types/account';

interface Props {
  style?: React.CSSProperties;
  type: LayoutType;
  shrinked?: boolean;
}

const Sidebar: React.FC<Props> = props => {
  const { style } = props;
  const location = useLocation();
  const windowSize = useWindowDimensions();
  const isOfType = (...types: LayoutType[]) => {
    return types.includes(props.type);
  };
  if (isOfType('settings')) {
    if (startsWith('/community', location.pathname)) return <CommunitySettingsSidebar />;
    if (startsWith('/project', location.pathname)) return <ProjectSettingsSidebar />;
    return null;
  }
  if (windowSize.isMobile) {
    if (startsWith('/super-admin', location.pathname)) return <SuperAdminSidebar />;
    if (startsWith('/community', location.pathname)) return <FullWidthCommunitySidebar />;
    if (startsWith('/project', location.pathname)) return <FullWidthProjectSidebar />;
    return null;
  } else {
    if (startsWith('/super-admin', location.pathname)) return <SuperAdminSidebar />;
    if (startsWith('/community', location.pathname))
      return <CommunitySidebar shrinked={props.shrinked} style={style} />;
    if (startsWith('/project', location.pathname)) {
      if (endsWith('progress', location.pathname)) return <BackSidebar />;
      else return <ProjectSidebar shrinked={props.shrinked} />;
    }
    return null;
  }
};

export default Sidebar;
