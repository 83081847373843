import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import Button from 'primitives/Button';
import Modal from 'primitives/Modal';
import Stack from 'primitives/Stack';
import { useDispatch, useSelector, connect } from 'react-redux';
import {
  createNewRequestAsync,
  fetchRequestsPendingFeedbackAsync,
  submitPendingFeedbackAsync,
} from 'redux/actions/request';
import { RootState } from 'StoreModel';
import accountRequestsPendingFeedbackSelector from 'redux/selectors/account/accountRequestsPendingFeedbackSelector';
import submitPendingFeedbackFlagSelector from 'redux/selectors/loading/submitPendingFeedbackFlagSelector';
import isFlagRequest from 'util/predicates/isFlagRequest';
import { Contents, StyledContainer } from './style';
import useClickAway from 'hooks/useClickAway';
import GiveFeedbackForm from 'components/common/give-feedback-form';
import accountSessionBearerSelector from 'redux/selectors/account/accountSessionBearerSelector';
import isRequestAdvisor from 'util/predicates/isRequestAdvisor';
import accountSelector from 'redux/selectors/account/accountSelector';
import { getProjectById } from 'redux/selectors/account';
import accountProjectsSelector from 'redux/selectors/account/accountProjectsSelector';
import usePathParams from 'hooks/usePathParams';
import { useLocation } from 'react-router-dom';
import literalsSelector from 'redux/selectors/literals';
import { Circle, Member, Request } from 'redux/types/account';

const mapStateToProps = (state: RootState) => ({
  selectedCommunity: state.account.selectedCommunity,
});

const mapDispatchToProps = {
  createNewRequest: createNewRequestAsync.request,
};

type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

function RequestsPendingFeedbackPopup({ selectedCommunity }: Props) {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const literals = useSelector(literalsSelector);
  const bearer = useSelector(accountSessionBearerSelector);
  const submitPendingFeedbackFlag = useSelector(submitPendingFeedbackFlagSelector);
  const requestsPendingFeedback = useSelector(accountRequestsPendingFeedbackSelector);
  const isPendingFeedbackOpen = isFlagRequest(submitPendingFeedbackFlag);
  const account = useSelector(accountSelector);
  const [request, setRequest] = useState<any>(null);
  const projects = useSelector(accountProjectsSelector);
  const { pathname } = useLocation();
  const { projectId } = usePathParams(pathname);
  const project = getProjectById(projects, projectId);
  const pendingRequestsForProject = (requestsPendingFeedback?.filter(
    (request: any) => request.projectId === projectId,
  ) as unknown) as Request[] | undefined;
  const mentorCircle = useMemo(() => (project?.circles || []).find((c: Circle) => c.name === 'Mentors'), [
    project?.circles,
  ]);

  const currentUserMember = useMemo(() => (project?.members || []).find((m: Member) => m.id === account.id), [
    project?.members,
  ]);

  const isProjectMentorCircle = mentorCircle?.id === currentUserMember?.circle?.id;
  const canCoachMembers =
    isProjectMentorCircle ||
    (selectedCommunity && selectedCommunity.permissions?.permissionCanCoachTeamMembers) ||
    selectedCommunity?.isCommunityAdmin;

  const handlePendingFeedbackClose = () => {
    setRequest(null);
    dispatch(submitPendingFeedbackAsync.cancel(null));
  };

  const handleSubmitFeedback = useCallback(
    key => {
      setRequest(pendingRequestsForProject ? pendingRequestsForProject[key] : null);
    },
    [pendingRequestsForProject, dispatch],
  );

  // otherwise redirect to new request
  // if there are still requests pending feeedback, re-do process
  const handleGiveSingleFeedback = useCallback(() => {
    dispatch(submitPendingFeedbackAsync.request({ project }));
    dispatch(fetchRequestsPendingFeedbackAsync.request());
  }, [dispatch, requestsPendingFeedback, projectId, project]);

  const handleCreateNewRequest = useCallback(() => {
    handlePendingFeedbackClose();
    dispatch(
      createNewRequestAsync.request({
        projectId: project?.id,
        isProjectAdmin: project?.isProjectAdmin || canCoachMembers,
      }),
    );
  }, [handlePendingFeedbackClose, dispatch, project?.id, project?.isProjectAdmin, canCoachMembers]);

  useEffect(() => {
    dispatch(fetchRequestsPendingFeedbackAsync.request());
  }, [dispatch]);

  useClickAway({ el: containerRef, onClickAway: handlePendingFeedbackClose });

  return (
    <Modal
      blur={false}
      backdrop={true}
      backdropStyle={{
        backgroundColor: '#272c36',
      }}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
      }}
      open={isPendingFeedbackOpen}
      onClose={handlePendingFeedbackClose}
    >
      <Contents ref={containerRef}>
        {request && (
          <StyledContainer>
            <GiveFeedbackForm
              onSubmit={handleGiveSingleFeedback}
              closeModal={handlePendingFeedbackClose}
              userId={account.details.user.id}
              bearer={bearer}
              request={request}
            />
          </StyledContainer>
        )}
        {!request && (
          <StyledContainer>
            {pendingRequestsForProject?.length ? (
              <>
                <h3>{literals.request_pending_feedback_required_title}</h3>
                <p>
                  {literals.request_pending_feedback_required_body.replace('{0}', pendingRequestsForProject?.length)}
                </p>
              </>
            ) : (
              <>
                <h3>{literals.request_pending_feedback_allowed_title}</h3>
                <p>{literals.request_pending_feedback_allowed_body}</p>
              </>
            )}

            {!!pendingRequestsForProject?.length && (
              <Stack
                style={{
                  maxHeight: '108px',
                  margin: '20px 0',
                  overflowY: 'auto',
                  minHeight: '32px',
                  padding: '8px',
                  gap: '8px',
                }}
              >
                {pendingRequestsForProject?.map((val: any, i) => (
                  <Stack style={{ justifyContent: 'space-between' }} direction="row" key={i}>
                    <div
                      style={{
                        height: '26px',
                        overflow: 'hidden',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      <strong>&nbsp;&bull;&nbsp;{val.name}</strong>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          handleSubmitFeedback(i);
                        }}
                      >
                        {literals.global_submit}
                      </Button>
                    </div>
                  </Stack>
                ))}
              </Stack>
            )}
            <Stack direction="row" spacing={10} style={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handlePendingFeedbackClose}>
                {literals.community_edit_cancel_button}
              </Button>
              <Button disabled={!!pendingRequestsForProject?.length} onClick={handleCreateNewRequest}>
                {literals.requests_coaching_new_session}
              </Button>
            </Stack>
          </StyledContainer>
        )}
      </Contents>
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestsPendingFeedbackPopup);
