import React from 'react';
import styled from 'styled-components';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { Dimmer, Loader } from 'semantic-ui-react';
import Image from '../common/lazyImage';
import { CommunityProgressOverview, ProjectProgressOverview } from 'redux/types/account';
import { Flag } from '../../redux/types/enums';
import defaultProjectPicture from '../../assets/common/project-placeholder.svg';

const Card = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 0.5em;
  padding: 1em;
  margin-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a,
  span {
    font-size: 0.9em;
  }
`;

const Header = styled.h3`
  width: 100%;
  font-size: 1.3em;
  line-height: 1.3em;
  margin-bottom: 1em;
`;

const CustomLink = styled(Link)`
  &&& {
    color: ${({ theme }) => theme.colors.blue.normal};
    font-size: 0.83em;
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 17em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryHeader = styled.h4`
  margin-top: 0;
  font-size: 0.71em;
  color: ${props => props.theme.colors.grey.dark};
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-bottom: 1em;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.5em;
  width: 100%;
  align-items: center;
`;

const ProgressImage = styled(Image)`
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
`;

const TitleWrapper = styled.div`
  display: flex;
  font-size: 0.83em;
  font-weight: 500;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
  & :hover {
    cursor: pointer;
  }
`;

const Progress = styled.div<{ slow?: boolean; regular?: boolean }>`
  margin-left: 0.5em;
  display: flex;
  justify-content: flex-end;
  font-size: 0.83em;
  width: 10%;
  color: ${props => {
    if (props.slow) return props.theme.colors.orange.light;
    else if (props.regular) return props.theme.colors.grey.dark;
    else return props.theme.colors.green.normal;
  }};
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
`;

const WithoutComments = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 1em;
  color: ${props => props.theme.colors.grey.medium};
  font-size: 0.9375em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const mapStateToProps = (state: RootState) => ({
  isFetchingProgress: state.loading.fetchCommunityProgressTrackingFlag,
  literals: state.literals,
});

const mapLiteralsToProps = (state: RootState) => ({
  literals: state.literals,
});

const mapDispatchToProps = {};

interface ProgressItemProps extends ReturnType<typeof mapLiteralsToProps> {
  slow?: boolean;
  regular?: boolean;
  progress: ProjectProgressOverview;
}

export const _ProgressItem: React.FC<ProgressItemProps> = ({ slow, regular, progress, literals }) => {
  const location = useLocation();
  const isOverviewProjects: boolean = location.pathname.includes('overview-projects');
  const history = useHistory();
  const goToProject = () => {
    history.push(`/project/${progress.projectId}/progress`);
  };
  return (
    <ProgressWrapper>
      <TitleWrapper title={progress.projectName} style={isOverviewProjects ? { width: '50%' } : { width: '80%' }}>
        <ProgressImage
          customSize={'32x32'}
          src={progress.projectLogo}
          onClick={goToProject}
          onError={(event: any) => (event.target.src = defaultProjectPicture)}
        />
        <span onClick={goToProject}>{progress.projectName}</span>
      </TitleWrapper>
      <Progress slow={slow} regular={regular}>
        {progress.progress}%
      </Progress>
      {progress.paragraphsWithoutComments ? (
        <WithoutComments
          title={(literals.community_progress_without_comments_title || '').replace(
            '{0}',
            progress.paragraphsWithoutComments,
          )}
        >
          {literals.community_progress_without_comments_title.replace('{0}', progress.paragraphsWithoutComments)}
        </WithoutComments>
      ) : null}
    </ProgressWrapper>
  );
};

export const ProgressItem = connect(mapLiteralsToProps, {})(_ProgressItem);

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  progress: CommunityProgressOverview;
}

const CommunityProgressPanel: React.FC<Props> = ({ progress, isFetchingProgress, literals }) => {
  const { communityId } = useParams<{ communityId: string }>();

  return progress?.nonDatedTasks.length > 0 || progress?.regular.length > 0 || progress?.slow.length > 0 ? (
    <Card>
      {isFetchingProgress === Flag.Request || progress === undefined ? (
        <LoaderContainer>
          <Dimmer inverted active>
            <Loader inverted>{literals.project_list_loading_tag_message}</Loader>
          </Dimmer>
        </LoaderContainer>
      ) : (
        <>
          <Header>{literals.primitive_startups}</Header>
          {progress.slow && progress.slow.length > 0 && (
            <>
              <CategoryHeader>🐌&nbsp; {literals.community_progress_slower_progress_section_title}</CategoryHeader>
              <CategoryWrapper>
                {progress.slow.map((p: ProjectProgressOverview, idx: number) => (
                  <ProgressItem slow progress={p} key={idx} />
                ))}
              </CategoryWrapper>
            </>
          )}
          {progress.nonDatedTasks && progress.nonDatedTasks.length > 0 && (
            <>
              <CategoryHeader>🛵&nbsp; {literals.community_progress_faster_progress_section_title}</CategoryHeader>
              <CategoryWrapper>
                {progress.nonDatedTasks.map((p: ProjectProgressOverview, idx: number) => (
                  <ProgressItem progress={p} key={idx} />
                ))}
              </CategoryWrapper>
            </>
          )}
          {progress.regular && progress.regular.length > 0 && (
            <>
              <CategoryHeader>{literals.community_progress_regular_progress_section_title}</CategoryHeader>
              <CategoryWrapper>
                {progress.regular.map((p: ProjectProgressOverview, idx: number) => (
                  <ProgressItem regular progress={p} key={idx} />
                ))}
              </CategoryWrapper>
            </>
          )}
          <CustomLink to={`/community/${communityId}/admin-panel/overview-projects`}>
            {literals.project_see_all_projects}
          </CustomLink>
        </>
      )}
    </Card>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityProgressPanel);
