import { AxiosResponse } from 'axios';
import { BusinessModel, ProjectTask, SaveParagraph, Circle, Comment, ProjectBusinessModel } from 'redux/types/account';
import { map } from 'ramda';
import { Planner } from '../../redux/types/enums';
import { getAxiosInstance } from './helper';
import { mentionParse } from '../../util/mention';

const ai = getAxiosInstance();

export function fetchBusinessModel(
  projectId: number,
  methodologyId: number,
  bearer: string,
): Promise<{ projectId: number; businessModel: BusinessModel; methodologyId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/BusinessModelContent/GetBusinessModel',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: projectId,
        methodologyId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          projectId,
          methodologyId: methodologyId,
          businessModel: {
            // This resets the customPrivacyCircles of the project. The reducer will
            // not use this field. If you change customPrivacyCircles take a look at
            // the reducer, otherwise it will not take any effect
            customPrivacyCircles: [],
            canEditBusinessModel: data.canEditBusinessModel,
            sections: data.sections
              .sort((a: any, b: any) => a.order - b.order)
              .map((s: any, idx: number) => {
                return {
                  id: s.id,
                  name: s.name,
                  order: s.order,
                  logo: s.logo,
                  isLean: s.isLean,
                  isStagegated: s.isStagegated || false,
                  isStagegateLocked: s.isStagegateLocked || false,
                  assignments: s.paragraphs
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((a: any) => {
                      return {
                        id: a.id,
                        name: a.name,
                        order: a.order,
                        content: mentionParse(a.content || ''),
                        isActive: a.isActive,
                        isApproved: a.isApproved || false,
                        privacyLevel: a.privacyLevel,
                        teamCanChoosePrivacy: a.teamCanChoosePrivacy,
                        lastUpdated: a.lastUpdated,
                        methodologyParagraphId: a.methodologyParagraphId,
                        sectionId: s.id,
                        sectionName: s.name,
                        details: mentionParse(a.infoText || ''),
                        description: mentionParse(a.wikiReferences || ''),
                        template: mentionParse(a.defaultContent || ''),
                        feedbackText: a.feedbackText,
                        wikiLinks: a.wikiLinks,
                        wikiProblem: a.wikiProblem,
                        isHillary: a.isHillary,
                        inTutorial: a.inTutorial,
                        comments: [],
                      };
                    }),
                };
              }),
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectBusinessModels(
  projectId: number,
  bearer: string,
): Promise<{ projectId: number; projectBusinessModelList: ProjectBusinessModel[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ProjectMethodology/ListMethodologiesByProject/` + projectId.toString(),
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const res: { projectId: number; projectBusinessModelList: ProjectBusinessModel[] } = {
          projectId,
          projectBusinessModelList: data,
        };
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function deleteProjectBusinessModel(projectId: number, methodologyId: number, bearer: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: '/api/ProjectMethodology/' + projectId.toString() + '/' + methodologyId.toString(),
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(true);
      })
      .catch(err => reject(err));
  });
}

export function addProjectBusinessModel(
  projectId: number,
  methodologyId: number,
  bearer: string,
): Promise<{ projectBusinessModel: ProjectBusinessModel }> {
  return new Promise((resolve, reject) => {
    const data = { projectId, methodologyId };
    ai({
      method: 'POST',
      url: '/api/ProjectMethodology/Create',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: data,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectTasks(
  projectId: number,
  bearer: string,
  methodologyId: number,
): Promise<{ projectId: number; projectTasks: ProjectTask[]; taskUnlockRule: Planner }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/BusinessModelTask/GetAll`,
      params: {
        projectId,
        methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const res: any = {
          projectId,
          projectTasks: [],
          taskUnlockRule: data.taskUnlockRule,
        };
        if (data.tasks.length > 0) {
          res.projectTasks = map(t => {
            return {
              id: t.id,
              commentCount: t.userCommentCount || 0,
              methodologySectionId: t.methodologySectionId,
              paragraph: {
                id: t.paragraph?.id || t.id,
                name: t.paragraph?.name || '',
                privacyLevel: t.paragraph?.privacyLevel || 0,
                content: t.paragraph?.content || '',
                commentCount: t.paragraph?.commentCount || 0,
              },
              order: t.order,
              status: t.status,
              startDate: t.dateStart,
              publishDate: t.datePublish,
              endDate: t.dateEnd,
            };
          }, data.tasks);
        }
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function saveProjectTasks(
  saveParagraph: SaveParagraph,
  projectId: number,
  bearer: string,
  circles: Circle[],
  taskId?: number | undefined,
): Promise<{
  projectTask: {
    id: number;
    content: string;
    isActive: boolean;
    privacyLevel: number;
    sectionId: number;
    customPrivacyCircles?: Circle[];
  };
  projectId: number;
}> {
  return new Promise((resolve, reject) => {
    let requestBody: any = {
      id: saveParagraph.id,
      name: saveParagraph.name,
      methodologyParagraphId: saveParagraph.methodologyParagraphId,
      content: saveParagraph.content,
      isActive: saveParagraph.isActive,
      privacyLevel: saveParagraph.privacyLevel,
      isDraftTask: saveParagraph.isDraftTask,
      isLockedCommentBusinessModel: saveParagraph.isLockedCommentBusinessModel,
    };
    if (taskId) requestBody = { ...requestBody, taskId };
    ai({
      method: 'POST',
      url: '/api/BusinessModelContent/SaveParagraph',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: requestBody,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const resObj = {
          projectId: projectId,
          projectTask: {
            id: data.id,
            content: data.content,
            isActive: data.isActive,
            privacyLevel: data.privacyLevel,
            sectionId: saveParagraph.sectionId,
          },
        };
        if (saveParagraph.privacyLevel === 4 && circles.length > 0) {
          saveParagraphPrivacy(bearer, saveParagraph.id, saveParagraph.privacyLevel, circles).then(res => {
            resolve({
              ...resObj,
              projectTask: {
                ...resObj.projectTask,
                customPrivacyCircles: circles,
              },
            });
          });
        } else {
          resolve(resObj);
        }
      })
      .catch(err => reject(err));
  });
}

function saveParagraphPrivacy(
  bearer: string,
  paragraphId: number,
  privacyLevel: number,
  circles: Circle[],
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Privacy/SaveParagraphPrivacy',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        paragraphId,
        privacyLevel,
        privacyCircles: map((c: Circle) => {
          return {
            circleId: c.id,
            communityId: c.communityId,
            projectId: c.projectId,
          };
        }, circles),
      },
    })
      .then(response => {
        resolve(true);
      })
      .catch(err => reject(err));
  });
}

export function fetchCustomCirclesByParagraph(
  projectId: number,
  bearer: string,
  paragraphId: number,
): Promise<{ projectId: number; paragraphId: number; circles: Circle[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Privacy/GetCustomCirclesByParagraph',
      params: {
        id: paragraphId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const auxCircles: any[] = [];
        data.forEach((c1: any) => {
          c1.privacyCircles.forEach((c2: any) => {
            auxCircles.push({ ...c2, typeName: c1.name, isProject: c1.isProject, isCommunity: c1.isCommunity });
          });
        });
        resolve({
          projectId,
          paragraphId,
          circles: map(c => {
            return {
              id: c.id,
              name: c.longName,
              shortName: c.shortName,
              admin: c.name === 'Admins',
              projectId: c.projectId,
              communityId: c.communityId,
              isActive: c.isActive,
              type: {
                name: c.typeName,
                isProject: c.isProject,
                isCommunity: c.isCommunity,
              },
            };
          }, auxCircles),
        });
      })
      .catch(err => reject(err));
  });
}

export function addCommentToBusinessModelParagraph(
  bearer: string,
  userId: number,
  parentId: number,
  paragraphId: number,
  content: string,
  projectId: number,
): Promise<{ projectId: number; paragraphId: number; parentId: number; comment: Comment }> {
  return new Promise((resolve, reject) => {
    const data: any = {
      userId,
      content,
      paragraphId,
      parentId,
    };
    if (parentId === paragraphId) delete data.parentId;
    ai({
      method: 'POST',
      url: '/api/Comment/SaveParagraphComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          projectId,
          paragraphId,
          parentId,
          comment: {
            id: data.id,
            author: {
              id: data.authorId,
              name: data.authorName,
              photo: data.authorPhoto,
              occupation: data.authorOccupation || '',
            },
            date: data.lastUpdated,
            content: mentionParse(data.content),
            parentId: data.parentId,
            likeCount: data.likeCount,
            isLiked: data.isLiked,
            comments: [],
            canUserEdit: data.canUserEdit,
            canUserRemove: data.canUserRemove,
            userLikesComment: data.userLikesComment,
            userDislikesComment: data.userDislikesComment,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommentsForBusinessModelParagraph(
  bearer: string,
  projectId: number,
  paragraphId: number,
): Promise<{ projectId: number; paragraphId: number; comments: Comment[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Comment/GetByParagraph',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: paragraphId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          projectId,
          paragraphId,
          comments: map((c1: any) => {
            return {
              id: c1.id,
              author: {
                id: c1.authorId,
                name: c1.authorName,
                photo: c1.authorPhoto,
                occupation: c1.authorOccupation || '',
              },
              date: c1.lastUpdated,
              content: mentionParse(c1.content),
              parentId: c1.parentId,
              likeCount: c1.likeCount,
              isLiked: c1.userLikesComment,
              comments: map(c2 => {
                return {
                  id: c2.id,
                  author: {
                    id: c2.authorId,
                    name: c2.authorName,
                    photo: c2.authorPhoto,
                    occupation: c2.authorOccupation || '',
                  },
                  date: c2.postDate,
                  content: mentionParse(c2.content),
                  parentId: c2.parentId,
                  likeCount: c2.likeCount,
                  isLiked: c2.userLikesComment,
                  comments: [],
                };
              }, c1.comments),
              canUserEdit: c1.canUserEdit,
              canUserRemove: c1.canUserRemove,
              userLikesComment: c1.userLikesComment,
              userDislikesComment: c1.userDislikesComment,
            };
          }, data),
        });
      })
      .catch(err => reject(err));
  });
}

export function saveSectionStagegateLocked(
  bearer: string,
  projectId: number,
  sectionId: number,
  isStagegateLocked: boolean,
): Promise<{ projectId: number; sectionId: number; isStagegateLocked: boolean }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/BusinessModelContent/SetStagegateSectionLock`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId,
        methodologySectionId: sectionId,
        isStagegateLocked,
      },
    })
      .then(() => {
        resolve({
          projectId,
          sectionId,
          isStagegateLocked,
        });
      })
      .catch(err => reject(err));
  });
}

export function toggleApprovedAssignment(
  bearer: string,
  projectId: number,
  sectionId: number,
  assignmentId: number,
): Promise<{
  projectId: number;
  sectionId: number;
  assignmentId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/BusinessModelContent/ApproveUnapproveToogleParagraph`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: assignmentId,
      },
    })
      .then(() => {
        resolve({
          projectId,
          sectionId,
          assignmentId,
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  fetchBusinessModel,
  fetchProjectTasks,
  saveProjectTasks,
  fetchCustomCirclesByParagraph,
  addCommentToBusinessModelParagraph,
  fetchCommentsForBusinessModelParagraph,
  saveSectionStagegateLocked,
  toggleApprovedAssignment,
  fetchProjectBusinessModels,
};
