import { catchError, filter, map, switchMap, concatMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { from, of } from 'rxjs';
import { Epic } from 'redux-observable';
import { RootAction, RootState, Services } from 'StoreModel';
import {
  calendarLogInOutlookAsync,
  calendarLogOutOutlookAsync,
  fetchMeetingsSettingsAsync,
  getUserEventsByMonthAsync,
  saveMeetingsSettingsAsync,
  setUserCalendarOutlookAsync,
  calendarLogOutGoogleAsync,
  calendarLogInGoogleAsync,
  setUserCalendarGoogleAsync,
  meetingProviderUnSyncAsync,
  meetingProviderSyncAsync,
} from '../actions/calendar';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const setUserCalendarOutlookEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setUserCalendarOutlookAsync.request)),
    switchMap(action =>
      from(api.calendar.setUserCalendarOutlook(action.payload.bearer, action.payload.calendarId)).pipe(
        map(setUserCalendarOutlookAsync.success),
        catchError(error => of(setUserCalendarOutlookAsync.failure(error.message))),
      ),
    ),
  );
export const calendarLogInOutlookEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(calendarLogInOutlookAsync.request)),
    switchMap(action =>
      from(
        api.calendar.calendarLogInOutlook(
          action.payload.bearer,
          action.payload.authorizationCode,
          action.payload.redirectUri,
        ),
      ).pipe(
        map(calendarLogInOutlookAsync.success),
        catchError(error => of(calendarLogInOutlookAsync.failure(error.message))),
      ),
    ),
  );
export const calendarLogOutOutlookEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(calendarLogOutOutlookAsync.request)),
    switchMap(action =>
      from(api.calendar.calendarLogOutOutlook(action.payload.bearer)).pipe(
        map(calendarLogOutOutlookAsync.success),
        catchError(error => of(calendarLogOutOutlookAsync.failure(error.message))),
      ),
    ),
  );

export const meetingProviderSyncEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(meetingProviderSyncAsync.request)),
    switchMap(action =>
      from(
        api.calendar.meetingProviderSync(
          action.payload.bearer,
          action.payload.authorizationCode,
          action.payload.redirectUri,
          action.payload.meetingProviderType,
        ),
      ).pipe(
        map(meetingProviderSyncAsync.success),
        catchError(error => of(meetingProviderSyncAsync.failure(error.message))),
      ),
    ),
  );

export const meetingProviderUnSyncEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(meetingProviderUnSyncAsync.request)),
    switchMap(action =>
      from(api.calendar.meetingProviderUnSync(action.payload.bearer)).pipe(
        map(meetingProviderUnSyncAsync.success),
        catchError(error => of(meetingProviderUnSyncAsync.failure(error.message))),
      ),
    ),
  );

export const calendarLogOutGoogleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(calendarLogOutGoogleAsync.request)),
    switchMap(action =>
      from(api.calendar.calendarLogOutGoogle(action.payload.bearer)).pipe(
        map(calendarLogOutGoogleAsync.success),
        catchError(error => of(calendarLogOutGoogleAsync.failure(error.message))),
      ),
    ),
  );

export const calendarLogInGoogleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(calendarLogInGoogleAsync.request)),
    switchMap(action =>
      from(
        api.calendar.calendarLogInGoogle(
          action.payload.bearer,
          action.payload.authorizationCode,
          action.payload.redirectUri,
        ),
      ).pipe(
        map(calendarLogInGoogleAsync.success),
        catchError(error => of(calendarLogInGoogleAsync.failure(error.message))),
      ),
    ),
  );

export const setUserCalendarGoogleEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setUserCalendarGoogleAsync.request)),
    switchMap(action =>
      from(api.calendar.setUserCalendarGoogle(action.payload.bearer, action.payload.calendarId)).pipe(
        map(setUserCalendarGoogleAsync.success),
        catchError(error => of(setUserCalendarGoogleAsync.failure(error.message))),
      ),
    ),
  );

export const fetchMeetingsSettingsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchMeetingsSettingsAsync.request)),
    switchMap(action =>
      from(api.calendar.getMeetingsSettings(action.payload.bearer, action.payload.userId)).pipe(
        map(fetchMeetingsSettingsAsync.success),
        catchError(error => of(fetchMeetingsSettingsAsync.failure(error.message))),
      ),
    ),
  );

export const saveMeetingsSettingsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveMeetingsSettingsAsync.request)),
    switchMap(action =>
      from(api.calendar.saveMeetingsSettings(action.payload.bearer, action.payload.meetingSettings)).pipe(
        map(saveMeetingsSettingsAsync.success),
        catchError(error => of(saveMeetingsSettingsAsync.failure(error.message))),
      ),
    ),
  );

export const getUserEventsByMonthEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(getUserEventsByMonthAsync.request)),
    concatMap(action =>
      from(
        api.calendar.getUserEventsByMonth(
          action.payload.bearer,
          action.payload.userId,
          action.payload.month,
          action.payload.year,
        ),
      ).pipe(
        map(getUserEventsByMonthAsync.success),
        catchError(error => of(getUserEventsByMonthAsync.failure(error.message))),
      ),
    ),
  );

export default {
  setUserCalendarGoogleEpic,
  calendarLogInGoogleEpic,
  calendarLogOutGoogleEpic,
  setUserCalendarOutlookEpic,
  fetchMeetingsSettingsEpic,
  saveMeetingsSettingsEpic,
  getUserEventsByMonthEpic,
  calendarLogOutOutlookEpic,
  calendarLogInOutlookEpic,
  meetingProviderSyncEpic,
  meetingProviderUnSyncEpic,
};
