import { Event } from 'react-big-calendar';
export enum CalendarViewType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  WORK_WEEK = 'work_week',
  AGENDA = 'agenda',
}

export interface CustomEvent extends Event {
  eventId: number;
  userId?: number;
  start: Date;
  end: Date;
  available?: boolean;
  availableOutside?: boolean;
  title?: string;
  selected?: boolean;
}

export type CalendarSlotEvent = {
  start: Date;
  end: Date;
  slots: Date[];
  action: string;
};

export interface IUserCalendarEvent extends Event {
  title: string;
  start: Date;
  end: Date;
}
