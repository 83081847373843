import React, { useState, FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import {
  fetchMultipleMeetingSettingsAsync,
  updateSelectedUsers,
  updateUserMultipleMeetingSettings,
} from 'redux/actions/user-calendar';
import {
  MultipleMeetingSettings,
  SelectedUserOption,
  UserWeeklyAvailabilityUser,
  YearMonthUser,
} from 'services/types/user-calendar';
import { CalendarViewType, CustomEvent, CalendarSlotEvent } from '../calendar-scheduler/types';
import { upsertCommunityCalendarEventAsync } from 'redux/actions/community';
import { NavigateAction, View, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import {
  createWeeklyEventsWithAvailabilityLocalized,
  generateUserColors,
  getUserAvailabilitiesEvents,
  getUserFixedAvailabilitiesEvents,
  getMonthsForCurrentWeek,
  Duration,
} from 'util/calendar';
import CalendarScheduler from '../calendar-scheduler/calendar-scheduler';
import { UserProfile } from 'UsersModels';
import { Option } from '../scheduler-multiple-user-modal/types';
import SelectedUser from '../scheduler-multiple-user-modal/components/selected-user';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { User, MeetingOriginType } from '../../../redux/types/account';
import { Checkbox } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import PeopleIcon from '@material-ui/icons/People';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Flag, MeetingProvider } from '../../../redux/types/enums';
import { toast } from '../toast';
import Button from 'primitives/Button';
import { SecondaryButton } from 'components/styled-components/common';
import { getUserEventsByMonth } from 'services/api/calendar';
import { UserEvent } from 'redux/types/calendar';
import EditIcon from '@material-ui/icons/Edit';
import { scheduleRequestSessionAsync } from 'redux/actions/request';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';

const mapStateToProps = (state: RootState) => ({
  multipleMeetingSettings: state.userCalendar.multipleMeetingSettings as MultipleMeetingSettings[],
  selectedUsers: state.userCalendar.selectedUsers,
  currentUser: state.account.details.user as User,
  literals: state.literals,
  isUpsertingCommunityCalendarEvent: state.loading.upsertCommunityCalendarEventFlag,
  isSchedulingMeeting: state.loading.scheduleRequestSessionFlag,
  community: state.account.selectedCommunity,
});

const mapDispatchToProps = {
  fetchMultipleMeetingSettings: fetchMultipleMeetingSettingsAsync.request,
  upsertCalendarEvent: upsertCommunityCalendarEventAsync.request,
  updateUserMultipleMeetingSettings: updateUserMultipleMeetingSettings,
  updateSelectedUsers: updateSelectedUsers,
  clearUpsertCalendarEventFlag: upsertCommunityCalendarEventAsync.cancel,
  scheduleRequestSession: scheduleRequestSessionAsync.request,
  clearScheduleRequestSession: scheduleRequestSessionAsync.cancel,
};

type dispatchType = typeof mapDispatchToProps;

const modalSteps = {
  FIRST_STEP: 'firstStep',
  SECOND_STEP: 'secondStep',
};

interface SchedulerIndividualModalProps extends ReturnType<typeof mapStateToProps>, dispatchType {
  bearer: string;
  onClose: () => void;
  showModal: boolean;
  communityId: number;
  guestUser: UserProfile | any;
  titlePlaceholder: string;
  requestId?: number;
  projectId?: number;
}

interface FormDataProps {
  description: string;
  meetingName: string;
  location: string;
  createLink: boolean;
}

const greyColor = '#A9A9A9';
const colorPalette = ['#87B23A'];

const SchedulerOneOnOneMeetingModal: FC<SchedulerIndividualModalProps> = ({
  bearer,
  currentUser,
  fetchMultipleMeetingSettings,
  onClose,
  showModal,
  upsertCalendarEvent,
  communityId,
  multipleMeetingSettings,
  literals,
  guestUser,
  updateSelectedUsers,
  selectedUsers,
  updateUserMultipleMeetingSettings,
  isUpsertingCommunityCalendarEvent,
  clearUpsertCalendarEventFlag,
  clearScheduleRequestSession,
  titlePlaceholder,
  requestId,
  projectId,
  scheduleRequestSession,
  isSchedulingMeeting,
  community,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<FormDataProps>({
    defaultValues: {
      description: '',
      meetingName: titlePlaceholder,
      location: '',
      createLink: false,
    },
  });
  const [modalStep, setModalStep] = useState<string>(modalSteps.FIRST_STEP);
  const [usersAvailability, setUsersAvailability] = useState<MultipleMeetingSettings[]>([]);
  const [isDisabledLocation, setIsDisabledLocation] = useState<boolean>(false);
  const [userColorMap, setUserColorMap] = useState<Record<number, string>>({});
  const [duration, setDuration] = useState<number>(Duration.DURATION_60);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [userWeeklyAvailabilities, setUsersWeeklyAvailabilities] = useState<UserWeeklyAvailabilityUser[]>([]);
  const [allEvents, setAllEvents] = useState<CustomEvent[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentUserTimeZone, setCurrentUserTimeZone] = useState<string>(momentTimezone.tz.guess());
  const [fixedTimeSlotsEvents, setFixedTimeSlotsEvents] = useState<CustomEvent[]>([]);
  const [fetchedUserMonths, setFetchedUserMonths] = useState<YearMonthUser[]>([]);
  const [scheduledUserEvents, setScheduledUserEvents] = useState<CustomEvent[]>([]);

  const communityHasMeetingProvider: boolean = community?.hasMeetingProvider;
  const communityMeetingProviderType: MeetingProvider = community?.meetingProviderType;
  // If the community has a meeting app sync-ed, then use that. Otherwise, use the Babele Zoom
  const communityMeetingProviderToUse: MeetingProvider = communityHasMeetingProvider
    ? communityMeetingProviderType
    : MeetingProvider.Zoom;

  const userHasMeetingProvider: boolean = Boolean(currentUser?.hasMeetingProvider);
  const userMeetingProviderType = currentUser?.meetingProviderType;

  // Updating the localizer to have Monday as the first day of the week
  // Explanation https://github.com/jquense/react-big-calendar/issues/1661
  moment.updateLocale('en', {
    week: {
      dow: 1,
      doy: 4,
    },
  });
  moment.tz.setDefault(currentUserTimeZone);
  const localizer = momentLocalizer(moment);

  // Reset moment locale to the default browser one when the component unmounts
  // so it doesn't interfere with other components using moment
  useEffect(() => {
    return () => {
      moment.tz.setDefault(momentTimezone.tz.guess());
    };
  }, []);

  const defaultStartOfDay = momentTimezone.tz(currentUserTimeZone);
  const defaultEndOfDay = momentTimezone.tz(currentUserTimeZone);
  defaultStartOfDay.set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
  defaultEndOfDay.set({ hour: 22, minute: 0, second: 0, millisecond: 0 });

  const meetingUsers = [
    { id: currentUser?.id, name: currentUser?.name, photo: currentUser?.photo },
    { id: guestUser?.id, name: guestUser?.name, photo: guestUser?.photo },
  ];

  useEffect(() => {
    fetchMultipleMeetingSettings({ bearer: bearer, usersId: [currentUser?.id, guestUser?.id] });

    const generatedColors = generateUserColors([guestUser], colorPalette);

    const userInMeetingSettings = multipleMeetingSettings?.find(
      (user: MultipleMeetingSettings) => user.userId === guestUser?.id,
    );

    if (
      !userInMeetingSettings ||
      !userInMeetingSettings.userRecurringAvailabilities ||
      userInMeetingSettings.userRecurringAvailabilities.length === 0
    ) {
      generatedColors[guestUser?.id] = greyColor;
    }

    setUserColorMap(generatedColors);

    const multipleMeetingSettingsResult = multipleMeetingSettings || [];
    setUsersAvailability(multipleMeetingSettingsResult);

    const newSelectedUsers: SelectedUserOption[] = meetingUsers.map(user => {
      return {
        id: user?.id,
        name: user?.name,
        color: userColorMap[user?.id],
        photo: user?.photo,
      };
    });
    updateSelectedUsers({ selectedUsers: newSelectedUsers });
  }, [showModal, currentUser, guestUser]);

  useEffect(() => {
    if (isDisabledLocation) setValue('location', '');
  }, [isDisabledLocation]);

  useEffect(() => {
    if (selectedUsers?.length > 0 && !showModal) {
      updateSelectedUsers({ selectedUsers: [] });
    }
  }, [selectedUsers, showModal]);

  useEffect(() => {
    // A week can span over 2 months, so get the months we need to fetch events for
    const monthsToShow = getMonthsForCurrentWeek(currentDate);
    const userIds = selectedUsers ? selectedUsers.map((user: any) => user.id) : [];
    // Creating a list with all the user ids and months that need to be shown
    const userMonthsToShow: YearMonthUser[] = userIds.flatMap((id: number) => {
      return monthsToShow.map(month => ({
        year: month.year,
        month: month.month,
        userId: id,
      }));
    });

    // Don't fetch data that we fetched already
    const userMonthsToFetch = userMonthsToShow.filter(({ year, month, userId }) => {
      return !fetchedUserMonths.find(userMonth => {
        return userMonth.month === month && userMonth.year === year && userMonth.userId === userId;
      });
    });

    const eventsFetchTasks = userMonthsToFetch.map(({ userId, year, month }) =>
      getUserEventsByMonth(bearer, userId, month, year),
    );

    // Fetched all the user events needed
    Promise.allSettled(eventsFetchTasks).then(results => {
      results
        .filter(result => result.status === 'fulfilled')
        .forEach(result => {
          // @ts-ignore
          const { userEvents }: { userEvents: UserEvent[] } = result.value;
          const customEvents = (userEvents || []).map(({ id, start, end }) => {
            return {
              eventId: id,
              start: new Date(start),
              end: new Date(end),
            };
          });

          setScheduledUserEvents(scheduled => [...scheduled, ...customEvents]);
        });
    });
    setFetchedUserMonths(fetchedUserMonths => [...fetchedUserMonths, ...userMonthsToFetch]);
  }, [currentDate, currentUser, selectedUsers]);

  useEffect(() => {
    const currentUserAvailability = usersAvailability.find(availability => {
      return availability.userId === currentUser.id;
    });

    const userSetTimeZone = currentUserAvailability?.userMeetingPreference?.timeZone;

    if (userSetTimeZone && Boolean(momentTimezone.tz.zone(userSetTimeZone as string))) {
      setCurrentUserTimeZone(userSetTimeZone);
    }

    const weeklyEvents: UserWeeklyAvailabilityUser[] = getUserAvailabilitiesEvents(usersAvailability);

    setUsersWeeklyAvailabilities(weeklyEvents);
    setFixedTimeSlotsEvents(getUserFixedAvailabilitiesEvents(usersAvailability));
  }, [usersAvailability, duration]);

  useEffect(() => {
    const selectedUsersIds = selectedUsers ? selectedUsers.map(({ id }: { id: number }) => id) : [];

    if (userWeeklyAvailabilities && fixedTimeSlotsEvents && scheduledUserEvents && currentUserTimeZone) {
      const weeklyEvents = createWeeklyEventsWithAvailabilityLocalized(
        currentDate,
        duration,
        userWeeklyAvailabilities,
        fixedTimeSlotsEvents,
        scheduledUserEvents,
        selectedUsersIds,
        currentUserTimeZone,
        literals.calendar_meeting_slot_available,
        currentUser,
      );
      setAllEvents([...weeklyEvents]);
    }
  }, [currentDate, userWeeklyAvailabilities, duration, fixedTimeSlotsEvents, scheduledUserEvents, currentUserTimeZone]);

  useEffect(() => {
    if (isSchedulingMeeting === Flag.Success || isUpsertingCommunityCalendarEvent === Flag.Success) {
      toast(literals.request_meeting_schedule_success, { type: 'success' });
      onCloseModal();
    }
    if (isSchedulingMeeting === Flag.Failure || isUpsertingCommunityCalendarEvent === Flag.Failure) {
      toast(literals.request_meeting_schedule_fail, { type: 'warning' });
    }
  }, [isSchedulingMeeting, isUpsertingCommunityCalendarEvent]);

  function onCalendarNavigate(newDate: Date, view: View, action: NavigateAction) {
    setCurrentDate(newDate);
  }

  const renderSelectedUsers = () => {
    return selectedUsers?.map((option: Option) => {
      return (
        <SelectedUser
          key={option?.id}
          name={option?.id === currentUser?.id ? literals.calendar_meeting_you : option?.name}
          color={userColorMap[option.id]}
          avatar={option?.photo}
          isCurrentUser={option?.id === currentUser?.id}
        />
      );
    });
  };

  const handleSelectEvent = (event: CustomEvent | null) => {
    setModalStep(modalSteps.SECOND_STEP);

    const startDate = moment(event?.start).format('DD/MM/YYYY HH:mm');
    const endDate = moment(event?.end).format('DD/MM/YYYY HH:mm');
    const selectedDate = `${startDate} to ${endDate}`;

    setSelectedDate(selectedDate);
  };

  const handleSelectSlot = (event: CalendarSlotEvent) => {
    setModalStep(modalSteps.SECOND_STEP);

    const startDate = moment(event?.start).format('DD/MM/YYYY HH:mm');
    const endDate = moment(event?.end).format('DD/MM/YYYY HH:mm');
    const selectedDate = `${startDate} to ${endDate}`;

    setSelectedDate(selectedDate);
  };

  const handleDurationButtonClick = (duration: number) => {
    setDuration(duration);
  };

  const handleEditParticipant = () => {
    setModalStep(modalSteps.FIRST_STEP);
  };

  const onCloseModal = () => {
    onClose();

    setSelectedDate('');
    setModalStep(modalSteps.FIRST_STEP);
    setUsersAvailability([]);
    setCurrentDate(new Date());
    setDuration(Duration.DURATION_60);
    clearUpsertCalendarEventFlag(null);
    clearScheduleRequestSession(null);

    reset({
      description: '',
      meetingName: titlePlaceholder,
    });
    updateSelectedUsers({ selectedUsers: [] });
    updateUserMultipleMeetingSettings({ multipleMeetingSettings: [] });
  };

  const onSubmitModal = (data: FormDataProps): void => {
    const [startDate, startTime, _, endDate, endTime] = selectedDate.split(' ');

    if (!requestId) {
      const formattedStartDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const formattedStartTime = moment(startTime, 'HH:mm').format('HH:mm:ss');
      const formattedEndTime = moment(endTime, 'HH:mm').format('HH:mm:ss');

      if (selectedDate) {
        upsertCalendarEvent({
          bearer,
          communityId,
          eventToSave: {
            id: '',
            summary: data.meetingName,
            description: data.description,
            startDate: formattedStartDate,
            startTime: formattedStartTime,
            endDate: formattedEndDate,
            endTime: formattedEndTime,
            isUserInAttendeeList: true,
            attendeeUsers: [guestUser, currentUser],
            location: data.createLink ? '' : data.location,
            createConference: data.createLink,
            meetingOriginType: MeetingOriginType.MembersOneOnOne,
          },
        });
      }
    } else if (selectedDate) {
      const momentStartDate = moment(startDate + ':' + startTime, 'DD/MM/YYYY:HH:mm');
      const momentEndDate = moment(endDate + ':' + endTime, 'DD/MM/YYYY:HH:mm');

      scheduleRequestSession({
        bearer,
        requestId,
        projectId: projectId || 0,
        advisorId: currentUser.id,
        summary: data.meetingName,
        description: data.description,
        start: momentStartDate,
        end: momentEndDate,
      });
    }
  };

  if (!showModal) {
    return null;
  }

  const portalRoot = document.getElementById('portal');

  return portalRoot
    ? ReactDOM.createPortal(
        <Wrapper>
          <Main>
            {!(isUpsertingCommunityCalendarEvent === Flag.Request || isSchedulingMeeting === Flag.Request) && (
              <ModalContent>
                <Header>
                  <TitleInputContainer>
                    <EditIcon />
                    <TitleInput
                      placeholder="Add a title"
                      type="text"
                      {...register('meetingName', { required: true })}
                    />
                    {errors.meetingName && <ErrorText>{literals.schedule_meeting_modal_required_filed}</ErrorText>}
                  </TitleInputContainer>
                  <CloseButton onClick={onCloseModal}>
                    <CloseIcon />
                  </CloseButton>
                </Header>
                <CustomForm onSubmit={handleSubmit(onSubmitModal)}>
                  <InputsContainer>
                    <TextAreaContainer>
                      <InputIcon>
                        <SubjectIcon />
                      </InputIcon>
                      <TextArea
                        placeholder={literals.calendar_meeting_description_placeholder}
                        rows={2}
                        {...register('description', { required: true })}
                      />
                      {errors.description && <ErrorText>{literals.schedule_meeting_modal_required_filed}</ErrorText>}
                    </TextAreaContainer>
                  </InputsContainer>
                  {selectedUsers?.length > 0 ? (
                    <div>
                      <SelectedUsersListContainer>
                        <PeopleIcon />
                        {renderSelectedUsers()}
                      </SelectedUsersListContainer>

                      {modalStep === modalSteps.FIRST_STEP && (
                        <>
                          <NoteContainer>
                            <InputIcon>
                              <DateRangeIcon />
                            </InputIcon>
                            <Note> {literals.calendar_meeting_select_time_duration} </Note>
                          </NoteContainer>
                          <ButtonDurationContainer>
                            <DurationButton
                              onClick={() => handleDurationButtonClick(Duration.DURATION_30)}
                              isActive={duration === Duration.DURATION_30}
                            >
                              30 {literals.calendar_meeting_duration_minutes.toLowerCase()}
                            </DurationButton>
                            <DurationButton
                              onClick={() => handleDurationButtonClick(Duration.DURATION_45)}
                              isActive={duration === Duration.DURATION_45}
                            >
                              45 {literals.calendar_meeting_duration_minutes.toLowerCase()}
                            </DurationButton>
                            <DurationButton
                              onClick={() => handleDurationButtonClick(Duration.DURATION_60)}
                              isActive={duration === Duration.DURATION_60}
                            >
                              60 {literals.calendar_meeting_duration_minutes.toLowerCase()}
                            </DurationButton>
                          </ButtonDurationContainer>
                        </>
                      )}
                    </div>
                  ) : (
                    <NoteContainer>
                      <DateRangeIcon />
                      <Note> Error </Note>
                    </NoteContainer>
                  )}
                  {modalStep === modalSteps.FIRST_STEP && (
                    <>
                      <CalendarScheduler
                        events={allEvents}
                        isToolbarVisible={true}
                        defaultView={CalendarViewType.WEEK}
                        timeSlot={4}
                        timeIncrements={15}
                        minTime={defaultStartOfDay.toDate()}
                        maxTime={defaultEndOfDay.toDate()}
                        views={[CalendarViewType.WEEK]}
                        localizer={localizer}
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        userColorMap={userColorMap}
                        isSelectable={usersAvailability.length > 1}
                        currentUser={currentUser}
                        onNavigate={onCalendarNavigate}
                      />
                      <Legend>
                        <LegendItem active={true}>{literals.event_inside_office_hours}</LegendItem>
                        <LegendItem active={false}>{literals.event_outside_office_hours}</LegendItem>
                      </Legend>
                    </>
                  )}
                  {modalStep === modalSteps.SECOND_STEP && (
                    <SubmittedTimeContainer>
                      <DateRangeIcon />
                      <SelectedDateContainer> {selectedDate} </SelectedDateContainer>
                      {selectedUsers?.length > 1 && (
                        <EditButton onClick={handleEditParticipant}>
                          {literals.calendar_meeting_edit_date_time}
                        </EditButton>
                      )}
                    </SubmittedTimeContainer>
                  )}

                  <InputContainer>
                    <InputIcon>
                      <VoiceChatIcon />
                    </InputIcon>
                    <Checkbox
                      onClick={() => {
                        setIsDisabledLocation(!isDisabledLocation);
                      }}
                      {...register('createLink', { required: false })}
                      color={'primary'}
                      style={{
                        marginLeft: '-12px',
                      }}
                    />
                    <FormatedP>{literals.event_generate_meet_text}</FormatedP>
                  </InputContainer>
                  <InputContainerWithIcon>
                    <InputIcon>
                      <LocationOnIcon />
                    </InputIcon>
                    <Input
                      placeholder={literals.calendar_meeting_location_placeholder}
                      disabled={isDisabledLocation}
                      type="text"
                      {...register('location')}
                    />
                  </InputContainerWithIcon>
                  <FooterContainer>
                    <SaveButton type="submit" disabled={!selectedDate}>
                      {literals.calendar_save_event}
                    </SaveButton>
                    <CancelButton onClick={onCloseModal}>{literals.confirm_cancel_button}</CancelButton>
                  </FooterContainer>
                </CustomForm>
              </ModalContent>
            )}
          </Main>
          {isUpsertingCommunityCalendarEvent === Flag.Request || isSchedulingMeeting === Flag.Request ? (
            <Dimmer active={true} inverted={true}>
              <Loader inverted={true}>{literals.calendar_saving_event}</Loader>
            </Dimmer>
          ) : null}
        </Wrapper>,
        portalRoot,
      )
    : null;
};

const InputIcon = styled.div`
  font-size: 12px;
  margin-right: 12px;
  margin-top: 10px;
`;

const InputContainerWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorText = styled.div`
  color: #ffdbaa;
  font-size: 12px;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Main = styled.div`
  position: relative;
  width: 70%;
  max-height: min(max(80%, 750px), 100vh);
  min-width: 320px;
  min-height: 320px;
  max-width: 850px;
  background-color: white;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;
`;

export const ModalContent = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 20px;
  width: 100%;
  height: calc(100% - 10px);
`;

const CustomForm = styled.form`
  width: 100%;
`;

export const SelectedEventContainer = styled.div`
  margin: 1rem 0;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
  && p {
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const FormatedP = styled.p`
  margin: 0;
  font-size: 1.2rem;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;

  background: none;
  font-weight: bold;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  margin-top: 20px;
`;

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  padding: 8px 0;

  align-items: center;
`;

const LegendItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ::before {
    content: '';
    width: 16px;
    height: 16px;
    background: ${({ active }) => (active ? '#ceedd8' : '#FFFFCC')};
    border-bottom: 1px solid ${({ active }) => (active ? '#91ba9d' : '#FFC000')};
    border-right: 1px solid ${({ active }) => (active ? '#91ba9d' : '#FFC000')};
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

const TextAreaContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.grey.lighter};
  background-color: ${({ theme }) => theme.colors.grey.lighter};
  color: #949494;
  font-size: 12px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
`;

const TextArea = styled.textarea`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.grey.lighter};
  background-color: ${({ theme }) => theme.colors.grey.lighter};
  color: #949494;
  font-size: 14px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  gap: 10px;
`;

const SaveButton = styled(Button)`
  height: auto;
  padding: 0.1em 3.3em;
  width: auto;
  border-radius: 10em;
  font-size: 12px;
  text-transform: uppercase;
`;

const CancelButton = styled(SecondaryButton)`
  height: auto;
  padding: 0.1em 3.3em;
  width: auto;
  border-radius: 10em;
  font-size: 12px;
  text-transform: uppercase;
`;

const SelectedUsersListContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.7rem 0;
  gap: 12px;
`;

const NoteContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Note = styled.div`
  color: black;
  font-size: 14px;
  padding-top: 6px;
  text-align: left;
`;

const ButtonDurationContainer = styled.div`
  display: flex;
  padding: 12px 0 14px 0;
  gap: 12px;
`;

const DurationButton = styled.div<{ isActive: boolean }>`
  align-items: center;
  justify-content: center;
  padding: 0.4em 2.3em;
  height: 36px;
  width: 155px;
  border-radius: 10em;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.dark};
  border: solid 2px ${({ theme }) => theme.colors.blue.dark};
  font-size: 12px;
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme.colors.blue.dark};
      color: ${theme.colors.white};
    `}
`;

const SelectedDateContainer = styled.div`
  padding: 6px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey.medium};
  font-size: 14px;
`;

const SubmittedTimeContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  gap: 12px;
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 2.3em;
  height: 36px;
  border-radius: 10em;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.dark};
  border: solid 2px ${({ theme }) => theme.colors.blue.dark};
  font-size: 12px;
`;
const TitleInput = styled.input`
  &&& {
    margin-left: 10px;
    width: 100% !important;
    border: 0 !important;
    font-weight: bold !important;
    font-size: 1.6em !important;
    padding-left: 1px !important;
  }
  &:focus {
    // border: 0;
  }
`;

const TitleInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerOneOnOneMeetingModal);
