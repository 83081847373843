import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { Separator, NoBackgroundButton } from '../styled-components/common';
import NotificationFeed from '../../components/notifications/NotificationsFeed';
import { Link } from 'react-router-dom';
import {
  fetchNotificationsAsync,
  markNotificationAsReadAsync,
  markAllNotificationsAsReadAsync,
} from '../../redux/actions/account';
import { Community, CommunityTab, Notification } from 'redux/types/account';
import { Input } from 'semantic-ui-react';
import { fetchNotifications as fetchNotificationsApi } from '../../services/api/account';
import debounce from 'debounce';
import { Flag } from '../../redux/types/enums';
import { fetchCommunityTabsAsync, fetchTabEntitiesByIdAsync } from 'redux/actions/tab';

const Container = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    font-size: 1.5em;
  }
`;

const BlueButton = styled(NoBackgroundButton)`
  &&& {
    color: ${({ theme }) => theme.colors.blue.normal};
  }
`;

const SettingsLink = styled(Link)`
  &&& {
    font-size: 0.9em;
    font-weight: 600;
    color: black;
    margin-left: 0.5em;
  }
`;

const StyledSearch = styled(Input)`
  &&& {
    min-width: 0;
    width: 15em;
    height: 2em;
  }
`;

const mapStateToProps = (state: RootState) => ({
  notifications: state.account.details.notifications,
  isFetching: state.loading.fetchNotificationsFlag,
  bearer: state.account.session.session.bearer,
  literals: state.literals,
  userDetails: state.account.details.user,
  communities: state.account.communities.list,
});

const mapDispatchToProps = {
  fetchNotifications: fetchNotificationsAsync.request,
  fetchTabs: fetchCommunityTabsAsync.request,
  fetchTabEntities: fetchTabEntitiesByIdAsync.request,
  markAsRead: markNotificationAsReadAsync.request,
  markAllAsRead: markAllNotificationsAsReadAsync.request,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const Notifications: React.FC<Props> = props => {
  const {
    communities,
    bearer,
    fetchNotifications,
    fetchTabEntities,
    fetchTabs,
    notifications,
    isFetching,
    markAsRead,
    markAllAsRead,
    literals,
    userDetails,
  } = props;

  const [searchedNotifications, setSearchedNotifications] = useState<Notification[]>([]);
  const [searchConcept, setSearchConcept] = useState<string>('');
  const [activeSearches, setActiveSearches] = useState<number>(0);
  const [once, setOnce] = useState<boolean>(false);
  const [myStuffEntities, setMyStuffEntities] = useState<{
    communities: { [id: number]: boolean };
    projects: { [id: number]: boolean };
    tabEntities?: { [id: number]: boolean };
  }>({ communities: {}, projects: {} });

  useEffect(() => {
    if (once) return;
    communities.forEach((community: Community) => {
      community?.tabs?.forEach((tab: any) => {
        fetchTabEntities({
          bearer,
          communityId: community.id,
          tabId: tab.id,
          skip: 0,
          take: 0,
          sorting: {},
          concept: '',
          entitiesFilter: {},
        });
        setOnce(true);
      });
    });
  }, [communities, once]);

  useEffect(() => {
    const tabEntities: { [id: number]: boolean } = {};
    communities.forEach((community: Community) => {
      community?.tabs?.forEach((tab: CommunityTab) => {
        tab?.entities?.forEach((entity: any) => {
          tabEntities[entity.id] = entity.isFollowing;
        });
      });
    });
    setMyStuffEntities({
      communities: myStuffEntities.communities,
      projects: myStuffEntities.projects,
      tabEntities,
    });
  }, [communities]);

  useEffect(() => {
    const communities: { [id: number]: boolean } = {};
    const projects: { [id: number]: boolean } = {};

    (userDetails.communities || []).forEach((community: any) => (communities[community.id] = true));
    (userDetails.ownedProjects || []).forEach((project: any) => (projects[project.id] = true));
    (userDetails.followedProjects || []).forEach((project: any) => (projects[project.id] = true));
    setMyStuffEntities({
      communities,
      projects,
    });

    Object.keys(communities).forEach((val: any) => {
      fetchTabs({ bearer, communityId: Number(val) });
    });
  }, [userDetails.communities, userDetails.ownedProjects, userDetails.followedProjects]);

  const searchNotifications = debounce((concept: string) => {
    setSearchConcept(concept);
    if (concept === '') setSearchedNotifications([]);
    else {
      setActiveSearches(searches => searches + 1);
      fetchNotificationsApi(bearer, 10, 0, concept)
        .then((response: { notifications: Notification[] }) => {
          setSearchedNotifications(response.notifications);
        })
        .finally(() => {
          setActiveSearches(searches => searches - 1);
        });
    }
  }, 500);

  useEffect((): void => {
    fetchNotifications({ bearer, take: 10, skip: 0 });
  }, []);

  const loadMoreNotifications = () => {
    if (searchConcept) {
      setActiveSearches(searches => searches + 1);
      fetchNotificationsApi(bearer, 10, searchedNotifications.length, searchConcept)
        .then((response: { notifications: Notification[] }) => {
          setSearchedNotifications([...searchedNotifications, ...response.notifications]);
        })
        .finally(() => {
          setActiveSearches(searches => searches - 1);
        });
    } else {
      fetchNotifications({ bearer, take: 10, skip: notifications.length });
    }
  };

  return (
    <Container>
      <HeaderRow>
        <Row>
          <BlueButton style={{ paddingLeft: 0 }} onClick={() => markAllAsRead({ bearer })}>
            {literals.notification_list_mark_all_as_read_button}
          </BlueButton>
          <span>|</span>
          <SettingsLink to="/account/settings/notifications">
            {literals.notifications_manage_notifications_settings}
          </SettingsLink>
        </Row>
        <StyledSearch
          icon="search"
          placeholder={literals.generic_search}
          onChange={(event: ChangeEvent, data: any) => {
            searchNotifications(data.value);
          }}
          loading={activeSearches > 0}
        />
      </HeaderRow>
      <Separator />
      <NotificationFeed
        myStuffEntities={myStuffEntities}
        notifications={searchConcept ? searchedNotifications : notifications}
        isFetching={searchConcept ? activeSearches > 0 : isFetching === Flag.Request}
        bearer={bearer}
        fetchMoreNotifications={loadMoreNotifications}
        markAsRead={markAsRead}
      />
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
