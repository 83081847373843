import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { isActionOf } from 'typesafe-actions';

import { catchError, switchMap, filter, map } from 'rxjs/operators';
import { fetchMultipleMeetingSettingsAsync } from '../actions/user-calendar';
import { RootAction, RootState, Services } from 'StoreModel';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchMultipleMeetingSettingsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchMultipleMeetingSettingsAsync.request)),
    switchMap(action =>
      from(api.userCalendar.fetchMultipleMeetingSettings(action.payload.bearer, action.payload.usersId)).pipe(
        map(fetchMultipleMeetingSettingsAsync.success),
        catchError(error => of(fetchMultipleMeetingSettingsAsync.failure(error.message))),
      ),
    ),
  );

export default { fetchMultipleMeetingSettingsEpic };
