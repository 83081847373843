import { AccountState } from '../reducers/account';
import { RootState } from 'StoreModel';
import { Circle, Community, Project, Request, User } from 'redux/types/account';

export const getUserDetails = (account: AccountState): User => account.details.user as User;

export const getCommunityById = (communities: Community[], id: number) =>
  communities.filter(community => community.id === id)[0];

export const getProjectById = (projects: Project[], id: number) => projects.filter(project => project.id === id)[0];

export const getCircleById = (communityId: number, circleId: number, communities: Community[]) =>
  getCommunityById(communities, communityId).circles?.filter((circle: Circle) => circleId === circle.id)?.[0];

export const getC2CPermission = (circleIdFrom: number, circleIdTo: number, community: Community) => {
  return community?.circleToCirclePermission?.filter(
    (c: any) => c.circleIdTo === circleIdFrom && c.circleIdFrom === circleIdTo,
  )?.[0];
};

export const getTabById = (community: Community, tabId: number) => community.tabs?.find(tab => tab.id === tabId);

export const getToken = (state: RootState) => state.account.session.session.bearer;

export const getProjectRequestById = (projects: Project[], requestId: number): Request | undefined => {
  let currentRequest: Request | undefined;

  projects.forEach((c: Project) => {
    const wantedRequest = c.requests?.find(r => r.id === requestId);
    if (wantedRequest) {
      currentRequest = wantedRequest;
    }
  });

  return currentRequest;
};
