import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';

import AppNavigator from './routes/navigation';
import { GlobalStyle, theme } from './components/styled-components/babele-theme';
import ToastContainer from './components/common/toast';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { store, persistor } from './redux/store';
import { gdprConsent, featuresCookieCategories } from './util/utils';
import { clearAllAssignmentCache } from './util/business-model';
import ReportIssueModal from './components/common/report-issue-modal';
import MaintenancePage from './routes/404/maintenance';
import GARouteTracker from './ga-route-tracker';

const MAINTENANCE_MODE = false;

const App: React.FC = () => {
  useEffect(() => {
    if (!gdprConsent(featuresCookieCategories.assignmentCache)) {
      clearAllAssignmentCache();
    }
    if (!gdprConsent(featuresCookieCategories.bugsnag)) {
      localStorage.removeItem('bugsnag-anonymous-id');
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title></title>
      </Helmet>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Router>
              {MAINTENANCE_MODE ? <MaintenancePage /> : <AppNavigator />}
              <GARouteTracker />
            </Router>
            <GlobalStyle />
            <ReportIssueModal />
          </ThemeProvider>
        </PersistGate>
      </Provider>
      <ToastContainer position="bottom-left" closeButton={false} />
    </React.Fragment>
  );
};

export default App;
