import { useCallback, useEffect } from 'react';

export default function useBodyScroll(status = true) {
  // console.log('useBodyScroll', status);
  const handleDisableTouch = useCallback(e => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    return false;
  }, []);

  useEffect(() => {
    document.body.style.overflow = status ? 'auto' : 'hidden';
    if (!status) {
      document.body.addEventListener('touchmove', handleDisableTouch);
      document.body.ontouchmove = handleDisableTouch;
      document.body.ontouchstart = handleDisableTouch;
      document.body.style.touchAction = 'none';
    }
    return () => {
      document.body.ontouchmove = () => null;
      document.body.ontouchstart = () => null;
      document.body.style.touchAction = 'initial';
      document.body.style.overflow = 'initial';
      document.body.removeEventListener('touchmove', handleDisableTouch);
    };
  }, [status]);
}
