import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NoBackgroundButton } from '../styled-components/common';

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface MapStateProps extends ReturnType<typeof mapStateToProps> {
  style?: React.CSSProperties;
  onClick?: () => void;
}
const _BackButton: React.FC<MapStateProps> = props => {
  const history = useHistory();
  return (
    <NoBackgroundButton onClick={() => history.goBack()}>
      <i className="material-icons">arrow_back</i>
      <span>{props.literals.profile_wizard_02_back_button}</span>
    </NoBackgroundButton>
  );
};

const BackButton = connect(mapStateToProps, {})(_BackButton);

const _XButton: React.FC<MapStateProps> = props => {
  const history = useHistory();
  const handleGoBack = () => history.goBack();
  return (
    <NoBackgroundButton style={{ ...(props.style || {}) }} onClick={props.onClick || handleGoBack}>
      <i className="material-icons" style={{ color: '#000' }}>
        close
      </i>
    </NoBackgroundButton>
  );
};
const XButton = connect(mapStateToProps, {})(_XButton);

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  padding: 2.1em 1em 1em;
  gap: 1em;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    position: initial;
    padding-top: 0;
    font-size: 1.3em;
  }
`;

const NavigationRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface NavigationProps {}
const NavigationHeader: React.FC<NavigationProps> = props => {
  const history = useHistory();
  const firstRedirectAfterLogin = history.location.state && (history.location.state as any).from === '/';
  return (
    <HeaderContainer>
      <NavigationRow style={{ visibility: firstRedirectAfterLogin ? 'hidden' : 'visible' }}>
        <BackButton />
        <XButton />
      </NavigationRow>
      {props.children}
    </HeaderContainer>
  );
};

export { BackButton, XButton, HeaderContainer };
export default NavigationHeader;
