import { createReducer, PayloadAction } from 'typesafe-actions';
import { buildFlagReducer } from '../store/utils';
import { ProjectOverview, ProjectSearchOverview } from 'redux/types/account';
import { fetchProjectSearchOverviewAsync } from '../actions/project-overview';

export const fetchProjectSearchOverviewFlag = buildFlagReducer(fetchProjectSearchOverviewAsync);

export const projectsOverviewReducer = createReducer([] as ProjectOverview[]).handleAction(
  fetchProjectSearchOverviewAsync.success,
  (projects: ProjectOverview[], action: PayloadAction<'FETCH_PROJECT_SEARCH_OVERVIEW_SUCCESS', ProjectOverview[]>) => {
    const payload = action.payload;

    return payload.map((x: ProjectSearchOverview) => {
      return {
        ...x,
        mentorCircleId: x.projectCircles.find(x => x.isMentorsCircle)?.id ?? -1,
      };
    });
  },
);

export default projectsOverviewReducer;
export type AccountState = ReturnType<typeof projectsOverviewReducer>;
