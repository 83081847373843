import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem } from '@material-ui/core';
import SettingsIcon from '../../../assets/sidebar-icons/settings.svg';

const Container = styled.div`
  padding: 1rem;
  border-radius: 15px;
  margin: 1.8em 0;
  border: 2px solid ${({ theme }) => theme.colors.grey.medium};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextConnected = styled.span`
  color: limegreen;
  font-weight: bolder;
  margin-right: 1rem;
`;

const TextAccount = styled.span`
  font-weight: bolder;
`;

const TextDescription = styled.span`
  margin-left: 0.7rem;
`;

const FormatedSettingsIcon = styled.img`
  height: 25px;
  :hover {
    cursor: pointer;
  }
`;

interface Props {
  logoUrl: string;
  onUnsync: () => void;
  alt?: string;
  providerSyncAccount: string;
  literals: any;
}
const SyncPlatformPanel: React.FC<Props> = ({ alt, literals, onUnsync, logoUrl, providerSyncAccount }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container>
      <div>
        <img style={{ width: '6em' }} src={logoUrl} alt={alt} />
        <TextDescription>{literals.calendar_sync_panel_active_account}: </TextDescription>
        <TextAccount>{providerSyncAccount}</TextAccount>
      </div>
      <div>
        <TextConnected>{literals.calendar_sync_panel_connected}!</TextConnected>
        <FormatedSettingsIcon src={SettingsIcon} alt={'Settings Icon'} onClick={handleOpenDropdown} />
        <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={!!anchorEl}>
          <MenuItem style={{ color: '#FF0000' }} onClick={() => onUnsync()}>
            {literals.calendar_sync_panel_disconnect_account}
          </MenuItem>
        </Menu>
      </div>
    </Container>
  );
};

export default SyncPlatformPanel;
