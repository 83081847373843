import { ProjectOverview } from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';

export const fetchProjectSearchOverviewAsync = createAsyncAction(
  'FETCH_PROJECT_SEARCH_OVERVIEW_REQUEST',
  'FETCH_PROJECT_SEARCH_OVERVIEW_SUCCESS',
  'FETCH_PROJECT_SEARCH_OVERVIEW_FAILURE',
  'FETCH_PROJECT_SEARCH_OVERVIEW_IDLE',
)<
  {
    //! This represents the payload type for the request action. It is an object with two properties: bearer of type string and id of type number.
    bearer: string;
    communityId?: number;
    concept?: string;
  },
  ProjectOverview[], //! This represents the result type for the success action. It specifies that the result data for the success action will be of type ProjectOverview.
  string, //! This represents the result type for the failure action. It specifies that the result data for the failure action will be of type string.
  null //! This represents the result type for the idle action. It specifies that the idle action does not have any associated result data.
>();

export default {
  fetchProjectSearchOverviewAsync,
};
