type UserMeetingPreference = {
  id: number;
  videoCallLink: string;
  minutesDuration: number;
  bookingType: number;
  timeZone: string;
};
export enum Day {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
type UserWeeklyAvailability = {
  id: number;
  startTime: string;
  endTime: string;
  day: Day;
  newEntry: boolean;
};
type UserWeeklyAvailabilityDto = {
  id: number;
  startTime: string;
  endTime: string;
  day: Day;
};
type UserRecurringAvailability = {
  id: number;
  date?: string;
  startDateTime: string;
  endDateTime: string;
};

type UserEvent = {
  id: number;
  subject: string;
  organizer: string;
  start: string;
  end: string;
};

type MeetingsSettings = {
  userWeeklyAvailabilities: UserWeeklyAvailability[];
  userMeetingPreference: UserMeetingPreference;
  userRecurringAvailabilities: UserRecurringAvailability[];
};
export type MeetingsSettingsDto = {
  userWeeklyAvailabilities: UserWeeklyAvailabilityDto[];
  userMeetingPreference: UserMeetingPreference;
  userRecurringAvailabilities: UserRecurringAvailability[];
};

type MeetingProviderData = {
  authorizationCode: string;
  redirectUri: string;
  meetingProviderType: 0 | 1;
};

type LogInCalendarData = { id: string; name: string };

export type CommunitySettings = {
  communityId: number;
  communityMeetingPreference: {
    id: number;
    videoCallLink: string;
    timeZone: string;
  };
};

export type CommunityCalendarLogin = {
  communityId: number;
  authorizationCode: string;
  redirectUri: string;
};

export type CommunityCalendarLogout = {
  communityId: number;
};

export type CommunityMeetingLogout = CommunityCalendarLogout;

export type CommunityMeetingLogin = MeetingProviderData & {
  communityId: number;
};

export type {
  LogInCalendarData,
  MeetingsSettings,
  UserEvent,
  UserRecurringAvailability,
  UserWeeklyAvailability,
  UserMeetingPreference,
  MeetingProviderData,
};
