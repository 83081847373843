import React from 'react';
import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';

const ProgressBar = styled(Progress)`
  &&& {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    border-radius: 0;
  }

  & > .bar {
    height: 3px !important;
    border-radius: 0 !important;
    background-color: ${({ theme }) => theme.colors.blue.normal} !important;
  }
`;

interface LoadingBarProps {}
const LoadingBarComponent: React.FC<LoadingBarProps> = () => <ProgressBar percent={100} active={true} size="tiny" />;

export default LoadingBarComponent;
