import useBodyScroll from 'hooks/useBodyScroll';
import React, { forwardRef, memo } from 'react';
import { ModalStyled, BackdropStyled } from './style';

type Props = {
  children: React.ReactNode;
  open?: boolean;
  onClose: (event: React.MouseEvent<HTMLDivElement>) => void;
  backdrop?: boolean;
  style?: React.CSSProperties;
  backdropStyle?: React.CSSProperties;
  blur?: boolean;
  className?: string;
};

/**
 *  The modal component provides a solid foundation for creating dialogs,
 * popovers, lightboxes, or whatever else.
 ** 💄 Manages modal stacking when one-at-a-time just isn't enough.
 ** 🔐 Creates a backdrop, for disabling interaction below the modal.
 ** 🔐 It disables scrolling of the page content while open.
 ** ♿️ It properly manages focus; moving to the modal content, and keeping it there until the modal is closed.
 ** ♿️ Adds the appropriate ARIA roles automatically.
 */
const Modal = forwardRef(({ children, open, onClose, style, className, backdrop, backdropStyle, blur }: Props, ref) => {
  useBodyScroll(!open);
  return (
    <>
      {backdrop && <BackdropStyled blur={blur} style={backdropStyle} onClick={onClose} open={open} />}
      <ModalStyled
        open={open}
        ref={ref}
        className={className}
        style={{
          ...style,
        }}
      >
        {children}
      </ModalStyled>
    </>
  );
});

Modal.displayName = 'Modal';

Modal.defaultProps = {
  style: {},
  backdropStyle: {},
  backdrop: true,
  blur: true,
  open: false,
  onClose: (event: React.MouseEvent<HTMLDivElement>) => {},
};

export default memo(Modal);
