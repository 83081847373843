import React from 'react';
import styled from 'styled-components';
import { ToastContainer, ToastContainerProps, toast as toastify, ToastOptions } from 'react-toastify';
import md5 from 'md5';

export const toast = (content: string, options?: ToastOptions): ReturnType<typeof toastify> => {
  return toastify(content, { ...(options || {}), toastId: md5(content) });
};

interface Props extends ToastContainerProps {
  className?: string;
}
export const WrappedToastContainer: React.FC<Props> = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export default styled(WrappedToastContainer).attrs({})`
  .Toastify__toast-container {
    left: 1em;
    bottom: 0;
  }
  .Toastify__toast {
    border-radius: 0.5em;
    text-align: left;
    padding: 1em;
    width: 12em;

    font-size: 0.9em;
  }
  .Toastify__toast--error {
    background-color: #fd5a3c;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
