import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from 'components/styled-components/common';

const buttonFontSizes = {
  small: '12px',
  medium: '15px',
  large: '18px',
  xlarge: '20px',
};

const Button = styled(PrimaryButton)<{ $fontSize: string; $mobileFontSize: string }>`
  &&& {
    font-size: ${({ fontSize }) => fontSize};
  }
`;

interface ISignInToPostButton {
  buttonText?: string;
  url?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}
const ActionButton = ({ buttonText = 'Button', url = '/login', size = 'medium' }: ISignInToPostButton) => {
  const history = useHistory();
  const handleRedirect = React.useCallback(() => history.push(url), []);

  return (
    <div>
      <Button fontSize={buttonFontSizes[size]} onClick={() => handleRedirect()}>
        {buttonText}
      </Button>
    </div>
  );
};

export default ActionButton;
