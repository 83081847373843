import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { sanitizeAssetUrl } from '../../util/assets';
import { getAxiosInstance } from './helper';
import { ImageType } from '../../redux/types/enums';
import { toast as customToast } from 'components/common/toast';
import { toast } from 'react-toastify';
import { RootState } from 'StoreModel';
import { store } from '../../redux/store';

export function uploadFile(
  bearer: string,
  uploadImage: {
    file: File | string;
    folder: string;
    communityId?: number;
  },
  imageType?: ImageType,
  isBigFile?: boolean,
): Promise<{ id: number; link: string }> {
  const { literals }: RootState = store.getState();
  const ai = getAxiosInstance();
  const toastId = customToast(literals.resource_upload_started_message, {
    type: 'info',
    progress: 0,
  });
  const config: AxiosRequestConfig = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (percentCompleted === 100) {
        toast.update(toastId, {
          render: `${literals.resource_upload_completed_message}`,
          progress: percentCompleted / 100,
          closeOnClick: true,
          pauseOnHover: false,
          autoClose: 2000,
        });
      } else
        toast.update(toastId, {
          render: `${literals.resource_upload_progress_message} ${percentCompleted}%`,
          progress: percentCompleted / 100,
          closeOnClick: false,
          pauseOnHover: false,
        });
    },
  };
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('folder', uploadImage.folder);
    formData.append('file', uploadImage.file);
    if (imageType) {
      formData.append('imageType', imageType.toString());
    }
    ai({
      method: 'POST',
      url: isBigFile ? '/api/File/Upload?isBigFile=true' : '/api/File/Upload',
      data: formData,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      ...config,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const res = {
          link: sanitizeAssetUrl(data),
          id: uploadImage.communityId ? uploadImage.communityId : 0,
        };
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function genericUploadFile(
  bearer: string,
  file: File,
  isBigFile?: boolean,
): Promise<{ fileName: string; url: string }> {
  const ai = getAxiosInstance();
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('file', file);
    data.append('imageType', ImageType.CkEditorImage.toString());
    data.append('folder', 'resource_files');
    ai({
      method: 'POST',
      url: isBigFile ? '/api/File/Upload?isBigFile=true' : '/api/File/Upload',
      data,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          fileName: `${file.name || 'new file'}`,
          url: response.data,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default {
  uploadFile,
  genericUploadFile,
};
