import { combineEpics } from 'redux-observable';
import accountEpics from '../epics/account';
import communityEpics from '../epics/community';
import discussionEpics from '../epics/discussion';
import feedEpics from '../epics/feed';
import fileUploadEpics from '../epics/file-upload';
import methodologyEpics from '../epics/methodology';
import projectEpics from '../epics/project';
import sessionEpics from '../epics/session';
import usersEpics from '../epics/users';
import businessModelEpics from '../epics/business-model';
import requestEpics from '../epics/request';
import applicationManagerEpics from '../epics/application-manager';
import formEpics from '../epics/form';
import tabEpics from '../epics/tab';
import projectSearchOverview from '../epics/project-overview';
import fetchRequestFeedbacksEpic from '../epics/project-request-feedback';
import calendarEpics from '../epics/calendar';
import fetchMultipleMeetingSettingsEpic from 'redux/epics/user-calendar';

export default combineEpics(
  ...Object.values(accountEpics),
  ...Object.values(communityEpics),
  ...Object.values(discussionEpics),
  ...Object.values(feedEpics),
  ...Object.values(fileUploadEpics),
  ...Object.values(methodologyEpics),
  ...Object.values(projectEpics),
  ...Object.values(sessionEpics),
  ...Object.values(usersEpics),
  ...Object.values(businessModelEpics),
  ...Object.values(requestEpics),
  ...Object.values(applicationManagerEpics),
  ...Object.values(formEpics),
  ...Object.values(tabEpics),
  ...Object.values(projectSearchOverview),
  ...Object.values(fetchRequestFeedbacksEpic),
  ...Object.values(fetchMultipleMeetingSettingsEpic),
  ...Object.values(calendarEpics),
);
