import {
  Community,
  Project,
  User,
  Methodology,
  SuperPanelCommunityStats,
  SuperPanelProjectStats,
  SuperPanelUserStats,
} from 'redux/types/account';
import { AxiosResponse } from 'axios';
import { getAxiosInstance, getDevelopmentStateList } from './helper';
import { _mapResponseToUser } from './account';
import moment from 'moment';
import { toast } from 'components/common/toast';

const ai = getAxiosInstance();

export function getAllCommunities(bearer: string): Promise<Community[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/GetAll`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getEnabledProjects(bearer: string): Promise<Project[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/GetEnabled',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getAllUsers(bearer: string): Promise<User[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/GetAll',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(
          data
            .map((user: any) => ({ ...user, skills: [], interests: [], name: `${user.firstName} ${user.lastName}` }))
            .map(_mapResponseToUser),
        );
      })
      .catch(err => reject(err));
  });
}

export function getAllMethodologies(bearer: string): Promise<Methodology[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Methodology/GetAll',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(
          data.map((methodology: any) => ({
            ...methodology,
            videoUrl: methodology.urlVideo,
            planner: {
              tasks: [],
              plannerType: methodology.taskUnlockRule,
            },
          })),
        );
      })
      .catch(err => reject(err));
  });
}

export function getCommunitiesStatistics(bearer: string): Promise<SuperPanelCommunityStats[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Stat/Communities',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getProjectsStatistics(bearer: string): Promise<SuperPanelProjectStats[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Stat/Projects',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const developmentStages: { [key: string]: string } = {};
        getDevelopmentStateList().forEach((developmentCategory: { key: string; value: string }[]) => {
          developmentCategory.forEach((developmentStage: { key: string; value: string }) => {
            developmentStages[developmentStage.key] = developmentStage.value;
          });
        });
        resolve(
          data.map((projectStat: SuperPanelProjectStats) => ({
            ...projectStat,
            projectDevelopmentStage: developmentStages[projectStat.projectDevelopmentStage],
            projectCreationDate: moment(projectStat.projectCreationDate).format('MMM Do YYYY'),
            lastUpdated: moment(projectStat.lastUpdated).format('MMM Do YYYY'),
            isProjectCreatedThisWeek: projectStat.isProjectCreatedThisWeek ? 'Yes' : 'No',
          })),
        );
      })
      .catch(err => reject(err));
  });
}

export function getUsersStatistics(bearer: string): Promise<SuperPanelUserStats[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Stat/Users',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(
          data.map((userStat: SuperPanelUserStats) => ({
            ...userStat,
            userLastLoginDate: moment(userStat.userLastLoginDate).format('MMM Do YYYY'),
            userRegistrationDate: moment(userStat.userRegistrationDate).format('MMM Do YYYY'),
            isUserCreatedThisWeek: userStat.isUserCreatedThisWeek ? 'Yes' : 'No',
          })),
        );
      })
      .catch(err => reject(err));
  });
}

export function banUser(bearer: string, userId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/BanUser',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function unbanUser(bearer: string, userId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/UnbanUser',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function disableUser(bearer: string, userId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/DisableUser',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function enableUser(bearer: string, userId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/EnableUser',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function togglePlatformAdminUser(bearer: string, userId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/TogglePlatformAdmin',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function saveProjectSuperAdmin(bearer: string, project: Project): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/SaveEditInfoAdminArea',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        canInviteMembersAndMentorsFromOutside: project.canInviteMembersAndMentorsFromOutside,
        canInviteMembersAndMentorsFromPlatform: project.canInviteMembersAndMentorsFromPlatform,
        userInvitationPolicy: project.userInvitationPolicy,
        id: project.id,
        name: project.name,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function saveCommunitySuperAdmin(bearer: string, community: Community): Promise<null> {
  return new Promise((resolve, reject) => {
    if (community.communitySkillCategories) {
      // filter to as backend automatically sets isActive/isVisible to true when id is 0, so we don't want to send them
      community.communitySkillCategories = community.communitySkillCategories
        .filter(skill => !(skill.isActive === false && skill.id === 0))
        .map(skill => ({
          ...skill,
          isVisible: skill.isActive,
        }));
    }
    ai({
      method: 'POST',
      url: '/api/Community/Save',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        ...community,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function deleteCommunitySuperAdmin(bearer: string, communityId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/Remove',
      params: {
        id: communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function renameMethodology(
  bearer: string,
  methodologyId: number,
  name: string,
): Promise<{ id: number; name: string }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Methodology/Rename',
      params: {
        id: methodologyId,
        name: name,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          id: methodologyId,
          name: name,
        });
      })
      .catch(err => reject(err));
  });
}

export function updateCommunityMethodology(bearer: string, methodologyId: number, communityId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Methodology/UpdateCommunity',
      data: {
        communityId,
        methodologyId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function createMethodology(
  bearer: string,
  name: string,
  isActive = true,
): Promise<{ name: string; id: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Methodology/Create',
      params: {
        name: name,
        isActive,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve({
          name: name,
          id: response.data.id,
        });
      })
      .catch(err => reject(err));
  });
}

export function updateMethodologySuperAdmin(
  bearer: string,
  methodology: Methodology,
): Promise<{
  nameChangeSuccess: boolean;
  communityChangeSuccess: boolean;
}> {
  return new Promise((resolve, reject) => {
    renameMethodology(bearer, methodology.id, methodology.name)
      .then(() => {
        updateCommunityMethodology(bearer, methodology.id, methodology.communityId || 0)
          .then(() => {
            resolve({
              nameChangeSuccess: true,
              communityChangeSuccess: true,
            });
          })
          .catch(() => {
            resolve({
              nameChangeSuccess: true,
              communityChangeSuccess: false,
            });
          });
      })
      .catch(() => {
        resolve({
          nameChangeSuccess: false,
          communityChangeSuccess: false,
        });
      });
  });
}

export function duplicateMethodology(bearer: string, methodologyId: number, communityId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/MethodologyContent/Duplicate',
      data: {
        methodologyId,
        communityId,
        onlyActive: true,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityDNS(
  communityId: number,
  bearer: string,
): Promise<{
  communityId: number;
  host: string;
  dnsId: number;
  mailAddress: string;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/CustomDns`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          host: response.data.host,
          dnsId: response.data.id,
          mailAddress: response.data.mailAddress,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function saveCommunityDNS(
  bearer: string,
  communityId: number,
  dnsId: number,
  host: string,
  certificate: File | undefined,
  certificatePassword: string,
  mailAddress: string | undefined,
): Promise<null> {
  const formData = new FormData();
  formData.append('host', host);
  communityId && formData.append('communityId', communityId.toString());
  if (certificate) {
    formData.append('certificate', certificate);
    formData.append('certificatePassword', certificatePassword);
  }
  if (mailAddress) formData.append('mailAddress', mailAddress);

  return new Promise((resolve, reject) => {
    ai({
      method: dnsId ? 'PUT' : 'POST',
      url: `/api/CustomDns${(dnsId && `/${dnsId}`) || ''}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: formData,
    })
      .then((response: AxiosResponse) => {
        toast('Custom DNS details updated successfully!', { type: 'success' });
        resolve(null);
      })
      .catch(err => {
        toast('Error updating custom DNS details!', { type: 'error' });
        reject(err);
      });
  });
}

export function deleteCommunityDNS(bearer: string, dnsId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/CustomDns/${dnsId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export default {
  getAllCommunities,
  getEnabledProjects,
  getAllUsers,
  getCommunitiesStatistics,
  getProjectsStatistics,
  getUsersStatistics,
  banUser,
  unbanUser,
  disableUser,
  enableUser,
  togglePlatformAdminUser,
  saveProjectSuperAdmin,
  deleteCommunitySuperAdmin,
  renameMethodology,
  fetchCommunityDNS,
  saveCommunityDNS,
  deleteCommunityDNS,
};
