import styled, { createGlobalStyle } from 'styled-components';
import React, { useMemo } from 'react';
import { Community, ApplicationDefinition } from 'redux/types/account';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import Image from '../components/common/lazyImage';

const AppContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    flex-direction: column;
    min-height: 100vh;
    display: flex;
  }
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 5em;
  background-color: #eee;
`;

const DockContainer = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 5em;
  background-color: #fff;
  display: none;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    position: sticky;
    height: auto;
    width: 100%;
    top: 0;
    z-index: 10;
  }
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 5em;
  height: 100%;
  background-color: #fff;
  width: 10em;
  margin-left: 2em;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 6em;
    width: 6em;
    background-color: white;
    left: calc(100% - 8em);
    z-index: 11;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  min-width: 30em;
  min-height: 100vh;
  position: relative;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    padding: 0 !important;
    min-width: auto;
  }
`;

const ShowDock = createGlobalStyle`
    #dock-container {
        display: flex;
    }
`;

export const PreviewContainer = styled.div`
  position: fixed;
  height: 100vh;
  overflow: auto;
  right: 0;
  top: 0;
  width: min(calc(100% - 45em), 40em);
  background: white;
  padding-left: 0.5em;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.7), -5px 0 5px -5px rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export const PreviewNavigation = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 0.5em;
  padding-left: 0.5em;
  height: 4rem;
  top: 0;
  position: sticky;
  align-items: center;
  background-color: white;
  z-index: 1;
`;

export const CoverContainer = styled.div`
  width: 100%;
  height: 20em;
  padding-left: 5em;
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    padding-left: 0;
  }
`;

export const Cover = styled(Image)`
  width: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: absolute;
`;

export const CoverTitle = styled.h1`
  position: absolute;
  bottom: 0em;
  left: 0em;
  color: #fff;
  font-weight: bold;
  font-size: 2em;
  padding-left: 7em;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey.secondLight};
  line-height: 2.5em;
  width: 100%;
  text-align: left;
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    text-align: center;
    padding: 0;
  }
`;

const mapStateToProps = (state: RootState) => ({
  selectedCommunity: state.account.selectedCommunity,
  applicationDefinitions: state.account.applicationDefinitions,
});

interface CoverComponentProps extends ReturnType<typeof mapStateToProps> {
  communityId?: number;
  applicationDefinitionId?: number;
  style?: React.CSSProperties;
}

const _CoverComponent: React.FC<CoverComponentProps> = props => {
  const { selectedCommunity, communityId, applicationDefinitionId, applicationDefinitions } = props;
  const applicationDefinition: ApplicationDefinition | null = useMemo(
    () =>
      applicationDefinitions &&
      applicationDefinitions.find(
        (applicationDefinition: ApplicationDefinition) => applicationDefinition.id === applicationDefinitionId,
      ),
    [applicationDefinitionId, applicationDefinitions],
  );
  const community: Community | null = useMemo(
    () => (communityId === selectedCommunity?.id ? selectedCommunity : null),
    [selectedCommunity],
  );

  const coverImage = useMemo(
    () => (community && community.coverImage) || (applicationDefinition && applicationDefinition.communityCover) || '',
    [community, applicationDefinition],
  );

  const name = applicationDefinition?.name || community?.name || '';

  return (
    <CoverContainer style={props.style}>
      <CoverContainer style={{ overflow: 'hidden', paddingLeft: '0em', position: 'relative' }}>
        {coverImage ? <Cover loading="eager" src={coverImage} /> : null}
        <CoverTitle>{name}</CoverTitle>
      </CoverContainer>
    </CoverContainer>
  );
};
const CoverComponent = connect(mapStateToProps, {})(_CoverComponent);

export { AppContainer, DockContainer, SidebarContainer, MainContainer, Header, ShowDock, CoverComponent };
