import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { ApplicationEntry, User, MyStuffItem, MyStuffReview } from 'redux/types/account';
import { Link } from 'react-router-dom';
import LoadingBar from '../common/loading-bar';
import { map, concat } from 'ramda';
import { Column, NoBackgroundButton, SecondaryButton } from '../styled-components/common';
import { MediumLogo } from '../common/logo';
import { Flag, RequestStatusEnum, Languages } from '../../redux/types/enums';
import Image from '../common/lazyImage';
import defaultProfilePicture from '../../assets/common/profile-placeholder.svg';
import { Rating } from 'semantic-ui-react';
import reviewArrow from '../../assets/common/review-arrow.svg';
import { scrollIdIntoView, scrollLayoutToTop } from 'util/utils';
import _StarIcon from '@material-ui/icons/Star';
import _StarBorderIcon from '@material-ui/icons/StarBorder';
import { saveProfileAsync } from '../../redux/actions/account';
import { getStatusLiteral } from '../../util/application-manager';

const StarIcon = styled(_StarIcon)`
  color: ${({ theme }) => theme.colors.blue.normal};
  font-size: 2em !important;
`;

const StarBorderIcon = styled(_StarBorderIcon)`
  color: ${({ theme }) => theme.colors.blue.normal};
  font-size: 2em !important;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 0.8em;
  margin: 0.5em 0;
  text-align: left;
  color: ${props => props.theme.colors.grey.dark};
  text-transform: uppercase;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ItemContainer = styled(Row)`
  padding: 0.7em 0;
  width: 100%;
  align-items: center;

  a {
    margin-right: 1em;
  }

  &:hover > .primary-community-star {
    opacity: 1;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    img {
      max-width: none !important;
    }
  }
`;

const ItemName = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  font-weight: bold;
  margin-bottom: 0;
`;

const ItemDescription = styled.p`
  font-size: 0.8em;
  line-height: 1em;
  margin-top: 0.5em;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2em;
`;

const RequestContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  height: 100%;
  padding-right: 10px;

  img {
    height: 100% !important;
    width: 100%;
    margin: 0;
  }
`;

const ReviewArrow = styled.img`
  margin-right: 5px;
`;

const AdvisorName = styled.span`
  color: black;
  text-decoration: none;
  font-size: 0.8em;
  line-height: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
`;

const DateLabel = styled.span`
  text-align: left;
  color: ${props => props.theme.colors.grey.medium};
  font-weight: 500;
  font-size: 0.7em;
  white-space: nowrap;
`;

const ReviewText = styled.div`
  margin-top: 10px;
  text-align: left;
  font-weight: 500;
  font-size: 0.8em;
`;

const ToggleReviewsSpan = styled.span`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 0.8em;
  color: ${props => props.theme.colors.blue.normal};

  :hover {
    cursor: pointer;
  }

  :active {
    color: ${props => props.theme.colors.grey.medium};
  }
`;

const StyledRating = styled(Rating)`
  &&& {
    margin-right: 10px;
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

const mapDispatchToProps = {
  saveProfile: saveProfileAsync.request,
};
type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  account: User;
  applications: ApplicationEntry[];
  isFetching: Flag;
  bearer: string;
}

const ReviewRow = ({ review, currentUserId }: { review: MyStuffReview; currentUserId: number }) => {
  let selectedReview;
  const currentUserIsAdvisor = currentUserId === review.advisorRating.userId;
  if (currentUserIsAdvisor) {
    selectedReview = review.entrepreneurRating;
  } else {
    selectedReview = review.advisorRating;
  }

  return (
    <Row>
      <ImageWrapper>
        <Image
          as={Link}
          to={`/user/${selectedReview.userId}`}
          style={{ width: '2em', height: '2em', objectFit: 'cover' }}
          circular={true}
          onError={(event: any) => (event.target.src = defaultProfilePicture)}
          src={selectedReview.userPhoto}
          alt={selectedReview.userName}
        />
      </ImageWrapper>
      <Column style={{ alignItems: 'flex-start' }}>
        <AdvisorName as={Link} to={`/user/${selectedReview.userId}`}>
          {`${selectedReview.userName}`}
          {currentUserIsAdvisor
            ? ` - advised by ${review.advisorRating.userName}`
            : ` - advised ${review.entrepreneurRating.userName}`}
        </AdvisorName>
        <Row style={{ lineHeight: '17px' }}>
          <StyledRating
            maxRating={5}
            defaultRating={selectedReview.rating}
            icon={'star'}
            disabled={true}
            size="large"
          />
          <DateLabel>{selectedReview.date.format('MMM D, YYYY')}</DateLabel>
        </Row>
        <ReviewText>
          <span>{selectedReview.comment}</span>
        </ReviewText>
      </Column>
    </Row>
  );
};

const MyStuff: React.FC<Props> = ({ account, isFetching, literals, saveProfile, bearer, applications }) => {
  const [showLessCommunities, setShowLessCommunities] = useState(true);
  const [showLessProjects, setShowLessProjects] = useState(true);
  const [showLessRequests, setShowLessRequests] = useState(true);
  const [showLessApplications, setShowLessApplications] = useState(true);
  const [expandedReviews, setExpandedReviews] = useState<number[]>([]);
  const [allCommunities, setAllCommunities] = useState<MyStuffItem[]>([]);

  useEffect(() => {
    if (account?.communities) {
      const primaryCommunity = account.communities.find((c: MyStuffItem) => c.id === account.primaryCommunityId);
      if (!primaryCommunity) {
        setAllCommunities(account.communities);
        return;
      }
      const nonPrimaryCommunities = account.communities.filter((c: MyStuffItem) => c.id !== account.primaryCommunityId);
      setAllCommunities([primaryCommunity, ...nonPrimaryCommunities]);
    }
  }, [account?.communities, account?.primaryCommunityId]);

  useEffect(() => {
    scrollIdIntoView();
  }, []);

  const expandReviews = (requestId: number): void => {
    setExpandedReviews([...expandedReviews, requestId]);
  };

  const collapseReviews = (requestId: number): void => {
    setExpandedReviews([...expandedReviews.filter(rId => rId !== requestId)]);
  };

  const areExpanded = (requestId: number): boolean => {
    return Boolean(expandedReviews.find(id => id === requestId));
  };

  const toggleReviews = (requestId: number) => {
    if (areExpanded(requestId)) {
      collapseReviews(requestId);
    } else {
      expandReviews(requestId);
    }
  };

  const handleChangePrimaryCommunity = (newPrimaryCommunityId: number) => {
    saveProfile({
      bearer,
      rawUser: account.rawUser,
      userId: account.id,
      country: account.countryId,
      city: account.city,
      role: account.occupation,
      bio: account.profile.biography,
      file: null,
      photo: account.photo,
      firstName: account.firstName || '',
      lastName: account.lastName || '',
      interests: account.profile.interests.map((c: { id: number }) => c.id),
      skills: account.profile.skills.map((c: { id: number }) => c.id),
      language: account.language as Languages,
      marketExpertises: account.profile.marketExpertises,
      sustainableDevelopmentGoals: account.profile.sustainableDevelopmentGoals,
      primaryCommunityId: newPrimaryCommunityId,
      company: account.company,
    });
  };

  const allProjects: MyStuffItem[] = concat(account.ownedProjects || [], account.followedProjects || []);
  return (
    <Container>
      {isFetching === Flag.Request && <LoadingBar />}

      {allCommunities.length > 0 && (
        <>
          <Label>{literals.community_list_title}</Label>
          <Column style={{ alignItems: 'center' }}>
            {map(
              community => (
                <ItemContainer key={community.id}>
                  <Link onClickCapture={scrollLayoutToTop} to={`/community/${community.id}`}>
                    <MediumLogo customSize={'64x64'} src={community.logo} alt={community.name} />
                  </Link>
                  <Column style={{ width: '80%' }}>
                    <Link
                      onClickCapture={scrollLayoutToTop}
                      to={`/community/${community.id}`}
                      style={{ color: 'black' }}
                    >
                      <ItemName>{community.name}</ItemName>
                    </Link>
                    <ItemDescription
                      style={{ lineHeight: '1.5em' }}
                      dangerouslySetInnerHTML={{ __html: community.description }}
                    />
                  </Column>
                  {community.id === account.primaryCommunityId ? (
                    <StarIcon />
                  ) : (
                    <StarBorderIcon
                      onClick={() => handleChangePrimaryCommunity(community.id)}
                      className="primary-community-star"
                    />
                  )}
                  <SecondaryButton disabled color="black">
                    {community.circleName}
                  </SecondaryButton>
                </ItemContainer>
              ),
              showLessCommunities ? allCommunities.slice(0, 5) : allCommunities,
            )}
            {allCommunities.length > 5 && (
              <NoBackgroundButton onClick={() => setShowLessCommunities(!showLessCommunities)}>
                {showLessCommunities
                  ? literals.community_tag_list_load_more
                  : literals.user_profile_ctrl_all_communities_visibles_collapse}
              </NoBackgroundButton>
            )}
          </Column>
        </>
      )}

      {allProjects.length > 0 && (
        <>
          <Label>{literals.project_list_title}</Label>
          <Column style={{ alignItems: 'center' }}>
            {map(
              project => (
                <ItemContainer key={project.id}>
                  <Link onClickCapture={scrollLayoutToTop} to={`/project/${project.id}`}>
                    <MediumLogo customSize={'64x64'} src={project.logo} alt={project.name} />
                  </Link>
                  <Column style={{ width: '80%' }}>
                    <Link onClickCapture={scrollLayoutToTop} to={`/project/${project.id}`} style={{ color: 'black' }}>
                      <ItemName>{project.name}</ItemName>
                    </Link>
                    <ItemDescription style={{ lineHeight: '1.5em' }}>{project.description}</ItemDescription>
                  </Column>
                  <SecondaryButton disabled color="black">
                    {project.circleName}
                  </SecondaryButton>
                </ItemContainer>
              ),
              showLessProjects ? allProjects.slice(0, 5) : allProjects,
            )}
            {allProjects.length > 5 && (
              <NoBackgroundButton onClick={() => setShowLessProjects(!showLessProjects)}>
                {showLessProjects
                  ? literals.community_tag_list_load_more
                  : literals.user_profile_ctrl_all_communities_visibles_collapse}
              </NoBackgroundButton>
            )}
          </Column>
        </>
      )}

      {account?.requests?.length > 0 && (
        <>
          <Label id="my-requests">{literals.community_menu_requests_option}</Label>
          <Column style={{ alignItems: 'center' }}>
            {map(
              request => (
                <RequestContainer key={request.id}>
                  <ItemContainer key={request.id}>
                    <Link onClickCapture={scrollLayoutToTop} to={`/project/${request.projectId}/request/${request.id}`}>
                      <MediumLogo customSize={'64x64'} src={request.projectLogoImage} alt={request.name} />
                    </Link>
                    <Column style={{ width: '80%' }}>
                      <Link
                        onClickCapture={scrollLayoutToTop}
                        to={`/project/${request.projectId}/request/${request.id}`}
                        style={{ color: 'black' }}
                      >
                        <ItemName>{request.name}</ItemName>
                      </Link>
                    </Column>
                    <SecondaryButton disabled color="black">
                      {RequestStatusEnum[request.requestStatus]}
                    </SecondaryButton>
                  </ItemContainer>
                  {request.requestStatus === 2 && request.reviews.length > 0 && (
                    <>
                      <ToggleReviewsSpan onClick={() => toggleReviews(request.id)}>
                        <ReviewArrow src={reviewArrow} alt="reviews" />
                        {`${areExpanded(request.id) ? literals.hide_comments : literals.show_comments}`}
                      </ToggleReviewsSpan>
                      {areExpanded(request.id) && (
                        <ReviewContainer>
                          {request.reviews.map(review => (
                            <ReviewRow key={review.id} currentUserId={account.id} review={review} />
                          ))}
                        </ReviewContainer>
                      )}
                    </>
                  )}
                </RequestContainer>
              ),
              showLessRequests ? account.requests.slice(0, 5) : account.requests,
            )}
            {account.requests.length > 5 && (
              <NoBackgroundButton onClick={() => setShowLessRequests(!showLessRequests)}>
                {showLessRequests
                  ? literals.community_tag_list_load_more
                  : literals.user_profile_ctrl_all_communities_visibles_collapse}
              </NoBackgroundButton>
            )}
          </Column>
        </>
      )}

      {applications.length > 0 && (
        <>
          <Label>Applications</Label>
          <Column style={{ alignItems: 'center' }}>
            {(showLessApplications ? applications.slice(0, 5) : applications).map(application => (
              <ItemContainer key={application.id}>
                <Link
                  to={`/application-definition/${application.applicationDefinitionId}/application/${application.id}`}
                  onClickCapture={scrollLayoutToTop}
                >
                  <MediumLogo src={application.communityLogoImage} alt={application.applicantName} />
                </Link>
                <Column style={{ width: '80%' }}>
                  <Link
                    onClickCapture={scrollLayoutToTop}
                    to={`/application-definition/${application.applicationDefinitionId}/application/${application.id}`}
                    style={{ color: 'black' }}
                  >
                    <ItemName>
                      {application.applicationDefinitionName ||
                        (application.communityName && `Application ${application.communityName}`) ||
                        application.project?.name ||
                        `Application #${application.id}`}
                    </ItemName>
                  </Link>
                  <ItemDescription></ItemDescription>
                </Column>
                <SecondaryButton disabled color="black">
                  {getStatusLiteral(application.status, literals)}
                </SecondaryButton>
              </ItemContainer>
            ))}
            {applications.length > 5 && (
              <NoBackgroundButton onClick={() => setShowLessApplications(!showLessApplications)}>
                {showLessApplications
                  ? literals.community_tag_list_load_more
                  : literals.user_profile_ctrl_all_communities_visibles_collapse}
              </NoBackgroundButton>
            )}
          </Column>
        </>
      )}
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStuff);
