export const Z_INDEX_STACK: { WIDGET: number; OVERLAY: number; BACKDROP: number; MODAL: number } = {
  WIDGET: 10,
  OVERLAY: 20,
  BACKDROP: 30,
  MODAL: 40,
};

export const ICON_SIZES = {
  small: '24px',
  medium: '32px',
  large: '48px',
};

export const SIZE: any = {
  xxs: '320px',
  xs: '475px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1440px',
  fhd: '1920px',
};

export const DEVICE: any = {
  xxs: `(min-width: ${SIZE.xxs})`,
  xs: `(min-width: ${SIZE.xs})`,
  sm: `(min-width: ${SIZE.sm})`,
  md: `(min-width: ${SIZE.md})`,
  lg: `(min-width: ${SIZE.lg})`,
  xl: `(min-width: ${SIZE.xl})`,
  xxl: `(min-width: ${SIZE.xxl})`,
  fhd: `(min-width: ${SIZE.fhd})`,
};

export const FONT: any = {
  subtitle1: {
    fontSize: '28px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    color: '#666',

    xl: {
      fontSize: '36px',
    },
  },

  subtitle2: {
    fontSize: '28px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    fontWeight: 500,
    '-webkit-text-stroke': '0.5px',
    color: '#000',
    xl: {
      fontSize: '36px',
    },
  },

  subtitle3: {
    fontSize: '20px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    fontWeight: 500,
    '-webkit-text-stroke': '0.5px',
    color: '#000',
  },

  subtitle4: {
    fontSize: '23px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },

  body1: {
    color: '#000',
    fontSize: '18px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },

  body2: {
    color: '#000',
    fontSize: '15px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },

  body3: {
    color: '#000',
    fontSize: '18px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },

  body4: {
    color: '#666',
    fontSize: '15px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
};
