import React, { useState, useMemo, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import Image from './lazyImage';
import { useHistory } from 'react-router-dom';
import { SecondaryButton, NoBackgroundButton } from '../styled-components/common';
import { Project, MyStuffItem, Community } from 'redux/types/account';
import { Community as UserCommunity } from 'UsersModels';
import { Dropdown } from 'semantic-ui-react';
import _LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import ChangeCircleIcon from '@material-ui/icons/DonutLarge';
import ChangeCircleModal from '../../components/user-profile/change-circle-modal';
import TodayIcon from '@material-ui/icons/Today';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { isLink } from '../../util/utils';
import InviteToProjectModal from '../../components/user-profile/invite-to-project';
import { editCommunityCircleAsync, fetchCommunityCirclesAsync } from '../../redux/actions/community';
import accountSessionBearerSelector from 'redux/selectors/account/accountSessionBearerSelector';
import { fetchGetPermissionsForUser } from 'services/api/community';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const LinkedInIcon = styled(_LinkedInIcon)`
  &&& {
    font-size: 1.5em;
    margin-left: 0.5em;
    color: #0077b7;
  }
  &:hover {
    cursor: pointer;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25em;
  margin-top: 2em;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    font-size: 1.5em;
    margin-top: 0;
  }
`;

const Name = styled.p`
  font-size: 1.2em;
  line-height: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
`;

const Headline = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin: 0;
`;

const ActionButton = styled(NoBackgroundButton)`
  &&& {
    padding-left: 0;
    color: ${({ theme }) => theme.colors.blue.normal};
    text-transform: none;
  }
`;

const RedActionButton = styled(ActionButton)`
  &&& {
    padding-left: 0;
    color: ${({ theme }) => theme.colors.orange.dark};
    text-transform: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 1em;
  margin-left: auto;
`;

const StyledImage = styled(Image)`
  &&& {
    height: 10em;
    width: 10em;
    object-fit: cover;
    margin-right: 2em;
  }
`;

const DropdownItem = styled(Dropdown.Item)`
  display: flex !important;
  flex-direction: row;
  align-items: center;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  currentUserProjects: state.account.details.user.ownedProjects,
  currentUserCommunities: state.account.details.user.communities,
  editCommunityCircleFlag: state.loading.editCommunityCircleFlag,
  bearer: state.account.session.session.bearer,
  communities: state.account.communities.list,
});

const mapDispatchToProps = {
  editCommunityCircle: editCommunityCircleAsync.request,
  fetchCommunityCircles: fetchCommunityCirclesAsync.request,
};

type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  profile: {
    id: number;
    photo: string;
    name: string;
    occupation: string;
    countryName: string;
    city: string;
    isFollowedByMe?: boolean;
    linkedInUrl?: string;
    projects: Project[];
    followedProjects: Project[];
    communities: UserCommunity[];
  };
  showAccountButtons?: boolean;
  showSendMessage?: boolean;
  signOut?: any;
  sendMessage?: any;
  scheduleMeeting?: any;
  toggleFollowUser?: any;
  isLoadingFollowButton?: boolean;
}

const UserCard: React.FC<Props> = ({
  profile,
  showAccountButtons,
  signOut,
  showSendMessage,
  sendMessage,
  scheduleMeeting,
  toggleFollowUser,
  isLoadingFollowButton,
  literals,
  currentUserProjects,
  currentUserCommunities,
  editCommunityCircle,
  bearer,
  communities,
  fetchCommunityCircles,
  editCommunityCircleFlag,
}) => {
  const [isInvitingUserToProject, setIsInvitingUserToProject] = useState<boolean>(false);
  const [changeCommunityCircle, setChangeCommunityCircle] = useState<UserCommunity | undefined>(undefined);
  const authToken = useSelector(accountSessionBearerSelector);
  const history = useHistory();
  const [permissions, setPermissions] = useState<any>({ canSendMessages: false, canScheduleMeetings: false });

  const currentUserOwnedProjects = (currentUserProjects || []).filter(
    (project: MyStuffItem) => project.circleName === 'Team',
  );
  const commonCommunitiesOwnedByCurrentUser = (profile.communities || []).filter((uc: UserCommunity) =>
    (currentUserCommunities || []).find((c: MyStuffItem) => uc.id === c.id && c.circleName === 'Admins'),
  );

  const editProfile = () => {
    history.push('/edit-profile');
  };

  const projectsCircleHash = useMemo(() => {
    const teamProjects = (profile.projects || []).reduce((acc, val) => ({ ...acc, [val.id]: 'Team' }), {});
    return (profile.followedProjects || []).reduce((acc, val) => ({ ...acc, [val.id]: 'Follower' }), teamProjects);
  }, [profile.projects]);

  const getUserCity = () => {
    if (profile.city && profile.countryName) return `${profile.city}, ${profile.countryName}`;
    return profile.city || profile.countryName || '';
  };

  useEffect(() => {
    if (profile) {
      fetchGetPermissionsForUser(profile.id, authToken)
        .then((out: any) => {
          setPermissions(out);
        })
        .catch(console.error);
    }
  }, [profile?.id, authToken]);

  return (
    <Row>
      <div>
        <StyledImage src={profile.photo} circular={true} />
      </div>
      <InfoContainer>
        <Name>
          {profile.name}{' '}
          {profile.linkedInUrl && isLink(profile.linkedInUrl) && (
            <LinkedInIcon
              onClick={() => {
                window.open(profile.linkedInUrl);
              }}
            />
          )}
        </Name>
        <Headline>{profile.occupation}</Headline>
        <Headline style={{ marginTop: '.5em' }}>{getUserCity()}</Headline>
        {showAccountButtons && (
          <Row>
            <ActionButton onClick={editProfile}>{literals.account_edit_profile}</ActionButton>
            <RedActionButton onClick={signOut}>{literals.account_sign_out}</RedActionButton>
          </Row>
        )}
      </InfoContainer>
      {showSendMessage && (
        <ButtonContainer className="nested-dropdown-fix">
          <SecondaryButton
            style={{ width: '100%' }}
            loading={isLoadingFollowButton === true}
            onClick={() => {
              if (toggleFollowUser) {
                toggleFollowUser();
              }
            }}
          >
            {profile?.isFollowedByMe
              ? literals.user_ctrl_initializer_unfollow_button
              : literals.user_ctrl_initializer_follow_button}
          </SecondaryButton>
          <Dropdown
            disabled={Object.values(permissions).every((p: any) => p === false)}
            icon={<></>}
            trigger={
              <SecondaryButton style={{ width: '100%' }}> {literals.log_on_footer_actions_title} </SecondaryButton>
            }
          >
            <Dropdown.Menu style={{ right: '0', left: 'unset' }}>
              {permissions?.canSendMessages && (
                <DropdownItem
                  onClick={() => {
                    if (sendMessage) {
                      sendMessage();
                    }
                  }}
                >
                  <EmailIcon /> {literals.send_message_title}
                </DropdownItem>
              )}
              {permissions?.canScheduleMeetings && (
                <DropdownItem
                  onClick={() => {
                    if (scheduleMeeting) {
                      scheduleMeeting();
                    }
                  }}
                >
                  <TodayIcon />
                  {literals.events_schedule_a_meeting_button}
                </DropdownItem>
              )}
              {commonCommunitiesOwnedByCurrentUser.length > 0 && (
                <DropdownItem>
                  <Dropdown
                    style={{ display: 'flex', alignItems: 'center' }}
                    scrolling
                    onClick={(evt: React.SyntheticEvent) => {
                      evt.stopPropagation();
                    }}
                    direction="left"
                    trigger={
                      <DropdownItem>
                        <ChangeCircleIcon />
                        {literals.member_options_change_circle}
                      </DropdownItem>
                    }
                  >
                    <Dropdown.Menu>
                      {commonCommunitiesOwnedByCurrentUser.map((community: UserCommunity) => (
                        <DropdownItem
                          onClick={() => {
                            setChangeCommunityCircle(community);
                            const c = communities.find((c: Community) => c.id === community.id);
                            if (!c) return;
                            if (!c.circles || c.circles.length === 0) {
                              fetchCommunityCircles({
                                bearer,
                                communityId: community.id,
                              });
                            }
                          }}
                          key={community.id}
                        >
                          {community.name}
                        </DropdownItem>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </DropdownItem>
              )}
              {currentUserOwnedProjects?.length > 0 && (
                <DropdownItem
                  onClick={() => {
                    setIsInvitingUserToProject(true);
                  }}
                >
                  <PersonAddIcon />
                  {literals.invite_to_projects}
                </DropdownItem>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </ButtonContainer>
      )}
      {isInvitingUserToProject && (
        <InviteToProjectModal
          teamMemberProjects={projectsCircleHash}
          userName={profile.name}
          userId={profile.id}
          closeModal={() => {
            setIsInvitingUserToProject(false);
          }}
        />
      )}
      {changeCommunityCircle !== undefined && (
        <ChangeCircleModal
          open={true}
          isLoading={editCommunityCircleFlag}
          circles={communities.find((c: Community) => c.id === changeCommunityCircle.id)?.circles || []}
          saveCircle={(circleId: number, circleName: string) => {
            editCommunityCircle({
              communityId: changeCommunityCircle.id,
              bearer: bearer,
              circleId: circleId.toString(),
              userId: profile.id.toString(),
              circleName: circleName,
            });
          }}
          currentCircleName={
            commonCommunitiesOwnedByCurrentUser.find((c: UserCommunity) => c.id === changeCommunityCircle.id)
              ?.circleName
          }
          close={() => {
            setChangeCommunityCircle(undefined);
          }}
        />
      )}
    </Row>
  );
};

UserCard.defaultProps = {
  showAccountButtons: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
