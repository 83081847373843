import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { SidebarItem, SidebarList } from '../styled-components/sidebar';
import { Separator } from '../styled-components/common';
import { Logo } from './logo';
import { Project, Community } from 'redux/types/account';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Team from '../project/team';
import { FullWidthSidebarContainer } from './fullWidthCommunitySidebar';
import { find } from 'ramda';
import { scrollLayoutToTop } from 'util/utils';
import { StyledButton } from 'primitives/Button/style';

const MenuOpenIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    font-size: 3em;
    cursor: pointer;
  }
`;

const MenuCloseIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 6em;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blue.lightGrey};
  margin-bottom: 1em;
  font-size: 0.7em;

  svg {
    font-size: 3em;
    cursor: pointer;
    margin-right: 0.3em;
  }
`;

const mapStateToProps = (state: RootState) => ({
  projects: state.account.projects.list,
  selectedCommunity: state.account.selectedCommunity,
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {}

interface ProjectTitleProps {
  children?: string;
}
const ProjectTitle: React.FC<ProjectTitleProps> = props => {
  const { children } = props;
  if (children) {
    if (children.length > 13) {
      return <h3 style={{ lineHeight: '1.5em' }}>{children}</h3>;
    }
    return <h2 style={{ lineHeight: '1.5em' }}>{children}</h2>;
  } else {
    return null;
  }
};

const FullWidthProjectSidebar: React.FC<Props> = ({ projects, literals, selectedCommunity }) => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const pId = parseInt(projectId as string);
  const project = find((project: Project) => project.id === pId, projects);
  // const firstCommunityId = project ? (project?.communityIds.length > 0 ? project?.communityIds[0] : 0) : 0;
  const community: Community | null = useMemo(() => selectedCommunity, [selectedCommunity]);

  useEffect(() => {
    if (setSideMenuIsOpen) setSideMenuIsOpen(false);
  }, [location.pathname]);

  if (!project) {
    return null;
  }

  if (!sideMenuIsOpen) {
    return (
      <MenuOpenIconContainer>
        <MenuIcon onClick={() => setSideMenuIsOpen(true)} />
      </MenuOpenIconContainer>
    );
  }
  return (
    <FullWidthSidebarContainer>
      <MenuCloseIconContainer>
        <CloseIcon onClick={() => setSideMenuIsOpen(false)} />
      </MenuCloseIconContainer>
      <SidebarList>
        <StyledButton
          as={Link}
          onClickCapture={scrollLayoutToTop}
          style={{ fontWeight: 'bold', padding: '0.4em 0.4em' }}
          to={`/community/${community?.id}/projects`}
        >
          {`<< `}
          {literals.sidebar_back_to_community}
        </StyledButton>
      </SidebarList>
      <div>
        <Logo src={project.logo} />
      </div>
      <ProjectTitle>{project.name}</ProjectTitle>
      <SidebarList>
        <Separator />
        <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}`} hasNotifications={false}>
          {literals.menu_home}
        </SidebarItem>
        <SidebarItem
          onClickCapture={scrollLayoutToTop}
          to={`/project/${projectId}/business-model`}
          hasNotifications={false}
        >
          {literals.primitive_program}
        </SidebarItem>
        <Separator />
        {community?.isRequestsTabVisible && (
          <SidebarItem
            onClickCapture={scrollLayoutToTop}
            to={`/project/${projectId}/requests`}
            hasNotifications={false}
          >
            {literals.sidebar_options_coaching}
          </SidebarItem>
        )}
        {((project.kpiDefinitions && project.kpiDefinitions.length > 0) ||
          !project.kpiDefinitions ||
          community?.isCommunityAdmin) && (
          <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}/kpis`} hasNotifications={false}>
            {literals.menu_kpis}
          </SidebarItem>
        )}
        <SidebarItem
          onClickCapture={scrollLayoutToTop}
          to={`/project/${projectId}/discussions`}
          hasNotifications={false}
        >
          {literals.project_discussion_ctrl_title}
        </SidebarItem>
        <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}/resources`} hasNotifications={false}>
          {literals.community_menu_resources_option}
        </SidebarItem>
        <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}/network`} hasNotifications={false}>
          {literals.project_user_list_ctrl_title}
        </SidebarItem>
        <Separator />
        <Team />
      </SidebarList>
    </FullWidthSidebarContainer>
  );
};

export default connect(mapStateToProps, {})(FullWidthProjectSidebar);
