import { Moment } from 'moment';
import {
  Request,
  SaveRequest,
  RequestFilteringSkill,
  Project,
  SaveCoachingSession,
  InvitedAdvisor,
} from 'redux/types/account';
import { RequestStatusEnum } from 'redux/types/enums';
import { createAsyncAction } from 'typesafe-actions';

export const fetchRequestAsync = createAsyncAction(
  'FETCH_REQUEST_REQUEST',
  'FETCH_REQUEST_SUCCESS',
  'FETCH_REQUEST_FAILURE',
  'FETCH_REQUEST_IDLE',
)<{ id: number; bearer: string }, { request: Request }, string, null>();

export const updateCommunityRequestTagsAsync = createAsyncAction(
  'UPDATE_COMMUNITY_REQUEST_TAGS_REQUEST',
  'UPDATE_COMMUNITY_REQUEST_TAGS_SUCCESS',
  'UPDATE_COMMUNITY_REQUEST_TAGS_FAILURE',
  'UPDATE_COMMUNITY_REQUEST_TAGS_IDLE',
)<
  { id: number; bearer: string; tags: Array<string>; communityId: number },
  { tags: Array<string>; requestId: number; communityId: number },
  string,
  null
>();

export const fetchCommunityRequestsTagsAsync = createAsyncAction(
  'FETCH_COMMUNITY_REQUESTS_TAGS_REQUEST',
  'FETCH_COMMUNITY_REQUESTS_TAGS_SUCCESS',
  'FETCH_COMMUNITY_REQUESTS_TAGS_FAILURE',
  'FETCH_COMMUNITY_REQUESTS_TAGS_IDLE',
)<{ communityId: number; bearer: string }, { requestsTags: string[]; communityId: number }, string, null>();

export const deleteRequestAsync = createAsyncAction(
  'DELETE_REQUEST_REQUEST',
  'DELETE_REQUEST_SUCCESS',
  'DELETE_REQUEST_FAILURE',
  'DELETE_REQUEST_IDLE',
)<
  { bearer: string; entityType: string; entityId: number; request: Request },
  { entityType: string; entityId: number; request: Request },
  string,
  null
>();

export const closeRequestAsync = createAsyncAction(
  'CLOSE_REQUEST_REQUEST',
  'CLOSE_REQUEST_SUCCESS',
  'CLOSE_REQUEST_FAILURE',
  'CLOSE_REQUEST_IDLE',
)<{ bearer: string; requestId: number }, { requestId: number }, string, null>();

export const saveRequestAsync = createAsyncAction(
  'SAVE_REQUEST_REQUEST',
  'SAVE_REQUEST_SUCCESS',
  'SAVE_REQUEST_FAILURE',
  'SAVE_REQUEST_IDLE',
)<{ bearer: string; projectId: number; request: SaveRequest }, { projectId: number; request: Request }, string, null>();

export const scheduleRequestSessionAsync = createAsyncAction(
  'SCHEDULE_REQUEST_SESSION_REQUEST',
  'SCHEDULE_REQUEST_SESSION_SUCCESS',
  'SCHEDULE_REQUEST_SESSION_FAILURE',
  'SCHEDULE_REQUEST_SESSION_IDLE',
)<
  {
    bearer: string;
    requestId: number;
    projectId: number;
    advisorId: number;
    summary: string;
    description: string;
    start: Moment;
    end: Moment;
  },
  {
    projectId: number;
    requestId: number;
    requestStatus: RequestStatusEnum;
    advisorId: number;
    start: string;
    location: string;
    attendeeUsers: InvitedAdvisor[];
  },
  string,
  null
>();

export const deleteRequestCommentAsync = createAsyncAction(
  'DELETE_REQUEST_COMMENT_REQUEST',
  'DELETE_REQUEST_COMMENT_SUCCESS',
  'DELETE_REQUEST_COMMENT_FAILURE',
  'DELETE_REQUEST_COMMENT_IDLE',
)<
  { bearer: string; projectId: number; requestId: number; commentId: number },
  { projectId: number; requestId: number; commentId: number },
  string,
  null
>();

export const fetchCommunityFilteringSkillsAsync = createAsyncAction(
  'FETCH_COMMUNITY_FILTERING_SKILLS_REQUEST',
  'FETCH_COMMUNITY_FILTERING_SKILLS_SUCCESS',
  'FETCH_COMMUNITY_FILTERING_SKILLS_FAILURE',
  'FETCH_COMMUNITY_FILTERING_SKILLS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; skills: RequestFilteringSkill[] }, string, null>();

export const fetchProjectFilteringSkillsAsync = createAsyncAction(
  'FETCH_PROJECT_FILTERING_SKILLS_REQUEST',
  'FETCH_PROJECT_FILTERING_SKILLS_SUCCESS',
  'FETCH_PROJECT_FILTERING_SKILLS_FAILURE',
  'FETCH_PROJECT_FILTERING_SKILLS_IDLE',
)<
  { bearer: string; communityId: number; projectId: number },
  { projectId: number; skills: RequestFilteringSkill[] },
  string,
  null
>();

export const createNewRequestAsync = createAsyncAction(
  'CREATE_NEW_REQUEST',
  'CREATE_NEW_SUCCESS',
  'CREATE_NEW_FAILURE',
  'CREATE_NEW_IDLE',
)<
  { projectId: number; isProjectAdmin?: boolean; replaceLocation?: boolean },
  { projectId: number; isProjectAdmin?: boolean; replaceLocation?: boolean },
  any,
  any
>();

export const submitPendingFeedbackAsync = createAsyncAction(
  'SUBMIT_PENDING_FEEDBACK_REQUEST',
  'SUBMIT_PENDING_FEEDBACK_SUCCESS',
  'SUBMIT_PENDING_FEEDBACK_FAILURE',
  'SUBMIT_PENDING_FEEDBACK_IDLE',
)<{ project: Project }, null, string, null>();

export const fetchRequestsPendingFeedbackAsync = createAsyncAction(
  'FETCH_REQUESTS_PENDING_FEEDBACK_REQUEST',
  'FETCH_REQUESTS_PENDING_FEEDBACK_SUCCESS',
  'FETCH_REQUESTS_PENDING_FEEDBACK_FAILURE',
  'FETCH_REQUESTS_PENDING_FEEDBACK_IDLE',
)<void, any, string, null>();

export const saveCoachingSessionAsync = createAsyncAction(
  'SAVE_COACHING_SESSION_REQUEST',
  'SAVE_COACHING_SESSION_SUCCESS',
  'SAVE_COACHING_SESSION_FAILURE',
  'SAVE_COACHING_SESSION_IDLE',
)<
  { bearer: string; coachingSession: SaveCoachingSession },
  { coachingSession: { id: number; projectId: number } },
  string,
  null
>();

export default {
  fetchRequestsPendingFeedbackAsync,
  createNewRequestAsync,
  submitPendingFeedbackAsync,
  fetchRequestAsync,
  updateCommunityRequestTagsAsync,
  deleteRequestAsync,
  closeRequestAsync,
  saveRequestAsync,
  deleteRequestCommentAsync,
  fetchCommunityFilteringSkillsAsync,
  fetchProjectFilteringSkillsAsync,
  saveCoachingSessionAsync,
  scheduleRequestSessionAsync,
};
