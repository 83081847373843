import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { version } from '../package.json';
import { RootState } from 'StoreModel';
import App from './App';
import { unregister } from './serviceWorker';
import { store } from './redux/store';
import { gdprConsent, featuresCookieCategories } from './util/utils';
import ReactGA from 'react-ga4';

/** Remove console logs from production environment */
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
} else {
  const savedConsoleLog = console.log;
  console.log = (...args) => {
    const stackTrace = Error().stack || '';
    savedConsoleLog(stackTrace.split('\n')[2]?.trim().split(' ')[1] || 'Unknown file', ...args);
  };
}

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID || '',
    gaOptions: {
      anonymizeIp: !gdprConsent(featuresCookieCategories.googleAnalytics),
    },
  },
]);

const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY || '';

if (BUGSNAG_API_KEY && process.env.NODE_ENV === 'production' && window.origin === 'https://app.babele.co') {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appVersion: version,
    plugins: [new BugsnagPluginReact()],
    generateAnonymousId: gdprConsent(featuresCookieCategories.bugsnag),
    onError: function (event) {
      const state: RootState = store.getState();
      const user = state.account.details.user;
      event.setUser(user.id, user.email, user.name);
    },
  });

  const ReactPlugin = Bugsnag.getPlugin('react');
  if (ReactPlugin) {
    const ErrorBoundary = ReactPlugin.createErrorBoundary(React);
    ReactDOM.render(
      <ErrorBoundary>
        <App />
      </ErrorBoundary>,
      document.getElementById('root'),
    );
  } else {
    ReactDOM.render(<App />, document.getElementById('root'));
  }
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
