import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { SidebarItem, SidebarList, SidebarContainer } from '../styled-components/sidebar';
import { useParams } from 'react-router-dom';
import { Separator } from '../styled-components/common';
import { scrollLayoutToTop } from 'util/utils';
import { Community } from 'redux/types/account';

const Heading = styled.h3`
  font-size: 1.5em;
  margin-top: 0;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  selectedCommunity: state.account.selectedCommunity,
});

interface Props extends ReturnType<typeof mapStateToProps> {}

const Sidebar: React.FC<Props> = ({ literals, selectedCommunity }) => {
  const { communityId } = useParams<{ communityId: string }>();
  const community: Community | null = selectedCommunity;

  return (
    <SidebarContainer>
      <SidebarList aria-label={'Admin panel Side'}>
        <SidebarItem
          aria-label={'Go back to previous page'}
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}`}
          hasNotifications={false}
        >
          ← {literals.profile_wizard_02_back_button}
        </SidebarItem>
        <Separator />
        <Heading>{literals.community_menu_admin_panel}</Heading>
        <SidebarItem
          aria-label={'Go to community settings'}
          testId="community-settings"
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/community-settings`}
          hasNotifications={false}
        >
          {literals.project_edit_general_tab}
        </SidebarItem>
        <SidebarItem
          aria-label={'Go to methodology'}
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/methodology`}
          hasNotifications={false}
        >
          {literals.planning_methodologies}
        </SidebarItem>
        {community?.isApplicationsTabVisible && (
          <SidebarItem
            aria-label={'Go to application'}
            to={`/community/${communityId}/admin-panel/application-definitions`}
            onClickCapture={scrollLayoutToTop}
            hasNotifications={false}
            alternativeActivePaths={[`/community/${communityId}/admin-panel/application-definition`]}
          >
            {literals.sidebar_applications}
          </SidebarItem>
        )}
        <SidebarItem
          aria-label={'Go to forms'}
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/forms`}
          hasNotifications={false}
        >
          {literals.sidebar_option_forms}
        </SidebarItem>
        <SidebarItem
          aria-label={'Go to coaching sessions status'}
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/requests-status`}
          hasNotifications={false}
        >
          {literals.sidebar_options_coaching}
        </SidebarItem>
        <SidebarItem
          aria-label={'Go to overview of projects'}
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/overview-projects`}
          hasNotifications={false}
        >
          {literals.primitive_startups}
        </SidebarItem>
        <SidebarItem
          aria-label={'Go to circles and roles'}
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/circle-management`}
          hasNotifications={false}
        >
          {literals.community_menu_circles_and_roles}
        </SidebarItem>
        <SidebarItem
          aria-label={'Go to tabs'}
          testId="community-settings-tabs"
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/tabs`}
          hasNotifications={false}
        >
          {literals.community_menu_tabs_settings}
        </SidebarItem>
        <SidebarItem
          aria-label={'Go to statistics'}
          onClickCapture={scrollLayoutToTop}
          to={`/community/${communityId}/admin-panel/statistics`}
          hasNotifications={false}
        >
          {literals.community_menu_statistics}
        </SidebarItem>
      </SidebarList>
    </SidebarContainer>
  );
};

export default connect(mapStateToProps, {})(Sidebar);
