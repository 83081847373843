import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Dimmer, Divider, Form, Input, Loader, Label, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Image, { LazyImage } from '../common/lazyImage';
import { FormControl, InputLabel, Select, Switch } from '@material-ui/core';
import { Dropdown } from '../common/dropdown';
import _PublicIcon from '@material-ui/icons/Public';
import _LockIcon from '@material-ui/icons/Lock';
import _GroupIcon from '@material-ui/icons/Group';
import { StyledButton } from 'primitives/Button/style';

export const Separator = styled.div`
  width: 100%;
  height: 0;
  border: 1.5px solid ${props => props.theme.colors.blue.veryLight};
  margin: 1em 0;
`;

export const AuthorName = styled.span`
  &&& {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 !important;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const AuthorOccupation = styled.span`
  &&& {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em !important;
    font-weight: normal;
    margin: 0 !important;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Card = styled.div`
  width: 35em;
  height: 20em;
  margin: 1em 0;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 0.5em;
`;

export const Pill = styled.div`
  background: ${({ theme }) => theme.colors.grey.light};
  padding: 0 6px 0 6px;
  border-radius: 10px;
  min-width: 30px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.grey.dark};
  font-size: 0.8em;
  line-height: 2em;
  margin-right: 0.3em;
`;

export const PageHeading = styled.h1`
  text-transform: capitalize;
  font-size: 1.1em;
  line-height: 1.5em;
`;

export const DescriptionContainer = styled.div`
  display: inline;
  flex-direction: column;
  margin-top: 1em;
  text-align: left;
`;

export const DescriptionHeading = styled.span`
  display: inline;
  font-size: 1em;
  margin-bottom: 0.3em;
  font-weight: 600;
  line-height: 1.2em;
  padding: 4px 8px;
  background: #ecf2ff;
  color: #000;
  border-top-right-radius: 0.875em;
  border-bottom-left-radius: 0.875em;
`;

export const ProjectDescription = styled.p`
  font-size: 0.9em;
  text-overflow: ellipsis;
  line-height: 1.5em;
  overflow: hidden;
  margin-bottom: 18px !important;
`;

export const PrimaryButton = styled(Button)`
  &&& {
    border-radius: 10em;
    padding: 0.6em 2em;
    background-color: ${({ theme }) => theme.colors.blue.normal};
    border: 1px solid ${({ theme }) => theme.colors.blue.normal};
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.7em;
    margin: 0.2em;
    text-transform: uppercase;
    white-space: nowrap;
    box-sizing: border-box;
  }
`;

export const GenericButton = styled(Button)<{ backgroundColor?: string; color?: string }>`
  &&& {
    border-radius: 10em;
    padding: 0.6em 2em;
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.blue.normal};
    border: 1px solid ${({ theme }) => theme.colors.blue.normal};
    color: ${({ theme, color }) => color || theme.colors.white};
    font-size: 0.7em;
    margin: 0.2em;
    text-transform: uppercase;
    white-space: nowrap;
    box-sizing: border-box;
  }
`;

export const PrimaryLabel = styled(Label)`
  &&& {
    border-radius: 10em;
    padding: 0.6em 2em;
    background-color: ${({ theme }) => theme.colors.grey.light};
    border: 1px solid ${({ theme }) => theme.colors.grey.light};
    color: ${({ theme }) => theme.colors.black};
    font-size: 0.7em;
    margin: 0.2em;
    text-transform: uppercase;
    white-space: nowrap;
    box-sizing: border-box;
    opacity: 0.5;
  }
`;

export const LightBlueButton = styled(PrimaryButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.lightBlue.normal};
    border-color: ${({ theme }) => theme.colors.lightBlue.normal};
  }
`;

export const GreyButton = styled(PrimaryButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.grey.secondMedium};
    border-color: ${({ theme }) => theme.colors.grey.secondMedium};
  }
`;

export const AcceptButton = styled(StyledButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.green.dark};
    border-color: ${({ theme }) => theme.colors.green.dark};
    color: ${({ theme }) => theme.colors.white};

    padding: 0.6em 2em;
    font-size: 0.7em;

    @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
      padding: ${props => (props?.size === 'big' ? '1em 2em' : '.6em 2em')};
      font-size: ${props => (props?.size === 'big' ? '0.9em' : '0.7em')};
    }
  }
`;

export const DenyButton = styled(StyledButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.orange.normal};
    border-color: ${({ theme }) => theme.colors.orange.normal};
    color: ${({ theme }) => theme.colors.white};

    padding: 0.6em 2em;
    font-size: 0.7em;

    @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
      padding: ${props => (props?.size === 'big' ? '1em 2em' : '.6em 2em')};
      font-size: ${props => (props?.size === 'big' ? '0.9em' : '0.7em')};
    }
  }
`;

export const SubmitButton = styled(StyledButton)`
  &&& {
    flex: 1;
    padding: 0.6em 2em;
    font-size: 0.7em;

    @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
      padding: ${props => (props?.size === 'big' ? '1em 2em' : '.6em 2em')};
      font-size: ${props => (props?.size === 'big' ? '0.9em' : '0.7em')};
    }
  }
`;

export const ShowMoreButton = styled(Button)`
  &&& {
    color: ${({ theme }) => theme.colors.blue.normal};
    font-size: 0.8em;
    padding: 1em 0.5em;
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 9em;
  }
`;

export const ShowMoreButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    font-size: 1.8em;
  }
`;

export const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.blue.normal};
    font-size: 0.8em;
    padding: 0;
  }
`;

export const SecondaryButton = styled(StyledButton)`
  &&& {
    border: 2px solid ${({ bc, theme }) => bc || theme.colors.grey.light};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ color, theme }) => color || theme.colors.grey.dark};
  }
`;

export const BlueSecondaryButton = styled(SecondaryButton)`
  &&& {
    border-color: ${props => props.theme.colors.blue.normal};
    color: ${props => props.theme.colors.blue.normal};
  }
`;

export const CoverTitle = styled.h1`
  color: #fff;
  text-shadow: 1px 1px 2px #000000bd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5em;
  margin-top: -8px !important;
`;

export const NoBackgroundButton = styled(Button)<{ $color?: string }>`
  &&& {
    padding: 1em 0.5em;
    background-color: transparent;
    color: ${({ $color }) => $color};
    font-size: 0.9em;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    span {
      font-size: 1em;
    }

    i {
      font-size: 1.8em;
      color: black;
    }
  }
`;

export const ContinueReadingButton = styled(NoBackgroundButton)`
  &&& {
    color: ${({ theme }) => theme.colors.blue.normal};
    font-size: 0.8em;
    padding: 0;
  }
`;

export const AssignmentDetailsButton = styled(NoBackgroundButton)`
  &&& {
    display: flex;
    justify-content: center;
    ${({ theme, active }) => (active ? `background: ${theme.colors.blue.light};` : '')}
    padding: 1em;

    @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
      width: 50%;
    }
  }
`;

export const DangerousInnerHTML = styled.div`
  width: 100%;
  min-height: 1em;
  font-size: 0.9em !important;

  * {
    max-width: 100%;
  }

  & .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  & .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  & .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  img {
    object-fit: contain;
    height: auto !important;
    width: 100%;
  }

  p,
  span,
  li {
    text-align: left !important;
  }

  ul {
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 20em;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    padding-right: 0;
    font-size: 1.5em;
    padding: 0 0.5em;
  }
`;

export const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0em;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    flex-direction: column;
  }
`;

export const TagsFiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    font-size: 1.8em;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`;

export const TagsFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;

  * {
    /* margin-right: 0.5em !important; */
    margin-right: 0.5em;
  }
`;

export const InputFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InputFileField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledFormField = styled(Form.Field)`
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  align-items: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2em;

  button {
    margin-right: 1em !important;
    font-size: 0.9em !important;
  }
`;

export const FollowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

export const FollowingUsers = styled.div`
  display: flex;
  align-items: center;
`;

export const UserPicture = styled(LazyImage)`
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: inline !important;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

interface ILB {
  isliked: number;
}

export const IconButton = styled(Button)<ILB>`
  &&& {
    font-size: 1em;
    padding: 0.3em 0.2em 0.3em 0.3em;
    background: none;
    margin: 0;

    i {
      ${({ isliked, theme }) => isliked === 1 && `color: ${theme.colors.orange.normal} !important;`};
    }
  }
`;

export const LeaveButton = styled(Button)`
  &&& {
    font-size: 1em;
    padding: 0.3em 0.2em 0.3em 0.3em;
    background-color: white;
    margin: 0;

    i {
      color: ${({ theme }) => theme.colors.orange.normal} !important;
    }
  }
`;

export const RedLeaveButton = styled(LeaveButton)`
  &&& {
    color: red;
  }
`;

export const CommentIcon = styled.img`
  margin-right: 0.2em;
  width: 1.1em;
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5em 0 3.5em 0em;
`;

export const DiscussionCover = styled(Image)`
  &&& {
    margin-top: 2em;
    object-fit: cover;
    width: 100%;
    height: 20em;
    border-radius: 0.5em;
  }
`;

export const SgdContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
`;

export const BigSdg = styled.img`
  width: 9em;
  height: 9em;
  border-radius: 5px;
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    width: 7em;
    height: 7em;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    justify-content: space-around;
    font-size: 1.2em;
  }
`;

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.grey.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8em;
`;

export const StyledText = styled.p`
  color: ${props => props.theme.colors.grey.dark};
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5em 0em;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2.25em 0 1.5em;

  span {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const StyledDivider = styled(Divider)`
  &&& {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const GreenButton = styled(StyledButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.green.normal};
    border-color: ${({ theme }) => theme.colors.green.normal};
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  margin-left: 1.5em;
  cursor: pointer;
`;

export const StyledImage = styled(Image)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StackedDots = styled.div`
  &:after {
    content: '...';
  }

  &:hover {
    cursor: pointer;
  }

  transform: rotate(270deg) translateY(-0.3em);
  font-weight: bold;
  color: ${props => props.theme.colors.grey.medium};
  font-size: 1em;
`;

export const CustomInput = styled(Input)`
  &&& {
    width: 100%;
    min-width: 12em;

    input {
      background: ${props => props.theme.colors.blue.veryLight};
      color: ${props => props.theme.colors.black};
      border: 0;
      font-size: 1em;
      font-weight: 600 !important;
      padding: 0.5em 1em;

      :focus {
        background: ${props => props.theme.colors.blue.veryLight};
      }

      ::placeholder {
        color: ${props => props.theme.colors.grey.dark};
      }
    }
  }
`;

export const FeedContentContainer = styled.div<{ collapsed?: boolean }>`
  position: relative;
  padding: 1em 1.5em 0em 1.5em;
  overflow: hidden;
  ${({ collapsed }) =>
    collapsed &&
    `
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;`}
  width: 100%;
`;

export const HighlightSiblings = styled.div<{ highlight?: boolean; borderSize?: string; borderRadius?: string }>`
  display: none;
  ${props =>
    props.highlight &&
    `
    z-index: -1;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: ${props.borderSize || '5px'} solid;
    left: -${props.borderSize || '5px'};
    top: -${props.borderSize || '5px'};
    -webkit-animation: highlight-fade 3s infinite;
    -moz-animation: highlight-fade 3s infinite;
    -o-animation: highlight-fade 3s infinite;
    animation: highlight-fade 3s infinite;
    box-sizing: content-box;
    border-radius: ${props.borderRadius || '10px'};
    `}
`;

export const DangerousInnerHTMLWrapper = styled.div`
  padding: 1em 0;
  width: 100%;
`;

export const DateLabel = styled.span`
  text-align: left;
  color: ${props => props.theme.colors.grey.dark};
  font-weight: bold;
  white-space: nowrap;
`;

export const CommentCount = styled.span`
  color: ${props => props.theme.colors.grey.dark100};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrivacyColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1em;
  width: 100%;
  border-radius: 8px;
`;

export const ColumnStart = styled(Column)`
  align-items: flex-start;
`;

export const Dot = styled.div<{ warning?: boolean }>`
  position: relative;
  width: 0.5em;
  height: 0.5em;
  background: ${({ theme, warning }) => (warning ? theme.colors.orange.light : theme.colors.grey.medium)};
  border-radius: 0.5em;
  margin: 0 1em;
  z-index: 1;
`;

export const GreenDot = styled.div<{ isDue?: boolean }>`
  position: relative;
  z-index: 2;
  width: 0.5em;
  height: 0.5em;
  background: ${({ theme, isDue }) => (isDue ? theme.colors.orange.light : theme.colors.green.dark)};
  border-radius: 0.5em;
`;

export const DotOutline = styled.div<{ isDue?: boolean }>`
  position: absolute;
  width: 1em;
  height: 1em;
  background-color: ${({ theme, isDue }) => (isDue ? theme.colors.orange.light : theme.colors.green.dark)};
  opacity: 0.45;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const GreenLine = styled(GreenDot)`
  position: absolute;
  width: 0.5em;
  height: 1.7em;
  border-radius: 0;
  background: ${({ theme, isDue }) => (isDue ? theme.colors.orange.veryLight : theme.colors.green.light)};
  top: 0.8em;
  left: 1em;
`;

export const GreenContainer = styled.div`
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.5em;
  margin: 0 1em;
  z-index: 1;
`;

export const ParagraphName = styled.span`
  width: 15em;
  line-height: 2em;
  font-size: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
`;

export const BlueDot = styled(Dot)`
  background: ${({ theme }) => theme.colors.blue.normal};
`;

export const SGDContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const SDGCard = styled.img<{ active: boolean }>`
  width: 7em;
  height: 7em;
  margin: 0.2em;
  border-radius: 5px;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  cursor: pointer;
`;

export const ReportIssueButton = styled(PrimaryButton)`
  &&& {
    position: fixed;
    bottom: -2px;
    right: 110px;
    border-radius: 10px 10px 0 0;
    background-color: #555555;
    border-color: #555555;
    @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
      right: 105px;
    }
  }
`;

const SuspenseFallbackWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const SuspenseFallback = (
  <SuspenseFallbackWrapper>
    <Dimmer active inverted>
      <Loader>Loading...</Loader>
    </Dimmer>
  </SuspenseFallbackWrapper>
);

const previewStyles = css`
  background-color: ${({ theme }) => theme.colors.purple.light};
  padding: 0.2em 0.5em;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 500;
  color: white;
`;

export const PreviewLabel = styled.span`
  ${previewStyles}
`;

export const PreviewAnchor = styled.a`
  ${previewStyles}
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`;

export const StyledContainer = styled.div`
  display: inline;
  flex-direction: column;
  margin-top: 1em;
  text-align: left;
`;

export const StyledTitle = styled.span`
  display: inline;
  font-size: 1em;
  margin-bottom: 0.3em;
  font-weight: 600;
  line-height: 1.2em;
  padding: 4px 8px;
  background: #ecf2ff;
  color: #000;
  border-top-right-radius: 0.875em;
  border-bottom-left-radius: 0.875em;
`;

export const StyledDescription = styled.p`
  font-size: 0.9em;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 18px !important;
`;

export const MandatoryInputLabel = styled.p`
  color: #ff0000 !important;
  line-height: 1.5em;
  margin-top: 0.2em;
`;

export const SelectedMembersList = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-bottom: 15px;
  p {
    text-align: center;
  }
`;

export const PrivacyDetailsRowOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PrivacyDetailsOption = styled.div`
  display: flex;
  align-items: center;
`;

export const Greenbox = styled(IconButton)`
  color: green !important;
`;

export const PrivacyDescription = styled.div`
  padding-bottom: 15px;
  width: 100%;
`;

export const StyleDropDown = styled(Select)`
  && {
    border: 0;
    border-radius: 8px;
    color: rgb(64, 64, 64);
    width: 100%;
    padding: 0.7em;
    border-bottom: none;
    margin: 0;
  }
  &::before {
    border-bottom: none !important;
  }
  &::after {
    border-bottom: none !important;
  }
  & .MuiInputLabel-formControl {
    margin: 0;
  }
  &&& .MuiSelect-root:focus {
    background-color: transparent;
  }
`;

export const StyledSelectContainer = styled(StyleDropDown)`
  && {
    color: rgba(0, 0, 0, 0.54);
    text-align: left;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
`;

export const CustomSwitch = styled(Switch)`
  && {
    width: 42px;
    height: 24px;
    padding: 0;
    margin: 2px;
  }
  label {
    margin: 0;
  }
  && .MuiSwitch-switchBase {
    padding: 1px;
    &.Mui-checked {
      transform: translateX(16px);
      & + .MuiSwitch-track {
        background-color: rgb(0 165 239 / 100%);
        opacity: 1;
        border: none;
      }
    }
    &&.Mui-focusVisible .MuiSwitch-thumb {
      color: #52d869;
      border: 6px solid #fff;
    }
  }
  && .MuiSwitch-thumb {
    width: 22px;
    height: 22px;
    background-color: #fff;
  }
  && .MuiSwitch-track {
    border-radius: 13px;
    opacity: 1;
    background-color: rgb(0 165 239 / 100%);
  }
`;

export const DropdownTags = styled(Dropdown)`
  && {
    padding: 0.875em 0.7em !important;
  }
  && .divider.default {
    height: 100%;
    display: flex !important;
    align-items: center;
    color: rgba(0, 0, 0, 0.54) !important;
    font-weight: 700;
  }
  .divider {
    color: red;
  }
  input.search {
    margin-left: 0 !important;
  }
`;

export const ColumnStartTag = styled(Column)`
  cursor: pointer;
  background: #f1f5f9;
  margin: 20px 0px;
  padding: 5px 0;
`;

export const InputLabelSelect = styled(InputLabel)`
  && {
    transform: translate(0%, -50%);
    top: 50%;
    left: 5%;
    color: rgba(0, 0, 0, 0.54) !important;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }
`;

export const FormControlSelect = styled(FormControl)`
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
`;

export const SelectData = styled.div`
  display: flex;
  align-items: center;
  span {
    color: #666;
    font-weight: 700;
    width: 70px;
    text-align: left;
  }
  p {
    margin: 0;
  }
`;

export const PrivacyMemberList = styled.div`
  width: 100%;
  display: flex;
  gap: 1em;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const SearchRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: -1px;
`;

export const RightButtonContainer = styled.div`
  display: flex;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightHeaderButton = styled(StyledButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.green.normal};
    border-color: ${({ theme }) => theme.colors.green.normal};
    white-space: nowrap;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const LabelInfo = styled.span`
  color: red;
  font-size: 13px;
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const Description = styled.div`
  line-height: 1.23m;
`;

export const ActionButton = styled(NoBackgroundButton)<{ grey?: Boolean | undefined }>`
  text-transform: none !important;
  font-size: 0.8em !important;
  color: ${({ theme, grey }) => (grey ? theme.colors.grey : theme.colors.blue.normal)} !important;
  position: relative;
  top: 0.5em;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.span<{ viewMode?: boolean; required?: boolean }>`
  display: flex;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2em;
  width: fit-content;
  ${({ viewMode, required }) =>
    !viewMode &&
    required &&
    `&&&::after {
      content:" *";
    }
   `}
  ${({ viewMode }) =>
    viewMode
      ? `
      display: flex;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.2em;
      padding: 4px 8px;
      background: #ecf2ff;
      color: #000;
      border-top-right-radius: 14px;
      border-bottom-left-radius: 14px;   
      `
      : `
      display:block;
      font-size: 1.2em; `}
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    align-items: center;
    justify-content: center;
    margin-top: 1em;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
`;

export const HFContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionTitle = styled(Title)`
  font-size: 1.08em;
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100% !important;
  font-size: 1.2rem;
  background: ${props => props.theme.colors.blue.veryLight};
  padding: 0.188em;
  border-radius: 0.2em;
  border: 0.125em solid ${props => props.theme.colors.blue.veryLight};
  :focus {
    background: white;
    border: 0.125em solid ${props => props.theme.colors.blue.normal};
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  box-sizing: border-box;
  width: 40em;
  height: 100%;
  padding: 5em 0;
`;

export const ContinueButton = styled(NoBackgroundButton)`
  * {
    color: ${({ theme }) => theme.colors.blue.normal} !important;
  }
`;

export const EditIcon = styled(Icon)`
  &&& {
    display: none;
    padding-right: 0.2em;
    height: auto;
    cursor: pointer;
  }
`;
export const PrivacyLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5em;
  font-weight: 500;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.grey.dark} !important;

  & > div {
    text-align: left;
  }

  & > div > svg {
    margin-left: 0.2em;
    color: ${({ theme }) => theme.colors.blue.normal} !important;
  }
`;

export const PublicIcon = styled(_PublicIcon)`
  font-size: 1em;
  margin-left: 0.3em;
  color: ${({ theme }) => theme.colors.blue.normal} !important;
`;

export const LockIcon = styled(_LockIcon)`
  font-size: 1em;
  margin-left: 0.3em;
  color: ${({ theme }) => theme.colors.blue.normal} !important;
`;

export const GroupIcon = styled(_GroupIcon)`
  font-size: 1em;
  margin-left: 0.3em;
  color: ${({ theme }) => theme.colors.blue.normal} !important;
`;

export const StyledHeading = styled('div')`
  font-size: 2em;
  font-weight: bold;
  align-self: flex-start;
`;

export const StyledCardDescription = styled.span`
  line-height: 1.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em !important;
`;

export const PageTitle = styled.h1`
  line-height: 1.2em;
  color: black;
  margin-bottom: 0.5em;
  font-size: 1.5em;
`;

const loginButtonStyle = css`
  color: #ffffff;
  height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #dadce0;
`;
export const LoginButton = styled.button`
  ${loginButtonStyle}
`;

export const LoginAnchor = styled.a`
  ${loginButtonStyle}
`;

const emailStyle = css`
  width: 100%;
  background-color: white;
  color: #3c4043;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.25px;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 0.5em;

  :hover {
    background-color: #f8faff;
    border-color: #d2e3fc;
  }
`;

export const Email = styled(LoginButton)`
  ${emailStyle}
`;

export const EmailAnchor = styled(LoginAnchor)`
  ${emailStyle}
`;

export const StyledPaper = styled.div`
  background-color: ${({ theme }) => theme.colors.blue.light};
  height: 5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: left;
`;

export const AutocompleteDropDownContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  flex-wrap: wrap;
  flex: 1;
  margin-bottom: 10px;
`;

export const InputContainerWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const InputIcon = styled.div`
  font-size: 12px;
  margin-right: 12px;
  margin-top: 10px;
`;

export const CircleContainer = styled.div`
  margin-top: 12px;
  margin-left: 12px;
`;

export const SelectedUsersListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 16px 0;
  align-items: center;
  gap: 14px;
  margin-left: 38px;
`;

export const DeleteIcon = styled(Icon)`
  &&& {
    display: none;
    height: auto;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.orange.normal};
  }
`;

export const CkEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  .ck-content p {
    font-size: 0.9em;
    line-height: 1.5em;
  }
  .ck-content li {
    font-size: 0.9em;
    line-height: 1.5em;
    margin-top: 8px;
  }
  > div.ck-content {
    height: 100% !important;
  }
`;
