import { combineReducers } from 'redux';
import { createReducer, PayloadAction } from 'typesafe-actions';
import { append, find, map } from 'ramda';

import { editCommunityCircleAsync } from '../actions/community';
import { fetchUserProfileAsync, followUserAsync, unfollowUserAsync } from '../actions/users';
import { Community, UserProfile } from 'UsersModels';
import { buildFlagReducer } from '../store/utils';

export const fetchUserProfileFlag = buildFlagReducer(fetchUserProfileAsync);

export const users = createReducer([] as UserProfile[])
  .handleAction(
    fetchUserProfileAsync.success,
    (profiles: UserProfile[], action: PayloadAction<'FETCH_USER_PROFILE_SUCCESS', UserProfile>) => {
      if (profiles.length === 0) {
        return [action.payload];
      }
      if (!find(p => p.id === action.payload.id, profiles)) {
        return append(action.payload, profiles);
      } else {
        return map(p => {
          if (p.id === action.payload.id) {
            return action.payload;
          } else {
            return p;
          }
        }, profiles);
      }
    },
  )
  .handleAction(
    editCommunityCircleAsync.success,
    (
      profiles: UserProfile[],
      action: PayloadAction<
        'EDIT_COMMUNITY_CIRCLE_SUCCESS',
        { communityId: number; circleName?: string; userId: number }
      >,
    ) => {
      return profiles.map((u: UserProfile) => {
        if (u.id !== action.payload.userId) return u;
        return {
          ...u,
          communities: u.communities.map((c: Community) => {
            if (c.id !== action.payload.communityId) return c;
            return {
              ...c,
              circleName: action.payload.circleName || c.circleName,
            };
          }),
        };
      });
    },
  )
  .handleAction(
    followUserAsync.success,
    (profiles: UserProfile[], action: PayloadAction<'FOLLOW_USER_SUCCESS', number>) => {
      return profiles.map((u: UserProfile) => {
        if (u.id === action.payload) {
          return {
            ...u,
            isFollowedByMe: true,
          };
        }
        return u;
      });
    },
  )
  .handleAction(
    unfollowUserAsync.success,
    (profiles: UserProfile[], action: PayloadAction<'UNFOLLOW_USER_SUCCESS', number>) => {
      return profiles.map((u: UserProfile) => {
        if (u.id === action.payload) {
          return {
            ...u,
            isFollowedByMe: false,
          };
        }
        return u;
      });
    },
  );

const usersReducer = combineReducers({
  list: users,
});

export default usersReducer;
export type UsersState = ReturnType<typeof usersReducer>;
