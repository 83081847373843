import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { SidebarList, SidebarContainer } from '../styled-components/sidebar';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Item = styled.div`
  height: 2em;
  padding: 0 1.1em 0 0;
  text-align: left;
  color: ${props => props.theme.colors.grey.dark};
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {}

const BackSidebar: React.FC<Props> = ({ literals }) => {
  const history = useHistory();

  const handleClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    history.goBack();
  };

  return (
    <SidebarContainer style={{ paddingTop: '3.5em' }}>
      <SidebarList style={{ alignItems: 'center' }}>
        <Item onClickCapture={handleClick}>← {literals.profile_wizard_02_back_button}</Item>
      </SidebarList>
    </SidebarContainer>
  );
};

export default connect(mapStateToProps, {})(BackSidebar);
