import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { RootState } from 'StoreModel';

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-image: url('auth.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 1440px;
  background-color: #f8e5df;
`;
const BigText = styled.span`
  text-align: left;
  font-size: 2.2em;
  line-height: 1.2em;
  font-weight: bold;
  max-width: 15em;
`;

const mapDispatchToProps = {};

const mapStateToProps = (state: RootState) => ({
  bearer: state.account.session.session.bearer,
  account: state.account.details.user,
  literals: state.literals,
});

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const MaintenancePage: React.FC<Props> = props => {
  return (
    <React.Fragment>
      {' '}
      <Helmet>
        <title>Maintenance</title>
      </Helmet>
      <Container>
        <BigText>
          We are down for scheduled maintenance. <br />
          We&apos;ll be back soon.
        </BigText>
        <span />
      </Container>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePage);
