import { RootState } from 'StoreModel';
import { StyledButton } from 'primitives/Button/style';
import React from 'react';
import { connect } from 'react-redux';
import { Skill } from 'redux/types/account';
import { Checkbox, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { ButtonContainer, SecondaryButton } from '../styled-components/common';
import Modal from './modal-component';

const GridItemsContainer = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemContainer = styled.div`
  margin: 0.5em 0;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface GridModalProps extends ReturnType<typeof mapStateToProps> {
  title: string;
  data: Array<any>;
  onItemClick: (item: any) => void;
  onRemoveSkill: (id: number) => void;
  isOpen: boolean;
  closeModal: () => void;
  skillsList: { [key: string]: Skill };
  selectedSkills: Array<number>;
  saveModal: () => void;
}

const _GridModal: React.FC<GridModalProps> = props => {
  const { title, data, onItemClick, isOpen, closeModal, selectedSkills, onRemoveSkill, saveModal, literals } = props;

  return (
    <Modal open={isOpen} title={`${title} Sub-skills`}>
      <Form>
        <GridItemsContainer>
          {data.map(item => (
            <ItemContainer key={item.id}>
              <Checkbox
                label={item.name}
                onChange={() => {
                  if (selectedSkills.includes(item.id)) {
                    onRemoveSkill(item.id);
                  } else {
                    onItemClick(item);
                  }
                }}
                checked={selectedSkills.includes(item.id)}
              />
            </ItemContainer>
          ))}
        </GridItemsContainer>
        <ButtonContainer>
          <StyledButton onClick={saveModal}>{literals.global_edit_save_changes_button}</StyledButton>
          <SecondaryButton onClick={closeModal}>{literals.confirm_cancel_button}</SecondaryButton>
        </ButtonContainer>
      </Form>
    </Modal>
  );
};

export const GridModal = connect(mapStateToProps, {})(_GridModal);
