import { getAxiosInstance } from './helper';
// import { AxiosResponse, AxiosError } from 'axios';

import { ProjectOverview } from 'redux/types/account';

import { toast } from 'react-toastify';

const ai = getAxiosInstance();

export function fetchProjectSearchOverview(
  bearer: string,
  communityId?: number,
  concept?: string,
): Promise<ProjectOverview[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/ProjectTracking/SearchOverview',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: { communityId, concept },
    })
      .then(response => {
        // Find the project in the projectArray based on the provided id
        const result = response.data;
        resolve(
          result.map((proj: ProjectOverview) => ({
            projectId: proj.projectId,
            projectName: proj.projectName,
            logoImage: proj.logoImage,
            progress: proj.progress,
            tags: proj.tags,
            projectCircles: proj.projectCircles,
            projectUsers: proj.projectUsers,
            lastUpdate: proj.lastUpdate,
            stage: proj.stage,
            coachingSessions: proj.coachingSessions,
          })),
        );
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          toast.error(
            'You do not have the necessary permissions to access the resource. Please request permission from the administrator.',
            { autoClose: false },
          );
        } else {
          reject(err);
        }
      });
  });
}

export default {
  fetchProjectSearchOverview,
};
