import React, { useState } from 'react';
import { RootState } from 'StoreModel';
import {
  calendarLogInGoogleAsync,
  calendarLogInOutlookAsync,
  setUserCalendarGoogleAsync,
  setUserCalendarOutlookAsync,
} from '../../../redux/actions/calendar';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { LogInCalendarData } from '../../../redux/types/calendar';
import styled from 'styled-components';
import CalendarIconColorImage from '../../../assets/community/calendar-icon-color.png';
import { NoBackgroundButton } from 'components/styled-components/common';

const CalendarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: 3px lightgray solid;
  border-radius: 1rem;
  margin: 0.5rem 0;
  :hover {
    cursor: pointer;
  }
`;

const SyncCalendarButton = styled.button`
  background-color: #376092;
  text-transform: uppercase;
  color: white;
  font-size: 0.9em;
  padding: 0.1rem 1.8rem;
  border-radius: 25px;
  margin-top: 1.8em;
  min-width: 150px;
  :hover {
    background-color: cornflowerblue;
  }
`;

const CloseButton = styled.button`
  position: relative;
  background-color: white;
  left: 24px;
  top: 10px;
`;

const Wrapper = styled.div`
  // Todo Matej - instead of exporting this from styled component, I should place it in separate file
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  // Todo Matej - instead of exporting this from styled component, I should place it in separate file
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 50vw;
`;

const OverflowBoundariesContainer = styled.div`
  overflow: auto;
  max-height: 40vh;
`;

const FormatedH2 = styled.h2`
  text-align: center;
  font-size: 1.9rem;
  min-width: 12em;
  margin-top: 0;
`;

const Description = styled.p`
  text-align: center;
  padding: 0.8em 4em;
  font-size: 11px;
`;

const TagSelect = styled.span`
  text-align: center;
  background-color: rgb(232, 232, 232);
  font-weight: bolder;
  color: rgb(63, 150, 63);
  padding: 0.3rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  min-width: 120px;
`;

const TagSelected = styled.span`
  text-align: center;
  background-color: rgb(232, 232, 232);
  font-weight: bolder;
  color: rgb(63, 150, 63);
  padding: 0.3rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  min-width: 120px;
`;

const CalendarItemName = styled.span`
  margin-left: 1rem;
  font-size: 11px;
`;

const StyledNoBackgroundButton = styled(NoBackgroundButton)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ModalContentContainer = styled.div`
  background-color: white;
  padding: 0 2rem 2rem;
  border-radius: 1rem;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  account: state.account.details.user,
  isSettingUpOutlookCalendar: state.loading.setUserCalendarOutlookFlag,
  isSettingUpGoogleCalendar: state.loading.setUserCalendarGoogleFlag,
  calendarLogInData: state.calendarLogInData,
});

const mapDispatchToProps = {
  setUserCalendarOutlook: setUserCalendarOutlookAsync.request,
  setUserCalendarGoogle: setUserCalendarGoogleAsync.request,
  calendarLogInGoogle: calendarLogInGoogleAsync.request,
  calendarLogInOutlook: calendarLogInOutlookAsync.request,
};
type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  isVisible: boolean;
  bearer: string;
  onClose: () => void;
  onSaveChangesSetUserCalendar: (currentCalendar: LogInCalendarData) => void;
}

const CalendarSyncModal: React.FC<Props> = ({
  isVisible,
  calendarLogInData,
  literals,
  onSaveChangesSetUserCalendar,
  onClose,
}) => {
  const [currentCalendar, setCurrentCalendar] = useState<LogInCalendarData | null>(null);

  if (!isVisible) {
    return null;
  }

  const clickSyncCalendar = () => {
    if (!calendarLogInData || !currentCalendar) return;
    onSaveChangesSetUserCalendar(currentCalendar);
  };

  const portalRoot = document.getElementById('portal');

  return portalRoot
    ? ReactDOM.createPortal(
        <Wrapper>
          <ModalContent>
            <ModalContentContainer>
              <StyledNoBackgroundButton onClick={onClose}>
                <i className="material-icons" style={{ color: '#A7A2A2' }}>
                  close
                </i>
              </StyledNoBackgroundButton>
              <FormatedH2>{literals.calendar_sync_modal_choose_calendar}</FormatedH2>
              <Description>{literals.calendar_sync_modal_desc}</Description>
              <OverflowBoundariesContainer>
                {calendarLogInData.logInData && calendarLogInData.logInData.length > 0
                  ? calendarLogInData.logInData.map((item: LogInCalendarData) => (
                      <CalendarItem onClick={() => setCurrentCalendar(item)} key={item.id}>
                        <div>
                          <img style={{ width: '45px' }} src={CalendarIconColorImage} alt="Calendar logo" />
                          <CalendarItemName>{item.name}</CalendarItemName>
                        </div>
                        {currentCalendar?.id === item.id ? (
                          <TagSelected>{literals.calendar_sync_modal_selected}</TagSelected>
                        ) : (
                          <TagSelect>{literals.calendar_sync_modal_select}</TagSelect>
                        )}
                      </CalendarItem>
                    ))
                  : null}
              </OverflowBoundariesContainer>
              <SyncCalendarButton onClick={clickSyncCalendar}>
                {literals.calendar_sync_modal_button_text}
              </SyncCalendarButton>
            </ModalContentContainer>
          </ModalContent>
        </Wrapper>,
        portalRoot,
      )
    : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSyncModal);
