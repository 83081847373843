import { Community, SkillCategory, CommunitySkillCategory } from 'redux/types/account';

export const filterSkillCategories = (skillsCategories: SkillCategory[], community?: Community | null) => {
  if (!community) return skillsCategories;
  if (!community.communitySkillCategories) return skillsCategories;
  if (!skillsCategories) return [];
  return skillsCategories.filter(
    (skillCategory: SkillCategory) =>
      (community.communitySkillCategories || []).find(
        (communitySkillCategory: CommunitySkillCategory) => communitySkillCategory.skillCategoryId === skillCategory.id,
      )?.isActive,
  );
};
