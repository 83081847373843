import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import Image from '../common/lazyImage';
import { Project, Member, Circle } from 'redux/types/account';

import { fetchProjectMembersAsync, applyToTeamProjectAsync } from '../../redux/actions/project';
import { Flag } from '../../redux/types/enums';
import useWindowDimensions from '../../util/windowSize';

import CirclePlus from '../../assets/project/circle-plus.svg';
import defaultProfilePicture from '../../assets/common/profile-placeholder.svg';

const TeamHeading = styled(Link)`
  font-size: 0.688em;
  line-height: 1em;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const TeamMemberContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.75em 0 1.5em 0;
`;

const MemberLink = styled(Link)`
  &&& {
    width: 2.5em;
    height: 2.5em;
    cursor: pointer;
    margin-right: 0.5em;
  }
`;

const MemberIcon = styled(Image)`
  &&& {
    width: 2.5em;
    height: 2.5em;
    object-fit: cover;
  }
`;

const PlusIcon = styled(Image)`
  &&& {
    width: 2.5em;
    height: 2.5em;
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 0.5em;
  background-color: ${({ theme }) => theme.colors.grey.light} !important;
`;

const LinkStyled = styled(Link)`
  &&& {
    text-align: left;
    font-weight: 500;
    font-size: 0.9375em;
    line-height: 1.5em;
    color: ${({ theme }) => theme.colors.blue.normal};
  }
`;

export const LinkStyled2 = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 1.5em;
  color: ${({ theme }) => theme.colors.blue.normal};
  margin-bottom: 0.5em;
  cursor: pointer;
`;

const mapStateToProps = (state: RootState) => ({
  bearer: state.account.session.session.bearer,
  isFetchingMembers: state.loading.fetchProjectMembersFlag,
  projects: state.account.projects.list,
  literals: state.literals,
});

const mapDispatchToProps = {
  fetchMembers: fetchProjectMembersAsync.request,
  applyToTeam: applyToTeamProjectAsync.request,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const Team: React.FC<Props> = ({ bearer, fetchMembers, isFetchingMembers, projects, applyToTeam, literals }) => {
  const location = useLocation();
  const { projectId: projectIdStr } = useParams<{ projectId: string }>();
  const projectId: number | undefined = projectIdStr ? parseInt(projectIdStr) : undefined;
  const [projectMembers, setProjectMembers] = useState<Member[] | undefined>();
  const [canInviteUser, setCanInviteUser] = useState<boolean>();
  const [project, setProject] = useState<Project>();
  const windowSize = useWindowDimensions();

  const isNetwork: boolean = location.pathname.includes('network');

  useEffect(() => {
    if (projectId && bearer && !projectMembers && !isNetwork) {
      const timeout = setTimeout(() => {
        fetchMembers({ bearer, id: projectId, sorting: { orderBy: 1 } });
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [bearer, projectId, projectMembers]);

  useEffect(() => {
    const project: Project | undefined = projects.find((project: Project): boolean => project.id === projectId);
    setProject(project);
    const teamCircleId: number =
      (project && project.circles && project.circles.find((c: Circle) => c.name === 'Team')?.id) || -1;
    const _filterTeam = (m: Member) => {
      if (teamCircleId === -1) return true;
      return m.circle.id === teamCircleId;
    };
    setProjectMembers(
      project && project.members && project.members.filter(_filterTeam).slice(0, project.canInviteUser ? 2 : 3),
    );
    setCanInviteUser(project && project.canInviteUser);
  }, [projectId, projects]);

  if (!projectId) return null;

  const onApplyToTeam = () => applyToTeam({ bearer, projectId });

  return (
    <>
      {!isNetwork ? (
        <TeamHeading to={`/project/${projectId}/network`}>{literals.project_team_title}</TeamHeading>
      ) : null}

      {isNetwork ? (
        project?.canEditProjectInfo &&
        project?.isMember &&
        !windowSize.isMobile && (
          <LinkStyled to={`/project/${projectId}/settings/general`}>{literals.project_menu_settings}</LinkStyled>
        )
      ) : (
        <>
          <TeamMemberContainer>
            {!(isFetchingMembers === Flag.Request) &&
              projectMembers &&
              projectMembers.map(member => (
                <MemberLink key={member.id} to={`/user/${member.id}`}>
                  <MemberIcon
                    customSize={'128x128'}
                    src={member.photo}
                    onError={(event: any) => (event.target.src = defaultProfilePicture)}
                    circular
                  />
                </MemberLink>
              ))}
            {isFetchingMembers === Flag.Request
              ? [<Placeholder key="0" />, <Placeholder key="1" />, <Placeholder key="2" />]
              : canInviteUser && (
                  <LinkStyled to={`/project/${projectId}/network?invite=true`}>
                    <PlusIcon src={CirclePlus} circular />
                  </LinkStyled>
                )}
          </TeamMemberContainer>
          {canInviteUser === true && (
            <LinkStyled to={`/project/${projectId}/network?invite=true`}>{literals.project_invite_to_team}</LinkStyled>
          )}
          {canInviteUser === false && (
            <LinkStyled2 onClick={onApplyToTeam}>{literals.project_apply_to_team_button}</LinkStyled2>
          )}
          {project?.canEditProjectInfo && !windowSize.isMobile && (
            <LinkStyled to={`/project/${projectId}/settings/general`}>{literals.project_menu_settings}</LinkStyled>
          )}
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
