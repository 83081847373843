import { buildHeaders, getAxiosInstance } from './helper';
import { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import {
  CommunityEntityTab,
  CommunityTab,
  TabEntity,
  TabEntityUserList,
  CommunityEntityTabForm,
  TabEntityCircle,
  CommunityEntityTabFilters,
  CommunityEntityTabFilterAnswer,
  Member,
} from '../../redux/types/account';
import { uploadFile } from './file-upload';
import { sanitizeAssetUrl } from '../../util/assets';
import { EmailInvitationDto } from 'ApiModels';
import { inviteCommunityMembers } from './community';
import { _mapToMember } from './project';
import { ImageType } from 'redux/types/enums';
import { prepareFormSaveStep1, prepareFormSaveStep2 } from 'components/forms/utils';

const ai = getAxiosInstance();

export const uploadFileAndContinue = <T>(
  callback: (a: T) => Promise<void>,
  bearer: string,
  data: T,
  attribute: keyof T,
  file: File | undefined,
  imageType?: ImageType,
) => {
  return file
    ? uploadFile(bearer, { file, folder: 'tab_image' }, imageType).then(resource => {
        data = { ...data, [attribute]: sanitizeAssetUrl(resource.link) };
        return callback(data);
      })
    : callback(data);
};

export function saveTab(bearer: string, tab: CommunityTab): Promise<{ communityId: number; tab: CommunityTab }> {
  const { communityId } = tab;

  Object.assign(tab.form, { communityId });
  return new Promise((resolve, reject) => {
    const initialForm = { ...tab.form };
    const formStep1 = prepareFormSaveStep1(initialForm);
    const performSave = (data: CommunityTab) =>
      ai({
        method: tab.id ? 'PUT' : 'POST',
        url: '/api/Tab' + (tab.id ? `/${tab.id}` : ''),
        headers: buildHeaders(bearer),
        data: {
          ...data,
          form: formStep1,
        },
      })
        .then(({ data: newData }) => {
          const formStep2 = prepareFormSaveStep2(initialForm, newData.form);

          ai({
            method: 'PUT',
            url: `/api/Tab/${newData.id}`,
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
            data: {
              ...newData,
              form: formStep2,
            },
          }).then((response: AxiosResponse) => resolve({ communityId, tab: response.data }));
        })
        .catch(err => reject(err));

    return uploadFileAndContinue<CommunityTab>(performSave, bearer, tab, 'icon', tab.iconFile);
  });
}

export function saveTabEntity(bearer: string, entity: CommunityEntityTabForm): Promise<{ entity: CommunityEntityTab }> {
  const method = entity.id ? 'PUT' : 'POST';
  const url = '/api/TabEntity' + (entity.id ? `/${entity.id}` : '');
  return new Promise((resolve, reject) => {
    const performSave = (data: CommunityEntityTabForm) =>
      ai({
        method,
        url,
        data,
        headers: buildHeaders(bearer),
      })
        .then((response: AxiosResponse) => resolve({ entity: response.data }))
        .catch(err => reject(err));
    return uploadFileAndContinue<CommunityEntityTabForm>(
      (data: CommunityEntityTabForm) => {
        return uploadFileAndContinue<CommunityEntityTabForm>(
          (params: CommunityEntityTabForm) => performSave(params),
          bearer,
          data,
          'coverImage',
          entity.coverImageFile,
          ImageType.TabCover,
        );
      },
      bearer,
      entity,
      'logo',
      entity.logoFile,
    );
  });
}

export function fetchCommunityTabs(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; tabs: CommunityTab[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/Tabs`,
      headers: buildHeaders(bearer),
    })
      .then((response: AxiosResponse) => resolve({ communityId, tabs: response.data }))
      .catch(err => reject(err));
  });
}

export function deleteTab(
  bearer: string,
  communityId: number,
  tabId: number,
): Promise<{ communityId: number; tabId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/Tab/${tabId}`,
      headers: buildHeaders(bearer),
    })
      .then(() => resolve({ communityId, tabId }))
      .catch(err => reject(err));
  });
}

export function deleteTabEntity(bearer: string, id: number): Promise<{ id: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/TabEntity/${id}`,
      headers: buildHeaders(bearer),
    })
      .then(() => resolve({ id }))
      .catch(err => reject(err));
  });
}

export function fetchTabById(
  bearer: string,
  communityId: number,
  tabId: number,
): Promise<{ communityId: number; tab: CommunityTab }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Tab/${tabId}`,
      headers: buildHeaders(bearer),
    })
      .then((response: AxiosResponse) => resolve({ communityId, tab: response.data }))
      .catch(err => reject(err));
  });
}

export function fetchTabEntitiesById(
  bearer: string,
  communityId: number,
  tabId: number,
  skip: number,
  take: number,
  sorting?: { orderBy?: number | null | undefined },
  concept?: string,
  filters?: Partial<CommunityEntityTabFilterAnswer>,
): Promise<{
  communityId: number;
  tabId: number;
  entities: CommunityEntityTab[];
  entitiesFilter: CommunityEntityTabFilterAnswer;
  skip: number;
  count: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Tab/${tabId}/Entities`,
      headers: buildHeaders(bearer),
      data: { ...filters, concept, orderby: sorting ? sorting.orderBy : 1, skip, take } || {},
    })
      .then((response: AxiosResponse) =>
        resolve({
          communityId,
          tabId,
          entities: response.data?.list || [],
          count: response.data?.count || 0,
          entitiesFilter: { filters: response.data?.filters },
          skip: skip,
        }),
      )
      .catch(err => reject(err));
  });
}

export const fetchTabEntitiesFiltersById = (bearer: string, tabId: number): AxiosPromise<CommunityEntityTabFilters> =>
  ai({
    method: 'GET',
    url: `/api/Tab/${tabId}/EntitiesFilters`,
    headers: buildHeaders(bearer),
  });

export function fetchTabEntityById(bearer: string, entityId: number): Promise<TabEntity> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/TabEntity/${entityId}`,
      headers: buildHeaders(bearer),
      data: {},
    })
      .then((response: AxiosResponse) => resolve(response.data))
      .catch(err => reject(err));
  });
}
export function fetchUserListTabEntityById(bearer: string, entityId: number): Promise<TabEntityUserList> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/TabEntity/GetUserList`,
      headers: buildHeaders(bearer),
      data: { tabEntityId: entityId },
    })
      .then((response: AxiosResponse) => resolve(response.data))
      .catch(err => reject(err));
  });
}

export function followOrUnFollow(
  bearer: string,
  communityId: number,
  tabId: number,
  entityId: number,
  isFollowing: boolean,
): Promise<{ communityId: number; tabId: number; entityId: number; isFollowing: boolean }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/TabEntity/${entityId}/FollowOrUnFollow`,
      headers: buildHeaders(bearer),
    })
      .then((_response: AxiosResponse) => resolve({ communityId, tabId, entityId, isFollowing }))
      .catch(err => reject(err));
  });
}

export function fetchTabEntityCirclesByTabEntityId(
  bearer: string,
  communityId: number,
  tabEntityId: number,
): Promise<{ circles: TabEntityCircle[]; communityId: number; tabEntityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/TabEntityCircle/GetByTabEntity',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: { id: tabEntityId },
    })
      .then((response: AxiosResponse) => resolve({ circles: response.data, communityId, tabEntityId }))
      .catch(err => reject(err));
  });
}

export function fetchTabEntityMemberByTabEntityId(
  bearer: string,
  communityId: number,
  tabEntityId: number,
  tabEntityCircleId?: number,
  skip?: number,
  take?: number,
): Promise<{
  members: Member[];
  canInviteUser: boolean;
  isAdmin: boolean;
  isMember: boolean;
  pendingApplyToTeam: Array<any>;
  pendingByUsers: Array<any>;
  communityId: number;
  tabEntityCircleId: number | undefined;
  tabEntityId: number;
  skip: number | undefined;
  take: number | undefined;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/TabEntity/GetUserList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: { tabEntityId, tabEntityCircleId, skip, take },
    })
      .then((response: AxiosResponse) =>
        resolve({
          members: response.data.users.list.map(_mapToMember),
          isAdmin: response.data.isAdmin,
          isMember: response.data.isMember,
          canInviteUser: response.data.canInviteUser,
          pendingApplyToTeam: response.data.pendingApplyToTeam.map(_mapToMember),
          pendingByUsers: response.data.pendingByUsers.map(_mapToMember),
          communityId,
          tabEntityCircleId,
          tabEntityId,
          skip,
          take,
        }),
      )
      .catch(err => reject(err));
  });
}

export function inviteTabEntityMembers(
  bearer: string,
  users: EmailInvitationDto[],
): Promise<(EmailInvitationDto | AxiosError)[]> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const answers: (EmailInvitationDto | AxiosError)[] = [];
    for (let userIndex = 0; userIndex < users.length; userIndex++) {
      const user = users[userIndex];
      try {
        const invitedUser = await inviteTabEntityMember(bearer, user);
        answers.push(invitedUser);
      } catch (err: any) {
        answers.push(err);
      }
    }
    resolve(answers);
  });
}

export function inviteTabEntityMember(bearer: string, user: EmailInvitationDto): Promise<EmailInvitationDto> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/TabEntity/InviteToTabEntityViaEmail',
      data: user,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(user);
      })
      .catch(err => reject(err));
  });
}

export function removeInviteTabEntityMember(
  bearer: string,
  tabEntityId: number,
  userId: number,
  userEmail: string,
): Promise<{ tabEntityId: number; userId: number; userEmail: string }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/TabEntity/RemoveInvitation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        tabEntityId,
        userId,
        email: userEmail,
      },
    })
      .then(() => {
        resolve({
          tabEntityId,
          userId,
          userEmail,
        });
      })
      .catch(err => reject(err));
  });
}

export function inviteTabEntityAndCommunityMembers(
  bearer: string,
  data: EmailInvitationDto[],
): Promise<(EmailInvitationDto | AxiosError)[]> {
  return new Promise((resolve, reject) => {
    const tabEntityMembers = data.filter((user: EmailInvitationDto) => !user.user.inviteToCommunity);
    const communityMembers = data.filter((user: EmailInvitationDto) => user.user.inviteToCommunity);
    const tabEntityData = tabEntityMembers.map((invitee: EmailInvitationDto) => ({
      id: invitee.id,
      user: {
        name: invitee.user.name,
        email: invitee.user.email,
        circleId: invitee.user.circleId,
      },
    }));
    const communityData = communityMembers.map((invitee: EmailInvitationDto) => ({
      id: invitee.user.communityId || 0,
      user: {
        name: invitee.user.name,
        email: invitee.user.email,
        circleId: invitee.user.communityCircleId || 0,
        inviteAlsoToTabEntityCircleId: invitee.user.circleId,
      },
    }));
    Promise.all([inviteTabEntityMembers(bearer, tabEntityData), inviteCommunityMembers(bearer, communityData)])
      .then((responses: (EmailInvitationDto | AxiosError)[][]) => {
        resolve([...responses[0], ...responses[1]]);
      })
      .catch(err => {
        console.log('got error');
        reject(err);
      });
  });
}

export function saveTabEntityCircle(
  bearer: string,
  communityId: number,
  id: number,
  circle: TabEntityCircle,
): Promise<{ communityId: number; circle: TabEntityCircle }> {
  return new Promise((resolve, reject) => {
    const data = { id: circle.id, tabEntityId: id, communityId, name: circle.name };
    ai({
      method: 'POST',
      url: '/api/TabEntityCircle/Save',
      headers: buildHeaders(bearer),
      data,
    })
      .then((response: AxiosResponse) => resolve({ communityId, circle: response.data }))
      .catch(err => reject(err));
  });
}

export function deleteTabEntityCircle(
  bearer: string,
  communityId: number,
  circleId: number,
): Promise<{ communityId: number; id: number }> {
  return new Promise((resolve, reject) => {
    const params = { id: circleId };
    ai({
      method: 'DELETE',
      url: `/api/TabEntityCircle/Remove`,
      headers: buildHeaders(bearer),
      params,
    })
      .then(() => resolve({ communityId, id: circleId }))
      .catch(err => reject(err));
  });
}

export function editTabEntityCircle(
  bearer: string,
  communityId: number,
  tabEntityId: number,
  circleId: string,
  userId: string,
): Promise<{ communityId: number; circleId: number; userId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/TabEntity/EditTabEntityCircle',
      params: {
        tabEntityCircleId: circleId,
        communityId,
        tabEntityId,
        userId,
      },
      headers: buildHeaders(bearer),
    })
      .then(() => {
        resolve({
          communityId,
          circleId: Number(circleId),
          userId: Number(userId),
        });
      })
      .catch(err => reject(err));
  });
}

export function acceptTabEntityInvitation(
  bearer: string,
  tabEntityId: number,
  userId: number,
): Promise<{ tabEntityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/TabEntity/AcceptInvitation',
      headers: buildHeaders(bearer),
      params: {
        tabEntityId,
        userId,
      },
    })
      .then(() => {
        resolve({
          tabEntityId,
        });
      })
      .catch(err => reject(err));
  });
}

export function declineTabEntityInvitation(
  bearer: string,
  tabEntityId: number,
  userId: number,
): Promise<{ tabEntityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/TabEntity/DeclineInvitation',
      headers: buildHeaders(bearer),
      params: {
        tabEntityId,
        userId,
      },
    })
      .then(() => {
        resolve({
          tabEntityId,
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  saveTab,
  fetchCommunityTabs,
  deleteTab,
  fetchTabById,
  fetchTabEntitiesById,
  fetchTabEntityById,
  followOrUnFollow,
  inviteTabEntityMembers,
  removeInviteTabEntityMember,
  inviteTabEntityAndCommunityMembers,
  fetchTabEntityCirclesByTabEntityId,
  fetchTabEntityMemberByTabEntityId,
  saveTabEntityCircle,
  deleteTabEntityCircle,
  editTabEntityCircle,
};
