import { Epic, ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel'; // Update the path to your store
import { fetchProjectSearchOverviewAsync } from '../actions/project-overview';
import { handleError } from './helpers';
import { ProjectOverview } from 'redux/types/account';
type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchProjectSearchOverviewEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    ofType(fetchProjectSearchOverviewAsync.request),
    switchMap(action =>
      from(
        api.projectSearchOverview.fetchProjectSearchOverview(
          action.payload.bearer,
          action.payload.communityId,
          action.payload.concept,
        ),
      ).pipe(
        // map(fetchProjectSearchOverviewAsync.success), //!ANOTHER WAY FOR FETCHING THE ASYNC FUNCTION SUCCESS RESPONSE
        switchMap((response: ProjectOverview[]) => of(fetchProjectSearchOverviewAsync.success(response))),
        catchError(error => handleError(error, fetchProjectSearchOverviewAsync)),
      ),
    ),
  );

export default { fetchProjectSearchOverviewEpic };
