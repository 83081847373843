import React, { ReactChild, ReactFragment, ReactPortal } from 'react';
import InfoTipImage from '../../../assets/sidebar-icons/infotip.svg';
import { styled } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltipPlacementBottom`]: {
    backgroundColor: '#ffffff',
    color: 'gray',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '1rem',
  },
}));

interface Props {
  children: ReactChild | ReactFragment | ReactPortal;
}
const CalendarInfotip: React.FC<Props> = ({ children }) => {
  return (
    <HtmlTooltip placement={'bottom'} title={children}>
      <img style={{ width: '1.2em' }} src={InfoTipImage} alt="Infotip image" />
    </HtmlTooltip>
  );
};

export default CalendarInfotip;
