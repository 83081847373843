import { Comment, Circle, Discussion } from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';
import { CommentType } from '../types/enums';

export const fetchCirclesByDiscussionsAsync = createAsyncAction(
  'FETCH_CIRCLES_BY_DISCUSSIONS_REQUEST',
  'FETCH_CIRCLES_BY_DISCUSSIONS_SUCCESS',
  'FETCH_CIRCLES_BY_DISCUSSIONS_FAILURE',
  'FETCH_CIRCLES_BY_DISCUSSIONS_IDLE',
)<
  { entityId: number; entityType: string; bearer: string },
  { entityId: number; entityType: string; circles: Circle[] },
  string,
  null
>();

export const updateDiscussionIsArchivedAsync = createAsyncAction(
  'UPDATE_DISCUSSION_IS_ARCHIVED_REQUEST',
  'UPDATE_DISCUSSION_IS_ARCHIVED_SUCCESS',
  'UPDATE_DISCUSSION_IS_ARCHIVED_FAILURE',
  'UPDATE_DISCUSSION_IS_ARCHIVED_IDLE',
)<
  {
    bearer: string;
    discussion: Discussion;
    isArchived: boolean;
  },
  {
    discussion: Discussion;
  },
  string,
  null
>();

export const fetchDiscussionPrivacyCirclesAsync = createAsyncAction(
  'FETCH_DISCUSSION_PRIVACY_CIRCLES_REQUEST',
  'FETCH_DISCUSSION_PRIVACY_CIRCLES_SUCCESS',
  'FETCH_DISCUSSION_PRIVACY_CIRCLES_FAILURE',
  'FETCH_DISCUSSION_PRIVACY_CIRCLES_IDLE',
)<
  {
    bearer: string;
    entityId: number;
    entityType: string;
    discussionId: number;
    discussionPrivacyCircles?: Circle[];
  },
  {
    entityId: number;
    entityType: string;
    discussionId: number;
    circles: Circle[];
  },
  string,
  null
>();

export const fetchDiscussionsTagsAsync = createAsyncAction(
  'FETCH_DISCUSSIONS_TAGS_REQUEST',
  'FETCH_DISCUSSIONS_TAGS_SUCCESS',
  'FETCH_DISCUSSIONS_TAGS_FAILURE',
  'FETCH_DISCUSSIONS_TAGS_IDLE',
)<
  { entityId: number; entityType: string; bearer: string },
  { entityId: number; entityType: string; tags: string[] },
  string,
  null
>();

export const saveDiscussionTagsAsync = createAsyncAction(
  'SAVE_DISCUSSION_TAGS_REQUEST',
  'SAVE_DISCUSSION_TAGS_SUCCESS',
  'SAVE_DISCUSSION_TAGS_FAILURE',
  'SAVE_DISCUSSION_TAGS_IDLE',
)<
  { bearer: string; discussionId: number; discussionType: string; communityId: number; tags: string[] },
  { communityId: number; discussionId: number; discussionType: string; tags: string[] },
  string,
  null
>();

export const createNewDiscussionAsync = createAsyncAction(
  'CREATE_NEW_DISCUSSION_REQUEST',
  'CREATE_NEW_DISCUSSION_SUCCESS',
  'CREATE_NEW_DISCUSSION_FAILURE',
  'CREATE_NEW_DISCUSSION_IDLE',
)<
  {
    discussion: Discussion;
    bearer: string;
    privacyCircles: Circle[];
    cover: File | string;
    discussionSkillCategories: string[];
    circleTags: string[];
  },
  Discussion,
  string,
  null
>();

export const deleteDiscussionAsync = createAsyncAction(
  'DELETE_DISCUSSION_REQUEST',
  'DELETE_DISCUSSION_SUCCESS',
  'DELETE_DISCUSSION_FAILURE',
  'DELETE_DISCUSSION_IDLE',
)<
  { bearer: string; entityId: number; entityType: string; discussionId: number },
  { entityId: number; entityType: string; discussionId: number },
  string,
  null
>();

export const fetchDiscussionAsync = createAsyncAction(
  'FETCH_DISCUSSION_REQUEST',
  'FETCH_DISCUSSION_SUCCESS',
  'FETCH_DISCUSSION_FAILURE',
  'FETCH_DISCUSSION_IDLE',
)<
  { discussionId: number; bearer: string; entityId: number; entityType: string },
  {
    entityId: number;
    entityType: string;
    discussion: Discussion;
    canEditDiscussions: boolean;
    canEditPrivacy: boolean;
  },
  string,
  null
>();

export const fetchDiscussionTagsAsync = createAsyncAction(
  'FETCH_DISCUSSION_TAGS_REQUEST',
  'FETCH_DISCUSSION_TAGS_SUCCESS',
  'FETCH_DISCUSSION_TAGS_FAILURE',
  'FETCH_DISCUSSION_TAGS_IDLE',
)<{ communityId: number; bearer: string }, { discussionTags: string[]; communityId: number }, string, null>();

export const addCommentToDiscussionAsync = createAsyncAction(
  'ADD_COMMENT_DISCUSSIONS_REQUEST',
  'ADD_COMMENT_DISCUSSIONS_SUCCESS',
  'ADD_COMMENT_DISCUSSIONS_FAILURE',
  'ADD_COMMENT_DISCUSSIONS_IDLE',
)<
  {
    bearer: string;
    userId: number;
    itemId: number;
    content: string;
    parentId: number;
    itemType: CommentType;
    entityId: number;
    entityType: string;
    discussionId: number;
  },
  { discussionId: number; entityId: number; entityType: string; comment: Comment },
  string,
  null
>();

export const followDiscussionAsync = createAsyncAction(
  'FOLLOW_DISCUSSION_REQUEST',
  'FOLLOW_DISCUSSION_SUCCESS',
  'FOLLOW_DISCUSSION_FAILURE',
  'FOLLOW_DISCUSSION_IDLE',
)<
  { bearer: string; entityType: string; entityId: number; discussionId: number },
  { entityType: string; entityId: number; discussionId: number },
  string,
  null
>();

export const unfollowDiscussionAsync = createAsyncAction(
  'UNFOLLOW_DISCUSSION_REQUEST',
  'UNFOLLOW_DISCUSSION_SUCCESS',
  'UNFOLLOW_DISCUSSION_FAILURE',
  'UNFOLLOW_DISCUSSION_IDLE',
)<
  { bearer: string; entityType: string; entityId: number; discussionId: number },
  { entityType: string; entityId: number; discussionId: number },
  string,
  null
>();

export default {
  fetchCirclesByDiscussionsAsync,
  updateDiscussionIsArchivedAsync,
  fetchDiscussionPrivacyCirclesAsync,
  fetchDiscussionsTagsAsync,
  saveDiscussionTagsAsync,
  createNewDiscussionAsync,
  deleteDiscussionAsync,
  fetchDiscussionAsync,
  fetchDiscussionTagsAsync,
  addCommentToDiscussionAsync,
  followDiscussionAsync,
  unfollowDiscussionAsync,
};
