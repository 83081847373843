import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import { scrollLayoutToTop } from 'util/utils';
import NavbarLogo from './components/NavbarLogo';
import Login from '../buttons/ActionButton';
import { landingPageLiterals } from '../../utils/landing-page-literals';

interface Tabs {
  name?: string;
  id?: number;
}
interface INavbar {
  logoUrl?: string;
  communityName?: string;
  tabs?: Tabs[];
}

const Navbar = ({ logoUrl, communityName, tabs }: INavbar) => {
  const history = useHistory();
  const { tabId } = useParams<{ tabId: string }>();

  return (
    <>
      <Nav>
        <NavBarContent>
          <NavBarLeft>
            <NavbarLogo url={logoUrl} name={communityName} />
            <NavBarButtons>
              {tabs?.map(({ name, id }, key) => (
                <NavBarButton
                  key={key}
                  onClickCapture={scrollLayoutToTop}
                  isActive={id === parseInt(tabId)}
                  onClick={() => {
                    history.push(`/landing/tab/${id}`);
                  }}
                >
                  {name}
                </NavBarButton>
              ))}
            </NavBarButtons>
          </NavBarLeft>

          <Login buttonText={landingPageLiterals.buttonTextLogin} size="small" />
        </NavBarContent>
      </Nav>
    </>
  );
};

const Nav = styled.nav`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const NavBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  padding: 10px;

  @media (min-width: 1440px) {
    padding: 10px 20px;
  }
`;

const NavBarLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    gap: 24px;
  }
`;

const NavBarButtons = styled.div`
  display: flex;
  gap: 18px;

  padding-left: 24px;
`;

const NavBarButton = styled.button<{ isActive: boolean }>`
  position: relative;

  font-size: 15px;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey.thirdMedium};

  &:before {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0px;

    width: 100%;
    height: 2px;

    background-color: ${({ isActive, theme }) => (isActive ? theme.colors.grey.light : 'transparent')};
  }

  :hover {
    color: ${({ theme }) => theme.colors.grey.dark};
  }
`;

export default Navbar;
