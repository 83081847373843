import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError } from './helpers';

import { fetchUserProfileAsync, followUserAsync, unfollowUserAsync } from '../actions/users';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchUserProfileEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchUserProfileAsync.request)),
    switchMap(action =>
      from(api.users.fetchUserProfile(action.payload.bearer, action.payload.id)).pipe(
        map(fetchUserProfileAsync.success),
        catchError(error => handleError(error, fetchUserProfileAsync)),
      ),
    ),
  );

export const followUserEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(followUserAsync.request)),
    switchMap(action =>
      from(api.users.followUser(action.payload.bearer, action.payload.id)).pipe(
        map(followUserAsync.success),
        catchError(error => handleError(error, followUserAsync)),
      ),
    ),
  );

export const unfollowUserEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(unfollowUserAsync.request)),
    switchMap(action =>
      from(api.users.unfollowUser(action.payload.bearer, action.payload.id)).pipe(
        map(unfollowUserAsync.success),
        catchError(error => handleError(error, unfollowUserAsync)),
      ),
    ),
  );

export default {
  fetchUserProfileEpic,
  followUserEpic,
  unfollowUserEpic,
};
