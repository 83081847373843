import React, { useState, useMemo, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { FormControl, InputLabel, ListSubheader, Select, MenuProps } from '@material-ui/core';
import { Option } from '../types';

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

interface AutocompleteProps {
  userOptions: Option[];
  onSelect: (value: Option[]) => void;
  selectedOptions: Option[];
  placeholder: string;
  selectedUserText: string;
  selectedUsersText: string;
}

const AutocompleteComponent: React.FC<AutocompleteProps> = ({
  userOptions,
  onSelect,
  selectedOptions,
  placeholder,
  selectedUserText,
  selectedUsersText,
}) => {
  const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const [searchText, setSearchText] = useState('');
  const displayedOptions: Option[] = useMemo(
    () => userOptions.filter((option: Option) => containsText(option.name, searchText)),
    [searchText],
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const clickedOptions = event.target.value as number[];
    const values: Option[] = userOptions?.filter((option: Option) => clickedOptions?.includes(option.id));

    onSelect(values);
  };

  const renderValue = useCallback(() => {
    if (!selectedOptions?.length) return '';
    if (selectedOptions.length === 1) {
      return `1 ${selectedUserText}`;
    }

    return `${selectedOptions.length} ${selectedUsersText}`;
  }, [selectedOptions]);

  return (
    <StyledFormControl>
      <StyledSelectContainer>
        <StyledInputLabel id="search-select-label">{placeholder}</StyledInputLabel>
        <StyledSelect
          MenuProps={menuProps as MenuProps}
          labelId="search-select-label"
          id="search-select"
          value={selectedOptions?.map(option => option?.id)}
          multiple
          onChange={handleChange}
          disableUnderline
          onClose={() => setSearchText('')}
          renderValue={renderValue}
        >
          <StyledListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </StyledListSubheader>
          {displayedOptions?.map((option: Option, index: number) => (
            <StyleMenuItem key={index} value={option.id}>
              <Avatar src={option.photo} />
              <Name>
                {option.name}
                {' • '}
              </Name>
              <CircleName>{option.circle}</CircleName>
            </StyleMenuItem>
          ))}
        </StyledSelect>
      </StyledSelectContainer>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  margin-left: 7px;
  margin-top: 5px;
  z-index: 2;
`;

const StyledListSubheader = styled(ListSubheader)`
  && {
    margin-top: -8px;
    padding-top: 12px;
    background-color: ${({ theme }) => theme.colors.blue.veryLight};
  }
`;

// Adding this container to set the background on it instead of the
// MUI Select. If we add a background to the Select, the input label
// won't be visible anymore. Also, creating the background using an ::after
// pseudoelement, since the container itself is much larger than the Select
// So we create an ::after with the same size as the select
const StyledSelectContainer = styled.div`
  border-radius: 5px;
  width: 230px;
  max-width: 100%;
  position: relative;
  z-index: 0;

  &::after {
    display: block;
    left: 0;
    bottom: 0;
    border-radius: 5px;
    content: '';
    width: 100%;
    height: 37px;
    background-color: ${({ theme }) => theme.colors.blue.veryLight};
    z-index: -1;
    position: absolute;
    pointer-events: none;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  padding: 3px 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.blue.veryLight};
  color: #949494;

  && {
    background: transparent;

    &:focus {
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.colors.blue.veryLight};
    }
  }
`;

const StyleMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Name = styled.div`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
`;

const CircleName = styled.div`
  font-size: 14px;
  margin-left: 2px;
`;

const Avatar = styled.img`
  border-radius: 20px;
  width: 30px;
  border: solid 1px ${({ theme }) => theme.colors.blue.dark};
  margin-right: 8px;
`;

export default AutocompleteComponent;
