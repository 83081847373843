import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { fetchMyStuffAsync } from 'redux/actions/account';
import { Flag } from 'redux/types/enums';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

const mapStateToProps = (state: RootState) => ({
  bearer: state.account.session.session.bearer,
  account: state.account.details.user,
  literals: state.literals,
  communities: state.account.communities.list,
  isFetchingCommunities: state.loading.fetchCommunitiesFlag,
  isFetchingUser: state.loading.fetchCurrentUserFlag,
  isFetchingMyStuff: state.loading.fetchMyStuffFlag,
});
const mapDispatchToProps = {
  fetchMyStuff: fetchMyStuffAsync.request,
};
type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const IndexPage: React.FC<Props> = props => {
  const {
    isFetchingCommunities,
    isFetchingUser,
    isFetchingMyStuff,
    bearer,
    account,
    communities,
    fetchMyStuff,
    literals,
  } = props;
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const isFetching =
    isFetchingCommunities === Flag.Request || isFetchingUser === Flag.Request || isFetchingMyStuff === Flag.Request;

  useEffect(() => {
    if (isFetchingMyStuff !== Flag.Request) {
      fetchMyStuff({
        bearer,
      });
    }
    setFirstRender(true);
  }, []);

  const getIndexRedirect = () => {
    const newApplicationRedirect = JSON.parse(
      localStorage.getItem('new-application-redirect') ||
        JSON.stringify({ afterRegister: '', expirationTime: new Date().getTime() - 10 }),
    );
    if (isFetching || !account?.primaryCommunityId || !communities || !account?.ownedProjects)
      return (
        <Dimmer active={true} inverted={true}>
          <Loader inverted={true}>{literals.project_list_loading_tag_message}</Loader>
        </Dimmer>
      );
    else if (newApplicationRedirect.expirationTime > new Date().getTime()) {
      localStorage.removeItem('new-application-redirect');
      return (
        <Redirect
          to={{
            pathname: newApplicationRedirect.afterRegister,
            state: { from: '/' },
          }}
        />
      );
    } else if (communities.find((c: any) => c.id === account?.primaryCommunityId))
      return (
        <Redirect
          to={{
            pathname: `/community/${account?.primaryCommunityId}`,
            state: { from: '/' },
          }}
        />
      );
    else if (communities.length > 0)
      return (
        <Redirect
          to={{
            pathname: `/community/${communities[0].id}`,
            state: { from: '/' },
          }}
        />
      );
    else if (account?.ownedProjects.length > 0)
      return (
        <Redirect
          to={{
            pathname: `/project/${account?.ownedProjects[0].id}`,
            state: { from: '/' },
          }}
        />
      );

    return (
      <Redirect
        to={{
          pathname: `/account`,
          state: { from: '/' },
        }}
      />
    );
  };

  return <React.Fragment>{firstRender && getIndexRedirect()}</React.Fragment>;
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
