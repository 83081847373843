import { startsWith } from 'ramda';

export const sanitizeAssetUrl = (url: string | undefined): string => {
  if (!url) {
    return '';
  } else if (startsWith('http', url)) {
    return url;
  } else {
    if (startsWith('/', url) || startsWith('\\', url)) {
      return url;
    } else {
      return '/' + url;
    }
  }
};

export const formatImgPath = (logoImagePath: string): string => {
  if (logoImagePath.includes('youtube') && !logoImagePath.includes('img.youtube')) {
    let video = '';
    const splitImagePath = logoImagePath.split('/');
    if (logoImagePath.includes('embed')) video = splitImagePath[splitImagePath.length - 1];
    else video = new URLSearchParams(logoImagePath).get('v') || '';
    return `https://img.youtube.com/vi/${video}/hqdefault.jpg`;
  }
  if (!startsWith('\\', logoImagePath) && !startsWith('/', logoImagePath)) {
    return `\\${logoImagePath}`;
  }
  return logoImagePath;
};
