import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'StoreModel';
import { persistStore } from 'redux-persist';
import { composeEnhancers } from './utils';
import rootReducer from './root-reducer';
import rootEpic from './root-epic';
import services from '../../services';

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
  dependencies: services,
});

const middlewares = [epicMiddleware];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const _logoutWrappedReducer = (state: RootState, action: RootAction) => {
  if (action.type === '') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const store = createStore(_logoutWrappedReducer, undefined, enhancer);
const persistor = persistStore(store, null, () => store.getState());

epicMiddleware.run(rootEpic);

export { store, persistor };
