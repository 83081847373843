import {
  acceptCommunityProjectAsync,
  createResourceAsync,
  deleteCommunityCalendarEventAsync,
  deleteCommunityCircleAsync,
  deleteCommunityProjectTagsAsync,
  deleteCommunityReportAsync,
  deleteCommunityTagsAsync,
  deleteKPIAsync,
  deleteResourceAsync,
  denyCommunityProjectAsync,
  downloadKPIAsync,
  editCommunityCircleAsync,
  editCommunityTagsAsync,
  fetchAllCommunityRequestsAsync,
  fetchCommunitiesAsync,
  fetchCommunityCalendarEventDetailsAsync,
  fetchCommunityCalendarEventsAsync,
  fetchCommunityCirclesAsync,
  fetchCommunityDetailsAsync,
  fetchCommunityDiscussionsAsync,
  fetchCommunityMembersAsync,
  fetchCommunityMembersByCircleAsync,
  fetchCommunityMembersCountriesAsync,
  fetchCommunityMembersInterestsAsync,
  fetchCommunityMembersSkillsAsync,
  fetchCommunityProgressTrackingAsync,
  fetchCommunityProjectFormAsync,
  fetchCommunityProjectsAsync,
  fetchCommunityProjectsCategoriesAsync,
  fetchCommunityProjectsCountriesAsync,
  fetchCommunityProjectsSDGsAsync,
  fetchCommunityProjectsStagesAsync,
  fetchCommunityProjectsTagsAsync,
  fetchCommunityRequestsAsync,
  fetchCommunityResourcesAsync,
  fetchKPIAsync,
  fetchProjectsAwaitingFeedbackAsync,
  fetchProjectsNextParagraphsAsync,
  fetchResourceCirclesAsync,
  fetchResourcesTagsAsync,
  inviteCommunityMembersAsync,
  removeCommunityInvitationAsync,
  removeUserFromCommunityAsync,
  respondCommunityJoinRequestAsync,
  saveCommunityCircleAsync,
  saveCommunityCirclesAsync,
  saveCommunityMembersTagsAsync,
  saveCommunityProjectsTagsAsync,
  searchCommunityProgressTrackingAsync,
  setAllowTasksAsync,
  updateCommunityInfoAsync,
  updateCommunityProjectFormAsync,
  updateMethodologyNameAsync,
  upsertCommunityCalendarEventAsync,
  upsertKPIAsync,
} from '../../actions/community';
import {
  addCommentToDiscussionAsync,
  fetchDiscussionAsync,
  fetchDiscussionTagsAsync,
  followDiscussionAsync,
  saveDiscussionTagsAsync,
  unfollowDiscussionAsync,
} from '../../actions/discussion';
import {
  addCommentToFeedAsync,
  addCommunityPostAsync,
  fetchCommunityFeedAsync,
  likeFeedPostAsync,
  unlikeFeedPostAsync,
} from '../../actions/feed';
import {
  createTasksAsync,
  deleteMethodologyAssignmentAsync,
  deleteMethodologySectionAsync,
  deleteTasksAsync,
  fetchMethodologyContentAsync,
  fetchMethodologyPlanningAsync,
  reorderMethodologyAssignmentsAsync,
  reorderMethodologySectionsAsync,
  saveMethodologyAssignmentAsync,
  saveMethodologyDiffAsync,
  saveMethodologySectionAsync,
  saveSectionsStagegateAsync,
  setTaskUnlockRuleAsync,
  updateMethodologyAssignmentAsync,
  updateTasksAsync,
  updateTasksOrderAsync,
  deleteMethodologyAsync,
} from '../../actions/methodology';
import { followUserAsync, unfollowUserAsync } from '../../actions/users';
import { uploadFileAsync } from '../../actions/file-upload';
import { addCommentToBusinessModelParagraphAsync } from '../../actions/business-model';
import { buildFlagReducer } from '../../store/utils';
import { editTabEntityCircleAsync } from 'redux/actions/tab';
import { deleteApplicationEntriesAsync, updateApplicationEntryStatusAsync } from 'redux/actions/application-manager';
import { editCommunityProjectTagsAsync } from 'redux/actions/community';
import { getLiteralsAsync } from 'redux/actions/account';

export const deleteMethodologyFlag = buildFlagReducer(deleteMethodologyAsync);

export const fetchCommunityMembersByCircleFlag = buildFlagReducer(fetchCommunityMembersByCircleAsync);

export const respondCommunityJoinRequestFlag = buildFlagReducer(respondCommunityJoinRequestAsync);

export const saveSectionsStagegateFlag = buildFlagReducer(saveSectionsStagegateAsync);

export const removeCommunityInvitationFlag = buildFlagReducer(removeCommunityInvitationAsync);

export const saveMethodologyDiffFlag = buildFlagReducer(saveMethodologyDiffAsync);

export const fetchDiscussionFlag = buildFlagReducer(fetchDiscussionAsync);

export const addCommentToDiscussionFlag = buildFlagReducer(addCommentToDiscussionAsync);

export const fetchDiscussionTagsFlag = buildFlagReducer(fetchDiscussionTagsAsync);

export const saveDiscussionTagsFlag = buildFlagReducer(saveDiscussionTagsAsync);

export const fetchCommunityFeedFlag = buildFlagReducer(fetchCommunityFeedAsync);

export const addCommunityPostFlag = buildFlagReducer(addCommunityPostAsync);

export const addCommentToFeedFlag = buildFlagReducer(addCommentToFeedAsync);

export const likeFeedPostFlag = buildFlagReducer(likeFeedPostAsync);

export const unlikeFeedPostFlag = buildFlagReducer(unlikeFeedPostAsync);

export const fetchMethodologyContentFlag = buildFlagReducer(fetchMethodologyContentAsync);

export const updateMethodologyAssignmentFlag = buildFlagReducer(updateMethodologyAssignmentAsync);

export const deleteMethodologySectionFlag = buildFlagReducer(deleteMethodologySectionAsync);

export const deleteMethodologyAssignmentFlag = buildFlagReducer(deleteMethodologyAssignmentAsync);

export const reorderMethodologySectionsFlag = buildFlagReducer(reorderMethodologySectionsAsync);

export const reorderMethodologyAssignmentsFlag = buildFlagReducer(reorderMethodologyAssignmentsAsync);

export const saveMethodologySectionFlag = buildFlagReducer(saveMethodologySectionAsync);

export const saveMethodologyAssignmentFlag = buildFlagReducer(saveMethodologyAssignmentAsync);

export const fetchMethodologyPlanningFlag = buildFlagReducer(fetchMethodologyPlanningAsync);

export const createTasksFlag = buildFlagReducer(createTasksAsync);

export const updateTasksFlag = buildFlagReducer(updateTasksAsync);

export const updateTasksOrderFlag = buildFlagReducer(updateTasksOrderAsync);

export const deleteTasksFlag = buildFlagReducer(deleteTasksAsync);

export const followUserFlag = buildFlagReducer(followUserAsync);

export const unfollowUserFlag = buildFlagReducer(unfollowUserAsync);

export const uploadFileFlag = buildFlagReducer(uploadFileAsync);

export const addCommentToBusinessModelParagraphFlag = buildFlagReducer(addCommentToBusinessModelParagraphAsync);

export const fetchCommunitiesFlag = buildFlagReducer(fetchCommunitiesAsync);

export const fetchCommunityDetailsFlag = buildFlagReducer(fetchCommunityDetailsAsync);

export const fetchCommunityMembersFlag = buildFlagReducer(fetchCommunityMembersAsync);

export const fetchCommunityResourcesFlag = buildFlagReducer(fetchCommunityResourcesAsync);

export const createResourceFlag = buildFlagReducer(createResourceAsync);

export const deleteResourceFlag = buildFlagReducer(deleteResourceAsync);

export const fetchCommunityDiscussionsFlag = buildFlagReducer(fetchCommunityDiscussionsAsync);

export const fetchAllCommunityRequestsFlag = buildFlagReducer(fetchAllCommunityRequestsAsync);

export const fetchCommunityRequestsFlag = buildFlagReducer(fetchCommunityRequestsAsync);

export const updateCommunityInfoFlag = buildFlagReducer(updateCommunityInfoAsync);

export const fetchCommunityProjectsFlag = buildFlagReducer(fetchCommunityProjectsAsync);

export const acceptCommunityProjectFlag = buildFlagReducer(acceptCommunityProjectAsync);

export const denyCommunityProjectFlag = buildFlagReducer(denyCommunityProjectAsync);

export const fetchCommunityCirclesFlag = buildFlagReducer(fetchCommunityCirclesAsync);

export const inviteCommunityMembersFlag = buildFlagReducer(inviteCommunityMembersAsync);

export const fetchResourceCirclesFlag = buildFlagReducer(fetchResourceCirclesAsync);

export const fetchResourcesTagsFlag = buildFlagReducer(fetchResourcesTagsAsync);

export const fetchCommunityMembersCountriesFlag = buildFlagReducer(fetchCommunityMembersCountriesAsync);

export const fetchCommunityMembersSkillsFlag = buildFlagReducer(fetchCommunityMembersSkillsAsync);

export const fetchCommunityMembersInterestsFlag = buildFlagReducer(fetchCommunityMembersInterestsAsync);

export const setAllowTasksFlag = buildFlagReducer(setAllowTasksAsync);

export const fetchProjectsAwaitingFeedbackFlag = buildFlagReducer(fetchProjectsAwaitingFeedbackAsync);

export const fetchCommunityProjectsCountriesFlag = buildFlagReducer(fetchCommunityProjectsCountriesAsync);

export const fetchCommunityProjectsCategoriesFlag = buildFlagReducer(fetchCommunityProjectsCategoriesAsync);

export const fetchCommunityProjectsStagesFlag = buildFlagReducer(fetchCommunityProjectsStagesAsync);

export const fetchCommunityProjectsSDGsFlag = buildFlagReducer(fetchCommunityProjectsSDGsAsync);

export const fetchCommunityProjectsTagsFlag = buildFlagReducer(fetchCommunityProjectsTagsAsync);

export const fetchProjectsNextParagraphsFlag = buildFlagReducer(fetchProjectsNextParagraphsAsync);

export const saveCommunityProjectsTagsFlag = buildFlagReducer(saveCommunityProjectsTagsAsync);

export const saveCommunityCircleFlag = buildFlagReducer(saveCommunityCircleAsync);

export const saveCommunityCirclesFlag = buildFlagReducer(saveCommunityCirclesAsync);

export const deleteCommunityCircleFlag = buildFlagReducer(deleteCommunityCircleAsync);

export const editCommunityCircleFlag = buildFlagReducer(editCommunityCircleAsync);
export const editTabEntityCircleFlag = buildFlagReducer(editTabEntityCircleAsync);

export const removeUserFromCommunityFlag = buildFlagReducer(removeUserFromCommunityAsync);

export const fetchCommunityProgressTrackingFlag = buildFlagReducer(fetchCommunityProgressTrackingAsync);

export const searchCommunityProgressTrackingFlag = buildFlagReducer(searchCommunityProgressTrackingAsync);

export const fetchKPIFlag = buildFlagReducer(fetchKPIAsync);

export const upsertKPIFlag = buildFlagReducer(upsertKPIAsync);

export const deleteKPIFlag = buildFlagReducer(deleteKPIAsync);

export const downloadKPIFlag = buildFlagReducer(downloadKPIAsync);

export const fetchCommunityCalendarEventsFlag = buildFlagReducer(fetchCommunityCalendarEventsAsync);

export const fetchCommunityCalendarEventDetailsFlag = buildFlagReducer(fetchCommunityCalendarEventDetailsAsync);

export const deleteCommunityCalendarEventFlag = buildFlagReducer(deleteCommunityCalendarEventAsync);

export const upsertCommunityCalendarEventFlag = buildFlagReducer(upsertCommunityCalendarEventAsync);

export const followDiscussionFlag = buildFlagReducer(followDiscussionAsync);

export const unfollowDiscussionFlag = buildFlagReducer(unfollowDiscussionAsync);

export const setTaskUnlockRuleFlag = buildFlagReducer(setTaskUnlockRuleAsync);

export const fetchCommunityProjectFormFlag = buildFlagReducer(fetchCommunityProjectFormAsync);

export const updateCommunityProjectFormFlag = buildFlagReducer(updateCommunityProjectFormAsync);

export const deleteCommunityReportFlag = buildFlagReducer(deleteCommunityReportAsync);

export const deleteApplicationEntriesFlag = buildFlagReducer(deleteApplicationEntriesAsync);

export const editCommunityTagsFlag = buildFlagReducer(editCommunityTagsAsync);

export const deleteCommunityTagsFlag = buildFlagReducer(deleteCommunityTagsAsync);

export const editCommunityProjectTagsFlag = buildFlagReducer(editCommunityProjectTagsAsync);

export const deleteCommunityProjectTagsFlag = buildFlagReducer(deleteCommunityProjectTagsAsync);

export const saveCommunityMembersTagsFlag = buildFlagReducer(saveCommunityMembersTagsAsync);

export const updateProgramNameFlag = buildFlagReducer(updateMethodologyNameAsync);

export const updateApplicationEntryStatusFlag = buildFlagReducer(updateApplicationEntryStatusAsync);

export const getLiteralsFlag = buildFlagReducer(getLiteralsAsync);
