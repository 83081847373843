import { createReducer, PayloadAction } from 'typesafe-actions';
import { buildFlagReducer } from '../store/utils';
import { ProjectRequestFeedback } from 'redux/types/account';
import { fetchProjectRequestFeedbackListAsync } from 'redux/actions/project-request-feedback';

export const fetchProjectSearchOverviewFlag = buildFlagReducer(fetchProjectRequestFeedbackListAsync);

export const projectsRequestFeedbackReducer = createReducer([] as ProjectRequestFeedback[]).handleAction(
  fetchProjectRequestFeedbackListAsync.success,
  (
    projectRequestFeedbacks: ProjectRequestFeedback[],
    action: PayloadAction<'FETCH_REQUEST_FEEDBACK_SUCCESS', ProjectRequestFeedback[]>,
  ) => {
    return action.payload;
  },
);

export default projectsRequestFeedbackReducer;
export type AccountState = ReturnType<typeof projectsRequestFeedbackReducer>;
