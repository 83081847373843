import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

const Container = styled.div<{ width: string }>`
  .ui.toggle.checkbox input:checked ~ label::before {
    background-color: ${({ theme }) => theme.colors.blue.normal} !important;
  }
  width: ${({ width }) => width || '2.7em'};
`;

const Toggle = ({ ...props }) => {
  return (
    <Container width={props.width}>
      <Checkbox toggle {...props} />
    </Container>
  );
};

export default Toggle;
