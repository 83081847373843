import {
  ApplicationDefinition,
  ApplicationEntry,
  ApplicationFilters,
  ApplicationScore,
  Assignment,
  CalendarEvent,
  Circle,
  Comment,
  Community,
  CommunityDetails,
  CommunityDiscussions,
  CommunityMembers,
  CommunityMembersFilters,
  CommunityPost,
  CommunityProgressOverview,
  CommunityProjectsFilters,
  CommunityRequests,
  CommunityRequestsFilters,
  CommunityResources,
  Country,
  Discussion,
  FeedItem,
  Form,
  FormQuestionAnswer,
  Interest,
  KpiDefinition,
  Literals,
  Member,
  MentionUser,
  Methodology,
  Project,
  ProjectAwaitingFeedback,
  ProjectCategory,
  ProjectNextParagraphs,
  ProjectStage,
  Report,
  Request,
  RequestFilteringSkill,
  RequestStatus,
  Resource,
  SDG,
  Section,
  Skill,
  Task,
} from 'redux/types/account';
import { getLiteralsAsync } from 'redux/actions/account';
import { createReducer, PayloadAction, StateType } from 'typesafe-actions';
import { difference, find, findIndex, map, prepend, propEq, update } from 'ramda';
import {
  createResourceAsync,
  deleteCommunityCalendarEventAsync,
  deleteCommunityCircleAsync,
  deleteKPIAsync,
  deleteResourceAsync,
  editCommunityCircleAsync,
  fetchAllCommunityRequestsAsync,
  fetchCommunitiesAsync,
  fetchCommunityCalendarEventDetailsAsync,
  fetchCommunityCalendarEventsAsync,
  fetchCommunityCirclesAsync,
  fetchCommunityCircleToCirclePermissionAsync,
  fetchCommunityCircleToCirclePermissionSaveAsync,
  fetchCommunityDetailsAsync,
  fetchCommunityDiscussionsAsync,
  fetchCommunityMembersAsync,
  fetchCommunityMembersByCircleAsync,
  fetchCommunityMembersCountriesAsync,
  fetchCommunityMembersInterestsAsync,
  fetchCommunityMembersPendingByUserAsync,
  fetchCommunityMembersSkillsAsync,
  fetchCommunityProgressTrackingAsync,
  fetchCommunityProjectFormAsync,
  fetchCommunityProjectsAsync,
  fetchCommunityProjectsCategoriesAsync,
  fetchCommunityProjectsCountriesAsync,
  fetchCommunityProjectsSDGsAsync,
  fetchCommunityProjectsStagesAsync,
  fetchCommunityProjectsTagsAsync,
  fetchCommunityReportsAsync,
  fetchCommunityRequestsAsync,
  fetchCommunityResourcesAsync,
  fetchKPIAsync,
  fetchMentionUsersAsync,
  fetchProjectsAwaitingFeedbackAsync,
  fetchProjectsNextParagraphsAsync,
  fetchResourceCirclesAsync,
  fetchResourcesTagsAsync,
  removeCommunityInvitationAsync,
  removeUserFromCommunityAsync,
  respondCommunityJoinRequestAsync,
  saveCommunityCircleAsync,
  saveCommunityCirclesAsync,
  saveCommunityProjectsTagsAsync,
  searchCommunityProgressTrackingAsync,
  setAllowTasksAsync,
  updateCommunityCircleAsync,
  updateCommunityInfoAsync,
  updateCommunityProjectFormAsync,
  upsertCommunityCalendarEventAsync,
  upsertCommunityReportAsync,
  deleteCommunityReportAsync,
  upsertKPIAsync,
  editCommunityTagsAsync,
  deleteCommunityTagsAsync,
  deleteCommunityProjectTagsAsync,
  editCommunityProjectTagsAsync,
  fetchCommunityMembersTagsAsync,
  saveCommunityMembersTagsAsync,
  updateMethodologyNameAsync,
} from '../actions/community';
import { DEFAULT_COMMUNITY } from '../types/default-types-values';
import { featuresCookieCategories, gdprConsent } from 'util/utils';
import { createTransform } from 'redux-persist';
import { getPersistKey } from 'util/persist';
import storage from 'redux-persist/es/storage';
import {
  closeRequestAsync,
  deleteRequestAsync,
  fetchCommunityFilteringSkillsAsync,
  fetchCommunityRequestsTagsAsync,
  updateCommunityRequestTagsAsync,
} from '../actions/request';
import {
  addCommentToDiscussionAsync,
  createNewDiscussionAsync,
  fetchCirclesByDiscussionsAsync,
  fetchDiscussionAsync,
  fetchDiscussionPrivacyCirclesAsync,
  fetchDiscussionsTagsAsync,
  fetchDiscussionTagsAsync,
  followDiscussionAsync,
  saveDiscussionTagsAsync,
  unfollowDiscussionAsync,
} from '../actions/discussion';

import {
  addCommentToFeedAsync,
  addCommunityPostAsync,
  deleteCommentAsync,
  fetchCommunityFeedAsync,
  fetchTabEntityCommentsAsync,
  likeFeedPostAsync,
  pinCommunityFeedPostAsync,
  unlikeFeedPostAsync,
  unpinCommunityFeedPostAsync,
} from '../actions/feed';

import {
  createTasksAsync,
  deleteMethodologyAssignmentAsync,
  deleteMethodologySectionAsync,
  deleteTaskAsync,
  deleteTasksAsync,
  disableMethodologyAsync,
  enableMethodologyAsync,
  fetchMethodologyContentAsync,
  fetchMethodologyPlanningAsync,
  reorderMethodologyAssignmentsAsync,
  reorderMethodologySectionsAsync,
  saveMethodologyAssignmentAsync,
  saveMethodologyDiffAsync,
  saveMethodologySectionAsync,
  saveSectionsStagegateAsync,
  setTaskUnlockRuleAsync,
  updateMethodologyAssignmentAsync,
  updateTasksAsync,
  updateTasksOrderAsync,
  deleteMethodologyAsync,
} from '../actions/methodology';

import {
  deleteApplicationDefinitionAsync,
  deleteApplicationEntriesAsync,
  fetchApplicationDefinitionByIdAsync,
  fetchApplicationEntriesByDefinitionIdAsync,
  fetchApplicationEntryCommentsAsync,
  fetchApplicationEntryScoresAsync,
  fetchApplicationFiltersAsync,
  fetchCommunityApplicationDefinitionsAsync,
  fetchEvaluationAnswersByApplicationEntryIdAsync,
  saveApplicationDefinitionAsync,
  saveApplicationEntryScoresAsync,
  saveApplicationEntryTagsAsync,
  updateApplicationEntryStatusAsync,
} from '../actions/application-manager';
import { deleteFormAsync, fetchCommunityFormsAsync, fetchFormByIdAsync, saveFormAsync } from '../actions/form';
import { followUserAsync, unfollowUserAsync } from '../actions/users';
import { uploadFileAsync } from '../actions/file-upload';
import { ApplicationStatus, CommentType, Languages, Planner, Response } from '../types/enums';
import {
  deleteTabAsync,
  fetchCommunityTabEntityCirclesAsync,
  fetchCommunityTabsAsync,
  fetchTabByIdAsync,
  fetchTabEntitiesByIdAsync,
  followOrUnFollowEntityAsync,
  saveTabAsync,
  fetchCommunityTabEntityCircleMembersAsync,
  saveTabEntityCircleAsync,
  deleteTabEntityCircleAsync,
  editTabEntityCircleAsync,
  removeUserFromTabEntityAsync,
} from '../actions/tab';
import {
  deleteCommunityProjectTagsActionHandler,
  deleteCommunityTagsActionHandler,
  deleteTabActionHandler,
  deleteTabEntityCircleActionHandler,
  editCommunityProjectTagsActionHandler,
  editCommunityTagsActionHandler,
  editTabEntityCircleAsyncActionHandler,
  fetchCommunityMembersTagsActionHandler,
  fetchCommunityTabEntityCircleMembersActionHandler,
  fetchCommunityTabEntityCirclesActionHandler,
  fetchCommunityTabsActionHandler,
  fetchTabEntitiesByIdActionHandler,
  fetchTabEntityCommentsActionHandler,
  followOrUnFollowEntityActionHandler,
  saveCommunityMembersTagsActionHandler,
  saveTabEntityCircleActionHandler,
  tabEntityCommentLike,
  tabEntityDeleteComment,
  updateMethodologyNameActionHandler,
  updateOrCreateTabActionHandler,
  updateTabCommentsFromCommunity,
} from './handlers/community-tabs-handlers';
import {
  discussionCommentLike,
  discussionDeleteComment,
  updateDiscussionCommentsFromCommunity,
} from './handlers/community-discussion-handlers';
import {
  commentLikeFromCommunity,
  deleteCommentFromCommunity,
  updateCommentsFromCommunity,
} from './handlers/community-handlers';

import { buildFlagReducer } from 'redux/store/utils';

export const deleteCommunityReportFlag = buildFlagReducer(deleteCommunityReportAsync);

export const deleteApplicationEntriesFlag = buildFlagReducer(deleteApplicationEntriesAsync);
export const deleteMethodologyFlag = buildFlagReducer(deleteMethodologyAsync);

export const communities = createReducer([] as Community[]).handleAction(
  fetchCommunitiesAsync.success,
  (communities: Community[], action: PayloadAction<'FETCH_COMMUNITIES_SUCCESS', Community[]>) => {
    return map(remoteCommunity => {
      const communityMatch = find(c => c.id === remoteCommunity.id, communities);
      if (!communityMatch) return remoteCommunity;
      return {
        ...communityMatch,
        ...remoteCommunity,
        reports: communityMatch.reports,
        allowTasks: communityMatch.allowTasks || remoteCommunity.allowTasks,
        methodologies: communityMatch.methodologies,
      };
    }, action.payload);
  },
);

export const selectedCommunity = createReducer(null as Community | null)
  .handleAction(
    likeFeedPostAsync.success,
    (
      selectedCommunity: Community | null,
      { payload: { id, entityId, type } }: StateType<typeof likeFeedPostAsync.success>,
    ) => {
      if (!selectedCommunity || entityId !== selectedCommunity.id) {
        return selectedCommunity;
      }

      let updatedSelectedCommunity: Community;
      if (type === CommentType.CommunityDiscussion) {
        updatedSelectedCommunity = discussionCommentLike(selectedCommunity, id, true);
      } else if (type === CommentType.TabEntity) {
        updatedSelectedCommunity = tabEntityCommentLike(selectedCommunity, id, true);
      } else {
        updatedSelectedCommunity = commentLikeFromCommunity(selectedCommunity, id, true);
      }

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    unlikeFeedPostAsync.success,
    (
      selectedCommunity: Community | null,
      { payload: { id, entityId, type } }: StateType<typeof unlikeFeedPostAsync.success>,
    ) => {
      if (!selectedCommunity || entityId !== selectedCommunity.id) {
        return selectedCommunity;
      }

      let updatedSelectedCommunity: Community;
      if (type === CommentType.CommunityDiscussion) {
        updatedSelectedCommunity = discussionCommentLike(selectedCommunity, id, false);
      } else if (type === CommentType.TabEntity) {
        updatedSelectedCommunity = tabEntityCommentLike(selectedCommunity, id, false);
      } else {
        updatedSelectedCommunity = commentLikeFromCommunity(selectedCommunity, id, false);
      }

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityFeedAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_FEED_SUCCESS',
        { id: number; feed: FeedItem[]; skip: number; take: number }
      >,
    ) => {
      const { id, feed, skip, take } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== id) {
        return selectedCommunity;
      }

      const existingFeed = selectedCommunity.feed || []; // Use a fallback value if feed is undefined
      let updatedSelectedCommunity: Community;
      if (skip === 0) {
        updatedSelectedCommunity = {
          ...selectedCommunity,
          feed,
          feedAllLoaded: feed.length < take,
        };
      } else {
        updatedSelectedCommunity = {
          ...selectedCommunity,
          feed: [...existingFeed, ...feed],
          feedAllLoaded: feed.length < take,
        };
      }

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchMethodologyContentAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_METHODOLOGY_CONTENT_SUCCESS',
        { communityId: number; methodologyId: number; sections: Section[] }
      >,
    ) => {
      const { communityId, methodologyId, sections } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const remoteSections = sections.sort((a: Section, b: Section) => (a.order > b.order ? 1 : -1));

      const updatedMethodologies = (selectedCommunity.methodologies || []).map(localMethodology => {
        if (localMethodology.id !== methodologyId) {
          return localMethodology;
        }

        const updatedSections = remoteSections.map(remoteSection => ({
          ...remoteSection,
          assignments: (remoteSection.assignments || []).sort((a, b) => a.order - b.order),
        }));

        return {
          ...localMethodology,
          sections: updatedSections,
        };
      });

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )

  .handleAction(
    disableMethodologyAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'DISABLE_METHODOLOGY_SUCCESS',
        {
          communityId: number;
          methodologyId: number;
        }
      >,
    ) => {
      const { communityId, methodologyId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;

        return {
          ...methodology,
          isActive: !methodology.isActive,
        };
      });

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    enableMethodologyAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'ENABLE_METHODOLOGY_SUCCESS',
        {
          communityId: number;
          methodologyId: number;
        }
      >,
    ) => {
      const { communityId, methodologyId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;

        return {
          ...methodology,
          isActive: !methodology.isActive,
        };
      });

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteMethodologyAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'DELETE_METHODOLOGY_SUCCESS',
        {
          communityId: number;
          methodologyId: number;
        }
      >,
    ) => {
      const { communityId, methodologyId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;

        return {
          ...methodology,
          isActive: !methodology.isActive,
        };
      });

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    reorderMethodologyAssignmentsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'REORDER_METHODOLOGY_ASSIGNMENTS_SUCCESS',
        {
          newAssignmentsOrder: { id: number; order: number }[];
          communityId: number;
          methodologyId: number;
        }
      >,
    ) => {
      const { newAssignmentsOrder, communityId, methodologyId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;

        const updatedSections = (methodology.sections || []).map(section => {
          const newAssignments = (section.assignments || []).map(assignment => {
            const matchedOrder = newAssignmentsOrder.find(item => item.id === assignment.id);
            const order = matchedOrder ? matchedOrder.order : assignment.order;

            return {
              ...assignment,
              order,
            };
          });

          const sortedAssignments = newAssignments.sort((a, b) => a.order - b.order);

          return {
            ...section,
            assignments: sortedAssignments,
          };
        });

        return {
          ...methodology,
          sections: updatedSections,
        };
      });

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    reorderMethodologySectionsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'REORDER_METHODOLOGY_SECTIONS_SUCCESS',
        { newSectionsOrder: { id: number; order: number }[]; methodologyId: number; communityId: number }
      >,
    ) => {
      const { newSectionsOrder, methodologyId, communityId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;

        const newSections = newSectionsOrder.map(sectionOrder => {
          const matchedSection = methodology.sections.find(section => section.id === sectionOrder.id);
          if (!matchedSection) return null;

          return {
            ...matchedSection,
            order: sectionOrder.order,
          };
        });

        const filteredSections = newSections.filter(section => section !== null) as Section[];
        const sortedSections = filteredSections.sort((a, b) => a.order - b.order);

        return {
          ...methodology,
          sections: sortedSections,
        };
      });

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchMethodologyPlanningAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_METHODOLOGY_PLANNING_SUCCESS',
        {
          communityId: number;
          methodologyId: number;
          planner: { type: Planner; tasks: Task[] };
          usingProjects: { projectId: number; projectName: string }[];
          isRetroactive: boolean;
        }
      >,
    ) => {
      const { communityId, methodologyId, isRetroactive, usingProjects, planner } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;
        return {
          ...methodology,
          isRetroactive,
          usingProjects,
          planner: {
            tasks: planner.tasks,
            plannerType: planner.type,
          },
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    createTasksAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'CREATE_TASKS_SUCCESS', { communityId: number; methodologyId: number; tasks: Task[] }>,
    ) => {
      const { communityId, methodologyId, tasks } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;
        return {
          ...methodology,
          planner: {
            ...methodology.planner,
            tasks: [
              ...tasks,
              ...methodology.planner.tasks.filter((t: Task) => !tasks.find((dupTask: Task) => dupTask.id === t.id)),
            ].sort((a: Task, b: Task) => a.order - b.order),
          },
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    updateTasksAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'UPDATE_TASK_SUCCESS', { communityId: number; methodologyId: number; tasks: Task[] }>,
    ) => {
      const { communityId, methodologyId, tasks } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;
        return {
          ...methodology,
          planner: {
            ...methodology.planner,
            tasks: methodology.planner.tasks.map((t: Task) => {
              const updatedTask = tasks.find((task: Task) => task.id === t.id);
              if (updatedTask) {
                return {
                  ...t,
                  ...updatedTask,
                };
              } else {
                return t;
              }
            }),
          },
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteTaskAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'DELETE_TASK_SUCCESS', { communityId: number; methodologyId: number; taskId: number }>,
    ) => {
      const { communityId, methodologyId, taskId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;
        return {
          ...methodology,
          planner: {
            ...methodology.planner,
            tasks: methodology.planner.tasks.filter((t: Task) => t.id !== taskId),
          },
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteTasksAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'DELETE_TASKS_SUCCESS', { communityId: number; methodologyId: number; taskIds: number[] }>,
    ) => {
      const { communityId, methodologyId, taskIds } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;
        return {
          ...methodology,
          planner: {
            ...methodology.planner,
            tasks: methodology.planner.tasks.filter(
              (t: Task) => !taskIds.find((deletedTaskId: number) => deletedTaskId === t.id),
            ),
          },
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    updateTasksOrderAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'UPDATE_TASKS_ORDER_SUCCESS',
        { communityId: number; methodologyId: number; tasks: Task[] }
      >,
    ) => {
      const { communityId, methodologyId, tasks } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id !== methodologyId) return methodology;
        const updatedTasks = (methodology.planner.tasks || []).map(task => {
          const updatedTask = tasks.find((t: Task) => t.id === task.id);
          return {
            ...task,
            order: updatedTask?.order || task.order,
          };
        });
        return {
          ...methodology,
          tasks: updatedTasks,
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveMethodologySectionAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'SAVE_METHODOLOGY_SECTION_SUCCESS',
        { communityId: number; section: Section; sectionIndex: number }
      >,
    ) => {
      const { communityId, section, sectionIndex } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        if (methodology.id === section.methodologyId) {
          const updatedSections = (methodology.sections || []).map((s: Section, i: number) => {
            if (s.id === section.id) {
              return {
                ...s,
                name: section.name,
                logo: section.logo,
              };
            }
            return s;
          });
          if (sectionIndex >= 0 && sectionIndex <= updatedSections.length) {
            updatedSections.splice(sectionIndex, 0, section);
          }
          return {
            ...methodology,
            sections: updatedSections,
          };
        }
        return methodology;
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveMethodologyAssignmentAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'SAVE_METHODOLOGY_ASSIGNMENT_SUCCESS',
        { communityId: number; assignment: Assignment; assignmentIndex: number }
      >,
    ) => {
      const { communityId, assignment, assignmentIndex } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        const updatedSections = (methodology.sections || []).map(section => {
          if (section.id === assignment.sectionId) {
            const updatedAssignments = (section.assignments || []).slice();
            if (assignmentIndex >= 0 && assignmentIndex <= updatedAssignments.length) {
              updatedAssignments.splice(assignmentIndex, 0, assignment);
            }
            return {
              ...section,
              assignments: updatedAssignments,
            };
          }
          return section;
        });
        return {
          ...methodology,
          sections: updatedSections,
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    updateMethodologyAssignmentAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'UPDATE_METHODOLOGY_ASSIGNMENT_SUCCESS', { communityId: number; assignment: Assignment }>,
    ) => {
      const { communityId, assignment } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        const updatedSections = (methodology.sections || []).map(section => {
          if (section.id === assignment.sectionId) {
            const updatedAssignments = (section.assignments || []).map(a => {
              if (a.id === assignment.id) {
                return {
                  ...assignment,
                };
              }
              return a;
            });
            return {
              ...section,
              assignments: updatedAssignments,
            };
          }
          return section;
        });
        return {
          ...methodology,
          sections: updatedSections,
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteMethodologyAssignmentAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'DELETE_METHODOLOGY_ASSIGNMENT_SUCCESS', { assignment: Assignment; communityId: number }>,
    ) => {
      const { communityId, assignment } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        const updatedSections = (methodology.sections || []).map(section => {
          if (section.id === assignment.sectionId) {
            const updatedAssignments = (section.assignments || []).filter(a => a.id !== assignment.id);
            return {
              ...section,
              assignments: updatedAssignments,
            };
          }
          return section;
        });
        return {
          ...methodology,
          sections: updatedSections,
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteMethodologySectionAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'DELETE_METHODOLOGY_SECTION_SUCCESS', { section: Section; communityId: number }>,
    ) => {
      const { communityId, section } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMethodologies = (selectedCommunity.methodologies || []).map(methodology => {
        const updatedSections = (methodology.sections || []).filter(s => s.id !== section.id);
        return {
          ...methodology,
          sections: updatedSections,
        };
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    addCommunityPostAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'ADD_COMMUNITY_POST_SUCCESS', { communityId: number; post: CommunityPost }>,
    ) => {
      const { communityId, post } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        feed: [post, ...(selectedCommunity.feed || [])],
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    addCommentToFeedAsync.success,
    (
      selectedCommunity: Community | null,
      { payload: { entityId, comment, itemType, itemId } }: StateType<typeof addCommentToFeedAsync.success>,
    ) => {
      if (!selectedCommunity) {
        return selectedCommunity;
      }
      let updatedSelectedCommunity: Community;
      if (itemType === CommentType.CommunityDiscussion) {
        updatedSelectedCommunity = updateDiscussionCommentsFromCommunity(selectedCommunity, itemId, comment);
      } else if (itemType === CommentType.TabEntity) {
        updatedSelectedCommunity = updateTabCommentsFromCommunity(selectedCommunity, itemId, comment);
      } else {
        updatedSelectedCommunity = updateCommentsFromCommunity(selectedCommunity, comment);
      }
      return updatedSelectedCommunity;
    },
  )
  .handleAction(fetchCommunityDetailsAsync.request, (selectedCommunity: Community | null) => {
    return null;
  })
  .handleAction(
    fetchCommunityDetailsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_DETAILS_SUCCESS', CommunityDetails>,
    ) => {
      const { id } = action.payload;
      if (selectedCommunity && selectedCommunity.id === action.payload.id) {
        const updatedCommunity: Community = {
          ...selectedCommunity,
          ...action.payload,
          methodologies: (action.payload.methodologies || []).map(methodology => {
            const existingMethodology = (selectedCommunity.methodologies || []).find(
              (m: Methodology) => m.id === methodology.id,
            );
            if (existingMethodology) {
              return {
                ...existingMethodology,
                ...methodology,
                sections: existingMethodology.sections,
                planner: existingMethodology.planner,
              };
            }
            return methodology;
          }),
        };

        const updatedSelectedCommunity =
          selectedCommunity && selectedCommunity.id === id ? updatedCommunity : selectedCommunity;
        return updatedSelectedCommunity;
      } else {
        const newCommunity: Community = {
          ...DEFAULT_COMMUNITY,
          ...action.payload,
        };

        return newCommunity;
      }
    },
  )
  .handleAction(
    fetchCommunityMembersAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_MEMBERS_SUCCESS',
        {
          list: CommunityMembers;
          skip: number;
          isCommunityAdmin: boolean;
          totalMembersCount?: number;
          membersCount?: number;
          memberFilters?: CommunityMembersFilters;
        }
      >,
    ) => {
      const { list, skip, isCommunityAdmin, totalMembersCount, membersCount } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== list.id) {
        return selectedCommunity;
      }
      let updatedSelectedCommunity: Community;
      if (skip === 0) {
        updatedSelectedCommunity = {
          ...selectedCommunity,
          members: list.members,
          pendingUsers: list.pendingUsers,
          pendingByUsers: list.pendingByUsers,
          isCommunityAdmin,
          membersCount: membersCount || (list.members || []).length,
          totalMembersCount: totalMembersCount || selectedCommunity.totalMembersCount,
          memberFilters: action.payload.memberFilters,
        };
      } else {
        const currentMembers = selectedCommunity.members || [];
        updatedSelectedCommunity = {
          ...selectedCommunity,
          members: [...currentMembers, ...(list.members || [])],
          isCommunityAdmin,
          totalMembersCount: totalMembersCount || selectedCommunity.totalMembersCount,
          memberFilters: action.payload.memberFilters,
        };
      }
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityMembersPendingByUserAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_MEMBERS_PENDING_BY_USER_SUCCESS',
        { id: number; pendingByUsers: Member[] }
      >,
    ) => {
      const { id, pendingByUsers } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== id) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        pendingByUsers,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    removeCommunityInvitationAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'REMOVE_COMMUNITY_INVITATION_SUCCESS',
        { communityId: number; userEmail: string; userId: number }
      >,
    ) => {
      const { communityId, userEmail } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        pendingByUsers: (selectedCommunity.pendingByUsers || []).filter((m: Member) => m.email !== userEmail),
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    respondCommunityJoinRequestAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'RESPOND_COMMUNITY_JOIN_REQUEST_SUCCESS',
        { communityId: number; userId: number; type: Response }
      >,
    ) => {
      const { communityId, userId, type } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        pendingUsers: (selectedCommunity.pendingUsers || []).filter((member: Member) => member.id !== userId),
      };
      if (type !== Response.Deny) {
        const acceptedUser = (selectedCommunity.pendingUsers || []).find((member: Member) => member.id === userId);
        updatedSelectedCommunity.members = acceptedUser
          ? [acceptedUser, ...(selectedCommunity.members || [])]
          : selectedCommunity.members || [];
      }
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityCirclesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_CIRCLES_SUCCESS', { communityId: number; circles: Circle[] }>,
    ) => {
      const { communityId, circles } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedCircles = circles.map(circle => ({
        ...circle,
        eventMembers: (selectedCommunity.circles || []).find((c: Circle) => c.id === circle.id)?.eventMembers,
      }));
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        circles: updatedCircles,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveCommunityCircleAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'SAVE_COMMUNITY_CIRCLE_SUCCESS', { communityId: number; circle: Circle }>,
    ) => {
      const { communityId, circle } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      let found = false;
      const updatedCircles = (selectedCommunity.circles || []).map((c: Circle) => {
        if (c.id === circle.id) {
          found = true;
          return {
            ...c,
            name: circle.name,
          };
        }
        return c;
      });
      if (!found) {
        updatedCircles.push(circle);
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        circles: updatedCircles,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveCommunityCirclesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'SAVE_COMMUNITY_CIRCLES_SUCCESS', { communityId: number; circles: Circle[] }>,
    ) => {
      const { communityId, circles } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedCircles = (selectedCommunity.circles || []).map((c: Circle) => {
        const newCircle = circles.find((circ: Circle) => circ.id === c.id);
        return newCircle ? { ...c, name: newCircle.name } : c;
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        circles: updatedCircles,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteCommunityCircleAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'DELETE_COMMUNITY_CIRCLE_SUCCESS', { communityId: number; circleId: number }>,
    ) => {
      const { communityId, circleId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedCircles = (selectedCommunity.circles || []).filter((c: Circle) => c.id !== circleId);
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        circles: updatedCircles,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    editCommunityCircleAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'EDIT_COMMUNITY_CIRCLE_SUCCESS', { communityId: number; circleId: number; userId: number }>,
    ) => {
      const { communityId, circleId, userId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedMembers = (selectedCommunity.members || []).map((member: Member) => {
        if (member.id === userId) {
          return {
            ...member,
            circle: { id: circleId, name: '', admin: false, shortName: '' },
          };
        }
        return member;
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        members: updatedMembers,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchProjectsAwaitingFeedbackAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_PROJECTS_AWAITING_FEEDBACK_SUCCESS',
        { communityId: number; projects: ProjectAwaitingFeedback[] }
      >,
    ) => {
      const { communityId, projects } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        projectsAwaitingFeedback: projects,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchProjectsNextParagraphsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_PROJECTS_NEXT_PARAGRAPHS_SUCCESS',
        { communityId: number; projects: ProjectNextParagraphs[] }
      >,
    ) => {
      const { communityId, projects } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        projectsNextParagraphs: projects,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityMembersCountriesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_MEMBERS_COUNTRIES_SUCCESS', { communityId: number; countries: Country[] }>,
    ) => {
      const { communityId, countries } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        countries: countries,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityMembersSkillsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_MEMBERS_SKILLS_SUCCESS', { communityId: number; skills: Skill[] }>,
    ) => {
      const { communityId, skills } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        skills: skills,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityMembersInterestsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_MEMBERS_INTERESTS_SUCCESS',
        { communityId: number; interests: Interest[] }
      >,
    ) => {
      const { communityId, interests } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        interests: interests,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchDiscussionTagsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_DISCUSSION_TAGS_SUCCESS', { discussionTags: string[]; communityId: number }>,
    ) => {
      const { discussionTags, communityId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        discussionTags: discussionTags,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityRequestsTagsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_REQUESTS_TAGS_SUCCESS', { requestsTags: string[]; communityId: number }>,
    ) => {
      const { requestsTags, communityId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        requestsTags: requestsTags,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityFilteringSkillsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_FILTERING_SKILLS_SUCCESS',
        { communityId: number; skills: RequestFilteringSkill[] }
      >,
    ) => {
      const { communityId, skills } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        requestSkills: skills,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveDiscussionTagsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'SAVE_DISCUSSION_TAGS_SUCCESS',
        { communityId: number; discussionId: number; discussionType: string; tags: string[] }
      >,
    ) => {
      const { communityId, discussionId, tags } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const mapFunction = (d: Discussion) => {
        if (d.id === discussionId) {
          return {
            ...d,
            discussionTags: tags,
          };
        }
        return d;
      };
      const newDiscussions = (selectedCommunity.discussions || []).map(mapFunction);
      const newCommunityDiscussions = (selectedCommunity.communityDiscussions || []).map(mapFunction);
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        discussions: newDiscussions,
        communityDiscussions: newCommunityDiscussions,
        discussionTags: [...tags, ...(selectedCommunity.discussionTags || [])].filter(
          (tag, idx, arr) => arr.indexOf(tag) === idx,
        ),
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityRequestsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_REQUESTS_SUCCESS',
        { list: CommunityRequests; skip: number; take: number; count: number; requestFilters: CommunityRequestsFilters }
      >,
    ) => {
      const { list, skip } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== list.id) {
        return selectedCommunity;
      }
      if (skip === 0) {
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          canCreateRequests: list.canCreateRequests,
          openRequests: list.openRequests,
          requestsCount: list.requestsCount,
          totalRequestsCount: list.totalRequestsCount,
          requestFilters: action.payload.requestFilters,
        };
        return updatedSelectedCommunity;
      } else {
        const currentR = selectedCommunity.openRequests || [];
        const requests = currentR.concat(list.openRequests as Request[]);
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          canCreateRequests: list.canCreateRequests,
          openRequests: requests,
          requestFilters: action.payload.requestFilters,
        };
        return updatedSelectedCommunity;
      }
    },
  )
  .handleAction(
    deleteRequestAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'DELETE_REQUEST_SUCCESS', { entityType: string; entityId: number; request: Request }>,
    ) => {
      const { entityType, entityId, request } = action.payload;
      if (entityType !== 'community') {
        return selectedCommunity;
      }

      if (!selectedCommunity || selectedCommunity.id !== entityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        openRequests: (selectedCommunity.openRequests || []).filter((r: Request) => r.id !== request.id),
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    closeRequestAsync.success,
    (selectedCommunity: Community | null, action: PayloadAction<'DELETE_REQUEST_SUCCESS', { requestId: number }>) => {
      const { requestId } = action.payload;

      if (!selectedCommunity) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        openRequests: (selectedCommunity.openRequests || []).filter((r: Request) => r.id !== requestId),
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchAllCommunityRequestsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_ALL_COMMUNITY_REQUESTS_SUCCESS', { list: RequestStatus[]; communityId: number }>,
    ) => {
      const { list, communityId } = action.payload;
      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        allRequests: list,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchMentionUsersAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_MENTION_USERS_SUCCESS', { communityId: number; mentionUsers: MentionUser[] }>,
    ) => {
      const { communityId, mentionUsers } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        mentionUsers: mentionUsers,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    updateCommunityRequestTagsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'UPDATE_COMMUNITY_REQUEST_TAGS_SUCCESS',
        { tags: Array<string>; requestId: number; communityId: number }
      >,
    ) => {
      const { tags, requestId, communityId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      let diffTagAdded: string[] = [];
      let diffTagRemoved: string[] = [];
      const newRequests = selectedCommunity.openRequests?.map(r => {
        if (r.id !== requestId) {
          return r;
        }
        diffTagAdded = difference(tags, r.tags);
        diffTagRemoved = difference(r.tags, tags);
        return {
          ...r,
          tags: tags,
        };
      });
      let requestsTags: string[] = selectedCommunity.requestsTags || [];
      if (diffTagAdded.length > 0) {
        if (selectedCommunity.requestsTags?.find(tag => tag === diffTagAdded[0]) === undefined) {
          requestsTags.push(diffTagAdded[0]);
        }
      }
      if (diffTagRemoved.length > 0) {
        if (newRequests?.find(r => r.tags.includes(diffTagRemoved[0])) === undefined) {
          requestsTags = requestsTags.filter(t => t !== diffTagRemoved[0]);
        }
      }
      const requestFilters = selectedCommunity.requestFilters;
      if (requestFilters) requestFilters.tags = requestsTags;
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        requestsTags: requestsTags.filter((tag: string, index: number, arr: string[]) => arr.indexOf(tag) === index),
        openRequests: newRequests,
        requestFilters,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityDiscussionsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_DISCUSSIONS_SUCCESS',
        {
          list: CommunityDiscussions;
          skip: number;
          take: number;
          discussionsCount?: number;
          totalDiscussionsCount?: number;
        }
      >,
    ) => {
      const { list, skip } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== list.id) {
        return selectedCommunity;
      }
      const discussionsList = (list.communityDiscussions || []).map((d: Discussion) => {
        return {
          ...d,
          projectName: selectedCommunity.name,
          projectLogoImage: selectedCommunity.logo,
        };
      });
      if (skip === 0) {
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          discussionsCount: action.payload.discussionsCount,
          totalDiscussionsCount: action.payload.totalDiscussionsCount || selectedCommunity.totalDiscussionsCount,
          communityDiscussions: discussionsList,
        };
        return updatedSelectedCommunity;
      } else {
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          communityDiscussions: [...(selectedCommunity.communityDiscussions || []), ...discussionsList],
        };
        return updatedSelectedCommunity;
      }
    },
  )
  .handleAction(
    followUserAsync.success,
    (selectedCommunity: Community | null, action: PayloadAction<'FOLLOW_USER_SUCCESS', number>) => {
      const userId = action.payload;

      if (!selectedCommunity) {
        return selectedCommunity;
      }
      const updatedMembers = selectedCommunity.members?.map(member => {
        if (member.id === userId) {
          return {
            ...member,
            isFollowed: true,
          };
        }
        return member;
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        members: updatedMembers || [],
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    unfollowUserAsync.success,
    (selectedCommunity: Community | null, action: PayloadAction<'UNFOLLOW_USER_SUCCESS', number>) => {
      const userId = action.payload;

      if (!selectedCommunity) {
        return selectedCommunity;
      }
      const updatedMembers = selectedCommunity.members?.map(member => {
        if (member.id === userId) {
          return {
            ...member,
            isFollowed: false,
          };
        }
        return member;
      });
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        members: updatedMembers || [],
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityResourcesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_RESOURCES_SUCCESS',
        { list: CommunityResources; skip: number; take: number }
      >,
    ) => {
      const { list, skip } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== list.id) {
        return selectedCommunity;
      }
      if (skip === 0) {
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          canEditResources: list.canEditResources,
          resources: list.resources,
          resourcesCount: list.resourcesCount || (list.resources || []).length,
          totalResourcesCount: list.totalResourcesCount || selectedCommunity.totalResourcesCount,
        };
        return updatedSelectedCommunity;
      } else {
        const currentResources = selectedCommunity.resources || [];
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          canEditResources: list.canEditResources,
          resources: currentResources.concat(list.resources as Resource[]),
        };
        return updatedSelectedCommunity;
      }
    },
  )
  .handleAction(
    createResourceAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'CREATE_RESOURCE_SUCCESS', { resource: Resource; isCommunityResource: boolean }>,
    ) => {
      const { resource, isCommunityResource } = action.payload;
      if (!isCommunityResource) {
        return selectedCommunity;
      }

      if (!selectedCommunity || selectedCommunity.id !== resource.communityId) {
        return selectedCommunity;
      }
      let updatedResources: Resource[];
      if (selectedCommunity.resources) {
        const index = findIndex(propEq('id', resource.id))(selectedCommunity.resources);
        if (index === -1) {
          updatedResources = prepend(resource, selectedCommunity.resources);
        } else {
          updatedResources = update(index, resource, selectedCommunity.resources);
        }
      } else {
        updatedResources = prepend(resource, []);
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        resources: updatedResources,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchResourcesTagsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_RESOURCES_TAGS_SUCCESS', { resourcesTags: string[]; communityId: number }>,
    ) => {
      const { resourcesTags, communityId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        resourcesTags: resourcesTags,
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteResourceAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'DELETE_RESOURCE_SUCCESS',
        { resourceId: number; entityId: number; isCommunityResource: boolean }
      >,
    ) => {
      const { resourceId, entityId, isCommunityResource } = action.payload;
      if (!isCommunityResource) {
        return selectedCommunity;
      }

      if (!selectedCommunity || selectedCommunity.id !== entityId) {
        return selectedCommunity;
      }
      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        resources: selectedCommunity.resources
          ? selectedCommunity.resources.filter((r: Resource) => r.id !== resourceId)
          : [],
      };
      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    updateCommunityInfoAsync.success,
    (selectedCommunity: Community | null, { payload }: PayloadAction<'UPDATE_COMMUNITY_INFO_SUCCESS', Community>) => {
      const updatedCommunity = payload;

      if (!selectedCommunity || selectedCommunity.id !== updatedCommunity.id) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = { ...selectedCommunity, ...updatedCommunity };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    uploadFileAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'UPLOAD_FILE_SUCCESS', { id: number; link: string }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.id) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        logo: payload.link,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityProjectsCountriesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_PROJECTS_COUNTRIES_SUCCESS',
        { communityId: number; countries: Country[] }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityProjectsCountries: action.payload.countries,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityProjectsCategoriesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_PROJECTS_CATEGORIES_SUCCESS',
        { communityId: number; categories: ProjectCategory[] }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityProjectsCategories: action.payload.categories,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityProjectsStagesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_PROJECTS_STAGES_SUCCESS', { communityId: number; stages: ProjectStage[] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityProjectsStages: action.payload.stages,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityProjectsSDGsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_PROJECTS_SDGS_SUCCESS', { communityId: number; sdgs: SDG[] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityProjectsSDGs: action.payload.sdgs,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityProjectsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_PROJECTS_SUCCESS',
        {
          list: Project[];
          skip: number;
          take: number;
          projectsCount: number;
          communityId: number;
          totalProjectsCount?: number;
          canCreateProjects: boolean;
          canAccessToOverviewProject: boolean;
          canApproveParagraph: boolean;
          canInviteToProjects: boolean;
          projectFilters: CommunityProjectsFilters;
        }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        projectsCount: action.payload.skip === 0 ? action.payload.projectsCount : selectedCommunity.projectsCount,
        totalProjectsCount: action.payload.totalProjectsCount,
        canCreateProjects: action.payload.canCreateProjects,
        canAccessToOverviewProject: action.payload.canAccessToOverviewProject,
        canApproveParagraph: action.payload.canApproveParagraph || false,
        canInviteToProjects: action.payload.canInviteToProjects || false,
        projectFilters: action.payload.projectFilters,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityProjectsTagsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_PROJECTS_TAGS_SUCCESS', { communityId: number; tags: string[] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityProjectsTags: action.payload.tags,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveCommunityProjectsTagsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'SAVE_COMMUNITY_PROJECTS_TAGS_SUCCESS',
        { projectId: number; communityId: number; tags: string[] }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const currentTags = selectedCommunity.communityProjectsTags ? [...selectedCommunity.communityProjectsTags] : [];
      action.payload.tags.forEach((t: string) => {
        const index = currentTags.findIndex((ct: string) => ct === t);
        if (index !== -1) {
          currentTags.splice(index, 1);
        }
      });
      const projectFilters = selectedCommunity?.projectFilters;
      if (projectFilters) projectFilters.tags = currentTags.concat(action.payload.tags);
      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityProjectsTags: currentTags.concat(action.payload.tags),
        projectFilters,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchResourceCirclesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_RESOURCE_CIRCLES_SUCCESS',
        { entityId: number; circles: Circle[]; resourceId?: number; isCommunityResource: boolean }
      >,
    ) => {
      if (!selectedCommunity || !action.payload.isCommunityResource) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = { ...selectedCommunity, resourcesCircles: action.payload.circles };

      if (updatedSelectedCommunity.resources) {
        const resourceIndex = updatedSelectedCommunity.resources.findIndex(
          (resource: Resource) => resource.id === action.payload.resourceId,
        );
        if (resourceIndex !== -1) {
          updatedSelectedCommunity.resources[resourceIndex].circles = action.payload.circles.filter(
            (c: Circle) => c.isActive,
          );
        }
      }

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    setAllowTasksAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'SET_ALLOW_TASKS_SUCCESS', { id: number; allowTasks: boolean }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.id) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        allowTasks: action.payload.allowTasks,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    removeUserFromCommunityAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'REMOVE_USER_FROM_COMMUNITY_SUCCESS', { communityId: number; userId: number }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        members: selectedCommunity.members
          ? selectedCommunity.members.filter(member => member.id !== action.payload.userId)
          : [],
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    removeUserFromTabEntityAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'REMOVE_USER_FROM_TAB_ENTITY_SUCCESS', { id: number; communityId: number; userId: number }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        members: selectedCommunity.members
          ? selectedCommunity.members.filter(member => member.id !== action.payload.userId)
          : [],
      };

      return updatedSelectedCommunity;
    },
  )

  .handleAction(
    fetchCommunityProgressTrackingAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<
        'FETCH_COMMUNITY_PROGRESS_TRACKING_SUCCESS',
        { progressOverview: CommunityProgressOverview; communityId: number }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        progressOverview: payload.progressOverview,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    searchCommunityProgressTrackingAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<
        'SEARCH_COMMUNITY_PROGRESS_TRACKING_SUCCESS',
        { data: CommunityProgressOverview; communityId: number }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        progressSearch: payload,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchKPIAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<'FETCH_KPI_SUCCESS', { methodologyId: number; communityId: number; kpis: KpiDefinition[] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: (selectedCommunity.methodologies || []).map((methodology: Methodology) => {
          if (methodology.id !== payload.methodologyId) {
            return methodology;
          }

          return {
            ...methodology,
            kpiDefinitions: payload.kpis,
          };
        }),
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    upsertKPIAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'UPSERT_KPI_SUCCESS', { communityId: number; kpi: KpiDefinition }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: (selectedCommunity.methodologies || []).map((methodology: Methodology) => {
          if (methodology.id !== payload.kpi.methodologyId) {
            return methodology;
          }

          let newKpiList: KpiDefinition[] = [];

          if (methodology.kpiDefinitions && methodology.kpiDefinitions.length > 0) {
            const existingKpi = methodology.kpiDefinitions.find(
              (kpiDefinition: KpiDefinition) => kpiDefinition.id === payload.kpi.id,
            );

            if (existingKpi) {
              newKpiList = methodology.kpiDefinitions.map((k: KpiDefinition) => {
                if (k.id === payload.kpi.id) {
                  return { ...payload.kpi };
                }
                return { ...k };
              });
            } else {
              newKpiList = [...methodology.kpiDefinitions, payload.kpi];
            }
          } else {
            newKpiList = [payload.kpi];
          }

          return {
            ...methodology,
            kpiDefinitions: newKpiList,
          };
        }),
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteKPIAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'DELETE_KPI_SUCCESS', { communityId: number; kpi: KpiDefinition }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: (selectedCommunity.methodologies || []).map((methodology: Methodology) => {
          if (methodology.id !== payload.kpi.methodologyId) {
            return methodology;
          }

          return {
            ...methodology,
            kpiDefinitions: (methodology.kpiDefinitions || []).filter((k: KpiDefinition) => k.id !== payload.kpi.id),
          };
        }),
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteCommentAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<
        'DELETE_COMMENT_SUCCESS',
        { postId: number; postType: CommentType; entityId: number; entityType: string; itemId: number }
      >,
    ) => {
      if (payload.entityType !== 'community' || !selectedCommunity || selectedCommunity.id !== payload.entityId) {
        return selectedCommunity;
      }

      if (payload.postType === CommentType.CommunityDiscussion)
        return discussionDeleteComment(selectedCommunity, payload.itemId, payload.postId);
      if (payload.postType === CommentType.TabEntity) return tabEntityDeleteComment(selectedCommunity, payload.postId);
      return deleteCommentFromCommunity(selectedCommunity, payload.postId);
    },
  )
  .handleAction(
    pinCommunityFeedPostAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'PIN_COMMUNITY_FEED_POST_SUCCESS', { postId: number; communityId: number }>,
    ) => {
      if (selectedCommunity?.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedFeed = (selectedCommunity.feed || []).map((feedItem: FeedItem) => {
        if (feedItem.id === payload.postId) {
          return { ...feedItem, isPinned: true };
        }
        return feedItem;
      });

      return {
        ...selectedCommunity,
        feed: updatedFeed,
      };
    },
  )
  .handleAction(
    unpinCommunityFeedPostAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'UNPIN_COMMUNITY_FEED_POST_SUCCESS', { postId: number; communityId: number }>,
    ) => {
      if (selectedCommunity?.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedFeed = (selectedCommunity.feed || []).map((feedItem: FeedItem) => {
        if (feedItem.id === payload.postId) {
          return { ...feedItem, isPinned: false };
        }
        return feedItem;
      });

      return {
        ...selectedCommunity,
        feed: updatedFeed,
      };
    },
  )
  .handleAction(
    fetchCommunityCalendarEventsAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<'FETCH_COMMUNITY_CALENDAR_EVENTS_SUCCESS', { communityId: number; eventList: CalendarEvent[] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        events: payload.eventList,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityCalendarEventDetailsAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<'FETCH_COMMUNITY_CALENDAR_EVENT_DETAILS_SUCCESS', { communityId: number; event: CalendarEvent }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        events: (selectedCommunity.events || []).map((event: CalendarEvent) => {
          if (event.id !== payload.event.id) {
            return event;
          }

          return payload.event;
        }),
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteCommunityCalendarEventAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'DELETE_COMMUNITY_CALENDAR_EVENT_SUCCESS', { communityId: number; eventId: string }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        events: (selectedCommunity.events || []).filter((event: CalendarEvent) => event.id !== payload.eventId),
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    upsertCommunityCalendarEventAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<'UPSERT_COMMUNITY_CALENDAR_EVENT_SUCCESS', { communityId: number; event: CalendarEvent }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const existingEventIndex = (selectedCommunity.events || []).findIndex(e => e.id === payload.event.id);

      if (existingEventIndex !== -1) {
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          events: (selectedCommunity.events || []).map((event: CalendarEvent) => {
            if (event.id !== payload.event.id) {
              return event;
            }

            return payload.event;
          }),
        };
        return updatedSelectedCommunity;
      } else {
        const updatedSelectedCommunity: Community = {
          ...selectedCommunity,
          events: (selectedCommunity.events || []).concat(payload.event),
        };
        return updatedSelectedCommunity;
      }
    },
  )
  .handleAction(
    setTaskUnlockRuleAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<
        'SET_TASK_UNLOCK_RULE_SUCCESS',
        { communityId: number; methodologyId: number; taskUnlockRule: Planner }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        methodologies: (selectedCommunity.methodologies || []).map((methodology: Methodology) => {
          if (methodology.id !== payload.methodologyId) {
            return methodology;
          }

          return {
            ...methodology,
            planner: {
              ...methodology.planner,
              plannerType: payload.taskUnlockRule,
            },
          };
        }),
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    upsertCommunityReportAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'UPSERT_COMMUNITY_REPORT_SUCCESS', { communityId: number; report: Report }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const { reports: existingReports } = selectedCommunity;
      const { report: upsertedReport } = payload;

      const isNewReport = !existingReports.some(({ id }) => id === upsertedReport.id);

      let updatedReports = [...existingReports];

      if (isNewReport) {
        const firstNegativeIndex = existingReports.findIndex(({ id }) => id < 0);
        updatedReports.splice(firstNegativeIndex, 0, upsertedReport);
      } else {
        updatedReports = existingReports.map(report => (report.id === upsertedReport.id ? upsertedReport : report));
      }

      return {
        ...selectedCommunity,
        reports: updatedReports,
      };
    },
  )
  .handleAction(
    deleteCommunityReportAsync.success,
    (
      selectedCommunity: Community,
      action: PayloadAction<'DELETE_COMMUNITY_REPORT_SUCCESS', { communityId: number; id: number }>,
    ) => {
      if (
        selectedCommunity.id !== action.payload.communityId ||
        !selectedCommunity.reports ||
        selectedCommunity.reports.length === 0
      )
        return selectedCommunity;
      if (selectedCommunity.reports.find((report: Report) => report.id === action.payload.id))
        return {
          ...selectedCommunity,
          reports: selectedCommunity.reports.filter((report: Report) => report.id !== action.payload.id),
        };
      return selectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityReportsAsync.success,
    (
      selectedCommunity: Community | null,
      { payload }: PayloadAction<'FETCH_COMMUNITY_REPORTS_SUCCESS', { communityId: number; reports: Report[] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        reports: payload.reports,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    createNewDiscussionAsync.success,
    (selectedCommunity: Community | null, { payload }: PayloadAction<'CREATE_NEW_DISCUSSION_SUCCESS', Discussion>) => {
      if (selectedCommunity?.id !== payload.communityId) {
        return selectedCommunity;
      }

      if (!selectedCommunity?.communityDiscussions || selectedCommunity.communityDiscussions.length === 0) {
        return {
          ...selectedCommunity,
          communityDiscussions: [payload],
        };
      }

      const updatedCommunityDiscussions = [...selectedCommunity.communityDiscussions];
      const existingDiscussionIndex = updatedCommunityDiscussions.findIndex(
        (discussion: Discussion) => discussion.id === payload.id,
      );

      if (existingDiscussionIndex !== -1) {
        updatedCommunityDiscussions[existingDiscussionIndex] = {
          ...updatedCommunityDiscussions[existingDiscussionIndex],
          ...payload,
        };
      } else {
        updatedCommunityDiscussions.unshift(payload);
      }

      return {
        ...selectedCommunity,
        communityDiscussions: updatedCommunityDiscussions,
      };
    },
  )
  .handleAction(
    fetchDiscussionAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<
        'FETCH_DISCUSSION_SUCCESS',
        {
          entityId: number;
          entityType: string;
          discussion: Discussion;
          canEditDiscussions: boolean;
          canEditPrivacy: boolean;
        }
      >,
    ) => {
      if (payload.entityType !== 'community' || selectedCommunity?.id !== payload.entityId) {
        return selectedCommunity;
      }

      const updatedCommunityDiscussions = [...(selectedCommunity.communityDiscussions || [])];

      const existingDiscussionIndex = updatedCommunityDiscussions.findIndex(
        (discussion: Discussion) => discussion.id === payload.discussion.id,
      );

      if (existingDiscussionIndex !== -1) {
        updatedCommunityDiscussions[existingDiscussionIndex] = {
          ...updatedCommunityDiscussions[existingDiscussionIndex],
          ...payload.discussion,
        };
      } else {
        updatedCommunityDiscussions.unshift(payload.discussion);
      }

      return {
        ...selectedCommunity,
        canEditDiscussions: payload.canEditDiscussions,
        communityDiscussions: updatedCommunityDiscussions,
      };
    },
  )
  .handleAction(
    addCommentToDiscussionAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<
        'ADD_COMMENT_DISCUSSIONS_SUCCESS',
        { discussionId: number; entityId: number; entityType: string; comment: Comment }
      >,
    ) => {
      if (payload.entityType !== 'community' || selectedCommunity?.id !== payload.entityId) {
        return selectedCommunity;
      }

      const updatedCommunityDiscussions = [...(selectedCommunity.communityDiscussions || [])];

      const targetDiscussion = updatedCommunityDiscussions.find(
        (discussion: Discussion) => discussion.id === payload.discussionId,
      );

      if (!targetDiscussion) {
        return selectedCommunity;
      }

      const updatedComments = [...(targetDiscussion.comments || [])];
      const parentId = payload.comment.parentId;

      if (parentId === 0) {
        updatedComments.unshift(payload.comment);
      } else {
        const parentComment = updatedComments.find((comment: Comment) => comment.id === parentId);

        if (parentComment) {
          parentComment.comments = [...(parentComment.comments || []), payload.comment];
        }
      }

      const updatedDiscussion = {
        ...targetDiscussion,
        commentCount: targetDiscussion.commentCount + 1,
        comments: updatedComments,
      };

      const discussionIndex = updatedCommunityDiscussions.findIndex(
        (discussion: Discussion) => discussion.id === payload.discussionId,
      );

      if (discussionIndex !== -1) {
        updatedCommunityDiscussions[discussionIndex] = updatedDiscussion;
      }

      return {
        ...selectedCommunity,
        communityDiscussions: updatedCommunityDiscussions,
      };
    },
  )
  .handleAction(
    fetchDiscussionsTagsAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<'FETCH_DISCUSSIONS_TAGS_SUCCESS', { entityId: number; entityType: string; tags: string[] }>,
    ) => {
      if (payload.entityType !== 'community' || selectedCommunity?.id !== payload.entityId) {
        return selectedCommunity;
      }

      return {
        ...selectedCommunity,
        discussionTags: payload.tags,
      };
    },
  )
  .handleAction(
    followDiscussionAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<'FOLLOW_DISCUSSION_SUCCESS', { entityType: string; entityId: number; discussionId: number }>,
    ) => {
      if (payload.entityType !== 'community' || selectedCommunity?.id !== payload.entityId) {
        return selectedCommunity;
      }

      const updatedCommunityDiscussions = (selectedCommunity.communityDiscussions || []).map(
        (discussion: Discussion) => {
          if (discussion.id === payload.discussionId) {
            return {
              ...discussion,
              isFollowing: true,
            };
          }

          return discussion;
        },
      );

      return {
        ...selectedCommunity,
        communityDiscussions: updatedCommunityDiscussions,
      };
    },
  )

  .handleAction(
    fetchCirclesByDiscussionsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_CIRCLES_BY_DISCUSSIONS_SUCCESS',
        { entityId: number; entityType: string; circles: Circle[] }
      >,
    ) => {
      if (action.payload.entityType !== 'community') {
        return selectedCommunity;
      }

      if (!selectedCommunity || selectedCommunity.id !== action.payload.entityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        discussionCircles: action.payload.circles,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    unfollowDiscussionAsync.success,
    (
      selectedCommunity: Community | null,
      {
        payload,
      }: PayloadAction<'UNFOLLOW_DISCUSSION_SUCCESS', { entityType: string; entityId: number; discussionId: number }>,
    ) => {
      if (payload.entityType !== 'community' || selectedCommunity?.id !== payload.entityId) {
        return selectedCommunity;
      }

      if (!selectedCommunity) {
        return selectedCommunity;
      }

      const updatedCommunityDiscussions = (selectedCommunity.communityDiscussions || []).map(
        (discussion: Discussion) => {
          if (discussion.id === payload.discussionId) {
            return {
              ...discussion,
              isFollowing: false,
            };
          }

          return discussion;
        },
      );

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityDiscussions: updatedCommunityDiscussions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchDiscussionPrivacyCirclesAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_DISCUSSION_PRIVACY_CIRCLES_SUCCESS',
        { entityId: number; entityType: string; discussionId: number; circles: Circle[] }
      >,
    ) => {
      const { entityId, entityType, discussionId, circles } = action.payload;
      if (entityType !== 'community') {
        return selectedCommunity;
      }

      if (!selectedCommunity || selectedCommunity.id !== entityId) {
        return selectedCommunity;
      }

      const updatedCommunityDiscussions = (selectedCommunity.communityDiscussions || []).map((d: Discussion) => {
        if (d.id !== discussionId) {
          return d;
        }

        return {
          ...d,
          privacyCircles: circles,
        };
      });

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        communityDiscussions: updatedCommunityDiscussions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveSectionsStagegateAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'SAVE_SECTIONS_STAGEGATE_SUCCESS',
        { communityId: number; methodologyId: number; sections: Section[] }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedMethodologies = (selectedCommunity.methodologies || []).map((methodology: Methodology) => {
        if (methodology.id !== action.payload.methodologyId) {
          return methodology;
        }

        return {
          ...methodology,
          sections: methodology.sections.map((section: Section) => {
            const updatedSection = action.payload.sections.find(
              (updatedSection: Section) => updatedSection.id === section.id,
            );
            if (!updatedSection) {
              return section;
            }
            return {
              ...section,
              isStagegated: updatedSection.isStagegated,
            };
          }),
        };
      });

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveMethodologyDiffAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'SAVE_METHODOLOGY_DIFF_SUCCESS', { communityId: number; methodology: Methodology }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedMethodologies = (selectedCommunity.methodologies || []).map((methodology: Methodology) => {
        if (methodology.id !== action.payload.methodology.id) {
          return methodology;
        }

        return {
          ...methodology,
          ...action.payload.methodology,
        };
      });

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        methodologies: updatedMethodologies,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveApplicationDefinitionAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'SAVE_APPLICATION_DEFINITION_SUCCESS',
        { communityId: number; applicationDefinition: ApplicationDefinition }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const existingApplicationDefinition = (selectedCommunity.applicationDefinitions || []).find(
        (applicationDefinition: ApplicationDefinition) =>
          applicationDefinition.id === action.payload.applicationDefinition.id,
      );

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        applicationDefinitions: existingApplicationDefinition
          ? (selectedCommunity.applicationDefinitions || []).map((applicationDefinition: ApplicationDefinition) => {
              if (applicationDefinition.id !== action.payload.applicationDefinition.id) {
                return applicationDefinition;
              }

              return {
                ...applicationDefinition,
                ...action.payload.applicationDefinition,
              };
            })
          : [...(selectedCommunity.applicationDefinitions || []), action.payload.applicationDefinition],
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityApplicationDefinitionsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_COMMUNITY_APPLICATION_DEFINITIONS_SUCCESS',
        { communityId: number; applicationDefinitions: ApplicationDefinition[] }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        applicationDefinitions: action.payload.applicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    updateCommunityCircleAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_UPDATE_COMMUNITY_CIRCLE_SUCCESS',
        { communityId: number; circleId: number; permission: any }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const communityCircles = selectedCommunity.circles || [];
      const circleIndex = communityCircles.findIndex((cir: Circle) => cir.id === action.payload.circleId);
      if (circleIndex === -1) {
        return selectedCommunity;
      }

      const updatedCircle = {
        ...communityCircles[circleIndex],
        ...action.payload.permission,
      };

      const updatedCircles = [
        ...communityCircles.slice(0, circleIndex),
        updatedCircle,
        ...communityCircles.slice(circleIndex + 1),
      ];

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        circles: updatedCircles,
      };

      return updatedSelectedCommunity;
    },
  )

  .handleAction(
    fetchCommunityCircleToCirclePermissionAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_SUCCESS', { communityId: number; list: [] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        circleToCirclePermission: action.payload.list,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityCircleToCirclePermissionSaveAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_CIRCLE_TO_CIRCLE_PERMISSION_SAVE_REQUEST', any>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const currCommunity = selectedCommunity;
      const index =
        currCommunity.circleToCirclePermission?.findIndex(
          (c: any) => c.circleIdTo === action.payload.circleIdTo && c.circleIdFrom === action.payload.circleIdFrom,
        ) || 0;

      if (selectedCommunity.circleToCirclePermission?.[index]) {
        Object.assign(selectedCommunity.circleToCirclePermission[index], action.payload);
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteApplicationDefinitionAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'DELETE_APPLICATION_DEFINITION_SUCCESS',
        { communityId: number; applicationDefinitionId: number }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).filter(
        (applicationDefinition: ApplicationDefinition) =>
          applicationDefinition.id !== action.payload.applicationDefinitionId,
      );

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityFormsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_COMMUNITY_FORMS_SUCCESS', { communityId: number; forms: Form[] }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        forms: action.payload.forms,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveFormAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'SAVE_FORM_SUCCESS', { communityId: number; form: Form }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedForms = (selectedCommunity.forms || []).map((form: Form) => {
        if (form.id !== action.payload.form.id) return form;
        return action.payload.form;
      });

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        forms: updatedForms,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteFormAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'DELETE_FORM_SUCCESS', { communityId: number; formId: number }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedForms = (selectedCommunity.forms || []).filter((form: Form) => form.id !== action.payload.formId);

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        forms: updatedForms,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchFormByIdAsync.success,

    (
      selectedCommunity: Community | null,
      action: PayloadAction<'FETCH_FORM_BY_ID_SUCCESS', { communityId: number; form: Form }>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedForms = (selectedCommunity.forms || [action.payload.form]).map((form: Form) => {
        if (form.id !== action.payload.form.id) return form;
        return action.payload.form;
      });

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        forms: updatedForms,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchApplicationDefinitionByIdAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_APPLICATION_DEFINITION_BY_ID_SUCCESS',
        { applicationDefinition: ApplicationDefinition }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.applicationDefinition.communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== action.payload.applicationDefinition.id) return applicationDefinition;
          return {
            ...applicationDefinition,
            ...action.payload.applicationDefinition,
          };
        },
      );

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchApplicationEntriesByDefinitionIdAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_APPLICATION_ENTRIES_BY_DEFINITION_ID_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntries: ApplicationEntry[];
          count: number;
          applicationDefinitionFilters: ApplicationFilters;
        }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== action.payload.applicationDefinitionId) return applicationDefinition;
          return {
            ...applicationDefinition,
            entries: action.payload.applicationEntries,
            entriesCount: action.payload.count,
          };
        },
      );

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
        applicationDefinitionFilters: action.payload.applicationDefinitionFilters,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchEvaluationAnswersByApplicationEntryIdAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_EVALUATION_ANSWERS_BY_APPLICATION_ENTRY_ID_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          formAnswers: FormQuestionAnswer[];
        }
      >,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== action.payload.communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== action.payload.applicationDefinitionId) return applicationDefinition;
          return {
            ...applicationDefinition,
            entries: (applicationDefinition.entries || []).map((applicationEntry: ApplicationEntry) => {
              if (applicationEntry.id !== action.payload.applicationEntryId) return applicationEntry;
              return {
                ...applicationEntry,
                formAnswers: action.payload.formAnswers,
              };
            }),
          };
        },
      );

      const updatedSelectedCommunity = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    saveApplicationEntryTagsAsync.success,
    (
      selectedCommunity: Community,
      action: PayloadAction<
        'SAVE_APPLICATION_ENTRY_TAGS_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          tags: string[];
        }
      >,
    ) => {
      if (selectedCommunity.id !== action.payload.communityId) return selectedCommunity;

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== action.payload.applicationDefinitionId) return applicationDefinition;

          const newApplicationTags = [...(applicationDefinition.applicationTags || []), ...action.payload.tags].filter(
            (tag: string, index: number, arr: string[]) => arr.indexOf(tag) === index,
          );

          const updatedEntries = (applicationDefinition.entries || []).map((applicationEntry: ApplicationEntry) => {
            if (applicationEntry.id !== action.payload.applicationEntryId) return applicationEntry;
            return {
              ...applicationEntry,
              tags: action.payload.tags,
            };
          });

          return {
            ...applicationDefinition,
            applicationTags: newApplicationTags,
            entries: updatedEntries,
          };
        },
      );

      return {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };
    },
  )
  .handleAction(
    saveApplicationEntryScoresAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'SAVE_APPLICATION_ENTRY_SCORES_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          scores: ApplicationScore[];
        }
      >,
    ) => {
      const { communityId, applicationDefinitionId, applicationEntryId, scores } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== applicationDefinitionId) {
            return applicationDefinition;
          }

          const updatedEntries = (applicationDefinition.entries || []).map((applicationEntry: ApplicationEntry) => {
            if (applicationEntry.id !== applicationEntryId) {
              return applicationEntry;
            }

            const updatedScores = applicationEntry.scores.map((oldScore: ApplicationScore) => {
              const newScore = scores.find(
                (s: ApplicationScore) => s.userId === oldScore.userId && s.criteria === oldScore.criteria,
              );
              return newScore || oldScore;
            });

            const newScores = scores.filter(
              (score: ApplicationScore) =>
                !applicationEntry.scores.find(
                  (s: ApplicationScore) => s.userId === score.userId && s.criteria === score.criteria,
                ),
            );

            return {
              ...applicationEntry,
              scores: [...updatedScores, ...newScores],
            };
          });

          return {
            ...applicationDefinition,
            entries: updatedEntries,
          };
        },
      );

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchApplicationEntryScoresAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_APPLICATION_ENTRY_SCORES_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          scores: ApplicationScore[];
        }
      >,
    ) => {
      const { communityId, applicationDefinitionId, applicationEntryId, scores } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== applicationDefinitionId) {
            return applicationDefinition;
          }

          const updatedEntries = (applicationDefinition.entries || []).map((applicationEntry: ApplicationEntry) => {
            if (applicationEntry.id !== applicationEntryId) {
              return applicationEntry;
            }

            return {
              ...applicationEntry,
              scores: scores,
            };
          });

          return {
            ...applicationDefinition,
            entries: updatedEntries,
          };
        },
      );

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    updateApplicationEntryStatusAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_APPLICATION_ENTRY_STATUS_SUCCESS',
        {
          communityId?: number;
          applicationDefinitionId?: number;
          applicationEntryIds: number[];
          status: ApplicationStatus;
        }
      >,
    ) => {
      const { communityId, applicationDefinitionId, applicationEntryIds, status } = action.payload;
      if (!communityId || !applicationDefinitionId) {
        return selectedCommunity;
      }

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== applicationDefinitionId) {
            return applicationDefinition;
          }

          const updatedEntries = (applicationDefinition.entries || []).map((applicationEntry: ApplicationEntry) => {
            if (applicationEntryIds.indexOf(applicationEntry.id) < 0) {
              return applicationEntry;
            }

            return {
              ...applicationEntry,
              status: status,
            };
          });

          return {
            ...applicationDefinition,
            entries: updatedEntries,
          };
        },
      );

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchApplicationFiltersAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_APPLICATION_FILTERS_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          tags: string[];
          countries: Country[];
          industries: Interest[];
          interests: Interest[];
          sdgs: SDG[];
        }
      >,
    ) => {
      const { communityId, applicationDefinitionId, tags, countries, industries, interests, sdgs } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== applicationDefinitionId) {
            return applicationDefinition;
          }

          return {
            ...applicationDefinition,
            applicationTags: tags,
            applicationCountries: countries,
            applicationIndustries: industries,
            applicationSDGs: sdgs,
            applicationInterests: interests,
          };
        },
      );

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchApplicationEntryCommentsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_APPLICATION_ENTRY_COMMENTS_SUCCESS',
        {
          communityId: number;
          applicationDefinitionId: number;
          applicationEntryId: number;
          comments: Comment[];
        }
      >,
    ) => {
      const { communityId, applicationDefinitionId, applicationEntryId, comments } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== applicationDefinitionId) {
            return applicationDefinition;
          }

          const updatedEntries = (applicationDefinition.entries || []).map((applicationEntry: ApplicationEntry) => {
            if (applicationEntry.id !== applicationEntryId) {
              return applicationEntry;
            }

            return {
              ...applicationEntry,
              comments: comments,
            };
          });

          return {
            ...applicationDefinition,
            entries: updatedEntries,
          };
        },
      );

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    fetchCommunityMembersByCircleAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<
        'FETCH_MEMBERS_BY_CIRCLE_SUCCESS',
        { communityId: number; circleId: number; skip: number; take: number; members: Member[] }
      >,
    ) => {
      const { communityId, circleId, skip, members } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedCircles = (selectedCommunity.circles || []).map((circle: Circle) => {
        if (circle.id !== circleId) {
          return circle;
        }

        if (skip === 0) {
          return {
            ...circle,
            eventMembers: members,
          };
        } else {
          return {
            ...circle,
            eventMembers: [...(circle.eventMembers || []), ...members],
          };
        }
      });

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        circles: updatedCircles,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    getLiteralsAsync.success,
    (
      selectedCommunity: Community | null,
      action: PayloadAction<'GET_LITERALS_SUCCESS', { language: Languages; literals: Literals; communityId?: number }>,
    ) => {
      const { literals, communityId } = action.payload;

      if (!selectedCommunity || selectedCommunity.id !== (communityId || -1)) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        customLiterals: literals,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    [fetchCommunityProjectFormAsync.success, updateCommunityProjectFormAsync.success],
    (
      selectedCommunity: Community | null,
      { payload: { communityId, form } }: StateType<typeof fetchCommunityProjectFormAsync.success>,
    ) => {
      if (!selectedCommunity || selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        projectForm: form,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(
    deleteApplicationEntriesAsync.success,
    (
      selectedCommunity: Community,
      action: PayloadAction<
        'DELETE_APPLICATION_ENTRY_SUCCESS',
        { applicationEntryIds: number[]; communityId: number; applicationDefinitionId: number }
      >,
    ) => {
      const { applicationEntryIds, communityId, applicationDefinitionId } = action.payload;

      if (selectedCommunity.id !== communityId) {
        return selectedCommunity;
      }

      const updatedApplicationDefinitions = (selectedCommunity.applicationDefinitions || []).map(
        (applicationDefinition: ApplicationDefinition) => {
          if (applicationDefinition.id !== applicationDefinitionId) {
            return applicationDefinition;
          }

          const updatedEntries = (applicationDefinition.entries || []).filter(
            (applicationEntry: ApplicationEntry) => applicationEntryIds.indexOf(applicationEntry.id) < 0,
          );

          return {
            ...applicationDefinition,
            entries: updatedEntries,
          };
        },
      );

      const updatedSelectedCommunity: Community = {
        ...selectedCommunity,
        applicationDefinitions: updatedApplicationDefinitions,
      };

      return updatedSelectedCommunity;
    },
  )
  .handleAction(fetchCommunityTabsAsync.success, fetchCommunityTabsActionHandler)
  .handleAction(fetchTabEntitiesByIdAsync.success, fetchTabEntitiesByIdActionHandler)
  .handleAction(followOrUnFollowEntityAsync.success, followOrUnFollowEntityActionHandler)
  .handleAction([fetchTabByIdAsync.success, saveTabAsync.success], updateOrCreateTabActionHandler)
  .handleAction(deleteTabAsync.success, deleteTabActionHandler)
  .handleAction(fetchCommunityTabEntityCirclesAsync.success, fetchCommunityTabEntityCirclesActionHandler)
  .handleAction(fetchCommunityTabEntityCircleMembersAsync.success, fetchCommunityTabEntityCircleMembersActionHandler)
  .handleAction(fetchTabEntityCommentsAsync.success, fetchTabEntityCommentsActionHandler)
  .handleAction(editTabEntityCircleAsync.success, editTabEntityCircleAsyncActionHandler)
  .handleAction(saveTabEntityCircleAsync.success, saveTabEntityCircleActionHandler)
  .handleAction(deleteTabEntityCircleAsync.success, deleteTabEntityCircleActionHandler)
  .handleAction(editCommunityTagsAsync.success, editCommunityTagsActionHandler)
  .handleAction(deleteCommunityTagsAsync.success, deleteCommunityTagsActionHandler)
  .handleAction(editCommunityProjectTagsAsync.success, editCommunityProjectTagsActionHandler)
  .handleAction(deleteCommunityProjectTagsAsync.success, deleteCommunityProjectTagsActionHandler)
  .handleAction(fetchCommunityMembersTagsAsync.success, fetchCommunityMembersTagsActionHandler)
  .handleAction(saveCommunityMembersTagsAsync.success, saveCommunityMembersTagsActionHandler)
  .handleAction(updateMethodologyNameAsync.success, updateMethodologyNameActionHandler);
export const communityTransform = createTransform<Community[], Community[], { list: Community[] }>(
  (state: Community[]) => {
    if (!gdprConsent(featuresCookieCategories.account)) {
      return [];
    }
    return state.map((community: Community) => ({
      id: community.id,
      name: community.name,
      logo: community.logo,
      feed: (community.feed || []).filter((feedItem: any, feedItemIndex: number) => feedItemIndex < 3),
      reports: [],
      allowTasks: community.allowTasks,
      canSeeProjectOverview: community.canSeeProjectOverview,
      canEditCommunityInfo: community.canEditCommunityInfo,
    }));
  },
  (state: Community[]) => {
    return state;
  },
  { whitelist: ['list'] },
);

export const persistCommunityConfig = {
  key: getPersistKey('list'),
  storage,
  transforms: [communityTransform],
  whitelist: ['list'],
};
